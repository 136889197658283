<template>
  <div class=" py-3 mb-3 bg-gray-950">
    <div class="mx-auto max-w-screen-2xl">
      <Filters
        v-model="filters"
        :filters="availableFilters"
        group="wallet"
      />
    </div>
  </div>

  <div>
    <div
      v-if="!isLoading && tokens.length === 0"
      class="flex flex-1 justify-center items-center text-2xl font-light text-gray-500"
    >
      <div class="py-16">
        No tokens
      </div>
    </div>

    <div
      v-if="isLoading || (!isLoading && tokens.length > 0)"
      class="grid flex-1 grid-cols-7 gap-6 items-start"
    >
      <GridListItem
        v-for="(item) in tokens"
        :key="item.id"
        :item="item"
        :collection="item.collection"
        show-collection
        hide-wallet
        @showDetails="() => showTokenDetails(item.id)"
      >
        <template #collection-context>
          <OverlayFilterContext
            @exclude="filters.push({key:'collectionId', type: 'collection', value: { comparator: 'nin', value: [item.collection.id] }})"
            @only="filters.push({key:'collectionId', type: 'collection', value: { comparator: 'in', value: [item.collection.id] }})"
          />
        </template>
      </GridListItem>

      <div
        v-for="i in loadingItems"
        :key="i"
      >
        <GridListItem
          :is-loading="true"
          show-collection
          hide-wallet
        />
      </div>
    </div>
  </div>

  <IntersectionObserver
    v-if="!isLoading && !isLoadingMore && hasMore"
    @isShow="loadMore"
  />
</template>

<script setup>
import { COLLECTION_LIST_ITEM_FIELDS } from '@/graphql/collection/fragments/collectionListItemFields.fragment';
import { TOKEN_FIELDS } from '@/graphql/token/fragments/tokenFields.fragment';
import gql from 'graphql-tag';
import {
  computed, reactive, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { usePremium } from '@/composition/premium';
import usePaginatedQuery from '@/composition/pagination/usePaginatedQuery';
import { useFiltersQuerySync } from '@/composition/filters/useFiltersQuerySync';
import useTokenModal from '@/composition/tokens/useTokenModal';
import { FILTERS, FILTER_TYPES } from '@/constants/filters';
import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { WATCHLIST_TYPES } from '@/constants';
import GridListItem from '../collections/GridListItem.vue';
import IntersectionObserver from '../IntersectionObserver.vue';
import Filters from '../filters/Filters.vue';
import OverlayFilterContext from '../OverlayFilterContext.vue';

const query = gql`
  query GetWalletGroupPortfolioTokens($slug: String!, $take: Int, $skip: Int, $filters: [FilterArg!]) {
    walletWatchlist: walletWatchlistBySlug(slug: $slug) {
      id
      tokens(take: $take, skip: $skip, filters: $filters) {
        ...Token_Fields
        price
        collection {
          ...CollectionListItem_Fields
        }
      }
    }
  }
  ${TOKEN_FIELDS}
  ${COLLECTION_LIST_ITEM_FIELDS}
`;

const route = useRoute();
const slug = computed(() => route.params.slug || 'portfolio');

const paginationData = reactive({
  skip: 0,
  take: 28,
});

// TODO: move to composition
const availableFilters = FILTERS.WALLET_TOKENS;
const filters = ref([{ key: 'hideSpam', type: FILTER_TYPES.BOOLEAN, value: true }]);
useFiltersQuerySync(filters);

const variables = computed(() => ({
  slug: slug.value,
  filters: filters.value,
}));

const {
  result,
  loading: isLoading,
  loadMore: fetchMore,
  hasMore,
  loadingMore: isLoadingMore,
  refetch,
} = usePaginatedQuery({
  take: paginationData.take,
  hasMoreCheck: (data) => data.walletWatchlist.tokens.length === paginationData.take,
  mergeResults: (oldData, newData) => {
    const data = JSON.parse(JSON.stringify(oldData));
    data.walletWatchlist.tokens = [
      ...data.walletWatchlist.tokens,
      ...newData.walletWatchlist.tokens,
    ];

    return data;
  },
}, query, variables);

const { onWatchlistMembersUpdated } = useWatchlists(WATCHLIST_TYPES.WALLET);

onWatchlistMembersUpdated(() => refetch());

const tokens = computed(() => result.value?.walletWatchlist?.tokens || []);

const loadingItems = computed(() => (isLoading.value ? 28 : 0));

const { requirePremium } = usePremium();
async function loadMore() {
  await requirePremium('to view all tokens');

  return fetchMore();
}

const { open } = useTokenModal();
function showTokenDetails(tokenId, withBuyIntent = false) {
  open(tokenId);
}

</script>
