<template>
  <UiButton
    tag="a"
    class="inline-block"
    href="https://domino.run?utm_source=compass&utm_medium=automate_button"
  >
    <div class="flex gap-2 items-center">
      <i class="-top-px text-indigo-500 fad fa-robot" />

      <span>Automate</span>
    </div>
  </UiButton>

  <UiModal
    v-model="isModalVisible"
    size="lg"
  >
    <UiModalHeader
      title="Create Automation"
      @dismiss="isModalVisible = false"
    />

    <div class="py-3 px-4">
      <UiTabs
        v-if="!selectedTemplate"
        pills
      >
        <UiTab name="Your Templates">
          <!-- list of templates -->
          <div class="flex flex-col gap-3">
            <AutomationTemplateListItem
              v-for="item in userTemplates"
              :key="item.id"
              class="hover:bg-gray-800 transition-colors cursor-pointer"
              :template="item"
              @click="selectTemplate(item)"
            />

            <div
              v-if="isLoading"
              class="flex gap-3 p-2 h-36 bg-gray-800 rounded-xl border border-gray-800 animate-pulse"
            />

            <NoContent
              v-if="!isLoading && userTemplates.length === 0"
              icon="fad fa-robot"
              content="You have no usable templates"
              size="sm"
            >
              <template #cta>
                <RouterLink :to="{name: routesNames.automationTemplateCreate}">
                  <UiButton size="sm">
                    Create Template
                  </UiButton>
                </RouterLink>
              </template>
            </NoContent>
          </div>
        </UiTab>

        <UiTab name="Community Templates">
          <!-- list of templates -->
          <div class="flex flex-col gap-3">
            <AutomationTemplateListItem
              v-for="item in publicTemplates"
              :key="item.id"
              class="hover:bg-gray-800 transition-colors cursor-pointer"
              :template="item"
              @click="selectTemplate(item)"
            />

            <div
              v-if="isLoading"
              class="flex gap-3 p-2 h-36 bg-gray-800 rounded-xl border border-gray-800 animate-pulse"
            />

            <NoContent
              v-if="!isLoading && publicTemplates.length === 0"
              icon="fad fa-robot"
              content="There are no usable templates"
              size="sm"
            >
              <template #cta>
                <RouterLink :to="{name: routesNames.automationExplore}">
                  <UiButton size="sm">
                    Explore all templates
                  </UiButton>
                </RouterLink>
              </template>
            </NoContent>
          </div>
        </UiTab>
      </UiTabs>

      <div v-if="selectedTemplate">
        <AutomationTemplateListItem
          :template="selectedTemplate"
          class="mb-3"
        />

        <ActionInputMapper
          v-model="newAutomationVariables"
          class="mb-6"
          :inputs="selectedTemplate.inputs"
          :outputs="[]"
          no-variables
          :validation-issues="validationIssues"
        />
      </div>
    </div>

    <UiModalFooter v-if="selectedTemplate">
      <div class="flex gap-2">
        <UiButton
          variant="white"
          size="sm"
          @click="selectedTemplate = null"
        >
          Back
        </UiButton>

        <UiButton
          :disabled="!isValid"
          size="sm"
          :is-loading="isCreatingAutomation"
          @click="createAutomation"
        >
          Create Automation
        </UiButton>
      </div>
    </UiModalFooter>
  </UiModal>
</template>

<script setup>
import { toRefs, ref, computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import getSchema from '@/validation/templates/inputs';
import useValidation from '@/composition/validation/use-validation';
import { useRouter } from 'vue-router';
import routesNames from '@/router/routesNames';
import usePublicTemplates from '@/composition/automation/use-public-templates';
import useAutomationTemplates from '@/composition/automation/use-automation-templates';
import UiButton from '../ui/UiButton.vue';
import UiModal from '../ui/UiModal.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';
import UiTabs from '../ui/UiTabs.vue';
import UiTab from '../ui/UiTab.vue';
import ActionInputMapper from './ActionInputMapper.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import AutomationTemplateListItem from './AutomationTemplateListItem.vue';
import NoContent from '../NoContent.vue';

const props = defineProps({
  buttonProps: {
    type: Object,
    default: () => ({
      variant: 'white',
    }),
  },
  availableInputs: {
    type: Array,
    default: () => [],
  },
  inputValues: {
    type: Object,
    default: () => ({}),
  },
});

const { buttonProps, availableInputs, inputValues } = toRefs(props);

const isModalVisible = ref(false);

const { result, loading: isLoading } = useQuery(gql`
  query GetAvailableTemplatesForInputs($inputTypes: [String!]!) {
    userTemplates: automationTemplatesByInputTypes(inputTypes: $inputTypes) {
      ...AutomationTemplateFragment
      createdAt
    }

    publicTemplates: publicAutomationTemplatesByInputTypes(inputTypes: $inputTypes) {
      ...AutomationTemplateFragment
      shortDescription
    }
  }

  fragment AutomationTemplateFragment on IAutomationTemplate {
    id
    name
    imageUrl
    inputs {
      description
      properties
      defaultValue
      required
      items
      actingAs
      type
      name
    }
  }
`, () => ({
  inputTypes: availableInputs.value,
}), {
  enabled: computed(() => isModalVisible.value && availableInputs.value.length > 0),
});

const userTemplates = computed(() => result.value?.userTemplates ?? []);
const publicTemplates = computed(() => result.value?.publicTemplates ?? []);

const selectedTemplate = ref(null);

const newAutomationVariables = ref({});

const validationSchema = computed(() => getSchema(selectedTemplate.value.inputs));

const { isValid, validationIssues } = useValidation(validationSchema, newAutomationVariables);

const selectTemplate = (template) => {
  selectedTemplate.value = template;
  newAutomationVariables.value = {};

  selectedTemplate.value.inputs.forEach((input) => {
    if (availableInputs.value.includes(input.actingAs)) {
      newAutomationVariables.value[input.name] = inputValues.value[input.actingAs];
    }
  });
};

const showModal = () => {
  isModalVisible.value = true;
  selectedTemplate.value = null;
};

const { createAutomation: createAutomationFromPublicTemplateMutation } = usePublicTemplates();
const {
  createAutomation: createAutomationFromPrivateTemplateMutation,
} = useAutomationTemplates();

const router = useRouter();
const isCreatingAutomation = ref(false);
const createAutomation = () => {
  let promise;
  isCreatingAutomation.value = true;
  // eslint-disable-next-line no-underscore-dangle
  if (selectedTemplate.value.__typename === 'PublicTemplate') {
    promise = createAutomationFromPublicTemplateMutation({
      templateId: selectedTemplate.value.id,
      variables: newAutomationVariables.value,
      name: `Created from template: ${selectedTemplate.value.name}`,
      status: false,
    });
  } else {
    promise = createAutomationFromPrivateTemplateMutation({
      templateId: selectedTemplate.value.id,
      variables: newAutomationVariables.value,
      name: `Created from template: ${selectedTemplate.value.name}`,
      status: false,
    }).then((res) => res.data.createAutomationFromTemplate);
  }

  return promise.then((res) => {
    router.push({
      name: routesNames.automationShow,
      params: {
        id: res.id,
      },
    });
  }).finally(() => {
    isCreatingAutomation.value = false;
    isModalVisible.value = false;
  });
};
</script>
