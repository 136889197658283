<template>
  <div class="flex items-center text-gray-800 dark:text-white">
    <i class="mr-2 fad fa-cube" />

    <div
      v-if="blockHead"
      class="font-medium tabular-nums"
    >
      <a
        :href="`https://etherscan.io/block/${blockHead}`"
        target="_blank"
      >{{ blockHead }}</a>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  inject,
} from 'vue';

export default defineComponent({
  name: 'LiveBadge',

  setup() {
    const blockHead = inject('eth:blockHead');

    return {
      blockHead,
    };
  },
});
</script>
