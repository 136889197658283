<template>
  <div class="flex">
    <UiInput
      v-model="displayValue"
      placeholder="1"
      class="rounded-r-none"
      :disabled="disabled"
    />

    <UiSelectCustom
      v-model="period"
      :button-class="['rounded-l-none']"
      :disabled="disabled"
      :options="[
        {id: 'hours', name: 'Hours'},
        {id: 'minutes', name: 'Minutes'},
      ]"
    />
  </div>
</template>

<script setup>
import { toRefs, computed, ref } from 'vue';
import UiInput from './UiInput.vue';
import UiSelectCustom from './UiSelectCustom.vue';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  isMiliSecond: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

// modelValue is the number of seconds this interval represents
const { modelValue, isMiliSecond } = toRefs(props);

const period = ref('minutes');

const multiplier = computed(() => (isMiliSecond.value !== false ? 1000 : 1));

const displayValue = computed({
  get: () => {
    if (period.value === 'minutes') {
      return modelValue.value / 60 / multiplier.value;
    }

    if (period.value === 'hours') {
      return modelValue.value / 60 / 60 / multiplier.value;
    }

    return modelValue.value / multiplier.value;
  },
  set: (value) => {
    if (period.value === 'minutes') {
      emit('update:modelValue', value * 60 * multiplier.value);
    }

    if (period.value === 'hours') {
      emit('update:modelValue', value * 60 * 60 * multiplier.value);
    }
  },
});

</script>
