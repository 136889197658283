<template>
  <div>
    <form
      @submit.prevent
      @keyup="onInputEnter"
      @keyup.enter="$emit('inputEnter', true)"
    >
      <UiInputGroup>
        <UiInput
          v-model="nameModel"
          placeholder="Name"
          autocomplete="off"
        />
      </UiInputGroup>

      <UiInputGroup>
        <select
          v-model="countryModel"
          class="block
      w-full
      sm:text-sm
      dark:text-gray-100
      bg-white
      dark:bg-gray-700 dark:bg-opacity-70
      rounded-lg
      border
      dark:border-gray-950
      focus:ring-indigo-500
      focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-black
      shadow-sm"
        >
          <option
            disabled
            :value="null"
          >
            Country
          </option>

          <option
            v-for="item in countries"
            :key="item.code"
            :value="item.code"
          >
            {{ item.name }}
          </option>
        </select>
      </UiInputGroup>

      <UiInputGroup>
        <UiInput
          v-model="addressLine1Model"
          placeholder="Address line 1"
          autocomplete="off"
        />
      </UiInputGroup>

      <UiInputGroup>
        <UiInput
          v-model="addressLine2Model"
          placeholder="Address line 2"
          autocomplete="off"
        />
      </UiInputGroup>

      <UiInputGroup>
        <div class="grid grid-cols-3 gap-2">
          <div>
            <UiInput
              v-model="cityModel"
              placeholder="City"
              autocomplete="off"
            />
          </div>

          <div>
            <UiInput
              v-model="stateModel"
              placeholder="State"
              autocomplete="off"
            />
          </div>

          <div>
            <UiInput
              v-model="zipCodeModel"
              placeholder="ZIP"
              autocomplete="off"
            />
          </div>
        </div>
      </UiInputGroup>

      <UiInputGroup v-if="askForVatNumber">
        <UiInput
          v-model="vatNumberModel"
          placeholder="VAT Number"
          autocomplete="off"
        />
      </UiInputGroup>
    </form>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getData } from 'country-list';
import { euMember } from 'is-european';
import UiInput from './ui/UiInput.vue';
import UiInputGroup from './ui/UiInputGroup.vue';

export default defineComponent({
  name: 'BillingInfoForm',
  components: { UiInput, UiInputGroup },

  props: {
    name: {
      default: '',
      type: String,
    },
    addressLine1: {
      default: '',
      type: String,
    },
    addressLine2: {
      default: '',
      type: String,
    },
    city: {
      default: '',
      type: String,
    },
    state: {
      default: '',
      type: String,
    },
    zipCode: {
      default: '',
      type: String,
    },
    country: {
      default: '',
      type: String,
    },
    vatNumber: {
      default: '',
      type: String,
    },
    isValid: {
      default: true,
      type: Boolean,
    },
  },
  emits: [
    'update:name',
    'update:addressLine1',
    'update:addressLine2',
    'update:city',
    'update:state',
    'update:zipCode',
    'update:country',
    'update:vatNumber',
    'update:isValid',
    'inputEnter',
  ],

  setup() {
    return {
      countries: getData().sort((a, b) => (a.name < b.name ? -1 : 1)),
    };
  },

  data() {
    return {
      countryFilter: '',
      countryFieldFocus: false,
    };
  },

  computed: {
    askForVatNumber() {
      return euMember(this.countryModel);
    },
    nameModel: {
      get() {
        return this.name;
      },

      set(value) {
        this.$emit('update:name', value);
      },
    },
    addressLine1Model: {
      get() {
        return this.addressLine1;
      },

      set(value) {
        this.$emit('update:addressLine1', value);
      },
    },
    addressLine2Model: {
      get() {
        return this.addressLine2;
      },

      set(value) {
        this.$emit('update:addressLine2', value);
      },
    },
    cityModel: {
      get() {
        return this.city;
      },

      set(value) {
        this.$emit('update:city', value);
      },
    },
    stateModel: {
      get() {
        return this.state;
      },

      set(value) {
        this.$emit('update:state', value);
      },
    },
    zipCodeModel: {
      get() {
        return this.zipCode;
      },

      set(value) {
        this.$emit('update:zipCode', value);
      },
    },
    countryModel: {
      get() {
        return this.country;
      },

      set(value) {
        this.$emit('update:country', value);
      },
    },
    vatNumberModel: {
      get() {
        return this.vatNumber;
      },

      set(value) {
        this.$emit('update:vatNumber', value);
      },
    },

  },

  methods: {
    onInputEnter() {
      const valid = !!this.addressLine1Model && !!this.cityModel && !!this.nameModel && !!this.countryModel;
      this.$emit('update:isValid', valid);
    },
  },
});
</script>

<style>

</style>
