<template>
  <UiCard class="py-6">
    <div
      class="grid gap-y-8 divide-x divide-gray-200 dark:divide-gray-700"
      :class="cols ? cols : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-6'"
    >
      <UiGridItem
        v-if="isEnabled('listedFloor')"
        class="px-6"
      >
        <MetricWidget
          :is-loading="!isRefetching && isAnalyticsLoading"
          title="Floor"
          :value="analytics?.listedFloor"
          :formatter="formatCurrency"
          :compare-to="analytics?.previousListedFloor"
        >
          <template #value="{ value }">
            <CurrencyDisplay
              class="font-mono"
              :value="value"
            />
          </template>
        </MetricWidget>
      </UiGridItem>

      <UiGridItem
        v-if="isEnabled('topOffer')"
        class="px-6"
      >
        <MetricWidget
          :is-loading="!isRefetching && isAnalyticsLoading"
          title="Top Bid"
          :value="analytics?.topOffer"
          :formatter="formatCurrency"
        >
          <template #value="{ value }">
            <CurrencyDisplay
              class="font-mono"
              :value="value"
            />
          </template>
        </MetricWidget>
      </UiGridItem>

      <UiGridItem
        v-if="isEnabled('totalTokensListed')"
        class="px-6"
      >
        <MetricWidget
          title="Tokens Listed"

          :value="analytics?.totalTokensListed"
          :is-loading="!isRefetching && isAnalyticsLoading"
        >
          <template #value="{value}">
            <div class="font-mono">
              {{ formatNumber(value) }} <span class="text-base text-gray-400">{{ formatPercent(analytics?.totalTokensListed / collection?.totalSupply) }}</span>
            </div>
          </template>
        </MetricWidget>
      </UiGridItem>

      <UiGridItem
        v-if="isEnabled('avgPrice')"
        class="px-6"
      >
        <MetricWidget
          :is-loading="!isRefetching && isAnalyticsLoading"
          title="Average price"
          :value="analytics?.metrics1d?.avgPrice"
          :compare-to="analytics?.metrics1dPrevious?.avgPrice"
          :formatter="formatCurrency"
        >
          <template #value="{value}">
            <CurrencyDisplay
              class="font-mono"
              :value="value"
            />
          </template>
        </MetricWidget>
      </UiGridItem>

      <UiGridItem
        v-if="isEnabled('volume1d')"
        class="px-6"
      >
        <MetricWidget
          :is-loading="!isRefetching && isAnalyticsLoading"
          title="24h Volume"
          :value="analytics?.metrics1d?.volume"
          :compare-to="analytics?.metrics1dPrevious?.volume"
          :formatter="formatCurrency"
          :formatter-args="['ETH', 0]"
        >
          <template #value="{value}">
            <CurrencyDisplay
              class="font-mono"
              :value="value"
            />
          </template>
        </MetricWidget>
      </UiGridItem>

      <UiGridItem
        v-if="isEnabled('volume30d')"
        class="px-6"
      >
        <MetricWidget
          :is-loading="!isRefetching && isAnalyticsLoading"
          title="30 Day Volume"
          :value="analytics?.metrics30d?.volume"
          :compare-to="analytics?.metrics30dPrevious?.volume"
          :formatter="formatCurrency"
          :formatter-args="['ETH', 0]"
        >
          <template #value="{value}">
            <CurrencyDisplay
              class="font-mono"
              :value="value"
            />
          </template>
        </MetricWidget>
      </UiGridItem>
    </div>
  </UiCard>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import { computed, toRefs } from 'vue';
import collectionMetricsQuery from '@/graphql/collection/queries/collectionMetrics.query.gql';
import { formatCurrency, formatNumber, formatPercent } from '@/utils/filters';
import { get } from 'lodash-es';
import { useRefetchQueries } from '@/composition/refetch-queries';
import MetricWidget from '../MetricWidget.vue';
import CurrencyDisplay from '../CurrencyDisplay.vue';
import UiGridItem from '../ui/UiGridItem.vue';
import UiCard from '../ui/UiCard.vue';

const props = defineProps(['collection', 'only', 'cols']);
const { collection, only, cols } = toRefs(props);

const {
  result: analyticsResult,
  refetch: refetchAnalytics,
  loading: isAnalyticsLoading,
} = useQuery(collectionMetricsQuery, () => ({ contractAddress: collection.value.id }));

const { isRefetching } = useRefetchQueries({
  analytics: refetchAnalytics,
  // 'chart-data': refetchChartData,
});
const analytics = computed(() => analyticsResult.value?.collection);

const isEnabled = computed(() => (key) => !only.value || !Array.isArray(only.value) || only.value.includes(key));

</script>
