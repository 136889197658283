<template>
  <UiDropdown
    v-if="filterConfig(item.key, 'inline', false) === false"
    ref="dropdown"
    :disabled="disabled !== false"
    hide-only
  >
    <template #toggle="{toggle, isOpen}">
      <UiButton
        variant="white"
        size="sm"
        :disabled="disabled !== false"
        :class="{'ring-2 ring-red-500' : !isOpen && !validateFilter(item), 'ring-2 ring-orange-500': isPinned(item) }"
        @click="toggle"
      >
        <div class="flex gap-2 items-center">
          <Component
            :is="filterConfig(item.key, 'displayComponent')"
            v-if="filterConfig(item.key, 'displayComponent', false)"
            :value="item"
          />

          <div
            v-else-if="isVariable(item.value) || isVariable(item.value?.value)"
            class="flex relative gap-1 items-center"
          >
            <div class="font-bold">
              {{ filterName(item.key) }}:
            </div>

            <div>
              <VariableValueBadge
                :disabled="true"
                :model-value="isVariable(item.value) ? item.value : item.value?.value"
              />
            </div>
          </div>

          <div
            v-else
            class="flex relative gap-1 items-center"
          >
            <div class="font-bold">
              {{ filterName(item.key) }}:
            </div>

            <div :class="{'italic': !validateFilter(item)}">
              {{ filterDisplayValue(item) }}
            </div>
          </div>

          <button
            type="button"
            class="text-gray-400 hover:text-gray-100 transition transition-color"
            @click.stop="removeFilter(item)"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </UiButton>
    </template>

    <template
      #body
    >
      <Component
        :is="filterComponent(item.type, item.key)"
        v-bind="filterProps(item.key)"
        :model-value="item.value"
        @update:modelValue="updateFilterValue({ item, value: $event })"
        @click.stop
      />

      <div class="flex gap-2 items-center pt-3 mt-3 border-t border-gray-800">
        <UiButton
          size="xs"
          :disabled="disabled !== false"
          variant="white"
          @click="removeFilter(item)"
        >
          Remove
        </UiButton>

        <UiButton
          v-if="validateFilter(item)"
          size="xs"
          variant="white"
          :disabled="disabled !== false"
          @click="pinFilter(item)"
        >
          <span v-if="!isPinned(item)">Pin</span>

          <span v-else>Unpin</span>
        </UiButton>

        <UiButton
          size="xs"
          variant="white"
          :disabled="disabled !== false"
          @click="convertToOr"
        >
          Or
        </UiButton>
      </div>
    </template>
  </UiDropdown>

  <UiButton
    v-if="filterConfig(item.key, 'inline', false) === true"
    variant="white"
    size="sm"
    :disabled="disabled !== false"
  >
    <div class="flex gap-3 items-center">
      <Component
        :is="filterComponent(item.type, item.key)"
        v-bind="filterProps(item.key)"
        :model-value="item.value"
        :name="filterName(item.key)"
        @update:modelValue="updateFilterValue({ item, value: $event })"
      />

      <button
        type="button"
        class="text-gray-400 hover:text-gray-100 transition transition-color"
        @click.stop="removeFilter(item)"
      >
        <i class="fas fa-times" />
      </button>
    </div>
  </UiButton>
</template>

<script setup>
import VariableValueBadge from '@/components/automation/VariableValueBadge.vue';
import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiButton from '@/components/ui/UiButton.vue';
import { FILTER_TYPES } from '@/constants/filters';
import {
  toRefs, ref, nextTick, computed,
} from 'vue';

const props = defineProps({
  item: null,
  filterConfig: null,
  validateFilter: null,
  filterProps: null,
  isPinned: null,
  filterName: null,
  filterDisplayValue: null,
  removeFilter: null,
  filterComponent: null,
  updateFilterValue: null,
  disabled: {
    type: Boolean,
    default: false,
  },
});

const {
  item,
  filterConfig,
  validateFilter,
  isPinned,
  filterName,
  filterProps,
  filterDisplayValue,
  removeFilter,
  filterComponent,
  updateFilterValue,
  disabled,
} = toRefs(props);
const dropdown = ref(null);
const toggle = () => {
  setTimeout(() => {
    dropdown.value.toggle();
  }, 50);
};

defineExpose({ toggle });

const convertToOr = () => {
  updateFilterValue.value({
    item: item.value, value: [{ ...item.value }], key: '$or', type: FILTER_TYPES.LOGICAL,
  });
};

const isVariable = computed(() => (value) => (typeof value === 'string' && value.startsWith('{{') && value.endsWith('}}')));
</script>
