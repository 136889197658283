<template>
  <div class="h-full">
    <UiCard
      is-overflow-auto
      class="flex flex-col h-full"
    >
      <div
        class="
          flex
          justify-between
          items-center
          py-3
          px-4
          border-b
          dark:border-gray-700
        "
      >
        <div class="flex flex-nowrap gap-1 items-center">
          <div class="flex gap-3 font-medium">
            {{ title }}
            <DelayedBadge :disabled="!collection.isPremiumRequired" />
          </div>

          <LoadingIndicator
            v-if="isLoading"
            :size="16"
          />

          <i
            v-else
            class="mt-1 text-xl leading-none text-yellow-500 opacity-0 transition-opacity fad fa-pause-circle"
            :class="{'opacity-100': isPaused}"
          />
        </div>

        <div
          v-if="noSort === false"
          class="flex items-center space-x-2"
        >
          <UiDropdown
            right
            body-width-class="w-64"
          >
            <template #toggle="{toggle}">
              <UiButton
                size="xs"
                variant="white"
                @click="toggle"
              >
                <div class="flex items-center space-x-1 leading-0">
                  <i class="far fa-filter" />

                  <span>Filter</span>

                  <span
                    v-if="hasFilters"
                    class="w-2 h-2 bg-indigo-500 rounded-full"
                  />
                </div>
              </UiButton>
            </template>

            <template #body>
              <UiDropdownForm>
                <div class="mb-3">
                  <div class="mb-2">
                    Price
                  </div>

                  <PremiumPlaceholder
                    :disabled="!collection.isPremiumRequired"
                    size="sm"
                    action="filter events by price"
                  >
                    <div class="grid grid-cols-2 gap-3">
                      <UiInputGroup label="Min">
                        <UiInput
                          v-model="filters.priceFrom"
                          type="number"
                        />
                      </UiInputGroup>

                      <UiInputGroup label="Max">
                        <UiInput
                          v-model="filters.priceTo"
                          type="number"
                        />
                      </UiInputGroup>
                    </div>
                  </PremiumPlaceholder>
                </div>

                <div>
                  <div class="mb-2">
                    Rarity
                  </div>

                  <PremiumPlaceholder
                    size="sm"
                    :disabled="!collection.isPremiumRequired"
                    action="filter by rarity"
                  >
                    <div class="grid grid-cols-2 gap-3">
                      <UiInputGroup label="Min">
                        <UiInput
                          v-model="filters.rarityFrom"
                          :disabled="!isRevealed"
                          type="number"
                        />
                      </UiInputGroup>

                      <UiInputGroup label="Max">
                        <UiInput
                          v-model="filters.rarityTo"
                          :disabled="!isRevealed"
                          type="number"
                        />
                      </UiInputGroup>
                    </div>
                  </PremiumPlaceholder>
                </div>

                <div>
                  <div class="mb-2">
                    Traits
                  </div>

                  <PremiumPlaceholder
                    size="sm"
                    action="filter by traits"
                    :disabled="!collection.isPremiumRequired"
                  >
                    <AttributeValuePicker
                      v-model:attribute-values-ids="filters.attributeValueIds"
                      :collection-address="collection.id"
                    />
                  </PremiumPlaceholder>
                </div>

                <UiButton
                  v-if="hasFilters"
                  size="xs"
                  variant="white"
                  @click="clearFilters"
                >
                  Clear
                </UiButton>
              </UiDropdownForm>
            </template>
          </UiDropdown>

          <UiSelect
            v-model="sortField"
            :options="sortOptions"
            size="sm"
            right
          >
            <template #toggle="{toggle}">
              <UiButton
                size="xs"
                variant="white"
                @click="toggle"
              >
                <div class="flex items-center space-x-1 leading-0">
                  <i class="fad fa-sort-alt" />

                  <span class="capitalize">{{ sortField.split('_')[0] }}</span>

                  <i
                    v-if="sortField.split('_')[1] === 'asc'"
                    class="fas fa-caret-up"
                  />

                  <i
                    v-else
                    class="fas fa-caret-down"
                  />
                </div>
              </UiButton>
            </template>
          </UiSelect>
        </div>
      </div>

      <TransitionGroup
        tag="div"
        name="events"
        class="
            overflow-y-scroll
            divide-y
            dark:divide-gray-700
            scrollbar-thin
            scrollbar-thumb-gray-700
            scrollbar-track-gray-800
            scrollbar-thumb-rounded-full
            scrollbar-track-rounded-full"
        @mouseover="pause()"
        @mouseleave="resume()"
      >
        <div
          v-for="item in orders"
          :key="`${item.token.tokenId}_${item.timestamp}`"
          class="
              block
              dark:hover:bg-gray-800
              hover:transition-colors
              cursor-pointer
            "
          @click="openTokenModal(item)"
        >
          <div class="flex justify-between items-center p-3 pr-4">
            <div class="flex">
              <div class="overflow-hidden mr-2 w-12 h-12 rounded">
                <TokenImage
                  :url="item.token.previewImageUrl"
                  :token="item.token"
                  :fallback="collection.imageUrl"
                  class="w-full h-auto"
                />
              </div>

              <div>
                <div class="flex gap-2 items-center">
                  <div
                    v-if="collection.id !== ENS_ADDRESS"
                    class="text-sm"
                  >
                    #{{ item.token.tokenId.slice(0, 20) }}{{ item.token.tokenId.length > 20 ? '...' : '' }}
                  </div>

                  <div v-else>
                    {{ item.token.name }}
                  </div>

                  <div
                    v-if="item.token.isReported"
                    v-tooltip="'Flagged on Opensea'"
                  >
                    <i class="text-red-500 fad fa-exclamation-triangle" />
                  </div>
                </div>

                <RarityRank
                  v-if="
                    item.token.rarityScore != '0' && item.token.rarityRank
                  "
                  class="mt-1 text-xs"
                  :supply="collection.totalSupply"
                  :rank="item.token.rarityRank"
                />
              </div>
            </div>

            <div class="flex flex-col items-end">
              <div
                class="flex items-center"
              >
                <div
                  v-if="item.pendingOrderFills && item.pendingOrderFills.length > 0"
                  v-tooltip="`${item.pendingOrderFills.length} pending transactions`"
                  class="inline-flex flex-grow-0 items-center py-0.5 px-1 mr-2 text-xs font-bold leading-none text-yellow-900 bg-yellow-400 rounded-full"
                  @click.stop="openPendingTransactions(item)"
                >
                  <LoadingIndicator
                    :size="10"
                    class="mr-1"
                  />

                  <span v-if="item.pendingOrderFills">{{ item.pendingOrderFills.length }}</span>

                  <span v-else>0</span>
                </div>

                <div class="mr-2">
                  <MarketplaceIcon :marketplace="item.marketplace" />
                </div>

                <div
                  :class="{
                    'text-green-500 hover:underline': type === TYPES.LISTINGS,
                  }"
                  @click.stop="buyItem(item)"
                >
                  <i
                    v-if="type === TYPES.LISTINGS"
                    class="mr-2 far fa-shopping-cart"
                  />

                  <CurrencyDisplay :value="item.value" />
                </div>
              </div>

              <div class="flex items-center space-x-2">
                <PurchaseTypeBadge
                  v-if="type === TYPES.SALES"
                  :type="item.saleType"
                  minimal
                />

                <ListingTypeBadge
                  v-if="type === TYPES.LISTINGS"
                  :type="item.listingType"
                  minimal
                />

                <Timestamp
                  auto-update
                  :timestamp="item.timestamp"
                  relative-only
                  relative-class="text-gray-500 dark:text-gray-400 whitespace-nowrap"
                />
              </div>
            </div>
          </div>
        </div>
      </TransitionGroup>
    </UiCard>
  </div>
</template>

<script>
import {
  defineComponent,
  toRefs,
  computed,
  reactive,
  inject,
} from 'vue';
import {
  useQuery,
  useSubscription,
  useApolloClient,
} from '@vue/apollo-composable';
import { uniqBy, orderBy } from 'lodash-es';
import { useRefetchQueries } from '@/composition/refetch-queries';
import collectionLatestOrdersQuery from '@/graphql/collection/queries/collectionLatestOrders.query.gql';
import collectionLatestSalesQuery from '@/graphql/collection/queries/collectionLatestSales.query.gql';
import collectionSalesFeedSubs from '@/graphql/collection/subscriptions/collectionSalesFeed.subscription.gql';
import collectionListingFeedSubs from '@/graphql/collection/subscriptions/collectionListingFeed.subscription.gql';
import collectionNewPendingOrderFillsSubs from '@/graphql/collection/subscriptions/collectionNewPendingOrderFills.subscription.gql';

import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import { usePremium } from '@/composition/premium';
import TokenImage from '@/components/TokenImage.vue';
import UiCard from '@/components/ui/UiCard.vue';
import CurrencyDisplay from '@/components/CurrencyDisplay.vue';
import Timestamp from '@/components/Timestamp.vue';
import PremiumPlaceholder from '@/components/PremiumPlaceholder.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import RarityRank from '@/components/RarityRank.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiInputGroup from '@/components/ui/UiInputGroup.vue';
import UiDropdownForm from '@/components/ui/UiDropdownForm.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import AttributeValuePicker from '@/components/AttributeValuePicker.vue';

import { ENS_ADDRESS } from '@/constants';
import useTokenModal from '@/composition/tokens/useTokenModal';
import MarketplaceIcon from '../MarketplaceIcon.vue';
import DelayedBadge from '../DelayedBadge.vue';
import PurchaseTypeBadge from '../PurchaseTypeBadge.vue';
import ListingTypeBadge from '../ListingTypeBadge.vue';

const TYPES = {
  LISTINGS: 'listings',
  SALES: 'sales',
};

const queries = {
  [TYPES.LISTINGS]: collectionLatestOrdersQuery,
  [TYPES.SALES]: collectionLatestSalesQuery,
};

const subscriptions = {
  [TYPES.LISTINGS]: collectionListingFeedSubs,
  [TYPES.SALES]: collectionSalesFeedSubs,
};

export default defineComponent({
  components: {
    TokenImage,
    UiCard,
    CurrencyDisplay,
    Timestamp,
    PremiumPlaceholder,
    UiButton,
    UiDropdown,
    UiSelect,
    RarityRank,
    UiInput,
    UiInputGroup,
    UiDropdownForm,
    LoadingIndicator,
    MarketplaceIcon,
    AttributeValuePicker,
    DelayedBadge,
    PurchaseTypeBadge,
    ListingTypeBadge,
  },

  props: {
    collection: null,
    type: {
      default: TYPES.LISTINGS,
      type: String,
    },
    noSort: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['newActivity'],

  setup(props, { emit }) {
    const {
      type,
      collection,
    } = toRefs(props);
    const isRevealed = inject('isRevealed');

    const state = reactive({
      sortField: 'date_desc',
      isPaused: false,
      hasIgnoredUpdates: false,
    });

    const filters = reactive({
      priceFrom: null,
      priceTo: null,
      rarityFrom: null,
      rarityTo: null,
      attributeValueIds: [],
    });

    const {
      result: latesOrdersResult,
      subscribeToMore,
      refetch: refetchLatesOrders,
      loading: isLoading,
    } = useQuery(
      queries[type.value],
      () => ({
        address: collection.value?.id,
        sortBy: state.sortField,
        priceFrom: filters.priceFrom ? parseFloat(filters.priceFrom) : undefined,
        priceTo: filters.priceTo ? parseFloat(filters.priceTo) : undefined,
        rarityFrom: filters.rarityFrom ? parseInt(filters.rarityFrom) : undefined,
        rarityTo: filters.rarityTo ? parseInt(filters.rarityTo) : undefined,
        attributeValueIds: filters.attributeValueIds,
      }),
      () => ({
        fetchPolicy: 'cache-and-network',
        debounce: 300,
        enabled: collection.value?.id,
      }),
    );
    const latestOrders = computed(() => latesOrdersResult.value?.collection?.events);

    const queriesToRefetch = {};
    queriesToRefetch[`latest-events-${type.value}`] = refetchLatesOrders;

    const {
      isRefetchDisabled,
    } = useRefetchQueries(queriesToRefetch);

    const { isPremium } = usePremium();

    subscribeToMore(() => ({
      document: subscriptions[type.value],
      variables: {
        address: collection.value?.id,
      },
      updateQuery(previousResult, { subscriptionData }) {
        if (!isPremium.value && collection.value.isPremiumRequired) {
          return previousResult;
        }

        if (!previousResult) {
          return previousResult;
        }

        emit('newActivity', subscriptionData.data.events);

        if (state.isPaused) {
          state.hasIgnoredUpdates = true;
          return previousResult;
        }

        const data = JSON.parse(JSON.stringify(previousResult));

        const filteredSubsData = subscriptionData.data.events.filter((item) => {
          if (filters.priceFrom && item.value < filters.priceFrom) {
            return false;
          }

          if (filters.priceTo && item.value > filters.priceTo) {
            return false;
          }

          if (filters.rarityFrom && item.token.rarityRank < filters.rarityFrom) {
            return false;
          }

          if (filters.rarityTo && item.token.rarityRank > filters.rarityTo) {
            return false;
          }

          return true;
        });

        data.collection.events = [
          ...filteredSubsData,
          ...(data.collection.events || []),
        ];

        return data;
      },
    }));

    const { onResult } = useSubscription(
      collectionNewPendingOrderFillsSubs,
      () => ({
        collectionId: collection.value?.id,
      }),
      () => ({
        enabled: type.value === TYPES.LISTINGS,
      }),
    );

    const { resolveClient } = useApolloClient('default');

    onResult(({ data }) => {
      const apolloClient = resolveClient();
      const queryVariables = {
        address: collection.value?.id,
        sortBy: state.sortField,
        priceFrom: filters.priceFrom ? parseFloat(filters.priceFrom) : undefined,
        priceTo: filters.priceTo ? parseFloat(filters.priceTo) : undefined,
        rarityFrom: filters.rarityFrom ? parseInt(filters.rarityFrom) : undefined,
        rarityTo: filters.rarityTo ? parseInt(filters.rarityTo) : undefined,
        attributeValueIds: filters.attributeValueIds || [],
      };

      const queryParams = {
        query: queries[type.value],
        variables: { ...queryVariables },
      };

      apolloClient.cache.updateQuery(queryParams, (cachedData) => {
        if (!cachedData) {
          return cachedData;
        }

        const fill = data.pendingFill;
        const events = cachedData.collection.events.map((item) => {
          const model = JSON.parse(JSON.stringify(item));
          if (model.id !== fill.orderHash) {
            return model;
          }
          model.pendingOrderFills.push(fill);
          return model;
        });

        return {
          collection: {
            ...cachedData.collection,
            events,
          },
        };
      });
    });

    const { open } = useTokenModal();

    const buyItem = async (item) => {
      if (type.value !== TYPES.LISTINGS) {
        return;
      }
      open(item.token.id, { buyIntent: true });
    };

    const openPendingTransactions = (item) => {
      open(item.token.id, { accordion: 'Pending Transactions' });
    };

    const openTokenModal = (item) => {
      open(item.token.id);
    };

    return {
      ...toRefs(state),
      filters,
      latestOrders,
      refetchLatesOrders,
      isRefetchDisabled,
      TYPES,
      isRevealed,
      isLoading,
      ENS_ADDRESS,
      buyItem,
      openPendingTransactions,
      openTokenModal,
    };
  },

  data() {
    return {
      sortMap: {
        date: {
          key: 'timestamp',
          direction: 'desc',
        },
        price: {
          key: 'value',
          direction: 'asc',
        },
        rarity: {
          key: 'token.rarityRank',
          direction: 'asc',
        },
      },
      availableSortOptions: [
        { value: 'date_desc', text: 'Date: new → old' },
        { value: 'date_asc', text: 'Date: old → new' },
        { value: 'price_desc', text: 'Price: high → low' },
        { value: 'price_asc', text: 'Price: low → high' },
        { value: 'rarity_asc', text: 'Rarity: rare → common' },
        { value: 'rarity_desc', text: 'Rarity: common → rare' },
      ],
      buffer: null,
    };
  },
  computed: {
    orders() {
      const [sortField, direction] = this.sortField.split('_');

      return orderBy(uniqBy(this.latestOrders || [], (item) => `${item.token.id}_${item.timestamp}`), [this.sortMap[sortField].key, 'token.tokenId'], [direction, 'asc']);
    },
    title() {
      const str = this.type;

      return `${str[0].toUpperCase()}${str.slice(1)}`;
    },
    hasFilters() {
      return Object.keys(this.filters).reduce((p, i) => p || (Array.isArray(this.filters[i]) ? this.filters[i].length : !!this.filters[i]), false);
    },
    sortOptions() {
      if (this.isRevealed) {
        return this.availableSortOptions;
      }
      const rarityStr = 'rarity';

      return this.availableSortOptions.filter((opt) => !opt.value.startsWith(rarityStr));
    },
  },
  methods: {
    clearFilters() {
      this.filters.priceFrom = null;
      this.filters.priceTo = null;
      this.filters.rarityFrom = null;
      this.filters.rarityTo = null;
      this.filters.attributeValueIds = [];
    },
    pause() {
      this.isPaused = true;
      this.isRefetchDisabled = true;
    },
    resume() {
      // we need to update query manually
      this.isPaused = false;
      this.isRefetchDisabled = false;
      if (this.hasIgnoredUpdates) {
        this.hasIgnoredUpdates = false;
        this.refetchLatesOrders();
      }
    },
  },
});
</script>

<style>
.events-move, /* apply transition to moving elements */
.events-enter-active,
.events-leave-active {
  transition: background-color 0.35s ease;
}

.events-enter-from {
  background-color: rgba(255,255,255,0.5);
}

</style>
