<template>
  <div class=" py-3 mb-3 bg-gray-950">
    <div class="mx-auto max-w-screen-2xl">
      <Filters
        v-model="filters"
        :filters="availableFilters"
        group="collection"
      >
        <template #before-filters>
          <SweepCollectionTokensModal :collection-id="collectionId" />

          <UiSelectCustom
            v-model="orderOptions"
            :prepend="`Sort by `"
            :options="[
              {name: 'Rarity', id: {orderBy: 'rarity', orderDesc: false}},
              {name: 'Rarity', id: {orderBy: 'rarity', orderDesc: true}},
              {name: 'Token ID', id: {orderBy: 'tokenId', orderDesc: false}},
              {name: 'Token ID', id: {orderBy: 'tokenId', orderDesc: true}},
              {name: 'Price', id: {orderBy: 'price', orderDesc: false}},
              {name: 'Price', id: {orderBy: 'price', orderDesc: true}},
            ]"
            size="sm"
          >
            <template #default="{selected}">
              <i
                class="mr-2 far"
                :class="{'fa-sort-amount-up-alt': !orderOptions.orderDesc, 'fa-sort-amount-down': orderOptions.orderDesc}"
              />
              {{ selected.name }}
            </template>

            <template #option="{item}">
              <div class="flex gap-2 items-center whitespace-nowrap">
                <i
                  class="mr-2 far"
                  :class="{'fa-sort-amount-up-alt': !item.id.orderDesc, 'fa-sort-amount-down': item.id.orderDesc}"
                />
                {{ item.name }}
              </div>
            </template>
          </UiSelectCustom>
        </template>
      </Filters>
    </div>
  </div>

  <div class="mx-auto max-w-screen-4xl">
    <div
      v-if="!isLoading && tokens.length === 0"
      class="flex flex-1 justify-center items-center text-2xl font-light text-gray-500"
    >
      <div class="py-16">
        No tokens
      </div>
    </div>

    <TokenGrid v-if="isLoading || (!isLoading && tokens.length > 0)">
      <GridListItem
        v-for="(item) in tokens"
        :key="item.id"
        :item="item"
        :collection="item.collection"
        :show-collection="false"
        @showDetails="() => showTokenDetails(item.id)"
      >
        <template #collection-context>
          <OverlayFilterContext
            @exclude="filters.push({key:'collectionId', type: 'collection', value: { comparator: 'nin', value: [item.collection.id] }})"
            @only="filters.push({key:'collectionId', type: 'collection', value: { comparator: 'in', value: [item.collection.id] }})"
          />
        </template>
      </GridListItem>

      <div
        v-for="i in loadingItems"
        :key="i"
      >
        <GridListItem
          :is-loading="true"
          show-collection
          hide-wallet
        />
      </div>
    </TokenGrid>
  </div>

  <IntersectionObserver
    v-if="!isLoading && !isLoadingMore && hasMore"
    class="relative -top-96 pointer-events-none"
    @isShow="loadMore"
  />
</template>

<script setup>
import { COLLECTION_LIST_ITEM_FIELDS } from '@/graphql/collection/fragments/collectionListItemFields.fragment';
import { TOKEN_FIELDS } from '@/graphql/token/fragments/tokenFields.fragment';
import gql from 'graphql-tag';
import {
  computed, reactive, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { usePremium } from '@/composition/premium';
import usePaginatedQuery from '@/composition/pagination/usePaginatedQuery';
import { useFiltersQuerySync } from '@/composition/filters/useFiltersQuerySync';
import useTokenModal from '@/composition/tokens/useTokenModal';
import { FILTERS, FILTER_TYPES } from '@/constants/filters';
import GridListItem from './GridListItem.vue';
import IntersectionObserver from '../IntersectionObserver.vue';
import Filters from '../filters/Filters.vue';
import OverlayFilterContext from '../OverlayFilterContext.vue';
import UiSelectCustom from '../ui/UiSelectCustom.vue';
import AttributeFilter from '../filters/AttributeFilter.vue';
import AttributeFilterDisplay from '../filters/display/AttributeFilterDisplay.vue';
import NumericTraitFilterDisplay from '../filters/display/NumericTraitFilterDisplay.vue';
import SweepCollectionTokensModal from '../tokens/SweepCollectionTokensModal.vue';
import TokenGrid from './TokenGrid.vue';
import NumericTraitFilter from '../filters/NumericTraitFilter.vue';

const query = gql`
  query GetCollectionPortfolioTokens($collectionId: String!, $take: Int, $skip: Int, $filters: [FilterArg!], $orderBy: String, $orderDesc: Boolean) {
    collection(where: {id: $collectionId}) {
      id
      tokens: tokensV2(take: $take, skip: $skip, filters: $filters, orderBy: $orderBy, orderDesc: $orderDesc) {
        ...Token_Fields
        price
        collection {
          ...CollectionListItem_Fields
        }
        lastSale {
          id
          price
        }
        isReported
      }
    }
  }
  ${TOKEN_FIELDS}
  ${COLLECTION_LIST_ITEM_FIELDS}
`;

const route = useRoute();
const collectionId = computed(() => route.params.contractAddress);

const paginationData = reactive({
  skip: 0,
  take: 28,
});
const availableFilters = computed(() => [...FILTERS.COLLECTION_TOKENS, {
  key: 'attributeValueId',
  type: FILTER_TYPES.CATEGORY,
  name: 'Trait',
  component: AttributeFilter,
  displayComponent: AttributeFilterDisplay,
  props: {
    collectionId: collectionId.value,
    multiple: true,
  },
}, {
  key: 'numericTrait',
  type: FILTER_TYPES.NUMERIC_TRAIT,
  name: 'Numeric Trait',
  component: NumericTraitFilter,
  displayComponent: NumericTraitFilterDisplay,
  props: {
    collectionId: collectionId.value,
  },
}]);
const filters = ref([]);
useFiltersQuerySync(filters);

const orderOptions = ref({
  orderBy: 'tokenId',
  orderDesc: false,
});
const variables = computed(() => ({
  collectionId: collectionId.value,
  filters: filters.value,
  ...orderOptions.value,
}));

const {
  result,
  loading: isLoading,
  loadMore: fetchMore,
  hasMore,
  loadingMore: isLoadingMore,
} = usePaginatedQuery({
  take: paginationData.take,
  hasMoreCheck: (data) => data.collection.tokens.length === paginationData.take,
  mergeResults: (oldData, newData) => {
    const data = JSON.parse(JSON.stringify(oldData));
    data.collection.tokens = [
      ...data.collection.tokens,
      ...newData.collection.tokens,
    ];

    return data;
  },
}, query, variables);

const tokens = computed(() => result.value?.collection?.tokens || []);

const loadingItems = computed(() => (isLoading.value ? 28 : 0));

const { requirePremium } = usePremium();
async function loadMore() {
  await requirePremium('to view all tokens');

  return fetchMore();
}

const { open } = useTokenModal();
function showTokenDetails(tokenId, withBuyIntent = false) {
  open(tokenId);
}

</script>
