<template>
  <UiSelectCustom
    v-model="value"
    :options="options"
  />
</template>

<script setup>
import useAutomationFolders from '@/composition/automation/use-automation-folders';
import { computed } from 'vue';
import UiSelectCustom from '../ui/UiSelectCustom.vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const { automationFolders } = useAutomationFolders();
const options = computed(() => [
  { value: null, name: 'Home' },
  ...automationFolders.value.map((folder) => ({
    id: folder.id,
    name: folder.name,
  })),
]);
</script>
