<template>
  <Component
    :is="tag"
    v-bind="$attrs"
    type="submit"
    :disabled="isDisabled"
    class="whitespace-nowrap transition-all transform appearance-none active"
    :class="[
      variant !== 'none' ? classes : '',
      {'font-medium shadow-sm border border-white border-opacity-10 active:shadow-inner': variant !== 'none'},
      block !== false ? 'w-full' : '',
      isDisabled ? 'opacity-75 cursor-default ' : 'active:translate-y-0.5',
    ]"
  >
    <span
      v-show="isLoading"
      class="absolute"
    ><LoadingIndicator
      :size="16"
      color="#fff"
    /></span>

    <span
      :class="{'opacity-0': isLoading}"
      class="inline-flex justify-center items-center w-full"
    ><slot /></span>
  </Component>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue';

const sizes = {
  xs: 'px-2 py-1 text-xs rounded-md',
  sm: 'px-3 py-1.5 text-sm rounded-lg',
  md: 'px-4 py-2 text-sm rounded-lg',
  lg: 'px-4 py-2 text-base rounded-xl',
  xl: 'px-6 py-3 text-base rounded-2xl text-lg',
  'xl2-responsive': 'px-6 md:px-8 py-4 md:py-6 text-base md:text-xl rounded-lg',
};

const variants = {
  'primary-reverse': {
    base: 'text-indigo-500 bg-indigo-900 bg-opacity-70 focus:outline-none',
    active: 'focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-opacity-90',
  },
  primary: {
    base: 'text-indigo-1000 bg-indigo-500 focus:outline-none',
    active: ' hover:bg-indigo-400',
  },
  blue: {
    base: 'text-white bg-blue-600',
    active: 'hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
  },
  green: {
    base: 'text-white bg-green-600 ',
    active: 'hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500',
  },
  red: {
    base: 'text-white bg-red-600 ',
    active: 'hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
  },
  gray: {
    base: 'bg-gray-200 text-gray-800 ',
    active: 'hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
  },
  secondary: {
    base: 'text-indigo-700 bg-indigo-100',
    active: 'hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
  },
  third: {
    base: 'bg-indigo-900 text-indigo-500',
    active: 'hover:bg-indigo-500 hover:text-gray-1000 focus:outline-none',
  },
  white: {
    base: 'dark:text-gray-300 text-gray-700 bg-white dark:bg-gray-700 dark:bg-opacity-70 border dark:border-gray-700 outline-none dark:active:bg-gray-750',
    active: 'hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none ',
  },
  'light-yellow': {
    base: 'dark:text-yellow-900 dark:bg-yellow-400 dark:bg-opacity-70',
    active: 'hover:bg-yellow-50 dark:hover:bg-yellow-300 focus:outline-none focus:ring-2',
  },
};

export default {
  name: 'UiButton',
  components: { LoadingIndicator },
  props: {
    size: {
      default: 'md',
    },
    variant: {
      default: 'primary',
    },
    block: {
      default: false,
    },
    isLoading: {
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
    },
    disabled: null,
  },
  computed: {
    classes() {
      return `${sizes[this.size]} ${variants[this.variant]?.base} ${this.isDisabled ? '' : variants[this.variant]?.active}`;
    },
    isDisabled() {
      return this.disabled || this.isLoading;
    },
  },
};
</script>

<style scoped>

</style>
