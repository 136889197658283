<template>
  <div>
    <div
      class="flex gap-2 items-center text-gray-200 hover:text-white transition-colors cursor-pointer select-none"
      @click="isVisible = !isVisible"
    >
      <div class="w-4 text-sm text-center text-gray-400">
        <i
          v-if="!isVisible"
          class="fas fa-chevron-right"
        />

        <i
          v-else
          class="fas fa-chevron-down"
        />
      </div>

      <slot name="title">
        <div>
          {{ title }}
        </div>
      </slot>
    </div>

    <div
      v-if="isVisible"
      class="mt-3"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});
const { title } = toRefs(props);
const isVisible = ref(false);
</script>
