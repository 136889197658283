<template>
  <div>
    <UiSelectCustom
      v-model="traitId"
      :options="traitTypes"
      class="mb-3"
    />

    <NumberFilter v-model="numberFilterModel" />
  </div>
</template>

<script setup>

import {
  toRefs, ref, computed, watch,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { isEqual } from 'lodash-es';
import NumberFilter from './NumberFilter.vue';
import UiSelectCustom from '../ui/UiSelectCustom.vue';

const props = defineProps(['modelValue', 'allowVariables', 'actionId', 'collectionId']);
const {
  modelValue, collectionId,
} = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const { result } = useQuery(gql`
  query GetCollectionTraitTypes($collectionId: String!) {
    collection(where: {id: $collectionId}) {
      id
      traitTypes {
        id
        name
      }
    }
  }
`, () => ({ collectionId: collectionId.value }), () => ({
  enabled: !!collectionId.value,
}));

const traitTypes = computed(() => result.value?.collection?.traitTypes || []);

const traitId = ref(modelValue.value?.traitId || null);
const numberFilterModel = ref({
  comparator: modelValue.value?.comparator || 'eq',
  value: modelValue.value?.value || null,
});

watch(modelValue, (v, oldValue) => {
  if (isEqual(v, oldValue)) {
    return;
  }

  traitId.value = v?.traitId || null;
  numberFilterModel.value = {
    comparator: v?.comparator || 'eq',
    value: v?.value || null,
  };
}, { deep: true });

watch([traitId, numberFilterModel], ([t, v]) => {
  emit('update:modelValue', {
    traitId: t,
    comparator: v?.comparator || 'eq',
    value: v?.value || null,
  });
});

</script>
