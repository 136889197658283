<template>
  <ActionInputMapper
    v-model="value"
    :inputs="action.inputs"
    :trigger="trigger"
  >
    <template #input(conditions)="{model, updateModel, addVariable}">
      <div>
        <div>
          <UiInputGroup label="Field">
            <UiInput
              v-if="!isVariable(model?.[0]?.key)"
              placeholder="Field"
              :model-value="model?.[0]?.key"
              :disabled="readonly !== false"
              @update:modelValue="updateModel([{...model?.[0], key: $event}])"
            />

            <div v-else>
              <VariableDisplay
                :model-value="model?.[0]?.key"
                :disabled="readonly !== false"
                @update:modelValue="updateModel([{...model?.[0], key: $event}])"
              />
            </div>

            <template
              v-if="readonly === false"
              #label="{label}"
            >
              <div class="flex gap-1 items-center">
                <div>{{ label }}</div>

                <button
                  class="relative active:top-px px-1 text-xs font-medium text-gray-300 bg-gray-700 hover:bg-gray-600 rounded-md transition-colors"
                  @click="addVariable(false, (value) => updateModel([{...model?.[0], key: `{{ ${value} }}`}]), { type: model?.[0]?.type ? displayName(model?.[0]?.type) : 'String' })"
                >
                  <i class="fas fa-plus" />
                  Use variable
                </button>
              </div>
            </template>
          </UiInputGroup>
        </div>

        <div>
          <UiInputGroup label="Comparator">
            <UiSelectCustom
              class="w-full"
              :options="COMPARATOR_OPTIONS"
              :disabled="readonly !== false"
              :model-value="`${model?.[0]?.type}:${model?.[0]?.value?.comparator}`"
              @update:modelValue="updateModel([{...model?.[0], type: $event.split(':')[0], value: { ...model?.[0]?.value, comparator: $event.split(':')[1] }}])"
            />
          </UiInputGroup>
        </div>

        <div v-if="model?.[0]?.type !== 'boolean'">
          <UiInputGroup label="Value">
            <UiInput
              v-if="!isVariable(model?.[0]?.value?.value)"
              placeholder="Value"
              :disabled="readonly !== false"
              :model-value="model?.[0]?.value?.value"
              @update:modelValue="updateModel([{...model?.[0], value: { ...model?.[0]?.value, value: $event}}])"
            />

            <div v-else>
              <VariableDisplay
                :model-value="model?.[0]?.value?.value"
                :disabled="readonly !== false"
                @update:modelValue="updateModel([{...model?.[0], value: { ...model?.[0]?.value, value: $event}}])"
              />
            </div>

            <template
              v-if="readonly === false"
              #label="{label}"
            >
              <div class="flex gap-1 items-center">
                <div>{{ label }}</div>

                <button
                  class="relative active:top-px px-1 text-xs font-medium text-gray-300 bg-gray-700 hover:bg-gray-600 rounded-md transition-colors"
                  @click="addVariable(false, (value) => updateModel([{...model?.[0], value: { ...model?.[0]?.value, value: `{{ ${value} }}`}}]), { type: model?.[0]?.type ? displayName(model?.[0]?.type) : 'String' })"
                >
                  <i class="fas fa-plus" />
                  Use variable
                </button>
              </div>
            </template>
          </UiInputGroup>
        </div>
      </div>
    </template>
  </ActionInputMapper>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import UiInputGroup from '@/components/ui/UiInputGroup.vue';
import UiSelectCustom from '@/components/ui/UiSelectCustom.vue';
import UiInput from '@/components/ui/UiInput.vue';
import { displayName } from '@/utils/filters';
import ActionInputMapper from '../ActionInputMapper.vue';
import VariableDisplay from '../VariableDisplay.vue';

const props = defineProps({
  modelValue: null,
  action: null,
  trigger: null,
  readonly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const {
  modelValue, action, trigger,
  readonly,
} = toRefs(props);

const value = computed({
  get() {
    return modelValue.value || {
      items: '',
      actions: [],
    };
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const COMPARATOR_OPTIONS = [
  {
    id: 'number:eq',
    name: '(Number) Equal',
  },
  {
    id: 'number:neq',
    name: '(Number) Not Equal',
  },
  {
    id: 'number:gt',
    name: '(Number) Greater Than',
  },
  {
    id: 'number:lt',
    name: '(Number) Less Than',
  },
  {
    id: 'number:gte',
    name: '(Number) Greater Than or Equal',
  },
  {
    id: 'number:lte',
    name: '(Number) Less Than or Equal',
  },
  {
    id: 'boolean:true',
    name: '(Boolean) True',
  },
  {
    id: 'boolean:false',
    name: '(Boolean) False',
  },
  {
    id: 'string:eq',
    name: '(String) Equal',
  },
  {
    id: 'string:neq',
    name: '(String) Not Equal',
  },
  {
    id: 'string:contains',
    name: '(String) Contains',
  },
  {
    id: 'string:notContains',
    name: '(String) Not Contains',
  },
  {
    id: 'string:startsWith',
    name: '(String) Starts With',
  },
  {
    id: 'string:endsWith',
    name: '(String) Ends With',
  },
];

const isVariable = computed(() => (v) => v?.startsWith?.('{{') && v?.endsWith?.('}}'));
</script>
