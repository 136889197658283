<template>
  <div
    class="
      flex
      justify-center
      items-center
      leading-none
      bg-indigo-500
      rounded-full
      cursor-pointer
      text-indigo-1000 text-shadow
    "
    :class="{
      'w-12 h-12 text-2xl': size === 'md',
      'w-10 h-10 text-lg': size === 'sm',
      'w-8 h-8 text-base': size === 'xs'
    }"
  >
    <i class="fas fa-lock-alt" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import eventBus from '@/utils/event-bus';

export default defineComponent({
  props: {
    size: {
      default: 'md',
      type: String,
    },
  },

  setup() {
    return {
      eventBus,
    };
  },
});
</script>

<style>
</style>
