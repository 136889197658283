<template>
  <div>
    <ResourcePicker
      v-if="typeof collectionIdHint === 'undefined'"
      v-model="collectionId"
      only="collection"
      class="mb-3"
    />

    <div
      :class="[pickerClass, { 'animate-pulse': loading || isLoadingTraits }]"
    >
      <UiSelectCustom
        v-model="selectedTraitId"
        prepend="Type:"
        :options="traitOptions"
        :disabled="!collectionId"
        :inline-dropdown="inlineDropdown"
      />

      <UiSelectCustom
        v-if="selectedTraitId"
        v-model="model"
        prepend="Value:"
        :options="traitValueOptions"
        :disabled="!selectedTraitId"
        :inline-dropdown="inlineDropdown"
      />
    </div>
  </div>
</template>

<script setup>
import useTraits from '@/composition/tokens/useTraits';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import {
  ref, computed, toRefs, onMounted, watch,
} from 'vue';
import ResourcePicker from './ResourcePicker.vue';
import UiSelectCustom from './ui/UiSelectCustom.vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  collectionId: null,
  inlineDropdown: {
    type: Boolean,
    default: false,
  },
  pickerClass: {
    type: String,
    default: 'flex gap-3',
  },
});

const emit = defineEmits(['update:modelValue']);

const { modelValue, inlineDropdown, collectionId: collectionIdHint } = toRefs(props);

const model = computed({
  get() {
    return modelValue.value;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const collectionId = ref(null);
const selectedTraitId = ref(null);

watch(() => collectionIdHint.value, (val) => {
  collectionId.value = val;
}, {
  immediate: true,
});

const { result, onResult, loading } = useQuery(gql`
query GetAttribute($id: String!) {
  attributeValues(where: {id: {equals: $id}}) {
    id
    attribute {
      id
      collectionId
    }
  }
}
`, () => ({ id: model.value }), {
  enabled: computed(() => !!model.value),
});

const initFromModel = () => {
  if (!collectionId.value) {
    collectionId.value = result.value?.attributeValues[0]?.attribute.collectionId;
  }

  if (!selectedTraitId.value) {
    selectedTraitId.value = result.value?.attributeValues[0]?.attribute.id;
  }
};

onMounted(() => {
  initFromModel();
});

onResult(() => {
  initFromModel();
});

const { traits } = useTraits(collectionId);

const selectedTrait = computed(() => {
  if (!selectedTraitId.value) {
    return null;
  }

  return traits.value.find((t) => t.id === selectedTraitId.value);
});

const traitOptions = computed(() => [
  {
    id: null,
    name: 'None',
  },
  ...(traits.value?.map((t) => ({
    id: t.id,
    name: t.name,
  })) || []),
]);

const traitValueOptions = computed(() => [
  {
    id: null,
    name: 'None',
  },
  ...(selectedTrait.value?.values.map((v) => ({
    id: v.id,
    name: v.value,
  })) || []),
]);
</script>
