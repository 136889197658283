<template>
  <div class="contents">
    <slot v-if="showContent" />

    <slot
      v-else-if="isLoggedIn"
      name="placeholder-content"
    >
      <div
        class="flex flex-col justify-center mx-auto max-w-xs h-full text-center"
        :class="{
          'py-2 px-2 md:py-4': size === 'sm',
          'py-8 px-3 md:py-12': size === 'md',
        }"
      >
        <div
          class="flex flex-row justify-center items-center"
          :class="{'gap-3 mb-2': size === 'sm', 'gap-6 mb-4': size === 'md'}"
        >
          <div>
            <PremiumIcon
              :size="size"
            />
          </div>

          <div
            class="font-medium dark:text-gray-100"
            :class="{
              'text-lg': size === 'sm',
              'text-2xl': size === 'md',
            }"
          >
            Get Premium
          </div>
        </div>

        <div
          class="text-sm dark:text-gray-300"
          :class="{
            'mb-2': size === 'sm',
            'mb-4': size === 'md',
          }"
        >
          <span class="capitalize">{{ action }}</span> with Compass Premium.
        </div>

        <div class="flex justify-center items-center">
          <UiButton
            :size="size === 'md' ? 'lg' : size"
            variant="primary"
            @click="() => eventBus.$emit('auth:showPremiumModal', `to ${action}`)"
          >
            Start Free Trial
          </UiButton>
        </div>
      </div>
    </slot>

    <slot
      v-else-if="!isLoggedIn"
      name="placeholder-content-guest"
    >
      <slot name="placeholder-content">
        <div
          class="flex flex-col justify-center mx-auto max-w-sm h-full text-center"
          :class="{
            'py-2 px-2 md:py-4': size === 'sm',
            'py-8 px-3 md:py-12': size === 'md',
          }"
        >
          <div>
            <PremiumIcon
              class="mx-auto mb-4"
              :size="size"
            />
          </div>

          <div
            class="font-medium dark:text-gray-100"
            :class="{
              'mb-1 text-lg': size === 'sm',
              'mb-2 text-2xl': size === 'md',
            }"
          >
            Authenticate
          </div>

          <div
            class="text-sm dark:text-gray-300"
            :class="{
              'mb-2': size === 'sm',
              'mb-4': size === 'md',
            }"
          >
            To {{ action }} you need to sign in and validate your premium
            access.
          </div>

          <div class="flex justify-center items-center">
            <UiButton
              :size="size === 'md' ? 'lg' : size"
              variant="primary"
              @click="() => eventBus.$emit('auth:signIn', `to ${action}`)"
            >
              Sign In
            </UiButton>
          </div>
        </div>
      </slot>
    </slot>
  </div>
</template>

<script>
import eventBus from '@/utils/event-bus';
import PremiumIcon from './PremiumIcon.vue';
import UiButton from './ui/UiButton.vue';

export default {
  components: { UiButton, PremiumIcon },
  props: {
    size: {
      default: 'md',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    horizontal: {
      default: false,
      type: Boolean,
    },
    action: {
      default: 'unlock this alpha',
      type: String,
    },
  },

  data() {
    return {
      eventBus,
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
    showContent() {
      return this.disabled !== false || this.$store.getters['auth/isPremium'] === true;
    },
  },
};
</script>

<style>
</style>
