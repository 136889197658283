<template>
  <div
    v-for="item in options"
    :key="item.key"
    class="flex justify-between items-center py-2.5 px-3 hover:bg-gray-750 rounded cursor-pointer event-type-selector__option"
  >
    <div class="leading-none text-gray-100">
      {{ item.name }}
    </div>

    <div class="flex gap-2">
      <div
        class="text-xs font-medium text-gray-400 hover:underline event-type-selector__option-only-btn"
        @click.stop="selectOnly(item.key)"
      >
        Only
      </div>

      <UiToggle
        size="sm"
        :model-value="values.get(item.key)"
        @update:modelValue="updateValue(item.key, $event)"
        @click.stop
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed, toRefs,
} from 'vue';
import UiToggle from './UiToggle.vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default() {
      return [];
    },
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
});

const { modelValue, options } = toRefs(props);
const emit = defineEmits(['update:modelValue']);

/**
 * Supported event types and their config
 */

/**
 * Computed values
 */
const values = computed(() => options.value.reduce((acc, item) => {
  acc.set(item.key, !!modelValue.value?.includes(item.key));
  return acc;
}, new Map()));

const updateValue = (key, value) => {
  const data = new Map(values.value.entries());
  data.set(key, value);
  emit('update:modelValue', Array.from(data.entries()).reduce((arr, [k, v]) => {
    if (v) {
      arr.push(k);
    }

    return arr;
  }, []));
};

/**
 * Select only one event type
 */
const selectOnly = (item) => {
  emit('update:modelValue', [item]);
};
</script>

<style>
.event-type-selector__option .event-type-selector__option-only-btn {
  display: none;
}
.event-type-selector__option:hover .event-type-selector__option-only-btn {
  display: block;
}
</style>
