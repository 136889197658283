<template>
  <UiCard title="Total ETH Spent on Mints">
    <template #title-right>
      <PeriodSelector
        v-model="period"
        :periods="['7d', '30d', '90d', '180d', '1y', '2y']"
        :max-enabled="5 * 365 * 24 * 60 * 60"
      />
    </template>

    <UiCardBody>
      <Chart
        :options="chartOptions"
        :is-loading="loading"
        watermark
      />
    </UiCardBody>
  </UiCard>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { ref, computed } from 'vue';
import moment from 'moment';
import { formatCurrency } from '@/utils/filters';
import UiCard from '../ui/UiCard.vue';
import PeriodSelector from '../PeriodSelector.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import Chart, { createTooltip } from '../Chart.vue';

const period = ref('1y');
const isStacked = ref(true);

const query = gql`
query GetTotalValueSpentOnMints($period: String!){
  totalValueMintedOverTime(period: $period)
}
`;

const {
  result,
  loading,
} = useQuery(query, () => ({ period: period.value }));

const chartOptions = computed(() => ({
  chart: {
    zoomType: 'x',
  },
  xAxis: {
    type: 'datetime',
  },
  tooltip: createTooltip({
    formatters: {
      Value: (value) => formatCurrency(value, 'ETH', 2),
      Gas: (value) => formatCurrency(value, 'ETH', 2),
    },
  }),
  plotOptions: {
    column: {
      stacking: true,
      dataLabels: {
        enabled: false,
      },
    },
  },
  yAxis: [
    { title: 'Value' },
    { title: 'Gas', opposite: true },
  ],
  legend: {
    enabled: true,
  },
  series: [
    {
      data: result.value?.totalValueMintedOverTime?.map((item) => [
        parseInt(moment(item.timestamp).format('x')),
        item.totalMintValue,
      ]) || [],
      type: 'column',
      name: 'Value',
    },
    {
      data: result.value?.totalValueMintedOverTime?.map((item) => [
        parseInt(moment(item.timestamp).format('x')),
        item.totalGasSpendOnMints,
      ]) || [],
      type: 'spline',
      name: 'Gas',
      yAxis: 1,
    },
  ],
}));

</script>
