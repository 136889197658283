<template>
  <UiCard
    title="Recent Bets"
  >
    <template #title-right>
      <PeriodSelector
        v-model="period"
        :periods="['1d', '7d', '14d', '30d', '90d', '180d']"
      />
    </template>

    <PremiumPlaceholder>
      <UiTable
        v-model:sort-by="sortBy"
        v-model:sort-desc="sortDesc"
        no-shadow
        :items="tableData"
        :fields="['collection', 'positionChange', 'tokenCountChange', 'actions']"
        :sortable="['positionChange', 'tokenCountChange']"
        :is-loading="loading && !loadingMore"
        class="overflow-y-auto h-full max-h-112 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
        sticky-class="top-0"
        :skeletons-rows="4"
        :show-skeleton="loadingMore"
      >
        <template #cell(collection)="{item: {collection}}">
          <CollectionListItem
            :collection="collection"
            :mini="true"
            :pic-size="8"
          >
            <template #context>
              <OverlayFilterContext>
                <UiButton
                  tag="router-link"
                  size="xs"
                  variant="white"
                  :to="{query: { filters: encodeFilters([{key: 'collectionId', type: 'collection', value: {comparator: 'in', value: [collection.id]}}]) } }"
                >
                  Only
                </UiButton>
              </OverlayFilterContext>
            </template>
          </CollectionListItem>
        </template>

        <template #head(positionChange)>
          Position Change
        </template>

        <template #head(tokenCountChange)>
          Tokens
        </template>

        <template #cell(positionChange)="{item}">
          <CurrencyDisplay :value="item.positionChange" />
        </template>

        <template #cell(tokenCountChange)="{item}">
          <span v-if="item.tokenCountChange > 0">+</span>{{ item.tokenCountChange }}
        </template>

        <template #cell(actions)="{}">
        <!--
 <UiButton
          v-if="walletId"
          tag="router-link"
          :to="{name: routesNames.walletFlips, params: { address: walletId }, query: { collectionId: item.collection.id } }"
          variant="white"
          size="xs"
        >
          View Flips
        </UiButton>
-->
        </template>

        <template #empty>
          <div class="flex justify-center items-center py-12 text-2xl font-light text-gray-500 bg-gray-850">
            No Data In This Period
          </div>
        </template>

        <template #after>
          <IntersectionObserver
            v-if="hasMore && !loadingMore && !loading"
            @isShow="loadMore"
          />
        </template>
      </UiTable>
    </PremiumPlaceholder>
  </UiCard>
</template>

<script setup>
import { COLLECTION_LIST_ITEM_FIELDS } from '@/graphql/collection/fragments/collectionListItemFields.fragment';
import gql from 'graphql-tag';
import { computed, ref, toRefs } from 'vue';
import routesNames from '@/router/routesNames';
import usePaginatedQuery from '@/composition/pagination/usePaginatedQuery';
import { encodeFilters } from '@/composition/filters/useFiltersQuerySync';
import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { WATCHLIST_TYPES } from '@/constants';
import CollectionListItem from '../collections/CollectionListItem.vue';
import CurrencyDisplay from '../CurrencyDisplay.vue';
import PeriodSelector from '../PeriodSelector.vue';
import UiCard from '../ui/UiCard.vue';
import UiTable from '../ui/UiTable.vue';
import UiButton from '../ui/UiButton.vue';
import IntersectionObserver from '../IntersectionObserver.vue';
import OverlayFilterContext from '../OverlayFilterContext.vue';
import PremiumPlaceholder from '../PremiumPlaceholder.vue';

const walletQuery = gql`
  query GetWalletPositionChanges($modelId: String!, $period: String!, $orderBy: String, $orderDesc: Boolean, $take: Int, $skip: Int, $filters: [FilterArg!]) {
    model: wallet(where: {id: $modelId}) {
      id
      positionChanges(period: $period, orderBy: $orderBy, orderDesc: $orderDesc, take: $take, skip: $skip, filters: $filters) {
        id
        collection {
          ...CollectionListItem_Fields
        }
        positionChange
        tokenCountChange
      }
    }
  }
  ${COLLECTION_LIST_ITEM_FIELDS}
`;

const watchlistQuery = gql`
  query GetWatchlistPositionChanges($modelId: String!, $period: String!, $orderBy: String, $orderDesc: Boolean, $take: Int, $skip: Int, $filters: [FilterArg!]) {
    model: walletWatchlistBySlug(slug: $modelId) {
      id
      positionChanges(period: $period, orderBy: $orderBy, orderDesc: $orderDesc, take: $take, skip: $skip, filters: $filters) {
        id
        collection {
          ...CollectionListItem_Fields
        }
        positionChange
        tokenCountChange
      }
    }
  }
  ${COLLECTION_LIST_ITEM_FIELDS}
`;

const props = defineProps(['walletId', 'watchlistId', 'filters']);
const { walletId, watchlistId, filters } = toRefs(props);

const period = ref('7d');
const sortBy = ref('tokenCountChange');
const sortDesc = ref(true);

const {
  result, loading, hasMore, loadMore, loadingMore, refetch,
} = usePaginatedQuery(
  {
    take: 10,
    hasMoreCheck: (data) => data.wallet?.positionChanges?.length === 10,
    mergeResults: (oldData, newData) => {
      const data = JSON.parse(JSON.stringify(oldData));

      data.wallet.positionChanges = [
        ...data.wallet.positionChanges,
        ...newData.wallet.positionChanges,
      ];

      return data;
    },
  },
  () => (walletId.value ? walletQuery : watchlistQuery),
  () => ({
    modelId: walletId.value || watchlistId.value,
    period: period.value,
    orderBy: sortBy.value,
    orderDesc: sortDesc.value,
    filters: filters.value,
  }),
);

const { onWatchlistMembersUpdated } = useWatchlists(WATCHLIST_TYPES.WALLET);

onWatchlistMembersUpdated(() => {
  if (watchlistId.value) {
    refetch();
  }
});

const tableData = computed(() => result.value?.model?.positionChanges || []);

</script>
