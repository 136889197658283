<template>
  <div>
    <UiInputRadio
      v-model="type"
      :options="radioOptions"
      class="mb-3"
    />

    <UiInputRadio
      v-model="valueType"
      :options="[{text: 'Wallets', value: 'wallet'}, {text: 'Group', value: 'walletWatchlist'}]"
      class="mb-3"
      @update:modelValue="() => localValue = []"
    />

    <ResourcePicker
      v-if="!isVariable && ['in', 'nin'].includes(type)"
      v-model="localValue"
      :only="valueType"
      multiple
    />

    <div v-else-if="['in', 'nin'].includes(type)">
      <VariableDisplay

        v-model="localValue"
        :reset-value="() => []"
      />
    </div>

    <UiButton
      v-if="allowVariables === true && valueType === 'wallet'"
      size="xs"
      variant="white"
      @click="selectValueVariable"
    >
      <i class="far fa-brackets-curly" />
    </UiButton>
  </div>
</template>

<script setup>

import {
  toRefs, ref, computed, watch, inject,
} from 'vue';
import VariableDisplay from '../automation/VariableDisplay.vue';
import ResourcePicker from '../ResourcePicker.vue';
import UiButton from '../ui/UiButton.vue';
import UiInputRadio from '../ui/UiInputRadio.vue';

const props = defineProps(['modelValue', 'allowEmpty', 'allowVariables', 'actionId']);
const {
  modelValue, allowEmpty, allowVariables, actionId,
} = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const validate = (value) => value?.length > 0;

const selectVariable = inject('selectVariable');

const type = ref(modelValue.value?.comparator || 'eq');
const localValue = ref(modelValue.value?.value || []);
const valueType = ref(modelValue.value?.valueType || 'wallet');
const isVariable = computed(() => localValue.value?.startsWith?.('{{') && localValue.value?.endsWith?.('}}'));
watch(modelValue, (v) => {
  type.value = v.comparator;
  localValue.value = v.value;
}, { deep: true });

const radioOptions = computed(() => [
  ...(allowEmpty.value !== false ? [{
    text: 'Empty',
    value: 'null',
  },
  {
    text: 'Not Empty',
    value: 'notNull',
  }] : []),
  {
    text: 'In',
    value: 'in',
  },
  {
    text: 'Not In',
    value: 'nin',
  },
]);

watch([type, localValue], ([t, v]) => {
  if (valueType.value === 'wallet') {
    emit('update:modelValue', { comparator: t, valueType: valueType.value, value: ['in', 'nin'].includes(t) && validate(v) ? v : null });
  }

  if (valueType.value === 'walletWatchlist') {
    emit('update:modelValue', { comparator: t, valueType: valueType.value, value: ['in', 'nin'].includes(t) && validate(v) ? v : null });
  }
}, { deep: true });

const selectValueVariable = () => {
  selectVariable({
    type: 'String',
    actingAs: 'wallet',
  }, actionId.value).then((variable) => {
    localValue.value = `{{${variable.id}}}`;
  });
};
</script>
