<template>
  <div v-if="!isEditing">
    <UiCard class="py-6 mb-6">
      <div
        class="watchlist-metrics__metrics-wrapper"
      >
        <UiGridItem class="px-6">
          <MetricWidget
            title="1d Volume"
            :value="weekMetrics?.volume1d?.value"
            :compare-to="metrics?.volume1d?.previous"
            :is-loading="!watchlistId || isWeekMetricsLoading"
            :formatter="formatCurrency"
          />
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            title="7d Volume"
            :value="weekMetrics?.volume7d?.value"
            :compare-to="metrics?.volume7d?.previous"
            :is-loading="!watchlistId || isWeekMetricsLoading"
            :formatter="formatCurrency"
          />
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            title="1d Unique buyers & sellers"
            :is-loading="!watchlistId || isWeekMetricsLoading"
            :formatter="formatNumber"
          >
            <template #value>
              <div class="flex overflow-hidden mt-0.5 w-full h-6 rounded">
                <!-- TODO: Refactor this part  -->
                <div
                  v-tooltip="`${formatNumber(weekMetrics?.uniqueBuyers1d?.value)} buyers`"
                  class="flex justify-center items-center h-6 text-green-900 bg-green-500"
                  :style="{width: formatPercent(weekMetrics?.uniqueBuyers1d?.value/(weekMetrics?.uniqueBuyers1d?.value + weekMetrics?.uniqueSellers1d?.value)) }"
                >
                  <span class="text-xs md:text-sm">
                    {{ formatPercent(weekMetrics?.uniqueBuyers1d?.value/(weekMetrics?.uniqueBuyers1d?.value + weekMetrics?.uniqueSellers1d?.value)) }}
                  </span>
                </div>

                <div
                  v-tooltip="`${formatNumber(weekMetrics?.uniqueSellers1d?.value)} sellers`"
                  class="flex justify-center items-center h-6 text-red-900 bg-red-500"
                  :style="{width: formatPercent(weekMetrics?.uniqueSellers1d?.value/(weekMetrics?.uniqueBuyers1d?.value + weekMetrics?.uniqueSellers1d?.value)) }"
                >
                  <span class="text-xs md:text-sm">{{ formatPercent(weekMetrics?.uniqueSellers1d?.value / (weekMetrics?.uniqueBuyers1d?.value + weekMetrics?.uniqueSellers1d?.value)) }}</span>
                </div>
              </div>
            </template>
          </MetricWidget>
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            title="7d Unique buyers & sellers"
            :is-loading="!watchlistId || isWeekMetricsLoading"
            :formatter="formatNumber"
          >
            <template #value>
              <div class="flex overflow-hidden mt-0.5 w-full h-6 rounded">
                <div
                  v-tooltip="`${formatNumber(weekMetrics?.uniqueBuyers7d?.value)} buyers`"
                  class="flex justify-center items-center h-6 text-green-900 bg-green-500"
                  :style="{width: formatPercent(weekMetrics?.uniqueBuyers7d?.value/(weekMetrics?.uniqueBuyers7d?.value + weekMetrics?.uniqueSellers7d?.value)) }"
                >
                  <span class="text-xs md:text-sm">{{ formatPercent(weekMetrics?.uniqueBuyers7d?.value/(weekMetrics?.uniqueBuyers7d?.value + weekMetrics?.uniqueSellers7d?.value)) }}</span>
                </div>

                <div
                  v-tooltip="`${formatNumber(weekMetrics?.uniqueSellers7d?.value)} sellers`"
                  class="flex justify-center items-center h-6 text-red-900 bg-red-500"
                  :style="{width: formatPercent(weekMetrics?.uniqueSellers7d?.value/(weekMetrics?.uniqueBuyers7d?.value + weekMetrics?.uniqueSellers7d?.value)) }"
                >
                  <span class="text-xs md:text-sm">
                    {{ formatPercent(weekMetrics?.uniqueSellers7d?.value / (weekMetrics?.uniqueBuyers7d?.value + weekMetrics?.uniqueSellers7d?.value)) }}
                  </span>
                </div>
              </div>
            </template>
          </MetricWidget>
        </UiGridItem>
      </div>
    </UiCard>

    <div class="grid grid-cols-1 md:grid-cols-2 auto-rows-auto gap-6 mb-6">
      <PeriodSelector
        v-model="period"
        class="col-span-full justify-self-end"
        :periods="['1h', '6h', '1d', '3d', '7d', '14d', '30d', '90d', '180d', '1y']"
      />

      <UiCard title="Market Capitalization">
        <UiCardBody>
          <Chart
            :is-loading="!watchlistId || isLoading"
            watermark
            :options="marketCapChartOptions"
          />
        </UiCardBody>
      </UiCard>

      <UiCard title="Volume">
        <UiCardBody>
          <Chart
            :is-loading="!watchlistId || isLoading"
            watermark
            :options="volumeChartOptions"
          />
        </UiCardBody>
      </UiCard>
    </div>
  </div>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import {
  computed,
  toRefs,
  ref,
  inject,
} from 'vue';
import moment from 'moment';
import { formatCurrency, formatNumber, formatPercent } from '@/utils/filters';
import {
  GET_COLLECTION_WATCHLIST_METRICS_QUERY,
  GET_COLLECTION_WATCHLIST_METRICS_FOR_WEEK_QUERY,
} from '@/graphql/watchlists/queries';
import UiCard from '@/components/ui/UiCard.vue';
import UiCardBody from '@/components/ui/UiCardBody.vue';
import Chart, { createTooltip } from '@/components/Chart.vue';
import UiGridItem from '@/components/ui/UiGridItem.vue';
import MetricWidget from '@/components/MetricWidget.vue';
import PeriodSelector from '@/components/PeriodSelector.vue';

const props = defineProps(['watchlistId', 'isEditing']);
const {
  watchlistId,
} = toRefs(props);
const period = ref('90d');

const {
  result: metricsData,
  loading: isLoading,
  refetch,
} = useQuery(
  GET_COLLECTION_WATCHLIST_METRICS_QUERY,
  () => ({
    id: watchlistId.value,
    period: period.value,
  }),
  () => ({ enabled: !!watchlistId.value }),
);
const metrics = computed(() => metricsData.value?.collectionWatchlist || {});
const { pushCbToStack } = inject('single-watchlist-change-stack');
pushCbToStack(() => refetch());

const {
  result: metrcisForWeekResult,
  loading: isWeekMetricsLoading,
} = useQuery(
  GET_COLLECTION_WATCHLIST_METRICS_FOR_WEEK_QUERY,
  () => ({
    id: watchlistId.value,
  }),
  () => ({ enabled: !!watchlistId.value }),
);
const weekMetrics = computed(() => metrcisForWeekResult.value?.collectionWatchlist);

const marketCapChartOptions = computed(() => ({
  xAxis: {
    type: 'datetime',
  },
  tooltip: createTooltip({
    formatters: {
      'Market Cap': (value) => formatCurrency(value, 'ETH'),
    },
  }),
  series: [
    {
      data: metricsData.value?.collectionWatchlist?.marketCapOverTime?.map((item) => [
        parseInt(moment(item.key).format('x')),
        item.value,
      ]) || [],
      name: 'Market Cap',
    },
  ],
}));

const volumeChartOptions = computed(() => ({
  xAxis: {
    type: 'datetime',
  },
  tooltip: createTooltip({
    formatters: {
      'Total Volume': (value) => formatCurrency(value, 'ETH'),
    },
  }),
  series: [
    {
      data: metricsData.value?.collectionWatchlist?.volumeOverTime?.map((item) => [
        parseInt(moment(item.key).format('x')),
        item.value,
      ]) || [],
      type: 'column',
      name: 'Total Volume',
    },
  ],
}));
</script>

<style lang="scss">
  .watchlist-metrics {
    &__metrics-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      @apply gap-y-8 divide-x divide-gray-200 dark:divide-gray-700;
    }
  }
</style>
