<template>
  <div :class="{'sm:p-6 py-5 px-4': slim === false, 'sm:p-3 py-2 px-3': slim !== false}">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiCardBody',
  props: {
    slim: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>
