<template>
  <PortfolioTable
    v-model:sortBy="sortBy"
    v-model:sortDesc="sortDesc"
    v-model:searchTerm="searchTerm"
    v-model:filters="filters"
    :is-loading="isLoading"
    :is-loading-more="isLoadingMore"
    :has-more="hasMore"
    :collections="collections"
    :watchlist-id="slug"
    @loadMore="loadMore"
  />
</template>

<script setup>
import {
  computed,
  toRefs,
  ref,
} from 'vue';
import { useRoute } from 'vue-router';

import { usePremium } from '@/composition/premium';
import usePaginatedQuery from '@/composition/pagination/usePaginatedQuery';
import query from '@/graphql/watchlists/queries/walletWatchlistPortfolio.query.gql';

import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { WATCHLIST_TYPES } from '@/constants';
import PortfolioTable from '../PortfolioTable.vue';

const route = useRoute();

const slug = computed(() => route.params.slug || 'portfolio');

const { sortBy, sortDesc } = toRefs({
  sortBy: 'estimatedValue',
  sortDesc: true,
});

const searchTerm = ref('');
const filters = ref([]);

const {
  result: collectionsResult,
  loading: isLoading,
  loadingMore: isLoadingMore,
  loadMore: loadMoreAction,
  hasMore,
  refetch,
} = usePaginatedQuery(
  {
    take: 20,
    hasMoreCheck: (data) => data.watchlist?.portfolio?.length === 20,
    mergeResults: (oldData, newData) => {
      const data = JSON.parse(JSON.stringify(oldData));

      data.watchlist.portfolio = [
        ...data.watchlist.portfolio,
        ...newData.watchlist.portfolio,
      ];

      return data;
    },
  },
  query,
  () => ({
    slug: slug.value,
    orderBy: sortBy.value,
    orderDesc: sortDesc.value,
    search: searchTerm.value,
    filters: filters.value,
  }),
  () => ({
    enabled: Boolean(slug.value),
    debounce: 500,
  }),
);
const collections = computed(() => collectionsResult.value?.watchlist.portfolio);

const { onWatchlistMembersUpdated } = useWatchlists(WATCHLIST_TYPES.WALLET);

onWatchlistMembersUpdated(() => refetch());

const { requirePremium } = usePremium();
async function loadMore() {
  await requirePremium('to view complete portfolio');

  return loadMoreAction();
}
</script>
