<template>
  <div>
    <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 w-full">
      <div
        v-for="item in collections"
        :key="item.id"
      >
        <UiCard
          :class="{
            'cursor-pointer dark:hover:bg-gray-800': !item.isMember,
          }"
          @click="!item.isMember && toggleItem(item.id)"
        >
          <div class="flex gap-x-6 justify-between items-center py-3 px-3">
            <slot
              name="collection"
              :item="item"
            >
              <CollectionListItem
                no-link
                no-overlay
                :collection="item"
              />
            </slot>

            <div
              v-if="!item.isMember"
              class="text-2xl pointer-events-none"
            >
              <UiCheckbox
                :model-value="value.includes(item.id)"
                size="lg"
              />
            </div>

            <p
              v-else
              class="flex justify-center items-center self-center py-1 px-2 text-xs font-bold text-indigo-1000 uppercase whitespace-nowrap bg-indigo-500 rounded-lg select-none"
            >
              added
            </p>
          </div>
        </UiCard>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed, toRefs,
} from 'vue';
import { useRecommendedCollections } from '@/composition/collections';
import UiCard from '../ui/UiCard.vue';
import CollectionListItem from '../collections/CollectionListItem.vue';
import UiCheckbox from '../ui/UiCheckbox.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps(['modelValue', 'collections', 'membersIds']);

const propsAsRefs = toRefs(props);

const value = computed({
  get() {
    return props.modelValue || [];
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const recommended = useRecommendedCollections({ enabled: true });

function getCollections(list) {
  const collections = [];
  for (const item of list) {
    const collection = {
      ...item,
      isMember: props.membersIds.some((id) => item.id === id),
    };

    collections.push(collection);
  }
  return collections;
}

const collections = computed(() => {
  if (propsAsRefs.collections.value) {
    return getCollections(propsAsRefs.collections.value);
  }

  return recommended.collections.value;
});

const toggleItem = (id) => {
  if (value.value.includes(id)) {
    value.value = value.value.filter((item) => item !== id);
  } else {
    value.value.push(id);
  }
};
</script>
