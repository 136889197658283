<template>
  <span
    v-tooltip="minimal === false ? undefined : capitalize(type)"
    class="inline-flex items-center text-xs font-medium leading-none text-gray-900 rounded-full border border-white border-opacity-10"
    :class="[classes, {'py-0.5 px-2': minimal === false, 'w-3 h-3': minimal !== false}]"
  >
    <span
      v-if="minimal === false"
      class="capitalize"
    >{{ capitalize(type) }}</span>
  </span>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { capitalize } from 'lodash-es';

const props = defineProps({
  type: null,
  minimal: {
    type: Boolean,
    default: false,
  },
});

const { type: typeProp, minimal } = toRefs(props);

const type = computed(() => typeProp.value?.toLowerCase());

const classes = computed(() => {
  if (type.value === 'new') {
    return 'bg-green-500';
  }

  return 'bg-red-500';
});
</script>
