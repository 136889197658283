<template>
  <div
    class="absolute top-6 right-6 z-10 w-10 h-10 leading-none bg-gray-800 hover:bg-gray-700 bg-opacity-60 rounded-full opacity-80 hover:opacity-100 cursor-pointer"
    @click="() => toggleIsVisible(false)"
  >
    <div>
      <i class="absolute top-1/2 left-1/2 pt-0.5 text-xl leading-none text-gray-300 align-bottom transform -translate-x-1/2 -translate-y-1/2 far fa-times" />
    </div>
  </div>

  <div class="flex flex-col md:flex-row gap-6 p-3 md:p-6">
    <div class="flex-shrink-0 lg:w-96 md:max-w-sm">
      <TokenImage
        v-if="mediaType === MEDIA_TYPES.IMAGE"
        :token="token"
        class="overflow-hidden mb-6 rounded-2xl"
        :loading="isLoadingDetails"
      />

      <iframe
        v-if="mediaType === MEDIA_TYPES.INTERACTIVE"
        class="overflow-hidden mb-6 rounded-2xl"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        height="400"
        allowfullscreen=""
        frameborder="0"
        sandbox="allow-scripts"
        :src="animationUrl"
        width="100%"
      />

      <TokenAttributes :token-id="`${collection?.id}_${tokenId}`" />
    </div>

    <div class="flex-1 min-w-0">
      <div class="flex gap-3 items-center mb-3 text-4xl font-bold dark:text-gray-200">
        {{ tokenName }}
        <span
          v-if="token && collection?.id !== ENS_ADDRESS && token.tokenId.length < 8"
          class="py-0.5 px-1.5 text-xl text-gray-400 bg-gray-700 rounded"
        >#{{ token.tokenId }}</span>
      </div>

      <div class="flex gap-4 items-center mb-4">
        <div>
          <TokenLinks
            :collection="collection"
            :token-id="tokenId"
          />
        </div>

        <div v-if="token && token.rarityRank && totalSupply">
          <RarityRank
            :rank="token.rarityRank"
            :supply="totalSupply"
          />
        </div>
      </div>

      <div class="flex gap-6 items-start mb-4">
        <div>
          <div class="mb-2 text-sm text-gray-300">
            Owner
          </div>

          <div>
            <WalletProfile
              :wallet="token?.owner"
              :is-loading="isLoadingDetails"
              class="inline-block"
            />
          </div>
        </div>

        <div>
          <div class="mb-2 text-sm text-gray-300">
            Collection
          </div>

          <div>
            <CollectionListItem
              :collection="token?.collection"
              :mini="true"
              :pic-size="8"
              :is-loading="isLoadingDetails"
            />
          </div>
        </div>
      </div>
      <!-- Purchase section -->
      <div
        v-if="isUpsellBannerVisible"
        class="py-3 px-4 mb-3 text-yellow-900 bg-yellow-500 rounded-2xl"
      >
        <div class="mb-2 font-bold">
          Quickbuy available with Compass Premium
        </div>

        <div>
          Want to try buying without Premium access? Purchase a <RouterLink
            class="font-bold underline"
            :to="{
              name: routesNames.collectionItems,
              params: { contractAddress: '0xda2686fd32c6b74d55605cfb48bef331771e7fc6'},
              query: { listingStatus: 'listed' }
            }"
          >
            Compass Lifetime Pass
          </RouterLink> now to enjoy the full experience.
        </div>
      </div>

      <div
        v-if="isLoadingListings"
        class="flex justify-between items-center p-4 mb-4 dark:bg-gray-800 rounded-2xl animate-pulse"
      >
        <UiButton
          size="xl"
          class="opacity-0"
        >
          Loading
        </UiButton>
      </div>

      <div
        v-if="!isLoadingListings && listing"
        class="flex flex-col sm:flex-row gap-y-3 sm:gap-y-0 sm:justify-between sm:items-center p-4 mb-4 dark:bg-gray-800 rounded-2xl"
      >
        <div>
          <div class="mb-2 text-sm font-medium text-gray-400">
            Current Price
          </div>

          <div class="flex gap-3 items-center">
            <MarketplaceIcon
              :marketplace="listing.marketplace"
              :size="6"
            />

            <div class="flex gap-2 items-end">
              <CurrencyDisplay
                :value="listing.price"
                class="font-mono text-2xl font-medium"
              />

              <div
                v-if="lastSale"
                v-tooltip="{
                  allowHTML: true,
                  content: `
                    <div>${formatPnl(profitIfSoldAt(listing.price))} at listed price</div>
                    <div>${formatPnl(profitIfSoldAt(floorPrice))} (${formatPercent(profitIfSoldAt(floorPrice)/(lastSale.price / lastSale.tokensSold))}) at floor</div>
                  `
                }"
                class="flex gap-1 items-center self-end text-sm leading-none"
                :class="{'text-green-500': profitIfSoldAt(listing.price) > 0, 'text-red-500': profitIfSoldAt(listing.price) < 0}"
              >
                <i
                  class="text-lg leading-none fas"
                  :class="{'fa-caret-up': profitIfSoldAt(listing.price) > 0, 'fa-caret-down': profitIfSoldAt(listing.price) < 0}"
                />
                {{ formatPercent(profitIfSoldAt(listing.price) / (lastSale.price / lastSale.tokensSold)) }}
              </div>
            </div>
          </div>

          <div class="mt-3 text-xs text-gray-400 align-bottom">
            <span
              v-if="listing.expiresAt"
              v-tooltip="moment.utc(listing.expiresAt).local().format('lll')"
            >Expires {{ moment.utc(listing.expiresAt).local().fromNow() }}.</span>

            <span v-if="lastSale"> Purchased for {{ formatCurrency((lastSale.price / lastSale.tokensSold)) }} {{ moment.utc(lastSale.blockTimestamp).local().fromNow() }}</span>
          </div>
        </div>

        <div>
          <div class="flex gap-4 items-center">
            <!-- <gas-preset /> -->
            <div v-if="isReported">
              <i
                v-tooltip="'Flagged on Opensea'"
                class="text-3xl text-red-500 fad fa-exclamation-triangle"
              />
            </div>

            <UiButton
              :disabled="isBuying"
              size="xl"
              :is-loading="isWaitingForProvider"
              @click="buyWithGas(gasPreset)"
            >
              <span
                v-if="!isBuying"
                class="font-bold"
              >Buy Now</span>

              <span v-else-if="isBuying && !firstIncompleteStep?.items?.[0]?.txHash">{{ firstIncompleteStep?.action || 'Preparing' }}</span>

              <span v-else-if="isBuying && firstIncompleteStep?.items?.[0]?.txHash">Confirming</span>
            </UiButton>
          </div>
        </div>
      </div>

      <div
        v-else-if="!isLoadingListings && token"
        class="flex justify-between items-center p-4 mb-4 dark:bg-gray-800 rounded-2xl"
      >
        <div
          v-if="!token.topOffer"
          class="text-lg font-medium text-gray-300"
        >
          Not for sale
        </div>

        <div v-else>
          <div class="text-sm text-gray-400">
            Best offer
          </div>

          <CurrencyDisplay
            :value="token.topOffer"
            class="font-mono text-2xl font-medium"
          />
        </div>

        <div class="flex gap-4 items-center">
          <div v-if="isReported">
            <i
              v-tooltip="'Flagged on Opensea'"
              class="text-3xl text-red-500 fad fa-exclamation-triangle"
            />
          </div>

          <TokenListingAlerts
            :token="token"
            from="token"
          />

          <CreateAutomationForInputs
            v-if="token"
            :available-inputs="['collection', 'token']"
            :input-values="{collection: token.collection.id, token: token.id}"
            :button-props="{variant:'white', size: 'lg'}"
          />
        </div>
      </div>

      <div
        v-if="purchaseError"
        class="
              p-3
              mb-4
              text-center
              text-red-600
              rounded-2xl
              bg-red-600 bg-opacity-20
              ring-red-600 ring-offset-0
            "
      >
        {{ purchaseError.message }}
      </div>

      <div
        v-if="firstIncompleteStep?.items?.[0]?.txHash"
        class="
              p-3
              mb-4
              text-center
              text-indigo-500
              rounded-2xl
              bg-indigo-600 bg-opacity-20
              ring-indigo-600 ring-offset-0
            "
      >
        Transaction submited. <a
          :href="`https://etherscan.io/tx/${firstIncompleteStep?.items?.[0]?.txHash}`"
          target="_blank"
          class="font-medium"
        >
          View on Etherscan
          <i class="ml-1 fad fa-external-link" />
        </a>
      </div>

      <!-- Transactions -->
      <Accordion
        v-model="currentlyOpenAccordion"
        name="Activity"
        class="mb-4"
      >
        <template #icon>
          <i class="fad fa-exchange" />
        </template>

        <TokenActivityChart
          class="py-4"
          :token-id="`${collection.id}_${tokenId}`"
        />

        <TokenActivity
          class="py-4"
          :token-id="`${collection.id}_${tokenId}`"
        />
      </Accordion>

      <Accordion
        v-model="currentlyOpenAccordion"
        name="Listings"
        class="mb-4"
      >
        <template #icon>
          <i class="fad fa-tag" />
        </template>

        <div class="py-4">
          <TokenOrders
            :token-id="`${collection.id}_${tokenId}`"
            mode="listings"
          />
        </div>
      </Accordion>

      <Accordion
        v-model="currentlyOpenAccordion"
        name="Offers"
        class="mb-4"
      >
        <template #icon>
          <i class="fad fa-gavel" />
        </template>

        <div class="py-4">
          <TokenOrders
            :token-id="`${collection.id}_${tokenId}`"
            mode="offers"
          />
        </div>
      </Accordion>

      <Accordion
        v-model="currentlyOpenAccordion"
        name="Pending Transactions"
        class="mb-4"
      >
        <template #icon>
          <i class="fad fa-clock" />
        </template>

        <template #default="{open}">
          <div class="py-4">
            <PendingTransactions
              v-if="!isLoadingListings && listing?.id"
              :collection="collection"
              :token-id="tokenId"
              :listing="listing"
              @pendingTxCount="(count) => count > 0 ? open() : undefined"
            />

            <div
              v-else-if="!isLoadingListings"
              class="text-center text-gray-400"
            >
              No pending transactions
            </div>

            <ViewLoader v-if="isLoadingListings" />
          </div>
        </template>
      </Accordion>

      <Accordion
        v-model="currentlyOpenAccordion"
        name="Metadata"
      >
        <template #icon>
          <i class="fad fa-brackets-curly" />
        </template>

        <div class="p-4">
          <pre
            v-if="token"
            class="text-sm whitespace-pre-wrap break-all"
          >{{ token.metadata }}</pre>

          <ViewLoader v-else />
        </div>
      </Accordion>
    </div>
  </div>
</template>

<script setup>
import {
  toRef,
  computed,
  toRefs,
  ref,
  watch,
  onMounted,
  onScopeDispose,
  inject,
} from 'vue';
import routesNames from '@/router/routesNames';
import { useQuery } from '@vue/apollo-composable';
import { useStore } from 'vuex';
import { fromWei } from 'web3-utils';
import { formatPercent, formatCurrency, formatPnl } from '@/utils/filters';
import moment from 'moment';
import gql from 'graphql-tag';
import tokenDetailsQuery from '@/graphql/token/queries/tokenDetails.query.gql';
import useBuyToken from '@/composition/tokens/useBuyToken';
import useSettings from '@/composition/settings/useSettings';
import ipfsUrl from '@/utils/filters/ipfsUrl';
import { usePremium } from '@/composition/premium';
import { ENS_ADDRESS } from '@/constants';
import { useRoute } from 'vue-router';
import UiButton from './ui/UiButton.vue';
import TokenLinks from './TokenLinks.vue';
import TokenImage from './TokenImage.vue';
import RarityRank from './RarityRank.vue';
import CurrencyDisplay from './CurrencyDisplay.vue';
import WalletProfile from './wallets/WalletProfile.vue';
import TokenTransactions from './tokens/TokenTransactions.vue';
import Accordion from './Accordion.vue';
import PendingTransactions from './tokens/PendingTransactions.vue';
import ViewLoader from './ViewLoader.vue';
import TokenAttributes from './tokens/TokenAttributes.vue';
import TokenListingAlerts from './tokens/TokenListingAlerts.vue';
import MarketplaceIcon from './MarketplaceIcon.vue';
import Banner from './Banner.vue';
import CollectionListItem from './collections/CollectionListItem.vue';
import TokenActivity from './tokens/TokenActivity.vue';
import TokenActivityChart from './tokens/TokenActivityChart.vue';
import { useUiDialog } from './ui-dialog/useUiDialog';
import CreateAutomationForInputs from './automation/CreateAutomationForInputs.vue';
import TokenOrders from './tokens/TokenOrders.vue';

const KEYS = {
  LEFT: {
    code: 37,
    name: 'left',
  },
  RIGHT: {
    code: 39,
    name: 'right',
  },
};

function createEventEmitter(emit) {
  return function emitEvent(e) {
    if (e.keyCode === KEYS.LEFT.code) {
      e.preventDefault();
      e.stopPropagation();
      emit('arrow-press', { customKeyName: KEYS.LEFT.name });
    } else if (e.keyCode === KEYS.RIGHT.code) {
      e.preventDefault();
      e.stopPropagation();
      emit('arrow-press', { customKeyName: KEYS.RIGHT.name });
    }
  };
}

const props = defineProps(['collection', 'tokenId']);
const emit = defineEmits(['arrow-press']);
const emitEvent = createEventEmitter(emit);

const {
  collection,
  tokenId,
} = toRefs(props);
const { toggleIsVisible } = inject('ui-modal-provide');

const collectionId = toRef(collection.value, 'id');

const isWaitingForProvider = ref(false);
const currentlyOpenAccordion = ref(null);

// queries
const {
  result: tokenDetailsResult,
  loading: isLoadingDetails,
} = useQuery(
  tokenDetailsQuery,
  () => ({ id: `${collectionId?.value}_${tokenId.value}` }),
  () => ({
    pollInterval: 5000,
  }),
);

const { result: totalSupplyResult } = useQuery(
  gql`
    query GetCollectionTotalSupply($id: String!) {
      collection(where: { id: $id }) {
        id
        totalSupply
      }
    }
  `,
  () => ({
    id: collection.value.id,
  }),
  () => ({
    enabled: !collection.value?.totalSupply,
  }),
);
// computed
const token = computed(() => tokenDetailsResult.value?.token);

const totalSupply = computed(() => collection.value.totalSupply || totalSupplyResult.value?.collection.totalSupply);

const tokenName = computed(() => {
  if (token.value?.name) {
    return token.value.name;
  }

  return collection.value.name;
});

const {
  result: isReportedResult,
} = useQuery(gql`
query GetTokenIsReported($id: String!) {
  token(where: { id: $id }) {
    id
    isReported
  }
}
`, () => ({ id: `${collection.value?.id}_${tokenId.value}` }), () => ({ enabled: collection.value && tokenId.value }));

const isReported = computed(() => isReportedResult.value?.token?.isReported || false);

const {
  result: lastSaleResult,
} = useQuery(
  gql`
    query GetTokenLastSale($tokenId: String!) {
      token(where: { id: $tokenId }) {
        lastSale {
          id
          marketplace
          price
          tokensSold
          blockTimestamp
          transaction {
            fromAddress
            gasInEth
          }
        }
      }
    }
  `,
  () => ({ tokenId: `${collection.value?.id}_${tokenId.value}` }),
  () => ({ enabled: !!collection.value?.id && !!tokenId.value }),
);

const lastSale = computed(() => lastSaleResult.value?.token?.lastSale);

const {
  result: floorPriceResult,
} = useQuery(
  gql`
    query GetCollectionFloor($id: String!) {
      collection(where: { id: $id }) {
        listedFloor
        royalties: openseaFeeBasisPoints
      }
    }
  `,
  () => ({ id: collection.value?.id }),
  () => ({ enabled: !!collection.value?.id }),
);

const floorPrice = computed(() => floorPriceResult.value?.collection?.listedFloor);

const royalties = computed(() => floorPriceResult.value?.collection?.royalties);

const {
  listing,
  reset,
  buyToken: purchaseToken,
  isListed,
  isBuying,
  checkoutText,
  isLoadingListings,
  onListingsResult,
  error: purchaseError,
  steps,
} = useBuyToken({ collectionId, tokenId });

const firstIncompleteStep = computed(() => steps.value.find((step) => step.items.filter((i) => i.status !== 'complete').length));

watch([collectionId, tokenId], async () => {
  currentlyOpenAccordion.value = null;
  isWaitingForProvider.value = false;
  reset();
});

onMounted(() => {
  document.addEventListener('keydown', emitEvent);
});

onScopeDispose(() => {
  document.removeEventListener('keydown', emitEvent);
});

// const floorDifference = computed(() => {
//   if (!isListed.value || !listing.value) {
//     return null;
//   }

//   return (listing.value.price / token.value.collection.listedFloor) - 1;
// })

const store = useStore();

const profitIfSoldAt = computed(() => (price) => {
  const grossProfit = price - lastSale.value.price;

  const royaltiesPaid = (price * (royalties.value / 100)) / 100;

  let txCosts = 0;
  if (lastSale.value?.transaction?.fromAddress === token.value?.ownerAddress) {
    txCosts += lastSale.value.transaction.gasInEth;
  }

  return grossProfit - royaltiesPaid - txCosts;
  // gas paid at purchase
});

const acceptDisclaimer = () => {
  if (localStorage.getItem('sniper-disclaimer')) {
    return true;
  }

  // eslint-disable-next-line no-restricted-globals
  const didAccept = confirm('Always make sure you check the transaction in your wallet before aproving it.\nI understand that Compass has not reviewed this collection and blockchain transactions are irreversible.');

  if (didAccept) {
    localStorage.setItem('sniper-disclaimer', Date.now());
    return true;
  }

  return false;
};

const {
  gasPreset,
  maxFeePerGasInput,
  maxPriorityFeePerGasInput,
} = useSettings({
  gasPreset: { key: 'gas.preset', default: 'fast' },
  maxFeePerGasInput: { key: 'gas.custom.maxFeePerGas' },
  maxPriorityFeePerGasInput: { key: 'gas.custom.maxPriorityFeePerGas' },
});

const { requirePremium, isPremium } = usePremium();

const LIFETIME_PASS_ADDRESS = '0xda2686fd32c6b74d55605cfb48bef331771e7fc6';
const { info } = useUiDialog();
const isUpsellBannerVisible = computed(() => !isPremium.value && collection.value?.id.toLowerCase() !== LIFETIME_PASS_ADDRESS);
const buyWithGas = async (gas) => {
  if (collection.value.id.toLowerCase() !== LIFETIME_PASS_ADDRESS) {
    await requirePremium('to buy NFTs');
  }

  const didAccept = await acceptDisclaimer();
  if (!didAccept) {
    return Promise.reject();
  }
  isWaitingForProvider.value = true;
  const provider = await store.dispatch('auth/getProvider');
  isWaitingForProvider.value = false;
  let promise;
  if (['rapid', 'fast'].indexOf(gas) !== -1) {
    // get from oracle
    const gasData = store.getters['gas/gasData'];
    promise = purchaseToken({
      provider,
      maxPriorityFeePerGas: gasData?.eip1559?.[gas]?.maxPriorityFeePerGas ? fromWei(gasData?.eip1559?.[gas]?.maxPriorityFeePerGas, 'gwei') : null,
      maxFeePerGas: gasData?.eip1559?.[gas]?.maxPriorityFeePerGas ? fromWei(gasData?.eip1559?.[gas]?.maxFeePerGas, 'gwei') : null,
    });
  } else if (gas === 'custom') {
    promise = purchaseToken({
      provider,
      maxPriorityFeePerGas: maxPriorityFeePerGasInput.value,
      maxFeePerGas: maxFeePerGasInput.value,
    });
  } else {
    promise = purchaseToken({ provider });
  }

  return promise.then(async (res) => {
    info('Purchase successful!');
  });
};

const openWithBuyIntent = () => {
  if (isListed.value) {
    return buyWithGas(gasPreset.value);
  }

  const a = onListingsResult((res) => {
    if (!res.data) {
      return;
    }
    a.off();
    buyWithGas(gasPreset.value);
  });

  return a;
};

const MEDIA_TYPES = {
  IMAGE: 0,
  VIDEO: 1,
  INTERACTIVE: 2,
};

const animationUrl = computed(() => ipfsUrl(token.value?.metadata?.animation_url));

const mediaType = computed(() => {
  if (!token.value) {
    return MEDIA_TYPES.IMAGE;
  }

  if (token.value?.metadata?.animation_url && token.value?.metadata?.animation_url.indexOf('.gif') !== -1) {
    return MEDIA_TYPES.IMAGE;
  }

  if (token.value?.metadata?.animation_url) {
    return MEDIA_TYPES.INTERACTIVE;
  }

  return MEDIA_TYPES.IMAGE;
});

const route = useRoute();

watch(() => route, () => {
  toggleIsVisible(false);
}, { deep: true });

const setConfig = ({ accordion, buyIntent }) => {
  currentlyOpenAccordion.value = accordion;

  if (buyIntent) {
    openWithBuyIntent();
  }
};

defineExpose({ openWithBuyIntent, setConfig });

</script>
