import sortData from '@/components/collections/workers/token-price-over-time-chart/sort-data';
import workerImitator from '@/utils/worker-imitator';

function initWorkers() {
  let dataWorker;

  if (window.Worker) {
    dataWorker = new Worker(
      new URL('./sort-listings-data.worker.js', import.meta.url),
      { type: 'module' },
    );
  } else {
    dataWorker = workerImitator(sortData);
  }

  return [dataWorker];
}

const [dataWorker] = initWorkers();

export {
  dataWorker,
};
