<template>
  <UiDropdown :inline="inlineDropdown !== false">
    <template #toggle="{toggle, isOpen}">
      <UiButton
        :size="size"
        variant="white"
        :disabled="disabled"
        :class="buttonClass"
        @click="toggle"
      >
        <div class="flex gap-2 justify-between items-center w-full">
          <slot :selected="selected">
            <span
              v-if="selected.length"
              class="text-left whitespace-normal"
            >{{ displayValue }}</span>

            <span v-else>Nothing selected</span>
          </slot>

          <div class="text-xs text-gray-400">
            <i
              class="transition-transform transform fas fa-chevron-down"
              :class="{'rotate-180': isOpen}"
            />
          </div>
        </div>
      </UiButton>
    </template>

    <template #body>
      <UiDropdownItem v-if="isLoading">
        <div class="w-24 h-3 bg-gray-800 animate-pulse" />
      </UiDropdownItem>

      <UiDropdownItem
        v-for="item in options"
        :key="item.id"
        :value="item"
        @click.stop="toggleItem(item)"
      >
        <div class="flex justify-between items-center">
          <div>
            <slot
              name="option"
              :item="item"
            >
              <span
                :class="[selected.includes(item.id) ? 'font-semibold' : 'font-normal', 'block truncate']"
                class="whitespace-normal"
              >{{ item.name }}</span>
            </slot>
          </div>

          <div>
            <span
              v-if="selected.includes(item.id)"
              :class="[active ? 'text-white' : 'text-indigo-600']"
            >
              <div class="w-5 h-5 text-center">
                <i class="far fa-check" />
              </div>
            </span>
          </div>
        </div>
      </UiDropdownItem>
    </template>
  </UiDropdown>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue';
import { eq, isEqual } from 'lodash-es';
import UiButton from './UiButton.vue';
import UiDropdown from './ui-dropdown/UiDropdown.vue';
import UiDropdownItem from './UiDropdownItem.vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
  },
  inlineDropdown: {
    type: Boolean,
    default: false,
  },
  buttonClass: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});
const {
  modelValue, options, size, disabled, inlineDropdown, isLoading,
} = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const selected = computed({
  get() {
    return modelValue.value.filter((item) => options.value.find((i) => isEqual(i.id, item)));
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const toggleItem = (item) => {
  if (selected.value.includes(item.id)) {
    selected.value = selected.value.filter((i) => !eq(i, item.id));
  } else {
    selected.value = [...selected.value, item.id];
  }
};

const displayValue = computed(() => {
  if (selected.value.length === 0) {
    return 'Nothing selected';
  }

  if (selected.value.length === 1) {
    return options.value.find((i) => i.id === selected.value[0])?.name || 'Nothing selected';
  }

  const selectedNames = selected.value.map((i) => options.value.find((o) => o.id === i).name);

  if (selected.value.length < 4) {
    return selectedNames.join(', ');
  }

  return `${selectedNames[0]}, ${selectedNames[1]} and ${selected.value.length - 2} more`;
});
</script>
