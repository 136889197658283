<template>
  <div class="mx-auto max-w-screen-2xl">
    <UiCard class="py-6 mb-6">
      <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-y-8 sm:divide-x divide-gray-200 dark:divide-gray-700">
        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="isLoading"
            :value="getStats('totalRevenue')"
            title="Total revenue"
          >
            <template #value="{value}">
              <CurrencyDisplay
                class="font-mono"
                :value="value"
              />
            </template>
          </MetricWidget>
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="isLoading"
            :value="getStats('totalSpent')"
            title="Total spent"
          >
            <template #value="{value}">
              <CurrencyDisplay
                class="font-mono"
                :value="value"
              />
            </template>
          </MetricWidget>
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="isLoading"
            :value="getStats('totalProfit')"
            title="Total profit"
            hint="Total spent on tokens - Total revenue from tokens"
          >
            <template #value="{value}">
              <CurrencyDisplay
                class="font-mono"
                :value="value"
              />
            </template>
          </MetricWidget>
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="isLoading"
            :value="getStats('totalRealizedGains')"
            title="Realized gains"
            hint="Profit flips. Does not include amount spent on tokens that haven't been sold yet."
          >
            <template #value="{value}">
              <CurrencyDisplay
                class="font-mono"
                :value="value"
              />
            </template>
          </MetricWidget>
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="isLoading"
            :value="getStats('totalGasSpent')"
            title="Total Gas Spent"
          >
            <template #value="{value}">
              <CurrencyDisplay
                class="font-mono"
                :value="value"
              />
            </template>
          </MetricWidget>
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="isLoading"
            :value="portfolioValue"
            title="Portfolio value"
            hint="Total value of the wallet's portfolio based on floor prices."
          >
            <template #value="{value}">
              <CurrencyDisplay
                class="font-mono"
                :value="value"
              />
            </template>
          </MetricWidget>

          <LiquidityBreakdown
            class="mt-3"
            :breakdown="portfolioValueBreakdown"
            v-bind="childProps"
          />
        </UiGridItem>
      </div>
    </UiCard>

    <div class="mb-6">
      <UiCard title="Portfolio Value Over Time">
        <UiCardBody>
          <PremiumPlaceholder action="view portfolio over time">
            <WalletPortfolioValueOverTimeChart v-bind="childProps" />
          </PremiumPlaceholder>
        </UiCardBody>
      </UiCard>
    </div>

    <UiCard
      title="Activity"
      class="mb-6"
    >
      <template #title-right>
        <PeriodSelector
          v-model="period"
          :periods="['1d', '7d', '14d', '30d', '90d', '180d']"
        />
      </template>

      <UiCardBody>
        <PremiumPlaceholder action="view wallet activity">
          <WalletActivityScatterChart
            v-bind="childProps"
            :period="period"
          />
        </PremiumPlaceholder>
      </UiCardBody>
    </UiCard>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
      <UiCard>
        <UiCardBody>
          <UiTabs pills>
            <UiTab name="Profits">
              <PremiumPlaceholder action="view profits by purchase type">
                <ProfitByPurchaseType v-bind="childProps" />
              </PremiumPlaceholder>
            </UiTab>

            <UiTab name="Losses">
              <PremiumPlaceholder action="view losses by purchase type">
                <ProfitByPurchaseType
                  v-bind="childProps"
                  mode="loss"
                />
              </PremiumPlaceholder>
            </UiTab>
          </UiTabs>
        </UiCardBody>
      </UiCard>

      <WalletPositionChanges v-bind="childProps" />
    </div>
  </div>

  <WalletRelatedWallets
    v-bind="childProps"
  />
</template>

<script setup>

import PeriodSelector from '@/components/PeriodSelector.vue';
import {
  computed,
  ref,
  toRefs,
} from 'vue';

import UiGridItem from '@/components/ui/UiGridItem.vue';
import MetricWidget from '@/components/MetricWidget.vue';
import UiCard from '@/components/ui/UiCard.vue';

// import Chart from '@/components/Chart.vue';
import CurrencyDisplay from '@/components/CurrencyDisplay.vue';
import PremiumPlaceholder from './PremiumPlaceholder.vue';
import UiTab from './ui/UiTab.vue';
import ProfitByPurchaseType from './wallets/ProfitByPurchaseType.vue';
import WalletActivityScatterChart from './wallets/WalletActivityScatterChart.vue';
import UiCardBody from './ui/UiCardBody.vue';
import WalletPositionChanges from './wallets/WalletPositionChanges.vue';
import WalletPortfolioValueOverTimeChart from './wallets/WalletPortfolioValueOverTimeChart.vue';
import LiquidityBreakdown from './LiquidityBreakdown.vue';
import UiTabs from './ui/UiTabs.vue';
import WalletRelatedWallets from './wallets/WalletRelatedWallets.vue';

const props = defineProps({
  model: {
    type: Object,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  filters: Array,
  mode: {
    type: String,
    default: 'wallet',
  },
});

const period = ref('30d');

const { model, filters, mode } = toRefs(props);

const stats = computed(() => model.value?.analytics);
const portfolioValue = computed(() => model.value?.totalPortfolioValue);
const portfolioValueBreakdown = computed(() => model.value?.totalPortfolioValueByLiquidity);

const getStats = computed(() => (key) => stats.value?.[key]);

const childProps = computed(() => {
  // eslint-disable-next-line no-underscore-dangle
  if (mode.value === 'wallet') {
    return {
      walletId: model.value.id,
      filters: filters.value,
    };
  }

  return {
    watchlistId: model.value?.slug,
    filters: filters.value,
  };
});

</script>

<style scoped>

</style>
