<template>
  <div class="space-y-1">
    <div
      v-for="(item, index) in radioOptions"
      :key="index"
      class="flex relative items-start"
    >
      <div class="flex items-center h-5">
        <input
          :id="id + index"
          v-model="model"
          :name="id + index"
          type="radio"
          :value="item.value"
          :disabled="disabled !== false"
          :class="{'opacity-50 cursor-not-allowed': disabled !== false}"
          class="
            w-4
            h-4
            text-indigo-600
            dark:bg-gray-800
            rounded-full
            border-gray-300
            dark:border-gray-700
            focus:ring-indigo-500
            ring-offset-transparent
          "
        >
      </div>

      <div class="ml-3 text-sm">
        <label
          :for="id + index"
          class="font-medium text-gray-700 dark:text-gray-200"
        >{{ item.text }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
} from 'vue';

export default defineComponent({
  // props: ['modelValue', 'options'],
  props: {
    modelValue: null,

    options: null,

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    return {
      model,
    };
  },

  data() {
    return {
      id: `ui-radio-${Math.floor(Date.now() * Math.random())}`,
    };
  },

  computed: {
    radioOptions() {
      return this.options || [];
    },
  },
});
</script>
