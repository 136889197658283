<template>
  <UiDropdown :disabled="disabled !== false">
    <template #toggle="{toggle}">
      <UiButton
        variant="white"
        size="sm"
        :disabled="disabled !== false"

        @click="toggle"
      >
        <div class="flex gap-2 items-center">
          <i class="far fa-filter" />
          Add Filter
        </div>
      </UiButton>
    </template>

    <template #body="{forceClose}">
      <UiDropdownItem
        v-for="item in filters"
        :key="item.key"
        @click="forceClose() && emit('add', item)"
      >
        <div class="flex gap-2">
          <div class="w-4 text-center">
            <i :class="FILTER_ICONS[item.type]" />
          </div>
          {{ item.name }}
        </div>
      </UiDropdownItem>

      <UiDropdownDivider v-if="showClear" />

      <UiDropdownItem
        v-if="showClear"
        @click="emit('remove-all')"
      >
        Clear filters
      </UiDropdownItem>
    </template>
  </UiDropdown>
</template>

<script setup>
import { FILTER_ICONS } from '@/constants/filters';
import { toRefs } from 'vue';
import UiDropdown from '../ui/ui-dropdown/UiDropdown.vue';
import UiButton from '../ui/UiButton.vue';
import UiDropdownDivider from '../ui/UiDropdownDivider.vue';
import UiDropdownItem from '../ui/UiDropdownItem.vue';

const props = defineProps({
  filters: {
    type: Array,
    required: true,
  },
  showClear: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { filters, showClear, disabled } = toRefs(props);

const emit = defineEmits(['add', 'remove-all']);
</script>
