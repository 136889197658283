<template>
  <div class="relative">
    <div
      v-if="watermark !== false"
      class="absolute top-1/2 left-1/2 -mt-8 transform -translate-x-1/2 -translate-y-1/2"
    >
      <img
        class="h-24 opacity-10"
        src="@/assets/watermark.svg"
        alt="Workflow"
      >
    </div>

    <Highcharts
      class="h-full"
      :options="chartOptions"
    />

    <div
      v-if="isLoading"
      class="
        flex
        absolute
        top-0
        left-0
        justify-center
        items-center
        w-full
        h-full
        bg-white
        dark:bg-gray-850
      "
    >
      <LoadingIndicator class="text-indigo-600" />
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

import drilldown from 'highcharts/modules/drilldown';

drilldown(Highcharts);

const timezone = new Date().getTimezoneOffset();

// $indigo-400 $blue-400 $green-400
export const ChartColorMap = {
  0: 'indigo-500',
  1: 'blue-500',
  2: 'green-500',
  3: 'orange-500',
  4: 'red-500',
  5: 'rose-500',
  6: 'purple-500',
  7: 'lime-500',
  8: 'yellow-500',
  9: 'emerald-500',
  10: 'gray-500',
  100: 'brand-opensea',
  101: 'brand-looksrare',
  102: 'brand-x2y2',
  103: 'brand-sudoswap',
  104: 'brand-blur',
};

export const createTooltip = ({
  formatters, flip = false, width = 'w-48', options = {},
} = { formatters: {} }) => ({
  ...options,
  animation: false,
  shared: true,
  useHTML: true,
  className: 'highcharts-tooltip-wrap-ext',
  headerFormat: `<div class="pointer-events-none ml-5 dark:bg-gray-900 tooltip top-0 left-0 z-20 ${width} p-3 rounded shadow-lg text-sm bg-white">
                                            <div class="tabular-nums text-xs text-gray-600 dark:text-gray-400 mb-2">${flip ? '{series.name}' : '{point.key}'}</div><div class="flex flex-col space-y-1">`,
  footerFormat: '</div></div>',
  pointFormatter() {
    return `<div class="flex justify-between gap-3">
                                        <div class="text-gray-200 font-medium">${
  flip ? this.name : this.series.name
  }</div>
                                        <div class="text-${
  ChartColorMap[this.colorIndex]
  } font-medium">${formatters[this.series.name] ? formatters[this.series.name](this.y) : this.y}</div>
                                    </div>`;
  },
});

Highcharts.setOptions({
  global: {
    timezoneOffset: timezone,
  },
});

export default {
  name: 'Chart',
  components: {
    LoadingIndicator,
    Highcharts: Chart,
  },
  props: {
    labels: {
      default() {
        return [];
      },
      type: Array,
    },
    data: {
      default() {
        return [];
      },
      type: Array,
    },
    options: {
      default() {
        return {};
      },
      type: Object,
    },
    dataFormatter: null,
    isLoading: {
      type: Boolean,
      value: false,
    },
    watermark: {
      type: Boolean,
      value: false,
    },
  },
  computed: {
    chartOptions() {
      // if (this.isLoading) {
      //   return {};
      // }
      const { data, labels } = this;
      let dataFormatterFn = () => {};
      if (this.dataFormatter) {
        dataFormatterFn = this.dataFormatter;
      }
      return Highcharts.merge(
        {
          chart: {
            animation: false,
            style: {
              fontFamily: 'monospace',
            },
            styledMode: true,
          },
          title: {
            text: '',
          },
          credits: {
            enabled: false,
          },

          subtitle: {
            enabled: false,
          },
          yAxis: {
            title: {
              enabled: false,
            },
            style: {
              fontFamily: 'monospace',
            },
          },
          xAxis: {
            title: {
              enabled: false,
            },
            style: {
              fontFamily: 'monospace',
            },
          },

          legend: {
            enabled: false,
          },

          tooltip: {
            followPointer: true,
            pointFormatter() {
              return dataFormatterFn(this.y);
            },
          },

          plotOptions: {
            series: {
              animation: false,
            },
          },

          series: [
            {
              data: data.map((item, index) => [labels[index], item]),
            },
          ],
        },
        this.options,
      );
    },
  },
};
</script>
