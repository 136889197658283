<template>
  <div
    class="p-3"
  >
    <ul class="flex flex-col gap-2">
      <li
        v-for="event in displayedEvents"
        :key="event.id"
        class="p-3 hover:bg-gray-800 rounded-xl border border-gray-750 cursor-pointer"
        @click="open(event.token.id)"
      >
        <div class="grid grid-cols-12 gap-4 w-full">
          <div class="flex col-span-3 gap-3 items-center">
            <TokenImage
              class="overflow-hidden w-16 h-16 rounded-lg "
              :url="event.token.previewImageUrl"
              image-class="object-cover w-full h-full"
            />

            <div>
              <div class="mb-2">
                <span

                  class="py-0.5 px-1.5 font-normal text-gray-400 bg-gray-700 rounded"
                >#{{ event.token.tokenId }}</span>
              </div>

              <div>
                <RarityRank
                  v-if="event.token.rarityRank"
                  class="inline-block text-sm"
                  :rank="event.token.rarityRank"
                  :supply="event.collection.totalSupply"
                />
              </div>
            </div>
          </div>

          <div
            class="col-span-2"
          >
            <div class="mb-2 text-sm font-light text-gray-300">
              Price
            </div>

            <div class="flex gap-3 items-center font-medium">
              <MarketplaceIcon
                class="text-xl leading-none"
                :marketplace="event.sale.marketplace"
              />

              <div
                v-tooltip="event.sale.type === 'bid' ? 'Bid' : undefined"
                class="text-lg"
                :class="{'text-purple-400': event.sale.type === 'bid'}"
              >
                {{ formatCurrency(event.sale.price) }}
              </div>
            </div>
          </div>

          <div
            v-if="event.flip"
            class="col-span-2"
          >
            <div class="mb-2 text-sm font-light text-gray-300">
              <span v-if="event.type === 'sell'">Profit</span>

              <span v-if="event.type === 'buy'">Seller Profit</span>
            </div>

            <div class="flex gap-3 items-center font-medium">
              <div
                class="text-lg"
                :class="{'text-green-500': event.flip.netProfit > 0, 'text-red-500': event.flip.netProfit < 0}"
              >
                {{ formatCurrency(event.flip.netProfit) }}
              </div>
            </div>
          </div>

          <div
            class="col-span-2"
          >
            <div class="mb-2 text-sm font-light text-gray-300">
              <span v-if="event.type === 'sell'">Buyer</span>

              <span v-if="event.type === 'buy'">Seller</span>
            </div>

            <div>
              <WalletProfile
                :wallet="event.type === 'sell' ? event.buyer : event.seller"
                :pic-size="7"
                name-class="text-sm font-medium"
              />
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="flex justify-center items-center">
      <button
        v-if="displayedEvents.length < feedItem?.events?.length || DEFAULT_MAX_EVENTS != maxEvents"
        class="mt-2 text-sm text-gray-500"
        @click="toggleShowMoreEvents"
      >
        <span v-if="maxEvents">View {{ feedItem?.events?.length - displayedEvents.length }} More</span>

        <span v-else>View Less</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import useTokenModal from '@/composition/tokens/useTokenModal';
import { computed, ref, toRefs } from 'vue';
import { formatCurrency } from '@/utils/filters';
import TokenImage from '../TokenImage.vue';
import RarityRank from '../RarityRank.vue';
import MarketplaceIcon from '../MarketplaceIcon.vue';
import WalletProfile from '../wallets/WalletProfile.vue';

const props = defineProps(['feedItem']);
const { feedItem } = toRefs(props);

const DEFAULT_MAX_EVENTS = 1;
const maxEvents = ref(DEFAULT_MAX_EVENTS);
const displayedEvents = computed(() => {
  if (!maxEvents.value) {
    return feedItem.value?.events || [];
  }

  return feedItem.value.events?.slice(0, maxEvents.value) || [];
});

const toggleShowMoreEvents = () => {
  if (maxEvents.value === 0) {
    maxEvents.value = DEFAULT_MAX_EVENTS;
  } else {
    maxEvents.value = 0;
  }
};

const { open } = useTokenModal();

</script>
