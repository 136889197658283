<template>
  <div class="flex">
    <div
      v-if="watchlist?.members"
      class="flex gap-3 items-center p-1 pr-5 hover:bg-gray-800 rounded-full border border-gray-800 transition-all transform active:translate-y-1 cursor-pointer"
    >
      <ul
        v-if="watchlist?.members"
        class="flex relative gap-0 pl-6 h-8"
      >
        <li
          v-for="(item, index) in previewWallets"
          :key="item.id"
          class="leading-none"
          :class="`-ml-6`"
          :style="`z-index: ${index}`"
        >
          <ProfilePicture
            :src="item.openseaProfile?.profilePictureUrl"
            :address="item.id"
            class="shadow-2xl"
          />
        </li>

        <li
          v-if="watchlist?.members.length > previewWallets.length"
          class="flex justify-center items-center w-8 h-8 text-xs font-bold leading-none text-orange-100 bg-gradient-to-t from-orange-600 to-orange-400 rounded-full bubble-shadow"
          :class="`-ml-6`"
          :style="`z-index: ${previewWallets.length}`"
        >
          +{{ watchlist?.members.length - previewWallets.length }}
        </li>
      </ul>

      <div class="leading-5 text-gray-200">
        {{ membershipText }}
      </div>
    </div>

    <div
      v-else
      class="p-1 w-64 bg-gray-800 rounded-full animate-pulse"
    >
      <div class="w-8 h-8" />
    </div>
  </div>
</template>

<script setup>

import { toRefs, computed } from 'vue';
import ProfilePicture from './ProfilePicture.vue';

const props = defineProps({
  watchlist: {
    type: Object,
    required: true,
  },
});
const { watchlist } = toRefs(props);

const previewWallets = computed(() => {
  if (!watchlist.value?.members) {
    return [];
  }

  return watchlist.value.members.slice(0, 2);
});

const membershipText = computed(() => {
  if (!watchlist.value?.members) {
    return '';
  }

  if (watchlist.value?.members.length === 1) {
    return `Including ${watchlist.value?.members[0]?.displayName}`;
  }

  if (watchlist.value?.members.length === 2) {
    return `Including ${watchlist.value?.members[0]?.displayName} and ${watchlist.value?.members[1]?.displayName}`;
  }

  return `Including ${watchlist.value?.members[0]?.displayName}, ${watchlist.value?.members[1]?.displayName} and ${watchlist.value.members.length - 2} others`;
});
</script>

<style>
.bubble-shadow {
  box-shadow: inset 0 1px 0 rgba(255,255,255,.35), 0 1px 3px rgba(0,0,0,.5);
  text-shadow: 0 1px 1px rgba(0,0,0,.25);
}
</style>
