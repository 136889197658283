<template>
  <UiModal
    v-model="isVisible"
  >
    <div class="flex flex-col gap-y-2 p-6">
      <div class="text-xl font-medium leading-snug">
        Slow down cowboy!
      </div>

      <div class="text-sm text-gray-300">
        It seems like you are enjoying Compass so much that you are exceeding our rate limits.
      </div>

      <UiButton
        :is-loading="isReloading"
        class="my-2"
        @click="reload"
      >
        Try Again
      </UiButton>

      <div class="text-xs text-gray-400">
        If you think this is a mistake, contact us in our Discord.
      </div>
    </div>
  </UiModal>
</template>

<script setup>
import { useApolloErrors } from '@/composition/errors';
import { computed, onMounted, ref } from 'vue';
import { useApolloClient } from '@vue/apollo-composable';
import UiModal from './ui/UiModal.vue';
import UiButton from './ui/UiButton.vue';

const {
  subscribe,
} = useApolloErrors();

const isReloading = ref(false);
const error = ref(null);
const isSolved = ref(true);
const tabCount = ref(0);

function getTabCount() {
  return JSON.parse(localStorage.getItem('tabs')).length;
}

const tabId = Math.random();
onMounted(() => {
  const tabs = JSON.parse(localStorage.getItem('tabs')) || [];
  if (tabs.indexOf(tabId) === -1) {
    tabs.push(tabId);
    localStorage.setItem('tabs', JSON.stringify(tabs));
  }
});

window.addEventListener('beforeunload', (e) => {
  const tabs = JSON.parse(localStorage.getItem('tabs'));
  const index = tabs.indexOf(tabId);
  if (index !== -1) { tabs.splice(index, 1); }
  localStorage.setItem('tabs', JSON.stringify(tabs));
});

const isVisible = computed({
  get() {
    return error.value !== null;
  },
  set() {
    // console.log('setting error handler visibility', value);
  },
});

const { resolveClient } = useApolloClient('default');
const apolloClient = resolveClient();
const reload = async () => {
  isReloading.value = true;
  isSolved.value = true;
  await apolloClient.resetStore();
  isReloading.value = false;
  if (isSolved.value) {
    error.value = null;
  }
};

subscribe((e) => {
  if (isReloading.value) {
    isSolved.value = false;
  }
  tabCount.value = getTabCount();
  if (error.value) {
    return;
  }

  error.value = e;
}, ['RATE_LIMITED']);
</script>
