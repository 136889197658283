<template>
  <div v-if="isActive">
    <slot />
  </div>
</template>

<script>
import {
  defineComponent,
  inject,
  onBeforeMount,
  toRefs,
  reactive,
  onBeforeUnmount,
} from 'vue';

export default defineComponent({
  name: 'UiTab',

  props: ['name'],

  setup(props) {
    const { name } = toRefs(props);

    const tabData = reactive({
      name: name.value,
      isActive: false,
    });

    const { isActive } = toRefs(tabData);

    const addTab = inject('addTab');
    const removeTab = inject('removeTab');

    onBeforeMount(() => {
      addTab(tabData);
    });

    onBeforeUnmount(() => {
      removeTab(tabData);
    });

    return {
      isActive,
    };
  },
});
</script>

<style scoped>

</style>
