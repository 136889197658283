<template>
  <div class="flex gap-2 items-center">
    <UiMultiSelect
      v-model="model"
      :options="displayedChannels"
      :disabled="disabled"
    />

    <UiButton
      variant="white"
      size="sm"
      @click="isCreateChannelVisible = true"
    >
      <i class="fas fa-plus" />
    </UiButton>
  </div>

  <UiModal v-model="isCreateChannelVisible">
    <CreateChannel @dismiss="isCreateChannelVisible" />
  </UiModal>
</template>

<script setup>
import {
  computed,
  toRefs,
  ref,
} from 'vue';
import { useUserChannelsGraphql } from '@/composition/user-channels';
import UiMultiSelect from '../ui/UiMultiSelect.vue';
import UiModal from '../ui/UiModal.vue';
import CreateChannel from './CreateChannel.vue';
import UiButton from '../ui/UiButton.vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  noTitle: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { modelValue, type, disabled } = toRefs(props);
const isCreateChannelVisible = ref(false);
const { channels, onResult } = useUserChannelsGraphql();
const emit = defineEmits(['update:modelValue']);

const displayedChannels = computed(() => {
  if (type.value) {
    return channels.value.filter((item) => item.type === type.value);
  }
  return channels.value;
});

const model = computed({
  get() {
    return modelValue.value || [];
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

// onResult((queryResult) => {
//   if (queryResult.data?.channels?.length === 1) {
//     emit('update:modelValue', queryResult.data.channels.map((item) => item.id));
//   }
// });
</script>

<style>
</style>
