<template>
  <ExternalLinks
    :mini="mini"
    :inline-dropdown="inlineDropdown"
    :right="right"
    :links="{
      opensea: `https://opensea.io/assets/ethereum/${collection.id}`,
      blur: `https://blur.io/collection/${collection.slug}`,
      looksrare: `https://looksrare.org/collections/${collection.id}`,
      x2y2: `https://x2y2.io/collection/${collection.id}`,
      uniswap: `https://app.uniswap.org/%23/nfts/collection/${collection.id}`,
      etherscan: `https://etherscan.io/token/${collection.id}`,
      externalWebsite: collection.externalUrl,
      discord: collection.discordUrl,
      twitter: `https://twitter.com/${collection.twitterUsername}`,
      telegram: collection.telegramUrl,
      medium: `https://medium.com/@${collection.mediumUsername}`,
      instagram: `https://www.instagram.com/${collection.instagramUsername}/`
    }"
  >
    <slot />
  </ExternalLinks>
</template>

<script setup>
import {
  toRefs,
} from 'vue';
import ExternalLinks from '../ExternalLinks.vue';
import UiLink from '../ui/UiLink.vue';

const props = defineProps({
  collection: null,
  noWrap: {
    default: false,
    type: Boolean,
  },
  mini: {
    default: false,
    type: Boolean,
  },
  inlineDropdown: {
    default: false,
    type: Boolean,
  },
  right: {
    default: false,
    type: Boolean,
  },
});

const {
  collection,
  mini,
  inlineDropdown,
  right,
} = toRefs(props);

</script>
