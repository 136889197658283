<template>
  <PortfolioTable
    v-model:sortBy="sortBy"
    v-model:sortDesc="sortDesc"
    v-model:searchTerm="searchTerm"
    v-model:filters="filters"
    :wallet-id="address"
    :is-loading="isLoading"
    :is-loading-more="isLoadingMore"
    :has-more="hasMore"
    :collections="collections"
    @loadMore="loadMore"
  />
</template>

<script setup>
import {
  computed,
  toRefs,
  ref,
} from 'vue';
import { useRoute } from 'vue-router';
import walletPortfolioQuery from '@/graphql/wallet/queries/walletPortfolioItems.query.gql';

import { usePremium } from '@/composition/premium';
import usePaginatedQuery from '@/composition/pagination/usePaginatedQuery';
import { useFiltersQuerySync } from '@/composition/filters/useFiltersQuerySync';
import PortfolioTable from '../PortfolioTable.vue';

const route = useRoute();
const { address } = route.params;

const sortBy = ref('realizedGains');
const sortDesc = ref(true);

const searchTerm = ref('');
const filters = ref([]);
useFiltersQuerySync(filters);
const {
  loading: isLoading,
  loadingMore: isLoadingMore,
  result: collectionsResult,
  loadMore: loadMoreAction,
  hasMore,
} = usePaginatedQuery(
  {
    take: 20,
    hasMoreCheck: (data) => data.wallet?.portfolio?.length === 20,
    mergeResults: (oldData, newData) => {
      const data = JSON.parse(JSON.stringify(oldData));

      data.wallet.portfolio = [
        ...data.wallet.portfolio,
        ...newData.wallet.portfolio,
      ];

      return data;
    },
  },
  walletPortfolioQuery,
  () => ({
    address,
    orderBy: sortBy.value,
    orderDesc: sortDesc.value,
    search: searchTerm.value,
    filters: filters.value,
  }),
  () => ({
    enabled: Boolean(address),
    debounce: 500,
  }),
);
const collections = computed(() => collectionsResult.value?.wallet.portfolio);

const { requirePremium } = usePremium();
async function loadMore() {
  await requirePremium('to view complete portfolio');

  return loadMoreAction();
}
</script>
