<template>
  <UiTable
    v-bind="$attrs"
    :key="period"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :is-loading="isLoading"
    :items="collections"
    :fields="fields"
    :sortable="['volume', 'salesCount', 'avgPrice', 'minPrice', 'maxPrice']"
    :show-skeleton="isLoadingMore"
    class="rounded-2xl"
    style="contain: paint"
  >
    <template #head(volume)>
      {{ period }} Volume
    </template>

    <template #head(salesCount)>
      Sales
    </template>

    <template #head(bidsFilledCount)>
      Bids Filled
    </template>

    <template #head(avgPrice)>
      Avg. Price
    </template>

    <template #head(minPrice)>
      Sales Floor
    </template>

    <template #head(floor)>
      Floor
    </template>

    <template #head(topBid)>
      Top Bid
    </template>

    <template #head(maxPrice)>
      Highest Sale
    </template>

    <template #head(volume_chart)>
      {{ period }} Volume
    </template>

    <template #cell(collection)="{item: {collection}}">
      <CollectionListItem :collection="collection">
        <template #actions>
          <slot
            name="collection-actions"
            :collection="collection"
          />
        </template>
      </CollectionListItem>
    </template>

    <template #cell(volume)="{item: {volume, previous}}">
      <MetricWithChange
        :value="volume"
        :previous-value="previous ? previous.volume : null"
        :formatter="formatCurrency"
        is-currency
      />
    </template>

    <template #cell(salesCount)="{item: {salesCount, previous}}">
      <MetricWithChange
        :value="salesCount"
        :previous-value="previous ? previous.salesCount : null"
        :formatter="formatNumber"
      />
    </template>

    <template #cell(bidsFilledCount)="{item: {bidsFilledCount, previous}}">
      <MetricWithChange
        :value="bidsFilledCount"
        :previous-value="previous ? previous.bidsFilledCount : null"
        :formatter="formatNumber"
      />
    </template>

    <template #cell(avgPrice)="{item: {avgPrice, previous}}">
      <MetricWithChange
        :value="avgPrice"
        :previous-value="previous ? previous.avgPrice : null"
        :formatter="formatCurrency"
        is-currency
      />
    </template>

    <template #cell(floor)="{item: {collection}}">
      <MetricWithChange
        :value="collection.listedFloor"
        :formatter="formatCurrency"
        :previous-value="collection.previousListedFloor"
        is-currency
      />
    </template>

    <template #cell(topBid)="{item: {collection}}">
      <MetricWithChange
        :value="collection.topOffer"
        :formatter="formatCurrency"
        is-currency
      />
    </template>

    <template #cell(spread)="{item: {collection}}">
      <MetricWithChange
        :value="collection.topOffer && collection.listedFloor ? Math.abs(collection.listedFloor - collection.topOffer) / collection.topOffer : null"
        :formatter="formatPercent"
      />
    </template>

    <template #cell(spreadEth)="{item: {collection}}">
      <MetricWithChange
        :value="collection.topOffer && collection.listedFloor ? collection.listedFloor - collection.topOffer : null"
        :formatter="formatCurrency"
      />
    </template>

    <template #cell(totalProfits)="{item: {collection}}">
      <MetricWithChange
        :value="collection.totalProfits"
        :formatter="formatCurrency"
        is-currency
      />
    </template>

    <template #cell(minPrice)="{item: {collection}}">
      <MetricWithChange
        :value="collection.salesFloor"
        :previous-value="collection.previousSalesFloor"
        :formatter="formatCurrency"
        is-currency
      />
    </template>

    <template #cell(maxPrice)="{item: {maxPrice, previous}}">
      <MetricWithChange
        :value="maxPrice"
        :previous-value="previous ? previous.maxPrice : null"
        :formatter="formatCurrency"
        is-currency
      />
    </template>

    <template #cell(created_at)="{item: {collection:{contract_created_at}}}">
      {{ moment(contract_created_at).from() }}
    </template>

    <template #cell(actions)="{item}">
      <slot
        name="actions"
        :item="item"
      />
    </template>

    <template #cell(volume_chart)="{item}">
      <UiLink
        :to="{
          name: routesNames.collectionAnalytics,
          params: {
            contractAddress: item.collection.id,
          }
        }"
      >
        <VolumeScatter
          :key="`${item.collection.id}_${period}`"
          type="column"
          :collection="item.collection"
          :chart-data="item.collection.charts.volume"
          :height="40"
          :interval="period"
          :max="maxVolume"
          @hasMax="setMaxVolume"
        />
      </UiLink>
    </template>

    <template #after>
      <slot name="after" />
    </template>
  </UiTable>
</template>

<script>
import { defineComponent } from 'vue';
import moment from 'moment-timezone';
import { formatNumber, formatCurrency, formatPercent } from '@/utils/filters';
import UiTable from '@/components/ui/UiTable.vue';
import UiLink from '@/components/ui/UiLink.vue';
import VolumeScatter from '@/components/VolumeScatter.vue';
import CollectionListItem from '@/components/collections/CollectionListItem.vue';
import MetricWithChange from '@/components/MetricWithChange.vue';
import routesNames from '@/router/routesNames';

// import UiButton from "@/components/ui/UiButton";
export default defineComponent({
  name: 'CollectionsTable',

  components: {
    MetricWithChange, CollectionListItem, VolumeScatter, UiLink, UiTable,
  },
  props: ['isLoading', 'collections', 'period', 'sortBy', 'sortDesc', 'prepend', 'preferredFields', 'showActions', 'isLoadingMore'],
  setup() {
    return {
      formatNumber,
      formatCurrency,
      moment,
      routesNames,
      formatPercent,
    };
  },
  data() {
    return {
      maxVolume: undefined,
    };
  },
  computed: {
    fields() {
      if (this.preferredFields) {
        return [...this.preferredFields, ...(this.showActions ? ['actions'] : [])];
      }

      return ['collection', 'volume', 'salesCount', 'avgPrice', 'floor', 'totalProfits', 'maxPrice', 'volume_chart', ...(this.showActions ? ['actions'] : [])];
    },
  },
  watch: {
    collections: {
      handler() {
        this.maxVolume = undefined;
      },
      deep: true,
    },
  },
  methods: {
    setMaxVolume(value) {
      if (!this.maxVolume || value > this.maxVolume) {
        this.maxVolume = value;
      }
    },
  },
});
</script>
