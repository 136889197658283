<template>
  <div
    :class="classes"
  >
    <span v-if="mini === false">{{ value }}</span>

    <span
      v-else
      v-tooltip="value === 'Dead' ? 'No liquidity' : `${value} liquidity`"
    >{{ value.slice(0, 1) }}</span>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue';

const props = defineProps({
  value: null,
  mini: {
    type: Boolean,
    default: false,
  },
});
const { value, mini } = toRefs(props);

const colorClasses = computed(() => {
  switch (value.value) {
    case 'Dead':
      return 'bg-red-500 text-red-100';
    case 'Low':
      return 'bg-orange-500 text-orange-900';
    case 'Medium':
      return 'bg-yellow-500 text-yellow-900';
    case 'High':
      return 'bg-green-500 text-green-900';
    default:
      return '';
  }
});

const classes = computed(() => [
  colorClasses.value,
  mini.value === false ? 'inline-block py-1 px-1.5 text-xs font-medium leading-none rounded-lg' : ' text-xs rounded px-1 py-0.5 leading-none',
]);
</script>
