<template>
  <UiCard
    title="Floor Price"
    class="flex flex-col mb-6 4xl:mb-0 h-full"
    :slim="slim"
  >
    <template #title-right>
      <PeriodSelector
        v-model="interval"
        :periods="['15m', '1d']"
      />
    </template>

    <UiCardBody class="relative flex-1 pr-0 sm:pr-0 h-full">
      <LightweightChart
        ref="lwChart"
        type="area"
        :data="data"
        :autosize="true"
        :chart-options="chartOptions"
        :series-options="seriesOptions"
      />

      <div
        v-if="!isRefetching && isChartDataLoading"
        class="flex absolute top-0 left-0 justify-center items-center w-full h-full bg-white dark:bg-gray-850"
      >
        <LoadingIndicator class="text-indigo-600" />
      </div>
    </UiCardBody>
  </UiCard>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import {
  computed, ref, toRefs,

} from 'vue';
import { useRefetchQueries } from '@/composition/refetch-queries';

import collectionPricingChartDataQuery from '@/graphql/collection/queries/collectionPricingChartData.query.gql';
import PeriodSelector from '@/components/PeriodSelector.vue';
import UiCard from '@/components/ui/UiCard.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import moment from 'moment';
import { trueGray } from 'tailwindcss/colors';
import { formatCurrency } from '@/utils/filters';
import LightweightChart from '../LightweightChart.vue';
import UiCardBody from '../ui/UiCardBody.vue';

const props = defineProps(['collection', 'slim']);
const { collection } = toRefs(props);
const interval = ref('1d');

const {
  result: chartDataResult,
  refetch: refetchChartData,
  loading: isChartDataLoading,
} = useQuery(collectionPricingChartDataQuery, () => ({ contractAddress: collection.value.id, interval: interval.value }));
const chartData = computed(() => chartDataResult.value?.collection?.analytics);

const { isRefetching } = useRefetchQueries({
  // analytics: refetchAnalytics,
  'chart-data': refetchChartData,
});

const priceChartData = computed(() => {
  const {
    averagePriceChart = [],
    volume = [],
    salesCount = [],
  } = chartData.value || {};

  return {
    salesFloor: chartDataResult.value?.collection?.floorPriceChart || [],
    averagePrice: averagePriceChart,
    volume,
    salesCount,
  };
});

const chartOptions = ref({
  layout: {
    background: { color: 'transparent' },
    textColor: trueGray[400],
    fontFamily: "'JetBrains Mono', monospace",
  },
  grid: {
    vertLines: {
      visible: false,
    },
    horzLines: {
      color: trueGray[700],
      style: 0,
      visible: true,
      width: 0.5,
    },
  },
  localization: {
    priceFormatter: (price) => formatCurrency(price),
  },
  rightPriceScale: {
    borderColor: 'rgba(0,0,0,0)',
    visible: true,
  },
  crosshair: {
    vertLine: {
      color: '#bfed72',
      width: 0.5,
      style: 1,
      visible: true,
      labelVisible: true,
    },
    horzLine: {
      visible: false,
      labelVisible: true,
    },
  },
  timeScale: {
    borderColor: '#646369',
    timeVisible: true,
    secondsVisible: false,
  },
});
const data = computed(() => priceChartData.value.salesFloor?.filter((item) => item.value !== null)?.map((item) => ({ time: moment(item.key).toDate().getTime() / 1000, value: item.value })) || []);
const seriesOptions = ref({
  lineColor: 'rgba(191, 237, 114, 1)',
  topColor: 'rgba(191, 237, 114, 0.5)',
  bottomColor: 'rgba(191, 237, 114, 0)',
  lineWidth: 2,
});
const lwChart = ref();

</script>
