<template>
  <UiCard
    title="Marketplace Volume"
    :slim="slim"
  >
    <template #title-right>
      <div class="flex items-center space-x-4">
        <PeriodSelector
          v-if="!defaultPeriod"
          v-model="period"
          :periods="['7d', '30d', '90d', '180d', '1y', '2y']"
          :max-enabled="5 * 365 * 24 * 60 * 60"
        />

        <UiDropdown
          toggle-text="Filter"
          :toggle-props="{ size: 'xs' }"
          right
          body-width-class="w-64"
        >
          <template #toggle="{toggle}">
            <button
              type="button"
              class="
                ml-2
                text-lg
                dark:text-gray-200 dark:hover:text-indigo-500
                transition-colors
              "
              @click="toggle"
            >
              <i class="fad fa-cog" />
            </button>
          </template>

          <template #body>
            <UiDropdownForm>
              <div class="flex flex-col space-y-2">
                <div class="text-sm font-bold">
                  Options
                </div>

                <UiToggle
                  v-model="isStacked"
                  reverse
                  size="sm"
                  justify="between"
                >
                  Stack
                </UiToggle>

                <UiToggle
                  v-model="excludeWashTrades"
                  reverse
                  size="sm"
                  justify="between"
                >
                  Exclude wash-trading
                </UiToggle>
              </div>
            </UiDropdownForm>
          </template>
        </UiDropdown>
      </div>
    </template>

    <UiCardBody :slim="slim">
      <Chart
        :options="chartOptions"
        :data-formatter="formatCurrency"
        :is-loading="loading"
        watermark
      />
    </UiCardBody>
  </UiCard>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { ref, computed, toRefs } from 'vue';
import moment from 'moment';
import { formatCurrency } from '@/utils/filters';
import { groupBy, mapValues } from 'lodash-es';
import UiToggle from '@/components/ui/UiToggle.vue';
import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownForm from '@/components/ui/UiDropdownForm.vue';
import UiCard from '../ui/UiCard.vue';
import PeriodSelector from '../PeriodSelector.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import Chart, { createTooltip } from '../Chart.vue';

const props = defineProps(['slim', 'defaultPeriod']);
const { slim, defaultPeriod } = toRefs(props);
const period = ref(defaultPeriod.value || '90d');
const excludeWashTrades = ref(true);
const isStacked = ref(true);

const query = gql`
query GetMarketplaceVolume($period: String!, $excludeWashTrades: Boolean){
  marketplaceVolume(period: $period, excludeWashTrades: $excludeWashTrades)
}
`;

const {
  result,
  loading,
} = useQuery(query, () => ({ period: period.value, excludeWashTrades: excludeWashTrades.value }));

const chartData = computed(() => groupBy(result.value?.marketplaceVolume, 'marketplace'));

const marketplaceOrder = ['Opensea', 'LooksRare', 'X2Y2', 'Sudoswap', 'Blur'];

const marketplaceColor = {
  Opensea: 100,
  LookRare: 101,
  X2Y2: 102,
  Sudoswap: 103,
  Blur: 104,
};

const chartOptions = computed(() => ({
  chart: {
    zoomType: 'x',
    // type: 'area',
  },
  xAxis: {
    type: 'datetime',
  },
  tooltip: createTooltip({
    formatters: {
      Opensea: (value) => formatCurrency(value),
      LooksRare: (value) => formatCurrency(value),
      X2Y2: (value) => formatCurrency(value),
      Sudoswap: (value) => formatCurrency(value),
      Blur: (value) => formatCurrency(value),
      other: (value) => formatCurrency(value),
    },
  }),
  plotOptions: {
    column: {
      stacking: isStacked.value ? 'normal' : false,
      dataLabels: {
        enabled: false,
      },
    },
  },
  legend: {
    enabled: true,
  },
  series: Object.entries(chartData.value).map(([marketplace, data]) => ({
    className: `${marketplace.toLowerCase()}-branded-chart`,
    colorIndex: marketplaceColor[marketplace],
    data: data.map((item) => [
      parseInt(moment(item.timestamp).format('x')),
      item.value,
    ]) || [],
    type: 'column',
    name: marketplace,
  })).sort((a, b) => marketplaceOrder.indexOf(a.name) - marketplaceOrder.indexOf(b.name)),
}));

</script>

<style scoped>

:deep(.x2y2-volume-chart),
:deep(.x2y2-volume-chart .highcharts-point) {
  fill: #562EC8;
}

:deep(.sudoswap-volume-chart),
:deep(.sudoswap-volume-chart .highcharts-point) {
  fill: #b9b9ff;
}

:deep(.blur-volume-chart),
:deep(.blur-volume-chart .highcharts-point) {
  fill: #E7672B;
}
</style>
