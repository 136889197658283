import { isEqual } from 'lodash-es';
import { ref } from 'vue';

const dialogs = ref([]);
const asks = ref([]);
const notifications = ref([]);
export default function useDialogs() {
  function createDialog(arr, options) {
    const confirmCallbacks = [];

    const onConfirmed = () => {
      confirmCallbacks.forEach((callback) => callback.resolve());
    };
    const onCancelled = () => {
      confirmCallbacks.forEach((callback) => callback.reject());
    };

    const item = {
      id: Number(Math.random() * 1000000).toString(16),
      onConfirmed,
      onCancelled,
      ...options,
    };
    arr.value = [...arr.value, item];

    const confirmed = () => new Promise((resolve, reject) => {
      confirmCallbacks.push({ resolve, reject });
    }).then(() => true)
      .catch(() => false)
      .finally(() => {
        arr.value = arr.value.filter((dialog) => dialog.id !== item.id);
      });

    return {
      confirmed,
      item,
      arr,
    };
  }
  const open = (options) => createDialog(dialogs, options);

  const ask = (options) => {
    const value = ref(options.defaultValue || null);
    const { confirmed } = createDialog(asks, {
      ...options,
      value,
    });

    return {
      confirmed: () => confirmed().then((res) => {
        if (res) {
          return value.value;
        }

        return null;
      }),
    };
  };

  return {
    dialogs,
    asks,
    notifications,
    open,
    ask,
    notify: (options) => {
      const id = Number(Math.random() * 1000000).toString(16);
      notifications.value = [...notifications.value, {
        id,
        ...options,
      }];

      setTimeout(() => {
        notifications.value = notifications.value.filter((notification) => notification.id !== id);
      }, 3000);
    },
  };
}
