<template>
  <UiSearchInput
    v-model="searchText"
    class="mb-3"
  >
    <template #input>
      <UiInput
        v-model="searchText"
        :autofocus="true"
        :placeholder="'Search variables'"
        class="pl-10"
      />
    </template>
  </UiSearchInput>

  <div
    v-if="searchText.length === 0"
    class="flex flex-col gap-3"
  >
    <Accordion
      v-for="(variables, groupName) in grouppedVariables"
      :key="groupName"
      v-model="accordionValue"
      size="sm"
      no-transition
      :name="variables?.[0]?.group || displayName(humanize(groupName))"
    >
      <template #name>
        <div class="flex items-center">
          {{ variables?.[0]?.groupName || displayName(humanize(groupName)) }}
          <!-- Hint -->
          <div
            v-if="variables?.[0]?.group === 'recommended'"
            v-tooltip="'Based on the expected value of the field'"
            class="ml-1 text-gray-400"
          >
            <i class="fad fa-info-circle" />
          </div>
        </div>
      </template>

      <ul class="flex flex-col p-1">
        <li
          v-for="item in variables"
          :key="item.id"
          class=" "
          @click="emit('select', item)"
        >
          <div
            class="flex gap-4 items-center py-2 px-3 hover:bg-white hover:bg-opacity-5 rounded-xl cursor-pointer"
            :class="{'opacity-50': schema.type && item.type !== schema.type}"
          >
            <VariableType :type="displayName(item.type)" />

            <div>
              <div class="flex flex-wrap items-center text-sm font-medium">
                <div
                  v-if="item.originalGroup"
                  class="py-0.5 px-1 mr-1 text-xs leading-none bg-gray-700 rounded"
                >
                  {{ displayName(humanize(item.originalGroup)) }}
                </div>

                <span v-if="item.actingAs">{{ actingAsById[item.actingAs].name }} <span class="text-sm text-gray-400">{{ item.name }}</span></span>

                <span v-if="!item.actingAs">{{ item.name }}</span>

                <div
                  v-if="schema.type && item.type !== schema.type"
                  v-tooltip="schema.type && item.type !== schema.type ? `The expected type is ${schema.type}, but the actual type is ${item.type}. You can still use it, but be aware that the result may not match your expectations.` : ''"
                >
                  <i class="ml-1 text-yellow-500 fad fa-exclamation-triangle" />
                </div>
              </div>

              <div class="text-xs text-gray-400">
                {{ item.description }}
              </div>
            </div>
          </div>

          <div v-if="item.children && item.children.length">
            <ul class="flex flex-col p-1 ml-4">
              <li
                v-for="child in item.children"
                :key="child.id"
                class="flex gap-4 items-center py-2 px-3 hover:bg-white hover:bg-opacity-5 rounded-xl cursor-pointer"
                :class="{'opacity-50': schema.type && child.type !== schema.type}"
                @click="emit('select', child)"
              >
                <VariableType :type="displayName(child.type)" />

                <div>
                  <div class="flex flex-wrap items-center text-sm font-medium">
                    <div
                      v-if="child.originalGroup"
                      class="py-0.5 px-1 mr-1 text-xs leading-none bg-gray-700 rounded"
                    >
                      {{ displayName(humanize(child.originalGroup)) }}
                    </div>

                    <span v-if="child.actingAs">{{ actingAsById[child.actingAs].name }} <span class="text-sm text-gray-400">{{ child.name }}</span></span>

                    <span v-if="!child.actingAs">{{ child.name }}</span>

                    <div
                      v-if="schema.type && child.type !== schema.type"
                      v-tooltip="schema.type && child.type !== schema.type ? `The variable has a type mismatch. The expected type is ${schema.type}, but the actual type is ${child.type}. You can still use the variable, but be aware that the result may not match your expectations.` : ''"
                    >
                      <i class="ml-1 text-yellow-500 fad fa-exclamation-triangle" />
                    </div>
                  </div>

                  <div class="text-xs text-gray-400">
                    {{ child.description }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </Accordion>
  </div>

  <div v-else>
    <ul class="flex flex-col">
      <li
        v-for="item in searchResults"
        :key="item.id"
        class="flex gap-4 items-center py-2 px-3 hover:bg-white hover:bg-opacity-5 rounded-xl cursor-pointer"
        @click="emit('select', item)"
      >
        <VariableType :type="displayName(item.type)" />

        <div>
          <div class="flex flex-wrap items-center text-sm font-medium">
            <div class="py-0.5 px-1 mr-1 text-xs leading-none bg-gray-700 rounded">
              {{ displayName(humanize(item.groupName || item.group)) }}
            </div>
            {{ item.name }}
          </div>

          <div class="text-xs text-gray-400">
            {{ item.description }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { IO_ACTING_AS } from '@/constants/automation';
import { displayName, humanize } from '@/utils/filters';
import {
  keyBy, groupBy, omit, uniqBy,
} from 'lodash-es';
import {
  computed, toRefs, ref, onMounted, inject, watch,
} from 'vue';

import useVariablePicker from '@/composition/automation/use-variable-picker';
import flexsearch from 'flexsearch';
import Accordion from '../Accordion.vue';
import UiInput from '../ui/UiInput.vue';
import UiSearchInput from '../ui/UiSearchInput.vue';
import VariableType from './VariableType.vue';

const {
  includedActions,
} = useVariablePicker();
const props = defineProps(['schema']);

const { schema } = toRefs(props);

const allVariables = inject('variables');

const enabledVariables = computed(() => {
  if (!includedActions.value) {
    return allVariables.value;
  }

  return allVariables.value.filter((v) => {
    if (!v.actionId) {
      return true;
    }

    return includedActions.value.includes(v.actionId);
  });
});

const searchText = ref('');
const actingAsById = keyBy(IO_ACTING_AS, 'id');
const emit = defineEmits(['select']);

const recommended = computed(() => {
  if (!schema.value) {
    return {};
  }

  // if (!schema.value.actingAs) {
  //   return {};
  // }
  const r = enabledVariables.value.map((v) => [
    v,
    ...(v.children || []),
  ]).flat().filter((v) => {
    if (v.actingAs && schema.value.actingAs && v.actingAs === schema.value.actingAs) {
      return true;
    }

    if (v.type === schema.value.type) {
      return true;
    }

    return false;
  }).sort((a, b) => {
    if (a.actingAs === schema.value.actingAs) {
      return -1;
    }

    if (b.actingAs === schema.value.actingAs) {
      return 1;
    }

    return 0;
  })
    .map((v) => ({
      ...v,
      group: 'recommended',
      groupName: 'Recommended',
      originalGroup: v.groupName || v.group,
      children: [],
    }));

  if (r.length === 0) {
    return {};
  }

  return {
    recommended: r,
  };
});

const grouppedVariables = computed(() => ({
  ...recommended.value,
  ...groupBy(enabledVariables.value, 'group'),
}));

const groupCount = computed(() => Object.keys(grouppedVariables.value).length);
const accordionValue = ref('recommended');
onMounted(() => {
  if (groupCount.value === 1) {
    [accordionValue.value] = Object.keys(grouppedVariables.value);
  }
});

const createIndex = () => new flexsearch.Document({
  tokenize: 'forward',
  document: {
    id: 'id',
    store: true,
    index: [
      'id',
      'name',
      'description',
      'groupName',
    ],
  },
});

let index = createIndex();

watch(() => enabledVariables.value, (variables) => {
  index = createIndex();
  variables.forEach((item) => {
    index.add(omit(item, ['children']));

    item.children?.forEach?.((child) => index.add(child));
  });
}, {
  immediate: true,
});

const searchResults = computed(() => {
  const results = index.search(searchText.value, {
    enrich: true,
  });

  return uniqBy(results.map((item) => item.result.map((i) => i.doc)).flat(), 'id');
});
</script>
