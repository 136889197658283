<template>
  <div
    class="flex gap-3 items-center"
  >
    <div v-if="value.value">
      <span class="font-bold">{{ trait?.name }}</span>
      {{ comparatorMap[value.value.comparator] }}
      {{ formatNumber(value.value.value) }}
    </div>

    <div
      v-else
      class="font-italic"
    >
      Invalid filter
    </div>
  </div>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import { toRefs, computed } from 'vue';
import gql from 'graphql-tag';
import { formatNumber } from '@/utils/filters';

const comparatorMap = {
  eq: 'equals',
  gt: 'greater than',
  gte: 'greater than or equal to',
  lt: 'less than',
  lte: 'less than or equal to',
};
const props = defineProps(['filter', 'value']);
const { value } = toRefs(props);
const query = gql`
  query ($id: String!) {
  attribute(where:{id:$id}) {
    id
    name
  }
}`;

const { result } = useQuery(query, () => ({ id: value.value.value?.traitId }), () => ({ enabled: value.value.value?.traitId }));
const trait = computed(() => result.value?.attribute || null);

</script>
