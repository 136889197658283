<template>
  <ActionInputMapper
    v-model="value"
    :inputs="action.inputs"
    :trigger="trigger"
    :readonly="readonly"
  >
    <template #input(actions)="{model, updateModel, validationIssues}">
      <AutomationActionEditor
        :model-value="model"
        :validation-issues="validationIssues"
        :readonly="readonly"
        :trigger="trigger"
        @update:modelValue="updateModel"
      />
    </template>
  </ActionInputMapper>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import ActionInputMapper from '../ActionInputMapper.vue';
import AutomationActionEditor from '../AutomationActionEditor.vue';

const props = defineProps({
  modelValue: null,
  action: null,
  trigger: null,
  readonly: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const {
  modelValue, action, trigger, readonly,
} = toRefs(props);

const value = computed({
  get() {
    return modelValue.value || {
      items: '',
      actions: [],
    };
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

</script>
