<template>
  <!-- Active: "bg-gray-100", Not Active: "" -->
  <Component
    :is="tag"
    v-bind="$attrs"
    class="block py-2 px-4 text-sm text-gray-700 dark:text-gray-200 rounded-lg shadow-3xl transition-colors cursor-pointer"
    :class="{'hover:bg-gray-50 dark:hover:bg-gray-800': !isActive, 'bg-gray-100 dark:bg-gray-600': isActive}"
  >
    <slot />
  </Component>
</template>

<script>
export default {
  name: 'UiDropdownItem',
  props: {
    tag: {
      default: 'div',
      type: String,
    },
    isActive: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped>

</style>
