<template>
  <div class="flex flex-wrap gap-3 items-center text-white">
    <slot name="before-filters" />

    <div
      v-for="(item, index) in allFilters"
      :key="index"
      class="flex gap-3 items-center"
    >
      <div
        v-if="mode !== 'and' && index > 0"
        class="italic font-light text-gray-300"
      >
        {{ mode }}
      </div>

      <FilterItem
        v-if="item.type !== FILTER_TYPES.LOGICAL"
        ref="filterItems"
        :item="item"
        :filter-config="filterConfig"
        :validate-filter="validateFilter"
        :is-pinned="isPinned"
        :filter-name="filterName"
        :filter-display-value="filterDisplayValue"
        :remove-filter="removeFilter"
        :filter-component="filterComponent"
        :filter-props="(key) => ({...filterProps(key), ...extraFilterProps})"
        :update-filter-value="updateFilterValue"
        :disabled="disabled !== false"
      />

      <div
        v-if="item.type === FILTER_TYPES.LOGICAL"
        class="rounded-lg ring-1 ring-gray-500 ring-offset-black ring-off"
        :class="[`ring-offset-${depthRingOffsets[level + 1]}`]"
      >
        <Filters
          v-if="item.value"
          :model-value="item.value"
          :filters="filters"
          :level="level + 1"
          :mode="item.key.replace('$', '')"
          :disabled="disabled !== false"
          @update:modelValue="updateFilterValue({ item, value: $event })"
        >
          <template #after-filters>
            <UiButton
              variant="white"
              size="sm"
              :disabled="disabled !== false"
              @click="removeFilter(item)"
            >
              Remove
            </UiButton>
          </template>
        </Filters>
      </div>
    </div>

    <AddFilterButton
      :show-clear="modelValue?.length > 0"
      :filters="filters"
      :disabled="disabled !== false"
      @add="addFilter"
      @removeAll="removeAll"
    />

    <slot name="after-filters" />
  </div>
</template>

<script setup>
import {
  toRefs, ref, computed, nextTick,
} from 'vue';
import { FILTER_TYPES } from '@/constants/filters';

import UiButton from '../ui/UiButton.vue';
import useFilters from '../../composition/filters/useFilters';
import FilterItem from './components/FilterItem.vue';
import AddFilterButton from './AddFilterButton.vue';

const depthRingOffsets = [0, 1, 2, 4, 8].reverse();

const props = defineProps({
  modelValue: null,
  filters: null,
  group: null,
  level: {
    type: Number,
    default: 0,
  },
  mode: {
    type: String,
    default: 'and',
  },
  filterProps: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },

});
const emit = defineEmits(['update:modelValue']);

const {
  modelValue, filters, group, mode, filterProps: extraFilterProps, disabled,
} = toRefs(props);
const filterItems = ref([]);
const model = computed({
  get: () => modelValue.value,
  set: (value) => emit('update:modelValue', value),
});

const {
  allFilters,
  pinFilter,
  addFilter,
  removeFilter,
  removeAll,
  onUpdated,
  onAdded,
  filterComponent,
  filterName,
  filterProps,
  filterDisplayValue,
  filterConfig,
  updateFilterValue,
  validateFilter,
  isPinned,
} = useFilters(filters, model, group);

onUpdated((value) => {
  emit('update:modelValue', value);
});

const addFilterToLogicalGroup = (logicalGroup, filter) => {
  const newFilter = {
    ...filter,
    value: null,
  };

  const newLogicalGroup = {
    ...logicalGroup,
    value: [
      ...logicalGroup.value,
      newFilter,
    ],
  };

  const newModel = model.value.map((item) => {
    if (item.key === logicalGroup.key) {
      return newLogicalGroup;
    }

    return item;
  });

  emit('update:modelValue', newModel);
};

onAdded(async () => {
  await nextTick();
  const last = filterItems.value?.at(-1);

  if (!last) {
    return;
  }

  last.toggle();
});

</script>
