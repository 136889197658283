<script> </script>
<script setup>
import { ref } from 'vue';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper } from 'swiper/vue';

// export default { inheritAttrs: false };

const random = ref(Math.floor(Math.random() * 100).toString());
const bulletActiveClass = 'custom-swiper__bullet-active';
const bulletClass = 'custom-swiper__bullet';
const paginationContainer = 'custom-swiper__pagination-container';
const prevEl = ref(`custom-swiper__prev-el-${random.value}`);
const nextEl = ref(`custom-swiper__next-el-${random.value}`);
const disabledClass = 'custom-swiper__nav-btn-disabled';
const outsideNavClass = 'custom-swiper__outside-nav';

const props = defineProps({
  navigation: {
    type: [Boolean, Object],
    default: false,
  },
  pagination: {
    type: [Boolean, Object],
    default: false,
  },
  autoplay: {
    type: [Boolean, Object],
    default: false,
  },
});

function generateNavigationOptions() {
  if (!props.navigation) {
    return null;
  }

  const navigationOpt = {
    prevEl: `.${prevEl.value}`,
    nextEl: `.${nextEl.value}`,
    disabledClass,
  };

  if (typeof props.navigation === 'boolean') {
    return navigationOpt;
  }

  if (typeof props.navigation === 'object') {
    return { ...props.pagination, ...navigationOpt };
  }

  return null;
}

function generatePaginationOptions() {
  if (!props.pagination) {
    return null;
  }

  const paginationOpt = {
    bulletActiveClass,
    bulletClass,
    el: `.${paginationContainer}-${random.value}`,
  };

  if (typeof props.pagination === 'boolean') {
    return paginationOpt;
  }

  if (typeof props.pagination === 'object') {
    return { ...props.pagination, ...paginationOpt };
  }

  return null;
}

function generateAutoplayOptions() {
  if (!props.autoplay) {
    return null;
  }

  return props.autoplay;
}

function generateSwiperModules() {
  const modules = [];
  if (props.navigation) {
    modules.push(Navigation);
  }

  if (props.pagination) {
    modules.push(Pagination);
  }

  if (props.autoplay) {
    modules.push(Autoplay);
  }

  return modules;
}

const modules = ref(generateSwiperModules());
const paginationOpt = ref(generatePaginationOptions());
const navigationOpt = ref(generateNavigationOptions());
const autoplayOpt = ref(generateAutoplayOptions());
</script>

<template>
  <div class="relative">
    <slot
      v-if="navigationOpt"
      name="outside-navigation"
      v-bind="{ prevEl, nextEl, outsideNavClass }"
    />

    <Swiper
      v-bind="$attrs"
      :modules="modules"
      :pagination="paginationOpt"
      :navigation="navigationOpt"
      :autoplay="autoplayOpt"
    >
      <slot />
    </Swiper>

    <div
      v-if="paginationOpt"
      class="flex flex-wrap justify-center pt-7"
      :class="[ `${paginationContainer}-${random}`]"
    />
  </div>
</template>

<style lang="scss">
@import "~swiper/scss";
@import "~swiper/scss/navigation";
@import "~swiper/scss/pagination";

.custom-swiper {
  &__pagination-container {
    @apply flex justify-center pt-3
  }

  &__outside-nav {
    @apply absolute right-0;
    transform: translateY(calc(-100% - 24px));
  }

  &__bullet {
    @apply inline-block w-2.5 h-2.5 p-2 mx-1 mt-6 rounded-full bg-indigo-900 cursor-pointer;
  }

  &__bullet-active {
    @apply bg-indigo-500;
  }

  &__nav-btn-disabled {
    @apply opacity-50 cursor-default;
  }
}
</style>
