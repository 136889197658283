<template>
  <div class="flex gap-4">
    <ExternalLinks
      :mini="mini"
      :inline-dropdown="inlineDropdown"
      :links="{
        etherscan: `https://etherscan.io/nft/${collection.id}/${tokenId}`,
        opensea: `https://opensea.io/assets/ethereum/${collection.id}/${tokenId}`,
        looksrare: `https://looksrare.org/collections/${collection.id}/${tokenId}`,
        x2y2: `https://x2y2.io/eth/${collection.id}/${tokenId}`,
      }"
    />
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import ExternalLinks from './ExternalLinks.vue';

const props = defineProps({
  collection: null,
  tokenId: null,
  mini: {
    type: Boolean,
    default: false,
  },
  inlineDropdown: {
    type: Boolean,
    default: false,
  },
});

const {
  collection, tokenId, mini, inlineDropdown,
} = toRefs(props);
</script>
