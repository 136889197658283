<template>
  <UiCard
    :title="(privateKey.name || 'Unnamed Wallet')"
    slim
  >
    <template #title-right>
      <div class="flex gap-4 items-center text-xl leading-none">
        <i
          v-if="privateKey.type === 'privateKey'"
          v-tooltip="'Compass Generated Wallet'"
          class=" text-indigo-500 fad fa-lock"
        />

        <i
          v-else-if="privateKey.type === 'remoteSigner'"
          v-tooltip="'Imported Wallet'"
          class=" text-blue-500 fad fa-key"
        />

        <div
          v-if="privateKey.type === 'remoteSigner'"
          v-tooltip="privateKey.isOnline ? (isKeyServedLocally ? 'Online, served from this browser' : 'Online, served from a different browser') : 'Offline'"
          class="w-3 h-3 rounded-full"
          :class="privateKey.isOnline ? 'bg-green-500' : 'bg-red-500'"
        />
      </div>
    </template>

    <UiCardBody slim>
      <div class="flex justify-between items-center mb-4">
        <WalletProfile :wallet="privateKey.wallet" />

        <div class="flex gap-3 items-center">
          <MetricWidget
            v-tooltip="`ETH: ${formatCurrency(privateKey.wallet.ethBalance)} WETH: ${formatCurrency(privateKey.wallet.wethBalance)} BETH: ${formatCurrency(privateKey.wallet.bethBalance)}`"
            :value="privateKey.wallet.ethBalance + privateKey.wallet.wethBalance + privateKey.wallet.bethBalance"
            title="ETH"
            :small="true"
            :formatter="formatCurrency"
          />
        </div>
      </div>

      <AddressDisplay
        class="mb-3 text-xs"
        :address="privateKey.address"
      />

      <div class="flex justify-between items-center">
        <div class="flex gap-2 items-center">
          <UiButton
            size="sm"
            variant="primary"
            @click="isDepositModalVisible = true"
          >
            Deposit
          </UiButton>

          <UiButton
            size="sm"
            variant="white"
            @click="isWithdrawModalVisible = true"
          >
            Withdraw
          </UiButton>
        </div>

        <div>
          <UiDropdown>
            <template #toggle="{toggle}">
              <UiButton
                variant="white"
                size="sm"
                @click="toggle"
              >
                <i class="far fa-ellipsis-h" />
              </UiButton>
            </template>

            <template #body>
              <UiDropdownItem @click="exportPrivateKey(privateKey.id)">
                Export private key
              </UiDropdownItem>

              <UiDropdownItem
                v-if="isKeyServedLocally"
                @click="removeKeyFromLocalStorage(privateKey)"
              >
                Stop serving and remove key
              </UiDropdownItem>
            </template>
          </UiDropdown>
        </div>
      </div>
    </UiCardBody>
  </UiCard>

  <WithdrawModal
    v-model="isWithdrawModalVisible"
    :private-key="privateKey"
  />

  <DepositModal
    v-model="isDepositModalVisible"
    :private-key="privateKey"
  />
</template>

<script setup>
import MetricWidget from '@/components/MetricWidget.vue';
import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import WalletProfile from '@/components/wallets/WalletProfile.vue';
import { formatCurrency } from '@/utils/filters';
import { toRefs, ref, computed } from 'vue';

import useDialogs from '@/components/ui/composition/use-dialogs';
import { copyTextToClipboard } from '@/utils/clipboard';
import useAutomationWallets from '@/composition/automation/use-private-keys';
import UiButton from '@/components/ui/UiButton.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiCardBody from '@/components/ui/UiCardBody.vue';
import { useStore } from 'vuex';

import WithdrawModal from './WithdrawModal.vue';
import DepositModal from './DepositModal.vue';
import AddressDisplay from '../AddressDisplay.vue';
import { init, reset } from '../../remote-signing';
import * as signingStorage from '../../remote-signing/storage';

const { exportPrivateKey: exportPrivateKeyMutation } = useAutomationWallets();
const props = defineProps({
  privateKey: {
    type: Object,
    required: true,
  },
});

const { privateKey } = toRefs(props);

const { open } = useDialogs();

const exportPrivateKey = (id) => exportPrivateKeyMutation({ id }).then(async ({ data }) => {
  copyTextToClipboard(data.privateKey);
  const { confirmed } = open({
    title: 'Private key exported',
    message: 'The private key has been exported to your clipboard.',
    confirmText: 'OK',
  });

  await confirmed();
});

const store = useStore();

const removeKeyFromLocalStorage = async () => {
  const { confirmed: didConfirm } = open({
    title: 'Stop serving wallet',
    message: 'Are you sure you want to stop serving this wallet? All automations using this wallet will stop working. You will need to re-import the wallet to start serving it again.',
    confirmText: 'Continue',
  });

  if (!await didConfirm()) {
    return;
  }

  signingStorage.set('automation-imported-wallets', {
    ...signingStorage.get('automation-imported-wallets', []),
    [privateKey.value.address]: undefined,
  });
  reset();
  init(signingStorage.get('automation-imported-wallets', []));
};

const isKeyServedLocally = computed(() => store.getters['auth/isKeyServed'](privateKey.value.address));
const isWithdrawModalVisible = ref(false);
const isDepositModalVisible = ref(false);
</script>
