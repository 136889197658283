<template>
  <UiModal
    v-model="isVisible"
  >
    <UiModalHeader
      title="Create group from holders"
      @dismiss="isVisible = false"
    />

    <div class="p-3">
      <UiInputGroup label="Top N">
        <UiInput
          v-model="topN"
          placeholder="10"
        />
      </UiInputGroup>
    </div>

    <UiModalFooter>
      <UiButton
        :is-loading="isCreating"
        @click="create"
      >
        Create
      </UiButton>
    </UiModalFooter>
  </UiModal>
</template>

<script setup>
import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { WATCHLIST_TYPES } from '@/constants';
import routesNames from '@/router/routesNames';
import { ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import UiButton from '../ui/UiButton.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiModal from '../ui/UiModal.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';

const props = defineProps({
  collection: null,
  holders: {
    type: Array,
    required: true,
  },
});

const { holders, collection } = toRefs(props);

const topN = ref(10);

const { getDraftWalletWatchlist } = useWatchlists(WATCHLIST_TYPES.WALLET);
const isCreating = ref(false);
const router = useRouter();
const create = async () => {
  isCreating.value = true;
  const name = `${collection.value.name} - Top ${topN.value} Holders`;
  await getDraftWalletWatchlist(holders.value.slice(0, topN.value).map((h) => h.wallet.id), name).then(({ data: { watchlist } }) => {
    // add({ id: watchlist.id, modelIds:  });
    isCreating.value = false;
    return watchlist;
  }).then((res) => {
    router.push({
      name: routesNames.walletWatchlist.show,
      params: {
        slug: res.slug,
      },
    });
  }).finally(() => {
    isCreating.value = false;
  });
};

const isVisible = ref(false);

const show = () => {
  isVisible.value = true;
};

defineExpose({
  show,
});
</script>
