<template>
  <div class="relative">
    <div
      class="flex justify-between py-2 px-3"
      :class="{'border-b border-gray-750': compact === false}"
    >
      <div class="flex gap-2 items-center">
        <WalletProfile
          :wallet="feedItem.wallet"
          :pic-size="9"
          :only-pic="true"
        />

        <div>
          <div class="text-sm font-bold leading-none">
            <RouterLink
              class="hover:underline"
              :to="{name: routesNames.wallet.show, params: {address:feedItem.wallet.id} }"
            >
              {{ feedItem.wallet.displayName }}
            </RouterLink>
          </div>

          <div class="mt-1.5 text-xs font-light leading-none text-gray-300">
            {{ verb }}
            {{ feedItem.tokenCount === 1 ? a(feedItem.collection.name, { articleOnly: true }) : feedItem.tokenCount }}
            <OverlayComponent
              class="inline"
              :width="300"
            >
              <RouterLink
                :to="{name: routesNames.collectionAnalytics, params: { contractAddress: feedItem.collection.id } }"
                class="font-bold hover:underline"
              >
                {{ feedItem.collection.name }}
              </RouterLink>

              <template #overlay-content="{hover}">
                <CollectionListItem
                  class="mb-3"
                  no-overlay
                  :collection="feedItem.collection"
                />

                <CollectionOverlayContent
                  :collection="feedItem.collection"
                  :is-active="hover"
                />
              </template>
            </OverlayComponent>
            {{ pluralize('token', feedItem.tokenCount) }}
            <span v-if="feedItem.value">for {{ formatCurrency(feedItem.value) }}</span>
          </div>
        </div>
      </div>

      <div class="flex gap-x-4 items-center">
        <div class="text-sm text-gray-300">
          <a
            v-if="feedItem.transaction?.id"
            :href="`https://etherscan.io/tx/${feedItem.transaction.id}`"
            target="_blank"
          >
            <Timestamp
              :timestamp="feedItem.timestamp"
              relative-class="text-sm font-medium leading-none text-gray-400 hover:underline"
              relative-only
              :largest="2"
              auto-update
            />
          </a>

          <Timestamp
            v-else
            auto-update
            :timestamp="feedItem.timestamp"
            relative-class="text-sm font-medium leading-none text-gray-400 hover:underline"
            relative-only
            :largest="2"
          />
        </div>
      </div>
    </div>

    <SaleFeedItemContent
      v-if="['buy', 'sell', 'sweep'].includes(feedItem.type)"
      :feed-item="feedItem"
    />

    <TransferFeedItemContent
      v-if="['mint', 'burn', 'send', 'receive'].includes(feedItem.type)"
      :feed-item="feedItem"
    />

    <ListFeedItemContent
      v-if="['list'].includes(feedItem.type)"
      :feed-item="feedItem"
    />
  </div>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel, Slide, Pagination, Navigation,
} from 'vue3-carousel';
import routesNames from '@/router/routesNames';
import { formatCurrency } from '@/utils/filters';
import moment from 'moment';
import pluralize from 'pluralize';
import { computed, ref, toRefs } from 'vue';
import useTokenModal from '@/composition/tokens/useTokenModal';
import a from 'indefinite';
import MarketplaceIcon from '../MarketplaceIcon.vue';
import Slider from '../Slider.vue';
import Timestamp from '../Timestamp.vue';
import TokenImage from '../TokenImage.vue';
import UiCard from '../ui/UiCard.vue';
import WalletProfile from '../wallets/WalletProfile.vue';
import CollectionListItem from '../collections/CollectionListItem.vue';
import SaleFeedItemContent from './SaleFeedItemContent.vue';
import TransferFeedItemContent from './TransferFeedItemContent.vue';
import ListFeedItemContent from './ListFeedItemContent.vue';
import OverlayComponent from '../OverlayComponent.vue';
import CollectionOverlayContent from '../collections/CollectionOverlayContent.vue';

const props = defineProps({
  feedItem: null,
  compact: {
    type: Boolean,
    default: false,
  },
});
const { feedItem, compact } = toRefs(props);

const typeToVerb = {
  buy: 'Purchased',
  sell: 'Sold',
  mint: 'Minted',
  burn: 'Burned',
  send: 'Sent',
  receive: 'Received',
  sweep: 'Swept',
  list: 'Listed',
};
const verb = computed(() => typeToVerb[feedItem.value.type]);

</script>
