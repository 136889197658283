<template>
  <Chart
    :is-loading="loading"
    :options="chartOptions"
  />
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, toRefs } from 'vue';
import moment from 'moment-timezone';
import { formatCurrency } from '@/utils/filters';

import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { WATCHLIST_TYPES } from '@/constants';
import Chart, { createTooltip } from '../Chart.vue';

const walletQuery = gql`
query GetPortfolioOverTime($modelId: String!, $filters: [FilterArg!]) {
  model: wallet(where: {id: $modelId}) {
    id
    portfolioValueOverTime(filters: $filters)
  }
}
`;

const watchlistQuery = gql`
query GetWalletGroupPortfolioOverTime($modelId: String!, $filters: [FilterArg!]) {
  model: walletWatchlistBySlug(slug: $modelId) {
    id
    portfolioValueOverTime(filters: $filters)
  }
}
`;

const props = defineProps(['walletId', 'watchlistId', 'filters']);
const { walletId, watchlistId, filters } = toRefs(props);

const { result, loading, refetch } = useQuery(() => (walletId.value ? walletQuery : watchlistQuery), () => ({ modelId: walletId.value || watchlistId.value, filters: filters.value }));

const { onWatchlistMembersUpdated } = useWatchlists(WATCHLIST_TYPES.WALLET);

onWatchlistMembersUpdated(() => {
  if (watchlistId.value) {
    refetch();
  }
});

const data = computed(() => result.value?.model?.portfolioValueOverTime || []);

const chartOptions = computed(() => ({
  chart: {
    type: 'area',
  },
  xAxis: {
    type: 'datetime',
  },
  tooltip: createTooltip({
    formatters: {
      Value: formatCurrency,
    },
  }),
  series: [
    {
      name: 'Value',
      data: data.value.map((item) => [Number(moment(item.key).format('x')), item.value]),
    },
  ],
}));
</script>
