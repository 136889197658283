<template>
  <div v-if="draw">
    <CustomSwiper
      v-if="isHolder"
      :slides-per-view="1"
      navigation
      :pagination="{
        clickable: true,
      }"
      :breakpoints="{
        1024: {
          slidesPerView: 2,
        }
      }"
    >
      <template
        v-if="isMinimumMdScreen"
        #outside-navigation="{ prevEl, nextEl, outsideNavClass }"
      >
        <TitleArrows
          :class="outsideNavClass"
          :prev-el="prevEl"
          :next-el="nextEl"
        />
      </template>

      <SwiperSlide
        v-for="n in availableEntries"
        :key="n.id"
      >
        <div class="overflow-hidden px-3 pt-32">
          <div class="p-6 bg-gray-800 rounded-xl">
            <div class="text-center">
              <img
                src="../../assets/crate.png"
                class="mx-auto -mt-32"
              >

              <div class="my-6">
                <div class="text-2xl font-bold text-white">
                  #{{ n.token.tokenId }}
                </div>
              </div>

              <div>
                <UiButton
                  block
                  size="lg"
                  variant="primary-reverse"
                  :disabled="!draw.isActive"
                  @click="showCrateOpenModal(n)"
                >
                  <span
                    v-if="!draw.isActive"
                    v-tooltip="moment(draw.startsAt).from()"
                  >
                    Open on {{ moment(draw.startsAt).format('MMM Do') }}
                  </span>

                  <span v-else>Open Now</span>
                </UiButton>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide v-if="floorPass">
        <div class="overflow-hidden px-3 pt-32">
          <div class="p-6 bg-gray-800 rounded-xl">
            <div class="text-center">
              <img
                src="../../assets/crate.png"
                class="mx-auto -mt-32"
              >

              <div class="my-6">
                <div class="text-2xl font-bold text-white">
                  #{{ floorPass.tokenId }}
                </div>
              </div>

              <div>
                <UiButton
                  block
                  size="lg"
                  variant="primary"
                  @click="openTokenModal(floorPass.id)"
                >
                  Buy Another for
                  {{ formatCurrency(floorPass.price) }}
                </UiButton>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide
        v-for="n in usedEntries"
        :key="n.id"
      >
        <div class="overflow-hidden px-3 pt-32 opacity-70">
          <div class="p-6 bg-gray-800 rounded-xl">
            <div class="text-center">
              <img
                src="../../assets/crate.png"
                class="mx-auto -mt-32"
              >

              <div class="my-6">
                <div class="text-2xl font-bold text-white">
                  #{{ n.token.tokenId }}
                </div>
              </div>

              <div>
                <UiButton
                  block
                  size="lg"
                  variant="primary-reverse"
                  @click="showCrateOpenModal(n)"
                >
                  Opened
                </UiButton>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </CustomSwiper>

    <div
      v-else
      class="grid md:grid-cols-2 md:gap-12 md:items-center text-white"
    >
      <div>
        <img
          src="../../assets/pass-hero.png"
          class="mx-auto max-w-full lg:max-w-lg"
        >
      </div>

      <div v-if="isLoggedIn">
        <div class="mx-auto max-w-lg">
          <div class="tracking-wider uppercase">
            Don't have a pass?
          </div>

          <div class="my-6 text-3xl lg:text-5xl font-semibold leading-tight">
            Buy a Pass today to claim your
            <span class="text-indigo-500">Compass crate</span> and much more.
          </div>

          <div class="mb-8 text-lg lg:text-xl text-gray-300">
            The compass Lifetime pass gets you more than just analytics: A free
            Compass Crate every month with valuable NFTs, Whitelist spots and
            Compass Coins!
          </div>

          <div>
            <UiButton
              size="xl"
              class="font-semibold"
              @click="openTokenModal(floorPass.id)"
            >
              Buy Now
              <span
                v-if="floorPass"
              >&nbsp;for {{ formatCurrency(floorPass.price) }}</span>
            </UiButton>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="mx-auto max-w-lg">
          <div class="tracking-wider uppercase">
            Already have a pass?
          </div>

          <div class="my-6 text-3xl lg:text-5xl font-semibold leading-tight">
            Sign in now to claim your
            <span class="text-indigo-500">Compass crate</span>.
          </div>

          <div class="mb-8 text-xl text-gray-300">
            The compass Lifetime pass gets you more than just analytics: A free
            Compass Crate every month with valuable NFTs, Whitelist spots and
            Compass Coins!
          </div>

          <div>
            <UiButton
              size="xl"
              class="font-semibold"
              @click="eventBus.$emit('auth:signIn')"
            >
              Connect With Wallet
            </UiButton>
          </div>
        </div>
      </div>
    </div>

    <UiModal
      v-model="isUnlockModalVisible"
      size="screen-xl"
    >
      <div class="grid grid-cols-2 gap-6 items-center p-6 py-6">
        <div class="pt-32 mt-8">
          <div class="text-center">
            <img
              src="../../assets/crate.png"
              class="mx-auto -mt-32"
            >

            <div class="my-6">
              <div class="text-2xl font-bold text-white">
                #{{ selectedTokenId }}
              </div>
            </div>

            <div>
              <UiButton
                v-if="!isOpened && !crateOpen"
                size="xl"
                block
                class="mx-auto max-w-sm font-semibold"
                :is-loading="isOpening"
                :disabled="isOpened"
                @click="openCrate"
              >
                Open Crate
              </UiButton>

              <UiButton
                v-if="isOpened || crateOpen"
                size="xl"
                variant="primary-reverse"
                block
                class="mx-auto max-w-sm font-semibold"
                @click="isUnlockModalVisible = false"
              >
                <span>Open Another</span>
              </UiButton>
            </div>
          </div>
        </div>

        <div v-if="!crateOpen && !isOpened">
          <UiInputGroup>
            <template #label>
              <div class="flex justify-between items-center">
                <div>Your Randomness</div>

                <button
                  type="button"
                  @click="regenerateSeed()"
                >
                  <i class="fas fa-redo" />
                </button>
              </div>
            </template>

            <UiInput v-model="seed" />

            <div class="mt-2 text-xs">
              <span class="mr-1 font-semibold">Client seed hash:</span>

              <code class="break-all">{{ seedHash }}</code>
            </div>

            <div class="mt-2 text-xs">
              <span class="mr-1 font-semibold">Server seed hash:</span>

              <code class="break-all">{{ draw.seedHash }}</code>
            </div>
          </UiInputGroup>

          <div
            class="mt-3 text-xs text-gray-400 hover:underline cursor-pointer"
            @click="isFairnessLearnMoreVisible = !isFairnessLearnMoreVisible"
          >
            Learn more about the fairness of the Crate drops.
          </div>

          <div
            v-if="isFairnessLearnMoreVisible"
            class="text-sm text-gray-300"
          >
            <div class="mb-2 text-lg font-semibold text-white">
              Fairness
            </div>

            <div class="mb-2 text-base font-medium text-white">
              How does it work?
            </div>

            <p class="mb-2">
              To make sure the Compass Crate drop is fair we decided to
              implement a provably fair random generation process. To achieve
              this, we must:
            </p>

            <ul class="pl-6 mb-2 space-y-1 list-disc">
              <li>
                Create a server seed and return the hash of that seed to you
                before you open the crate. This is the
                <span class="font-semibold">Server seed hash</span> below.
              </li>

              <li>
                Receive a client seed generated by you or your browser. This is
                <span class="font-semibold">Client seed phrase</span> below, you
                can change it to any value if you wish.
              </li>

              <li>Combine the two seeds to create to get one</li>

              <li>
                Shuffle all remaining prizes with a random generator seeded with
                the combined seeds
              </li>

              <li>
                Reveal the unhashed server seed after all crates are opened for
                verification.
              </li>
            </ul>

            <p class="mb-2">
              This way, you will be able to:
            </p>

            <ul class="pl-6 mb-2 space-y-1 list-disc">
              <li>
                Make sure the server seed we used to generate the result is
                indeed the one we sent you as a hash (you can hash it yourself
                to compare, we used SHA-512 as the hashing algorithm)
              </li>

              <li>
                Reproduce the same Crate open result by reproducing the whole
                process. We will release an open source website to do this.
              </li>
            </ul>
          </div>
        </div>

        <div v-else>
          <div class="mb-3 text-2xl font-semibold">
            Your {{ pluralize("Prize", crateOpenData.prizes.length) }}
          </div>

          <ul class="space-y-3">
            <li
              v-for="(item, index) in crateOpenData.prizes"
              :key="index"
            >
              <UiCard
                v-if="item.__typename === 'CoinPrize'"
                class="dark:bg-gray-1000"
              >
                <PrizeItem
                  :image="require('../../assets/coin.jpg')"
                  :name="`${item.coinAmount} Coins`"
                  :amount="item.numberOfPackages"
                  type-badge="Compass Coins"
                />
              </UiCard>

              <UiCard
                v-if="item.__typename === 'WhitelistCollectionPrize'"
                class="dark:bg-gray-1000"
              >
                <a
                  target="_blank"
                  :href="item.upcomingCollection.externalUrl"
                >
                  <PrizeItem
                    :image="item.upcomingCollection.imageUrl"
                    :name="item.upcomingCollection.name"
                    :amount="item.numberOfSpots"
                    type-badge="Whitelist Spot"
                  />
                </a>
              </UiCard>

              <UiCard
                v-if="item.__typename === 'TokenPrize' && item.type !== 'erc20'"
                class="dark:bg-gray-1000"
              >
                <a
                  target="_blank"
                  :href="`/collections/${item.token.collection.id}/items?tokenId=${item.token.tokenId}`"
                >
                  <PrizeItem
                    :image="item.token.image"
                    :name="item.token.collection.name"
                    type-badge="NFT"
                  />
                </a>
              </UiCard>

              <UiCard
                v-if="item.__typename === 'TokenPrize' && item.type === 'erc20'"
                class="dark:bg-gray-1000"
              >
                <a
                  target="_blank"
                  :href="`/collections/${item.token.collection.id}/items?tokenId=${item.token.tokenId}`"
                >
                  <PrizeItem
                    :image="item.erc20Info.imageUrl"
                    :name="item.erc20Info.name"
                    :type-badge="`${item.amount} tokens`"
                  />
                </a>
              </UiCard>
            </li>
          </ul>
        </div>
      </div>
    </UiModal>
  </div>

  <div v-else>
    <div class="grid grid-cols-2">
      <div
        v-for="n in 2"
        :key="n"
        class="overflow-hidden px-3 pt-32"
      >
        <div class="p-6 bg-gray-800 rounded-xl">
          <div class="text-center">
            <img
              src="../../assets/crate.png"
              class="mx-auto -mt-32 filter grayscale"
            >

            <div class="my-6">
              <div class="text-2xl font-bold text-white">
                <LoadingPlaceholder
                  :width="66"
                  :height="20"
                  class="w-full h-8"
                />
              </div>
            </div>

            <div>
              <LoadingPlaceholder
                :width="600"
                :height="38"
                class="w-full h-12"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  toRefs,
} from 'vue';
import { useQuery, useApolloClient } from '@vue/apollo-composable';
import { SwiperSlide } from 'swiper/vue';
import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import floorLifetimePassQuery from '@/graphql/floor-lifetime-pass/queries/floorLifetimePass.query.gql';
import crateOpensExtQuery from '@/graphql/crate-open/queries/createOpensExt.query.gql';
import openCrateMutation from '@/graphql/crate-open/mutations/openCrate.mutation.gql';
import activeCratesActiveDrawQuery from '@/graphql/crate-open/queries/activeCratesActiveDraw.query.gql';
import { formatCurrency } from '@/utils/filters';
import UiModal from '@/components/ui/UiModal.vue';
import CustomSwiper from '@/components/custom-swiper/CustomSwiper.vue';
import TitleArrows from '@/components/custom-swiper/navigation/TitleArrows.vue';
import { useDevice } from '@/composition/device-detector';

import eventBus from '@/utils/event-bus';
import useTokenModal from '@/composition/tokens/useTokenModal';
import UiButton from '../ui/UiButton.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiCard from '../ui/UiCard.vue';
import PrizeItem from './PrizeItem.vue';
import LoadingPlaceholder from '../LoadingPlaceholder.vue';

export default defineComponent({
  components: {
    CustomSwiper,
    SwiperSlide,
    TitleArrows,
    UiButton,
    UiInput,
    UiInputGroup,
    UiCard,
    PrizeItem,
    LoadingPlaceholder,
    UiModal,
  },

  props: {
    draw: null,
  },

  setup(props) {
    const { draw } = toRefs(props);
    const { isMinimumMdScreen } = useDevice();

    const selectedTokenId = ref(null);

    const entries = computed(() => draw.value?.entries || []);

    const usedEntries = computed(() => entries.value.filter((item) => item.isOpened));

    const isOpened = computed(() => (
      usedEntries.value
        .map((item) => item.token.tokenId)
        .indexOf(selectedTokenId.value) !== -1
    ));

    const {
      result: floorPassResult,
    } = useQuery(
      floorLifetimePassQuery,
      null,
      () => ({
        pollInterval: 30000,
      }),
    );
    const floorPass = computed(() => floorPassResult.value?.floorPass);

    const {
      result: crateOpensResult,
    } = useQuery(
      crateOpensExtQuery,
      () => ({
        tokenId: selectedTokenId.value,
        drawId: draw.value?.id,
      }),
      () => ({
        enabled: isOpened.value,
      }),
    );
    const crateOpens = computed(() => crateOpensResult.value?.crateOpens);

    const { resolveClient } = useApolloClient('default');
    const apolloClient = resolveClient();

    const { open } = useTokenModal();

    return {
      entries,
      usedEntries,
      isOpened,
      crateOpens,
      selectedTokenId,
      moment,
      floorPass,
      apolloClient,
      pluralize,
      formatCurrency,
      isMinimumMdScreen,
      openTokenModal: open,
    };
  },
  data() {
    return {
      isOpening: false,
      isUnlockModalVisible: false,
      isFairnessLearnMoreVisible: false,
      seed: CryptoJS.lib.WordArray.random(128 / 8),
      crateOpen: null,
      eventBus,
    };
  },
  computed: {
    isHolder() {
      return this.$store.getters['auth/passBalance'] > 0;
    },
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
    availableEntries() {
      return this.entries.filter((item) => !item.isOpened);
    },
    seedHash() {
      return CryptoJS.SHA512(this.seed).toString();
    },
    crateOpenData() {
      return this.crateOpen || this.crateOpens[0] || null;
    },
  },
  methods: {
    showCrateOpenModal(entry) {
      this.isUnlockModalVisible = true;
      this.selectedTokenId = entry.token.tokenId;
      this.crateOpen = null;
      this.isFairnessLearnMoreVisible = false;
      this.regenerateSeed();
    },
    regenerateSeed() {
      this.seed = CryptoJS.lib.WordArray.random(128 / 8);
    },
    openCrate() {
      this.isOpening = true;
      return this.apolloClient
        .mutate({
          errorPolicy: 'none',
          mutation: openCrateMutation,
          variables: {
            seedHash: this.seedHash,
            tokenId: this.selectedTokenId,
          },
          refetchQueries() {
            return [{ query: activeCratesActiveDrawQuery }];
          },
        })
        .then((res) => {
          this.crateOpen = res.data.openCrate;
        })
        .finally(() => {
          this.isOpening = false;
        });
    },
  },
});
</script>

<style>
</style>
