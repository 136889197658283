<template>
  <div
    class="inline-block py-0.5 px-1 font-mono rounded-lg border"
    :class="rank / supply > 0.1 ? 'border-gray-600': 'border-orange-600 border-opacity-75'"
  >
    <slot
      :rank="rank"
      :color="backgroundColor"
    >
      <div
        :style="{color: backgroundColor}"
        :class="{'opacity-75': rank / supply > 0.5}"
      >
        #{{ rank }}
      </div>
    </slot>
  </div>
</template>

<script>
import rarityRankColor from '@/utils/rarityRankColor';

export default {
  props: {
    rank: null,
    supply: null,
    textMode: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    backgroundColor() {
      return rarityRankColor(Math.min(this.rank / this.supply, 1));
    },

  },
};
</script>

<style>

</style>
