<template>
  <div
    v-if="pendingTransactions && pendingTransactions.length === 0"
    class="text-center text-gray-400"
  >
    No pending transactions
  </div>

  <div v-if="pendingTransactions && pendingTransactions.length > 0">
    <div>
      <UiTable
        :no-shadow="true"
        no-sticky
        :items="pendingTransactions"
        :fields="[
          'from',
          'gasPrice',
          'maxFeePerGas',
          'maxPriorityFeePerGas',
          'firstSeen',
        ]"
      >
        <template #cell(from)="{ item }">
          <WalletProfile :address="item.from" />
        </template>

        <template #head(gasPrice)>
          Gas Price
        </template>

        <template #head(maxFeePerGas)>
          Max Fee
        </template>

        <template
          #head(maxPriorityFeePerGas)
        >
          Prio. Fee
        </template>

        <template #cell(gasPrice)="{ item }">
          {{ formatNumber(formatWei(item.gasPrice, "gwei")) }}
        </template>

        <template #cell(maxFeePerGas)="{ item }">
          {{ formatNumber(formatWei(item.maxFeePerGas, "gwei")) }}
        </template>

        <template #cell(maxPriorityFeePerGas)="{ item }">
          {{
            formatNumber(formatWei(item.maxPriorityFeePerGas, "gwei"))
          }}
        </template>

        <template #cell(firstSeen)="{ item }">
          <a
            :href="`https://etherscan.io/tx/${item.transactionHash}`"
            target="_blank"
          >
            <Timestamp
              :timestamp="item.firstSeen"
              :auto-update="true"
              :relative-only="true"
            />
          </a>

          <UiBadge
            v-if="item.status === 'complete'"
            variant="green"
          >
            {{ item.status }}
          </UiBadge>

          <UiBadge
            v-if="item.status === 'pending'"
            variant="yellow"
          >
            {{ item.status }}
          </UiBadge>

          <UiBadge
            v-if="item.status === 'failed'"
            variant="red"
          >
            {{
              item.status
            }}
          </UiBadge>
        </template>
      </UiTable>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import pendingTransactionsQuery from '@/graphql/pending-order-fill/queries/pendingTransactions.query.gql';
import { formatNumber, formatWei } from '@/utils/filters';
import UiTable from '../ui/UiTable.vue';
import WalletProfile from '../wallets/WalletProfile.vue';
import UiBadge from '../ui/UiBadge.vue';
import Timestamp from '../Timestamp.vue';

const props = defineProps(['collection', 'tokenId', 'listing', 'enabled']);
const emit = defineEmits(['pendingTxCount']);
const {
  collection, tokenId, listing, enabled,
} = toRefs(props);

const {
  result: pendingTransactionsResult,
  onResult,
} = useQuery(
  pendingTransactionsQuery,
  () => ({
    collectionId: collection.value?.id,
    tokenId: tokenId.value,
    orderHash: listing.value?.id,
  }),
  () => ({
    enabled: listing.value?.id && enabled.value,
    fetchPolicy: 'cache-and-network',
    pollInterval: 3000,
  }),
);

onResult((result) => {
  emit('pendingTxCount', result.data?.pendingTransactions?.length || 0);
});

const pendingTransactions = computed(() => pendingTransactionsResult.value?.pendingTransactions);
</script>
