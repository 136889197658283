<template>
  <UiDropdownRoot
    v-dropdown-component="id"
  >
    <UiDropdownToggleContainer
      ref="toggleBtn"
      class="select-none"
      :class="[toggleClass]"
    >
      <slot
        name="toggle"
        v-bind="{toggle, forceClose, isOpen}"
      >
        <UiButton
          variant="white"
          v-bind="toggleProps"
          @click="toggle"
        >
          {{
            toggleText
          }}
        </UiButton>
      </slot>
    </UiDropdownToggleContainer>

    <Component
      :is="inline !== false ? 'div' : Teleport"
      v-bind="inline !== false ? {} : {to: '#dropdown-components'}"
      v-if="hideOnly === false ? isOpen : true"
      :class="{'absolute z-100': inline, }"
    >
      <UiDropdownBody
        ref="dropdownBody"
        :class="[bodyWidthClass, bodyClass, `dropdown-menu-${id}`, {'hidden': !isOpen}]"
        class="min-w-max"
        @click="forceClose"
      >
        <UiDropdownContent
          class="py-2 px-2 dropdown__content"
          :class="[contentClass]"
        >
          <slot
            name="body"
            v-bind="{toggle, forceClose, isOpen}"
          >
            <slot />
          </slot>
        </UiDropdownContent>
      </UiDropdownBody>
    </Component>
  </UiDropdownRoot>
</template>

<script setup>
import {
  nextTick,
  ref,
  toRefs,
  Teleport,
} from 'vue';

import UiButton from '@/components/ui/UiButton.vue';
import { createPopper } from '@popperjs/core';
import useClickAwaySimple from '@/composition/click-away/useClickAwaySimple';
import {
  UiDropdownRoot,
  UiDropdownToggleContainer,
  UiDropdownBody,
  UiDropdownContent,
} from './fcomponents';

const props = defineProps({
  bodyWidthClass: {
    type: String,
    default: 'w-56',
  },
  bodyClass: {
    type: String,
    default: '',
  },
  contentClass: {
    type: String,
    default: '',
  },
  toggleClass: {
    type: String,
    default: '',
  },
  right: {
    type: Boolean,
    default: false,
  },
  top: {
    type: Boolean,
    default: false,
  },
  toggleText: {
    default: 'Dropdown',
    type: String,
  },
  toggleProps: {
    default() {
      return {};
    },
    type: Object,
  },
  inline: {
    default: false,
    type: Boolean,
  },
  hideOnly: {
    default: false,
    type: Boolean,
  },
});
const {
  right,
  top,
  inline,

} = toRefs(props);

const isOpen = ref(false);

const id = Math.floor((Math.random() * (2 ** 16)) + (2 ** 16)).toString(16);

const toggleBtn = ref(null);
const dropdownBody = ref(null);
let popperInstance = null;
const toggle = () => {
  isOpen.value = !isOpen.value;
  nextTick(() => {
    if (popperInstance) {
      popperInstance.update();
    }
  });
};
const forceClose = () => nextTick(() => isOpen.value = false);
defineExpose({
  toggle,
  forceClose,
  isOpen,
});

const createPopperInstance = (el, tooltip) => {
  const { onClickAway } = useClickAwaySimple(el);

  onClickAway((e) => {
    if (isOpen.value && !el.contains(e.target) && !tooltip.contains(e.target)) {
      forceClose();
    }
  });

  if (inline.value !== false) {
    return;
  }
  const popcorn = el;

  if (!tooltip) {
    return;
  }
  popperInstance = createPopper(popcorn, tooltip, {
    placement: `${top.value ? 'top' : 'bottom'}-${right.value ? 'end' : 'start'}`,
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: document,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });
  tooltip.addEventListener('click', () => {
    nextTick(() => {
      if (!popperInstance) {
        return;
      }
      popperInstance.update();
    });
  });
};

const vDropdownComponent = {
  updated(el, { value, instance }) {
    const tooltip = document.querySelector(`.dropdown-menu-${value}`);
    if (!tooltip && popperInstance) {
      popperInstance.destroy();
      popperInstance = null;
      return;
    }

    if (!tooltip) {
      return;
    }

    if (!popperInstance) {
      createPopperInstance(el, tooltip);
      return;
    }

    popperInstance.update();
  },
  mounted(el, { value, instance }) {
    // handle clicks outside of dropdown menu to close it
    const tooltip = document.querySelector(`.dropdown-menu-${value}`);
    if (!tooltip) {
      return;
    }

    createPopperInstance(el, tooltip);
  },
};
</script>

<style lang="scss">
@mixin dropdown-animation() {
  animation-duration: .15s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}
.dropdown-enter-active {
  animation-name: dropdown-in;
  @include dropdown-animation;
}
.dropdown-leave-active {
  animation-name: dropdown-out;
  @include dropdown-animation;
}

@keyframes dropdown-in {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes dropdown-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

</style>

<style scoped>
@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.dropdown__content {
  animation: dropdownFadeIn 0.3s;
}
</style>
