<template>
  <div class="flex gap-2 items-center">
    <UiSelectCustom
      v-if="!isVariable"
      :disabled="disabled !== false"
      :model-value="modelValue"
      :options="walletRadioOptions"
      :class="{'animate-pulse cursor-not-allowed': isLoadingPrivateKeys}"
      @update:modelValue="emit('update:modelValue', $event)"
    />

    <UiButton
      variant="white"
      size="sm"
      :is-loading="isCreating"
      @click="createWallet"
    >
      <i class="fas fa-plus" />
    </UiButton>
  </div>
</template>

<script setup>
import { computed, toRefs, ref } from 'vue';
import useAutomationWallets from '@/composition/automation/use-private-keys';
import UiSelectCustom from '../ui/UiSelectCustom.vue';
import useDialogs from '../ui/composition/use-dialogs';
import UiButton from '../ui/UiButton.vue';

const { automationWallets, isLoading: isLoadingPrivateKeys, create } = useAutomationWallets();

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isVariable: {
    type: Boolean,
    default: false,
  },
});

const { modelValue, disabled, isVariable } = toRefs(props);

const emit = defineEmits(['update:modelValue']);

const walletRadioOptions = computed(() => automationWallets.value.map((pk) => ({
  name: pk.name && pk.name.trim().length ? `${pk.name} ${pk.wallet.id.slice(0, 6)}` : pk.wallet.displayName,
  id: pk.address,
})));
const { ask } = useDialogs();
const isCreating = ref(false);
const createWallet = async () => {
  const { confirmed } = ask({
    title: 'Create new wallet',
    message: 'Name',
  });

  const name = await confirmed();
  if (!name || !name.length) {
    return false;
  }

  isCreating.value = true;
  return create({ name }).then((res) => {
    emit('update:modelValue', res.data.createAutomationWallet.address);
  }).finally(() => {
    isCreating.value = false;
  });
};
</script>
