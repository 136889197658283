<template>
  <UiModalHeader
    title="Edit wallet name"
    @dismiss="emit('dismiss')"
  />

  <div class="p-3">
    <UiInputGroup label="Name">
      <UiInput v-model="walletName" />
    </UiInputGroup>

    <p class="text-sm text-gray-600 dark:text-gray-400">
      Only you can see the name you set.
    </p>
  </div>

  <UiModalFooter>
    <UiButton
      :is-loading="isLoading"
      @click="save"
    >
      Save
    </UiButton>
  </UiModalFooter>
</template>

<script>
import {
  computed,
  defineComponent,
  inject,
  toRefs,
  ref,
  defineEmits,
} from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { mapGetters } from 'vuex';
import editWalletNameMutation from '@/graphql/wallet/mutations/editWalletName.mutation.gql';
import walletByAddressQuery from '@/graphql/wallet/queries/walletByAddress.query.gql';
import { useUiDialog } from '@/components/ui-dialog/useUiDialog';

import { mapGraphqlErrors } from '@/composition/errors';
import { usePremium } from '@/composition/premium';
import UiButton from '../ui/UiButton.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';

export default defineComponent({
  components: {
    UiInput,
    UiInputGroup,
    UiModalHeader,
    UiModalFooter,
    UiButton,
  },

  props: ['wallet'],

  setup(props) {
    const { wallet } = toRefs(props);
    const newName = ref('');
    const { info } = useUiDialog();

    const emit = defineEmits(['dismiss']);

    const walletName = computed({
      get() {
        return wallet.value?.displayName;
      },
      set(value) {
        newName.value = value;
      },
    });

    const requireLogin = inject('requireLogin');

    const { requirePremium } = usePremium();

    const {
      mutate: editWalletName,
    } = useMutation(
      editWalletNameMutation,
      () => ({
        variables: {
          walletId: wallet.value?.id,
          name: newName.value,
        },
        errorPolicy: 'none',
        refetchQueries: [
          {
            query: walletByAddressQuery,
            variables: { address: wallet.value?.id },
          },
        ],
      }),
    );

    return {
      requireLogin,
      requirePremium,
      newName,
      walletName,
      editWalletName,
      info,
      emit,
    };
  },

  data() {
    return {
      isVisible: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
  methods: {
    async show() {
      if (!this.isLoggedIn) {
        await this.requireLogin();
      }
      this.isVisible = true;
      this.newName = '';
    },
    save() {
      this.isLoading = true;
      return this.editWalletName()
        .then(() => {
          this.info('Wallet name updated');
          this.isVisible = false;
        }).finally(() => {
          this.isLoading = false;
        }).catch(async (err) => {
          if (!err.graphQLErrors) {
            throw err;
          }

          const errors = mapGraphqlErrors(err.graphQLErrors);

          if (errors.get('editWalletName').code === 'PREMIUM_REQUIRED') {
            await this.requirePremium('to edit wallet name');
          }
        });
    },
  },
});
</script>

<style>
</style>
