<template>
  <div :class="{'opacity-50': disabled !== false}">
    <UniversalSearch
      v-if="isSearchVisible && (multiple || (multiple === false && model === null))"
      :only="only"
      small
      @selectResult="selectModel($event)"
    >
      <template #search-field="{ search, show, close }">
        <div class="flex relative items-center">
          <span
            class="absolute ml-3 text-sm text-gray-500"
          ><i class="far fa-search" /></span>

          <UiInput
            v-model="search.term"
            class="pl-8"
            :placeholder="placeholder"
            :disabled="disabled !== false"
            @focus="show"
            @blur="close"
          />
        </div>
      </template>
    </UniversalSearch>

    <div
      v-if="multiple === false && model !== null"
    >
      <div
        class="
          flex
          overflow-hidden
          relative
          justify-between
          items-center
          py-2
          px-3
          bg-gray-750
          rounded-xl
        "
      >
        <CollectionListItem
          v-if="only === 'collection'"
          :pic-size="8"
          :mini="true"
          no-overlay
          :address="model"
        />

        <WalletProfile
          v-if="only === 'wallet'"
          :wallet="{id: model}"
        />

        <WalletWatchlistListItem
          v-if="only === 'walletWatchlist'"
          :watchlist="{id: model}"
        />

        <UiButton
          v-if="disabled === false"
          variant="white"
          size="xs"
          class="absolute top-1/2 right-3 transform -translate-y-1/2"
          @click="model = null"
        >
          Change
        </UiButton>
      </div>
    </div>

    <div
      v-if="multiple && model !== null"
      class="mt-2 space-y-1"
    >
      <div
        v-for="item in model"
        :key="item"
        class="
          flex
          justify-between
          items-center
          p-2
          rounded
          border
          dark:border-gray-800
        "
      >
        <CollectionListItem
          v-if="only === 'collection'"
          :pic-size="8"
          :mini="true"
          no-overlay
          :address="item"
        />

        <WalletProfile
          v-if="only === 'wallet'"
          :wallet="{id: item}"
        />

        <WalletWatchlistListItem
          v-if="only === 'walletWatchlist'"
          :watchlist="{id: item}"
        />

        <button
          class="text-gray-400 hover:text-gray-200"
          @click="removeModel(item)"
        >
          <i class="far fa-times" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  toRefs,
} from 'vue';

import pluralize from 'pluralize';
import { uniq } from 'lodash-es';
import CollectionListItem from './collections/CollectionListItem.vue';
import UiButton from './ui/UiButton.vue';
import UiInput from './ui/UiInput.vue';
import UniversalSearch from './universal-search/UniversalSearch.vue';
import WalletProfile from './wallets/WalletProfile.vue';
import WalletWatchlistListItem from './wallets/WalletWatchlistListItem.vue';

const props = defineProps({
  multiple: {
    default: false,
    type: Boolean,
  },
  only: {
    default: false,
    type: [Boolean, String],
  },
  modelValue: {
    default: null,
    type: [Array, String],
  },
  disabled: {
    default: false,
    type: Boolean,
  },

});
const emit = defineEmits(['update:modelValue']);
const {
  modelValue, only, multiple, disabled,
} = toRefs(props);
const model = computed({
  get() {
    return modelValue.value;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const isSearchVisible = computed(() => true);
const placeholder = computed(() => {
  if (only.value === false) {
    return 'Search collections and wallets';
  }

  let noun = only.value;
  if (noun === 'walletWatchlist') {
    noun = 'wallet group';
  }

  return `Search ${pluralize(noun, 2)}`;
});

const selectModel = ({ data }) => {
  if (multiple.value) {
    model.value = uniq([
      ...(model.value || []),
      data.id,
    ]);
    return;
  }

  model.value = data.id;
};

const removeModel = (item) => {
  if (multiple.value) {
    model.value = model.value.filter((i) => i !== item);
    return;
  }

  model.value = null;
};

</script>

<style>
</style>
