<template>
  <div
    class="flex items-center"
    :class="{'flex-row': reverse === false, 'flex-row-reverse': reverse !== false, 'justify-between': justify === 'between'}"
  >
    <button
      type="button"
      :disabled="disabled !== false"

      class="
        inline-flex
        relative
        flex-shrink-0
        rounded-full
        ring-1
        focus:ring-2
        ring-gray-700
        ring-offset-2
        ring-offset-black
        transition-colors
        duration-200
        ease-in-out
        cursor-pointer
        focus:outline-none

        border-2 border-transparent
      "
      role="switch"
      :class="[model ? `bg-${variant}-600` : `bg-${variant}-1000`, {'h-6 w-11': size === 'base', 'h-4 w-7': size === 'sm'}, `focus:ring-${variant}-500`, {'cursor-not-allowed opacity-50': disabled !== false}]"
      @click="model = !model"
    >
      <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
      <span
        aria-hidden="true"
        class="
          inline-block
          bg-gray-700
          rounded-full
          ring-0
          shadow
          transition
          duration-200
          ease-in-out
          transform
          pointer-events-none
        "
        :class="{'translate-x-5': model && size === 'base', 'translate-x-3': model && size === 'sm', 'translate-x-0': !model, 'h-5 w-5': size === 'base', 'h-3 w-3': size === 'sm'}"
      />
    </button>

    <div
      v-if="$slots.default"
      class="text-sm text-gray-100"
      :class="{'ml-3': reverse === false, 'mr-3': reverse !== false}"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
} from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      default: false,
      type: Boolean,
    },
    reverse: {
      default: false,
      type: Boolean,
    },
    size: {
      default: 'base',
      type: String,
    },
    variant: {
      default: 'indigo',
      type: String,
    },
    justify: null,
    disabled: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    return {
      model,

    };
  },
});
</script>

<style>
</style>
