<template>
  <div class="flex items-center font-mono text-gray-700 dark:text-gray-400">
    <div v-if="!limit">
      <span class="hidden sm:block">{{ address }}</span>

      <span class="block sm:hidden">{{ slice(address, 0, 32) }}...</span>
    </div>

    <div v-else>
      {{ slice(address, 0, limit) }}
    </div>

    <div
      class="flex sticky right-0 gap-2 items-center px-2"
      style="background: inherit"
    >
      <button
        class="hover:text-gray-100"
        @click.stop.prevent="copy"
      >
        <i class="far fa-clipboard" />
      </button>

      <UiLink
        :href="`https://etherscan.io/${as || 'address'}/${address}`"
        target="_blank"
        class="hover:text-gray-100"
      >
        <i class="far fa-external-link" />
      </UiLink>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs } from 'vue';
import { copyTextToClipboard } from '@/utils/clipboard';
import { slice } from '@/utils/filters';
import UiLink from '@/components/ui/UiLink.vue';
import { useUiDialog } from '@/components/ui-dialog/useUiDialog';
import useDialogs from './ui/composition/use-dialogs';

export default defineComponent({
  name: 'AddressDisplay',

  components: { UiLink },

  props: ['address', 'limit', 'as'],

  setup(props) {
    const { address } = toRefs(props);
    const { info } = useUiDialog();
    const { notify } = useDialogs();
    function copy() {
      copyTextToClipboard(address.value);
      notify({
        message: 'Address copied to clipboard',
      });
    }

    return {
      slice,
      copy,
    };
  },
});
</script>

<style scoped>

</style>
