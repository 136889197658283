<template>
  <div class="mx-auto max-w-screen-2xl">
    <div>
      <UiCard
        class=""
        :is-overflow-auto="true"
        style="contain:paint"
      >
        <UiCardBody>
          <Filters
            v-model="filtersModel"
            :filters="availableFilters"
            group="wallet"
          >
            <template #before-filters>
              <UiSearchInput v-model="searchTermModel" />
            </template>
          </Filters>
        </UiCardBody>

        <UiTable
          v-model:sort-by="sortByModel"
          v-model:sort-desc="sortDescModel"
          :sortable="[
            'realizedGains',
            'tokenCount',
            'estimatedValue',
            'totalRevenue',
            'totalInvested',
            'avgInvested',
          ]"
          :items="displayedCollections"
          :fields="[
            'collection',
            'realizedGains',
            'estimatedValue',
            'totalRevenue',
            'totalInvested',
            'avgInvested',
            'tokensSold',
          ]"
          :is-loading="(isLoading && !isLoadingMore)"
          :show-skeleton="isLoadingMore"
          :skeletons-rows="20"
        >
          <template #head(tokenCount)>
            Balance
          </template>

          <template #head(estimatedValue)>
            Value
          </template>

          <template #head(realizedGains)>
            Profit
          </template>

          <template #head(collection-listedFloor)>
            Floor
          </template>

          <template #head(totalRevenue)>
            Revenue
          </template>

          <template #head(totalInvested)>
            Invested
          </template>

          <template #cell(collection)="{ item: { collection } }">
            <div class="flex gap-3 items-center">
              <CollectionListItem :collection="collection">
                <template #context>
                  <OverlayShortcutContext>
                    <UiLink
                      :to="{
                        name: getRouteName('analytics'),
                        params: {
                          address: walletId,
                          slug: watchlistId,
                        },
                        query: {
                          filters: encodeFilters([{key: 'collectionId', type: 'collection', value: {comparator: 'in', value: [collection.id]}}]),
                        },
                      }"
                    >
                      <UiButton
                        variant="white"
                        size="xs"
                      >
                        Analytics
                      </UiButton>
                    </UiLink>

                    <UiLink
                      :to="{
                        name: getRouteName('items'),
                        params: {
                          address: walletId,
                          slug: watchlistId,
                        },
                        query: {
                          filters: encodeFilters([{key: 'collectionId', type: 'collection', value: {comparator: 'in', value: [collection.id]}}]),
                        },
                      }"
                    >
                      <UiButton
                        variant="white"
                        size="xs"
                      >
                        Tokens
                      </UiButton>
                    </UiLink>

                    <UiLink
                      :to="{
                        name: getRouteName('transactions'),
                        params: {
                          address: walletId,
                          slug: watchlistId,
                        },
                        query: {
                          filters: encodeFilters([{key: 'collectionId', type: 'collection', value: {comparator: 'in', value: [collection.id]}}]),
                        },
                      }"
                    >
                      <UiButton
                        variant="white"
                        size="xs"
                      >
                        Activity
                      </UiButton>
                    </UiLink>
                  </OverlayShortcutContext>
                </template>

                <template #actions />
              </CollectionListItem>
            </div>
          </template>

          <template #cell(realizedGains)="{item}">
            <div
              class="inline-flex items-center tabular-nums whitespace-nowrap"
              :class="{'text-red-500': item.realizedGains < 0, 'text-green-500': item.realizedGains > 0}"
            >
              {{ formatCurrency(item.realizedGains) }} <span class="ml-1 text-sm">Realized</span>
            </div>

            <div class="text-xs text-gray-400">
              {{ formatCurrency(item.unrealizedGains) }} Unrealized
            </div>
          </template>

          <template #cell(totalRevenue)="{ item }">
            <CurrencyDisplay :value="item.totalRevenue" />
          </template>

          <template #cell(estimatedValue)="{item}">
            <CurrencyDisplay
              :value="item.estimatedValue"
            />

            <div class="text-xs text-gray-400">
              {{ item.tokenCount }} tokens
            </div>
          </template>

          <template #cell(totalInvested)="{ item }">
            <UiTooltip offset-distance="5px">
              <template #content>
                <div class="space-y-2 text-left">
                  <div class="flex justify-between items-center space-x-2">
                    <div>Investment</div>

                    <CurrencyDisplay :value="item.totalInvested" />
                  </div>

                  <div class="flex justify-between items-center space-x-2">
                    <div>Gas</div>

                    <CurrencyDisplay :value="item.totalGasSpent" />
                  </div>

                  <div class="flex justify-between items-center space-x-2">
                    <div>Value</div>

                    <div>{{ getUsdValue(item.totalInvested + item.totalGasSpent) }}</div>
                  </div>
                </div>
              </template>

              <CurrencyDisplay
                :value="item.totalInvested + item.totalGasSpent"
                :show-tooltip="false"
              />
            </UiTooltip>

            <div class="flex gap-1.5 items-center text-xs text-gray-400">
              <i class="far fa-gas-pump" />

              <CurrencyDisplay :value="item.totalGasSpent" />
            </div>
          </template>

          <template #cell(avgInvested)="{ item }">
            <CurrencyDisplay :value="item.avgInvested" />
          </template>

          <template #cell(tokensSold)="{ item }">
            <div class="mb-2">
              {{ item.tokensSold }}/{{ item.tokensIn }}
              <span class="text-sm text-gray-400">{{ formatPercent((item.tokensSold / item.tokensIn)) }}</span>
            </div>

            <UiProgressBar
              :value="item.tokensSold"
              :max="item.tokensIn"
            />
          </template>
        </UiTable>
      </UiCard>
    </div>

    <IntersectionObserver
      v-if="!isLoading && !isLoadingMore && hasMore"
      @isShow="emit('loadMore')"
    />
  </div>
</template>

<script setup>
import {
  computed,
  toRefs,
} from 'vue';
import { useStore } from 'vuex';
import routesNames from '@/router/routesNames';

import UiCardBody from '@/components/ui/UiCardBody.vue';
import UiCard from '@/components/ui/UiCard.vue';
import { formatCurrency, formatNumber, formatPercent } from '@/utils/filters';
import UiTable from '@/components/ui/UiTable.vue';
import CollectionListItem from '@/components/collections/CollectionListItem.vue';
import CurrencyDisplay from '@/components/CurrencyDisplay.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLink from '@/components/ui/UiLink.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import IntersectionObserver from '@/components/IntersectionObserver.vue';
import { FILTERS, FILTER_TYPES } from '@/constants/filters';
import { encodeFilters } from '@/composition/filters/useFiltersQuerySync';
import Filters from './filters/Filters.vue';
import UiSearchInput from './ui/UiSearchInput.vue';
import UiProgressBar from './UiProgressBar.vue';
import OverlayShortcutContext from './OverlayShortcutContext.vue';

const props = defineProps({
  walletId: String,
  watchlistId: Number,
  collections: {
    type: Array,
    required: true,
  },
  sortBy: {
    type: String,
    required: true,
  },
  sortDesc: {
    type: Boolean,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  isLoadingMore: {
    type: Boolean,
    required: true,
  },
  searchTerm: {
    type: String,
    required: true,
  },
  hasMore: {
    type: Boolean,
    required: true,
  },
  filters: {
    type: Array,
    default() {
      return [];
    },
  },
});

const emit = defineEmits(['loadMore', 'update:sortBy', 'update:sortDesc', 'update:searchTerm', 'update:filters']);

const {
  sortBy,
  sortDesc,
  isLoadingMore,
  searchTerm,
  collections,
  filters,
  walletId,
  watchlistId,
} = toRefs(props);

const sortByModel = computed({
  get: () => sortBy.value,
  set: (value) => emit('update:sortBy', value),
});

const sortDescModel = computed({
  get: () => sortDesc.value,
  set: (value) => emit('update:sortDesc', value),
});

const searchTermModel = computed({
  get: () => searchTerm.value,
  set: (value) => emit('update:searchTerm', value),
});

const store = useStore();
const priceData = computed(() => store.getters['gas/ethPrice']);

function getUsdValue(ethValue) {
  return `$${formatNumber(ethValue * priceData.value, 2, false)}`;
}

const displayedCollections = computed(() => collections.value);

const filtersModel = computed({
  get() {
    return filters.value || [];
  },
  set(value) {
    emit('update:filters', value);
  },
});
const getRouteName = (childName) => {
  if (walletId.value) {
    return routesNames.wallet[childName];
  }

  if (watchlistId.value === 'portfolio') {
    return routesNames.portfolio[childName];
  }

  return routesNames.walletWatchlist[childName];
};
const availableFilters = FILTERS.WALLET_COLLECTIONS;

</script>
