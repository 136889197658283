<template>
  <Teleport
    to="#modal-components"
  >
    <Transition name="modal">
      <div
        v-if="isVisible"
        class="
          overflow-y-auto
          fixed
          inset-0
          bg-gray-500
          dark:bg-gray-900
          bg-opacity-75
          dark:bg-opacity-75
          scrollbar-thin
          scrollbar-thumb-gray-700
          scrollbar-track-gray-800
          scrollbar-thumb-rounded-full
          scrollbar-track-rounded-full
        "
        :class="[`z-${zIndex}`, {'backdrop-filter backdrop-blur': bgBlur !== false}]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex sm:block justify-center items-end sm:p-0 pb-20 min-h-screen text-center modal-wrap"
          @click="closeOnBackdropClick"
        >
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >&#8203;</span>

          <div
            class="inline-block overflow-hidden sm:my-8 w-full text-left shadow-2xl"
            :class="[sizeClass, styleClasses, `align-${align}`]"
          >
            <slot :close="() => toggleIsVisible(false)" />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import useClickAwaySimple from '@/composition/click-away/useClickAwaySimple';
import { useGlobalModal } from '@/composition/modal';
import {
  watch,
  defineComponent,
  toRefs,
  computed,
  provide,
  onBeforeUnmount,
  nextTick,
  ref,
} from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';

export default defineComponent({
  name: 'UiModal',

  props: {
    modelValue: {
      default: false,
      type: Boolean,
    },
    size: {
      default: 'md',
      type: String,
    },
    styleClasses: {
      default: 'bg-white dark:bg-gray-850 dark:text-gray-100 rounded-lg',
      type: String,
    },
    align: {
      default: 'middle',
      type: String,
    },
    zIndex: {
      default: '50',
      type: String,
    },
    bgBlur: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit, expose }) {
    const { modelValue, teleport } = toRefs(props);

    const { modalHidden, modalShown } = useGlobalModal();

    const isVisible = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    watch(() => isVisible.value, (value, oldValue) => {
      if (value === oldValue) {
        return;
      }
      if (value) {
        modalShown();
      } else {
        modalHidden();
      }
    });

    onBeforeUnmount(() => {
      modalHidden();
    });

    const toggleIsVisible = (val) => {
      isVisible.value = Boolean(val);
    };

    provide('ui-modal-provide', {
      isVisible,
      toggleIsVisible,
    });

    expose({
      toggleIsVisible,
    });

    const closeOnBackdropClick = (event) => {
      if (event.target.classList.contains('modal-wrap')) {
        toggleIsVisible(false);
      }
    };

    return {
      toggleIsVisible,
      isVisible,
      closeOnBackdropClick,
    };
  },

  computed: {
    sizeClass() {
      if (this.size) {
        return `sm:max-w-${this.size}`;
      }

      return 'sm:max-w-sm';
    },
  },
});
</script>

<style lang="scss">
@mixin modal-animation() {
  animation-duration: .15s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}
.modal-enter-active {
  animation-name: modal-in;
  @include modal-animation;
}
.modal-leave-active {
  animation-name: modal-out;
  @include modal-animation;
}

@keyframes modal-in {
  from {
    opacity: 0;
    transform: translateY(2rem) scale(1.05);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes modal-out {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(2rem) scale(1.05);
  }
}

</style>
