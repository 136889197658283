<template>
  <div class="">
    <div class="mr-1 font-medium">
      <Transition
        mode="out-in"
        :name="value > previousModelValue ? 'metric-update-green' : 'metric-update-red'"
      >
        <CurrencyDisplay
          v-if="isCurrency"
          :key="value"
          :value="value"
        />

        <span
          v-else
        >
          <span :key="value">{{ displayedValue }}</span>
        </span>
      </Transition>
    </div>

    <div v-if="previousValue">
      <span
        class="text-sm font-medium"
        :class="{'text-green-600': (value/previousValue)-1 > 0, 'text-red-600': (value/previousValue)-1 < 0}"
      >
        <span v-if="(value/previousValue)-1 > 0">+</span>{{ formatPercent((value/previousValue)-1) }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { formatPercent } from '@/utils/filters';
import CurrencyDisplay from '@/components/CurrencyDisplay.vue';

export default defineComponent({
  name: 'MetricWithChange',

  components: {
    CurrencyDisplay,
  },

  props: {
    value: {
      type: [String, Number],
    },
    formatter: {
      type: Function,
    },
    previousValue: {
      type: [String, Number],
    },
    isCurrency: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      formatPercent,
    };
  },
  data() {
    return {
      previousModelValue: null,
    };
  },
  computed: {
    displayedValue() {
      if (this.formatter) {
        return this.formatter(this.value);
      }

      return this.value;
    },
  },
  watch: {
    value: {
      handler() {
        this.$nextTick(() => {
          this.previousModelValue = this.value;
        });
      },
      immediate: true,
    },
  },
});
</script>

<style scoped>

</style>
