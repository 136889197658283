<template>
  <Popper
    hover
    arrow
    :placement="placement"
    :offset-distance="offsetDistance"
  >
    <template #content>
      <div class="z-1000 bg-white dark:bg-gray-800 rounded border dark:border-gray-800">
        <div class="py-1 px-2 text-sm dark:text-gray-100">
          <slot name="content">
            {{ text }}
          </slot>
        </div>
      </div>
    </template>

    <slot />
  </Popper>
</template>

<script>
import Popper from 'vue3-popper';

export default {
  name: 'UiTooltip',
  components: {
    Popper,
  },
  props: {
    text: null,
    placement: {
      default: 'top',
      type: String,
    },
    offsetDistance: {
      default: '10px',
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
html {
  --popper-theme-box-shadow: 0 1px 5px rgba(0,0,0,.1);
  --popper-theme-border-radius: 10px;

  :deep(.popper:hover),
  :deep(.popper:hover > #arrow::before) {
    background-color: white;
  }
}

html.dark {
  :deep(.popper:hover),
  :deep(.popper:hover > #arrow::before) {
    background-color: rgb(38, 38, 38);
  }
}
</style>
