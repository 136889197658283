<template>
  <ActionInputMapper
    v-model="value"
    :inputs="action.inputs"
    :validation-issues="validationIssues"
    :trigger="trigger"
    :readonly="readonly"
  >
    <template #input(templateId)="{model, updateModel, error}">
      <UiInputGroup
        label="Template"
        :error-message="error?.message"
      >
        <UiSelectCustom
          :model-value="model"
          :options="automationTemplates"
          :disabled="readonly === true"
          @update:modelValue="updateModel"
        />
      </UiInputGroup>
    </template>

    <template #input(status)="{model, updateModel}">
      <UiInputGroup>
        <UiToggle
          :model-value="model || false"
          size="sm"
          :disabled="readonly === true"
          @update:modelValue="updateModel"
        >
          Enabled on creation
        </UiToggle>
      </UiInputGroup>
    </template>

    <template #input(folderId)="{model, updateModel}">
      <UiInputGroup label="Folder">
        <AutomationFolderPicker
          :model-value="model || false"
          @update:modelValue="updateModel"
        />
      </UiInputGroup>
    </template>

    <template #input(name)="{model, updateModel, error, addVariable}">
      <UiInputGroup
        label="Automation Name"
        :error-message="error?.message"
      >
        <UiInput
          :model-value="model"
          :disabled="readonly === true"
          @update:modelValue="updateModel"
        />

        <template
          v-if="readonly !== true"
          #label="{label}"
        >
          <div class="flex gap-1 items-center">
            <div>{{ label }}</div>

            <UiButton
              size="xs"
              variant="white"
              @click="addVariable()"
            >
              <i class="far fa-brackets-curly" />
            </UiButton>
          </div>
        </template>
      </UiInputGroup>
    </template>

    <template #input(variables)="{model, updateModel, addVariable}">
      <div>
        <UiInputGroup
          v-for="item in variables"
          :key="item.name"
          :label="displayName(humanize(item.name))"
        >
          <UiInput
            :model-value="model?.[item.name]"
            :disabled="readonly === true"
            @update:modelValue="(v) => model[item.name] = v"
          />

          <template
            v-if="readonly !== true"
            #label="{label}"
          >
            <div class="flex gap-1 items-center">
              <div>{{ label }}</div>

              <UiButton
                size="xs"
                variant="white"
                @click="addVariable(false, (v) => updateModel({ ...model, [item.name]: `{{ ${v} }}` }))"
              >
                <i class="far fa-brackets-curly" />
              </UiButton>
            </div>
          </template>
        </UiInputGroup>
      </div>
    </template>
  </ActionInputMapper>
</template>

<script setup>
import UiInputGroup from '@/components/ui/UiInputGroup.vue';
import UiSelectCustom from '@/components/ui/UiSelectCustom.vue';
import { toRefs, computed } from 'vue';
import useAutomationTemplates from '@/composition/automation/use-automation-templates';
import UiInput from '@/components/ui/UiInput.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiToggle from '@/components/ui/UiToggle.vue';
import { displayName, humanize } from '@/utils/filters';
import ActionInputMapper from '../ActionInputMapper.vue';
import AutomationFolderPicker from '../AutomationFolderPicker.vue';

const props = defineProps(['modelValue', 'action', 'type', 'validationIssues', 'trigger', 'readonly']);
const emit = defineEmits(['update:modelValue', 'trigger']);

const {
  modelValue, action, validationIssues, readonly,
} = toRefs(props);

const value = computed({
  get() {
    return modelValue.value;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const { automationTemplates } = useAutomationTemplates();

const selectedTemplate = computed(() => {
  const template = automationTemplates.value.find((t) => Number(t.id) === Number(value.value.templateId));
  return template;
});

const variables = computed(() => selectedTemplate.value?.inputs || []);
</script>
