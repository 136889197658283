<template>
  <div :title="benchmark.title">
    {{ benchmark.icon }}
  </div>
</template>

<script>
export function getMintSpeedBenchmark(value) {
  if (value > 1500) {
    return {
      title: 'Hot mint',
      icon: '🔥',
    };
  }
  if (value > 500) {
    return {
      title: 'Fast mint',
      icon: '🐇',
    };
  }
  if (value > 100) {
    return {
      title: 'Slow mint',
      icon: '🐢',
    };
  }

  return {
    title: 'Very slow mint',
    icon: '💤',
  };
}
export default {
  props: ['value'],
  computed: {
    benchmark() {
      return getMintSpeedBenchmark(this.value);
    },
  },
};
</script>
