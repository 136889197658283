<template>
  <UiCard
    slim
    :title="cardTitle"
  >
    <template #title-right>
      <slot name="title-right" />
    </template>

    <ul class="divide-y divide-gray-800">
      <li
        v-for="item in items"
        :key="item[trackBy]"
        class="text-gray-300 hover:bg-gray-800 active:bg-gray-850 transition-all duration-300 cursor-pointer item-card"
        @click="selectItem(item)"
      >
        <div
          class="flex justify-between items-center py-2 px-3 border-l-2 transition-all duration-300"
          :class="isActive(item) ? 'border-indigo-500' : 'border-transparent'"
        >
          <slot
            :item="item"
            name="item-slot"
          />

          <div class="item-card__toolbar">
            <slot name="item-toolbar" />
          </div>
        </div>
      </li>
    </ul>
  </UiCard>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import UiCard from './UiCard.vue';

const props = defineProps({
  cardTitle: String,
  items: Array,
  modelValue: Object,
  trackBy: {
    type: String,
    default: 'id',
  },
});

const emit = defineEmits(['update:modelValue']);

const activeItem = ref(props.modelValue);

const selectItem = (item) => {
  activeItem.value = item[props.trackBy];
  emit('update:modelValue', item[props.trackBy]);
};

const isActive = computed(() => (item) => activeItem.value && activeItem.value === item[props.trackBy]);

watch(activeItem, (newVal) => {
  emit('update:modelValue', newVal);
});
</script>

<style>
.item-card__toolbar {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
}

.item-card:hover .item-card__toolbar {
  opacity: 1;
  visibility: visible;
}
</style>
