<template>
  <div
    class="flex gap-3 p-2 rounded-xl border border-gray-800"
  >
    <div class="overflow-hidden flex-grow-0 flex-shrink-0 w-48 h-auto rounded-lg">
      <img
        :src="template.imageUrl"
        :alt="template.name"
      >
    </div>

    <div class="py-2">
      <div class="mb-2 font-bold">
        {{ template.name }}
      </div>

      <div
        v-if="template.shortDescription"
        class="text-sm text-gray-400"
      >
        {{ template.shortDescription }}
      </div>

      <div
        v-if="template.createdAt"
        class="text-sm text-gray-400"
      >
        Created {{ moment(template.createdAt).fromNow() }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import moment from 'moment';

const props = defineProps({
  template: {
    type: Object,
    required: true,
  },
});

const { template } = toRefs(props);
</script>
