<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="flex flex-col gap-4 text-white">
    <div>
      <div
        v-if="readonly === false"
        class="flex gap-3 items-center"
      >
        <UiInputGroup

          label="Name"
          :class="{'animate-pulse': isLoading}"
          class="flex-1"
        >
          <UiInput
            v-model="name"
            :placeholder="isLoading ? '' : namePlaceholder"
          />
        </UiInputGroup>

        <UiInputGroup
          v-if="noFolder === false"
          label="Folder"
          :class="{'animate-pulse': isLoading}"
        >
          <AutomationFolderPicker v-model="folderId" />
        </UiInputGroup>
      </div>

      <Accordion
        v-if="!isLoading"
        v-model="isTriggerAccordionOpen"
        name="Inputs"
        no-transition
        max-h="max-h-screen"
        class="mt-2"
      >
        <template #icon>
          <i
            class="text-indigo-500 fad fa-code"
          />
        </template>

        <div class="py-3 px-4">
          <UiTabs
            v-model="inputTabIndex"
            pills
          >
            <UiTab
              v-if="isTemplate === false && readonly === false"
              name="Values"
            >
              <div v-if="!inputsSchema?.length && Object.keys(inputs).length > 0">
                <UiInputGroup
                  v-for="(value, key) in inputs"
                  :key="key"
                  :label="displayName(humanize(key))"
                >
                  <UiInput
                    :model-value="value"
                    :disabled="readonly !== false"
                    @update:modelValue="inputs[key] = $event"
                  />
                </UiInputGroup>
              </div>

              <ActionInputMapper
                v-else-if="inputsSchema?.length"
                v-model="inputs"
                :inputs="inputsSchema"
                no-variables
                :readonly="readonly"
              />

              <NoContent
                v-else
                content="Setup inputs before providing values"
                size="sm"
              >
                <template #cta>
                  <UiButton
                    size="sm"
                    @click="inputTabIndex = 1"
                  >
                    Setup inputs
                  </UiButton>
                </template>
              </NoContent>
            </UiTab>

            <UiTab name="Setup">
              <ContextVariablesEditor
                ref="inputSchemaEditor"
                v-model="inputsSchema"
                :readonly="readonly"
              />
            </UiTab>
          </UiTabs>
        </div>
      </Accordion>

      <UiCard
        v-if="isLoading"
        class="animate-pulse"
      >
        <UiCardBody slim>
          <div class="text-4xl opacity-0">
            Loading
          </div>
        </UiCardBody>
      </UiCard>
    </div>

    <div v-if="linkedTemplate">
      <slot name="linked-template" />
    </div>

    <div
      v-if="!linkedTemplate"
      class="mb-24"
    >
      <div
        class="mb-2 text-lg font-light"
      >
        Trigger
      </div>

      <Accordion
        v-if="!isLoading && trigger"
        v-model="isTriggerAccordionOpen"
        max-h="max-h-screen"
        :name="selectedTrigger.name"
        no-transition
      >
        <template #icon>
          <i
            :class="triggerIcon"
            class="text-indigo-500"
          />
        </template>

        <template
          v-if="readonly === false"
          #actions
        >
          <!-- Error icon -->
          <i
            v-if="triggerErrors.length > 0"
            v-tooltip="`${triggerErrors.length} ${pluralize('issue', triggerErrors.length)}`"
            class="text-lg text-yellow-500 fad fa-exclamation-circle"
          />

          <UiButton
            size="xs"
            variant="white"
            @click="trigger = null"
          >
            Change
          </UiButton>
        </template>

        <UiCardBody v-if="trigger === 'log'">
          <ActionInputMapper
            v-model="triggerConfig"
            :validation-issues="triggerErrors"
            :inputs="[
              {
                name: 'contract',
                type: 'String',
                required: true,
              },
              {
                name: 'logEvent',
                type: 'String',
                required: false,
              }
            ]"
          />
        </UiCardBody>

        <UiCardBody v-if="trigger === 'openseaBid'">
          <UiInputGroup label="Bid type">
            <UiInputRadio
              v-model="triggerConfig.bidType"
              :options="[
                {
                  text: 'Trait',
                  value: 'ATTRIBUTE',
                },
                {
                  text: 'Collection',
                  value: 'COLLECTION',
                }
              ]"
              @update:modelValue="() => {triggerConfig.collectionId = null; triggerConfig.traitId = null;}"
            />
          </UiInputGroup>

          <ActionInputMapper
            v-model="triggerConfig"
            :validation-issues="triggerErrors"
            :inputs="triggerConfig.bidType === 'ATTRIBUTE' ? [
              {
                name: 'traitId',
                type: 'String',
                actingAs: 'tokenAttribute',
              }
            ] : [
              {
                name: 'collectionId',
                type: 'String',
                actingAs: 'collection',
              }
            ]"
          />
        </UiCardBody>

        <UiCardBody v-if="trigger === 'webhook'">
          <UiInputGroup
            label="Inputs"
            :error-message="getError(['triggerConfig', 'inputs'])"
          >
            <ContextVariablesEditor
              :model-value="triggerConfig.inputs || []"
              :readonly="readonly"
              @update:modelValue="triggerConfig.inputs = $event"
            />
          </UiInputGroup>

          <div v-if="triggerConfig.webhookPath">
            <UiInputGroup
              label="Webhook URL"
              class="relative"
            >
              <UiInput
                :model-value="webhookUrl"
                disabled
                readonly
                class="pr-10"
              />

              <UiButton
                size="xs"
                variant="white"
                class="absolute right-1 bottom-1"
                @click="copyWebhookUrl"
              >
                <i class="far fa-copy" />
              </UiButton>
            </UiInputGroup>
          </div>

          <div v-if="!triggerConfig.webhookPath">
            <!-- Message that webhook will be displayed on save -->
            <div class="mb-2 text-sm font-light">
              Webhook URL
            </div>

            <div class="mb-3 text-sm text-gray-400">
              Webhook URL will be displayed after saving
            </div>
          </div>
        </UiCardBody>

        <UiCardBody v-if="trigger === 'cron'">
          <UiInputGroup
            label="Schedule"
            :error-message="getError(['triggerConfig', 'schedule'])"
          >
            <UiCron
              v-model="triggerConfig.schedule"
              :disabled="readonly !== false"
            />
          </UiInputGroup>
        </UiCardBody>

        <UiCardBody v-if="trigger === 'telegram'">
          <div class="mb-2 text-sm">
            <div class="font-medium">
              Step 1
            </div>

            <div class="text-gray-700 dark:text-gray-300">
              <p>
                Send <span class="py-0.5 px-1 dark:text-gray-400 bg-gray-200 dark:bg-gray-800 rounded-sm">/start</span>

                to <a
                  target="_blank"
                  class="text-indigo-600 hover:text-indigo-700"
                  href="http://t.me/compass_nft_alert_bot"
                >
                  @compass_nft_alert_bot
                </a>
              </p>
            </div>
          </div>

          <div class="mb-3 text-sm">
            <div class="font-medium">
              Step 2
            </div>

            <div class="text-gray-700 dark:text-gray-300">
              <p>Enter your Telegram ID below</p>
            </div>
          </div>

          <UiInputGroup
            label="Chat ID"
            :error-message="getError(['triggerConfig', 'channelId'])"
          >
            <UiInput
              v-model="triggerConfig.channelId"
              :disabled="readonly !== false"
            />
          </UiInputGroup>
        </UiCardBody>

        <UiCardBody
          v-if="trigger === 'repeat'"
          class="grid grid-cols-2 gap-3"
        >
          <UiInputGroup
            label="Every"
            :error-message="getError(['triggerConfig', 'every'])"
          >
            <UiIntervalPicker
              v-model="triggerConfig.every"
              :disabled="readonly !== false"
              is-mili-second
            />
          </UiInputGroup>

          <UiInputGroup
            label="Max repetitions"
            :error-message="getError(['triggerConfig', 'limit'])"
          >
            <UiInput
              v-model="triggerConfig.limit"
              :disabled="readonly !== false"
              type="number"
            />
          </UiInputGroup>
        </UiCardBody>

        <UiCardBody
          v-if="selectedTrigger.group === 'events'"
        >
          <UiInputGroup
            label="Conditions"
            :error-message="getError(['triggerConfig', 'conditions'])"
          >
            <Filters
              v-model="triggerConfig.conditions"
              :filters="availableConditions"
              :disabled="readonly !== false"
              :filter-props="{}"
            />
          </UiInputGroup>

          <UiHiddenContent title="Advanced">
            <UiInputGroup
              label="Min triggers to run"
              :error-message="getError(['triggerConfig', 'minTriggersToRun'])"
            >
              <UiInput
                v-model="triggerConfig.minTriggersToRun"
                type="number"
                :disabled="readonly !== false"
              />
            </UiInputGroup>

            <UiInputGroup
              label="Trigger period"
              :error-message="getError(['triggerConfig', 'triggerWindowSeconds'])"
            >
              <UiIntervalPicker
                v-model="triggerConfig.triggerWindowSeconds"
                :is-mili-second="false"
                :disabled="readonly !== false"
              />
            </UiInputGroup>

            <UiInputGroup
              label="Count by"
              :error-message="getError(['triggerConfig', 'countBy'])"
            >
              <UiMultiSelect
                :model-value="Array.isArray(triggerConfig.countBy) ? triggerConfig.countBy : (triggerConfig.countBy ? [triggerConfig.countBy] : [])"
                :disabled="readonly !== false"
                :options="[
                  {name: 'Collection', id: 'collectionId'},
                  {name: 'Token', id: 'tokenId'},
                  {name: 'Wallet', id: 'walletId'},
                ]"
                @update:modelValue="triggerConfig.countBy = $event.length > 0 ? $event : null"
              />
            </UiInputGroup>
          </UiHiddenContent>
        </UiCardBody>

        <UiCardBody v-if="selectedTrigger.group === 'priceChange'">
          <ActionInputMapper
            v-model="triggerConfig"
            :inputs="[{name: 'collectionId', type: 'String', actingAs: 'collection'}]"
          />
        </UiCardBody>
      </Accordion>

      <UiCard
        v-if="readonly === false && !trigger && !isLoading"
      >
        <div class="grid grid-cols-12">
          <div
            class="grid grid-cols-2 col-span-8 gap-3 p-3"
          >
            <div
              v-for="(value, key) in availableTriggers"
              :key="key"
            >
              <button
                type="button"
                class="flex gap-3 items-center py-3 px-3 w-full text-sm leading-none text-left bg-gray-800 bg-opacity-50 hover:bg-opacity-100 rounded-lg hover:shadow-xl transition-all"
                @click.stop="selectTrigger(value)"
              >
                <div class="w-4 h-4 text-center text-indigo-500">
                  <i :class="value.icon" />
                </div>
                {{ value.name }}
              </button>
            </div>
          </div>

          <div class="col-span-4 p-3 bg-gray-800 bg-opacity-40">
            <div class="mb-2 text-base font-light">
              Set Up Triggers
            </div>

            <div class="mb-3 text-sm text-gray-400">
              Triggers kickstart an Automation. Events like token trades, minting or schedules set off automatic processes to streamline workflows.
            </div>

            <UiButton
              size="sm"
              variant="white"
              tag="a"
              target="_blank"
              class="inline-block"
              href="https://help.compass.art"
            >
              <i class="mr-2 fas fa-question-circle" />
              Learn More
            </UiButton>
          </div>
        </div>
      </UiCard>

      <UiCard
        v-if="isLoading"
        class="animate-pulse"
      >
        <UiCardBody slim>
          <div class="text-4xl opacity-0">
            Loading
          </div>
        </UiCardBody>
      </UiCard>

      <div class="mt-6 mb-2 text-lg font-light">
        Actions
      </div>

      <AutomationActionEditor
        v-if="!isLoading"
        v-model="actions"
        :validation-issues="actionErrors"
        :trigger="trigger"
        :readonly="readonly"
      />

      <UiCard
        v-else
        class="animate-pulse"
      >
        <UiCardBody slim>
          <div class="text-4xl opacity-0">
            Loading
          </div>
        </UiCardBody>
      </UiCard>

      <div
        v-if="readonly === false && actions.length > 0"
        class="h-screen50"
      />
    </div>
  </div>

  <VariableEditorModal />

  <VariablePickerModal />
</template>

<script setup>

import Filters from '@/components/filters/Filters.vue';

import { FILTER_TYPES } from '@/constants/filters';
import {
  toRefs, computed, ref, provide,
} from 'vue';
import UiButton from '@/components/ui/UiButton.vue';
import { isEqual } from 'lodash-es';

import { displayName, humanize } from '@/utils/filters';

import { AUTOMATION_TRIGGERS } from '@/constants/automation';
import pluralize from 'pluralize';
import { copyTextToClipboard } from '@/utils/clipboard';
import useAutomationVariables from '@/composition/automation/use-automation-variables';
import Accordion from '../Accordion.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiInput from '../ui/UiInput.vue';

import UiCardBody from '../ui/UiCardBody.vue';

import UiCron from '../ui/UiCron.vue';
import AutomationActionEditor from './AutomationActionEditor.vue';
import UiCard from '../ui/UiCard.vue';
import UiIntervalPicker from '../ui/UiIntervalPicker.vue';
import ContextVariablesEditor from './ContextVariablesEditor.vue';
import UiHiddenContent from '../ui/UiHiddenContent.vue';
import ActionInputMapper from './ActionInputMapper.vue';
import UiMultiSelect from '../ui/UiMultiSelect.vue';
import AutomationFolderPicker from './AutomationFolderPicker.vue';
import FeatureFlag from '../FeatureFlag.vue';
import UiTabs from '../ui/UiTabs.vue';
import UiTab from '../ui/UiTab.vue';
import TraitFilter from '../filters/TraitFilter.vue';
import TokenTraitFilterDisplay from '../filters/display/TokenTraitFilterDisplay.vue';
import NoContent from '../NoContent.vue';
import VariablePickerModal from './VariablePickerModal.vue';
import VariableEditorModal from './VariableEditorModal.vue';
import UiInputRadio from '../ui/UiInputRadio.vue';

const isTriggerAccordionOpen = ref(false);
const props = defineProps({
  name: {
    type: String,
    required: false,
    default: null,
  },
  actions: {
    type: Array,
    required: true,
    default() {
      return [];
    },
  },
  trigger: {
    type: String,
    required: true,
    default: null,
  },
  triggerConfig: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
  inputs: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },

  inputsSchema: {
    type: Array,
    default() {
      return [];
    },
  },
  folderId: {
    type: Number,
    default: null,
  },
  validationIssues: {
    type: Array,
    default() {
      return [];
    },
  },
  namePlaceholder: {
    type: String,
    default: 'Describe your automation',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  noFolder: {
    type: Boolean,
    default: false,
  },
  isTemplate: {
    type: Boolean,
    default: false,
  },
  linkedTemplate: {
    type: Object,
    default() {
      return null;
    },
  },
});
const {
  name: nameModel,
  actions: actionsModel,
  trigger: triggerModel,
  triggerConfig: triggerConfigModel,
  inputs: inputsModel,
  inputsSchema: inputsSchemaModel,
  validationIssues,
  namePlaceholder,
  isLoading,
  readonly,
  folderId: folderIdModel,
  noFolder,
  linkedTemplate,
} = toRefs(props);
const emit = defineEmits(['update:actions', 'update:trigger', 'update:triggerConfig', 'update:name', 'update:inputs', 'update:folderId', 'update:inputsSchema']);
const inputTabIndex = ref(0);

const name = computed({
  get() {
    return nameModel.value || null;
  },
  set(value) {
    emit('update:name', value);
  },
});

const trigger = computed({
  get() {
    return triggerModel.value || null;
  },
  set(value) {
    emit('update:trigger', value);
  },
});

const triggerConfig = computed({
  get() {
    return triggerConfigModel.value || null;
  },
  set(value) {
    emit('update:triggerConfig', value);
  },
});

const inputs = computed({
  get() {
    return inputsModel.value || {};
  },
  set(value) {
    emit('update:inputs', value);
  },
});

const inputsSchema = computed({
  get() {
    return inputsSchemaModel.value || [];
  },
  set(value) {
    emit('update:inputsSchema', value);
  },
});

const actions = computed({
  get() {
    return actionsModel.value || [];
  },
  set(value) {
    emit('update:actions', value);
  },
});

const folderId = computed({
  get() {
    return folderIdModel.value || null;
  },
  set(value) {
    emit('update:folderId', value);
  },
});

const availableTriggers = AUTOMATION_TRIGGERS;

const selectedTrigger = computed(() => availableTriggers.find((t) => t.value === trigger.value));

const {
  variables, getVariablesFromActions, flatVariables, selectVariable, variableSchema,
} = useAutomationVariables(computed(() => ({
  trigger: trigger.value,
  triggerConfig: triggerConfig.value,
  actions: actions.value,
  inputsSchema: inputsSchema.value,
})));

provide('variables', variables);
provide('variableSchema', variableSchema);
provide('flatVariables', flatVariables);
provide('getVariablesFromActions', getVariablesFromActions);
provide('selectVariable', selectVariable);

const availableConditions = computed(() => [
  {
    key: 'amount',
    name: 'Amount',
    type: FILTER_TYPES.NUMBER,
    props: {
      allowVariables: true,
    },
  },
  {
    key: 'value',
    name: 'Value',
    type: FILTER_TYPES.NUMBER,
    props: {
      allowVariables: true,
    },
  },
  {
    key: 'wallet.id',
    name: 'Wallet',
    type: FILTER_TYPES.WALLET,
    props: {
      allowEmpty: false,
      allowVariables: true,
    },
    defaultValue: {
      comparator: 'in',
      value: [],
    },
  },
  {
    key: 'collection.id',
    name: 'Collection',
    type: FILTER_TYPES.COLLECTION,
    props: {
      allowVariables: true,
    },
    defaultValue: {
      comparator: 'in',
      value: [],
    },
  },
  {
    key: 'token.id',
    name: 'Token ID (internal)',
    type: FILTER_TYPES.NUMBER,
    props: {
      allowVariables: true,
    },
  },
  {
    key: 'token.tokenId',
    name: 'Token ID',
    type: FILTER_TYPES.NUMBER,
    props: {
      allowVariables: true,
    },
    defaultValue: {
      comparator: 'includes',
      value: null,
    },
  },
  {
    key: 'token.attributeValueIds',
    name: 'Token Traits',
    type: FILTER_TYPES.ARRAY,
    component: TraitFilter,
    displayComponent: TokenTraitFilterDisplay,
    props: {
      allowVariables: true,

    },
  },
  {
    key: 'collection.listedFloor',
    name: 'Collection Floor',
    type: FILTER_TYPES.NUMBER,
    props: {
      allowVariables: true,
    },
  },
  {
    key: 'collection.totalVolume',
    name: 'Collection Volume',
    type: FILTER_TYPES.NUMBER,
    props: {
      allowVariables: true,
    },
  },
  {
    name: 'Collection Liquidity',
    key: 'collection.liquidityRank',
    type: FILTER_TYPES.CATEGORY,
    defaultValue: ['Low', 'Medium', 'High'],
    props: {
      allowVariables: true,
      multiple: true,
      options: [
        {
          name: 'Dead',
          value: 'Dead',
        },
        {
          name: 'Low',
          value: 'Low',
        },
        {
          name: 'Medium',
          value: 'Medium',
        },
        {
          name: 'High',
          value: 'High',
        },
      ],
    },
  },
  {
    name: 'Sale Type',
    key: 'sale.type',
    type: FILTER_TYPES.CATEGORY,
    defaultValue: null,
    props: {
      multiple: false,
      allowVariables: true,
      options: [
        {
          name: 'Bid',
          value: 'bid',
        },
        {
          name: 'Purchase',
          value: 'purchase',
        },
        {
          name: 'Blend',
          value: 'blend',
        },
      ],
    },
  },
  {
    name: 'Sale Marketplace',
    key: 'sale.marketplace',
    type: FILTER_TYPES.CATEGORY,
    defaultValue: null,
    props: {
      multiple: true,
      allowVariables: true,
      options: [
        {
          name: 'Opensea',
          value: 3,
        },
        {
          name: 'Looksrare',
          value: 1,
        },
        {
          name: 'X2Y2',
          value: 4,
        },
        {
          name: 'Cryptopunks',
          value: 5,
        },
        {
          name: 'Sudoswap',
          value: 6,
        },
        {
          name: 'Blur',
          value: 7,
        },
      ],
    },
  },
  {
    name: 'Marketplace',
    key: 'metadata.marketplace',
    type: FILTER_TYPES.CATEGORY,
    defaultValue: null,
    props: {
      multiple: true,
      allowVariables: true,
      options: [
        {
          name: 'Opensea',
          value: 3,
        },
        {
          name: 'Looksrare',
          value: 1,
        },
        // {
        //   name: 'X2Y2',
        //   value: 4,
        // },
        // {
        //   name: 'Cryptopunks',
        //   value: 5,
        // },
        // {
        //   name: 'Sudoswap',
        //   value: 6,
        // },
        {
          name: 'Blur',
          value: 7,
        },
      ],
    },
  },
  {
    key: 'flip.netProfit',
    name: 'Net Profit',
    type: FILTER_TYPES.NUMBER,
    props: {
      allowVariables: true,
    },
  },
].filter((item) => variables.value.find((i) => i.id.replace('event.', '') === item.key)));

const triggerIcon = computed(() => {
  const d = availableTriggers.find((t) => t.value === triggerModel.value);
  return d ? d.icon : 'fad fa-question';
});

const getError = (key) => {
  const error = validationIssues.value.find((i) => isEqual(i.path, key));
  return error ? error.message : null;
};

const triggerErrors = computed(() => validationIssues.value.filter((i) => i.path[0] === 'triggerConfig'));
const actionErrors = computed(() => validationIssues.value.filter((i) => i.path[0] === 'actions'));

const selectTrigger = ({ value, defaultConfig, name: triggerName }) => {
  trigger.value = value;
  isTriggerAccordionOpen.value = triggerName;
  if (typeof defaultConfig === 'function') {
    triggerConfig.value = defaultConfig();
  } else {
    triggerConfig.value = {};
  }
};
const webhookUrl = computed(() => {
  if (triggerConfig.value.webhookPath) {
    return `https://matiz-api.compass.art${triggerConfig.value.webhookPath}`;
  }
  return null;
});
const copyWebhookUrl = () => copyTextToClipboard(webhookUrl.value);
</script>
