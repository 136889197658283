<template>
  <div
    ref="target"
  >
    <slot />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  toRefs,
} from 'vue';

import { useIntersectionObserver } from '@/composition/intersection-observer';

export default defineComponent({
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ['is-show', 'is-hide'],

  setup(props, { emit }) {
    const { options } = toRefs(props);

    const target = ref(null);

    function cbWhenShow() {
      emit('is-show');
    }

    function cbWhenHide() {
      emit('is-hide');
    }

    const { initIntersectionObserver } = useIntersectionObserver({
      target, cbWhenShow, cbWhenHide, options,
    });

    onMounted(initIntersectionObserver);

    return {
      target,
    };
  },
});
</script>
