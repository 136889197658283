<template>
  <div
    v-if="!loading"
    class="flex gap-3 items-center"
  >
    <span v-if="attributeValue"><span class="font-bold">{{ attributeValue.attribute.name }}</span>: {{ attributeValue.value }}</span>

    <span
      v-else
      class="text-italic"
    >None</span>
  </div>

  <div
    v-else
    class="w-24 h-3 bg-gray-800 rounded-lg animate-pulse"
  />
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import { toRefs, computed } from 'vue';
import gql from 'graphql-tag';
import { groupBy, keyBy } from 'lodash-es';

const props = defineProps(['filter', 'value']);
const { filter, value } = toRefs(props);
const query = gql`
  query ($id: String!) {
    attributeValues(where: {id: {in: [$id]}}) {
      id
      value
      attribute {
        id
        name
      }
    }
  }
`;

const { result, loading } = useQuery(query, () => ({ id: value.value?.value?.value }), { enabled: computed(() => !!value.value?.value?.value) });
const attributeValue = computed(() => result.value?.attributeValues?.[0]);
</script>
