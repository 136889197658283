<template>
  <div>
    <div class="p-3">
      <ResourcePicker
        only="wallet"
        @update:modelValue="addModel"
      />
    </div>

    <ul class="divide-y divide-gray-800">
      <li
        v-for="item in members"
        :key="item.id"
        class="flex justify-between items-center py-2 px-3 hover:bg-gray-800 transition"
      >
        <WalletProfile
          :wallet="item"
        />

        <UiButton
          v-if="!isPortfolio"
          variant="white"
          size="xs"
          :is-loading="removeLoading[item.id]"
          @click="removeModel(item.id)"
        >
          Remove
        </UiButton>
      </li>

      <li
        v-for="item in loadingItems"
        :key="item"
        class="flex justify-between items-center py-2 px-3"
      >
        <WalletProfile
          :is-loading="true"
          no-overlay
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import {
  computed, reactive, ref, toRefs,
} from 'vue';
import ResourcePicker from '../ResourcePicker.vue';
import UiButton from '../ui/UiButton.vue';
import WalletProfile from '../wallets/WalletProfile.vue';

const props = defineProps(['watchlistId', 'enabled']);
const emit = defineEmits(['membersUpdated']);

const { watchlistId, enabled } = toRefs(props);

const query = gql`
query GetWatchlistMembers($watchlistId: Int!) {
  watchlist: walletWatchlist(id: $watchlistId) {
    id
    slug
    members {
      id
      name
      openseaProfile
    }
  }
}
`;

const { result, loading, refetch } = useQuery(query, () => ({ watchlistId: watchlistId.value }), () => ({ enabled: !!enabled.value }));
const isPortfolio = computed(() => result.value?.watchlist?.slug === 'portfolio');
const members = computed(() => result.value?.watchlist?.members || []);
const isAdding = ref(false);
const { add, remove } = useWatchlists();
const addModel = (modelId) => {
  isAdding.value = true;
  return add({ id: watchlistId.value, modelIds: [modelId] })
    .then(() => refetch())
    .then(() => emit('membersUpdated'))
    .finally(() => {
      isAdding.value = false;
    });
};
const removeLoading = reactive({});
const removeModel = (modelId) => {
  removeLoading[modelId] = true;

  return remove({ id: watchlistId.value, modelIds: [modelId] })
    .then(() => refetch())
    .then(() => emit('membersUpdated'))
    .finally(() => {
      removeLoading[modelId] = false;
    });
};

const loadingItems = computed(() => (loading.value || isAdding.value ? 1 : 0));
</script>
