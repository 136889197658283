<template>
  <div
    ref="editorContainer"
    class="overflow-hidden w-full rounded-xl editor"
    :class="[heightClass]"
    @keyup.stop
  />
</template>

<script setup>
import * as monaco from 'monaco-editor';
import useMonacoEditor from '@/composition/monaco/use-monaco-editor';
import {
  toRefs, computed, ref,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  language: {
    type: String,
    default: 'typescript',
  },
  lineNumbers: {
    type: String,
    default: 'on',
  },
  heightClass: {
    type: String,
    default: 'h-96',
  },
  sdkVersion: {
    type: String,
    default: 'v1',
  },
  extraDeclarations: {
    type: Array,
    default() {
      return [];
    },
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const {
  modelValue, language, lineNumbers, heightClass, extraDeclarations, sdkVersion, readonly,
} = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return modelValue.value;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const { result } = useQuery(gql`
  query GetIDEDefinitions($sdkVersion: AutomationSdkVersion!) {
    ideTypeDefinitions(sdkVersion: $sdkVersion) {
      name
      contents
    }
  }
`, () => ({
  sdkVersion: sdkVersion.value,
}));

const defaultLibs = computed(() => (result.value?.ideTypeDefinitions || []).map(({ name, contents }) => ({
  content: contents,
  filePath: name === 'file:///index.d.ts' ? name : `file:///node_modules/@types/compass__${name}`,
})));

const editorContainer = ref(null);
const options = computed(() => ({
  language: language.value,
  lineNumbers: lineNumbers.value,
  readOnly: readonly.value !== false,
  extraDeclarations: [
    ...defaultLibs.value,
    ...extraDeclarations.value,
  ],
}));
const { insertText, registerCompletionItemProvider } = useMonacoEditor(value, editorContainer, options);

function createDependencyProposals(range) {
  // returning a static list of proposals, not even looking at the prefix (filtering is done by the Monaco editor),
  // here you could do a server side lookup
  return [
    {
      label: 'import http from "@compass/http"',
      kind: monaco.languages.CompletionItemKind.Module,
      documentation: 'HTTP library for making requests to any API',
      insertText: 'import http from "@compass/http";',
      range,
    },
    {
      label: 'import compass from "@compass/api"',
      kind: monaco.languages.CompletionItemKind.Module,
      documentation: 'API library for making GraphQL requests to the Compass API',
      insertText: 'import compass from "@compass/api";',
      range,
    },
    {
      label: 'import cache from "@compass/cache"',
      kind: monaco.languages.CompletionItemKind.Module,
      documentation: 'Cache library for caching data in a persistent cache store',
      insertText: 'import cache from "@compass/cache";',
      range,
    },
  ];
}

registerCompletionItemProvider('typescript', 'ui-code-editor', {
  provideCompletionItems(model, position) {
    // find out if we are completing a property in the 'dependencies' object.
    const textUntilPosition = model.getValueInRange({
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });
    const match = textUntilPosition.match(
      /^([\s,[;]*import.*[\s,[;]*)*$/g,
    );
    if (!match) {
      return { suggestions: [] };
    }
    const word = model.getWordUntilPosition(position);
    console.log('word', word);
    const range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: 1,
      endColumn: word.endColumn,
    };
    return {
      suggestions: createDependencyProposals(range),
    };
  },
});

defineExpose({
  insertText,
});
</script>
