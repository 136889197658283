<template>
  <UiDropdown
    top
    body-width-class="w-96"
  >
    <template #toggle="{toggle}">
      <button
        class="flex items-center font-medium text-white"
        @click="open(toggle)"
      >
        <span class="hidden md:inline mr-2"><i class="text-gray-300 fad fa-calculator" /></span>

        <span class="hidden md:inline mr-1">Calculator
        </span>
      </button>
    </template>

    <template #body>
      <UiDropdownForm>
        <UiInputGroup label="Collection">
          <ResourcePicker
            v-model="collectionId"
            only="collection"
          />
        </UiInputGroup>

        <div class="grid grid-cols-2 gap-3">
          <UiInputGroup label="Paid">
            <UiInput v-model="boughtFor" />
          </UiInputGroup>

          <UiInputGroup label="Sell for">
            <UiInput v-model="soldFor" />
          </UiInputGroup>
        </div>

        <div
          class="flex flex-col gap-2 p-3 text-white bg-gray-900 rounded-xl border border-gray-700"
        >
          <div class="flex justify-between text-sm">
            <div>Gross Profit</div>

            <div v-if="royalties !== null">
              <CurrencyDisplay :value="soldFor - boughtFor" />
            </div>

            <div v-else>
              ?
            </div>
          </div>

          <div class="flex justify-between text-sm">
            <div>Royalties</div>

            <div v-if="royalties !== null">
              <CurrencyDisplay :value="royalties" />
            </div>

            <div v-else>
              ?
            </div>
          </div>

          <div
            v-for="(item, index) in marketplaceFees"
            :key="index"
            class="flex justify-between text-sm"
          >
            <div>{{ item.marketplace }} fee</div>

            <div v-if="royalties !== null">
              <CurrencyDisplay :value="item.feesPaid" />
            </div>

            <div v-else>
              ?
            </div>
          </div>

          <UiDropdownDivider />

          <div class="flex justify-between text-sm font-bold">
            <div>Net profit</div>

            <div v-if="royalties !== null">
              <CurrencyDisplay
                :value="minNetProfit"
                :class="{'text-red-500': minNetProfit < 0, 'text-green-500': minNetProfit > 0}"
              />
              —
              <CurrencyDisplay
                :value="maxNetProfit"
                :class="{'text-red-500': maxNetProfit < 0, 'text-green-500': minNetProfit > 0}"
              />
            </div>

            <div v-else>
              ?
            </div>
          </div>
        </div>
      </UiDropdownForm>
    </template>
  </UiDropdown>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import collectionListItemQuery from '@/graphql/collection/queries/collectionListItemWithFloor.query.gql';
import { useQuery } from '@vue/apollo-composable';
import { maxBy, minBy } from 'lodash-es';
import useTracking from '@/composition/tracking';
import UiDropdown from './ui/ui-dropdown/UiDropdown.vue';
import ResourcePicker from './ResourcePicker.vue';
import UiDropdownForm from './ui/UiDropdownForm.vue';
import UiInputGroup from './ui/UiInputGroup.vue';
import UiInput from './ui/UiInput.vue';
import CurrencyDisplay from './CurrencyDisplay.vue';
import UiDropdownDivider from './ui/UiDropdownDivider.vue';

const MARKETPLACE_FEES = [
  { name: 'Opensea', fee: 250 },
  { name: 'Looksrare', fee: 200 },
  { name: 'X2Y2', fee: 50 },
];

const collectionId = ref(null);

const {
  result: collectionData,
} = useQuery(
  collectionListItemQuery,
  () => ({ address: collectionId.value }),
  () => ({ enabled: Boolean(collectionId.value && !collectionId.value?.id) }),
);

const collection = computed(() => collectionData.value?.collection);

const boughtFor = ref(null);
const soldFor = ref(null);

const royalties = computed(() => {
  if (!collection.value) {
    return null;
  }

  return (collection.value.devFeeBasisPoints * soldFor.value) / 10000;
});

const marketplaceFees = computed(() => MARKETPLACE_FEES.map((item) => ({
  marketplace: item.name,
  feesPaid: (soldFor.value * item.fee) / 10000,
})));

const minNetProfit = computed(() => {
  if (!collection.value || royalties.value === null) {
    return null;
  }

  return soldFor.value - royalties.value - boughtFor.value - maxBy(marketplaceFees.value, 'feesPaid').feesPaid;
});
const maxNetProfit = computed(() => {
  if (!collection.value || royalties.value === null) {
    return null;
  }

  return soldFor.value - royalties.value - boughtFor.value - minBy(marketplaceFees.value, 'feesPaid').feesPaid;
});

watch(() => collection.value, (value) => {
  if (value) {
    soldFor.value = value.listedFloor;
  }
});

const { track } = useTracking();
const open = (callback) => {
  callback();
  track('profit calculator toggled');
};

</script>
