<template>
  <div class="mb-24">
    <ActionForm
      v-model="model"
      no-sdk-change
    >
      <template #buttons>
        <UiButton
          size="lg"
          :is-loading="isLoading"
          @click="update"
        >
          Update
        </UiButton>
      </template>

      <template #tabs>
        <UiTab
          v-if="action"
          name="Releases"
        >
          <div v-if="action?.releases?.length === 0">
            <NoContent
              content="No releases yet"
              horizontal
              size="sm"
            >
              <template #cta>
                <UiButton
                  variant="white"
                  @click="createReleaseModal.show()"
                >
                  Create release
                </UiButton>
              </template>
            </NoContent>
          </div>

          <div
            v-else
            class="mb-6"
          >
            <UiButton
              variant="white"
              @click="createReleaseModal.show()"
            >
              Create release
            </UiButton>
          </div>

          <ActionRelease
            v-for="release in action.releases"
            :key="release.id"
            class="mb-6"
            :release="release"
          />
        </UiTab>
      </template>
    </ActionForm>
  </div>

  <CreateActionRelease
    v-if="action"
    ref="createReleaseModal"
    :action="action"
  />
</template>

<script setup>
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import {
  reactive, ref, toRefs, onMounted, inject,
} from 'vue';
import UiButton from '@/components/ui/UiButton.vue';
import ActionForm from '@/components/automation/ActionForm.vue';
import useAutomationActions from '@/composition/automation/use-automation-actions';
import moment from 'moment';
import useDialogs from '../ui/composition/use-dialogs';
import UiTab from '../ui/UiTab.vue';
import UiCard from '../ui/UiCard.vue';
import NoContent from '../NoContent.vue';
import CreateActionRelease from './CreateActionRelease.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import UiBadge from '../ui/UiBadge.vue';
import UiCodeEditor from '../ui/UiCodeEditor.vue';
import ContextVariablesEditor from './ContextVariablesEditor.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import ActionRelease from './ActionRelease.vue';

const props = defineProps(['actionId']);
const { actionId } = toRefs(props);
const { getAction, refetchActions } = useAutomationActions();
const { action, onResult, refetch } = getAction(actionId);
const confirm = inject('confirm');
const model = reactive({
  name: '',
  icon: '',
  script: '',
  inputs: [],
  outputs: [],
  snippetIds: [],
});

const init = () => {
  const data = action.value ? JSON.parse(JSON.stringify(action.value)) : null;
  if (!data) {
    return;
  }
  model.sdkVersion = data.sdkVersion;
  model.icon = data.icon;
  model.name = data.name;
  model.script = data.script;
  model.inputs = data.inputs;
  model.outputs = data.outputs;
  model.snippetIds = data.snippetIds;
};

onResult(init);
onMounted(init);

const {
  mutate,
} = useMutation(gql`
mutation UpdateAutomationAction($id: String!, $action: CreateAutomationActionInput!) {
  updateAutomationAction(id: $id, action: $action) {
    id
    sdkVersion
    name
    icon
    snippetIds
    inputs {
      name
      type
      actingAs
      required
      defaultValue
      items
      properties
      description
    }
    outputs {
      name
      type
      items
      properties
      description
    }
  }
}
`);
const isLoading = ref(false);
const { notify } = useDialogs();
const update = () => {
  isLoading.value = true;
  return mutate({
    id: actionId.value,
    sdkVersion: model.sdkVersion,
    action: {
      name: model.name,
      icon: model.icon,
      sdkVersion: model.sdkVersion,
      script: model.script,
      inputs: model.inputs.map((item) => ({
        name: item.name,
        type: item.type,
        required: item.required,
        defaultValue: item.defaultValue,
        actingAs: item.actingAs,
        items: item.items,
        properties: item.properties,
        description: item.description,
      })),
      outputs: model.outputs.map((item) => ({
        name: item.name,
        type: item.type,
        items: item.items,
        properties: item.properties,
        description: item.description,
      })),
      snippetIds: model.snippetIds,
    },
  }, {
    errorPolicy: 'none',
  }).then(() => {
    notify({
      title: 'Action updated',
    });
    refetchActions();
  }).catch((err) => {
    notify({
      title: 'Error',
      message: err.message,
      variant: 'warning',
    });
  }).finally(() => {
    isLoading.value = false;
  });
};

const createReleaseModal = ref(null);

</script>
