<script setup>
import { useDevice } from '@/composition/device-detector';

import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiButton from '@/components/ui/UiButton.vue';

defineProps({
  vertical: {
    default: false,
    type: Boolean,
  },
  pills: {
    default: false,
    type: Boolean,
  },
  tabsData: {
    type: Map,
    required: true,
  },
});

const { isMinimumSmScreen } = useDevice();
</script>

<script>
export class UiTabs2Item {
  constructor({
    key,
    title,
    path,
  }) {
    this.key = key;
    this.title = title;
    this.path = path;
  }
}

export function generateTabsData(tabsData, { key, title, path }) {
  tabsData.set(key, new UiTabs2Item({ key, title, path }));
}

</script>

<template>
  <div>
    <!-- Tabs -->
    <div class="mx-auto max-w-screen-2xl">
      <div :class="{'md:border-b md:border-gray-200 md:dark:border-gray-800': !pills }">
        <div class="flex flex-col md:flex-row gap-4 md:gap-0 md:justify-between md:items-center">
          <nav
            v-if="!pills"
            aria-label="Tabs"
            class="flex overflow-x-auto flex-1 gap-2 -mb-px whitespace-nowrap border-b border-gray-200 dark:border-gray-800 md:border-none scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
          >
            <RouterLink
              v-for="data in tabsData"
              :key="data[1].key"
              v-slot="{ navigate, href, isExactActive: isActive }"
              :to="data[1].path"
              custom
            >
              <a
                class="py-2.5 md:py-4 px-3 md:px-4 text-sm font-medium whitespace-nowrap border-b-2 transition-colors"
                :class="isActive ? 'border-indigo-500 text-gray-900 dark:text-gray-100' : 'border-transparent text-gray-500 dark:text-gray-300 dark:hover:text-gray-300 hover:text-gray-700 hover:border-gray-300'"
                :href="href"
                @click="navigate"
              >
                {{ data[1].title }}
              </a>
            </RouterLink>
          </nav>

          <div v-else>
            <div
              v-if="!isMinimumSmScreen"
              :class="{
                'hidden': tabsData.size <= 2,
                'flex': true,
              }"
            >
              <slot name="mobileTabs">
                <UiDropdown>
                  <template #toggle="{toggle, isVisible}">
                    <UiButton
                      variant="white"
                      class="flex items-center"
                      @click="toggle"
                    >
                      <div>
                        {{ tabsData.get($route.meta.key) }}
                      </div>

                      <div class="ml-3 text-sm">
                        <i
                          class="far"
                          :class="`fa-chevron-${isVisible ? 'up' : 'down'}`"
                        />
                      </div>
                    </UiButton>
                  </template>

                  <template #body="{ forceClose }">
                    <UiDropdownItem
                      v-for="data in tabsData"
                      :key="data[1].key"
                      :to="data[1].path"
                      tag="router-link"
                      @click="() => forceClose()"
                    >
                      {{ data[1].title }}
                    </UiDropdownItem>
                  </template>
                </UiDropdown>
              </slot>
            </div>

            <nav
              v-else
              class="flex flex-wrap space-x-2"
              aria-label="Tabs"
              :class="{'hidden sm:flex': tabsData.size > 2 }"
            >
              <slot name="desktopTabs">
                <RouterLink
                  v-for="data in tabsData"
                  :key="data[1].key"
                  v-slot="{ isExactActive: isActive, navigate, href }"
                  :to="data[1].path"
                  custom
                >
                  <a
                    class="block py-1.5 px-3 text-sm font-medium whitespace-nowrap rounded-lg transition-colors"
                    :class="isActive ? 'bg-gray-200 text-indigo-500 dark:bg-indigo-500 dark:bg-opacity-15' : 'dark:hover:text-indigo-500 hover:text-indigo-500 text-gray-600 dark:text-gray-400'"
                    :href="href"
                    @click="navigate"
                  >
                    {{ data[1].title }}
                  </a>
                </RouterLink>
              </slot>
            </nav>
          </div>

          <slot name="tabs-extra" />
        </div>
      </div>
    </div>

    <div class="mt-4">
      <slot />
    </div>
  </div>
</template>

<style scoped>

</style>
