<template>
  <div class="relative ">
    <slot name="input">
      <UiInput
        v-model="model"
        :placeholder="placeholder"
        class="pl-10"
      />
    </slot>

    <i class="absolute top-1/2 left-3 text-gray-400 transform -translate-y-1/2 far fa-search" />
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import UiInput from './UiInput.vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  widthClass: {
    type: String,
    default: 'w-72',
  },
  placeholder: {
    type: String,
    default: 'Search',
  },
});
const emit = defineEmits(['update:modelValue']);

const { modelValue, placeholder } = toRefs(props);

const model = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>
