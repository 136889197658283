<template>
  <div>
    <ul class="mb-3">
      <li
        v-for="item in scriptSnippets"
        :key="item.id"
      >
        <UiCheckbox
          v-model="value"
          :value="item.id"
        >
          {{ item.name }}
        </UiCheckbox>
      </li>
    </ul>

    <UiButton
      size="xs"
      variant="white"
      @click="isCreateModalVisible = true"
    >
      <i class="mr-1 far fa-plus" />
      New Snippet
    </UiButton>
  </div>

  <CreateSnippetModal
    v-model="isCreateModalVisible"
    @created="value = [...value, $event.id]"
  />
</template>

<script setup>
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, ref } from 'vue';
import CreateSnippetModal from './CreateSnippetModal.vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const { result } = useQuery(gql`
 query GetScriptSnippets {
  scriptSnippets {
    id
    name
    code
  }
 }
`);

const scriptSnippets = computed(() => result.value?.scriptSnippets);

const isCreateModalVisible = ref(false);
</script>
