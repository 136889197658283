<template>
  <form
    class="block
      py-1.5
      px-3
      w-full
      sm:text-sm
      placeholder-gray-400
      text-gray-100
      dark:bg-gray-700 dark:bg-opacity-70 border dark:border-gray-700 outline-none dark:active:bg-gray-750
      rounded-lg
  ring-0 focus:ring-0
      shadow-sm
      transition-colors
      dark:focus:border-indigo-500 focus:outline-none"
  >
    <CardComponent
      ref="cardComponent"
      class="fieldset"
      :fonts="config.fonts"
      :styles="config.styles"
      :locale="config.locale"
      :classes="config.classes"
      @change="onChange"
    >
      <CardNumber
        class="field empty"
        :placeholder="'4111 1111 1111 1111'"
        @focus="onFocus"
      />

      <CardExpiry
        class="field empty"
        :placeholder="'MM / YY'"
        @focus="onFocus"
      />

      <CardCvv
        class="field empty"
        :placeholder="'CVV'"
        @focus="onFocus"
      />
    </CardComponent>

    <div id="errors">
      {{ errorMessage }}
    </div>
  </form>
</template>

<script setup>
import {
  CardComponent, CardNumber, CardExpiry, CardCvv,
} from '@chargebee/chargebee-js-vue-wrapper';
import { ref } from 'vue';

const config = {
  styles: {
    base: {
      color: '#fff',
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: 'rgba(245,245,245)',
      },

      '::placeholder': {
        color: 'rgba(163, 163, 163)',
      },

      ':focus::placeholder': {
        color: 'rgba(163, 163, 163)',
      },
    },
    invalid: {
      color: '#fff',
      ':focus': {
        color: '#FA755A',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  locale: 'en',
  classes: {
    focus: 'focus',
    complete: 'complete',
    invalid: 'invalid',
    empty: 'empty',
  },
};

const cardComponent = ref(null);
const isValid = ref(false);
const tokenize = () => cardComponent.value.tokenize();

defineExpose({
  tokenize,
  isValid,
});

const onSubmit = (e) => {
  e.preventDefault();
  // this.$refs.cardComponent.tokenize().then((data) => {
  //   console.log('chargebee token', data.token);
  // });
};
const onChange = (status) => {
  console.log(status);
  isValid.value = status.complete && !status.error;
  // const errors = {
  //   ...this.errors,
  //   [status.field]: status.error,
  // };
  // this.errors = errors;
  // const { message } = Object.values(errors).filter((message) => !!message).pop() || {};
  // this.errorMessage = message;
};
const onFocus = (event) => {
  console.log(event.field, 'focused');
};

</script>
