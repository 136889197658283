<template>
  <div>
    <!-- Tabs -->
    <div class="mx-auto max-w-screen-2xl">
      <div :class="{'border-b border-gray-200 dark:border-gray-800': pills === false}">
        <div
          v-if="hideSingleTab === false || displayedTabs.length > 1"
          class="flex flex-col md:flex-row md:justify-between md:items-center space-y-2 md:space-y-0"
        >
          <nav
            v-if="pills === false"
            class="-mb-px flex-1 flex space-x-8 whitespace-nowrap overflow-x-auto scrollbar-none
"
            aria-label="Tabs"
          >
            <a
              v-for="(tab, tabIndex) in displayedTabs"
              :key="tabIndex"
              href="javascript:void(0)"
              class="py-4 px-1 text-sm font-medium whitespace-nowrap border-b-2 transition-colors"
              :class="tabIndex === currentTabIndex ? 'border-indigo-500 text-gray-900 dark:text-gray-100' : 'border-transparent text-gray-500 dark:text-gray-300 dark:hover:text-gray-300 hover:text-gray-700 hover:border-gray-300'"
              @click="setTabIndex(tabIndex)"
            >
              {{ tab.name }}
            </a>
          </nav>

          <div v-else>
            <div
              class="sm:hidden"
              :class="{'hidden': displayedTabs.length <= 2}"
            >
              <UiDropdown>
                <template #toggle="{toggle, isVisible}">
                  <UiButton
                    variant="white"
                    class="flex items-center"
                    @click="toggle"
                  >
                    <div v-if="displayedTabs[currentTabIndex]">
                      {{ displayedTabs[currentTabIndex].name }}
                    </div>

                    <div class="ml-3 text-sm">
                      <i
                        class="far"
                        :class="`fa-chevron-${isVisible ? 'up' : 'down'}`"
                      />
                    </div>
                  </UiButton>
                </template>

                <template #body="{ forceClose }">
                  <UiDropdownItem
                    v-for="(tab, tabIndex) in displayedTabs"
                    :key="tabIndex"
                    @click="() => {
                      setTabIndex(tabIndex);
                      forceClose();
                    }"
                  >
                    {{ tab.name }}
                  </UiDropdownItem>
                </template>
              </UiDropdown>
            </div>

            <nav
              class="flex flex-wrap gap-x-2 gap-y-3"
              aria-label="Tabs"
              :class="{'hidden sm:flex': displayedTabs.length > 2 }"
            >
              <a
                v-for="(tab, tabIndex) in displayedTabs"
                :key="tabIndex"
                href="javascript:void(0)"
                class="block py-1.5 px-3 text-sm font-medium whitespace-nowrap rounded-lg transition-colors"
                :class="tabIndex === currentTabIndex ? 'bg-gray-200 text-indigo-500 dark:bg-indigo-500 dark:bg-opacity-15' : 'dark:hover:text-indigo-500 hover:text-indigo-500 text-gray-600 dark:text-gray-400'"
                @click="setTabIndex(tabIndex)"
              >
                {{ tab.name }}
              </a>
            </nav>
          </div>

          <slot name="tabs-extra" />
        </div>
      </div>
    </div>

    <div :class="{'mt-6': hideSingleTab === false || displayedTabs.length > 1}">
      <slot />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  provide,
  reactive,
  toRefs,
  onMounted,
  computed,
  watch,
} from 'vue';

import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiButton from '@/components/ui/UiButton.vue';

export default defineComponent({
  name: 'UiTabs',
  components: {
    UiButton,
    UiDropdownItem,
    UiDropdown,
    // renderVnode: {
    //   functional: true,
    //   render: (h, ctx) => ctx.props.vnode
    // }
  },
  props: {
    modelValue: null,
    vertical: {
      default: false,
      type: Boolean,
    },
    pills: {
      default: false,
      type: Boolean,
    },
    tabs: {
      default() {
        return [];
      },
      type: Array,
    },
    hideSingleTab: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { modelValue, tabs } = toRefs(props);
    const state = reactive({
      children: [],
      localTabIndex: 0,
    });

    function addTab(tab) {
      state.children.push(tab);
    }
    function removeTab(tab) {
      state.children = state.children.filter((t) => t !== tab);
    }
    provide('addTab', addTab);
    provide('removeTab', removeTab);

    const currentTabIndex = computed({
      get() {
        if (modelValue.value == null) {
          return state.localTabIndex;
        }
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    const displayedTabs = computed(() => {
      if (state.children.length === 0 && tabs.value.length > 0) {
        return tabs.value;
      }

      return state.children;
    });

    function setTabIndex(index, isExternalChange = false) {
      if (!isExternalChange) {
        currentTabIndex.value = index;
      }

      state.localTabIndex = index;

      for (let i = 0; i < state.children.length; i++) {
        state.children[i].isActive = (i === state.children.indexOf(displayedTabs.value[index]));
      }
    }

    onMounted(() => {
      setTabIndex(currentTabIndex.value);
    });

    watch(() => currentTabIndex.value, (newVal) => {
      if (newVal !== state.localTabIndex) {
        setTabIndex(newVal, true);
      }
    });

    return {
      ...toRefs(state),
      currentTabIndex,
      setTabIndex,
      displayedTabs,
    };
  },
});
</script>

<style scoped>

</style>
