<template>
  <div class="mx-auto max-w-screen-2xl">
    <Filters
      v-model="filters"
      :filters="availableFilters"
      class="mb-6"
      group="wallet"
    />

    <PortfolioAnalytics
      :model="model"
      :is-loading="isLoading"
      :filters="filters"
      mode="watchlist"
    />
  </div>
</template>

<script setup>

import {
  computed,
  ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';

// import Chart from '@/components/Chart.vue';
import gql from 'graphql-tag';

import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { WATCHLIST_TYPES } from '@/constants';
import { FILTER_TYPES } from '@/constants/filters';
import { useFiltersQuerySync } from '@/composition/filters/useFiltersQuerySync';
import PortfolioAnalytics from '../PortfolioAnalytics.vue';
import Filters from '../filters/Filters.vue';

const route = useRoute();

const slug = computed(() => route.params.slug || 'portfolio');

const availableFilters = [
  {
    name: 'Collection',
    key: 'collectionId',
    type: FILTER_TYPES.COLLECTION,
    autoPin: true,
  },
];
const filters = ref([]);
// useFiltersQuerySync(filters);
useFiltersQuerySync(filters);

const query = gql`
query GetWalletWatchlistAnalytics($slug: String!, $filters: [FilterArg!]) {
  watchlist: walletWatchlistBySlug(slug: $slug) {
      id
      slug
      analytics(filters: $filters) {
          totalSpent
          totalRevenue
          totalProfit
          totalRealizedGains
          totalGasSpent
      }
      totalPortfolioValue(filters: $filters)
      totalPortfolioValueByLiquidity {
        liquidity
        value
      }
  }
}
`;

const {
  result,
  loading: isLoading,
  refetch,
} = useQuery(query, () => ({ slug: slug.value, filters: filters.value }));

const { onWatchlistMembersUpdated } = useWatchlists(WATCHLIST_TYPES.WALLET);

onWatchlistMembersUpdated(() => refetch());

const model = computed(() => result.value?.watchlist || { slug: slug.value });

</script>

<style scoped>

</style>
