<template>
  <TransitionGroup name="list">
    <div
      v-if="isRefetching"
      class="flex fixed bottom-10 left-1 gap-2 items-center py-1 px-1.5 pr-3 text-xs font-bold leading-none text-white bg-black bg-opacity-75 rounded-xl"
    >
      <LoadingIndicator :size="16" />

      <span class="text-gray-200">Syncing data</span>
    </div>
  </TransitionGroup>
</template>

<script setup>
import { useRefetchQueries } from '@/composition/refetch-queries';
import LoadingIndicator from './LoadingIndicator.vue';

const { isRefetching } = useRefetchQueries();
</script>

<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}</style>
