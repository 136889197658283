<template>
  <UiModal
    v-model="isVisible"
    align="top"
  >
    <UiModalHeader
      title="Sweep tokens"
      @dismiss="isVisible = false"
    />

    <div class="py-3 px-4">
      <UiInputGroup
        label="Token count"
      >
        <div class="grid grid-cols-6 gap-6 items-center">
          <div class="col-span-5">
            <input
              v-model="tokenCount"
              type="range"
              min="1"
              :max="tokens.length"
              class="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-lg border-indigo-500 appearance-none cursor-pointer"
            >
          </div>

          <div>
            <UiInput
              v-model="tokenCount"
            />
          </div>
        </div>
      </UiInputGroup>

      <div class="flex justify-between items-center py-3">
        <div>Total</div>

        <div class="text-lg">
          {{ formatCurrency(totalPrice) }}
        </div>
      </div>

      <div
        class="grid grid-cols-4 gap-3"
        :class="{'animate-pulse': isLoading}"
      >
        <div
          v-for="token in selectedTokens"
          :key="token.id"
        >
          <div class="overflow-hidden rounded-lg aspect-w-1 aspect-h-1 aspect">
            <TokenImage
              absolute
              :url="token.previewImageUrl"
              :fallback="collectionImageUrl"
            />
          </div>

          <div class="flex gap-2 justify-center items-center py-1.5 text-center">
            <MarketplaceIcon :marketplace="token.orders[0].marketplace" />

            <span class="font-medium">{{ formatCurrency(token.orders[0].price) }}</span>
          </div>
        </div>
      </div>
    </div>

    <UiModalFooter class="sticky bottom-0">
      <div class="flex justify-between items-center w-full">
        <div v-if="!isLoading">
          <span
            v-if="errorMessage.length && hasEnoughBalance"
            class="text-sm text-red-500"
          >{{ errorMessage }}</span>

          <span
            v-if="!hasEnoughBalance"
            class="text-sm text-yellow-500"
          >Insufficient funds</span>
        </div>

        <div v-if="isLoading">
          <LoadingIndicator :size="4">
            Refreshing
          </LoadingIndicator>
        </div>

        <UiButton
          size="sm"
          :is-loading="isSweeping"
          :disabled="isLoading || !hasEnoughBalance || !selectedTokens.length"
          @click="buySelectedTokens"
        >
          <div class="flex gap-2 items-center">
            <i class="fas fa-broom" />

            <span class="font-medium">Sweep</span>
          </div>
        </UiButton>
      </div>
    </UiModalFooter>
  </UiModal>

  <UiButton
    size="sm"
    @click="showModal"
  >
    <div class="flex gap-2 items-center">
      <i class="fas fa-broom" />

      <span class="font-medium">Sweep</span>
    </div>
  </UiButton>
</template>

<script setup>
import { formatCurrency } from '@/utils/filters';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import {
  toRefs, ref, computed, onMounted,
} from 'vue';
import { useRefetchQueries } from '@/composition/refetch-queries';
import UiButton from '../ui/UiButton.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiModal from '../ui/UiModal.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';
import useOrderManager from '../../composition/trade/use-order-manager';
import MarketplaceIcon from '../MarketplaceIcon.vue';
import TokenImage from '../TokenImage.vue';
import useUserWallet from '../../composition/wallet/use-user-wallet';
import LoadingIndicator from '../LoadingIndicator.vue';

const props = defineProps({
  collectionId: {
    type: String,
    required: true,
  },
});

const { collectionId } = toRefs(props);

const isVisible = ref(false);

// load tokens for the collection with minimal required data
// we need

const {
  result,
  loading: isLoading,
  refetch,
} = useQuery(gql`
query GetSweepTokens($collectionId: String!, $take: Int) {
  collection(where: {id: $collectionId}) {
    id
    imageUrl
    tokens: tokensV2(orderBy: "price", orderDesc: false, take: $take) {
      id
      tokenId
      previewImageUrl
      price
      orders(where: {side: { equals: SELL }, status: {equals: ACTIVE}}, orderBy: {price: asc}, take: 1) {
        id
        status
        side
        price
        marketplace
      }
    }
  }
}
`, () => ({
  collectionId: collectionId.value,
  take: 50,
}));
useRefetchQueries({ sweepTokens: refetch });
const collectionImageUrl = computed(() => result.value?.collection?.imageUrl);
const tokens = computed(() => (result.value?.collection?.tokens || []).filter((item) => item.price));

const tokenCount = ref(1);

const unavailableIds = ref([]);

const selectedTokens = computed(() => tokens.value.filter((item) => !unavailableIds.value.includes(item.orders[0].id)).slice(0, tokenCount.value));

const totalPrice = computed(() => selectedTokens.value.reduce((acc, token) => acc + token.price, 0));

const { sweepTokens } = useOrderManager();
const isSweeping = ref(false);

const errorMessage = ref('');

const { balance, init } = useUserWallet();

const hasEnoughBalance = computed(() => balance.value >= totalPrice.value);

const buySelectedTokens = () => {
  errorMessage.value = '';
  isSweeping.value = true;

  return sweepTokens(selectedTokens.value.map((item) => item.orders[0].id)).finally(() => {
    isSweeping.value = false;
  }).catch((err) => {
    if (err.response?.data?.message?.includes('not found or not fillable')) {
      refetch();
    }
    const message = err.response?.data?.message || err.message;
    if (message.includes('user rejected transaction')) {
      return;
    }

    if (message.includes('not found or not fillable')) {
      return;
    }

    if (message.includes('execution reverted')) {
      errorMessage.value = 'Transaction failed. Please try again.';
      refetch();
      return;
    }

    errorMessage.value = message;
  });
};

const showModal = async () => {
  isVisible.value = true;
  await init();
};
</script>
