<template>
  <UiInputGroup
    label="Type"
    :error-message="getError?.(['type'])"
  >
    <UiSelectCustom
      v-model="computedValue.type"
      :options="IO_TYPES"
      class="w-full"
    />
  </UiInputGroup>

  <UiInputGroup
    label="Description"
    class="flex-1"
    :error-message="getError(['description'])"
  >
    <UiInput v-model="computedValue.description" />
  </UiInputGroup>

  <div
    v-if="computedValue.type === 'Object'"
    class="px-3 pt-2 mb-3 rounded-xl border border-gray-700"
  >
    <div class="mb-1 font-light text-white">
      Object Properties
    </div>

    <UiInputGroup
      class="mb-0"
    >
      <ContextVariablesEditor
        :cols="1"
        :model-value="computedValue.properties || []"
        @update:modelValue="computedValue.properties = $event"
      />
    </UiInputGroup>
  </div>

  <div
    v-if="computedValue.type === 'Array'"
    class="px-3 pt-2 mb-3 rounded-xl border border-gray-700"
  >
    <div class="mb-1 font-light text-white">
      Array Items
    </div>

    <UiInputGroup class="mb-0">
      <SingleContextVariableEditor
        :model-value="computedValue.items"
        :mode="mode"
        :get-error="getError"
        @update:modelValue="computedValue.items = $event"
      />
    </UiInputGroup>
  </div>

  <UiInputGroup
    v-if="computedValue.type"
    label="Acting As"
    :error-message="getError?.(['actingAs'])"
    hint="Specify type of value"
  >
    <UiSelectCustom
      v-model="computedValue.actingAs"
      :options="IO_ACTING_AS"
      class="w-full"
    />
  </UiInputGroup>

  <UiInputGroup
    v-if="mode === 'input'"
    label=""
    :error-message="getError?.(['required'])"
  >
    <div class="py-1.5">
      <UiToggle v-model="computedValue.required">
        Required
      </UiToggle>
    </div>
  </UiInputGroup>

  <UiInputGroup
    v-if="mode === 'input' && !computedValue.required"
    label="Default Value"
    class="flex-1"
    :error-message="getError?.(['defaultValue'])"
  >
    <UiInput
      v-model="computedValue.defaultValue"
      :disabled="computedValue.required"
    />
  </UiInputGroup>
</template>

<script setup>
import { toRefs, computed, watch } from 'vue';
import { IO_TYPES, IO_ACTING_AS } from '@/constants/automation';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiSelectCustom from '../ui/UiSelectCustom.vue';
import UiToggle from '../ui/UiToggle.vue';
import ContextVariablesEditor from './ContextVariablesEditor.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  mode: {
    type: String,
    default: 'input',
  },
  getError: {
    type: Function,
  },
});

const emit = defineEmits(['update:modelValue']);

const { modelValue, mode, getError } = toRefs(props);

const computedValue = computed({
  get() {
    return modelValue.value || {};
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

watch(() => computedValue.value?.type, (value) => {
  if (!value) {
    return;
  }
  if (value === 'Object' && !computedValue.value.properties) {
    computedValue.value.properties = [];
  }
  if (value === 'Array' && !computedValue.value.items) {
    computedValue.value.items = {
      type: null,
      actingAs: null,
    };
  }
}, {
  immediate: true,
});
</script>
