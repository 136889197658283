<template>
  <div
    :style="{ width: `${chartWidth}px` }"
    class="-mr-6 border-b dark:border-gray-600 volume-scatter__wrap"
  >
    <Chart
      :is-loading="false"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  toRefs,
  computed,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import moment from 'moment-timezone';
import { groupBy, maxBy } from 'lodash-es';
import collectionVolumeSparklineQuery from '@/graphql/collection/queries/collectionVolumeSparkline.query.gql';

import { formatCurrency } from '@/utils/filters';
import Chart, { createTooltip } from '@/components/Chart.vue';
import { dateRangeFromPeriod } from '@/utils/dateRangeFromPeriod';

const intervalPeriodMap = {
  '1m': 60 * 1000,
  '5m': 60 * 1000,
  '15m': 60 * 1000,
  '30m': 60 * 1000,
  '1h': 3 * 60 * 1000,
  '6h': 30 * 60 * 1000,
  '1d': 60 * 60 * 1000,
  '7d': 6 * 60 * 60 * 1000,
  '30d': 24 * 60 * 60 * 1000,
};

export default defineComponent({
  name: 'VolumeScatter',

  components: { Chart },

  props: [
    'collection',
    'attributeValue',
    'width',
    'height',
    'chartData',
    'interval',
    'type',
    'valueFilter',
    'max',
  ],

  emits: ['hasMax'],

  setup(props) {
    const {
      collection,
      interval,
      attributeValue,
      chartData,
    } = toRefs(props);

    const computedInterval = computed(() => interval.value || '1d');

    const {
      result: chartDataResult,
    } = useQuery(
      collectionVolumeSparklineQuery,
      () => ({
        contractAddress: collection.value?.address,
        interval: computedInterval.value,
        attributeValueId: attributeValue.value?.id,
      }),
      () => ({
        enabled: !chartData.value,
      }),
    );
    const remoteChartData = computed(() => chartDataResult.value?.collection.analytics.volume);

    return {
      remoteChartData,
      computedInterval,
    };
  },

  computed: {
    chartWidth() {
      return typeof this.width !== 'undefined' ? this.width : 150;
    },
    chartOptions() {
      let chartData = this.chartData || this.remoteChartData || [];
      const valueFilter = this.valueFilter
        ? this.$options.filters[this.valueFilter]
        : formatCurrency;

      function groupByPeriodLength(items, periodLength = 60 * 60 * 1000) {
        const grouped = groupBy(items, (item) => Math.floor(moment(item.key).toDate().getTime() / periodLength));
        const result = [];
        Object.keys(grouped).forEach((ts) => {
          result.push({
            key: moment(ts * periodLength).toDate(),
            value: grouped[ts].reduce((total, item) => item.value + total, 0),
          });
        });

        return result;
      }

      chartData = groupByPeriodLength(
        chartData,
        intervalPeriodMap[this.computedInterval],
      );
      const { start, end } = dateRangeFromPeriod(
        this.computedInterval,
        moment(chartData[chartData.length - 1]?.key),
      );
      this.$emit('hasMax', maxBy(chartData, 'value')?.value);
      return {
        chart: {
          backgroundColor: null,
          borderWidth: 0,
          type: this.type ?? 'area',
          // margin: [0, -3, 0, -3],
          margin: 0,
          width: this.chartWidth,
          height: typeof this.height !== 'undefined' ? this.height : 20,
          style: {
            overflow: 'visible',
          },
          // small optimalization, saves 1-2 ms each sparkline
          skipClone: true,
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
          // startOnTick: true,
          // endOnTick: true,
          min: parseInt(start.format('x')),
          max: parseInt(end.format('x')),
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
          tickPositions: [0],
          min: 0,
          // max: this.max,
        },
        legend: {
          enabled: false,
        },
        tooltip: createTooltip({
          formatters: {
            Volume: formatCurrency,
          },
          options: {
            outside: true,
            hideDelay: 10,
          },
        }),

        plotOptions: {
          series: {
            connectNulls: true,
            marker: {
              enabled: false,
            },
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            // marker: {
            //   radius: 1,
            //   states: {
            //     hover: {
            //       radius: 2
            //     }
            //   }
            // },
            fillOpacity: 0.25,
          },
          column: {
            negativeColor: '#910000',
            borderWidth: 0,
          },
        },
        series: [
          {
            data: chartData.map((item) => [
              parseInt(moment(item.key).format('x')),
              item.value,
            ]),
            name: 'Volume',
          },
        ],
      };
    },
  },
});
</script>

<style>
.volume-scatter__wrap .highcharts-graph {
  stroke-width: 1px;
}
</style>
