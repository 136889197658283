<template>
  <div
    v-if="actions.length > 0"
    class="mb-2"
  >
    <ul
      class="flex flex-col text-white "
    >
      <li
        v-for="item in actions"
        :key="item.id"
      >
        <UiCard v-if="!actionsByType[item.type]">
          Invalid action
        </UiCard>

        <Accordion
          v-if="actionsByType[item.type]"
          :id="item.id"
          v-model="currentlyOpenAccordion"
          max-h="max-h-full"
          class="mb-6 shadow-lg action-accordion"
          :class="{'action-accordion--open': currentlyOpenAccordion === item.id, 'opacity-50': item.isDisabled}"
          :disabled="item.isDisabled"
          no-transition
          no-scroll
        >
          <template #name>
            <div class="flex relative items-center grip-wrap">
              <div

                class="w-8 text-xl"
                :class="{'text-indigo-500': !item.type.startsWith('custom-'), 'text-purple-500': item.type.startsWith('custom-')}"
              >
                <i
                  :class="actionsByType[item.type].icon"
                />
              </div>

              <div class="text-gray-300">
                {{ item.name || actionsByType[item.type].name }}
              </div>

              <div
                class="py-1 px-1 ml-2 text-xs font-medium leading-none bg-gray-700 rounded action-id"
                @click.stop="copyTextToClipboard(item.id)"
              >
                ID: <span class="font-mono font-normal">{{ item.id }}</span>
              </div>
            </div>
          </template>

          <template #actions>
            <div class="text-sm text-gray-300">
              <div
                v-tooltip="erroredTracesByActionId[item.id] ? `${erroredTracesByActionId[item.id].length} errors` : undefined"
                :class="{'text-yellow-500': !!erroredTracesByActionId[item.id]}"
              >
                {{ tracesByActionId[item.id]?.length || 0 }} {{ pluralize('calls', tracesByActionId[item.id]?.length || 0) }}
              </div>
            </div>
          </template>

          <div class="p-3">
            <div
              v-if="!tracesByActionId[item.id]"
              class="p-3 text-lg font-light text-center text-gray-400"
            >
              No traces for this action during this run
            </div>

            <AutomationActionTraces
              v-if="['repeat', 'group', 'doWhile'].includes(item.type)"
              :actions="item.config.actions"
              :traces="traces"
            />

            <ul v-else>
              <li
                v-for="trace in tracesByActionId[item.id]"
                :key="trace.id"
                class="px-3 pt-2 pb-1 mb-3 bg-gray-800 rounded-xl border-l-4"
                :class="{'border-green-500': trace.status === 'success', 'border-yellow-500': trace.status === 'running', 'border-red-500': trace.status === 'error'}"
              >
                <div
                  v-if="trace.actionError"
                  class="p-3 mb-2 text-sm bg-red-500 rounded-lg"
                >
                  {{ trace.actionError.message }}
                </div>

                <UiHiddenContent title="Inputs">
                  <ul class="text-sm">
                    <li
                      v-for="(value, key) in trace.actionInputs"
                      :key="key"
                    >
                      <span class="mr-1 font-bold">{{ key }}:</span>

                      <span class="text-gray-400">{{ value }}</span>
                    </li>
                  </ul>
                </UiHiddenContent>

                <UiHiddenContent title="Outputs">
                  <ul class="text-sm">
                    <li
                      v-for="(value, key) in trace.actionOutputs"
                      :key="key"
                    >
                      <span class="mr-1 font-bold">{{ key }}:</span>

                      <span class="text-gray-400">{{ value }}</span>
                    </li>
                  </ul>
                </UiHiddenContent>
              </li>
            </ul>
          </div>
        </Accordion>
      </li>
    </ul>
  </div>
</template>

<script setup>
import useAutomations from '@/composition/automation/use-automations';

import {
  computed, toRefs, ref,
} from 'vue';
import { keyBy, groupBy } from 'lodash-es';
import { copyTextToClipboard } from '@/utils/clipboard';

import pluralize from 'pluralize';
import Accordion from '../Accordion.vue';
import UiCard from '../ui/UiCard.vue';
import UiHiddenContent from '../ui/UiHiddenContent.vue';

const props = defineProps({
  actions: {
    type: Array,
    required: true,
  },
  traces: {
    type: Array,
    required: true,
  },
});

const {
  actions,
  traces,
} = toRefs(props);

const currentlyOpenAccordion = ref(null);

const { automationActions } = useAutomations();

const actionsByType = computed(() => keyBy(automationActions.value, 'type'));

const tracesByActionId = computed(() => groupBy(traces.value, 'actionId'));
const erroredTracesByActionId = computed(() => groupBy(traces.value.filter((item) => item.status === 'error'), 'actionId'));
</script>
