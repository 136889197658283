<template>
  <UiCard>
    <UiCardBody>
      <div class="flex justify-between items-center pb-2 border-b border-gray-700">
        <div>
          <div class="flex gap-3 items-center text-3xl font-medium">
            v{{ release.version }}

            <UiBadge
              size="md"
              :variant="release.status === 'draft' ? 'yellow' : 'green'"
            >
              <span class="text-lg capitalize">{{ release.status }}</span>
            </UiBadge>
          </div>

          <div class="flex gap-2 items-center mt-3 text-sm text-gray-400">
            <div>
              {{ moment(release.createdAt).fromNow() }}
            </div>

            <div v-if="release.reviewStatus === 'approved' && release.status === 'draft'">
              <UiBadge variant="indigo">
                Approved
              </UiBadge>
            </div>

            <div v-if="release.reviewStatus === 'rejected' && release.status === 'draft'">
              <UiBadge variant="red">
                Rejected
              </UiBadge>
            </div>
          </div>
        </div>

        <div v-if="release.status === 'draft'">
          <UiButton
            v-if="release.reviewStatus === 'approved'"
            :is-loading="isSubmittingForReview"
            @click="publishActionRelease(release)"
          >
            Publish
          </UiButton>

          <UiButton
            v-else
            :disabled="release.reviewStatus"
            :is-loading="isSubmittingForReview"
            @click="submitActionReleaseForReview(release)"
          >
            <span v-if="!release.reviewStatus">Submit for review</span>

            <span v-else-if="release.reviewStatus === 'review'">Under review</span>

            <span v-else>{{ release.reviewStatus }}</span>
          </UiButton>
        </div>
      </div>

      <div class="mt-2">
        {{ release.description }}
      </div>

      <div class="mt-3">
        <UiCodeEditor
          :model-value="release.script"
          readonly
        />
      </div>

      <div class="grid grid-cols-2 gap-3 mt-2">
        <UiInputGroup
          label="Inputs"
          class="mb-0"
        >
          <ContextVariablesEditor
            :model-value="release.inputs"
            readonly
          />
        </UiInputGroup>

        <UiInputGroup
          label="Outputs"
          class="mb-0"
        >
          <ContextVariablesEditor
            :model-value="release.outputs"
            readonly
          />
        </UiInputGroup>
      </div>
    </UiCardBody>
  </UiCard>
</template>

<script setup>
import {
  toRefs, ref, inject, computed,
} from 'vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiBadge from '@/components/ui/UiBadge.vue';
import UiCodeEditor from '@/components/ui/UiCodeEditor.vue';
import ContextVariablesEditor from '@/components/automation/ContextVariablesEditor.vue';
import UiInputGroup from '@/components/ui/UiInputGroup.vue';
import UiCardBody from '@/components/ui/UiCardBody.vue';
import moment from 'moment';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { GET_ACTION_QUERY } from '@/composition/automation/use-automation-actions';
import UiCard from '../ui/UiCard.vue';

const props = defineProps(['release', '']);
const { release } = toRefs(props);
const confirm = inject('confirm');

const { mutate: submitActionReleaseForReviewMutation } = useMutation(gql`
mutation SubmitActionReleaseForReview($actionReleaseId: Int!) {
  submitActionReleaseForReview(actionReleaseId: $actionReleaseId) {
    id
    status
    reviewStatus
  }
}
`, () => ({
  awaitRefetchQueries: true,
  refetchQueries: [
    {
      query: GET_ACTION_QUERY,
      variables: {
        id: Number(release.value.actionId),
      },
    },
  ],
}));

const { mutate: publishActionReleaseMutation } = useMutation(gql`
mutation PublishActionRelease($actionReleaseId: Int!) {
  publishActionRelease(actionReleaseId: $actionReleaseId) {
    id
    status
    reviewStatus
  }
}
`, () => ({
  awaitRefetchQueries: true,
  refetchQueries: [
    {
      query: GET_ACTION_QUERY,
      variables: {
        id: Number(release.value.actionId),
      },
    },
  ],
}));

const isSubmittingForReview = ref(false);
const submitActionReleaseForReview = async () => {
  await confirm('Are you sure you want to submit this release for review? Once submitted, you will not be able to make any changes to it until it is approved or rejected.', {
    title: 'Submit for review',
    okText: 'Submit',
  });
  isSubmittingForReview.value = true;
  return submitActionReleaseForReviewMutation({
    actionReleaseId: release.value.id,
  }).finally(() => {
    isSubmittingForReview.value = false;
  });
};

const publishActionRelease = async () => {
  await confirm('Are you sure you want to publish this release? Once published, you will not be able to make any changes to it.', {
    title: 'Publish release',
    okText: 'Publish',
  });
  isSubmittingForReview.value = true;
  return publishActionReleaseMutation({
    actionReleaseId: release.value.id,
  }).finally(() => {
    isSubmittingForReview.value = false;
  });
};

const isReadOnly = computed(() => release.value.status !== 'draft' || release.value.reviewStatus);
</script>
