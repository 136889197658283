import workerImitator from '@/utils/worker-imitator';
import { getDataForChart } from './get-data-for-chart';

function initWorker() {
  let getDataForChartWorker = null;

  if (window.Worker) {
    getDataForChartWorker = new Worker(
      new URL('./get-data-for-chart.worker.js', import.meta.url),
      { type: 'module' },
    );
  } else {
    getDataForChartWorker = workerImitator(getDataForChart);
  }

  return getDataForChartWorker;
}

const getDataForChartWorker = initWorker();

export {
  getDataForChartWorker,
};
