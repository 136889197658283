<template>
  <div
    :class="{'shadow border-b border-gray-200 dark:border-gray-1000': noShadow === false}"
  >
    <div :class="isLoading ? 'overflow-hidden' : 'overflow-x-auto overflow-y-hidden xl:overflow-x-visible xl:overflow-y-visible'">
      <table
        ref="tableRef"
        class="min-w-full bg-white dark:bg-gray-850 divide-y divide-gray-200 dark:divide-black table-fixed"
        :class="tableClass"
      >
        <thead
          v-if="noHead === false"
          class="bg-gray-50 dark:bg-gray-800 rounded-t-sm "
          :class="[isLoading ? '' : (noSticky === false ? 'xl:sticky z-20' : ''), stickyClass]"
        >
          <tr>
            <th
              v-for="(item, index) in displayedFields"
              :key="index"
              scope="col"
              class="text-sm font-medium text-left text-gray-500 dark:text-white capitalize whitespace-nowrap border-b border-gray-800 opacity-90"
              :class="[{'cursor-pointer': sortable.indexOf(item.key) !== -1 }, item.colClass, slim === false ? 'py-3 px-6' : 'py-1 px-2']"
              :style="item.width ? `width: ${item.width}%` : ''"
              @click="toggleSort(item.key)"
            >
              <slot
                v-if="item.key !== 'actions'"
                :name="`head(${makeSlotName(item.key)})`"
              >
                {{ humanize(item.name) }}
              </slot>

              <div
                v-if="sortable.indexOf(item.key) !== -1"
                class="inline ml-2"
              >
                <i :class="[sortBy === item.key ? ('fas ' + (sortDesc ? 'fa-sort-down' : 'fa-sort-up')) : 'fal fa-sort']" />
              </div>
            </th>
          </tr>
        </thead>

        <tbody
          v-if="!isLoading"
          :class="tbodyClass"
        >
          <tr
            v-for="(item, itemIndex) in displayedItems"
            :key="getKey(itemIndex, item)"
            class="dark:bg-gray-850 dark:hover:bg-gray-800 dark:hover:bg-opacity-60 transition-colors"
            :data-key="itemIndex"
            :class="{'dark:bg-opacity-100': itemIndex % 2 === 0, 'dark:bg-opacity-70': itemIndex % 2 !== 0, 'pointer-events-none opacity-50': item.id === '__TEMP_ID__' }"
            @click="$emit('item-click', item)"
          >
            <td
              v-for="(field, fieldIndex) in displayedFields"
              :key="`${getKey(itemIndex, item)}-${field.key}`"
              class="text-gray-900 dark:text-gray-100 whitespace-nowrap dark:border-gray-800"

              :class="[{'border-b': itemIndex < displayedItems.length - 1}, slim === false ? 'py-3 px-6' : 'py-1 px-2']"
            >
              <div
                v-if="field.key === 'actions'"
                class="flex justify-end"
              >
                <slot
                  :name="`cell(${makeSlotName(field.key)})`"
                  v-bind="{item, itemIndex, field, fieldIndex}"
                >
                  {{ item[field.key] }}
                </slot>
              </div>

              <slot
                v-else
                :name="`cell(${makeSlotName(field.key)})`"
                v-bind="{item, itemIndex, field, fieldIndex}"
              >
                {{ item[field.key] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        v-if="isLoading || showSkeleton"
        v-set-column-widths
        class="min-w-full bg-white dark:bg-gray-850 border-t dark:border-gray-800 divide-y divide-gray-200 dark:divide-black table-fixed"
        :class="tableClass"
      >
        <tbody>
          <tr
            v-for="row in skeletonsRows"
            :key="row"
          >
            <td
              v-for="field in displayedFields.length"
              :key="field"
              class="py-4 px-6 text-gray-900 whitespace-nowrap border-b dark:border-gray-800"
            >
              <div>
                <div class="w-full h-5 bg-gray-800 rounded-lg animate-pulse" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="!isLoading && !showSkeleton && items.length === 0"
      >
        <slot name="empty" />
      </div>

      <slot name="after" />
    </div>

    <UiCardBody
      v-if="isPaginated && !isLoading && items.length > perPage && items.length > ((currentPage + 1) * perPage)"
      class="border-t dark:border-black"
    >
      <div>
        <div class="flex justify-center items-center">
          <UiButton
            variant="white"
            size="xs"
            @click.prevent="currentPage = currentPage + 1"
          >
            Load More
          </UiButton>
        </div>
      </div>
    </UiCardBody>

    <slot />
  </div>
</template>

<script>
import { get } from 'lodash-es';
import { humanize } from '@/utils/filters';
import UiCardBody from '@/components/ui/UiCardBody.vue';
import UiButton from '@/components/ui/UiButton.vue';

export default {
  name: 'UiTable',
  components: {
    UiButton, UiCardBody,
  },
  directives: {
    setColumnWidths: {
      updated(el) {
        const heads = el.querySelectorAll('tr:first-child td');
        el.style.width = `${el.previousSibling.getBoundingClientRect().width}px`;
        el.previousSibling.querySelectorAll('th').forEach((item, index) => {
          const { width } = item.getBoundingClientRect();
          heads[index].style.width = `${width}px`;
        });
      },
      mounted(el) {
        const heads = el.querySelectorAll('tr:first-child td');
        el.style.width = `${el.previousSibling.getBoundingClientRect().width}px`;
        el.previousSibling.querySelectorAll('th').forEach((item, index) => {
          const { width } = item.getBoundingClientRect();
          heads[index].style.width = `${width}px`;
        });
      },
    },
  },
  props: {
    tableClass: null,
    isLoading: {
      type: Boolean,
      default: false,
    },
    showSkeleton: {
      type: Boolean,
      default: false,
    },
    tbodyClass: {
      type: String,
      default: '',
    },
    sortable: {
      type: Array,
      default() {
        return [];
      },
    },
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    noSticky: {
      type: Boolean,
      default: false,
    },
    stickyClass: {
      type: String,
      default: 'top-24',
    },
    noHead: {
      type: Boolean,
      default: false,
    },
    sortLocal: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
    perPage: null,
    skeletonsRows: {
      type: Number,
      default: 10,
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:sortBy', 'update:sortDesc', 'item-click'],

  setup(props) {
    return {
      humanize,
    };
  },
  data() {
    return {
      currentPage: 0,
    };
  },
  computed: {
    getKey() {
      return (index, item) => {
        if (item.id) {
          return item.id;
        }
        return index;
      };
    },
    isPaginated() {
      return typeof this.perPage !== 'undefined' && this.perPage !== null;
    },
    pages() {
      if (!this.isPaginated) {
        return [];
      }

      return (new Array(Math.ceil(this.items.length / this.perPage))).fill(null).map((_, index) => index);
    },
    displayedItems() {
      let items = [...this.items];
      if (this.sortLocal !== false) {
        items = items.sort((a, b) => {
          const aValue = get(a, this.sortBy, 0);
          const bValue = get(b, this.sortBy, 0);

          return aValue - bValue;
        });

        if (this.sortDesc === true) {
          items = items.reverse();
        }
      }
      if (!this.isPaginated) {
        return items;
      }
      return items.slice(0, this.perPage * (this.currentPage + 1));
    },
    displayedFields() {
      // No fields or items
      if (this.fields.length === 0 && this.items.length === 0) {
        return [];
      }

      // No fields passed
      if (this.fields.length === 0 && this.items.length > 0) {
        return Object.keys(this.items[0]).map((item) => ({
          key: item,
          name: item,
        }));
      }

      if (this.fields.length > 0) {
        return this.fields.map((item) => {
          if (typeof item === 'object') {
            if (typeof item.name === 'undefined') {
              item.name = item.key;
            }

            return item;
          }

          return {
            key: item,
            name: item,
          };
        });
      }

      return this.fields;
    },
  },

  methods: {
    toggleSort(fieldKey) {
      if (this.sortable.indexOf(fieldKey) === -1) {
        return;
      }

      if (this.sortBy === fieldKey) {
        this.$emit('update:sortDesc', !this.sortDesc);
      }

      this.$emit('update:sortBy', fieldKey);
    },
    makeSlotName(name) {
      return name.replace('.', '-');
    },
  },
};
</script>

<style scoped>

</style>
