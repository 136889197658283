<script>
import { ref } from 'vue';

const resolve = ref(null);
export function useReloadNotification() {
  function setResolve(r) {
    resolve.value = r;
  }

  function callResolve() {
    return resolve.value(true);
  }

  return {
    setResolve,
    callResolve,
    resolve,
  };
}
</script>

<script setup>
const { callResolve } = useReloadNotification();
</script>

<template>
  <Transition name="fade">
    <div
      v-if="resolve"
      class="bg-indigo-500 cursor-pointer"
      @click="callResolve"
    >
      <p class="text-xs text-center">
        A new version of Compass is available! Click here to refresh this tab.
      </p>
    </div>
  </Transition>
</template>

<style>
/* we will explain what these classes do next! */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
