<template>
  <span
    class="inline-flex items-center py-0.5 px-2.5 text-xs font-medium rounded-lg border border-white border-opacity-20"
    :class="[` text-${variant}-500  dark:text-${variant}-500`]"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'UiBadge',
  props: {
    variant: {
      type: String,
      default: 'blue',
    },
  },
};
</script>
