<template>
  <div class="mx-auto max-w-screen-2xl">
    <UiCard class="py-6 mb-6">
      <div
        class="
          md:grid-cols-2
          lg:grid-cols-4
          gap-y-8
          dark:divide-gray-700
          divide-x divide-gray-200
          grid grid-cols-2
        "
      >
        <UiGridItem class="px-6">
          <MetricWidget
            :value="uniqueWalletsCount"
            :formatter="formatNumber"
            :formatter-args="[0, false]"
            title="Unique Wallets"
            :is-loading="isHoldersLoading"
          />
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :value="bluechipHoldersCount / uniqueWalletsCount"
            :formatter="formatPercent"
            title="Bluechip Holders"
            :is-loading="isHoldersLoading"
          />
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :value="numberOfWalletsWithSingleToken / uniqueWalletsCount"
            :formatter="formatPercent"
            :formatter-args="[0, false]"
            title="Wallets Holding Single Item"
            :is-loading="isHoldersLoading"
          />
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            title="Average Balance Per Wallet"
            :is-loading="isHoldersLoading"
          >
            <template #value>
              <span class="font-mono">
                {{ formatNumber(averageTokensPerWallet) }}
              </span>
            </template>
          </MetricWidget>
        </UiGridItem>
      </div>
    </UiCard>

    <div class="grid md:grid-cols-2 gap-6 mb-6">
      <UiCard title="Holders over time">
        <template #title-right>
          <PeriodSelector
            v-model="chartConfig.holdersOverTime.period"
            :periods="['1h', '1d']"
          />
        </template>

        <UiCardBody>
          <Chart
            :is-loading="isHolderOvertimeLoading"
            v-bind="chartProps('holdersOverTime')"
          />
        </UiCardBody>
      </UiCard>

      <UiCard title="Average balance over time">
        <template #title-right>
          <PeriodSelector
            v-model="chartConfig.avgBalanceOverTime.period"
            :periods="['1h', '1d']"
          />
        </template>

        <UiCardBody>
          <Chart
            :is-loading="isAvgBalanceLoading"
            v-bind="chartProps('avgBalanceOverTime')"
          />
        </UiCardBody>
      </UiCard>
    </div>

    <UiCard>
      <UiCardBody>
        <UiButton @click="showCreateWatchlist">
          <div class="flex gap-2 items-center">
            <i class="fas fa-plus" />
            Create Group
          </div>
        </UiButton>
      </UiCardBody>

      <UiTable
        :per-page="50"
        :items="wallets"
        :is-loading="isHoldersLoading"
        no-sticky
        :fields="[
          'wallet',
          'balance',
          'profitAndLoss',
          'change6h',
          'change1d',
          'change3d',
          'change7d',
          'actions',
        ]"
      >
        <template #head(change6h)>
          Change 6h
        </template>

        <template #head(change1d)>
          Change 1d
        </template>

        <template #head(change3d)>
          Change 3d
        </template>

        <template #head(change7d)>
          Change 7d
        </template>

        <template #cell(profitAndLoss)="{ item, itemIndex }">
          <PremiumPlaceholder :disabled="itemIndex <= 2">
            <div
              class="text-sm"
              :class="{'text-red-500': item.unrealizedProfitAndLoss < 0, 'text-green-500': item.unrealizedProfitAndLoss > 0}"
            >
              {{ formatCurrency(item.unrealizedProfitAndLoss) }} Unrealized
            </div>

            <div class="text-xs text-gray-400">
              {{ formatCurrency(item.profitAndLoss) }} Realized
            </div>

            <template #placeholder-content>
              <div>
                <PremiumIcon size="xs" />
              </div>
            </template>
          </PremiumPlaceholder>
        </template>

        <template #cell(change6h)="{ item, itemIndex }">
          <PremiumPlaceholder :disabled="itemIndex <= 2">
            <span
              v-if="item.change6h < 0"
              class="mr-1"
              :class="changeClasses(item.change6h)"
            ><i class="fas fa-caret-down" /></span>

            <span
              v-if="item.change6h > 0"
              class="mr-1"
              :class="changeClasses(item.change6h)"
            ><i class="fas fa-caret-up" /></span>

            <span
              v-if="item.change6h"
              :class="changeClasses(item.change6h)"
            >{{
              Math.abs(item.change6h)
            }}</span>

            <span
              v-if="!item.change6h"
              class="text-gray-300 dark:text-gray-700"
            >--</span>

            <template #placeholder-content>
              <div>
                <PremiumIcon size="xs" />
              </div>
            </template>
          </PremiumPlaceholder>
        </template>

        <template #cell(change1d)="{ item, itemIndex }">
          <PremiumPlaceholder :disabled="itemIndex <= 2">
            <span
              v-if="item.change1d < 0"
              class="mr-1"
              :class="changeClasses(item.change1d)"
            ><i class="fas fa-caret-down" /></span>

            <span
              v-if="item.change1d > 0"
              class="mr-1"
              :class="changeClasses(item.change1d)"
            ><i class="fas fa-caret-up" /></span>

            <span
              v-if="item.change1d"
              :class="changeClasses(item.change1d)"
            >{{
              Math.abs(item.change1d)
            }}</span>

            <span
              v-if="!item.change1d"
              class="text-gray-300 dark:text-gray-700"
            >--</span>

            <template #placeholder-content>
              <div>
                <PremiumIcon size="xs" />
              </div>
            </template>
          </PremiumPlaceholder>
        </template>

        <template #cell(change3d)="{ item, itemIndex }">
          <PremiumPlaceholder :disabled="itemIndex <= 2">
            <span
              v-if="item.change3d < 0"
              class="mr-1"
              :class="changeClasses(item.change3d)"
            ><i class="fas fa-caret-down" /></span>

            <span
              v-if="item.change3d > 0"
              class="mr-1"
              :class="changeClasses(item.change3d)"
            ><i class="fas fa-caret-up" /></span>

            <span
              v-if="item.change3d"
              :class="changeClasses(item.change3d)"
            >{{
              Math.abs(item.change3d)
            }}</span>

            <span
              v-if="!item.change3d"
              class="text-gray-300 dark:text-gray-700"
            >--</span>

            <template #placeholder-content>
              <div>
                <PremiumIcon size="xs" />
              </div>
            </template>
          </PremiumPlaceholder>
        </template>

        <template #cell(change7d)="{ item, itemIndex }">
          <PremiumPlaceholder :disabled="itemIndex <= 2">
            <span
              v-if="item.change7d < 0"
              class="mr-1"
              :class="changeClasses(item.change7d)"
            ><i class="fas fa-caret-down" /></span>

            <span
              v-if="item.change7d > 0"
              class="mr-1"
              :class="changeClasses(item.change7d)"
            ><i class="fas fa-caret-up" /></span>

            <span
              v-if="item.change7d"
              :class="changeClasses(item.change7d)"
            >{{
              Math.abs(item.change7d)
            }}</span>

            <span
              v-if="!item.change7d"
              class="text-gray-300 dark:text-gray-700"
            >--</span>

            <template #placeholder-content>
              <div>
                <PremiumIcon size="xs" />
              </div>
            </template>
          </PremiumPlaceholder>
        </template>

        <template #cell(balance)="{ item, itemIndex }">
          <PremiumPlaceholder :disabled="itemIndex <= 2">
            {{ formatNumber(item.balance, 0, false) }} ({{
              formatPercent(item.balance / collection.totalSupply)
            }})
            <UiProgressBar
              :max="largest"
              :value="item.balance"
              class="mt-2"
            />

            <template #placeholder-content>
              <div>
                <PremiumIcon size="xs" />
              </div>
            </template>
          </PremiumPlaceholder>
        </template>

        <template #cell(wallet)="{ item }">
          <WalletProfile :wallet="item.wallet" />
        </template>
        <!--
 <template v-slot:cell(balanceOverTime)="{ item }">
        <volume-scatter
          :chart-data="item.balanceOverTime"
          :valueFilter="formatNumber"
        />
      </template>
-->
        <template #cell(actions)="{ item }">
          <div class="flex gap-3">
            <UiButton
              size="xs"
              variant="white"
              :to="{
                name: routesNames.wallet.flips,
                params: { address: item.wallet.id },
                query: {
                  filters: encodeFilters([
                    {
                      key: 'collectionId',
                      type: FILTER_TYPES.COLLECTION,
                      value: {
                        comparator: 'in',
                        value: [collection.id],
                      }
                    },
                  ]),
                },
              }"
              tag="router-link"
            >
              Flips
            </UiButton>

            <UiButton
              size="xs"
              variant="white"
              :to="{
                name: routesNames.wallet.items,
                params: { address: item.wallet.id },
                query: {
                  filters: encodeFilters([
                    {
                      key: 'collectionId',
                      type: FILTER_TYPES.COLLECTION,
                      value: {
                        comparator: 'in',
                        value: [collection.id],
                      }
                    },
                  ]),
                },
              }"
              tag="router-link"
            >
              Tokens
            </UiButton>
          </div>
        </template>
      </UiTable>
    </UiCard>
  </div>

  <CreateWatchlistFromHoldersModal
    ref="createWatchlistFromHoldersModal"
    :holders="wallets"
    :collection="collection"
  />
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
  ref,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { maxBy } from 'lodash-es';
import moment from 'moment';
import collectionHoldersQuery from '@/graphql/collection-holders/queries/collectionHolders.query.gql';
import collectionHoldersOverTimeQuery from '@/graphql/chart-data/queries/collectionHoldersOverTime.query.gql';
import collectionAvgBalanceOverTimeQuery from '@/graphql/chart-data/queries/collectionAvgBalanceOverTime.query.gql';

import { formatCurrency, formatNumber, formatPercent } from '@/utils/filters';
import UiTable from '@/components/ui/UiTable.vue';
import WalletProfile from '@/components/wallets/WalletProfile.vue';
import UiProgressBar from '@/components/UiProgressBar.vue';
import MetricWidget from '@/components/MetricWidget.vue';
// import VolumeScatter from "@/components/VolumeScatter";
import { useRoute } from 'vue-router';
import { useCollection } from '@/composition/collections';
import routesNames from '@/router/routesNames';
import { encodeFilters } from '@/composition/filters/useFiltersQuerySync';
import { FILTER_TYPES } from '@/constants/filters';
import UiButton from '../ui/UiButton.vue';
import decodesFilterQuery from '../../mixins/decodesFilterQuery';
import Chart, { ChartColorMap } from '../Chart.vue';
import UiCard from '../ui/UiCard.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import PremiumPlaceholder from '../PremiumPlaceholder.vue';
import PeriodSelector from '../PeriodSelector.vue';
import PremiumIcon from '../PremiumIcon.vue';
import UiGridItem from '../ui/UiGridItem.vue';
import UiModal from '../ui/UiModal.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiInput from '../ui/UiInput.vue';
import CreateWatchlistFromHoldersModal from './CreateWatchlistFromHoldersModal.vue';

export default defineComponent({
  name: 'CollectionHolders',

  components: {
    MetricWidget,
    UiCardBody,
    UiCard,
    UiProgressBar,
    WalletProfile,
    UiTable,
    // VolumeScatter,
    UiButton,
    Chart,
    PremiumPlaceholder,
    PeriodSelector,
    PremiumIcon,
    UiGridItem,
    CreateWatchlistFromHoldersModal,
  },

  mixins: [decodesFilterQuery],

  setup() {
    const route = useRoute();
    const { contractAddress } = route.params;
    const { collection } = useCollection({ contractAddress });
    const chartConfig = reactive({
      holdersOverTime: {
        period: '1d',
        name: 'Holders',
      },
      avgBalanceOverTime: {
        period: '1d',
        name: 'Avg balance',
        type: 'column',
      },
    });

    const {
      result: collectionHoldersResult,
      loading: isHoldersLoading,
    } = useQuery(collectionHoldersQuery, () => ({ address: contractAddress }));
    const holders = computed(() => collectionHoldersResult.value?.collectionHolders);

    const {
      result: holdersOverTimeResult,
      loading: isHolderOvertimeLoading,
    } = useQuery(
      collectionHoldersOverTimeQuery,
      () => ({
        address: contractAddress,
        period: chartConfig.holdersOverTime.period,
      }),
    );
    const holdersOverTime = computed(() => holdersOverTimeResult.value?.holdersOverTime);

    const {
      result: avgBalanceOverTimeResult,
      loading: isAvgBalanceLoading,
    } = useQuery(
      collectionAvgBalanceOverTimeQuery,
      () => ({
        address: contractAddress,
        period: chartConfig.avgBalanceOverTime.period,
      }),
    );
    const avgBalanceOverTime = computed(() => avgBalanceOverTimeResult.value?.avgBalanceOverTime);
    const createWatchlistFromHoldersModal = ref(null);
    return {
      collection,
      holders,
      holdersOverTime,
      avgBalanceOverTime,
      isHoldersLoading,
      isHolderOvertimeLoading,
      isAvgBalanceLoading,
      chartConfig,
      formatNumber,
      formatPercent,
      formatCurrency,
      routesNames,
      createWatchlistFromHoldersModal,
      encodeFilters,
      FILTER_TYPES,
    };
  },

  computed: {
    largest() {
      if (!this.holders) {
        return null;
      }

      return maxBy(this.wallets, 'balance')?.balance;
    },
    wallets() {
      return this.holders?.wallets || [];
    },
    uniqueWalletsCount() {
      return this.holders?.count;
    },
    averageTokensPerWallet() {
      return this.holders?.avgBalance;
    },
    numberOfWalletsWithSingleToken() {
      return this.holders?.singleTokenWalletCount;
    },
    bluechipHoldersCount() {
      return this.holders?.bluechipHoldersCount;
    },
    changeClasses() {
      return (value) => ({
        'text-red-600': value < 0,
        'text-green-600': value > 0,
      });
    },
    chartProps() {
      return (type) => {
        if (!this[type]) {
          return {
            options: {},
          };
        }
        return {
          options: {
            chart: {
              type: this.chartConfig[type].type,
            },
            xAxis: {
              type: 'datetime',
            },
            tooltip: {
              animation: false,
              shared: true,
              useHTML: true,
              className: 'highcharts-tooltip-wrap-ext',
              headerFormat: `<div class="pointer-events-none ml-5 dark:bg-gray-900 tooltip top-0 left-0 z-20 w-48 p-3 rounded shadow-lg text-sm bg-white">
                                            <div class="tabular-nums text-xs text-gray-600 dark:text-gray-400">{point.key}</div>`,
              footerFormat: '</div>',
              pointFormatter() {
                return `<div class="flex justify-between">
                                        <div class="text-gray-600 dark:text-gray-400 font-medium">${
  this.series.name
}</div>
                                        <div class="text-${
  ChartColorMap[this.colorIndex]
} font-medium">${formatNumber(this.y)}</div>
                                    </div>`;
              },
            },
            series: [
              {
                name: this.chartConfig[type].name,
                data: this[type].map((item) => [
                  moment(item.key).toDate().getTime(),
                  item.value,
                ]),
              },
            ],
          },
        };
      };
    },
  },

  methods: {
    showCreateWatchlist() {
      this.createWatchlistFromHoldersModal.show();
    },
  },
});
</script>

<style scoped>
</style>
