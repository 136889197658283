<template>
  <div
    :class="{
      'shadow rounded-2xl': true,
      'dark:bg-gray-850 dark:text-white bg-white': !transparent,
      'overflow-hidden': isOverflowAuto === false,
    }"
  >
    <div>
      <div
        v-if="title || $slots.title"
        class="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-2 sm:space-y-0"
        :class="{'sm:p-6 py-5 px-4': !slim, 'sm:p-3 py-2 px-3': slim}"
      >
        <div class="text-xl font-light dark:text-gray-100">
          <slot
            v-if="isLoading === false"
            name="title"
          >
            {{ title }}
          </slot>

          <div
            v-else
            class="py-1 w-44 h-5 bg-gray-750 rounded-lg animate-pulse"
          />
        </div>

        <slot name="title-right" />
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiCard',

  props: {
    title: {
      type: String,
      default: '',
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    isOverflowAuto: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>
