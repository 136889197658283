<template>
  <div class="pb-8">
    <AppHeader
      :links-left="headerLinksLeft"
      :links-right="headerLinksRight"
    />

    <main>
      <slot />
    </main>

    <RefetchLoading />

    <BottomBar />
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { defineComponent, computed } from 'vue';
import routesNames from '@/router/routesNames';
import AppHeader from '@/components/AppHeader.vue';
import BottomBar from './BottomBar.vue';
import RefetchLoading from './RefetchLoading.vue';

export default defineComponent({
  name: 'AppShell2',

  components: {
    AppHeader,
    BottomBar,
    RefetchLoading,
  },

  setup() {
    const router = useRouter();
    function generateCollectionTablesLinks() {
      const collectionRoute = router.getRoutes().find((route) => route.name === routesNames.collections);
      const base = {
        exact: true,
        title: 'Collections',
        children: [],
      };
      for (const child of collectionRoute.children) {
        if (child.meta?.fastNavigation) {
          base.children.push({
            title: child.meta.title,
            to: { name: child.name },
          });
        }
      }
      return base;
    }

    return {
      headerLinksLeft: [
        generateCollectionTablesLinks(),
        {
          title: 'Wallets',
          to: { name: routesNames.feed },
        },

        {
          title: 'Pulse',
          to: { name: routesNames.pulse },
        },
      ],
      headerLinksRight: computed(() => [
        {
          title: 'Portfolio',
          to: { name: routesNames.portfolio.show },
        },
        {
          title: 'Automations',
          to: { name: routesNames.automation },
        },
        {
          icon: 'fad fa-bell',
          to: { name: routesNames.alerts },
        },
      ]),
    };
  },
});
</script>

<style scoped>

</style>
