<template>
  <textarea
    v-bind="mutatedAttrs"
    ref="inputEl"
    v-model="inputValue"
    :type="type"
    class="
      block
      w-full
      sm:text-sm
      placeholder-gray-400
      text-gray-100
      dark:bg-gray-700 dark:bg-opacity-70 border dark:border-gray-700 outline-none dark:active:bg-gray-750 dark:hover:bg-opacity-90 dark:focus:bg-opacity-100
      rounded-xl
  ring-0 focus:ring-0
      shadow-sm
      transition-colors
      dark:focus:border-indigo-500 focus:outline-none
    "
    :class="{
      'cursor-not-allowed': mutatedAttrs.disabled,
      'input-without-arrows': withoutArrows
    }"
  />
</template>

<script>
import {
  defineComponent,
  useAttrs,
  computed,
  toRefs,
} from 'vue';

export default defineComponent({
  name: 'UiTextArea',

  props: {
    modelValue: {
      type: [Number, String],
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    withoutArrows: {
      type: Boolean,
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const attrs = useAttrs();

    const mutatedAttrs = computed(() => {
      const $attrs = {
        ...attrs,
      };

      if ($attrs.onInput) {
        const onInput = (event) => {
          attrs.onInput(event.target.value);
        };
        $attrs.onInput = onInput;
      }

      return $attrs;
    });

    const inputValue = computed({
      get() {
        return modelValue.value;
      },

      set(value) {
        emit('update:modelValue', value);
      },
    });

    return {
      inputValue,
      mutatedAttrs,
    };
  },

  mounted() {
    if (this.autofocus !== false) {
      this.$refs.inputEl.focus();
    }
  },
});
</script>

<style lang="scss" scoped>
.input-without-arrows::-webkit-outer-spin-button,
.input-without-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-without-arrows {
  -moz-appearance: textfield;
}
</style>
