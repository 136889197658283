<template>
  <UiDropdown :right="right">
    <template #toggle="toggleProps">
      <slot
        name="toggle"
        v-bind="toggleProps"
      />
    </template>

    <template #body="{ forceClose }">
      <UiDropdownItem
        v-for="(item, index) in options"
        :key="index"
        @click="() => {
          setInputValue(item.value);
          forceClose();
        }"
      >
        {{ item.text }}
      </UiDropdownItem>
    </template>
  </UiDropdown>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
} from 'vue';

import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';

export default defineComponent({
  name: 'UiSelect',

  components: { UiDropdown, UiDropdownItem },

  props: {
    modelValue: null,
    size: {
      type: String,
      default: 'md',
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    right: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const inputValue = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    function setInputValue(val) {
      inputValue.value = val;
    }

    return {
      setInputValue,
      inputValue,
    };
  },
});
</script>

<style scoped>
</style>
