<template>
  <div class="flex justify-end py-3 px-4 border-t dark:border-gray-800">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiModalFooter',
};
</script>

<style scoped>

</style>
