<template>
  <div>
    <UiInputRadio
      v-model="type"
      :options="radioOptions"
      class="mb-3"
    />

    <UiInputGroup label="Hours">
      <UiInput
        v-model="localValue"
        v-autofocus
        placeholder="24"
      />
    </UiInputGroup>

    <UiButton
      v-if="allowVariables === true"
      size="xs"
      variant="white"
      @click="selectValueVariable"
    >
      <i class="far fa-brackets-curly" />
    </UiButton>
  </div>
</template>

<script setup>

import {
  toRefs, ref, inject, watch,
} from 'vue';

import UiButton from '../ui/UiButton.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiInputRadio from '../ui/UiInputRadio.vue';

const props = defineProps(['modelValue', 'allowVariables', 'actionId']);
const { modelValue, allowVariables, actionId } = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const validateNumber = (value) => allowVariables.value || value === 0 || (value && String(value).length > 0 && !Number.isNaN(parseFloat(value)));

const type = ref(modelValue.value?.comparator || 'eq');
const localValue = ref(typeof modelValue.value?.value === 'number' ? modelValue.value.value / 60 / 60 : null);

const selectVariable = inject('selectVariable');

watch(modelValue, (v) => {
  type.value = v.comparator;
  localValue.value = (v.value || 0) / 60 / 60;
}, { deep: true });

const radioOptions = [
  {
    text: 'Equal',
    value: 'eq',
  },
  {
    text: 'Not Equal',
    value: 'neq',
  },
  {
    text: 'Greater Than',
    value: 'gt',
  },
  {
    text: 'Less Than',
    value: 'lt',
  },
];

watch([type, localValue], ([t, v]) => {
  if (allowVariables.value && v && v.startsWith('{{') && v.endsWith('}}')) {
    emit('update:modelValue', { comparator: t, value: v });
    return;
  }
  emit('update:modelValue', { comparator: t, value: validateNumber(v) ? Number(v * 60 * 60) : null });
});

const selectValueVariable = () => {
  selectVariable({
    type: 'Number',
  }, actionId.value).then((variable) => {
    localValue.value = `{{${variable.id}}}`;
  });
};
</script>
