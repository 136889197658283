<template>
  <UiModalHeader
    title="Create Alert"
    @dismiss="$emit('dismiss')"
  />

  <div class="p-4">
    <div v-if="model.type !== null">
      <div
        v-if="!isPremium && model.type !== 'FEED_ACTIVITY'"
        class="py-2 px-3 mb-4 text-yellow-900 bg-yellow-500 rounded-lg"
      >
        <span v-if="model.type === 'WALLET_ACTIVITY'"><span class="font-bold">Wallet alerts limited</span> to your linked wallets. Upgrade now for unrestricted alerts.</span>

        <span v-if="['FLOOR_PRICE', 'COLLECTION_LISTINGS', 'COLLECTION_ACTIVITY'].includes(model.type)"><span class="font-bold">Alerts limited</span> to select top collections. Upgrade now to remove restrictions.</span>

        <UiButton
          variant="light-yellow"
          size="sm"
          class="mt-2"
          @click="requirePremium(`to create alerts on any ${model.type === 'WALLET_ACTIVITY' ? 'wallet' : 'collection'}`)"
        >
          <span class="font-bold">Upgrade Now</span>
        </UiButton>
      </div>

      <AlertForm
        v-model="model"
        :on-last-field-enter="create"
        :input-errors="inputErrors"
      />
    </div>

    <div v-if="model.type === null">
      <UiInputGroup label="Select Alert Type">
        <UiRadioCards
          v-model="model.type"
          size="md"
          :options="alertTypes"
        >
          <template #item-title="{item}">
            <div class="flex items-center">
              <div>
                {{ item.title }}
              </div>

              <UiBadge
                v-if="item.disabled && item.disabledBadge"
                class="ml-2"
              >
                {{ item.disabledBadge }}
              </UiBadge>
            </div>
          </template>
        </UiRadioCards>
      </UiInputGroup>
    </div>

    <div
      v-if="model.type && hasRequiresPremiumError"
      class="py-2 px-3 my-1 bg-yellow-500 rounded-lg"
    >
      <div class="flex gap-x-3 mx-auto max-w-screen-2xl text-yellow-900">
        <div>
          <div class="mb-2 font-bold">
            Upgrade to Premium
          </div>

          <div class="text-sm opacity-85">
            To create alerts on any wallet or collection, upgrade your Compass account or purchase a Lifetime Pass.
          </div>

          <div>
            <UiButton
              size="sm"
              variant="light-yellow"
              @click="requirePremium(`to create alerts on any ${model.type === 'WALLET_ACTIVITY' ? 'wallet' : 'collection'}`)"
            >
              Upgrade Now
            </UiButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <UiModalFooter v-if="model.type">
    <UiButton
      v-if="model.type"
      class="mr-2"
      variant="white"
      @click="model.type = null"
    >
      Back
    </UiButton>

    <UiButton
      :is-loading="isCreating"
      :disabled="invalidateListingAlert(model)"
      @click="create"
    >
      Create
    </UiButton>
  </UiModalFooter>
</template>

<script>
import { defineComponent } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import userAlertsQuery from '@/graphql/alert/queries/userAlerts.query.gql';
import createAlertMutation from '@/graphql/alert/mutations/createAlert.mutation.gql';
import { useUiDialog } from '@/components/ui-dialog/useUiDialog';

import { mapGraphqlErrors } from '@/composition/errors';
import { usePremium } from '@/composition/premium';
import UiButton from '../ui/UiButton.vue';

import UiModalFooter from '../ui/UiModalFooter.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';

import AlertForm from './AlertForm.vue';
import UiRadioCards from '../ui/UiRadioCards.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiBadge from '../ui/UiBadge.vue';
import { normalizeThreshold } from '../../utils/normalizeThreshold';
import { invalidateListingAlert } from '../../utils/invalidateListingAlert';

const alertTypes = [
  {
    title: 'Wallet Activity',
    value: 'WALLET_ACTIVITY',
    description: 'Get notified when one or more wallets make transactions',
    allowedConfigKeys: ['wallets', 'types'],
  },
  {
    title: 'Feed Activity',
    value: 'FEED_ACTIVITY',
    description: 'Get notified when there is new activity on your wallet feed',
    allowedConfigKeys: [],
  },
  {
    title: 'Floor Price',
    value: 'FLOOR_PRICE',
    disabled: false,
    disabledBadge: 'Soon',
    description: 'Get notified when a collection\'s floor crosses a specifc threshold',
    allowedConfigKeys: ['type', 'collectionId', 'threshold'],
  },
  {
    title: 'Collection Listings',
    value: 'COLLECTION_LISTINGS',
    description: 'Get notified when one or more tokens are listed',
    allowedConfigKeys: ['collectionId', 'tokenFilters'],
  },
  {
    title: 'Collection Activity',
    value: 'COLLECTION_ACTIVITY',
    disabled: true,
    disabledBadge: 'Soon',
    description: 'Get notified about any collection specific transactions',
    allowedConfigKeys: [],
  },
];
function initModel() {
  return {
    name: '',
    type: null,
    config: {
      wallets: [],
      types: [],
      type: null,
      collectionId: null,
      threshold: [],
      tokenFilters: {
        attributeValueIds: [],
        priceFrom: null,
        priceTo: null,
        tokenId: null,
        owner: null,
      },
    },
    channels: [],
  };
}

function filterConfig(data) {
  const keys = alertTypes.filter((item) => item.value === data.type)[0].allowedConfigKeys;
  const result = { ...data };
  const config = {};
  Object.keys(data.config).forEach((key) => {
    if (keys.indexOf(key) !== -1) {
      config[key] = data.config[key];
    }
  });

  result.config = config;

  return result;
}

export default defineComponent({
  components: {
    UiModalHeader,
    UiModalFooter,
    UiButton,
    UiRadioCards,
    AlertForm,
    UiInputGroup,
    UiBadge,
  },

  expose: ['show'],
  emits: ['dismiss'],

  setup() {
    const { info } = useUiDialog();

    const { mutate: createAlert } = useMutation(
      createAlertMutation,
      () => ({
        refetchQueries: [
          {
            query: userAlertsQuery,
          },
        ],
        errorPolicy: 'none',
      }),
    );

    const { requirePremium, isPremium } = usePremium();

    return {
      info,
      createAlert,
      requirePremium,
      isPremium,
    };
  },

  data() {
    return {
      hasRequiresPremiumError: false,
      inputErrors: {},
      isVisible: false,
      isCreating: false,
      model: initModel(),
      alertTypes,
    };
  },

  methods: {
    invalidateListingAlert,

    show() {
      this.isVisible = true;
      this.model = initModel();
    },

    create() {
      this.isCreating = true;
      this.hasRequiresPremiumError = false;
      this.inputErrors = {};
      return this.createAlert(normalizeThreshold(filterConfig(this.model)))
        .then((res) => {
          if (Array.isArray(res.errors)) {
            res.errors.forEach((error) => {
              this.info(error.message, {
                icon: 'far fa-exclamation-triangle text-lg text-red-600',
              });
            });

            return;
          }

          this.info('Alert created');
          this.isVisible = false;
        })
        .catch(async (err) => {
          const errors = mapGraphqlErrors(err.graphQLErrors);
          if (errors.get('createAlert').code === 'PREMIUM_REQUIRED') {
            this.hasRequiresPremiumError = true;
            return;
          }

          if (errors.get('createAlert').code === 'BAD_USER_INPUT') {
            this.inputErrors = errors.get('createAlert').errorFields;
            return;
          }

          throw err;
        })
        .finally(() => {
          this.isCreating = false;
        });
    },
  },
});
</script>

<style>
</style>
