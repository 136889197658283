<template>
  <div
    v-once
    class="loading-indicator__wrap"
    :class="[
      inline === false ? 'flex' : 'flex-inline',
      {
        'content-center': block === false,
        'justify-content-center': block === false && align === 'center',
        'justify-content-start': block === false && align === 'left',
        'justify-content-end': block === false && align === 'right'
      },
    ]"
  >
    <div
      class="loading-indicator__spinner"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
        borderWidth: `${Math.ceil(Math.max(size / 12, 2))}px`,
        borderColor: 'transparent',
        borderLeftColor: 'currentColor',
      }"
    />

    <slot />
  </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    size: {
      default: 24,
      type: Number,
    },
    align: {
      default: 'center',
      type: String,
    },
    color: {
      default: '#3B82F6',
      type: String,
    },
    block: {
      default: false,
      type: Boolean,
    },
    inline: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.loading-indicator__spinner,
.loading-indicator__spinner:after {
  border-radius: 50%;
}

.loading-indicator__spinner:after {
  width: inherit;
  height: inherit;
}

.loading-indicator__spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border: solid;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.8s infinite linear;
  animation: load8 0.8s infinite linear;
  flex-shrink: 0;
  flex-grow: 0;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
