<template>
  <div class="flex gap-2.5">
    <span class="font-medium">{{ name }}</span>

    <UiToggle
      :model-value="modelValue"
      size="sm"
      @update:modelValue="emit('update:modelValue', $event)"
      @click.stop
    />
  </div>
</template>

<script setup>
import {
  toRefs,
} from 'vue';
import UiToggle from '../ui/UiToggle.vue';

const props = defineProps(['modelValue', 'name']);
const { modelValue, name } = toRefs(props);
const emit = defineEmits(['update:modelValue']);

</script>
