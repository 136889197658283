<template>
  <div class="text-sm text-gray-600 dark:text-gray-400">
    <span
      v-if="noLabel === false"
      class="mr-1 font-medium dark:text-gray-200"
    >Period:</span>

    <a
      v-for="item in options"
      :key="item.value"
      href="#"
      class="py-1 px-1 transition-colors"
      :class="[
        selected === item.value ? 'bg-gray-200 text-indigo-500 dark:bg-indigo-500 dark:bg-opacity-15 rounded-md' : '',
        { 'opacity-50 cursor-default': item.isDisabled, 'hover:text-indigo-500 dark:hover-text-indigo-500': !item.isDisabled },
      ]"
      @click.prevent="select(item)"
    >
      <!-- <i class="fas fa-lock-alt" v-if="item.isDisabled"></i> -->
      <span
        v-if="item.isDisabled"
        v-tooltip="'Requires premium access'"
      >
        {{ item.value }}
      </span>

      <span v-else>
        {{ item.value }}
      </span>
    </a>
  </div>
</template>

<script setup>
import {
  computed,
  toRefs,
  onMounted,
  watch,
} from 'vue';
import moment from 'moment';
import eventBus from '@/utils/event-bus';
import { usePremium } from '@/composition/premium';
import { dateRangeFromPeriod } from '@/utils/dateRangeFromPeriod';

const props = defineProps({
  periods: null,
  modelValue: null,
  minEnabled: {
    type: Number,
    default: 60 * 60,
  },
  maxEnabled: {
    type: Number,
    default: 7 * 24 * 60 * 60,
  },
  mode: {
    type: String,
    default: 'name',
  },
  noLabel: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);
const {
  modelValue,
  periods,
  minEnabled,
  maxEnabled,
} = toRefs(props);
const minFreeLength = computed(() => minEnabled.value);
const maxFreeLength = computed(() => maxEnabled.value);
const selected = computed({
  get() {
    return modelValue.value;
  },

  set(val) {
    emit('update:modelValue', val);
  },
});

const { isPremium } = usePremium();

const options = computed(() => periods.value.map((item) => {
  const dateRangeLength = dateRangeFromPeriod(item, moment()).length;
  return {
    value: item,
    isDisabled: !isPremium.value && (dateRangeLength < minFreeLength.value || dateRangeLength > maxFreeLength.value),
  };
}));

onMounted(() => {
  const length = modelValue.value ? dateRangeFromPeriod(modelValue.value, moment()).length : 0;

  if (!isPremium.value && (length < minFreeLength.value || length > maxFreeLength.value)) {
    // eslint-disable-next-line prefer-destructuring
    selected.value = options.value.filter((item) => !item.isDisabled)[0].value;
  }
});

watch(() => modelValue.value, () => {
  const length = modelValue.value ? dateRangeFromPeriod(modelValue.value, moment()).length : 0;

  if (!isPremium.value && (length < minFreeLength.value || length > maxFreeLength.value)) {
    // eslint-disable-next-line prefer-destructuring
    selected.value = options.value.filter((item) => !item.isDisabled)[0];
  }
});

const select = (item) => {
  if (item.isDisabled) {
    eventBus.$emit('auth:showPremiumModal', 'to unlock all periods');
    return;
  }

  selected.value = item.value;
};

</script>

<style scoped>
</style>
