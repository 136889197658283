export function getDataForChart(dict) {
  const timezoneOffset = new Date().getTimezoneOffset() * -1 * 60;

  for (const key of Object.keys(dict)) {
    dict[key] = dict[key].filter((item) => Boolean(item.value)).map((item) => {
      const data = {
        time: timezoneOffset + (parseInt(new Date(item.key).getTime()) / 1000),
        value: item.value,
      };

      return data;
    });
  }

  return dict;
}
