import { h } from 'vue';

function flexHelper(props) {
  const el = props.el || 'div';
  const { items, justify } = props;
  const itemsClass = items ? `items-${items}` : '';
  const justifyClass = justify ? `justify-${justify}` : '';

  return { el, itemsClass, justifyClass };
}

export function Flex(props, { slots }) {
  const flex = 'flex';
  const { el, itemsClass, justifyClass } = flexHelper(props);
  return h(el, { class: [flex, itemsClass, justifyClass] }, [slots?.default()]);
}
Flex.props = ['el', 'items', 'justify'];

export function FlexCol(props, { slots }) {
  const cl = 'flex flex-col';
  const { el, itemsClass, justifyClass } = flexHelper(props);
  return h(el, { class: [cl, itemsClass, justifyClass] }, [slots?.default()]);
}
FlexCol.props = ['el', 'items', 'justify'];
