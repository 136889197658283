<template>
  <UiModal
    v-model="isVisible"
    size="2xl"
  >
    <UiModalHeader title="Create Script Snippet" />

    <div class="py-3 px-4">
      <UiInputGroup label="Name">
        <UiInput v-model="name" />
      </UiInputGroup>

      <UiInputGroup label="Code">
        <UiCodeEditor v-model="code" />
      </UiInputGroup>
    </div>

    <UiModalFooter>
      <UiButton
        :is-loading="isCreating"
        @click="create"
      >
        Save
      </UiButton>
    </UiModalFooter>
  </UiModal>
</template>

<script setup>
import { ref, computed } from 'vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiInputGroup from '@/components/ui/UiInputGroup.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiCodeEditor from '@/components/ui/UiCodeEditor.vue';
import UiModalFooter from '@/components/ui/UiModalFooter.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiModalHeader from '@/components/ui/UiModalHeader.vue';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'created']);

const isVisible = computed({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const name = ref('');
const code = ref('');

const { mutate: createMutation, loading: isCreating } = useMutation(gql`
  mutation CreateScriptSnippet($name: String!, $code: String!) {
    createScriptSnippet(name: $name, code: $code) {
      id
      name
      code
    }
  }
`, {
  updateQueries: {
    GetScriptSnippets: (prev, { mutationResult }) => {
      const newSnippet = mutationResult.data.createScriptSnippet;

      return {
        ...prev,
        scriptSnippets: [
          newSnippet,
          ...prev.scriptSnippets,
        ],
      };
    },
  },
});

const create = async () => {
  const { data: { createScriptSnippet } } = await createMutation({
    name: name.value,
    code: code.value,
  });
  emit('created', createScriptSnippet);
  isVisible.value = false;
};
</script>
