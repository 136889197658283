<template>
  <div
    v-if="!isVariable"
    class="flex gap-3 items-center"
  >
    <div
      v-for="(items, key) in byIds"
      :key="key"
    >
      <span class="font-bold">{{ items[0].attribute.name }}</span>: {{ items.map((item) => item.value).join(', ') }}
    </div>
  </div>

  <div v-else>
    <span class="mr-1 font-bold">Trait:</span>

    <VariableValueBadge
      :disabled="true"
      :model-value="value.value[0]"
    />
  </div>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import { toRefs, computed } from 'vue';
import gql from 'graphql-tag';
import { groupBy, keyBy } from 'lodash-es';
import VariableDisplay from '@/components/automation/VariableDisplay.vue';
import VariableValueBadge from '@/components/automation/VariableValueBadge.vue';

const props = defineProps(['filter', 'value']);
const { filter, value } = toRefs(props);
const query = gql`
  query ($ids: [String!]!) {
    attributeValues(where: {id: {in: $ids}}) {
      id
      value
      attribute {
        id
        name
      }
    }
  }
`;

const { result } = useQuery(query, () => ({ ids: value.value.value }));
const byIds = computed(() => groupBy(result.value?.attributeValues || [], (item) => item.attribute.id));

const isVariable = computed(() => value.value?.value?.length && value.value.value[0].startsWith('{{') && value.value.value[0].endsWith('}}'));
</script>
