<template>
  <div v-if="isLoading">
    <div class="p-3 text-xs text-transparent rounded-xl border border-gray-800 animate-pulse">
      <div class="font-medium">
        Loading
      </div>

      <div>
        <p>Loading</p>
      </div>

      <div>
        Loading
      </div>
    </div>
  </div>

  <div
    v-else-if="billingInfo && !isEditingBillingInfo"
  >
    <div class="p-3 text-xs text-white rounded-xl border border-gray-800">
      <div class="font-medium">
        {{ billingInfo.name }} {{ billingInfo.vatNumber }}
      </div>

      <div class="text-gray-200">
        <p>{{ billingInfo.addressLine1 }}</p>

        <p>{{ billingInfo.addressLine2 }}</p>
      </div>

      <div class="text-gray-200">
        <p>{{ billingInfo.city }}, {{ billingInfo.country }}</p>

        <p>{{ billingInfo.zipCode }}</p>
      </div>
    </div>
  </div>

  <div v-else-if="!billingInfo && !isEditingBillingInfo">
    <div
      class="flex gap-3 items-center p-3 text-xs text-white hover:bg-gray-800 rounded-xl border border-gray-800 cursor-pointer"
      @click="startEditing"
    >
      <div class="text-lg">
        <i class="fas fa-plus-circle" />
      </div>

      <div class="text-base font-medium">
        Add billing info
      </div>
    </div>
  </div>

  <div
    v-else
  >
    <BillingInfoForm
      v-model:name="billingInfoModel.name"
      v-model:addressLine1="billingInfoModel.addressLine1"
      v-model:addressLine2="billingInfoModel.addressLine2"
      v-model:city="billingInfoModel.city"
      v-model:state="billingInfoModel.state"
      v-model:zipCode="billingInfoModel.zipCode"
      v-model:country="billingInfoModel.country"
      v-model:vat-number="billingInfoModel.vatNumber"
      v-model:is-valid="billingInfoModelValid"
    />
  </div>

  <div
    v-if="billingInfo?.id || isEditingBillingInfo"
    class="flex gap-2 mt-3"
  >
    <UiButton
      v-if="isEditingBillingInfo || !billingInfo"
      :disabled="!billingInfoModelValid"
      variant="white"
      size="xs"
      :is-loading="isSaving"
      @click="billingInfo ? update() : create()"
    >
      Save
    </UiButton>

    <UiButton
      v-if="!isEditingBillingInfo && billingInfo?.id"
      variant="white"
      size="xs"
      @click="startEditing"
    >
      Edit
    </UiButton>

    <UiButton
      v-if="isEditingBillingInfo"
      variant="white"
      size="xs"
      @click="isEditingBillingInfo = false"
    >
      Cancel
    </UiButton>
  </div>
</template>

<script setup>
import {
  reactive, ref,
} from 'vue';
import { useBillingDataGraphql } from '@/composition/billing/useBillingDataGraphql';
import useBillingInfo from '@/composition/billing/useBillingInfo';
import useTracking from '@/composition/tracking';
import BillingInfoForm from './BillingInfoForm.vue';
import UiButton from './ui/UiButton.vue';

const billingInfoModel = reactive({
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  vatNumber: '',
});

const isEditingBillingInfo = ref(false);

const billingInfoModelValid = ref(false);

const { billingInfo, onBillingInfoResult, isLoading } = useBillingInfo();

const startEditing = () => {
  isEditingBillingInfo.value = true;
  Object.keys(billingInfoModel).forEach((key) => {
    if (typeof billingInfo.value[key] === 'undefined') {
      return;
    }

    billingInfoModel[key] = billingInfo.value[key];
  });
};

onBillingInfoResult((result) => {
  if (result.loading) {
    return;
  }

  if (!result.data.billingInfo) {
    return;
  }

  Object.keys(billingInfoModel).forEach((key) => {
    if (typeof result.data.billingInfo[key] === 'undefined') {
      return;
    }
    billingInfoModel[key] = result.data.billingInfo[key];
  });
});

const { createBillingInfo, updateBillingInfo } = useBillingDataGraphql();

const isSaving = ref(false);

const { track } = useTracking();

const create = () => {
  if (!billingInfoModelValid.value) {
    throw new Error('Invalid billing info');
  }
  isSaving.value = true;
  createBillingInfo({
    ...billingInfoModel,
  }).then(() => {
    isEditingBillingInfo.value = false;
    track('billing info created');
  }).finally(() => {
    isSaving.value = false;
  });
};

const update = () => {
  if (!billingInfoModelValid.value) {
    throw new Error('Invalid billing info');
  }
  isSaving.value = true;
  updateBillingInfo({
    ...billingInfoModel,
    id: billingInfo.value.id,
  }).then(() => {
    isEditingBillingInfo.value = false;
    track('billing info updated');
  }).finally(() => {
    isSaving.value = false;
  });
};
</script>
