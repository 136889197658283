<template>
  <div>
    <UiSelectCustom
      :model-value="modelValue"
      :options="watchlists"
      :disabled="disabled"
      button-class="leading-none"
      @update:modelValue="emit('update:modelValue', $event)"
    >
      <template #item="{item}">
        <div class="flex gap-2 items-center leading-none">
          <img
            :src="item.imageUrl"
            class="w-6 h-6 rounded"
          >
          {{ item.name }}
        </div>
      </template>
    </UiSelectCustom>
  </div>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, toRefs } from 'vue';
import UiSelectCustom from './ui/UiSelectCustom.vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const { modelValue, disabled } = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const { result } = useQuery(gql`
query GetCollectionWatchlists {
  collectionWatchlists {
    id
    name
    imageUrl
  }
}
`, {}, {
  fetchPolicy: 'cache-and-network',
});

const watchlists = computed(() => result.value?.collectionWatchlists || []);

</script>
