<template>
  <slot
    name="toggle"
    :toggle="toggle"
  />

  <UiModal v-model="isVisible">
    <UiModalHeader @dismiss="isVisible = false" />

    <div
      class="py-3 px-4"
      @click.stop
    >
      <UiTabs
        v-model="currentTabIndex"
        pills
        hide-single-tab
      >
        <UiTab
          v-if="mode === 'input'"
          name="Templates"
        >
          <div
            v-for="item in galleryItems"
            :key="item.name"
            class="flex gap-3 items-center p-2 hover:bg-gray-800 rounded-xl border border-gray-800 transition-colors cursor-pointer"
            @click="selectItem(item)"
          >
            <div class="overflow-hidden flex-grow-0 flex-shrink-0 w-48 h-20 rounded-lg">
              <img
                :src="require(`../../assets/acting-as-previews/${item.name}.png`)"
                :alt="item.title"
                class="w-auto max-w-max h-full"
              >
            </div>

            <div class="py-2">
              <div class="mb-2 font-bold">
                {{ item.title }}
              </div>

              <div
                v-if="item.description"
                class="text-sm text-gray-400"
              >
                {{ item.description }}
              </div>
            </div>
          </div>
        </UiTab>

        <UiTab name="Custom">
          <UiInputGroup
            label="Name"
            class="flex-1"
            :error-message="getError(['name'])"
          >
            <UiInput v-model="computedValue.name" />
          </UiInputGroup>

          <SingleContextVariableEditor
            v-model="computedValue"
            :get-error="getError"
            :mode="mode"
          />
        </UiTab>
      </UiTabs>
    </div>

    <UiModalFooter>
      <slot
        name="footer"
        :toggle="toggle"
        :is-valid="isValid"
      />
    </UiModalFooter>
  </UiModal>
</template>

<script setup>
import {
  computed, ref, toRefs,
} from 'vue';
import useValidation from '@/composition/validation/use-validation';
import { z } from 'zod';
import { isEqual } from 'lodash-es';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiInput from '../ui/UiInput.vue';
import { IO_TYPES } from '../../constants/automation';
import SingleContextVariableEditor from './SingleContextVariableEditor.vue';
import UiModal from '../ui/UiModal.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';
import UiTabs from '../ui/UiTabs.vue';
import UiTab from '../ui/UiTab.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  mode: {
    type: String,
    default: 'input',
  },
});
const emit = defineEmits(['update:modelValue']);
const { modelValue, mode } = toRefs(props);
const schema = ref(z.object({
  name: z.string().min(1).regex(/^([^\s])+$/, 'No spaces allowed'),
  type: z.union(IO_TYPES.map((type) => z.literal(type.id))),
  required: z.boolean(),
  defaultValue: z.string().optional(),
  description: z.string().optional().nullish(),
}));
const computedValue = computed({
  get: () => modelValue.value,
  set: (value) => emit('update:modelValue', value),
});

const { isValid, validationIssues } = useValidation(schema, computedValue);
const getError = (key) => {
  const error = validationIssues.value.find((i) => isEqual(i.path, key));
  return error ? error.message : null;
};
const currentTabIndex = ref(0);
const isVisible = ref(false);
const toggle = () => {
  isVisible.value = !isVisible.value;
  if (isVisible.value) {
    currentTabIndex.value = isValid.value ? 1 : 0;
  }
};

const galleryItems = [
  {
    name: 'collection',
    title: 'Collection',
    description: 'Collection contract address input with search and autocomplete',
    template: () => ({
      name: 'collectionId',
      type: 'String',
      required: false,
      description: '',
      actingAs: 'collection',
    }),
  },
  {
    name: 'wallet',
    title: 'Wallet',
    description: 'Wallet address input with search and autocomplete',
    template: () => ({
      name: 'walletId',
      type: 'String',
      required: false,
      description: '',
      actingAs: 'wallet',
    }),
  },
  {
    name: 'tradingWallet',
    title: 'Trading Wallet',
    description: 'Wallet address picker, with wallets from the user\'s connected wallets',
    template: () => ({
      name: 'walletId',
      type: 'String',
      required: false,
      description: '',
      actingAs: 'wallet',
    }),
  },
  {
    name: 'collectionTokenFilters',
    title: 'Collection Token Filters',
    description: 'Builder to create a set of filters for collection tokens. Returns an array of filters accepted by the API',
    template: () => ({
      name: 'collectionTokenFilters',
      type: 'Array',
      required: false,
      description: '',
      actingAs: 'collectionTokenFilters',
    }),
  },
  {
    name: 'collectionWatchlist',
    title: 'Collection Watchlist',
    description: 'Collection Watchlist picker. Returns a collection watchlist ID',
    template: () => ({
      name: 'collectionWatchlist',
      type: 'Number',
      required: false,
      description: '',
      actingAs: 'collectionWatchlist',
    }),
  },
  {
    name: 'walletCollectionFilters',
    title: 'Wallet Collection Filters',
    description: 'Builder to create a set of filters for wallet collections. Returns an array of filters accepted by the API',
    template: () => ({
      name: 'walletCollectionFilters',
      type: 'Array',
      required: false,
      description: '',
      actingAs: 'walletCollectionFilters',
    }),
  },
  {
    name: 'walletTokenFilters',
    title: 'Wallet Token Filters',
    description: 'Builder to create a set of filters for wallet tokens. Returns an array of filters accepted by the API',
    template: () => ({
      name: 'walletTokenFilters',
      type: 'Array',
      required: false,
      description: '',
      actingAs: 'walletTokenFilters',
    }),
  },
  {
    name: 'duration',
    title: 'Duration',
    description: 'Duration input with a dropdown to select between minutes and hours, always returns seconds',
    template: () => ({
      name: 'duration',
      type: 'Number',
      required: false,
      description: '',
      actingAs: 'duration',
    }),
  },
  {
    name: 'marketplace',
    title: 'Marketplace',
    description: 'Picker for internal marketplace ID',
    template: () => ({
      name: 'marketplace',
      type: 'Number',
      required: false,
      description: '',
      actingAs: 'marketplace',
    }),
  },
  {
    name: 'notificationChannels',
    title: 'Notification Channels',
    description: 'Picker for notification channels',
    template: () => ({
      name: 'notificationChannels',
      type: 'Array',
      required: false,
      description: '',
      actingAs: 'notificationChannels',
    }),
  },
  {
    name: 'period',
    title: 'Period',
    description: 'Period input with predefined options as accepted by the API',
    template: () => ({
      name: 'period',
      type: 'String',
      required: false,
      description: '',
      actingAs: 'period',
    }),
  },
  {
    name: 'token',
    title: 'Token',
    description: 'Token internal token ID input',
    template: () => ({
      name: 'token',
      type: 'String',
      required: false,
      description: '',
      actingAs: 'token',
    }),
  },
  {
    name: 'tokenAttribute',
    title: 'Token Attribute',
    description: 'Token attribute internal ID picker',
    template: () => ({
      name: 'tokenAttribute',
      type: 'String',
      required: false,
      description: '',
      actingAs: 'tokenAttribute',
    }),
  },
];

const selectItem = (item) => {
  const template = item.template();

  Object.keys(template).forEach((key) => {
    computedValue.value[key] = template[key];
  });
  currentTabIndex.value = 1;
};
</script>
