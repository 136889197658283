<template>
  <div v-if="isLoading">
    <UiTable
      :is-loading="isLoading"
      :fields="fields"
      :items="[{}]"
    />
  </div>

  <div v-else>
    <UiTable
      :items="transactions"
      :fields="fields"
      :table-class="['table-fixed', tableClass]"
      :no-shadow="noShadow"
      :no-sticky="noSticky"
      :show-skeleton="!!isLoadingMore"
    >
      <template #cell(collection)="{item}">
        <CollectionListItem
          :collection="item.collection"
          :mini="true"
          :pic-size="10"
        />
      </template>

      <template #cell(from_address)="{item}">
        <WalletProfile :wallet="item.from" />
      </template>

      <template #cell(to_address)="{item}">
        <WalletProfile :wallet="item.to" />
      </template>

      <template #cell(type)="{item}">
        <span
          v-if="item.type"
          class="capitalize"
        >
          <div class="flex items-center">
            <MarketplaceIcon
              v-if="item.type === 'sale'"
              class="mr-1"
              :marketplace="item.sale.marketplace"
            />
            {{ item.type }}
          </div>

          <i
            v-if="item.type === 'mint' && item.to.id !== item.transaction.fromAddress"
            v-tooltip="'This transaction was not initiated by the wallet owner.'"
            class="fas fa-exclamation-circle"
          />
        </span>

        <span v-else-if="item.sale">Sale</span>

        <span v-else-if="item.from.id === '0x0000000000000000000000000000000000000000'">Mint <i
          v-if="item.to.id !== item.transaction.fromAddress"
          v-tooltip="'This transaction was not initiated by the wallet owner.'"
          class="fas fa-exclamation-circle"
        /></span>

        <span v-else-if="item.to.id === '0x0000000000000000000000000000000000000000'">Burn</span>

        <span v-else>Transfer</span>
      </template>

      <template #cell(token)="{item}">
        <UiLink @click="$emit('showTokenDetails', item)">
          <TokenListItem
            :token="item.token"
            :collection="item.collection"
          />
        </UiLink>
      </template>

      <template #cell(value_in_eth)="{item}">
        <div>
          <CurrencyDisplay
            v-if="item.hasOwnProperty('transactionValue')"
            :value="item.transactionValue || item.transaction.value || 0"
          />

          <CurrencyDisplay
            v-else
            :value="item.sale ? item.sale.price / item.sale.tokensSold : item.transaction.value"
          />
        </div>

        <div class="text-xs text-gray-600 dark:text-gray-400">
          <CurrencyDisplay :value="item.gasPrice || item.transaction.gas_in_eth" />

          <i class="ml-1 far fa-gas-pump" />
        </div>
      </template>

      <template #cell(created_at)="{item}">
        <UiLink
          target="_blank"
          :href="`https://etherscan.io/tx/${item.transaction.id}`"
        >
          <Timestamp
            :timestamp="item.timestamp"
            :relative-only="true"
            :auto-update="true"
          />
        </UiLink>
      </template>
    </UiTable>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { groupBy } from 'lodash-es';
import moment from 'moment-timezone';
import { formatAddress } from '@/utils/filters';
import UiLink from '@/components/ui/UiLink.vue';
import UiTable from '@/components/ui/UiTable.vue';
import CurrencyDisplay from '@/components/CurrencyDisplay.vue';
import WalletProfile from '@/components/wallets/WalletProfile.vue';
import decodesFilterQuery from '../../mixins/decodesFilterQuery';
import Timestamp from '../Timestamp.vue';
import CollectionListItem from './CollectionListItem.vue';
import MarketplaceIcon from '../MarketplaceIcon.vue';
import TokenListItem from '../TokenListItem.vue';

export default defineComponent({
  name: 'TransactionsTable',
  components: {
    WalletProfile,
    CurrencyDisplay,
    UiTable,
    UiLink,
    Timestamp,
    CollectionListItem,
    MarketplaceIcon,
    TokenListItem,
  },
  mixins: [
    decodesFilterQuery,
  ],
  props: {
    transactions: null,
    isLoading: null,
    isLoadingMore: null,
    excludedFields: null,
    tableClass: null,
    groupHeadClass: null,
    noShadow: {
      default: false,
      type: Boolean,
    },
    noSticky: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['showTokenDetails'],

  setup() {
    return {
      formatAddress,
    };
  },

  computed: {
    transactionsByDate() {
      if (!this.transactions) {
        return [];
      }
      return groupBy(this.transactions, (item) => moment(item.timestamp).format('ll'));
    },
    blacklistedFields() {
      return this.excludedFields || [];
    },
    fields() {
      return [
        // 1/6 + 1/6 + 1/8 + 1/8 + 1/8 + 1/10 + 1/10 + 1/6
        { key: 'collection', name: 'Collection', colClass: 'w-1/6' },
        { key: 'token', name: 'Token', colClass: 'w-1/6' },
        { key: 'from_address', name: 'From', colClass: 'w-1/8' },
        { key: 'to_address', name: 'To', colClass: 'w-1/8' },
        { key: 'value_in_eth', name: 'Value', colClass: 'w-1/8' },
        { key: 'type', name: 'Type', colClass: 'w-1/10' },
        { key: 'created_at', name: 'Date', colClass: '1/10' },
      ].filter((item) => this.blacklistedFields.indexOf(item.key) === -1);
    },
  },
});
</script>

<style scoped>

</style>
