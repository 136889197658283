<template>
  <UiTable
    v-if="(isLoading) || (!isLoading && activity.length > 0)"
    :items="activity"
    :fields="[
      {key: 'type', name: 'Type', width: 10 },
      ...(hideToken === false ? [{key: 'token', name: 'Token', width: 25 }] : []),
      {key: 'value', name: 'Price', width: 15 },
      {key: 'from', name: 'From', width: 20 },
      {key: 'to', name: 'To', width: 20},
      {key: 'timestamp', name: 'Timestamp', width: 20 }]"
    :show-skeleton="loadingMore"
    :skeletons-rows="loadingItems"
    :is-loading="isLoading && !loadingMore"
    v-bind="tableProps"
  >
    <template #cell(type)="{item}">
      <div class="flex gap-2 items-center">
        <div
          v-tooltip="item.type === 'mint' && item.from ? 'Spam mint' : ''"
          class="inline-block py-1.5 px-2.5 text-sm leading-none capitalize rounded-full"
          :class="[typeColor[item.type], {'ring-2 ring-red-500 ring-offset-2 ring-offset-black bg-opacity-75': item.type === 'mint' && item.from}]"
        >
          {{ item.type }}
        </div>

        <div
          v-if="item.type === 'sale'"
          class="inline-block py-1.5 px-2.5 text-sm leading-none capitalize rounded-full"
          :class="`bg-brand-${marketplaceMap[item.sale.marketplace]}`"
        >
          <MarketplaceIcon :marketplace="item.sale.marketplace" />
        </div>

        <div
          v-if="item.type === 'list'"
          class="inline-block py-1.5 px-2.5 text-sm leading-none capitalize rounded-full"
          :class="`bg-brand-${marketplaceMap[item.listing.marketplace]}`"
        >
          <MarketplaceIcon :marketplace="item.listing.marketplace" />
        </div>

        <div
          v-if="item.type === 'bid'"
          class="inline-block py-1.5 px-2.5 text-sm leading-none capitalize rounded-full"
          :class="`bg-brand-${marketplaceMap[item.offer.marketplace]}`"
        >
          <MarketplaceIcon :marketplace="item.offer.marketplace" />
        </div>
      </div>
    </template>

    <template #head(token)>
      Item
    </template>

    <template #cell(token)="{item: {token, collection}}">
      <CollectionListItem
        v-if="!token && collection"
        :collection="collection"
      />

      <TokenListItem
        v-if="token"
        :token="token"
        :collection="collection"
      >
        <template #context>
          <OverlayFilterContext>
            <UiDropdown inline>
              <template #toggle="{toggle}">
                <UiButton
                  variant="white"
                  size="xs"
                  @click="toggle"
                >
                  Only
                </UiButton>
              </template>

              <UiDropdownItem @click="emit('addFilter', {key: 'collectionId', type: 'collection', value: {comparator: 'in', value: [collection.id]}})">
                Collection
              </UiDropdownItem>

              <UiDropdownItem>Token</UiDropdownItem>
            </UiDropdown>

            <UiDropdown inline>
              <template #toggle="{toggle}">
                <UiButton
                  variant="white"
                  size="xs"
                  @click="toggle"
                >
                  Exclude
                </UiButton>
              </template>

              <UiDropdownItem @click="emit('addFilter', {key: 'collectionId', type: 'collection', value: {comparator: 'nin', value: [collection.id]}})">
                Collection
              </UiDropdownItem>

              <UiDropdownItem>Token</UiDropdownItem>
            </UiDropdown>
          </OverlayFilterContext>
        </template>
      </TokenListItem>
    </template>

    <template #cell(from)="{item: {from: wallet}}">
      <WalletProfile
        v-if="wallet"
        :wallet="wallet"
      >
        <template #context>
          <OverlayFilterContext
            @exclude="emit('addFilter', {key: 'fromAddress', type: 'wallet', value: {comparator: 'nin', value: [wallet.id]}})"
            @only="emit('addFilter', {key: 'fromAddress', type: 'wallet', value: {comparator: 'in', value: [wallet.id]}})"
          />
        </template>
      </WalletProfile>

      <div v-else>
        --
      </div>
    </template>

    <template #cell(to)="{item: {to: wallet}}">
      <WalletProfile
        v-if="wallet"
        :wallet="wallet"
      >
        <template #context>
          <OverlayFilterContext
            @exclude="emit('addFilter', {key: 'toAddress', type: 'wallet', value: {comparator: 'nin', value: [wallet.id]}})"
            @only="emit('addFilter', {key: 'toAddress', type: 'wallet', value: {comparator: 'in', value: [wallet.id]}})"
          />
        </template>
      </WalletProfile>

      <div v-else>
        --
      </div>
    </template>

    <template #cell(value)="{item: { value, floorDelta, sale, gasSpent}}">
      <CurrencyDisplay
        v-if="value !== null"
        v-tooltip="sale?.type === 'bid' ? 'Bid' : undefined"
        :value="value"
        :class="{'text-purple-400': sale?.type === 'bid'}"
      />

      <div v-else>
        --
      </div>

      <div
        v-if="floorDelta === null && gasSpent !== null"
        class="flex gap-1.5 items-center text-sm text-gray-400"
      >
        <i class="far fa-gas-pump" />

        <CurrencyDisplay :value="gasSpent" />
      </div>

      <div
        v-if="floorDelta !== null"
        class="text-xs font-light text-gray-400"
      >
        {{ formatPercent(Math.abs(floorDelta), 0) }} {{ floorDelta > 0 ? 'above' : 'below' }} floor
      </div>
    </template>

    <template #cell(timestamp)="{item: {timestamp, transaction}}">
      <a
        v-if="transaction?.id"
        :href="`https://etherscan.io/tx/${transaction?.id}`"
        target="_blank"
      >
        <Timestamp
          auto-update
          :timestamp="timestamp"
          :largest="1"
        />
      </a>

      <Timestamp
        v-else
        :timestamp="timestamp"
        :largest="1"
        :auto-update="true"
      />
    </template>
  </UiTable>
</template>

<script setup>
import { formatPercent } from '@/utils/filters';
import { ref, toRefs } from 'vue';
import CollectionListItem from './collections/CollectionListItem.vue';
import CurrencyDisplay from './CurrencyDisplay.vue';
import MarketplaceIcon from './MarketplaceIcon.vue';
import OverlayFilterContext from './OverlayFilterContext.vue';
import Timestamp from './Timestamp.vue';
import TokenListItem from './TokenListItem.vue';
import UiDropdown from './ui/ui-dropdown/UiDropdown.vue';
import UiButton from './ui/UiButton.vue';
import UiDropdownItem from './ui/UiDropdownItem.vue';
import UiTable from './ui/UiTable.vue';
import WalletProfile from './wallets/WalletProfile.vue';

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  activity: {
    type: Array,
    default() {
      return [];
    },
  },
  loadingMore: {
    type: Boolean,
    default: false,
  },
  loadingItems: {
    type: Number,
    default: 10,
  },
  tableProps: {
    type: Object,
    default() {
      return {};
    },
  },
  hideToken: {
    type: Boolean,
    default: false,
  },
});

const filters = ref([]);

const emit = defineEmits(['addFilter']);

const {
  isLoading, activity, loadingMore, tableProps,
} = toRefs(props);

const typeColor = {
  mint: 'bg-blue-700 text-blue-100',
  burn: 'bg-red-700 text-red-100',
  sale: 'bg-green-700 text-green-100',
  transfer: 'bg-gray-700 text-gray-100',
  list: 'bg-sky-600 text-sky-100',
  bid: 'bg-purple-600 text-purple-100',
};

const marketplaceMap = {
  0: 'opensea',
  1: 'looksrare',
  2: 'opensea',
  3: 'opensea',
  4: 'x2y2',
  5: 'cryptopunks',
  6: 'sudoswap',
  7: 'blur',
};

</script>
