<template>
  <a
    href="https://compass-status.com"
    target="_blank"
    class="flex items-center text-white cursor-pointer"
  >
    <div
      class="md:mr-2 w-3 h-3 rounded-full"
      :class="{
        'bg-green-500': overallStatus === 'operational',
        'bg-red-500': overallStatus === 'degraded',
        'bg-yellow-500': overallStatus === null,
      }"
    />

    <span
      v-if="overallStatus"
      class="hidden md:block capitalize"
    >{{ overallStatus }}</span>

    <span
      v-if="!overallStatus"
      class="hidden md:block capitalize"
    >Unknown</span>
  </a>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      statusData: null,
      updateInterval: null,
    };
  },
  computed: {
    overallStatus() {
      return this.statusData?.attributes?.aggregate_state || null;
    },
  },
  mounted() {
    this.loadStatus();
    this.updateInterval = setInterval(() => {
      this.loadStatus();
    }, 60000);
  },
  beforeUnmount() {
    if (!this.updateInterval) {
      return;
    }
    clearInterval(this.updateInterval);
  },
  methods: {
    loadStatus() {
      return axios.get('https://api.compass-status.com/').then((res) => {
        this.statusData = res.data.data;
      }).catch(() => {});
    },
  },
};
</script>

<style>

</style>
