<template>
  <span
    :ref="init"
    v-tooltip="tooltip"
  ><slot /></span>
</template>

<script setup>
import {
  ref, useSlots, watch, nextTick,
} from 'vue';

const tooltip = ref(null);
const slots = useSlots();
const process = (slot, el) => {
  if (slot.length !== 1 || typeof slot[0].children !== 'string') {
    console.error('Invalid children passed to OverflowText', slot);
    return;
  }
  if (!el) {
    return;
  }
  if (el.scrollWidth <= el.offsetWidth) {
    return;
  }

  tooltip.value = slot[0].children;
};
const init = (el) => {
  const slot = slots.default();
  watch(() => slot[0]?.children, (newValue, oldValue) => {
    if (!el || oldValue === newValue) {
      return;
    }
    nextTick(() => {
      process(slot, el);
    });
  });
  nextTick(() => {
    process(slot, el);
  });
};
</script>
