<template>
  <ActionInputMapper
    v-model="value"
    :inputs="action.inputs"
    :trigger="trigger"
  >
    <template #input(template)="{model, updateModel, addVariable, error}">
      <UiInputGroup
        v-if="readonly === false"
        :error-message="error?.message"
      >
        <template #after-label>
          <UiButton
            size="xs"
            variant="white"
            class="ml-2"
            @click="addVariable(false, value => editor.insertText(`{{ ${value} }}`))"
          >
            <i class="far fa-brackets-curly" />
          </UiButton>
        </template>

        <UiCodeEditor
          ref="editor"
          :model-value="model"
          language="liquid"
          line-numbers="off"
          @update:modelValue="updateModel"
        />
      </UiInputGroup>
    </template>
  </ActionInputMapper>
</template>

<script setup>
import UiInputGroup from '@/components/ui/UiInputGroup.vue';
import { toRefs, computed, ref } from 'vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCodeEditor from '@/components/ui/UiCodeEditor.vue';
import ActionInputMapper from '../ActionInputMapper.vue';

const editor = ref(null);

// Outputs "Welcome to Liquid!"
// ['modelValue', 'outputs', 'action', 'trigger']
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  action: {
    type: Object,
    required: true,
  },
  trigger: {
    type: String,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const {
  modelValue, action, trigger,
} = toRefs(props);

const value = computed({
  get() {
    return modelValue.value;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

</script>
