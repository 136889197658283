<template>
  <ContentLoader
    v-darkmode
    :view-box="`0 0 ${width} ${height}`"
    :speed="2"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <slot v-if="$slots.default" />

    <rect
      v-else
      x="0"
      y="0"
      rx="0"
      ry="0"
      :width="width"
      :height="height"
    />
  </ContentLoader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'LoadingPlaceholder',
  directives: {
    darkmode: {
      mounted(el) {
        el.querySelectorAll('linearGradient stop').forEach((item, index) => item.classList.add(`loader-stop-${index}`));
      },
    },
  },
  components: {
    ContentLoader,
  },
  props: ['width', 'height'],
};
</script>

<style>
.loader-stop-0 {
  stop-color: #F3F4F6
}
.loader-stop-1 {
  stop-color: #D1D5DB
}
.loader-stop-2 {
  stop-color: #F3F4F6
}

  html.dark .loader-stop-0 {
    stop-color: rgb(32, 33, 32)
  }
  html.dark .loader-stop-1 {
    stop-color: rgb(41, 42, 41)
  }
  html.dark .loader-stop-2 {
    stop-color: rgb(32, 33, 32)
  }

</style>
