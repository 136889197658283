<template>
  <div
    :class="componentClasses"
    class="flex relative items-start select-none"
  >
    <div class="flex items-center h-5">
      <input
        :id="id"
        v-bind="inputAttrs"
        v-model="model"
        aria-describedby="comments-description"
        :name="id"
        type="checkbox"
        :indeterminate.prop="indeterminate"
        :class="sizeClasses"
        class="
            text-gray-800
            dark:bg-gray-800
            border-gray-300
            dark:border-gray-700
            focus:ring-indigo-500
            dark:ring-offset-black
          "
      >
    </div>

    <div
      v-if="$slots.default"
      class="ml-3 text-sm"
    >
      <label
        :for="id"
        class="font-medium text-gray-700 dark:text-gray-200"
      ><slot /></label>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
} from 'vue';

export default defineComponent({
  inheritAttrs: false,

  props: ['modelValue', 'size', 'indeterminate'],

  emits: ['update:modelValue'],

  setup(props, { emit, attrs }) {
    const { modelValue } = toRefs(props);
    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    const componentClasses = attrs.class?.slice() || '';
    const inputAttrs = { ...attrs };
    delete inputAttrs?.class;

    return {
      model,
      componentClasses,
      inputAttrs,
    };
  },

  data() {
    return {
      id: `ui-checkbox-${Math.floor(Date.now() * Math.random())}`,
    };
  },

  computed: {
    sizeClasses() {
      if (this.size === 'lg') {
        return 'w-5 h-5 rounded-md';
      }
      return 'w-4 h-4 rounded';
    },
  },
});
</script>

<style>
</style>
