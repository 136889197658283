<template>
  <div>
    <div v-if="size === 'sm'">
      <div :class="`grid grid-cols-${cols} gap-3 sm:grid-cols-${cols}`">
        <!--
        In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
        Active: "ring-2 ring-offset-2 ring-indigo-500"
        Checked: "", Not Checked: ""
      -->
        <label
          v-for="(item, index) in options"
          :key="index"
          class="
            flex
            sm:flex-1
            justify-center
            items-center
            py-3
            px-3
            text-sm
            font-medium
            rounded-md
            border
            focus:outline-none

          "
          :class="
            [isSelected(item.value)
               ? 'bg-indigo-500 border-transparent text-indigo-900 opacity-100'
               : 'bg-white dark:bg-gray-800 dark:border-gray-700 border-gray-200 dark:text-gray-200 text-gray-900 opacity-90 hover:opacity-100',
             { 'opacity-75 cursor-default': item.disabled, 'cursor-pointer': !item.disabled, 'hover:bg-gray-50': !isSelected(item.value) && !item.disabled }
            ]
          "
        >
          <input
            v-model="model"
            :type="type"
            name="memory-option"
            :value="item.value"
            class="sr-only"
            :disabled="item.disabled"
          >

          <p id="memory-option-0-label">{{ item.title }}</p>
        </label>
      </div>
    </div>

    <div v-if="size === 'md'">
      <div class="space-y-4">
        <!--
      Checked: "border-transparent", Not Checked: "border-gray-300"
      Active: "ring-2 ring-indigo-500"
    -->
        <label
          v-for="(item, index) in options"
          :key="index"
          class="
            block
            relative
            sm:flex sm:justify-between sm:items-center
            py-4
            px-6
            bg-white
            border-gray-300 dark:bg-gray-800
            dark:hover:bg-gray-750
            rounded-lg
            dark:border-gray-800
            shadow-sm
            focus:outline-none
          "
          :class="{ 'opacity-75 cursor-default': item.disabled, 'cursor-pointer': !item.disabled}"
        >
          <input
            v-model="model"
            :type="type"
            class="sr-only"
            :value="item.value"
            :disabled="item.disabled"
          >

          <div class="flex justify-between items-center">
            <div class="text-sm">
              <p
                id="server-size-0-label"
                class="font-medium "
              >
                <slot
                  name="item-title"
                  v-bind="{item}"
                >{{ item.title }}</slot>
              </p>

              <div
                v-if="item.description"
                class="mt-1 text-xs text-gray-400"
              >{{ item.description }}</div>
            </div>

          </div>

          <div>
            <div
              class="flex justify-center items-center w-5 h-5 border border-gray-400"
              :class="[{'rounded-full': type === 'radio', 'rounded': type === 'checkbox'}]"
            >
              <i
                v-if="isSelected(item.value)"
                class="text-sm leading-none text-center text-white fas fa-check"
              />
            </div>
          </div>

        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, toRefs } from 'vue';

export default defineComponent({
  name: 'UiRadioCards',

  props: {
    options: {
      default() {
        return [];
      },
      type: Array,
    },
    size: {
      default: 'sm',
      type: String,
    },
    type: {
      default: 'radio',
      type: String,
    },
    modelValue: {
      default: () => ([]),
    },
    cols: null,
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    return {
      model,
    };
  },

  computed: {
    isSelected() {
      if (Array.isArray(this.model)) {
        return (value) => this.model.indexOf(value) !== -1;
      }

      return (value) => this.model === value;
    },
  },
});
</script>

<style>
</style>
