<template>
  <div class="py-3 px-4 dark:text-gray-100 border-b dark:border-gray-800">
    <div class="flex justify-between items-center">
      <slot>
        <div class="font-medium">
          {{ title }}
        </div>
      </slot>

      <button
        class="text-lg"
        @click="$emit('dismiss')"
      >
        <i class="far fa-times" />
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiModalHeader',

  props: ['title'],

  emits: ['dismiss'],
});
</script>

<style scoped>
</style>
