<template>
  <div class="mx-auto max-w-screen-2xl">
    <Filters
      v-model="filters"
      :filters="availableFilters"
      class="mb-6"
      group="wallet"
    />

    <PortfolioAnalytics
      :model="model"
      :is-loading="isLoading"
      :filters="filters"
      mode="wallet"
    />
  </div>
</template>

<script setup>

import {
  computed,
  ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';

import walletAnalyticsQuery from '@/graphql/wallet/queries/walletAnalytics.query.gql';

// import Chart from '@/components/Chart.vue';
import { FILTER_TYPES } from '@/constants/filters';
import { useFiltersQuerySync } from '@/composition/filters/useFiltersQuerySync';
import PortfolioAnalytics from '../PortfolioAnalytics.vue';
import Filters from '../filters/Filters.vue';

const route = useRoute();

const address = computed(() => route.params.address);

const period = ref('7d');
const availableFilters = [
  {
    name: 'Collection',
    key: 'collectionId',
    type: FILTER_TYPES.COLLECTION,
    autoPin: true,
  },
];
const filters = ref([]);
// useFiltersQuerySync(filters);
useFiltersQuerySync(filters);
const {
  result,
  loading: isLoading,
} = useQuery(walletAnalyticsQuery, () => ({ address: route.params.address, filters: filters.value }));

const model = computed(() => result.value?.wallet || { id: address.value });

</script>

<style scoped>

</style>
