<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-3">
      <UiInputGroup label="SDK Version">
        <UiSelectCustom
          v-model="model.sdkVersion"
          v-tooltip="noSdkChange === false ? undefined : 'SDK version cannot be changed'"
          :disabled="noSdkChange !== false"
          :options="[{id: 'v1', name: 'V1'}, {id: 'v2', name: 'V2'}]"
        />
      </UiInputGroup>

      <UiInputGroup label="Name">
        <div class="flex gap-3 items-center">
          <div class="flex-grow-0 flex-shrink-0">
            <UiIconPicker v-model="model.icon" />
          </div>

          <div class="flex-1">
            <UiInput
              v-model="model.name"
              size="lg"
            />
          </div>
        </div>
      </UiInputGroup>

      <UiInputGroup label="Inputs">
        <ContextVariablesEditor
          v-model="model.inputs"
          cols="1"
        />
      </UiInputGroup>

      <UiInputGroup label="Outputs">
        <ContextVariablesEditor
          v-model="model.outputs"
          cols="1"
          mode="output"
        />
      </UiInputGroup>

      <UiInputGroup
        v-if="model.sdkVersion === 'v1'"
        label="Import Snippets"
      >
        <ScriptSnippetPicker v-model="model.snippetIds" />
      </UiInputGroup>
    </div>

    <div class="sticky top-24 col-span-9">
      <UiTabs
        v-model="editorTabIndex"
        pills
      >
        <UiTab name="Editor">
          <UiInputGroup class="relative">
            <UiCodeEditor
              v-model="model.script"
              height-class="h-visible"
              :extra-declarations="[inputDeclaration]"
              :sdk-version="model.sdkVersion"
            />
          </UiInputGroup>
        </UiTab>

        <UiTab
          v-if="model.sdkVersion === 'v2'"
          name="Debugger"
        >
          <div v-if="runResults.length > 0">
            <UiCard
              v-for="item in runResults"
              :key="item.start"
            >
              <div class="p-3">
                <div class="mb-3 text-sm text-gray-400">
                  Duration {{ item.time }}ms
                </div>

                <div class="text-gray-100">
                  <div class="font-medium text-md">
                    Result
                  </div>

                  <div>
                    <table>
                      <tr
                        v-for="(value, key) of item.result"
                        :key="key"
                      >
                        <td class="w-1/4 text-gray-400">
                          {{ key }}
                        </td>

                        <td class="w-3/4 break-all">
                          {{ value }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="text-gray-100">
                  <div class="font-medium text-md">
                    Logs
                  </div>

                  <div>
                    <ul class="py-2 px-2.5 mb-1 font-mono text-sm bg-black bg-opacity-20 rounded">
                      <li
                        v-for="log in item.logs"
                        :key="log.timestamp"
                        class="mb-1"
                      >
                        <span class="text-gray-400">{{ moment(log.timestamp).format('HH:mm:ss.SSS') }}</span>
                        {{ log.args.join('\t') }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </UiCard>
          </div>

          <div v-else>
            <NoContent
              content="No runs yet"
              horizontal
              size="sm"
            >
              <template #cta>
                <UiButton
                  variant="white"
                  :is-loading="isRunning"
                  @click="runScript"
                >
                  <i class="mr-2 text-indigo-500 fas fa-play" />
                  Run
                </UiButton>
              </template>
            </NoContent>
          </div>
        </UiTab>

        <slot name="tabs" />
      </UiTabs>

      <UiModal
        v-if="model.sdkVersion === 'v2'"
        v-model="isRunModalVisible"
      >
        <UiModalHeader title="Run script" />

        <div class="py-3 px-4">
          <ActionInputMapper
            v-model="previewValues"
            :inputs="model.inputs"
            no-variables
          />
        </div>

        <UiModalFooter>
          <UiButton
            variant="white"
            :is-loading="isRunning"
            @click="runScript"
          >
            <i class="mr-2 text-indigo-500 fas fa-play" />
            Run
          </UiButton>
        </UiModalFooter>
      </UiModal>
    </div>
  </div>

  <div class="flex fixed bottom-8 justify-between py-6 mx-auto w-full max-w-screen-2xl bg-gray-950 bg-opacity-90 shadow-xl backdrop-filter backdrop-blur">
    <div />

    <div class="flex gap-2 items-center">
      <UiButton
        v-if="model.sdkVersion === 'v2'"
        size="lg"
        variant="white"
        :is-loading="isRunning"
        @click="runScript"
      >
        <i class="mr-2 text-indigo-500 fas fa-play" />
        Run
      </UiButton>

      <slot name="buttons" />
    </div>
  </div>
</template>

<script setup>
import {
  reactive, toRefs, computed, ref,
} from 'vue';
import moment from 'moment';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import useValidation from '@/composition/validation/use-validation';
import getSchema from '@/validation/templates/inputs';
import UiCodeEditor from '../ui/UiCodeEditor.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import ContextVariablesEditor from './ContextVariablesEditor.vue';
import ScriptSnippetPicker from './scripting/ScriptSnippetPicker.vue';
import UiModal from '../ui/UiModal.vue';
import ActionInputMapper from './ActionInputMapper.vue';
import UiButton from '../ui/UiButton.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';
import UiTabs from '../ui/UiTabs.vue';
import UiTab from '../ui/UiTab.vue';
import UiCard from '../ui/UiCard.vue';
import useDialogs from '../ui/composition/use-dialogs';
import Timestamp from '../Timestamp.vue';
import UiSelectCustom from '../ui/UiSelectCustom.vue';
import UiIconPicker from '../ui/UiIconPicker.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import NoContent from '../NoContent.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  noSdkChange: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const {
  modelValue,
  noSdkChange,
} = toRefs(props);

const model = computed({
  get: () => modelValue.value,
  set: (value) => emit('update:modelValue', value),
});

const typescriptType = (type) => {
  if (type === 'Array') {
    return 'any[]';
  }

  if (type === 'Object') {
    return 'Record<string, any>';
  }

  return type.toLowerCase();
};

const inputDeclaration = computed(() => ({
  content: `declare interface IActionInputs {
  ${model.value.inputs.map((input) => `/**
* ${input.description}
*/
${input.name}${input.required ? ':' : '?:'} ${typescriptType(input.type)}`).join(',\n')}
}`,
  filePath: 'file:///inputs.d.ts',
}));

const previewValues = ref({});
const isRunModalVisible = ref(false);
const isRunning = ref(false);
const editorTabIndex = ref(0);

const { mutate: runScriptMutation } = useMutation(gql`
mutation ($script: String!, $inputs: JSON) {
  executeAutomationScript(script: $script, inputs: $inputs)
}
`, () => ({
  errorPolicy: 'none',
}));

const { isValid } = useValidation(
  computed(() => getSchema(model.value.inputs)),
  previewValues,
);

const runResults = ref([]);

const {
  notify,
} = useDialogs();

const runScript = async () => {
  if (model.value.inputs.length && (!isRunModalVisible.value || !isValid.value)) {
    isRunModalVisible.value = true;
    return;
  }

  isRunning.value = true;
  try {
    const { data: { executeAutomationScript } } = await runScriptMutation({
      script: model.value.script,
      inputs: previewValues.value,
    });
    runResults.value = [executeAutomationScript, ...runResults.value];
    editorTabIndex.value = 1;
    isRunModalVisible.value = false;
  } catch (e) {
    console.error(e, e.message);
    notify({
      title: 'Failed to run script',
      message: e.message,
      variant: 'warning',
    });
  } finally {
    isRunning.value = false;
  }
};
</script>
