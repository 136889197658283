<template>
  <UiCard>
    <div
      class="
        flex
        justify-between
        items-center
        px-4
        sm:px-6
        border-b
        border-gray-200
        dark:border-gray-800
      "
      :class="{'py-3': !isCompact, 'py-2': isCompact}"
    >
      <div class="flex items-center space-x-2">
        <WalletProfile
          :pic-size="7"
          :wallet="item.wallet"
        />

        <UiBadge class="text-xs capitalize">
          {{ type }}
        </UiBadge>

        <UiBadge
          v-if="item.tokenTransfers.length > 1"
          class="text-xs"
        >
          {{ item.tokenTransfers.length }} {{ pluralize('token', item.tokenTransfers.length) }}
        </UiBadge>
      </div>

      <div>
        <UiLink
          class="text-sm text-gray-600 dark:text-gray-400"
          :href="`https://etherscan.io/tx/${item.transaction.id}`"
          target="_blank"
        >
          {{ moment(item.transaction.blockTimestamp).from() }}
        </UiLink>
      </div>
    </div>

    <div
      :class="{'py-3': isCompact, 'py-5': !isCompact}"
      class=" px-5"
    >
      <div class="flex justify-between">
        <div
          class="flex"
          :class="{'flex-col space-y-5': !isCompact, 'items-center justify-between flex-1': isCompact}"
        >
          <div class="flex items-center space-x-3 text-sm font-medium">
            <CollectionListItem
              class="whitespace-nowrap"
              :pic-size="10"
              :collection="item.collection"
              no-overlay
            />
          </div>

          <div class="flex space-x-4">
            <div>
              <div class="text-xs text-gray-500 dark:text-gray-400">
                Price
              </div>

              <div class="flex items-center h-10">
                <CurrencyDisplay :value="eventValue" />
              </div>
            </div>

            <div>
              <div class="text-xs text-gray-500 dark:text-gray-400">
                Gas
              </div>

              <div class="flex items-center h-10">
                <CurrencyDisplay :value="transaction.gas_in_eth" />
              </div>
            </div>

            <div v-if="counterparty">
              <div class="text-xs text-gray-500 dark:text-gray-400">
                {{ counterparty.type }}
              </div>

              <div class="flex items-center h-10">
                <WalletProfile
                  :pic-size="6"
                  :wallet="counterparty.wallet"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isCompact">
          <div class="overflow-hidden md:overflow-visible md:relative w-24 h-24">
            <div
              v-for="(tokenTransfer, index) in item.tokenTransfers.slice(0, 5)"
              :key="tokenTransfer.id"
              class="md:absolute"
            >
              <div
                v-if="tokenTransfer.token"
                class="overflow-hidden w-24 rounded-lg shadow-lg"
                :class="{'hidden md:block': index > 0}"
              >
                <TokenImage
                  :url="tokenTransfer.token.previewImageUrl"
                  :token="tokenTransfer.token"
                  :fallback="tokenTransfer.collection.imageUrl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </UiCard>
</template>

<script>
import { defineComponent } from 'vue';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import UiCard from './ui/UiCard.vue';
import WalletProfile from './wallets/WalletProfile.vue';
import UiLink from './ui/UiLink.vue';
import TokenImage from './TokenImage.vue';
import UiBadge from './ui/UiBadge.vue';
import CurrencyDisplay from './CurrencyDisplay.vue';
import CollectionListItem from './collections/CollectionListItem.vue';

// const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";
export default defineComponent({
  components: {
    UiCard,
    WalletProfile,
    UiLink,
    TokenImage,
    UiBadge,
    CurrencyDisplay,
    CollectionListItem,
  },
  props: ['item', 'isCompact'],
  setup() {
    return {
      moment,
      pluralize,
    };
  },
  computed: {
    type() {
      return this.item.type;
    },
    verb() {
      return '';
    },
    transaction() {
      return this.item.transaction;
    },
    counterparty() {
      switch (this.type) {
        case 'mint':
          return null;
        case 'burn':
          return null;
        case 'purchase':
          return { wallet: this.item.tokenTransfers[0].from, type: 'Seller' };
        case 'sale':
          return { wallet: this.item.tokenTransfers[0].to, type: 'Buyer' };
        case 'transfer':
          return { wallet: this.item.tokenTransfers[0].to.id === this.item.wallet.id ? this.item.tokenTransfers[0].from : this.item.tokenTransfers[0].to, type: this.item.tokenTransfers[0].to.id === this.item.wallet.id ? 'Sender' : 'Recipient' };
        default:
          return null;
      }
    },
    eventValue() {
      switch (this.type) {
        case 'sale':
        case 'purchase':
          return this.item.tokenTransfers.reduce((total, item) => total + item.sale.price, 0);
        default:
          console.error(`Unsupported type: ${this.type}`);
      }

      return this.item.transaction.value;
    },
  },
});
</script>
