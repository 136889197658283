<template>
  <div class="mb-3">
    <label
      class="flex gap-1 items-center text-sm font-medium text-gray-700 dark:text-gray-400"
      :for="id"
    >
      <slot
        name="label"
        :label="label"
      >{{ label }}</slot>

      <div
        v-if="hint"
        v-tooltip="hint"
      >
        <i class="text-gray-400 fad fa-question-circle" />
      </div>

      <div
        v-if="errorMessage !== false"
        v-tooltip="errorMessage"
      >
        <i
          v-if="errorMessage"
          class="text-yellow-500 fad fa-exclamation-circle"
        />

      </div>

      <slot name="after-label" />
    </label>

    <div class="mt-1">
      <slot :id="id" />
    </div>

    <slot name="after" />
  </div>
</template>

<script>

export default {
  name: 'UiInputGroup',
  // props: ['label', 'errorMessage', 'hint'],
  props: {
    label: null,
    errorMessage: {
      type: Boolean,
      default: false,
    },
    hint: null,
  },
  data() {
    return {
      id: `ui-input-group-${Math.floor(Date.now() * Math.random())}`,
    };
  },
};
</script>

<style scoped>

</style>
