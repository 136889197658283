<template>
  <UiCard>
    <UiCardBody class="p-3 border-b border-black">
      <UiButton @click="() => openCreateAlert()">
        <i class="mr-2 far fa-plus" />
        Create Alert
      </UiButton>
    </UiCardBody>

    <div v-if="loading">
      <div class="flex justify-center items-center py-6 space-x-3">
        <LoadingIndicator
          class="text-indigo-500"
          :size="24"
        />

        <div class="font-medium">
          Loading
        </div>
      </div>
    </div>

    <ul
      v-if="alerts && alerts.length > 0"
      class="divide-y divide-gray-200 dark:divide-black "
      @click.stop
    >
      <li
        v-for="item in alerts"
        :key="item.id"
      >
        <div
          class="
            py-2
            dark:text-white
            px-6 hover:bg-gray-800
            cursor-pointer

          "
          @click="() => openSingleAlert(item)"
        >
          <div class="flex text-sm font-medium">
            <div>{{ item.name }}</div>

            <div
              v-if="item.status ==='ERROR'"
              class="flex items-center px-1 ml-2 text-xs font-medium leading-none text-red-500 bg-red-500 bg-opacity-50 rounded"
            >
              Error
            </div>
          </div>

          <div class="flex space-x-1 text-xs text-gray-400">
            <div><i :class="itemIcon(item.type)" /></div>

            <div>·</div>

            <div>Created {{ moment(item.createdAt).from() }}</div>
          </div>
        </div>
      </li>
    </ul>

    <div
      v-if="alerts && alerts.length === 0"
      class="py-3"
    >
      <div class="font-medium text-center text-gray-600 dark:text-gray-400">
        You have no Alerts yet
      </div>
    </div>

    <UiModal v-model="isCreateAlertOpen">
      <CreateAlert @dismiss="isCreateAlertOpen = false" />
    </UiModal>

    <UiModal v-model="isSingleAlertOpen">
      <SingleAlert :alert="singleAlert" />
    </UiModal>
  </UiCard>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import moment from 'moment-timezone';
import userAlertsQuery from '@/graphql/alert/queries/userAlerts.query.gql';
import UiModal from '@/components/ui/UiModal.vue';
import UiButton from '../ui/UiButton.vue';
import CreateAlert from './CreateAlert.vue';
import SingleAlert from './SingleAlert.vue';
import UiCard from '../ui/UiCard.vue';
import LoadingIndicator from '../LoadingIndicator.vue';
import UiCardBody from '../ui/UiCardBody.vue';

const ALERT_TYPE_ICONS = {
  WALLET_ACTIVITY: 'fas fa-wallet',
  FEED_ACTIVITY: 'far fa-newspaper',
  FLOOR_PRICE: 'far fa-usd-square',
};

export default defineComponent({
  components: {
    UiModal,
    UiButton,
    CreateAlert,
    SingleAlert,
    UiCard,
    LoadingIndicator,
    UiCardBody,
  },

  setup() {
    const isCreateAlertOpen = ref(false);
    const isSingleAlertOpen = ref(false);
    const singleAlert = ref(null);
    const { result, loading } = useQuery(userAlertsQuery);
    const alerts = computed(() => result.value?.alerts);

    function openCreateAlert() {
      isCreateAlertOpen.value = true;
    }

    function openSingleAlert(item) {
      singleAlert.value = item;
      isSingleAlertOpen.value = true;
    }

    return {
      alerts,
      moment,
      loading,
      isCreateAlertOpen,
      isSingleAlertOpen,
      openCreateAlert,
      openSingleAlert,
      singleAlert,
    };
  },

  computed: {
    itemIcon() {
      return (key) => ALERT_TYPE_ICONS[key];
    },
  },
});
</script>
