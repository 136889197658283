<template>
  <UiCard class="flex flex-col h-full">
    <div
      v-if="showCollection !== false"
      class="py-2 px-3"
    >
      <CollectionListItem
        v-if="!isLoading"
        :collection="collection"
        :pic-size="6"
        :mini="true"
      >
        <template #context>
          <slot name="collection-context" />
        </template>
      </CollectionListItem>

      <div
        v-else
        class="flex gap-3 items-center"
      >
        <div class="w-6 h-6 bg-gray-800 rounded-full animate-pulse" />

        <div class="w-16 h-3 bg-gray-800 rounded animate-pulse" />
      </div>
    </div>

    <div
      class="relative cursor-pointer"
      @click="$emit('showDetails', false)"
    >
      <TokenImage
        v-if="!isLoading"
        class="w-full"
        :token="item"
        :fallback="collection?.imageUrl"
        :url="item?.previewImageUrl"
        clip
      />

      <div
        v-else
        class="w-full dark:bg-gray-800 animate-pulse aspect-w-1 aspect-h-1"
      />

      <div
        v-if="!isLoading"
        class="flex absolute right-0 bottom-0 left-0 justify-between p-2 space-x-1 bg-gray-850 bg-opacity-90 backdrop-filter backdrop-blur-sm"
      >
        <div>
          <UiBadge
            v-if="item.price"
            v-tooltip="'Click to quick buy'"
            variant="green"
            class="text-sm font-medium whitespace-nowrap"
            @click.stop.prevent="$emit('showDetails', true)"
          >
            <i
              class="mr-2 far fa-shopping-cart"
            />
            {{ formatCurrency(item.price) }}
          </UiBadge>
        </div>

        <div>
          <RarityRank
            v-if="item.rarityScore && traits.length > 0"
            :rank="item.rarityRank"
            :supply="collection.totalSupply"
            :text-mode="true"
            class="px-2 text-sm"
          />
        </div>
      </div>
    </div>

    <div class="flex-1 p-3">
      <div class="flex gap-2 items-center mb-3 text-sm">
        <div
          v-if="!isLoading"
          class=" font-medium dark:text-white"
        >
          {{ tokenName }}
        </div>

        <div
          v-if="isLoading"
          class="w-1/2 h-4 dark:bg-gray-800 rounded animate-pulse"
        />

        <div
          v-if="item?.isReported"
          v-tooltip="'Flagged on Opensea'"
        >
          <i class="text-red-500 fad fa-exclamation-triangle" />
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <div
          v-if="!isLoading && hideWallet === false"
          class="mb-3 dark:text-gray-100"
        >
          <WalletProfile
            v-if="item.owner"
            no-overlay
            :pic-size="6"
            :wallet="item.owner"
          />
        </div>

        <div v-if="!isLoading && traits.length > 0">
          <div class="text-sm leading-none text-gray-300">
            Rarest Trait
          </div>

          <ul class="mt-1 text-xs divide-y">
            <li
              v-for="(trait, index) in traits"
              :key="index"
              class="flex flex-col justify-between py-1 dark:border-gray-500"
            >
              <div class="font-medium">
                {{ trait.attribute.name }}
              </div>

              <div>
                {{ trait.attributeValue.value }}
                <span
                  class="text-gray-600 dark:text-gray-400"
                >({{
                  formatPercent(trait.attributeValue.tokenCount / collection.totalSupply)
                }})</span>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="!isLoading && item.lastSale">
          <div class="text-sm text-gray-300">
            Last Sale
          </div>

          <div class="mt-1">
            <CurrencyDisplay :value="item.lastSale.price" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isLoading"
      class="flex justify-between p-3 mt-3"
    >
      <TokenLinks
        :collection="collection"
        :token-id="item.tokenId"
        mini
      />
    </div>
  </UiCard>
</template>

<script>
import { defineComponent } from 'vue';
import { formatCurrency, formatPercent } from '@/utils/filters';
import UiCard from '@/components/ui/UiCard.vue';
import WalletProfile from '@/components/wallets/WalletProfile.vue';
import UiBadge from '../ui/UiBadge.vue';
import TokenImage from '../TokenImage.vue';
import RarityRank from '../RarityRank.vue';
import CollectionListItem from './CollectionListItem.vue';
import TokenLinks from '../TokenLinks.vue';
import CurrencyDisplay from '../CurrencyDisplay.vue';

export default defineComponent({
  name: 'GridListItem',
  components: {
    WalletProfile,
    UiCard,
    UiBadge,
    TokenImage,
    RarityRank,
    CollectionListItem,
    TokenLinks,
    CurrencyDisplay,
  },
  props: {
    item: null,
    collection: null,
    showCollection: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hideWallet: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['showDetails'],

  setup() {
    return {
      formatPercent,
      formatCurrency,
    };
  },

  computed: {
    itemImage() {
      if (this.item?.metadata?.image_data) {
        return (
          `data:image/svg+xml;base64,${btoa(this.item?.metadata?.image_data)}`
        );
      }
      let imageUrl = null;
      if (this.item.image) {
        imageUrl = this.item.image;
      }

      if (!imageUrl) {
        imageUrl = this.collection.imageUrl;
      }

      const ipfsBase = 'https://ipfs.compass.art/ipfs/';

      if (imageUrl && imageUrl.indexOf('ipfs://') === 0) {
        imageUrl = imageUrl.replace('ipfs://', ipfsBase);
      }

      return imageUrl?.replace('https://gateway.pinata.cloud/ipfs/', ipfsBase);
    },
    traits() {
      const attributes = JSON.parse(JSON.stringify(this.item.attributes));
      return attributes
        .sort((a, b) => a.attributeValue.tokenCount - b.attributeValue.tokenCount)
        .slice(0, 1);
    },
    tokenName() {
      const { tokenId, name } = this.item;

      if (!name) {
        return `#${tokenId}`;
      }

      if (name.indexOf(tokenId) !== -1) {
        return name;
      }

      return `${name}${tokenId.length < 7 ? ` (${tokenId})` : ''}`;
    },
  },
});
</script>

<style>

</style>
