<template>
  <div class="grid grid-cols-12 gap-6 mx-auto max-w-screen-2xl main-container">
    <div class="col-span-3">
      <UiItemListCard
        v-model="sharedOptions.traitName"
        track-by="name"
        card-title="Types"
        :items="displayedTraits"
      >
        <template #item-slot="{item}">
          <div class="flex justify-between items-center w-full">
            <div>
              <i
                class="mr-2 fad fa-folder"
                :class="{'text-indigo-500': sharedOptions.traitName === item.name}"
              />
              {{ item.name }}
            </div>

            <div>
              <UiBadge variant="indigo">
                {{ item.traitCount }}
              </UiBadge>
            </div>
          </div>
        </template>
      </UiItemListCard>
    </div>

    <div class="col-span-9">
      <UiCard>
        <UiCardBody>
          <div class="flex justify-between">
            <PeriodSelector
              v-model="period"
              :periods="['1d', '7d', '30d']"
            />
            <!-- Sort -->
            <UiSelectCustom
              v-model="sharedOptions.orderBy"
              :options="[
                {
                  id: 'value',
                  name: 'Name',
                },
                {
                  id: 'tokenCount',
                  name: 'Token count',
                },
                {
                  id: 'floorPrice',
                  name: 'Floor price',
                },
                {
                  id: 'topOffer',
                  name: 'Top offer',
                },
                {
                  id: 'saleCount',
                  name: 'Sales',
                },
                {
                  id: 'avgPrice',
                  name: 'Avg. price',
                },
                {
                  id: 'lastSaleTimestamp',
                  name: 'Last sale',
                },
              ]"
            />
          </div>
        </UiCardBody>

        <UiTable
          v-model:sort-by="sharedOptions.orderBy"
          v-model:sort-desc="sharedOptions.orderDesc"
          :skeletons-rows="20"
          :show-skeleton="loadingMore"
          :is-loading="(isCollectionTraitsLoading || isTraitDetailsLoading) && !loadingMore"
          no-sticky
          :sortable="[
            'floorPrice',
            'topOffer',
            'saleCount',
            'avgPrice',
            'lastSaleTimestamp',
          ]"
          :fields="[
            'value',
            'floorPrice',
            'topOffer',
            'saleCount',
            'avgPrice',
            'lastSaleTimestamp',
            'actions'
          ]"
          :items="tableItems"
        >
          <template #cell(value)="{item}">
            <RouterLink
              :to="{
                name: routesNames.collectionItems,
                query: { filters: getEncodedFilters([{key: 'attributeValueId', type: 'category', value: [item.id]}]) },
              }"
            >
              <div class="flex gap-3">
                <ul
                  class="grid overflow-hidden w-16 h-16 rounded-xl"
                  :class="`grid-cols-${Math.min(2, item.previewTokens.length)}`"
                >
                  <li
                    v-for="token in item.previewTokens"
                    :key="token.id"
                  >
                    <img
                      :src="token.previewImageUrl"
                      :class="`w-${16/Math.min(2, item.previewTokens.length)} h-${16/Math.min(2, item.previewTokens.length)}`"
                    >
                  </li>
                </ul>

                <div class="flex flex-col justify-between h-16">
                  <div class="text-lg leading-tight">
                    {{ item.value }}
                  </div>

                  <div class="text-gray-400">
                    <div class="text-sm">
                      {{ item.tokenCount }} tokens
                    </div>

                    <UiProgressBar
                      :value="item.tokenCount"
                      :max="traitMaxValue('tokenCount')"
                      class="mt-1 w-20"
                    />
                  </div>
                </div>
              </div>
            </RouterLink>
          </template>

          <template #cell(floorPrice)="{item}">
            <CurrencyDisplay :value="item.floorPrice" />

            <UiProgressBar
              :value="item.floorPrice"
              :max="traitMaxValue('floorPrice')"
              class="mt-2 w-20"
            />
          </template>

          <template #cell(topOffer)="{item}">
            <CurrencyDisplay :value="item.topOffer" />

            <UiProgressBar
              :value="item.topOffer"
              :max="traitMaxValue('topOffer')"
              class="mt-2 w-20"
            />
          </template>

          <template #cell(saleCount)="{item}">
            <div>{{ formatNumber(item.saleCount) }}</div>

            <UiProgressBar
              :value="item.saleCount"
              :max="traitMaxValue('saleCount')"
              class="mt-2 w-20"
            />
          </template>

          <template #head(saleCount)>
            Sales
          </template>

          <template #head(avgPrice)>
            Avg. Price
          </template>

          <template #cell(avgPrice)="{item}">
            <OverlayComponent :width="300">
              <template #overlay-content>
                <div class="flex gap-3 items-center text-gray-100">
                  <UiInputGroup label="Min price">
                    <template #after-label>
                      <button
                        v-tooltip="'Sort'"
                        @click="() => {sharedOptions.orderBy = 'minPrice'; sharedOptions.orderDesc = !sharedOptions.orderDesc}"
                      >
                        <!-- Sort by min price -->
                        <div v-if="sharedOptions.orderBy === 'minPrice'">
                          <i
                            v-if="sharedOptions.orderDesc === false"
                            class="fad fa-sort-up"
                          />

                          <i
                            v-else
                            class="fad fa-sort-down"
                          />
                        </div>

                        <div v-else>
                          <i

                            class="fad fa-sort"
                          />
                        </div>
                      </button>
                    </template>

                    <div>{{ formatNumber(item.minPrice) }}</div>

                    <UiProgressBar
                      :value="item.minPrice"
                      :max="traitMaxValue('minPrice')"
                      class="mt-2 w-20"
                    />
                  </UiInputGroup>

                  <UiInputGroup label="Max price">
                    <template #after-label>
                      <button
                        v-tooltip="'Sort'"
                        @click="() => {sharedOptions.orderBy = 'maxPrice'; sharedOptions.orderDesc = !sharedOptions.orderDesc}"
                      >
                        <!-- Sort by min price -->
                        <div v-if="sharedOptions.orderBy === 'maxPrice'">
                          <i
                            v-if="sharedOptions.orderDesc === false"
                            class="fad fa-sort-up"
                          />

                          <i
                            v-else
                            class="fad fa-sort-down"
                          />
                        </div>

                        <div v-else>
                          <i

                            class="fad fa-sort"
                          />
                        </div>
                      </button>
                    </template>

                    <div>{{ formatNumber(item.maxPrice) }}</div>

                    <UiProgressBar
                      :value="item.maxPrice"
                      :max="traitMaxValue('maxPrice')"
                      class="mt-2 w-20"
                    />
                  </UiInputGroup>
                </div>
              </template>

              <CurrencyDisplay :value="item.avgPrice" />

              <UiProgressBar
                :value="item.avgPrice"
                :max="traitMaxValue('avgPrice')"
                class="mt-2 w-20"
              />
            </OverlayComponent>
          </template>

          <template #head(lastSaleTimestamp)>
            Last sale
          </template>

          <template #cell(lastSaleTimestamp)="{item}">
            <Timestamp
              v-if="item.lastSaleTimestamp"
              :timestamp="item.lastSaleTimestamp"
            />
          </template>

          <template #cell(actions)="{item}">
            <div class="flex justify-end">
              <CreateAutomationForInputs
                :available-inputs="['collection', 'tokenAttribute']"
                :input-values="{collection: contractAddress, tokenAttribute: item.id}"
              />
            </div>
          </template>
        </UiTable>
      </UiCard>

      <IntersectionObserver
        v-if="!loadingMore && hasMore"
        @isShow="loadMore"
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import collectionTraitsQuery from '@/graphql/collection/queries/collectionTraits.query.gql';
import traitDetailsQuery from '@/graphql/collection/queries/traitDetails.query.gql';
import { useRoute } from 'vue-router';
import routesNames from '@/router/routesNames';

import {
  formatNumber,
} from '@/utils/filters';
import UiTable from '@/components/ui/UiTable.vue';
import usePaginatedQuery from '@/composition/pagination/usePaginatedQuery';
import { useRouteQueryFilters } from '@/composition/filters/useRouteQueryFilters';
import UiCard from '../ui/UiCard.vue';
import UiBadge from '../ui/UiBadge.vue';
import UiProgressBar from '../UiProgressBar.vue';
import CurrencyDisplay from '../CurrencyDisplay.vue';
import Timestamp from '../Timestamp.vue';
import OverlayComponent from '../OverlayComponent.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import PeriodSelector from '../PeriodSelector.vue';
import IntersectionObserver from '../IntersectionObserver.vue';
import CreateAutomationForInputs from '../automation/CreateAutomationForInputs.vue';
import UiItemListCard from '../ui/UiItemListCard.vue';
import UiSelectCustom from '../ui/UiSelectCustom.vue';

const route = useRoute();

const contractAddress = computed(() => route.params.contractAddress);

const { data: sharedOptions } = useRouteQueryFilters({
  traitName: {
    type: String,
  },
  orderBy: {
    type: String,
    default: 'floorPrice',
  },
  orderDesc: {
    type: Boolean,
    default: true,
  },
});

const {
  result: collectionTraitsResult,
  loading: isCollectionTraitsLoading,
  onResult: onCollectionTraitsResult,
} = useQuery(collectionTraitsQuery, () => ({ address: contractAddress.value }));
const traits = computed(() => collectionTraitsResult.value?.collection.traits);

const currentTrait = computed(() => {
  if (!traits.value) {
    return null;
  }

  return traits.value.find(({ name }) => name === sharedOptions.traitName);
});

onCollectionTraitsResult((res) => {
  if (sharedOptions.traitName) {
    return;
  }

  sharedOptions.traitName = res.data.collection.traits[0].name;
});

const period = ref('7d');

const {
  result: traitDetailsResult,
  loading: isTraitDetailsLoading,
  hasMore,
  loadMore,
  loadingMore,
} = usePaginatedQuery(
  {
    take: 20,
    hasMoreCheck: (data) => data.collection.traitTypes[0].traits.length === 20,
    mergeResults: (prev, next) => ({
      collection: {
        ...prev.collection,
        traitTypes: [
          {
            ...prev.collection.traitTypes[0],
            traits: [
              ...prev.collection.traitTypes[0].traits,
              ...next.collection.traitTypes[0].traits,
            ],
          },
        ],
      },
    }),
  },
  traitDetailsQuery,
  () => ({
    address: contractAddress.value,
    attributeId: currentTrait.value?.id,
    orderBy: sharedOptions.orderBy,
    orderDesc: sharedOptions.orderDesc,
    period: period.value,
  }),
  () => ({
    enabled: Boolean(currentTrait.value),
  }),
);
const traitData = computed(() => traitDetailsResult.value?.collection?.traitTypes?.[0].traits);

const displayedTraits = computed(() => {
  if (!traits.value) {
    return [];
  }

  return traits.value;
});

const tableItems = computed(() => traitData.value || []);

const traitMaxValue = computed(() => (key) => Math.max(...tableItems.value.map((trait) => trait[key])));

const getEncodedFilters = (filters) => btoa(JSON.stringify(filters));

</script>

<style scoped>
</style>
