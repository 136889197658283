<template>
  <UiModal
    v-for="(item, index) in dialogs"
    :key="index"
    :model-value="true"
    bg-blur
    @update:modelValue="$event ? () => {} : item.onCancelled"
  >
    <UiModalHeader
      v-if="item.title"
      :title="item.title"
      @dismiss="item.onCancelled"
    />

    <div class="p-3 text-gray-300 whitespace-pre-wrap">
      {{ item.message }}
    </div>

    <UiModalFooter>
      <div class="flex gap-2">
        <UiButton
          variant="white"
          size="sm"
          @click="item.onCancelled"
        >
          {{ item.cancelText || 'Cancel' }}
        </UiButton>

        <UiButton
          :variant="item.variant || 'primary'"
          size="sm"
          @click="item.onConfirmed"
        >
          {{ item.confirmText || 'OK' }}
        </UiButton>
      </div>
    </UiModalFooter>
  </UiModal>

  <UiModal
    v-for="(item, index) in asks"
    :key="index"
    :model-value="true"
    bg-blur
    @update:modelValue="$event ? () => {} : item.onCancelled"
  >
    <UiModalHeader
      v-if="item.title"
      :title="item.title"
      @dismiss="item.onCancelled"
    />

    <UiInputGroup
      class="p-3 text-gray-300"
      :label="item.message"
    >
      <UiInput
        v-model="item.value"
        :autofocus="true"
      />
    </UiInputGroup>

    <UiModalFooter>
      <div class="flex gap-2">
        <UiButton
          variant="white"
          size="sm"
          @click="item.onCancelled"
        >
          {{ item.cancelText || 'Cancel' }}
        </UiButton>

        <UiButton
          :variant="item.variant || 'primary'"
          size="sm"
          @click="item.onConfirmed"
        >
          {{ item.confirmText || 'OK' }}
        </UiButton>
      </div>
    </UiModalFooter>
  </UiModal>

  <Teleport to="body">
    <TransitionGroup
      tag="div"
      name="notification"
      class="flex fixed bottom-14 left-1/2 z-1000 flex-col gap-3 items-center max-w-screen-sm transform -translate-x-1/2"
    >
      <div
        v-for="(item, index) in notifications"
        :key="index"
      >
        <div
          class="py-2 px-3 rounded shadow-4xl"
          :class="{
            'text-indigo-900 bg-indigo-500':item.variant === 'primary' || !item.variant,
            'text-yellow-900 bg-yellow-500':item.variant === 'warning',
            'text-red-900 bg-red-500':item.variant === 'danger',
          }"
          :style="{opacity: (index + 1) / notifications.length}"
        >
          <div
            v-if="item.title"
            class="font-medium"
          >
            {{ item.title }}
          </div>

          <div
            v-if="item.message"
            class="text-sm"
          >
            {{ item.message }}
          </div>
        </div>
      </div>
    </TransitionGroup>
  </Teleport>
</template>

<script setup>
import { ref } from 'vue';
import UiButton from './UiButton.vue';
import UiModal from './UiModal.vue';
import UiModalFooter from './UiModalFooter.vue';
import useDialogs from './composition/use-dialogs';
import UiModalHeader from './UiModalHeader.vue';
import UiInput from './UiInput.vue';
import UiInputGroup from './UiInputGroup.vue';

const close = () => {};

const { dialogs, asks, notifications } = useDialogs();
</script>

<style>
.notification-enter-active,
.notification-leave-active {
  transition: all 0.3s ease;
  transform: translateY(0) scale(1);
}
.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translateY(50%) scale(0.98);
}
</style>
