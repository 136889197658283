import { ref, computed } from 'vue';

const alerts = ref([]);
const $resolve = ref(null);

export function useUiDialog() {
  function addModel(title, options = {}) {
    const model = {
      id: `alert-${Math.floor(Date.now() * Math.random())}`,
      title,
      ...options,
    };

    alerts.value.push(model);
    return model.id;
  }

  function removeModel(id) {
    alerts.value = alerts.value.filter((item) => item.id !== id);
  }

  function info(title, options = {}) {
    const id = addModel(title, options);

    setTimeout(() => {
      removeModel(id);
    }, 5000);
  }

  function error(title) {
    info(title, {
      icon: 'fad fa-exclamation-circle text-red-500 text-2xl',
    });
  }

  function answer(val, id) {
    $resolve.value(val);
    $resolve.value = null;
    removeModel(id);
  }

  function ask(resolver, title, options = {}) {
    const $options = { ...options, isQuestion: true };
    addModel(title, $options);
    $resolve.value = resolver;
  }

  const isResolver = computed(() => $resolve.value !== null);

  return {
    alerts,
    info,
    error,
    ask,
    answer,
    isResolver,
  };
}
