<template>
  <div
    v-accordion-overflow
    class="overflow-x-hidden"
  >
    <div
      class="px-8 -mx-8 collection-description"
      :class="{'collection-description--overflows': overflows && !showMore, 'collection-description--visible': showMore}"
    >
      <Markdown
        class="collection-description__content"
        :source="description || ''"
      />

      <div class="description-shadow" />
    </div>

    <div class="flex justify-center mt-2 font-medium">
      <UiLink
        v-if="overflows"
        @click="showMore = !showMore"
      >
        <span v-if="showMore">Show less</span>

        <span v-else>Show more</span>
      </UiLink>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Markdown from 'vue3-markdown-it';
import UiLink from '@/components/ui/UiLink.vue';

export default defineComponent({
  name: 'CollectionDescription',
  directives: {
    accordionOverflow: {
      mounted(el, bindings) {
        const content = el.querySelector('.collection-description__content');
        const wrapper = el.querySelector('.collection-description');
        bindings.instance.setOverflows(wrapper.offsetHeight < content.offsetHeight);
      },
    },
  },
  components: {
    UiLink,
    Markdown,
  },
  props: ['description'],
  data() {
    return {
      overflows: false,
      showMore: false,
    };
  },
  methods: {
    setOverflows(value) {
      this.overflows = value;
    },
  },
});
</script>

<style scoped>
  .collection-description {
    max-height: 4rem;
    overflow: hidden;
    position: relative;
  }
  .collection-description--overflows .description-shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(249,250,251,0) 0%, rgba(249,250,251,1) 100%);
    height: 1.5rem;
  }

    html.dark .collection-description--overflows .description-shadow {
      background: linear-gradient(180deg, rgba(14, 15, 14, 0) 0%, rgba(14, 15, 14, 1) 100%);
    }

  .collection-description--visible {
    max-height: initial;
  }
</style>
