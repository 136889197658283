<template>
  <UiCard
    class="flex flex-col w-full h-full"
    :slim="slim"
  >
    <template #title>
      <div class="flex gap-3 items-center">
        <div>Listings</div>

        <DelayedBadge :disabled="!collection.isPremiumRequired" />
      </div>
    </template>

    <template #title-right>
      <div class="flex gap-1">
        <UiDropdown right>
          <template #toggle="{ toggle }">
            <UiButton
              variant="white"
              size="xs"
              @click="toggle"
            >
              Price grouping: {{ dataGrouping }}
            </UiButton>
          </template>

          <template #body>
            <PremiumPlaceholder
              size="sm"
              action="change grouping"
            >
              <UiDropdownItem
                v-for="(item, index) in dataGroupings"
                :key="index"
                @click="dataGrouping = item"
              >
                {{ item }}
              </UiDropdownItem>
            </PremiumPlaceholder>
          </template>
        </UiDropdown>

        <UiDropdown right>
          <template #toggle="{toggle}">
            <button
              type="button"
              class="
                ml-2
                text-lg
                dark:text-gray-200 dark:hover:text-indigo-500
                transition-colors
              "
              @click="toggle"
            >
              <i class="fad fa-cog" />
            </button>
          </template>

          <template #body>
            <UiDropdownForm>
              <UiInputGroup label="Min price">
                <UiInput
                  v-model="minPrice"
                  placeholder="Min price"
                  type="number"
                />
              </UiInputGroup>

              <UiInputGroup label="Max price">
                <UiInput
                  v-model="maxPrice"
                  placeholder="Max price"
                  type="number"
                />
              </UiInputGroup>
            </UiDropdownForm>
          </template>
        </UiDropdown>
      </div>
    </template>

    <UiCardBody
      class="flex-1"
      :slim="slim"
    >
      <CollectionListingsHistorgram
        :collection="collection"
        :data-grouping="dataGrouping"
        :min-price="minPrice"
        :max-price="maxPrice"
      />
    </UiCardBody>
  </UiCard>
</template>

<script>
import { ref } from 'vue';
import UiDropdownForm from '@/components/ui/UiDropdownForm.vue';
import UiInputGroup from '@/components/ui/UiInputGroup.vue';
import UiInput from '@/components/ui/UiInput.vue';
import PremiumPlaceholder from '../PremiumPlaceholder.vue';
import UiButton from '../ui/UiButton.vue';
import UiCard from '../ui/UiCard.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import UiDropdown from '../ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from '../ui/UiDropdownItem.vue';
import CollectionListingsHistorgram from './CollectionListingsHistorgram.vue';
import DelayedBadge from '../DelayedBadge.vue';

export default {
  components: {
    UiCard,
    UiCardBody,
    UiButton,
    UiDropdown,
    CollectionListingsHistorgram,
    PremiumPlaceholder,
    UiDropdownItem,
    UiInput,
    UiDropdownForm,
    UiInputGroup,
    DelayedBadge,
  },

  props: ['collection', 'slim'],

  setup() {
    const minPrice = ref(null);
    const maxPrice = ref(null);

    return {
      minPrice,
      maxPrice,
    };
  },

  data() {
    return {
      dataGroupings: [0.001, 0.005, 0.01, 0.025, 0.05, 0.1, 0.25, 0.5, 1, 10],
      dataGrouping: 0.1,
    };
  },
};
</script>

<style>
</style>
