<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    v-if="alerts.length > 0"
    aria-live="assertive"
    class="flex fixed inset-y-14 inset-x-0 z-100 flex-col gap-3 justify-end sm:justify-start sm:items-end sm:p-6 py-6 px-4 pointer-events-none"
  >
    <TransitionGroup>
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <div
        v-for="(item) in alerts"
        :key="item.id"
        class="overflow-hidden w-full max-w-sm bg-gray-900 rounded-lg border border-gray-800 ring-1 ring-black ring-opacity-5 shadow-lg pointer-events-auto border-1"
      >
        <div class="p-4">
          <div class="flex items-start">
            <div
              v-if="item.icon"
              class="flex-shrink-0"
            >
              <div class="flex justify-center items-center w-6 h-6">
                <i :class="item.icon" />
              </div>
              <!-- <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" /> -->
            </div>

            <div class="flex flex-col flex-1 ml-3 w-0">
              <p class="text-sm font-medium text-gray-100">
                {{ item.title }}
              </p>

              <p
                v-if="item.description"
                class="mt-1 text-sm text-gray-400"
              >
                {{ item.description }}
              </p>

              <div
                v-if="isResolver && item.isQuestion"
                class="flex gap-2"
              >
                <button
                  class="inline-flex text-gray-400 hover:text-gray-500 bg-white rounded-md focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-none"
                  @click="() => answer(true, item.id)"
                >
                  <span class="font-medium">Yes</span>
                </button>

                <button
                  class="inline-flex text-gray-400 hover:text-gray-500 bg-white rounded-md focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-none"
                  @click="() => answer(false, item.id)"
                >
                  <span class="font-medium">No</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { useUiDialog } from './useUiDialog';

const {
  alerts,
  isResolver,
  answer,
} = useUiDialog();
</script>

<style scoped>

</style>
