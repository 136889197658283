<template>
  <div>
    <dt
      class="text-gray-500 dark:text-gray-400 truncate"
      :class="small ? 'text-xs' : 'text-sm sm:text-md'"
    >
      {{ title }}
      <i
        v-if="hint"
        v-tooltip="hint"
        class="far fa-info-circle"
      />
    </dt>

    <dd
      class="
        flex
        justify-between
        items-end
        space-x-3
        font-semibold
        text-gray-900
        dark:text-gray-100
        text-md
      "
      :class="small ? 'text-md mt-1' : 'text-xl mt-3'"
    >
      <PremiumPlaceholder :disabled="requiresPremium === false">
        <Transition
          v-if="!isLoading"
          mode="out-in"
          :name="value > previousValue ? 'metric-update-green' : 'metric-update-red'"
        >
          <slot
            :key="value"
            name="value"
            v-bind="{ value, displayValue }"
          >
            <span class="font-mono whitespace-nowrap">{{
              displayValue
            }}</span>
          </slot>
        </Transition>

        <div
          v-else-if="!small"
          class="w-24 h-5 bg-white bg-opacity-10 rounded animate-pulse"
        />

        <div
          v-else-if="small"
          class="my-1 w-16 h-4 bg-white bg-opacity-10 rounded animate-pulse"
        />

        <slot name="extra">
          <div
            v-if="shouldCompare && !isLoading"
            :class="{
              ' text-green-400 dark:text-green-500':
                percentChange > 0,
              ' text-red-400  dark:text-red-500':
                percentChange < 0,
            }"
            class="
              inline-flex
              flex-grow-0
              items-baseline
              md:mt-2
              lg:mt-0
              space-x-2
              text-sm
              font-medium
              rounded-full
            "
            :title="compareTo"
          >
            <i
              class="fas"
              :class="{
                'fa-long-arrow-up transform rotate-45': percentChange > 0,
                'fa-long-arrow-down transform -rotate-45': percentChange < 0,
              }"
            />

            <span class="hidden sm:inline">{{
              formatPercent(percentChange)
            }}</span>
          </div>
        </slot>

        <template #placeholder-content>
          <div
            class="flex gap-3 items-center cursor-pointer"
            @click="() => eventBus.$emit('auth:showPremiumModal', 'to unlock all metrics')"
          >
            <PremiumIcon size="sm" />
          </div>
        </template>

        <template #placeholder-content-guest>
          <div
            class="flex gap-3 items-center cursor-pointer"
            @click="() => eventBus.$emit('auth:signIn', 'to unlock all metrics')"
          >
            <PremiumIcon size="sm" />
          </div>
        </template>
      </PremiumPlaceholder>
    </dd>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { formatPercent } from '@/utils/filters';
import eventBus from '@/utils/event-bus';
import PremiumPlaceholder from './PremiumPlaceholder.vue';
import PremiumIcon from './PremiumIcon.vue';

export default defineComponent({
  name: 'MetricWidget',
  components: { PremiumPlaceholder, PremiumIcon },
  props: {
    title: null,
    value: null,
    formatter: null,
    formatterArgs: null,
    compareTo: null,
    isLoading: null,
    hint: null,
    small: null,
    requiresPremium: {
      default: false,
      type: Boolean,
    },
  },

  setup() {
    return {
      eventBus,
      formatPercent,
    };
  },
  data() {
    return {
      previousValue: null,
    };
  },
  computed: {
    displayValue() {
      if (!this.formatter) {
        return this.value;
      }

      if (typeof this.formatter === 'function') {
        return this.formatter(this.value);
      }

      return this.formatter(this.value, ...(this.formatterArgs || []));
    },
    shouldCompare() {
      return typeof this.compareTo !== 'undefined';
    },
    percentChange() {
      if (!this.shouldCompare) {
        return null;
      }

      return (this.value - this.compareTo) / this.compareTo;
    },
  },
  watch: {
    value: {
      handler() {
        this.$nextTick(() => {
          this.previousValue = this.value;
        });
      },
      immediate: true,
    },
  },
});
</script>
