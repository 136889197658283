<template>
  <UiModal
    v-model="isVisible"
  >
    <UiModalHeader
      title="Withdraw Funds"
      @dismiss="isVisible = false"
    />

    <div class="py-3 px-4">
      <div class="grid grid-cols-2 gap-3">
        <div>
          <UiInputGroup label="Type">
            <UiSelectCustom
              v-model="type"
              button-class="w-full text-left"
              :options="[
                { id: 'ETH', name: 'ETH' },
                { id: 'ERC20', name: 'ERC20' }
              ]"
            />
          </UiInputGroup>
        </div>

        <div>
          <UiInputGroup
            v-if="type === 'ERC20'"
            label="Contract"
          >
            <UiSelectCustom
              v-model="contract"
              button-class="w-full text-left"
              :options="[
                { name: 'Wrapped ETH', id: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' },
                { name: 'Blur Pool Token', id: '0x0000000000a39bb272e79075ade125fd351887ac' },
              ]"
            />
          </UiInputGroup>
        </div>
      </div>

      <UiInputGroup label="Amount">
        <UiInput v-model="amount" />

        <div
          class="flex gap-1 items-center text-sm text-gray-400 hover:text-gray-200 cursor-pointer"
          @click="amount = availableBalance"
        >
          Max: <span v-if="!isLoading">{{ availableBalance }}</span>

          <span
            v-if="isLoading"
            class="inline-block w-12 h-4 bg-gray-700 rounded animate-pulse"
          />
        </div>
      </UiInputGroup>

      <UiInputGroup label="Destination">
        <UiInput
          v-model="destination"
          :placeholder="privateKey.address"
        />
      </UiInputGroup>
    </div>

    <UiModalFooter>
      <UiButton
        :is-loading="isWithdrawing"
        @click="withdraw"
      >
        Withdraw
      </UiButton>
    </UiModalFooter>
  </UiModal>
</template>

<script setup>
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, toRefs, ref } from 'vue';
import UiButton from '../ui/UiButton.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiModal from '../ui/UiModal.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';
import UiSelectCustom from '../ui/UiSelectCustom.vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  privateKey: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const { modelValue, privateKey } = toRefs(props);

const isVisible = computed({
  get: () => modelValue.value,
  set: (value) => emit('update:modelValue', value),
});

const type = ref('ETH');
const amount = ref(0);
const contract = ref('');
const { result: ethBalance, loading: isLoadingEthBalance } = useQuery(gql`
    query GetBalance($id: String!) {
      wallet(where: {id: $id}) {
        balance: ethBalance
      }
    }
  `, () => ({
  id: privateKey.value.address,
}), () => ({
  enabled: isVisible.value,
}));

const { result: erc20Balance, loading: isLoadingErc20Balance } = useQuery(gql`
    query GetBalance($id: String!, $contract: String!) {
      wallet(where: {id: $id}) {
        balance: erc20Balance(contract: $contract)
      }
    }
  `, () => ({
  id: privateKey.value.address,
  contract: contract.value,
}), () => ({
  enabled: isVisible.value && type.value === 'ERC20' && contract.value?.length,
}));

const availableBalance = computed(() => {
  if (type.value === 'ETH') {
    return ethBalance.value?.wallet?.balance;
  }

  return erc20Balance.value?.wallet?.balance;
});

const isLoading = computed(() => isLoadingEthBalance.value || isLoadingErc20Balance.value);

const isWithdrawing = ref(false);

const destination = ref('');

const { mutate } = useMutation(gql`
mutation WithdrawFunds(
  $privateKeyId: Int!
  $type: String!
  $contract: String
  $amount: Float!
  $destination: String!
) {
  transferWalletFunds(
    privateKeyId: $privateKeyId
    type: $type
    contract: $contract
    amount: $amount
    destination: $destination
  )
}`);

const withdraw = () => {
  isWithdrawing.value = true;

  return mutate({
    privateKeyId: privateKey.value.id,
    type: type.value,
    contract: contract.value,
    amount: Number(amount.value),
    destination: destination.value,
  }).then(() => {
    isVisible.value = false;
  }).finally(() => {
    isWithdrawing.value = false;
  });
};
</script>
