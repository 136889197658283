<template>
  <UiInputRadio
    v-if="multiple === false"
    v-model="model"
    :options="radioOptions"
  />

  <div v-else>
    <UiMultiToggle
      v-model="model"
      :options="multiOptions"
    />
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import UiInputRadio from '../ui/UiInputRadio.vue';
import UiMultiToggle from '../ui/UiMultiToggle.vue';

const props = defineProps({
  modelValue: null,
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  multiple: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const { modelValue, options, multiple } = toRefs(props);
const radioOptions = computed(() => (options.value || []).map((item) => ({
  text: item.name,
  value: item.value,
})));

const model = computed({
  get() {
    return modelValue.value;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const multiOptions = computed(() => options.value.map((item) => ({
  key: item.value,
  name: item.name,
  defaultValue: true,
})));
</script>
