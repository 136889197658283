<template>
  <div>
    <UiInputRadio
      v-model="type"
      :options="radioOptions"
      class="mb-3"
    />

    <UiInput v-model="localValue" />
  </div>
</template>

<script setup>

import {
  toRefs, ref, computed, watch,
} from 'vue';
import UiInputRadio from '../ui/UiInputRadio.vue';
import UiInput from '../ui/UiInput.vue';

const props = defineProps(['modelValue', 'allowVariables']);
const {
  modelValue,
} = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const validate = (value) => value?.length > 0;

const type = ref(modelValue.value?.comparator || 'eq');
const localValue = ref(modelValue.value?.value || []);

watch(modelValue, (v) => {
  type.value = v.comparator;
  localValue.value = v.value;
}, { deep: true });

const radioOptions = computed(() => [
  {
    text: 'Includes',
    value: 'includes',
  },
  {
    text: 'Excludes',
    value: 'excludes',
  },
]);

watch([type, localValue], ([t, v]) => {
  emit('update:modelValue', { comparator: t, value: validate(v) ? v : null });
}, { deep: true });

</script>
