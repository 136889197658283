<template>
  <div
    v-if="breakdown"
    class="flex overflow-hidden w-full h-3 rounded-full cursor-pointer"
  >
    <div
      v-if="display.Dead?.value"
      v-tooltip="`No liquidity: ${formatCurrency(display.Dead.value)}`"
      class="h-full bg-red-500 bg-opacity-90 hover:bg-opacity-100 transition-colors"
      :style="{width: `${(display.Dead.value/total) * 100}%`}"
      @click="router.push({name: walletId ? routesNames.walletPortfolio : routesNames.walletWatchlist.portfolio, params: {address: walletId, slug: watchlistId}, query: {filters: encodeFilters([{ key: 'liquidity', value: ['Dead'], type: 'category' }])}})"
    />

    <div
      v-if="display.Low?.value"
      v-tooltip="`Low liquidity: ${formatCurrency(display.Low.value)}`"
      class="h-full bg-orange-500 bg-opacity-90 hover:bg-opacity-100 transition-colors"
      :style="{width: `${(display.Low.value/total) * 100}%`}"
      @click="router.push({name: walletId ? routesNames.walletPortfolio : routesNames.walletWatchlist.portfolio, params: {address: walletId, slug: watchlistId}, query: {filters: encodeFilters([{ key: 'liquidity', value: ['Low'], type: 'category' }])}})"
    />

    <div
      v-if="display.Medium?.value"
      v-tooltip="`Medium liquidity: ${formatCurrency(display.Medium.value)}`"
      class="h-full bg-yellow-500 bg-opacity-90 hover:bg-opacity-100 transition-colors"
      :style="{width: `${(display.Medium.value/total) * 100}%`}"
      @click="router.push({name: walletId ? routesNames.walletPortfolio : routesNames.walletWatchlist.portfolio, params: {address: walletId, slug: watchlistId}, query: {filters: encodeFilters([{ key: 'liquidity', value: ['Medium'], type: 'category' }])}})"
    />

    <div
      v-if="display.High?.value"
      v-tooltip="`High liquidity: ${formatCurrency(display.High.value)}`"
      class="h-full bg-green-500 bg-opacity-90 hover:bg-opacity-100 transition-colors"
      :style="{width: `${(display.High.value/total) * 100}%`}"
      @click="router.push({name: walletId ? routesNames.walletPortfolio : routesNames.walletWatchlist.portfolio, params: {address: walletId, slug: watchlistId}, query: {filters: encodeFilters([{ key: 'liquidity', value: ['High'], type: 'category' }])}})"
    />
  </div>
</template>

<script setup>
import { encodeFilters } from '@/composition/filters/useFiltersQuerySync';
import routesNames from '@/router/routesNames';
import { formatCurrency } from '@/utils/filters';
import { keyBy, sumBy } from 'lodash-es';
import { computed, toRefs } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps(['breakdown', 'walletId', 'watchlistId']);

const {
  breakdown,
  walletId,
  watchlistId,
} = toRefs(props);

const total = computed(() => sumBy(breakdown.value, 'value'));
const display = computed(() => keyBy(breakdown.value, 'liquidity'));

</script>
