<template>
  <div
    class="p-3 text-sm rounded"
    :class="classes"
  >
    <slot>
      <Markdown :source="text || ''" />
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Markdown from 'vue3-markdown-it';

export default defineComponent({
  components: {
    Markdown,
  },

  props: ['variant', 'text'],

  computed: {
    classes() {
      switch (this.variant) {
        case 'indigo':
          return 'bg-indigo-400 border-indigo-500 text-indigo-900 ';
        case 'yellow':
          return 'bg-yellow-400 border-yellow-500 text-yellow-900 ';
        case 'green':
          return 'bg-green-400 border-green-500 text-green-900 ';
        case 'red':
          return 'bg-red-500 border-red-500 text-red-500 bg-opacity-30';
        case 'blue':
          return 'bg-blue-400 border-blue-500 text-blue-900 ';
        default:
          return '';
      }
    },
  },
});
</script>

<style>

</style>
