<template>
  <div class="flex gap-6">
    <MetricWidget
      title="Floor"
      :value="collectionData?.listedFloor"
      :formatter="formatCurrency"
      :small="true"
      :is-loading="isLoading"
    />

    <MetricWidget
      title="Top Bid"
      :value="collectionData?.topOffer"
      :formatter="formatCurrency"
      :small="true"
      :is-loading="isLoading"
    />

    <MetricWidget
      title="Total Volume"
      :value="collectionData?.totalVolume"
      :formatter="formatCurrency"
      :small="true"
      :is-loading="isLoading"
    />
  </div>

  <div class="flex gap-3 justify-between items-center mt-2">
    <UiLink
      :to="{
        name: routesNames.collectionItems,
        params: {
          contractAddress: model.id,
        }
      }"
    >
      <UiBadge
        v-if="!isLoading && collectionData?.isRevealed"
        variant="indigo"
      >
        Revealed
      </UiBadge>

      <UiBadge
        v-else-if="!isLoading"
        variant="yellow"
      >
        Unrevealed
      </UiBadge>

      <div
        v-else
        class="w-20 h-5 bg-gray-700 rounded-full animate-pulse"
      />
    </UiLink>
  </div>

  <div
    v-if="isActive"
    class="flex justify-between items-center mt-3"
  >
    <div class="flex gap-x-2 items-center">
      <div>
        <AddToWatchlist
          :type="WATCHLIST_TYPES.COLLECTION"
          :model-id="model.id"
          button-size="xs"
          :inline-dropdown="true"
        />
      </div>

      <slot name="actions" />
    </div>

    <div>
      <CollectionLinks
        :collection="model"
        :mini="true"
        :inline-dropdown="true"
      />
    </div>
  </div>
</template>

<script setup>
import routesNames from '@/router/routesNames';
import { useQuery } from '@vue/apollo-composable';
import { computed, toRefs } from 'vue';
import gql from 'graphql-tag';
import { formatCurrency } from '@/utils/filters';
import { WATCHLIST_TYPES } from '@/constants';
import UiBadge from '../ui/UiBadge.vue';
import UiLink from '../ui/UiLink.vue';
import AddToWatchlist from '../watchlists/AddToWatchlist.vue';
import CollectionLinks from './CollectionLinks.vue';
import MetricWidget from '../MetricWidget.vue';
import LiquidityRank from '../LiquidityRank.vue';

const props = defineProps(['collection', 'isActive']);
const { collection: model, isActive } = toRefs(props);
const {
  result,
  loading: isLoading,
} = useQuery(
  gql`
    query GetCollectionIsRevealed($address: String) {
      collection(where: { id: $address }) {
        id
        listedFloor
        topOffer
        isRevealed
        totalVolume
        liquidityRank
      }
    }
  `,
  () => ({ address: model.value?.id }),
  () => ({ enabled: Boolean(isActive.value && model.value?.id) }),
);

const collectionData = computed(() => result.value?.collection);

</script>
