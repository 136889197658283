<template>
  <div
    v-if="horizontal === false"
    class="text-center"
    :class="{
      'py-8': size === 'md',
      'py-4': size === 'sm'
    }"
  >
    <div
      class="flex justify-center items-center mx-auto text-indigo-300 bg-indigo-900 rounded-full"
      :class="{
        'w-16 h-16': size === 'md',
        'w-12 h-12': size === 'sm'
      }"
    >
      <i

        :class="[icon, {
          'fa-2x': size === 'md',
          '': size === 'sm'
        }]"
      />
    </div>

    <div
      class=" font-light text-gray-300"
      :class="{
        'mt-6 text-2xl': size === 'md',
        'mt-3 text-lg': size === 'sm'
      }"
    >
      <slot>{{ content }}</slot>
    </div>

    <div
      v-if="$slots.cta"
      class="mt-6"
    >
      <slot name="cta" />
    </div>
  </div>

  <div
    v-if="horizontal !== false"
    class="flex justify-between items-center bg-gray-900 rounded-lg border border-gray-800"
    :class="{
      'p-8': size === 'md',
      'p-4': size === 'sm'
    }"
  >
    <div class="flex gap-3 items-center">
      <div
        class="flex justify-center items-center text-indigo-300 bg-indigo-900 rounded-full"
        :class="{
          'w-14 h-14': size === 'md',
          'w-10 h-10': size === 'sm'
        }"
      >
        <i

          :class="[icon, {
            'fa-2x': size === 'md',
            '': size === 'sm'
          }]"
        />
      </div>

      <div
        class=" font-light text-gray-300"
        :class="{
          'text-2xl': size === 'md',
          'text-lg': size === 'sm'
        }"
      >
        <slot>{{ content }}</slot>
      </div>
    </div>

    <div
      v-if="$slots.cta"
    >
      <slot name="cta" />
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    default: 'fad fa-empty-set',
  },
  content: {
    type: String,
    default: 'Nothing found',
  },
  size: {
    type: String,
    default: 'md',
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
});
const { icon, content } = toRefs(props);
</script>
