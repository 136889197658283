<template>
  <div
    v-if="actions.length > 0"
    class="mb-2"
  >
    <Draggable
      :model-value="actions"
      ghost-class="opacity-50"
      tag="ul"
      class="flex flex-col text-white automation-action-editor"
      :class="{'automation-action-editor--disabled': readonly !== false}"
      handle=".grip-icon"
      :item-key="(item) => item.id"
      :animation="150"
      group="automation-actions"
      :disabled="readonly !== false"
      @change="actions = $event"
    >
      <template #item="{element:item, index}">
        <li
          :key="index"
        >
          <UiCard v-if="!actionsByType[item.type]">
            Invalid action
          </UiCard>

          <Accordion
            v-if="actionsByType[item.type]"
            :id="item.id"
            v-model="currentlyOpenAccordion"
            v-tooltip="`ID: ${item.id}`"
            max-h="max-h-full"
            class="shadow-lg action-accordion"
            :class="{'action-accordion--open': currentlyOpenAccordion === item.id, 'opacity-50': item.isDisabled}"
            :disabled="item.isDisabled"
            no-transition
            no-scroll
            sticky-head
            @open="scrollStepIntoView(item.id)"
          >
            <template #name>
              <div
                class="flex relative gap-3 items-center grip-wrap"
              >
                <div class="absolute right-full grip-icon">
                  <i class="far fa-grip-vertical" />
                </div>

                <div

                  class="w-8 text-xl"
                  :class="{'text-indigo-500': !item.type.startsWith('custom-'), 'text-purple-500': item.type.startsWith('custom-')}"
                >
                  <i
                    :class="actionsByType[item.type].icon"
                  />
                </div>

                <div class="text-gray-300">
                  {{ item.name || actionsByType[item.type].name }}
                </div>

                <div>
                  <UiDropdown
                    v-if="actionsByType[item.type].version"
                    @click.stop
                  >
                    <template #toggle="{toggle}">
                      <UiButton
                        size="xs"
                        variant="white"
                        @click="toggle"
                      >
                        <div class="flex gap-1 items-center leading-none">
                          <div>v{{ actionsByType[item.type].version }}</div>

                          <div
                            v-tooltip="actionsByType[item.type].version === actionsByType[item.type].versions[0].version ? 'Latest version' : 'Previous version'"
                            class="w-2 h-2 rounded-full"
                            :class="actionsByType[item.type].version === actionsByType[item.type].versions[0].version ? 'bg-green-500' : 'bg-yellow-500'"
                          />
                        </div>
                      </UiButton>
                    </template>

                    <template #body>
                      <UiDropdownItem
                        v-for="i in actionsByType[item.type].versions"
                        :key="i.version"
                        @click="() => {
                          const v = item.type.split('@').at(-1);
                          item.type = item.type.replace(v, i.version);
                          item.id = item.id.replace(v, i.version);
                        }"
                      >
                        <div class="flex gap-2 items-center">
                          <div>v{{ i.version }}</div>

                          <div
                            v-tooltip="i.isLatest ? 'Latest version' : 'Previous version'"
                            class="w-2 h-2 rounded-full"
                            :class="i.isLatest ? 'bg-green-500' : 'bg-yellow-500'"
                          />
                        </div>
                      </UiDropdownItem>
                    </template>
                  </UiDropdown>
                </div>
              </div>
            </template>

            <template
              v-if="readonly === false"
              #actions
            >
              <i
                v-if="actionErrors(index).length > 0"
                v-tooltip="`${actionErrors(index).length} ${pluralize('issue', actionErrors(index).length)}`"
                class="text-lg text-yellow-500 fad fa-exclamation-circle"
              />

              <UiToggle
                v-tooltip="!item.isDisabled ? 'Disable Action' : 'Enable Action'"
                :model-value="!item.isDisabled"
                size="sm"
                @update:modelValue="item.isDisabled = !$event"
                @click.stop
              />

              <UiDropdown right>
                <template #toggle="{toggle}">
                  <UiButton
                    size="xs"
                    variant="white"
                    @click.stop="toggle"
                  >
                    <i class="far fa-ellipsis-h" />
                  </UiButton>
                </template>

                <UiDropdownItem
                  @click="copyTextToClipboard(item.id)"
                >
                  Copy ID
                </UiDropdownItem>

                <UiDropdownItem
                  @click="duplicateAction(item)"
                >
                  Duplicate
                </UiDropdownItem>

                <UiDropdownItem
                  class="text-red-500"
                  @click="removeAction(item)"
                >
                  <span class="text-red-500">Remove</span>
                </UiDropdownItem>
              </UiDropdown>
            </template>

            <UiCardBody>
              <Component
                :is="componentMap[item.type] || DefaultActionConfig"
                :type="item.type"
                :action-id="item.id"
                :action="automationActions.find(i => i.type === item.type)"
                :model-value="item.config"
                :validation-issues="actionErrors(index)"
                :trigger="trigger"
                :readonly="readonly"
                @update:modelValue="updateAction(item, $event)"
              />

              <UiHiddenContent
                v-if="readonly === false"
                title="Advanced"
                class="mb-3"
              >
                <UiInputGroup label="Step name">
                  <UiInput
                    :model-value="item.name || actionsByType[item.type].name"
                    @update:modelValue="item.name = $event"
                  />
                </UiInputGroup>

                <UiInputGroup>
                  <UiToggle v-model="item.continueOnError">
                    Continue on error
                    <!-- Hint on hover of info icon -->
                    <i
                      v-tooltip="'If this action fails, continue to the next action.'"
                      class="fa-duotone fa-circle-question"
                    />
                  </UiToggle>
                </UiInputGroup>
              </UiHiddenContent>

              <UiHiddenContent
                v-if="readonly === false"
                title="Outputs"
              >
                <ContextVariablesEditor
                  :model-value="actionsByType[item.type].outputs"
                  :readonly="true"
                  :cols="2"
                  mode="output"
                >
                  <template #item-actions="{item: variable}">
                    <UiButton
                      size="xs"
                      variant="white"
                      @click="copyVariable(item, variable)"
                    >
                      <i class="far fa-copy" />
                    </UiButton>
                  </template>
                </ContextVariablesEditor>
              </UiHiddenContent>
            </UiCardBody>
          </Accordion>

          <div class="flex relative justify-center items-center w-full add-action-after">
            <div class="w-0 h-14 border-l border-gray-700 border-dashed" />

            <UiButton
              v-if="readonly === false"
              variant="none"
              class="absolute w-8 h-8 text-gray-400 hover:text-white bg-gray-900 rounded-full"
              @click="openAddActionModal(index)"
            >
              <!-- Add -->
              <div class="w-full">
                <i class="far fa-plus" />
              </div>
            </UiButton>
          </div>
        </li>
      </template>
    </Draggable>
  </div>

  <div v-if="readonly === false && actions.length === 0">
    <UiCard class="shadow">
      <div class="grid grid-cols-3">
        <div class="col-span-2">
          <AutomationActionPicker @select="addAction($event)" />
        </div>

        <div class="col-span-1 p-3 bg-gray-800 bg-opacity-40">
          <div class="mb-2 text-base font-light">
            Select Actions
          </div>

          <div class="mb-3 text-sm text-gray-400">
            Actions are the building blocks of an Automation. They can interact with marketplaces, APIs, and other tools to create streamlined workflows. They execute as part of the Automation.
          </div>

          <UiButton
            size="sm"
            variant="white"
            tag="a"
            target="_blank"
            class="inline-block"
            href="https://help.compass.art"
          >
            <i class="mr-2 fas fa-question-circle" />
            Learn More
          </UiButton>
        </div>
      </div>
    </UiCard>
  </div>

  <UiModal
    v-if="readonly === false"
    v-model="isAddActionModalVisible"
  >
    <AutomationActionPicker @select="addActionFromModal" />
  </UiModal>
</template>

<script setup>
import Draggable from 'vuedraggable';
import useAutomations from '@/composition/automation/use-automations';

import BidActionConfig from '@/components/automation/actions/BidActionConfig.vue';
import NotifyActionConfig from '@/components/automation/actions/NotifyActionConfig.vue';
import {
  computed, toRefs, ref, nextTick,
} from 'vue';
import { cloneDeep, keyBy } from 'lodash-es';
import pluralize from 'pluralize';
import { copyTextToClipboard } from '@/utils/clipboard';
import ScriptAction from './actions/ScriptActionConfig.vue';
import Accordion from '../Accordion.vue';
import UiButton from '../ui/UiButton.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import RepeatActionConfig from './actions/RepeatActionConfig.vue';
import DefaultActionConfig from './actions/DefaultActionConfig.vue';
import FilterActionConfig from './actions/FilterActionConfig.vue';
import CreateAutomationConfig from './actions/CreateAutomationConfig.vue';
import UiCard from '../ui/UiCard.vue';
import AutomationActionPicker from './AutomationActionPicker.vue';
import UiModal from '../ui/UiModal.vue';
import GroupActionConfig from './actions/GroupActionConfig.vue';
import DoWhileActionConfig from './actions/DoWhileActionConfig.vue';
import UiToggle from '../ui/UiToggle.vue';
import UiHiddenContent from '../ui/UiHiddenContent.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiInput from '../ui/UiInput.vue';
import ContextVariablesEditor from './ContextVariablesEditor.vue';
import useDialogs from '../ui/composition/use-dialogs';
import SetVariableActionConfig from './actions/SetVariableActionConfig.vue';
import UiDropdown from '../ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from '../ui/UiDropdownItem.vue';
import SetDataActionConfig from './actions/SetDataActionConfig.vue';
import SetArrayActionConfig from './actions/SetArrayActionConfig.vue';

const componentMap = {
  bid: BidActionConfig,
  notify: NotifyActionConfig,
  script: ScriptAction,
  repeat: RepeatActionConfig,
  doWhile: DoWhileActionConfig,
  group: GroupActionConfig,
  filter: FilterActionConfig,
  createAutomation: CreateAutomationConfig,
  setVariable: SetVariableActionConfig,
  setData: SetDataActionConfig,
  setArray: SetArrayActionConfig,
};

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  validationIssues: {
    type: Array,
    default() {
      return [];
    },
  },
  trigger: {
    type: String,
    required: true,
  },

  readonly: {
    type: Boolean,
    default: false,
  },
});

const {
  modelValue, validationIssues, trigger, readonly,
} = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const actions = computed({
  get() {
    return modelValue.value || [];
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const currentlyOpenAccordion = ref(null);

const { automationActions } = useAutomations();

const actionsByType = computed(() => keyBy(automationActions.value, 'type'));

const defaultConfig = {
  script: () => ({
    script: '',
  }),
  repeat: () => ({
    items: null,
    actions: [],
  }),
  doWhile: () => ({
    actions: [],
  }),
  group: () => ({
    actions: [],
  }),
  setVariable: () => ({
    name: null,
    type: null,
    value: null,
  }),
};

const addAction = (action, index) => {
  const insertIndex = index !== null ? Math.min(actions.value.length, index) : actions.value.length;
  const d = {
    id: `${action.type.split('@').at(0)}-${Array.from(window.crypto.getRandomValues(new Uint8Array((4) / 2)), (item) => item.toString(16).padStart(2, '0')).join('')}`,
    type: action.type,
    config: defaultConfig[action.type]?.() || {},
  };

  action.inputs.forEach((input) => {
    if (typeof d.config[input.name] === 'undefined') {
      d.config[input.name] = null;
    }
  });

  const data = [...actions.value];
  data.splice(insertIndex + 1, 0, d);

  actions.value = data;
  nextTick(() => {
    currentlyOpenAccordion.value = d.id;
  });
};

const updateAction = (index, action) => {
  actions.value[index].config = action;
};

const removeAction = (action) => {
  actions.value = actions.value.filter((item) => item !== action);
};

const regenerateIdsRecursively = (action) => {
  const data = cloneDeep(action);
  data.id = `${action.type}-${Array.from(window.crypto.getRandomValues(new Uint8Array((4) / 2)), (item) => item.toString(16).padStart(2, '0')).join('')}`;
  if (data.config.actions) {
    data.config.actions = data.config.actions.map((item) => regenerateIdsRecursively(item));
  }

  return data;
};

const duplicateAction = (action) => {
  const data = [...actions.value];
  const index = data.indexOf(action);
  const newAction = regenerateIdsRecursively(action);
  data.splice(index + 1, 0, newAction);

  actions.value = data;
  nextTick(() => {
    currentlyOpenAccordion.value = newAction.id;
  });
};

const actionErrors = computed(() => (index) => validationIssues.value?.filter((item) => item.path[0] === 'actions' && Number(item.path[1]) === Number(index))?.map((item) => ({
  ...item,
  path: item.path.slice(3),
})) || []);

const isAddActionModalVisible = ref(false);
const insertAtIndex = ref(null);
const openAddActionModal = (index) => {
  insertAtIndex.value = index;
  isAddActionModalVisible.value = true;
};

const addActionFromModal = (event) => {
  addAction(event, insertAtIndex.value);
  isAddActionModalVisible.value = false;
};
const { notify } = useDialogs();
const copyVariable = (action, item) => {
  copyTextToClipboard(`outputs.${action.id}.${item.name}`);
  notify({
    message: 'Output ID copied to clipboard',
  });
};

const scrollStepIntoView = (stepId) => {
  nextTick(() => {
    const step = document.getElementById(stepId);
    step.style.scrollMargin = '116px';
    if (step) {
      step.scrollIntoView({
        behavior: 'smooth',
      });
    }
  });
};

</script>

<style lang="scss">
.automation-action-editor {
  .grip-wrap {
      @apply transition-transform transform translate-x-0;
    }
    .grip-icon {
      @apply w-3 absolute -left-6 transition-opacity opacity-0 cursor-move;
    }
  &:not(.automation-action-editor--disabled) {

    .action-accordion:not(.action-accordion--open) {
      .accordion-toggle:hover .grip-wrap {
        @apply translate-x-6;
      }
      .accordion-toggle:hover .grip-icon {
        @apply opacity-100;
      }
    }
    .accordion-toggle .action-id {
      @apply hidden;
    }

    .accordion-toggle:hover .action-id {
      @apply block;
    }

    .sortable-drag .add-action-after {
      opacity: 0;
      visibility: hidden;
    }
  }
}

</style>
