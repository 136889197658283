<template>
  <UiModalHeader
    :title="title"
    @dismiss="dismiss"
  />

  <div class="p-3">
    <UiTabs
      v-model="tab"
      pills
    >
      <UiTab name="Update">
        <AlertForm
          v-if="!isLoading"
          v-model="updateModel"
          :on-last-field-enter="updateAlert"
        />

        <ViewLoader v-else />

        <div class="flex justify-between items-center mt-6">
          <div>
            <UiButton
              variant="red"
              @click="deleteAlert"
            >
              Delete
            </UiButton>
          </div>

          <div>
            <UiButton
              :is-loading="isUpdating"
              :disabled="invalidateListingAlert(updateModel)"
              @click="updateAlert"
            >
              Update
            </UiButton>
          </div>
        </div>
      </UiTab>

      <UiTab name="History">
        <div
          v-if="!alertHistory || alertHistory.length === 0"
          class="flex justify-center items-center py-4"
        >
          <div class="text-lg font-medium text-gray-600">
            There is nothing here yet
          </div>
        </div>

        <ul
          v-else
          class="space-y-2"
        >
          <li
            v-for="item in alertHistory"
            :key="item.id"
            class="p-3 rounded border dark:border-gray-800"
          >
            <div class="text-sm">
              {{ item.payload.message }}
            </div>

            <div class="text-xs text-gray-500">
              {{ moment(item.createdAt).from() }}
            </div>
          </li>
        </ul>
      </UiTab>
    </UiTabs>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
  reactive,
  inject,
} from 'vue';
import { useQuery, useApolloClient } from '@vue/apollo-composable';
import moment from 'moment-timezone';
import alertHistoryQuery from '@/graphql/alert-history/queries/alertHistory.query.gql';
import singleAlertQuery from '@/graphql/alert/queries/singleAlert.query.gql';
import userAlertsQuery from '@/graphql/alert/queries/userAlerts.query.gql';
import updateAlertMutation from '@/graphql/alert/mutations/updateAlert.mutation.gql';
import deleteAlertMutation from '@/graphql/alert/mutations/deleteAlert.mutation.gql';
import { useUiDialog } from '@/components/ui-dialog/useUiDialog';

import UiModalHeader from '../ui/UiModalHeader.vue';
import UiTab from '../ui/UiTab.vue';
import UiTabs from '../ui/UiTabs.vue';
import AlertForm from './AlertForm.vue';
import UiButton from '../ui/UiButton.vue';
import ViewLoader from '../ViewLoader.vue';
import { normalizeThreshold } from '../../utils/normalizeThreshold';
import { invalidateListingAlert } from '../../utils/invalidateListingAlert';

export default defineComponent({
  components: {
    UiTabs,
    UiTab,
    UiModalHeader,
    AlertForm,
    UiButton,
    ViewLoader,
  },

  props: ['alert'],

  setup(props) {
    const state = reactive({
      isUpdating: false,
      tab: 0,
      updateModel: null,
      isLoading: false,
    });
    const { toggleIsVisible } = inject('ui-modal-provide');

    const { info } = useUiDialog();

    const { result: alertHistoryResult } = useQuery(
      alertHistoryQuery,
      () => ({ id: props.alert?.id }),
      () => ({ enabled: Boolean(props.alert) }),
    );
    const alertHistory = computed(() => alertHistoryResult.value?.alertHistory);

    const { resolveClient } = useApolloClient('default');
    const apolloClient = resolveClient();

    return {
      ...toRefs(state),
      alertHistory,
      moment,
      apolloClient,
      info,
      toggleIsVisible,
    };
  },

  computed: {
    title() {
      return this.alert?.name;
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    invalidateListingAlert,

    load() {
      this.isLoading = true;
      return this.apolloClient
        .query({
          // fetchPolicy: "cache-and-network",
          query: singleAlertQuery,
          variables: {
            id: this.alert.id,
          },
        })
        .then((res) => {
          const alert = JSON.parse(JSON.stringify(res.data.alert));

          if (typeof alert.config.threshold === 'string' || typeof alert.config.threshold === 'number') {
            if (typeof alert.config.threshold === 'number') {
              alert.config.threshold = alert.config.threshold.toString();
            }
            alert.config.threshold = alert.config.threshold
              .split(',')
              .map((item) => ({
                value: parseFloat(item),
                isNew: false,
              })).sort((a, b) => a.value - b.value);
          } else if (Array.isArray(alert.config.threshold)) {
            alert.config.threshold = alert.config.threshold
              .map((item) => ({
                value: parseFloat(item),
                isNew: false,
              }))
              .sort((a, b) => a.value - b.value);
          }

          this.updateModel = { ...alert };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    updateAlert() {
      this.isUpdating = true;
      this.apolloClient
        .mutate({
          mutation: updateAlertMutation,
          refetchQueries: () => [
            {
              query: userAlertsQuery,
            },
          ],
          variables: {
            id: this.alert.id,
            data: {
              ...normalizeThreshold(this.updateModel),
              id: undefined,
              __typename: undefined,
              status: undefined,
            },
          },
        })
        .then(() => {
          this.t = false;
        })
        .finally(() => {
          this.isUpdating = false;
        });
    },
    dismiss() {
      this.toggleIsVisible(false);
    },
    deleteAlert() {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(`Are you sure that you want to delete the "${this.alert.name}" alert?`)
      ) {
        return Promise.reject();
      }

      return this.apolloClient
        .mutate({
          mutation: deleteAlertMutation,
          variables: {
            id: this.alert.id,
          },
          refetchQueries: () => [
            {
              query: userAlertsQuery,
            },
          ],
        })
        .then(() => {
          this.info('Alert deleted');
          this.toggleIsVisible(false);
        });
    },
  },
});
</script>

<style>
</style>
