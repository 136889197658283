<template>
  <UiModalHeader
    title="Generate API Token"
    @dismiss="() => toggleIsVisible(false)"
  />

  <div class="p-4">
    <form
      v-if="!token"
      @submit.prevent="createToken"
    >
      <UiInputGroup label="Token name">
        <UiInput
          v-model="tokenName"
          placeholder="Describe your token"
        />
      </UiInputGroup>
    </form>

    <div v-else>
      <Banner
        class="mb-3"
        variant="yellow"
      >
        Copy your API token and store it in a safe place because we won't be able to display it again.
      </Banner>

      <UiInputGroup label="Your Token">
        <UiInput
          v-model="token"
          readonly
          disabled
        />
      </UiInputGroup>

      <button
        class="-mt-3 text-xs hover:underline"
        @click="copyToClipboard"
      >
        Copy to clipboard
      </button>
    </div>
  </div>

  <UiModalFooter>
    <UiButton
      v-if="!token"
      :is-loading="isCreating"
      @click="createToken"
    >
      Create
    </UiButton>

    <UiButton
      v-if="token"
      @click="() => toggleIsVisible(false)"
    >
      Done
    </UiButton>
  </UiModalFooter>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  inject,
} from 'vue';
import { useMutation } from '@vue/apollo-composable';
import userApiTokensQuery from '@/graphql/personal-access-tokens/queries/userApiTokens.query.gql';
import createApiTokenMutation from '@/graphql/personal-access-tokens/mutations/createApiToken.mutation.gql';
import { copyTextToClipboard } from '@/utils/clipboard';
import { useUiDialog } from '@/components/ui-dialog/useUiDialog';

import UiButton from './ui/UiButton.vue';
import UiModalFooter from './ui/UiModalFooter.vue';
import UiModalHeader from './ui/UiModalHeader.vue';
import UiInputGroup from './ui/UiInputGroup.vue';
import UiInput from './ui/UiInput.vue';
import Banner from './Banner.vue';

export default defineComponent({
  components: {
    UiModalHeader,
    UiModalFooter,
    UiButton,
    UiInputGroup,
    UiInput,
    Banner,
  },

  setup() {
    const state = reactive({
      tokenName: '',
      token: null,
    });

    const { toggleIsVisible } = inject('ui-modal-provide');
    const { info } = useUiDialog();

    const {
      mutate: createToken,
      loading: isCreating,
      onDone,
    } = useMutation(createApiTokenMutation, () => ({
      variables: {
        name: state.tokenName,
      },
      refetchQueries() {
        return [
          { query: userApiTokensQuery },
        ];
      },
    }));

    onDone((result) => {
      state.token = result.data.token;
    });

    return {
      ...toRefs(state),
      info,
      createToken,
      isCreating,
      toggleIsVisible,
    };
  },

  methods: {
    async copyToClipboard() {
      await copyTextToClipboard(this.token);
      this.info('API token copied to clipboard', {
        icon: 'fas fa-check-circle text-green-400',
      });
    },
  },
});
</script>

<style>
</style>
