<template>
  <UiCard
    class="overflow-x-auto w-full max-w-full"
  >
    <UiCardBody
      slim
    >
      <Chart :options="chartOptions" />
    </UiCardBody>
  </UiCard>
</template>

<script setup>
import {
  computed, toRefs,
} from 'vue';
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { groupBy, orderBy } from 'lodash-es';
import moment from 'moment';
import { displayName, formatCurrency, formatNumber } from '@/utils/filters';
import { ACTIVITY_TYPE_TO_COLOR, ACTIVITY_TYPE_TO_COLOR_INDEX } from '@/utils/activityTypeColors';
import UiCard from '../ui/UiCard.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import Chart from '../Chart.vue';

const query = gql`
  query GetTokenActivityChart($tokenId: String!) {
    token(where: {id: $tokenId}) {
      id
      activityChart
    }
  }
`;

const props = defineProps(['tokenId']);

const { tokenId } = toRefs(props);

const {
  result, loading: isLoading, loadingMore, hasMore, loadMore,
} = useQuery(query, () => ({ tokenId: tokenId.value }));

const chartData = computed(() => orderBy(result.value?.token?.activityChart || [], 'key'));

const chartOptions = computed(() => {
  const byType = groupBy(chartData.value, 'type');
  return {
    chart: {
      zoomType: 'xy',
      styledMode: false,
      height: 300,
      resetZoomButton: {
        theme: {
          fill: '#404040',
          stroke: '#404040',
          style: {
            color: '#D4D4D4',
          },
        },
      },
    },
    xAxis: {
      type: 'datetime',
      startOnTick: true,
      endOnTick: true,

      // gridLineWidth: 1,
      // tickInterval: 24 * 3600 * 1000,
    },
    yAxis: [
      {
        opposite: false,
        min: 0,
        title: {
          enabled: false,
        },
      },
    ],
    plotOptions: {

      series: {
        // pointPlacement: 'between',
        boostThreshold: 2000,
      },
      scatter: {
        marker: {
          symbol: 'circle',
          radius: 5,
        },
      },
      column: {
        borderRadius: 2,
        pointPadding: 0.3,
        grouping: false,
        groupPadding: 0.3,
      },
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: '#ddd', cursor: 'pointer', fontSize: '12px', fontWeight: 'bold', textOverflow: 'ellipsis',
      },
      itemHoverStyle: {
        color: '#eee', cursor: 'pointer', fontSize: '12px', fontWeight: 'bold', textOverflow: 'ellipsis',
      },
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      symbolWidth: 12,
      symbolHeight: 12,

    },
    tooltip: {
      followPointer: false,
      snap: false,
      shared: true,
      useHTML: true,
      padding: 0,
      borderWidth: 0,
      shadow: false,
      hideDelay: 30,
      animation: false,
      shape: 'rect',
      headerFormat: '',
      footerFormat: '',
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: 'rgba(0,0,0,0)',
      pointFormatter() {
        // const item = activityChart.value.value[this.index];
        return `
          <div class="bg-gray-800 font-sans p-3 text-gray-400 rounded-xl pointer-events-none">
            <table class="font-sans w-full">
              <tr class="mb-2">
                <th class="text-xs font-sans font-normal text-gray-400" colspan="2">${moment(this.x).format('ll')}</th>
              </tr>
              <tr>
                <td class="text-sm ">${this.series.name}</td>
                <td class="text-sm text-white text-medium">${formatCurrency(this.y)}</td>
              </tr>
            </table>
          </div>
          `;
      },
    },
    series: [
      ...Object.keys(byType).map((type) => ({
        states: {
          inactive: {
            opacity: 1,
          },
        },
        colorIndex: ACTIVITY_TYPE_TO_COLOR_INDEX[type],
        color: ACTIVITY_TYPE_TO_COLOR[type],
        stickyTracking: false,
        yAxis: 0,
        name: displayName(type),
        type: 'scatter',
        data: byType[type].map((item) => ({ x: moment(item.key).toDate().getTime(), y: parseFloat(item.value || 0) })) || [],
        showInLegend: true,
      })),
    ],
  };
});
</script>

<style>
.tx-table-min-w {
  min-width: 720px;;
}
</style>
