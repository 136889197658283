<template>
  <UiDropdown :inline="inlineDropdown !== false">
    <template #toggle="{toggle, isOpen}">
      <UiButton
        :size="size"
        variant="white"
        :disabled="disabled"
        :class="buttonClass"
        @click="toggle"
      >
        <div class="flex gap-2 justify-between items-center w-full">
          <slot :selected="selected">
            <span
              v-if="prepend"
              class="font-medium"
            >{{ prepend }}</span>

            <span v-if="selected">
              <slot
                name="item"
                :item="selected"
              >
                {{ selected.name }}
              </slot>
            </span>

            <span v-else>Nothing selected</span>
          </slot>

          <div class="text-xs text-gray-400">
            <i
              class="transition-transform transform fas fa-chevron-down"
              :class="{'rotate-180': isOpen}"
            />
          </div>
        </div>
      </UiButton>
    </template>

    <template #body>
      <UiSearchInput
        v-if="options.length > 10"
        v-model="searchText"
        v-autofocus
        class="mb-3"
        @click.stop
      />

      <div :class="'overflow-scroll scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800 scrollbar-thumb-rounded-full scrollbar-track-rounded-full max-h-112'">
        <UiDropdownItem
          v-for="item in displayedOptions"
          :key="item.id"
          :value="item"
          @click="selected = item"
        >
          <div class="flex justify-between items-center">
            <div>
              <slot
                name="option"
                :item="item"
              >
                <div :class="[selected === item ? 'font-semibold' : 'font-normal', 'block truncate']">
                  <slot
                    name="item"
                    :item="item"
                  >
                    {{ item.name }}
                  </slot>
                </div>
              </slot>
            </div>

            <div>
              <span
                v-if="selected === item"
                :class="[active ? 'text-white' : 'text-indigo-600']"
              >
                <div class="w-5 h-5 text-center">
                  <i class="far fa-check" />
                </div>
              </span>
            </div>
          </div>
        </UiDropdownItem>
      </div>
    </template>
  </UiDropdown>
</template>

<script setup>
import { computed, toRefs, ref } from 'vue';

import { isEqual } from 'lodash-es';
import UiButton from './UiButton.vue';
import UiDropdown from './ui-dropdown/UiDropdown.vue';
import UiDropdownItem from './UiDropdownItem.vue';
import UiSearchInput from './UiSearchInput.vue';

const props = defineProps({
  modelValue: null,
  options: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
  },
  prepend: {
    type: String,
    default: '',
  },
  inlineDropdown: {
    type: Boolean,
    default: false,
  },
  buttonClass: {
    type: [String, Array],
    default: '',
  },
});
const {
  modelValue, options, size, disabled, inlineDropdown, prepend,
} = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const selected = computed({
  get() {
    return options.value.find((item) => isEqual(item.id, modelValue.value));
  },
  set(value) {
    emit('update:modelValue', value.id);
  },
});

const searchText = ref('');

const displayedOptions = computed(() => {
  if (searchText.value === '') {
    return options.value;
  }

  return options.value.filter((item) => item.name.toLowerCase().includes(searchText.value.toLowerCase()));
});
</script>
