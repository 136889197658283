<template>
  <NoContent
    v-if="!isLoading && runs.length === 0"
    icon="fa-duotone fa-clock-rotate-left"
    content="No Runs Yet"
  />

  <div v-if="isLoading || runs.length > 0">
    <UiTable
      v-if="!selectedRunId"
      :items="runs"
      :fields="[
        'status',
        'id',
        'startedAt',
        'runTime',
      ]"
      class="rounded-2xl"
      style="contain: paint"
      :is-loading="isLoading && !isRefetching"
      @itemClick="selectedRunId = $event.id"
    >
      <template #cell(startedAt)="{item}">
        <Timestamp :timestamp="item.startedAt" />
      </template>

      <template #cell(runTime)="{item}">
        <span v-if="item.runTime !== null && item.runTime < 60000">{{ Math.floor(item.runTime / 1000) }} {{ pluralize('second', Math.floor(item.runTime / 1000)) }}</span>

        <span v-else-if="item.runTime">{{ moment.duration(item.runTime).humanize() }}</span>

        <span
          v-else
          class="text-gray-400"
        >-</span>
      </template>

      <template #cell(status)="{item}">
        <AutomationRunStatusBadge :status="item.status" />

        <div
          v-if="item.error?.message"
          class="mt-2 text-sm text-gray-400 whitespace-normal"
        >
          {{ item.error.message }}
        </div>
      </template>
    </UiTable>

    <UiCard
      v-if="selectedRunId"
      :is-loading="isLoadingSingleRun"
    >
      <UiCardBody>
        <div>
          <button
            type="button"
            class="font-light text-gray-400"
            @click="selectedRunId = null"
          >
            All History
          </button>
        </div>

        <div
          class="flex justify-between items-center"
        >
          <div>
            <div class="flex gap-2 items-center">
              <div class="text-2xl font-light">
                Traces
              </div>

              <div
                v-if="selectedRun"
                class="py-1 px-2 text-sm font-medium leading-none text-gray-300 bg-gray-700 rounded-lg"
              >
                Run #{{ selectedRun.id }}
              </div>

              <div
                v-else
                class="py-1 px-2 text-sm leading-none text-gray-800 bg-gray-800 rounded-lg animate-pulse select-none"
              >
                Loading
              </div>

              <AutomationRunStatusBadge
                v-if="selectedRun"
                :status="selectedRun?.status"
              />
            </div>

            <div
              v-if="selectedRun"
              class="flex gap-2 items-center mt-2 text-sm text-gray-400"
            >
              <div>
                Started
                <Timestamp
                  :timestamp="selectedRun.startedAt"
                  relative-only
                />
              </div>

              <div v-if="selectedRun.finishedAt">
                completed in
                {{ moment.duration(selectedRun.runTime).humanize() }}
              </div>
            </div>
          </div>

          <div>
            <UiButton
              variant="white"
              size="sm"
              class="rounded-r-none"
              :disabled="isFirstRun"
              @click="previousRun()"
            >
              <i class="fas fa-chevron-left" />
            </UiButton>

            <UiButton
              variant="white"
              size="sm"
              class="rounded-l-none"
              :disabled="isLastRun"
              @click="nextRun()"
            >
              <i class="fas fa-chevron-right" />
            </UiButton>
          </div>
        </div>
      </UiCardBody>
    </UiCard>

    <AutomationActionTraces
      v-if="automation && selectedRun"
      :actions="automation.actions"
      :traces="selectedRun.traces"
      class="mt-6"
    />
  </div>
</template>

<script setup>
import { eventBus } from '@/real-time';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import {
  toRefs, computed, ref, watchEffect,
} from 'vue';
import moment from 'moment';
import useAutomations from '@/composition/automation/use-automations';
import pluralize from 'pluralize';
import Timestamp from '../Timestamp.vue';
import UiTable from '../ui/UiTable.vue';
import UiCard from '../ui/UiCard.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import UiButton from '../ui/UiButton.vue';
import NoContent from '../NoContent.vue';
import AutomationRunStatusBadge from './AutomationRunStatusBadge.vue';
import AutomationActionTraces from './AutomationActionTraces.vue';

const query = gql`
query GetAutomationRunHistory($id: Int!) {
  automation(id: $id) {
    id
    runs {
      id
      startedAt
      status
      finishedAt
      triggeredAt
      runTime
      error
    }
  }
}
`;

const singleRunQuery = gql`
query GetAutomationRun($automationId: Int!, $runId: Int!) {
  automation(id: $automationId) {
    id
    run(id: $runId) {
      id
      startedAt
      status
      finishedAt
      triggeredAt
      runTime
      error
      traces {
        id 
        actionError
        actionInputs
        actionOutputs
        actionId
        startedAt
        endedAt
        status
      }
    }
  }
}
`;

const props = defineProps(['automationId']);
const { automationId } = toRefs(props);

const { getAutomation } = useAutomations();
const {
  automation,
} = getAutomation(automationId);

const selectedRunId = ref(null);

const {
  result,
  loading: isLoading,
} = useQuery(query, () => ({
  id: automationId.value,
}), {
  fetchPolicy: 'cache-and-network',
});

const {
  result: singleRunResult,
  loading: isLoadingSingleRun,
  refetch: refetchSingleRun,
} = useQuery(singleRunQuery, () => ({
  automationId: automationId.value,
  runId: selectedRunId.value,
}), {
  fetchPolicy: 'cache-and-network',
  enabled: computed(() => !!selectedRunId.value),
});

watchEffect(() => {
  eventBus.on(`automationRun:complete:${selectedRunId.value}`, () => {
    refetchSingleRun();
  });
});

const runs = computed(() => result.value?.automation?.runs || []);
const selectedRun = computed(() => {
  if (!selectedRunId.value) {
    return null;
  }

  return singleRunResult.value?.automation?.run;
});

const previousRun = () => {
  const index = runs.value.findIndex((run) => run.id === selectedRunId.value);
  selectedRunId.value = runs.value[index + 1]?.id;
};

const nextRun = () => {
  const index = runs.value.findIndex((run) => run.id === selectedRunId.value);
  selectedRunId.value = runs.value[index - 1]?.id;
};

const isLastRun = computed(() => {
  if (!selectedRunId.value) {
    return false;
  }

  return runs.value[0].id === selectedRunId.value;
});

const isFirstRun = computed(() => {
  if (!selectedRunId.value) {
    return false;
  }

  return runs.value[runs.value.length - 1].id === selectedRunId.value;
});

</script>
