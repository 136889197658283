import { h } from 'vue';

export function UiDropdownBody(props, { slots }) {
  const position = 'z-100';

  return h('div', { class: [position] }, [slots.default()]);
}

export function UiDropdownContent(props, { slots }) {
  const color = 'bg-white dark:bg-gray-850';
  const focusRing = 'focus:outline-none ring-1 ring-black ring-opacity-5';
  const border = 'rounded-md border border-gray-950 shadow-5xl';
  const box = 'py-1';
  return h('div', { class: [box, color, focusRing, border] }, [slots.default()]);
}

export function UiDropdownRoot(props, { slots }) {
  return h('div', { class: ['relative'] }, [slots.default()]);
}

export function UiDropdownToggleContainer(props, { slots }) {
  return h('div', [slots.default()]);
}
