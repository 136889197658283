<template>
  <CronCore
    v-slot="{fields, period}"
    v-model="value"
  >
    <div class="flex flex-wrap gap-x-2 gap-y-1 items-center">
      <!-- period selection -->
      {{ period.prefix }}
      <UiDropdown>
        <template #toggle="{toggle}">
          <UiButton
            size="xs"
            variant="white"
            @click="toggle"
          >
            {{ period.attrs.modelValue }}
          </UiButton>
        </template>

        <template #body>
          <UiDropdownItem
            v-for="item in period.items"
            :key="item.id"
            @click="period.events['update:model-value'](item.id)"
          >
            {{ item.text }}
          </UiDropdownItem>
        </template>
      </UiDropdown>

      {{ period.suffix }}

      <!-- cron expression fields -->
      <template
        v-for="f in fields"
        :key="f.id"
      >
        {{ f.prefix }}
        <UiDropdown>
          <template #toggle="{toggle}">
            <UiButton
              size="xs"
              variant="white"
              @click="toggle"
            >
              {{ f.selectedStr }}
            </UiButton>
          </template>

          <template #body>
            <UiDropdownItem
              v-for="item in f.items"
              :key="item.value"
              @click="f.events['update:model-value']([item.value])"
            >
              {{ item.text }}
            </UiDropdownItem>
          </template>
        </UiDropdown>

        {{ f.suffix }}
      </template>
    </div>

    <!-- <UiInput v-model="value" /> -->
  </CronCore>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue';
import UiDropdown from './ui-dropdown/UiDropdown.vue';
import UiButton from './UiButton.vue';
import UiDropdownItem from './UiDropdownItem.vue';
import UiInput from './UiInput.vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: null,
});

const { modelValue } = toRefs(props);

const value = computed({
  get() {
    return modelValue.value;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});
</script>
