<template>
  <article
    ref="slider"
    class="overflow-hidden relative w-full slider"
  >
    <div class="slider__btn-container slider__btn-container--prev">
      <button
        v-if="isMinimumMdScreen"
        class="slider__btn"
        type="button"
        :disabled="isSlidingPrevForbidden"
        @click="changeSlide(SLIDE_BACK)"
      >
        <i class="fas fa-chevron-left" />
      </button>
    </div>

    <!-- slides -->
    <ul
      ref="list"
      v-touch:swipe.left="() => { changeSlide(SLIDE_FORTH) }"
      v-touch:swipe.right="() => { changeSlide(SLIDE_BACK) }"
      class="slider__slides-list"
    >
      <li
        v-for="id in slidesIdsArray"
        ref="slidesRefs"
        :key="id"
        class="slider__slide-slot"
      >
        <slot :name="id" />
      </li>
    </ul>

    <div class="slider__btn-container slider__btn-container--next">
      <button
        v-if="isMinimumMdScreen"
        class="slider__btn "
        type="button"
        :disabled="isSlidingNextForbidden"
        @click="changeSlide(SLIDE_FORTH)"
      >
        <i class="fas fa-chevron-right" />
      </button>
    </div>
  </article>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  toRefs,
} from 'vue';

import { useCSSVariables } from '@/composition/css-variables';
import { useDevice } from '@/composition/device-detector';

const SIDE_SLIDE_VISIBLE_PART = 0.025; // 2.5%
const MARGIN_X_SLIDES = 0.05; // 5%;
const SLIDE_BACK = 1;
const SLIDE_FORTH = -1;

export default defineComponent({
  props: {
    slidesIdsArray: {
      type: Array,
      default: null,
    },

    startFromIndex: {
      type: Number,
      default: 1,
    },

    autoPlayOn: {
      type: Boolean,
      default: false,
    },

    autoPlaySpeedMs: {
      type: Number,
      default: 2500,
    },
  },

  setup(props) {
    const {
      startFromIndex,
      autoPlayOn,
      autoPlaySpeedMs,
    } = toRefs(props);

    const isAutoPlayEnabled = ref(autoPlayOn.value);
    const intervalId = ref(null);

    const list = ref(null);
    const slidesRefs = ref([]);
    const slider = ref(null);
    const { createRefBinding } = useCSSVariables(slider);
    const isSlidingPrevForbidden = ref(true);
    const isSlidingNextForbidden = ref(false);
    const { isMinimumMdScreen } = useDevice();

    const slideWidth = computed(() => slidesRefs.value[0].offsetWidth);

    const listScreenWidth = computed(() => list.value.offsetWidth);

    const sideSlideVisiblePartPx = computed(() => {
      if (isMinimumMdScreen.value) {
        return Math.floor(listScreenWidth.value * SIDE_SLIDE_VISIBLE_PART);
      }

      return 0;
    });

    const marginXSlidesPx = computed(() => {
      if (isMinimumMdScreen.value) {
        return Math.floor(listScreenWidth.value * MARGIN_X_SLIDES);
      }

      return 0;
    });

    const translationToDisplaySlide = computed(() => slideWidth.value + sideSlideVisiblePartPx.value);

    const midBetweenSlides = computed(() => marginXSlidesPx.value + sideSlideVisiblePartPx.value);

    const firstSlidePos = computed(() => {
      if (isMinimumMdScreen.value) {
        return midBetweenSlides.value;
      }

      return 0;
    });

    const translateListX = createRefBinding('--translate-list-x', '0px');
    const buttonsPosition = createRefBinding('--buttons-position', '0px');
    const marginXSlide = createRefBinding('--margin-x-slide', '0px');

    const currentSlide = ref(0);

    function blockSlidingIfNeed(slideIndex) {
      const lastSlideIndex = slidesRefs.value.length - 1;

      if (slideIndex === lastSlideIndex) {
        isSlidingNextForbidden.value = true;
      } else if (slideIndex <= lastSlideIndex && slideIndex > 0) {
        isSlidingNextForbidden.value = false;
        isSlidingPrevForbidden.value = false;
      } else if (slideIndex === 0) {
        isSlidingPrevForbidden.value = true;
      }
    }

    function countNextPosition(multiplier, lastPosition) {
      currentSlide.value -= multiplier;

      blockSlidingIfNeed(currentSlide.value);

      let lastVal = parseInt(lastPosition);

      if (lastVal === 0) {
        lastVal = firstSlidePos.value;
      }

      const result = lastVal + (
        (translationToDisplaySlide.value + midBetweenSlides.value) * multiplier
      );

      return result;
    }

    function changeSlide(multiplier, isUserInteraction = true) {
      if (isUserInteraction && isAutoPlayEnabled.value) {
        isAutoPlayEnabled.value = false;
        clearInterval(intervalId.value);
      }

      if (
        (multiplier === SLIDE_BACK && isSlidingPrevForbidden.value)
        || (multiplier === SLIDE_FORTH && isSlidingNextForbidden.value)
      ) {
        return;
      }

      const result = countNextPosition(multiplier, translateListX.value);

      translateListX.value = `${result}px`;
    }

    function initTranslationOnMount() {
      let translation = firstSlidePos.value;
      let startSlideIndex = startFromIndex.value;
      const lastSlideIndex = slidesRefs.value.length - 1;

      if (startSlideIndex > lastSlideIndex) {
        startSlideIndex = lastSlideIndex;
      }

      for (let i = 0; i < startSlideIndex; i++) {
        translation = countNextPosition(SLIDE_FORTH, translation);
      }

      return `${translation}px`;
    }

    function autoSlideChanger() {
      const isMovingForth = ref(true);

      return function () {
        if (isSlidingNextForbidden.value && isMovingForth.value) {
          isMovingForth.value = false;
          changeSlide(SLIDE_BACK, false);
        } else if (!isSlidingNextForbidden.value && !isSlidingPrevForbidden.value && !isMovingForth.value) {
          changeSlide(SLIDE_BACK, false);
        } else if (isSlidingPrevForbidden.value && !isMovingForth.value) {
          isMovingForth.value = true;
          changeSlide(SLIDE_FORTH, false);
        } else if (!isSlidingNextForbidden.value && !isSlidingPrevForbidden.value && isMovingForth.value) {
          changeSlide(SLIDE_FORTH, false);
        }
      };
    }

    function makeAutoPlay() {
      intervalId.value = setInterval(autoSlideChanger(), autoPlaySpeedMs.value);
    }

    function init() {
      translateListX.value = initTranslationOnMount();
      buttonsPosition.value = `${midBetweenSlides.value}px`;
      marginXSlide.value = `${100 * MARGIN_X_SLIDES}%`;

      if (isAutoPlayEnabled.value) {
        makeAutoPlay();
      }
    }

    onMounted(init);

    return {
      list,
      slidesRefs,
      changeSlide,
      slider,
      isSlidingPrevForbidden,
      isSlidingNextForbidden,
      midBetweenSlides,
      firstSlidePos,
      translationToDisplaySlide,
      slideWidth,
      currentSlide,
      isMinimumMdScreen,
      SLIDE_BACK,
      SLIDE_FORTH,
    };
  },
});
</script>

<style lang="scss" scoped>
.slider {
  --translate-list-x: 0px;
  --buttons-position: 0px;
  --margin-x-slide: 0px;
  $root: &;

  &__btn-container {
    @apply absolute top-1/2 transform -translate-y-2/4 z-10;

    &#{ $root }__btn-container--prev {
      @apply -translate-x-2/4;
      left: var(--buttons-position);
    }

    &#{ $root }__btn-container--next {
      @apply translate-x-2/4;
      right: var(--buttons-position);
    }
  }

  &__btn {
    @apply hidden;
    @apply md:flex justify-center items-center w-14 h-14 outline-none rounded-lg text-2xl;
    @apply text-indigo-500 bg-indigo-900 hover:bg-indigo-500 hover:text-black;
    @apply disabled:opacity-50 disabled:text-indigo-500 disabled:bg-indigo-900;
  }

  &__slides-list {
    @apply flex transition-transform duration-300 ease-out;
    transform: translate3d(var(--translate-list-x), 0, 0);
  }

  &__slide-slot {
    @apply w-full;
    @apply md:w-9/12 flex-shrink-0;

    @screen md {
      margin-left: var(--margin-x-slide);
      margin-right: var(--margin-x-slide);
    }

  }
}
</style>
