<template>
  <slot v-if="isLoggedIn" />

  <UiCard
    v-else
    class="mx-auto w-full max-w-3xl"
  >
    <UiCardBody class="text-center">
      <div class="flex flex-col items-center py-3 md:py-10">
        <div class="mb-6">
          <div class="mb-3 text-xl md:text-2xl font-bold">
            {{ title }}
          </div>

          <div
            v-if="description"
            class="text-gray-700 dark:text-gray-400"
          >
            {{ description }}
          </div>
        </div>

        <div class="flex flex-col justify-center w-full max-w-sm">
          <div>
            <UiButton
              size="lg"
              class="mx-auto rounded-lg"
              :is-loading="isLoading"
              @click="eventBus.$emit('auth:signIn', action || null)"
            >
              Get started now
            </UiButton>
          </div>
        </div>
      </div>
    </UiCardBody>
  </UiCard>
</template>

<script>
import eventBus from '@/utils/event-bus';
import { useStore } from 'vuex';
import { computed } from 'vue';
import UiCard from './ui/UiCard.vue';
import UiCardBody from './ui/UiCardBody.vue';
import UiButton from './ui/UiButton.vue';

export default {
  name: 'GuestHero',

  components: { UiCard, UiCardBody, UiButton },

  props: ['title', 'description', 'action', 'isLoading'],

  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    return {
      isLoggedIn,
    };
  },

  data() {
    return {
      eventBus,
    };
  },
};
</script>

<style>
</style>
