<template>
  <div>
    <UiButton
      v-if="allowVariables === true"
      size="xs"
      variant="white"
      @click="selectValueVariable"
    >
      <i class="far fa-brackets-curly" />
    </UiButton>

    <AttributeValuePicker
      v-if="!isVariable"
      v-model:attribute-values-ids="localValue"
      :collection-address="collectionId"
    />

    <VariableDisplay
      v-if="isVariable"
      :model-value="localValue[0]"
      @update:modelValue="v => {localValue[0] = v; localValue = localValue.filter(Boolean)}"
    />
  </div>
</template>

<script setup>
import {
  toRefs, computed, inject,
} from 'vue';
import AttributeValuePicker from '../AttributeValuePicker.vue';
import UiButton from '../ui/UiButton.vue';
import VariableDisplay from '../automation/VariableDisplay.vue';

const props = defineProps(['modelValue', 'collectionId', 'allowVariables', 'actionId', 'multiple']);
const { modelValue, collectionId, actionId } = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const validate = (value) => Array.isArray(value);

const localValue = computed({
  get() {
    return modelValue.value || [];
  },
  set(v) {
    if (validate(v)) {
      emit('update:modelValue', v);
    }
  },
});

const selectVariable = inject('selectVariable');

const selectValueVariable = () => {
  selectVariable({
    actingAs: 'tokenAttribute',
  }, actionId.value).then((variable) => {
    localValue.value = [`{{${variable.id}}}`];
  });
};

const isVariable = computed(() => localValue.value?.[0]?.startsWith?.('{{') && localValue.value?.[0]?.endsWith?.('}}'));
</script>
