<template>
  <div
    :class="{
      'dark:hover:bg-opacity-90 dark:focus:bg-opacity-100': disabled === false,
      'cursor-not-allowed': disabled !== false,
    }"
    class="flex relative flex-wrap gap-2 items-center py-1.5 px-2 w-full sm:text-sm placeholder-gray-400 text-gray-100 dark:bg-gray-700 dark:active:bg-gray-750 dark:bg-opacity-70 rounded-lg border dark:border-gray-700 dark:focus:border-indigo-500 ring-0 focus:ring-0 shadow-sm transition-colors cursor-pointer outline-none focus:outline-none flex-wra"
    @click="editVariable"
  >
    <div
      class="inline-flex items-center text-sm leading-none rounded shadow-lg text-shadow-md"
      :class="[{'cursor-pointer': disabled === false}, variableSchema(baseName) ? 'bg-blue-600 text-white' : 'bg-yellow-500 text-yellow-900']"
    >
      <div class="py-1 px-1.5 ">
        <span v-if="variableSchema(baseName)">
          {{ displayName(humanize(variableSchema(baseName)?.groupName || variableSchema(baseName)?.group)) }} » {{ displayName(humanize(variableSchema(baseName)?.actingAs)) || variableSchema(baseName)?.name }}
        </span>

        <span v-else>
          {{ baseName }}
        </span>
      </div>
    </div>

    <div
      v-for="(item, index) in filters"
      :key="index"
    >
      <span class="mr-2 text-sm leading-none text-gray-300">{{ humanize(item.name) }}</span>

      <div
        v-for="(i, argIndex) in item.args"
        :key="argIndex"
        class="inline-flex items-center py-1 px-1.5 text-sm leading-none text-white bg-blue-600 rounded shadow-lg text-shadow-md"
      >
        <span v-if="variableSchema(i)">{{ displayName(humanize(variableSchema(i)?.groupName || variableSchema(i)?.group)) }} » {{ displayName(humanize(variableSchema(i)?.actingAs)) || variableSchema(i)?.name }}</span>

        <span v-else>{{ i.replaceAll('"', '') }}</span>
      </div>
    </div>

    <UiButton
      v-if="disabled === false"
      size="xs"
      variant="none"
      class="absolute top-1.5 right-2 px-1.5"
      @click.stop="emit('update:modelValue', resetValue())"
    >
      <i class="far fa-times" />
    </UiButton>
  </div>
</template>

<script setup>
import { toRefs, computed, inject } from 'vue';
import { displayName, humanize } from '@/utils/filters';
import { Liquid } from 'liquidjs';
import useVariableEditor from '@/composition/automation/use-variable-editor';
import UiButton from '../ui/UiButton.vue';

const props = defineProps({
  modelValue: {
    type: [Number, String],
    required: true,
  },
  resetValue: {
    type: Function,
    required: false,
    default: () => null,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const {
  modelValue,
  disabled,
  resetValue,
} = toRefs(props);
const liquid = new Liquid();
const parsed = computed(() => liquid.parse(modelValue.value));

const { show } = useVariableEditor();
const baseName = computed(() => {
  const [{ value }] = parsed.value;

  return value.initial.postfix[0].getText();
});

const filters = computed(() => {
  const [{ value }] = parsed.value;

  return value.filters.map((item) => ({
    name: item.name,
    args: item.args.map((arg) => arg.getText()),
  }));
});

const variableSchema = inject('variableSchema');
const editVariable = async () => {
  if (disabled.value !== false) {
    return;
  }
  const schema = variableSchema.value(baseName.value);
  const newVariable = await show({
    variable: modelValue.value, schema,
  });

  if (newVariable) {
    emit('update:modelValue', newVariable);
  }
};
</script>
