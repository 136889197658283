<template>
  <UiModal v-model="isVisible">
    <UiModalHeader title="Deposit credit" />

    <div class="py-3 px-4">
      <UiInputGroup label="Amount in USD">
        <UiInput
          v-model="amount"
          type="number"
          min="20"
          placeholder="Amount"
        />
      </UiInputGroup>

      <div class="grid grid-cols-5 gap-2 mb-3">
        <UiButton
          variant="white"
          size="sm"
          @click="amount = 20"
        >
          <div class="text-center">
            $20
          </div>
        </UiButton>

        <UiButton
          variant="white"
          size="sm"
          @click="amount = 30"
        >
          <div class="text-center">
            $30
          </div>
        </UiButton>

        <UiButton
          variant="white"
          size="sm"
          @click="amount = 50"
        >
          <div class="text-center">
            $50
          </div>
        </UiButton>

        <UiButton
          variant="white"
          size="sm"
          @click="amount = 75"
        >
          <div class="text-center">
            $75
          </div>
        </UiButton>

        <UiButton
          variant="white"
          size="sm"
          @click="amount = 100"
        >
          <div class="text-center">
            $100
          </div>
        </UiButton>
      </div>

      <UiInputGroup label="Name">
        <UiInput
          v-model="billingInfo.name"
          placeholder="Vitalik Buterin"
        />
      </UiInputGroup>

      <UiInputGroup label="Country">
        <UiSelectCustom
          v-model="billingInfo.country"
          :options="countries.map(i => ({ id: i.code, name: i.name }))"
        />
      </UiInputGroup>

      <UiInputGroup label="Payment method">
        <ul class="flex flex-col gap-3">
          <li class="py-3 px-3 bg-gray-800 rounded-xl border border-gray-800 ">
            <div
              class="flex justify-between items-center cursor-pointer"
              @click="selectedPaymentMethodType = 'card'"
            >
              <div class="flex gap-3 items-center leading-none">
                <div>
                  <input
                    v-model="selectedPaymentMethodType"
                    class="w-5 h-5 text-indigo-600 dark:bg-gray-800 rounded-full border-gray-300 dark:border-gray-700 focus:ring-indigo-500 ring-offset-transparent"
                    type="radio"
                    value="card"
                  >
                </div>

                <div>
                  New Card
                </div>
              </div>
            </div>

            <div
              v-if="selectedPaymentMethodType === 'card'"
              class="mt-6"
            >
              <UiInputGroup
                label="Card information"
                class="mb-0"
              >
                <CreditCardForm ref="cardForm" />
              </UiInputGroup>
            </div>
          </li>

          <li
            v-for="item in existingPaymentMethods"
            :key="item.id"
            class="py-3 px-3 bg-gray-800 rounded-xl border border-gray-800"
          >
            <div
              class="flex justify-between items-center cursor-pointer"
              @click="selectedPaymentMethodType = item.id"
            >
              <div class="flex gap-3 items-center leading-none">
                <div>
                  <input
                    v-model="selectedPaymentMethodType"
                    class="w-5 h-5 text-indigo-600 dark:bg-gray-800 rounded-full border-gray-300 dark:border-gray-700 focus:ring-indigo-500 ring-offset-transparent"
                    type="radio"
                    :value="item.id"
                  >
                </div>

                <div>
                  <i
                    :class="`fab fa-cc-${item.type}`"
                    class="mr-2 fa-xl"
                  />
                  {{ item.displayName }}
                </div>
              </div>
            </div>
          </li>

          <li
            class="py-3 px-3 bg-gray-800 rounded-xl border border-gray-800 "
          >
            <div
              class="flex justify-between items-center cursor-pointer"
            >
              <div
                class="flex gap-3 items-center leading-none"
                @click="selectedPaymentMethodType = 'crypto'"
              >
                <div>
                  <input
                    v-model="selectedPaymentMethodType"
                    class="w-5 h-5 text-indigo-600 dark:bg-gray-800 rounded-full border-gray-300 dark:border-gray-700 focus:ring-indigo-500 ring-offset-transparent"
                    type="radio"
                    value="crypto"
                  >
                </div>

                <div>
                  Crypto
                </div>
              </div>
            </div>
          </li>
        </ul>
      </UiInputGroup>

      <div
        v-if="passBalance > 0"
        class="py-2 px-3 bg-gradient-to-b from-green-400 to-blue-500 rounded-xl text-shadow-default"
        style="text-shadow: 0 1px 2px rgba(00,0,0,.25);"
      >
        <div class="text-lg font-bold">
          Boost Your Deposit with Lifetime Passes
        </div>

        <div class="mb-2 text-sm">
          Want to get the most out of your deposit? Holders of our Lifetime Passes can receive up to a 50% bonus on their deposit. The more passes you hold, the bigger your bonus!
        </div>

        <div class="text-sm font-bold">
          Your current bonus level is {{ Math.min(passBalance, 5) * 10 }}%.
        </div>
      </div>
    </div>

    <UiModalFooter>
      <UiButton
        :disabled="!isFormValid"
        :is-loading="isDepositing"
        @click="deposit"
      >
        Deposit
      </UiButton>
    </UiModalFooter>
  </UiModal>
</template>

<script setup>
import {
  ref, computed, reactive, inject,
} from 'vue';
import usePaymentMethods from '@/composition/payment-methods/use-payment-methods';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useStore } from 'vuex';
import useBuyWithCrypto from '@/composition/coinbase/use-buy-with-crypto';
import { usePremium } from '@/composition/premium';
import { getData } from 'country-list';
import UiModal from '../ui/UiModal.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiInput from '../ui/UiInput.vue';
import UiButton from '../ui/UiButton.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import CreditCardForm from '../account/CreditCardForm.vue';
import useDialogs from '../ui/composition/use-dialogs';
import UiSelectCustom from '../ui/UiSelectCustom.vue';

const store = useStore();
const passBalance = computed(() => store.getters['auth/passBalance']);
const billingInfo = reactive({
  name: store.getters['auth/user'].billingName || '',
  country: store.getters['auth/user'].billingCountry || '',
});

const countries = getData();

const { requirePremium } = usePremium();

const isVisible = ref(false);

const show = async () => {
  await requirePremium('to deposit automation credits');
  isVisible.value = true;
};
const amount = ref('');
defineExpose({
  show,
});

const selectedPaymentMethodType = ref('card');

const cardForm = ref(null);

const {
  paymentMethods: existingPaymentMethods,
  createPaymentMethod,
} = usePaymentMethods();

const isCardValid = computed(() => {
  if (cardForm.value) {
    return cardForm.value.isValid;
  }
  return false;
});

const {
  onPaymentDetected,
  offPaymentDetected,
  onFailure,
} = useBuyWithCrypto();

const isFormValid = computed(() => {
  if (!amount.value || amount.value < 0) {
    return false;
  }

  if (selectedPaymentMethodType.value === 'card') {
    return isCardValid.value;
  }

  if (selectedPaymentMethodType.value === 'crypto') {
    return true;
  }

  if (!selectedPaymentMethodType.value) {
    return false;
  }

  return true;
});

const { mutate: depositAutomationCreditsWithCryptoMutation } = useMutation(gql`
  mutation DepositAutomationCreditsWithCrypto($amount: Float!, $name: String!, $country: String!) {
    depositAutomationCreditsWithCrypto(amount: $amount, name: $name, country: $country)
  }
`, {
  errorPolicy: 'none',
});
const { notify } = useDialogs();
const finishDeposit = () => {
  notify({
    title: 'Deposit successful',
    message: 'Your deposit was successful, your credits will be added shortly.',
  });
  isVisible.value = false;
};
const isDepositing = ref(false);
const buyWithCryptoInstance = ref(null);
const onCryptoPaymentReceivedCallback = () => {
  offPaymentDetected(onCryptoPaymentReceivedCallback);
  if (buyWithCryptoInstance.value) {
    buyWithCryptoInstance.value.hideModal();
  }
  isDepositing.value = false;
  setTimeout(() => {
    finishDeposit();
  }, 800);
};

const depositWithCrypto = async (event) => {
  const { data: { depositAutomationCreditsWithCrypto: chargeId } } = await depositAutomationCreditsWithCryptoMutation({
    amount: amount.value,
    ...billingInfo,
  });

  const buttonId = `bwc-${Math.floor(1e16 * Math.random()).toString(16)}`;
  buyWithCryptoInstance.value = new window.BuyWithCrypto();

  buyWithCryptoInstance.value.code = chargeId;
  buyWithCryptoInstance.value.link = event.target;
  buyWithCryptoInstance.value.buttonId = buttonId;
  buyWithCryptoInstance.value.params = { buttonId };
  buyWithCryptoInstance.value.preloadModal();
  buyWithCryptoInstance.value.showModal();
  isDepositing.value = false;
  onPaymentDetected(onCryptoPaymentReceivedCallback);
};

const { mutate: depositAutomationCreditsWithCardMutation } = useMutation(gql`
  mutation DepositAutomationCreditsWithCard($amount: Float!, $paymentMethodId: String!, $name: String!, $country: String!) {
    depositAutomationCreditsWithCard(amount: $amount, paymentMethodId: $paymentMethodId, name: $name, country: $country)
  }
`, {
  errorPolicy: 'none',
});

const depositWithCard = async () => {
  let paymentMethodId = selectedPaymentMethodType.value;
  if (paymentMethodId === 'card') {
    const { token } = await cardForm.value.tokenize();
    const { data } = await createPaymentMethod({
      token,
    });

    paymentMethodId = data.createPaymentMethod.id;
    selectedPaymentMethodType.value = paymentMethodId;
  }

  return depositAutomationCreditsWithCardMutation({
    amount: parseFloat(amount.value),
    paymentMethodId,
    ...billingInfo,
  }).then(() => {
    finishDeposit();
  }).finally(() => {
    isDepositing.value = false;
  });
};

const deposit = async (event) => {
  isDepositing.value = true;
  try {
    if (selectedPaymentMethodType.value === 'crypto') {
      return await depositWithCrypto(event);
    }

    return await depositWithCard(event);
  } catch (e) {
    notify({
      title: 'Deposit failed',
      message: e.message,
    });
    throw e;
  } finally {
    isDepositing.value = false;
  }
};

</script>

<style lang="scss" scoped>

</style>
