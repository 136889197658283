<template>
  <div class="mx-auto max-w-screen-2xl">
    <UiCard class="py-6 mb-6">
      <div
        class="
          md:grid-cols-5
          gap-y-8
          dark:divide-gray-700
          divide-x divide-gray-200
          grid grid-cols-2
        "
      >
        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="!isRefetching && isMintMetricsLoading"
            title="Total Minted"
            :value="getValue('mintMetrics.mintCount')"
            :formatter="formatNumber"
          >
            <template #extra>
              <div>
                <UiProgressBar
                  v-if="collection.maxSupply"
                  :min="0"
                  :max="collection.maxSupply"
                  :value="getValue('mintMetrics.mintCount')"
                  class="mt-2"
                />
              </div>
            </template>
          </MetricWidget>
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="!isRefetching && isMintMetricsLoading"
            title="Mint Price"
            :value="getValue('mintMetrics.mintPrice')"
            :formatter="formatCurrency"
          />
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="!isRefetching && isMintMetricsLoading"
            title="Total Value"
            :value="getValue('mintMetrics.totalValue')"
            :formatter="formatCurrency"
          />
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="!isRefetching && isMintMetricsLoading"
            title="Median Tokens per TX"
            :value="getValue('mintMetrics.medianTokensPerTransaction')"
            :formatter="formatNumber"
          />
        </UiGridItem>

        <UiGridItem class="px-6">
          <MetricWidget
            :is-loading="!isRefetching && isMintMetricsLoading"
            title="Peak Mints Per Hour"
            :value="getValue('mintMetrics.mintsPerHour')"
            :formatter="formatNumber"
          >
            <template #value>
              <div class="flex space-x-2">
                <div class="font-mono">
                  {{ formatNumber(getValue("mintMetrics.mintsPerHour")) }}
                </div>

                <MintSpeedBenchmark
                  :value="getValue('mintMetrics.mintsPerHour')"
                />
              </div>
            </template>
          </MetricWidget>
        </UiGridItem>
      </div>
    </UiCard>

    <div class="grid md:grid-cols-12 gap-6 mb-6">
      <UiCard
        title="Mints Over Time"
        class="md:col-span-8"
      >
        <template
          v-if="!isMintMetricsLoading || isRefetching"
          #title-right
        >
          <div class="text-sm text-gray-400">
            Started
            <span
              :title="moment(getValue('mintMetrics.firstMintTimestamp')).format('lll')"
            >
              {{ moment(getValue("mintMetrics.firstMintTimestamp")).from() }}
            </span>
            , last mint
            <span
              :title="moment(getValue('mintMetrics.lastMintTimestamp')).format('lll')"
            >{{
              moment(getValue("mintMetrics.lastMintTimestamp")).from()
            }}</span>
          </div>
        </template>

        <UiCardBody>
          <Chart
            :is-loading="!isRefetching && isMintMetricsLoading"
            v-bind="mintRateChartOptions"
          />
        </UiCardBody>
      </UiCard>

      <UiCard
        title="Top Minters"
        class="md:col-span-4"
      >
        <template
          v-if="!isMintMetricsLoading || isRefetching"
          #title-right
        >
          <div class="text-sm text-gray-400">
            {{
              formatNumber(getValue("mintMetrics.uniqueMintersCount"))
            }}
            unique minters
          </div>
        </template>

        <UiTable
          :is-loading="!isRefetching && isMintersLoading"
          :fields="['wallet', 'count']"
          :items="getValue('minters')"
          class="border-none shadow-none h-100"
          no-sticky
        >
          <template #cell(wallet)="{ item }">
            <WalletProfile :wallet="item.wallet" />
          </template>
        </UiTable>
      </UiCard>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  reactive,
} from 'vue';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { get } from 'lodash-es';
import moment from 'moment-timezone';
import { useRefetchQueries } from '@/composition/refetch-queries';
import collectionMintersQuery from '@/graphql/collection/queries/collectionMinters.query.gql';
import collectionMintMetricsQuery from '@/graphql/collection/queries/collectionMintMetrics.query.gql';
import { useCollection } from '@/composition/collections';

import { formatNumber, formatCurrency } from '@/utils/filters';
import UiCard from '../ui/UiCard.vue';
import UiGridItem from '../ui/UiGridItem.vue';
import MetricWidget from '../MetricWidget.vue';
import Chart, { ChartColorMap, createTooltip } from '../Chart.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import UiTable from '../ui/UiTable.vue';
import WalletProfile from '../wallets/WalletProfile.vue';
import MintSpeedBenchmark from '../MintSpeedBenchmark.vue';
import UiProgressBar from '../UiProgressBar.vue';

export default defineComponent({
  name: 'CollectionMinting',

  components: {
    UiCard,
    UiGridItem,
    MetricWidget,
    Chart,
    UiCardBody,
    UiTable,
    WalletProfile,
    MintSpeedBenchmark,
    UiProgressBar,
  },

  setup() {
    const route = useRoute();
    const contractAddress = computed(() => route.params.contractAddress);
    const { collection } = useCollection({ contractAddress });

    const commonVars = reactive({
      address: contractAddress,
    });

    const {
      result: mintersResult,
      refetch: mintersRefetch,
      loading: isMintersLoading,
    } = useQuery(collectionMintersQuery, () => commonVars);
    const minters = computed(() => mintersResult.value?.collection?.minters);

    const {
      result: mintMetricsResult,
      refetch: mintMetricsRefetch,
      loading: isMintMetricsLoading,
    } = useQuery(collectionMintMetricsQuery, () => commonVars);
    const mintMetrics = computed(() => mintMetricsResult.value?.collection?.mintMetrics);

    const { isRefetching } = useRefetchQueries({
      minters: mintersRefetch,
      'mint-metrics': mintMetricsRefetch,
    });

    return {
      minters,
      mintMetrics,
      isMintersLoading,
      isMintMetricsLoading,
      isRefetching,
      formatNumber,
      formatCurrency,
      moment,
      collection,
    };
  },

  computed: {
    getValue() {
      return (key) => get(this, key);
    },

    mintRateChartOptions() {
      const labels = this.mintMetrics?.mintRateChart?.map((item) => parseInt(moment(item.key).format('x')));

      const tokensMinted = this.mintMetrics?.mintRateChart?.map((item, index) => [
        labels[index],
        item.value,
      ]);

      return {
        options: {
          chart: {
            type: 'column',
          },
          xAxis: {
            type: 'datetime',
          },
          yAxis: [
            // {
            //   name: 'Avg Gas',
            //   opposite: true,
            //   title: {
            //     enabled: false,
            //   },
            // },
            {
              name: 'Tokens Minted',
              opposite: false,
              title: {
                enabled: false,
              },
            },
          ],
          tooltip: createTooltip({
            formatters: {
              'Tokens Minted': formatNumber,
            },
          }),
          series: [
            {
              name: 'Tokens Minted',
              data: tokensMinted,
              yAxis: 0,
            },
          ],
        },
      };
    },
  },
});
</script>
