<template>
  <div
    :id="id"
    class="dark:bg-gray-800 rounded-2xl border border-gray-800"
    style="contain: paint;"
  >
    <div
      class="flex justify-between items-center dark:bg-gray-800 border-gray-900 cursor-pointer accordion-toggle"
      :class="{'border-b rounded-t-2xl': isOpen, 'rounded-2xl': !isOpen, 'sticky top-24 z-10': isOpen && stickyHead !== false, 'p-4': size === 'md', 'py-2 px-3': size === 'sm'}"
      @click="toggle"
    >
      <slot name="name">
        <div class="flex items-center select-none">
          <div
            v-if="$slots.icon"
            class="w-8 text-xl"
          >
            <slot name="icon" />
          </div>

          <div class="text-gray-300">
            {{ name }}
          </div>
        </div>
      </slot>

      <div class="flex gap-4 items-center px-2">
        <slot name="actions" />

        <div>
          <i
            class="text-gray-100 transform far fa-chevron-down"
            :class="{'rotate-180': isOpen, 'transition-all duration-300': noTransition === false}"
          />
        </div>
      </div>
    </div>

    <div
      class=" bg-gray-850 accordion__content "
      :class="[
        isOpen ? `${maxH} ${maxH !== 'max-h-full' ? 'overflow-scroll' : 'overflow-visible'}` : 'max-h-0 overflow-hidden',
        {'overflow-hidden': !isOpen, 'overflow-scroll': isOpen && noScroll === false && maxH !== 'max-h-full', 'overflow-visible': isOpen && noScroll === false && maxH === 'max-h-full' },
        noScroll === false && isOpen ? maxH : '',
        {'accordion__content--transition': noTransition === false},
        {'max-w-full scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800 scrollbar-thumb-rounded-full scrollbar-track-rounded-full': noScroll === false},
      ]"
    >
      <slot
        v-if="isOpen"
        :is-open="isOpen"
        :open="() => isOpen = true"
      />
    </div>
  </div>
</template>

<script setup>

import {
  computed, toRefs, watch,
} from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  modelValue: {
    type: String,
    default: null,
  },
  id: {
    type: String,
    default: null,
  },
  maxH: {
    type: String,
    default: 'max-h-96',
  },
  noTransition: {
    type: Boolean,
    default: false,
  },
  noScroll: {
    type: Boolean,
    default: false,
  },
  stickyHead: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
  },
});
const emit = defineEmits(['update:modelValue', 'open', 'close']);

const {
  name, modelValue, id, maxH, noTransition, noScroll, stickyHead, disabled, size,
} = toRefs(props);

const isOpen = computed({
  get() {
    if (disabled.value === true) return false;

    return modelValue.value === (id.value || name.value);
  },
  set(value) {
    if (disabled.value === true) return;
    if (value) {
      emit('update:modelValue', (id.value || name.value));
    } else {
      emit('update:modelValue', null);
    }
  },
});

watch(() => isOpen.value, (value) => {
  if (value) {
    emit('open');
  } else {
    emit('close');
  }
}, {
  immediate: true,
});

const toggle = () => isOpen.value = !isOpen.value;
</script>

<style>
.accordion__content--transition {
  transition: max-height .5s ease;
}
</style>
