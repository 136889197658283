<template>
  <UiDropdown
    :body-width-class="hasListingAlert ? 'w-48' : (isPremium ? 'w-72' : 'w-80')"
    right
  >
    <template #toggle="{toggle}">
      <UiButton
        class="w-"
        size="lg"
        variant="primary-reverse"
        @click="toggle"
      >
        <span v-if="hasListingAlert">Manage Listing Alert</span>

        <span v-else>Create Listing Alert</span>
      </UiButton>
    </template>

    <template #body="{toggle}">
      <UiDropdownForm v-if="!hasListingAlert">
        <PremiumPlaceholder action="Get notified when this token is listed for sale">
          <ChannelPicker
            v-model="channelIds"
            class="mb-3"
          />

          <div>
            <UiButton
              :disabled="channelIds.length === 0"
              size="sm"
              :is-loading="isCreating"
              @click="createAlert(() => toggle())"
            >
              Create
            </UiButton>
          </div>
        </PremiumPlaceholder>
      </UiDropdownForm>

      <UiDropdownItem
        v-else
        @click="deleteRevealAlert()"
      >
        Delete Alert
      </UiDropdownItem>
    </template>
  </UiDropdown>
</template>

<script setup>
import { useApolloClient, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, ref, toRefs } from 'vue';
import deleteAlertMutation from '@/graphql/alert/mutations/deleteAlert.mutation.gql';
import createAlertMutation from '@/graphql/alert/mutations/createAlert.mutation.gql';
import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownForm from '@/components/ui/UiDropdownForm.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import PremiumPlaceholder from '@/components/PremiumPlaceholder.vue';
import ChannelPicker from '@/components/alerts/ChannelPicker.vue';
import UiButton from '@/components/ui/UiButton.vue';
import { usePremium } from '@/composition/premium';

const props = defineProps(['token']);

const { token } = toRefs(props);

const tokenId = computed(() => token.value.id);

const tokenIdParts = computed(() => tokenId.value.split('_'));
const listingAlertsQuery = gql`
  query GetTokenListingAlerts($tokenId: String!) {
    listingAlerts(tokenId: $tokenId) {
      id
    }
  }
`;
const { result } = useQuery(listingAlertsQuery, () => ({ tokenId: tokenId.value }));

const hasListingAlert = computed(() => result.value?.listingAlerts?.length > 0);

const listingAlert = computed(() => result.value?.listingAlerts?.[0] || null);

const isCreating = ref(false);

const channelIds = ref([]);

const { isPremium } = usePremium();

const { resolveClient } = useApolloClient('default');
const apolloClient = resolveClient();

const createAlert = (callback = () => {}) => {
  isCreating.value = true;
  return apolloClient.mutate({
    mutation: createAlertMutation,
    variables: {
      name: `${token.value.collection.name} #${token.value.tokenId} Listing Alert`,
      config: {
        collectionId: tokenIdParts.value[0],
        tokenFilters: {
          attributeValueIds: [],
          priceFrom: null,
          priceTo: null,
          tokenId: tokenIdParts.value[1],
          owner: null,
        },
      },
      type: 'COLLECTION_LISTINGS',
      channels: channelIds.value,
    },
    awaitRefetchQueries: true,
    refetchQueries() {
      return [
        { query: listingAlertsQuery, variables: { tokenId: tokenId.value } },
      ];
    },
  }).then(callback).finally(() => {
    isCreating.value = false;
  });
};

const deleteRevealAlert = (callback = () => {}) => {
  if (!hasListingAlert.value) {
    return Promise.resolve();
  }
  return apolloClient
    .mutate({
      mutation: deleteAlertMutation,
      variables: {
        id: listingAlert.value.id,
      },
      awaitRefetchQueries: true,
      refetchQueries() {
        return [
          { query: listingAlertsQuery, variables: { tokenId: tokenId.value } },
        ];
      },
    })
    .then(callback);
};
</script>
