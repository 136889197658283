<template>
  <div class="mx-auto max-w-screen-2xl">
    <UiCard
      is-overflow-auto
      style="contain:paint"
    >
      <UiCardBody>
        <Filters
          v-model="filters"
          :filters="availableFilters"
          group="leaderboard"
        />
      </UiCardBody>

      <UiTable
        :items="leaderboard"
        :is-loading="loading"
        :fields="['rank', 'wallet', 'totalProfit', 'purchases', 'purchasePrice', 'salePrice', 'firstPurchasedAt', 'avgHeldFor', 'actions']"
      >
        <template #head(rank)>
          <span />
        </template>

        <template #cell(rank)="{item}">
          <div class="w-0 text-xl font-light tabular-nums">
            #{{ item.rank }}
          </div>
        </template>

        <template #cell(wallet)="{item}">
          <WalletProfile :wallet="item.wallet" />
        </template>

        <template #cell(totalProfit)="{item}">
          <CurrencyDisplay :value="item.totalProfit" />

          <div class="text-xs text-gray-400">
            <CurrencyDisplay :value="item.avgProfit" /> avg.
          </div>
        </template>

        <template #cell(firstPurchasedAt)="{item}">
          <Timestamp :timestamp="item.firstPurchasedAt" />
        </template>

        <template #cell(avgHeldFor)="{item}">
          {{ moment.duration(item.avgHeldFor * 1000).humanize() }}
        </template>

        <template #cell(purchasePrice)="{item}">
          <sub
            v-if="item.minPurchasePrice !== item.avgPurchasePrice"
            v-tooltip="'Lowest Price'"
            class="text-gray-300"
          >{{ formatNumber(item.minPurchasePrice) }}</sub>

          <span class="mx-1 font-medium">{{ formatNumber(item.avgPurchasePrice) }}</span>

          <sup
            v-if="item.maxPurchasePrice != item.avgPurchasePrice"
            class="text-gray-300"
          >{{ formatNumber(item.maxPurchasePrice) }}</sup>
        </template>

        <template #cell(salePrice)="{item}">
          <sub
            v-if="item.minSalePrice !== item.avgSalePrice"
            class="font-light text-gray-300"
          >{{ formatNumber(item.minSalePrice) }}</sub>

          <span class="mx-1 font-medium">{{ formatNumber(item.avgSalePrice) }}</span>

          <sup
            v-if="item.maxSalePrice != item.avgSalePrice"
            class="font-light text-gray-300"
          >{{ formatNumber(item.maxSalePrice) }}</sup>
        </template>

        <template #cell(purchases)="{item}">
          <div class="mb-1 text-sm font-light text-gray-300">
            <span class="font-bold">{{ formatNumber(item.uniqueTokensFlipped) }}</span> tokens flipped
          </div>

          <div class="flex overflow-hidden items-center w-full h-3 rounded-full">
            <div
              v-for="i in item.purchases"
              :key="i.type"
              v-tooltip="purchaseTypeToVerb[i.type](i.count)"
              class="h-full"
              :class="purchaseTypeClassMap[i.type]"
              :style="{'width': `${100 * i.rate}%`}"
            />
          </div>
        </template>

        <template #cell(actions)="{item}">
          <UiButton
            tag="router-link"
            :to="{name: routesNames.wallet.analytics, params: {address: item.wallet.id}, query: {filters: encodeFilters([{key:'collectionId',type:'collection',value:{comparator: 'in', value: [collectionId]}}])}}"
            variant="white"
            size="xs"
          >
            View Flips
          </UiButton>
        </template>
      </UiTable>
    </UiCard>
  </div>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment-timezone';
import { formatCurrency, formatNumber } from '@/utils/filters';
import routesNames from '@/router/routesNames';
import { FILTER_TYPES } from '@/constants/filters';
import { encodeFilters } from '@/composition/filters/useFiltersQuerySync';
import { purchaseTypeClassMap, purchaseTypeToVerb } from '@/utils/leaderboard';
import UiTable from '../ui/UiTable.vue';
import WalletProfile from '../wallets/WalletProfile.vue';
import CurrencyDisplay from '../CurrencyDisplay.vue';
import UiButton from '../ui/UiButton.vue';
import Timestamp from '../Timestamp.vue';
import UiCard from '../ui/UiCard.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import Filters from '../filters/Filters.vue';

const query = gql`
query GetCollectionProfitLeaderboard($collectionId: String!, $filters: [FilterArg!]) {
  collection(where: {id: $collectionId}) {
    id
    profitLeaderboard(filters: $filters) {
      id
      rank
      totalProfit
      firstPurchasedAt
      purchases {
        count
        type
        rate
      }
      wallet {
        id
        displayName
      }
      avgHeldFor
      avgProfit
      maxPurchasePrice
      maxSalePrice
      minPurchasePrice
      minSalePrice
      avgSalePrice
      avgPurchasePrice
      uniqueTokensFlipped
    }
  }
}
`;

const availableFilters = [
  {
    key: 'totalProfit',
    name: 'Total Profit',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'avgProfit',
    name: 'Avg Profit',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'minSalePrice',
    name: 'Min Sale Price',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'maxSalePrice',
    name: 'Max Sale Price',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'minPurchasePrice',
    name: 'Min Purchase Price',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'maxPurchasePrice',
    name: 'Max Purchase Price',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'avgHeldFor',
    name: 'Avg Held For',
    type: FILTER_TYPES.INTERVAL,
  },
  {
    key: 'tokensFlipped',
    name: 'Tokens Flipped',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'tokensMinted',
    name: 'Mints',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'tokensPurchased',
    name: 'Purchases',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'tokensAirdropped',
    name: 'Tokens Received',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'tokensWon',
    name: 'Bids Won',
    type: FILTER_TYPES.NUMBER,
  },
  {
    key: 'firstPurchasedAt',
    name: 'First Purchased At',
    type: FILTER_TYPES.TIMESTAMP,
  },
];
const filters = ref([]);

const route = useRoute();
const collectionId = computed(() => route.params.contractAddress);

const { result, loading } = useQuery(query, () => ({ collectionId: collectionId.value, filters: filters.value }));

const leaderboard = computed(() => result.value?.collection?.profitLeaderboard || []);
</script>
