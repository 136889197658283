<template>
  <div>
    <slot v-bind="{show}" />

    <UiModal v-model="isModalVisible">
      <UiModalHeader
        title="Report Collection"
        @dismiss="isModalVisible = false"
      />

      <div class="p-3">
        <UiRadioCards
          v-model="reason"
          size="md"
          :options="mappedReasons"
        />

        <div class="flex md:justify-end mt-4">
          <UiButton
            :is-loading="isReporting"
            size="lg"
            :disabled="!reason"
            @click="sendReport"
          >
            Send report
          </UiButton>
        </div>
      </div>
    </UiModal>
  </div>
</template>

<script setup>
import { ReportReason, useCollectionReport } from '@/composition/collections/useCollectionReport';
import { toRefs, computed, ref } from 'vue';
import { usePremium } from '@/composition/premium';
import UiRadioCards from '../ui/UiRadioCards.vue';
import UiModal from '../ui/UiModal.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';
import UiButton from '../ui/UiButton.vue';
import { useUiDialog } from '../ui-dialog/useUiDialog';

const props = defineProps(['collectionId']);

const reason = ref(null);

const { collectionId } = toRefs(props);

const {
  isReported,
  report,
  reasons,
  isReporting,
} = useCollectionReport(collectionId);

const { info, error } = useUiDialog();

const mappedReasons = computed(() => reasons?.map((item) => ({
  title: item.name,
  value: item.id,
  description: item.description,
})) || []);
const { requireAuth } = usePremium();
const isModalVisible = ref(false);
const show = async () => {
  await requireAuth().then(() => {
    isModalVisible.value = true;
    reason.value = null;
  });
};

const sendReport = () => report(reason.value).then(() => {
  isModalVisible.value = false;
  info("We've received your report. The team will review it as soon as possible.", { icon: 'fad fa-check-circle text-green-500 text-2xl' });
}).catch((err) => {
  error(err.message);
});
</script>
