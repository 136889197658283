<template>
  <UiModal v-model="isVisible">
    <UiModalHeader
      title="Update variable"
      @dismiss="isVisible = false"
    />

    <div class="py-4 px-3">
      <UiTabs pills>
        <UiTab name="Builder">
          <div>
            <UiInputGroup label="Value">
              <div
                class="inline-block items-center py-1.5 px-2 mb-3 leading-none rounded shadow-lg text-shadow-md"
                :class="variableSchema(model.initial) ? 'bg-blue-600 text-white' : 'bg-yellow-500 text-yellow-900'"
              >
                <div class="font-medium">
                  {{ modelName(model.initial) }}
                </div>

                <div
                  v-if="variableSchema(model.initial)?.description"
                  class="mt-2 text-sm leading-none opacity-80"
                >
                  {{ variableSchema(model.initial)?.description }}
                </div>
              </div>
            </UiInputGroup>

            <ul>
              <li
                v-for="(item, index) in model.filters"
                :key="index"
                class="flex justify-between items-center py-2 px-3 mb-2 bg-gray-800 rounded-lg"
              >
                <div class="flex flex-wrap gap-2 items-center">
                  <div class=" w-5 text-center">
                    <i
                      :class="filterModel(item.name).icon"
                    />
                  </div>

                  <div class=" whitespace-nowrap">
                    {{ filterModel(item.name).name }}
                  </div>

                  <div
                    v-for="(arg, argIndex) in item.args"
                    :key="argIndex"
                    class="flex gap-2 items-center"
                  >
                    <div
                      v-if="variableSchema(arg.value)"
                      class="inline-flex items-center py-1 px-1.5 text-sm leading-none text-white bg-blue-600 rounded shadow-lg text-shadow-md"
                    >
                      {{ modelName(arg.value) }}
                      <button
                        class="ml-1 text-gray-100 hover:text-white"
                        @click="updateFilterValue(index, argIndex, '')"
                      >
                        <i class="far fa-times" />
                      </button>
                    </div>

                    <input
                      v-else
                      v-autofocus
                      :tab-index="argIndex"
                      type="text"
                      :value="arg.value"
                      :placeholder="arg.name"
                      class="py-0 px-0 ml-2 leading-none text-gray-200 focus:text-white bg-transparent border-0 border-b border-gray-300 focus:border-indigo-500 focus:ring-0 focus:outline-none"
                      @input="updateFilterValue(index, argIndex, $event.target.value)"
                    >

                    <UiButton
                      tabindex="-1"
                      size="xs"
                      variant="white"
                      @click="onSelectVariable(filterModel(item.name).args[argIndex].type, index, argIndex)"
                    >
                      <!-- curly brackets -->
                      <i class="fas fa-brackets-curly" />
                    </UiButton>
                  </div>
                </div>

                <div>
                  <button
                    class="text-gray-400 hover:text-gray-200"
                    @click="removeFilter(index)"
                  >
                    <i class="fas fa-times" />
                  </button>
                </div>
              </li>
            </ul>

            <div class="flex flex-wrap gap-2 mt-2">
              <UiButton
                v-for="item in availableFilters"
                :key="item.id"
                v-tooltip="item.description"
                size="xs"
                variant="white"
                @click="addFilter(item)"
              >
                <i
                  :class="item.icon"
                  class="mr-1.5"
                />
                {{ item.name }}
              </UiButton>
            </div>
          </div>

          <UiHiddenContent
            title="Test variable"
            class="mt-3"
          >
            <UiInputGroup
              v-for="item in dependencies"
              :key="item"
              :label="modelName(item)"
            >
              <UiInput

                :model-value="sampleContext[item]"
                :placeholder="`Value for ${modelName(item)}`"
                @update:modelValue="set(sampleContext, item, $event)"
              />
            </UiInputGroup>

            Result: {{ previewValue }}
          </UiHiddenContent>
        </UiTab>

        <UiTab name="Advanced">
          <UiTextArea
            v-model="template"
            rows="5"
          />
        </UiTab>
      </UiTabs>
    </div>

    <UiModalFooter>
      <UiButton
        size="sm"
        @click="update(template)"
      >
        Done
      </UiButton>
    </UiModalFooter>
  </UiModal>
</template>

<script setup>
import { computed, ref, inject } from 'vue';
import { Liquid, Context } from 'liquidjs';
import useVariableEditor from '@/composition/automation/use-variable-editor';
import { set, uniq } from 'lodash-es';
import { displayName, humanize } from '@/utils/filters';
import UiModal from '../ui/UiModal.vue';
import UiInput from '../ui/UiInput.vue';
import UiButton from '../ui/UiButton.vue';
import UiTabs from '../ui/UiTabs.vue';
import UiTextArea from '../ui/UiTextArea.vue';
import UiTab from '../ui/UiTab.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiHiddenContent from '../ui/UiHiddenContent.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';

const liquid = new Liquid();
const selectVariable = inject('selectVariable');
const {
  isModalVisible: isVisible, model: template, type, update,
} = useVariableEditor();

const variableSchema = inject('variableSchema');

class TrappedContext extends Context {
  constructor(...args) {
    super(...args);
    this.accessedVariables = new Set();
  }

  // eslint-disable-next-line no-underscore-dangle
  _get(key) {
    this.accessedVariables.add(key);
    // eslint-disable-next-line no-underscore-dangle
    return super._get(key);
  }
}

const LIQUID_FILTERS = [
  {
    id: 'abs',
    name: 'Absolute value',
    description: 'Returns the absolute value of the number.',
    args: [],
    icon: 'fad fa-value-absolute',
    type: 'Number',
  },
  {
    id: 'at_least',
    name: 'At least',
    description: 'Returns the number if it is greater than or equal to the argument, otherwise returns the argument.',
    args: [
      {
        name: 'Number',
        type: 'Number',
      },
    ],
    icon: 'fad fa-balance-scale-left',
    type: 'Number',
  },
  {
    id: 'at_most',
    name: 'At most',
    description: 'Returns the number if it is less than or equal to the argument, otherwise returns the argument.',
    args: [
      {
        name: 'Number',
        type: 'Number',
      },
    ],
    icon: 'fad fa-balance-scale-right',
    type: 'Number',
  },
  {
    id: 'ceil',
    name: 'Ceiling',
    description: 'Returns the smallest integer greater than or equal to the number.',
    args: [],
    icon: 'fad fa-sort-numeric-up',
    type: 'Number',
  },
  {
    id: 'divided_by',
    name: 'Divided by',
    description: 'Returns the number divided by the argument.',
    args: [
      {
        name: 'Number',
        type: 'Number',
      },
    ],
    icon: 'fad fa-divide',
    type: 'Number',
  },
  {
    id: 'floor',
    name: 'Floor',
    description: 'Returns the largest integer less than or equal to the number.',
    args: [],
    icon: 'fad fa-sort-numeric-down',
    type: 'Number',
  },
  {
    id: 'minus',
    name: 'Minus',
    description: 'Returns the number minus the argument.',
    args: [
      {
        name: 'Number',
        type: 'Number',
      },
    ],
    icon: 'fad fa-minus',
    type: 'Number',
  },
  {
    id: 'modulo',
    name: 'Modulo',
    description: 'Returns the remainder of the number divided by the argument.',
    args: [
      {
        name: 'Number',
        type: 'Number',
      },
    ],
    icon: 'fad fa-percent',
    type: 'Number',
  },
  {
    id: 'plus',
    name: 'Plus',
    description: 'Returns the number plus the argument.',
    args: [
      {
        name: 'Number',
        type: 'Number',
      },
    ],
    icon: 'fad fa-plus',
    type: 'Number',
  },
  {
    id: 'round',
    name: 'Round',
    description: 'Returns the number rounded to the nearest integer.',
    args: [
      {
        name: 'Number',
        type: 'Number',
      },
    ],
    icon: 'fad fa-sort-numeric-up-alt',
    type: 'Number',
  },
  {
    id: 'times',
    name: 'Times',
    description: 'Returns the number times the argument.',
    args: [
      {
        name: 'Number',
        type: 'Number',
      },
    ],
    icon: 'fad fa-times',
    type: 'Number',
  },
  {
    id: 'compact',
    name: 'Compact',
    description: 'Returns a copy of the array with all nil elements removed.',
    args: [],
    type: 'Array',
    icon: 'fad fa-filter',
  },
  {
    id: 'concat',
    name: 'Concat',
    description: 'Returns a new array by concatenating the two arrays together to produce a third array.',
    args: [
      {
        name: 'Array',
        type: 'Array',
      },
    ],
    type: 'Array',
    icon: 'fad fa-union',
  },
  {
    id: 'first',
    name: 'First',
    description: 'Returns the first element of the array.',
    args: [],
    type: 'Array',
    icon: 'fad fa-step-backward',
  },
  {
    id: 'join',
    name: 'Join',
    description: 'Returns a string created by converting each element of the array to a string, separated by the given argument.',
    args: [
      {
        name: 'String',
        type: 'String',
      },
    ],
    type: 'Array',
    icon: 'fad fa-font',
  },
  {
    id: 'last',
    name: 'Last',
    description: 'Returns the last element of the array.',
    args: [],
    type: 'Array',
    icon: 'fad fa-step-forward',
  },
  {
    id: 'map',
    name: 'Map',
    description: 'Returns a new array with the results of running a provided function on every element in this array.',
    args: [
      {
        name: 'String',
        type: 'String',
      },
    ],
    type: 'Array',
    icon: 'fad fa-redo-alt',
  },
  {
    id: 'reverse',
    name: 'Reverse',
    description: 'Returns a copy of the array with all elements in reverse order.',
    args: [],
    type: 'Array',
    icon: 'fad fa-undo-alt',
  },
  {
    id: 'size',
    name: 'Size',
    description: 'Returns the number of elements in the array.',
    args: [],
    type: 'Array',
    icon: 'fad fa-ruler',
  },
  {
    id: 'slice',
    name: 'Slice',
    description: 'Returns a copy of the array with all elements between the start and end indices.',
    args: [
      {
        name: 'Start',
        type: 'Number',
      },
      {
        name: 'End',
        type: 'Number',
      },
    ],
    type: 'Array',
    icon: 'fad fa-ballot-check',
  },
  {
    id: 'sort',
    name: 'Sort',
    description: 'Returns a copy of the array with all elements sorted.',
    args: [],
    type: 'Array',
    icon: 'fad fa-sort-up',
  },
  {
    id: 'uniq',
    name: 'Unique',
    description: 'Returns a copy of the array with all duplicate elements removed.',
    args: [],
    type: 'Array',
    icon: 'fad fa-asterisk',
  },
  {
    id: 'where',
    name: 'Where',
    description: 'Returns a copy of the array with all elements that match the given key and value.',
    args: [
      {
        name: 'Key',
        type: 'String',
      },
      {
        name: 'Value',
        type: 'String',
      },
    ],
    type: 'Array',
    icon: 'fad fa-search',
  },
  {
    id: 'min_by',
    name: 'Min by',
    description: 'Returns the element in the array with the minimum value of the given key.',
    args: [
      {
        name: 'Key',
        type: 'String',
      },
    ],
    type: 'Array',
    icon: 'fad fa-arrow-down',
  },
  {
    id: 'max_by',
    name: 'Max by',
    description: 'Returns the element in the array with the maximum value of the given key.',
    args: [
      {
        name: 'Key',
        type: 'String',
      },
    ],
    type: 'Array',
    icon: 'fad fa-arrow-up',
  },
  {
    id: 'sort_by',
    name: 'Sort by',
    description: 'Returns a copy of the array sorted by the given key.',
    args: [
      {
        name: 'Key',
        type: 'String',
      },
    ],
    type: 'Array',
    icon: 'fad fa-sort',
  },
  {
    id: 'append',
    name: 'Append',
    description: 'Returns a copy of the string with the argument appended to the end.',
    args: [
      {
        name: 'String',
        type: 'String',
      },
    ],
    type: 'String',
    icon: 'fad fa-angle-double-right',
  },
  {
    id: 'capitalize',
    name: 'Capitalize',
    description: 'Returns a copy of the string with the first character converted to uppercase and the remainder to lowercase.',
    args: [],
    type: 'String',
    icon: 'fad fa-font-case',
  },
  {
    id: 'downcase',
    name: 'Downcase',
    description: 'Returns a copy of the string with all uppercase letters converted to lowercase.',
    args: [],
    type: 'String',
    icon: 'fad fa-font-case',
  },
  {
    id: 'lstrip',
    name: 'Left strip',
    description: 'Returns a copy of the string with leading whitespace removed.',
    args: [],
    type: 'String',
    icon: 'fad fa-ellipsis-h',
  },
  {
    id: 'prepend',
    name: 'Prepend',
    description: 'Returns a copy of the string with the argument prepended to the beginning.',
    args: [
      {
        name: 'String',
        type: 'String',
      },
    ],
    type: 'String',
    icon: 'fad fa-angle-double-left',
  },
  {
    id: 'remove',
    name: 'Remove',
    description: 'Returns a copy of the string with all occurrences of the argument removed.',
    args: [
      {
        name: 'String',
        type: 'String',
      },
    ],
    type: 'String',
    icon: 'fad fa-trash-alt',
  },
  {
    id: 'remove_first',
    name: 'Remove first',
    description: 'Returns a copy of the string with the first occurrence of the argument removed.',
    args: [
      {
        name: 'String',
        type: 'String',
      },
    ],
    type: 'String',
    icon: 'fad fa-trash',
  },
  {
    id: 'replace',
    name: 'Replace',
    description: 'Returns a copy of the string with all occurrences of the first argument replaced with the second argument.',
    args: [
      {
        name: 'Find',
        type: 'String',
      },
      {
        name: 'Replace',
        type: 'String',
      },
    ],
    type: 'String',
    icon: 'fad fa-exchange-alt',
  },
  {
    id: 'replace_first',
    name: 'Replace first',
    description: 'Returns a copy of the string with the first occurrence of the first argument replaced with the second argument.',
    args: [
      {
        name: 'Find',
        type: 'String',
      },
      {
        name: 'Replace With',
        type: 'String',
      },
    ],
    type: 'String',
    icon: 'fad fa-exchange-alt',
  },
  {
    id: 'rstrip',
    name: 'Right strip',
    description: 'Returns a copy of the string with trailing whitespace removed.',
    args: [],
    type: 'String',
    icon: 'fad fa-ellipsis-h',
  },
  {
    id: 'split',
    name: 'Split',
    description: 'Returns an array of substrings based on splitting the string on the argument.',
    args: [
      {
        name: 'String',
        type: 'String',
      },
    ],
    type: 'String',
    icon: 'fad fa-layer-group',
  },
  {
    id: 'strip',
    name: 'Strip',
    description: 'Returns a copy of the string with leading and trailing whitespace removed.',
    args: [],
    type: 'String',
    icon: 'fad fa-ellipsis-h',
  },
  {
    id: 'truncate',
    name: 'Truncate',
    description: 'Returns a copy of the string truncated to the length of the argument.',
    args: [
      {
        name: 'Max length',
        type: 'Number',
      },
      {
        name: 'String',
        type: 'String',
      },
    ],
    type: 'String',
    icon: 'fad fa-ellipsis-h',
  },
  {
    id: 'truncatewords',
    name: 'Truncate words',
    description: 'Returns a copy of the string truncated to the number of words specified by the argument.',
    args: [
      {
        name: 'Max words',
        type: 'Number',
      },
      {
        name: 'String',
        type: 'String',
      },
    ],
    type: 'String',
    icon: 'fad fa-ellipsis-h',
  },
  {
    id: 'upcase',
    name: 'Upcase',
    description: 'Returns a copy of the string with all lowercase letters converted to uppercase.',
    args: [],
    type: 'String',
    icon: 'fad fa-arrow-up',
  },
];

const model = computed(() => {
  const [{ value }] = liquid.parse(template.value);
  return {
    initial: value.initial.postfix[0].getText(),
    filters: value.filters.map((i) => {
      const filter = LIQUID_FILTERS.find((j) => j.id === i.name);
      return {
        name: i.name,
        args: i.args.map((j, index) => ({
          name: filter?.args[index]?.name,
          value: j?.getText?.()?.replaceAll('"', ''),
        })),
      };
    }),
  };
});

const sampleContext = ref({});
const dependencies = ref([]);
const previewValue = computed(() => {
  const context = sampleContext.value;

  const liquidContext = new TrappedContext(context);
  const result = liquid.parseAndRenderSync(template.value, liquidContext);
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  dependencies.value = uniq(Array.from(liquidContext.accessedVariables).map((item) => item.join('.')).flat());
  return result;
});

const setValue = (value) => {
  const { initial, filters } = value;
  const liquidTemplate = `{{ ${initial} | ${filters.map((i) => `${i.name}${i.args.length > 0 ? ': ' : ''}${i.args.map((arg) => {
    if (variableSchema.value(arg.value)) {
      return arg.value;
    }
    return `"${arg.value || ''}"`;
  }).join(', ')}`).join(' | ')} }}`;
  template.value = liquidTemplate;
};

const availableFilters = computed(() => LIQUID_FILTERS.filter((item) => item.type === type.value));

const addFilter = async (filter) => {
  const filterArgs = [];
  for (const arg of filter.args) {
    filterArgs.push({ value: arg.type === 'number' ? 0 : '' });
  }
  setValue({
    initial: model.value.initial,
    filters: [
      ...model.value.filters,
      {
        name: filter.id,
        args: filterArgs,
      },
    ],
  });
};

const filterModel = computed(() => (key) => LIQUID_FILTERS.find((filter) => filter.id === key));

const updateFilterValue = (filterIndex, filterArgIndex, value) => {
  const newFilters = [...model.value.filters];
  newFilters[filterIndex].args[filterArgIndex].value = value || null;

  setValue({
    initial: model.value.initial,
    filters: newFilters,
  });
};

const removeFilter = (filterIndex) => {
  const newFilters = [...model.value.filters];
  newFilters.splice(filterIndex, 1);

  setValue({
    initial: model.value.initial,
    filters: newFilters,
  });
};

const onSelectVariable = async (t, filterIndex, argIndex) => {
  const variable = await selectVariable({ type: t });
  if (variable) {
    updateFilterValue(filterIndex, argIndex, variable.id);
  }
};

const modelName = computed(() => (key) => {
  const schema = variableSchema.value(key);
  if (!schema) {
    return key;
  }

  return `${displayName(humanize(schema.groupName || schema.group))} » ${displayName(humanize(schema.actingAs)) || schema.name}`;
});
</script>
