<template>
  <OverlayComponent
    :width="300"
    :no-overlay="noOverlay"
  >
    <Component
      :is="itemComponentProps.is"
      v-bind="itemComponentProps"
    >
      <div class="flex items-center">
        <div
          class="overflow-hidden flex-grow-0 flex-shrink-0 mr-3 rounded-full"
          :class="picSize ? `w-${picSize} h-${picSize}` : 'md:w-10 md:h-10 w-8 h-8'"
        >
          <img
            v-if="!isLoading && model && model.imageUrl"
            :src="model.imageUrl"
            :class="picSize ? `w-${picSize} h-${picSize}` : 'md:w-10 md:h-10 w-8 h-8'"
          >

          <img
            v-else-if="!isLoading"
            src="@/assets/image-placeholder.svg"
            :class="picSize ? `w-${picSize} h-${picSize}` : 'md:w-10 md:h-10 w-8 h-8'"
          >

          <div
            v-if="isLoading"
            class="bg-gray-700 rounded-full animate-pulse"
            :class="picSize ? `w-${picSize} h-${picSize}` : 'md:w-10 md:h-10 w-8 h-8'"
          />
        </div>

        <div>
          <div
            v-if="model && model.name"
            class="flex gap-1.5 items-center text-sm font-medium dark:text-white"
            style="max-width: 12rem"
          >
            <OverflowText class="overflow-hidden overflow-ellipsis whitespace-nowrap">
              {{ model.name }}
            </OverflowText>

            <i
              v-if="model.isVerified"
              class="text-blue-400 fas fa-badge-check"
              title="Verified on Opensea"
            />

            <LiquidityRank
              v-if="model.liquidityRank"
              :value="model.liquidityRank"
              mini
            />
          </div>

          <div
            v-if="isLoading"
            class="w-24 h-3 bg-gray-700 rounded animate-pulse"
          />

          <div
            v-if="!mini && !isLoading && model"
            class="flex space-x-1 text-xs dark:text-white opacity-75"
          >
            <slot
              name="extra-content"
              :item="model"
            >
              <span>{{ moment(model.contractCreatedTimestamp).fromNow(true) }}</span>

              <span>·</span>

              <span>{{ formatNumber(model.totalSupply, 0, false) }} tokens</span>
            </slot>
          </div>
        </div>
      </div>
    </Component>

    <template
      v-if="!isLoading"
      #overlay-content="{hover}"
    >
      <slot name="context" />

      <CollectionOverlayContent
        :collection="model"
        :is-active="hover"
      >
        <template #actions>
          <slot name="actions" />
        </template>
      </CollectionOverlayContent>
    </template>
  </OverlayComponent>
</template>

<script setup>
import {
  computed,
  toRefs,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import moment from 'moment-timezone';
import { formatNumber } from '@/utils/filters';
import collectionListItemQuery from '@/graphql/collection/queries/collectionListItem.query.gql';
import routesNames from '@/router/routesNames';
import UiLink from '@/components/ui/UiLink.vue';
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';
import OverlayComponent from '@/components/OverlayComponent.vue';
import OverflowText from '@/components/OverflowText.vue';
import CollectionOverlayContent from './CollectionOverlayContent.vue';
import LiquidityRank from '../LiquidityRank.vue';

const props = defineProps({
  collection: undefined,

  mini: undefined,

  address: undefined,

  picSize: undefined,

  targetTab: {
    type: String,
    default: routesNames.collectionAnalytics,
  },

  noOverlay: {
    default: false,
    type: Boolean,
  },

  noLink: {
    default: false,
    type: Boolean,
  },
  isLoading: {
    default: false,
    type: Boolean,
  },
});
const {
  address,
  collection,
  noLink,
  noOverlay,
  targetTab,
  mini,
  picSize,
  isLoading,
} = toRefs(props);

const {
  result: collectionListItemResult,
} = useQuery(
  collectionListItemQuery,
  () => ({ address: address.value }),
  () => ({ enabled: Boolean(address.value && !collection.value?.id) }),
);

const model = computed(() => {
  const collectionData = collectionListItemResult.value?.collection;

  if (collectionData) {
    return collectionData;
  }

  return collection.value;
});
const itemComponentProps = computed(() => {
  if (noLink.value !== false || !(model.value?.id || address.value)) {
    return {
      is: 'div',
    };
  }

  if (isLoading.value) {
    return {
      is: 'div',
    };
  }

  return {
    is: UiLink,
    to: {
      name: targetTab.value,
      params: {
        contractAddress: model.value?.id || address.value,
      },
    },
  };
});

</script>

<style scoped>
  .collection-list-item__overlay {
    margin: calc(-0.5rem - 1px);
    width: calc(100% + 1rem + 1px);
  }
  .bg-inherit {
    background-color: inherit;
  }
</style>
