<template>
  <ul
    v-if="(isLoading || !isActive)"
    class="flex flex-wrap gap-1"
  >
    <li
      class="inline-flex flex-none items-center py-0.5 px-2.5 text-xs font-medium text-transparent whitespace-nowrap bg-gray-800 rounded-full border border-white border-opacity-20 animate-pulse"
    >
      Loading
    </li>
  </ul>

  <ul
    v-else-if="walletLabels.length > 0"
    class="flex flex-wrap gap-1"
  >
    <li
      v-for="(item, index) in walletLabels"
      :key="index"
      v-tooltip="item.description"
      class="inline-flex flex-none items-center py-0.5 px-2.5 text-xs font-medium text-gray-200 whitespace-nowrap rounded-full border border-white border-opacity-20"
    >
      {{ item.displayName }}
    </li>
  </ul>

  <ul
    v-else-if="walletLabels.length === 0"
    class="flex flex-wrap gap-1"
  >
    <li
      class="inline-flex flex-none items-center py-0.5 px-2.5 text-xs font-medium text-gray-400 whitespace-nowrap rounded-full border border-white border-opacity-20 border-dashed"
    >
      No labels yet
    </li>
  </ul>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import walletLabelsQuery from '@/graphql/wallet/queries/walletLabels.query.gql';

export default defineComponent({
  props: {
    wallet: null,
    labels: {
      default() {
        return [];
      },
      type: Array,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const {
      wallet,
      labels,
      isActive,
    } = toRefs(props);

    const {
      result: walletLabelsResult,
      loading: isLoading,
    } = useQuery(walletLabelsQuery, () => ({ address: wallet.value?.id }), () => ({ enabled: !labels.value && isActive.value }));
    const walletLabels = computed(() => labels.value || walletLabelsResult.value?.wallet.labels || []);

    return {
      walletLabels,
      isLoading,
    };
  },
});
</script>

<style>

</style>
