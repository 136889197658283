<template>
  <div>
    <UiInputRadio
      v-model="type"
      :options="radioOptions"
      class="mb-3"
    />

    <UiInput
      v-model="localValue"
      type="datetime-local"
    />
  </div>
</template>

<script setup>
import {
  toRefs, ref, computed, watch,
} from 'vue';
import moment from 'moment';
import UiInput from '../ui/UiInput.vue';
import UiInputRadio from '../ui/UiInputRadio.vue';

const props = defineProps(['modelValue']);
const { modelValue } = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const type = ref(modelValue.value?.comparator || 'eq');
const localValue = ref(modelValue.value?.value || null);

watch(modelValue, (v) => {
  type.value = v.comparator;
  localValue.value = v.value;
}, { deep: true });

const radioOptions = [
  {
    text: 'On',
    value: 'eq',
  },
  {
    text: 'Before',
    value: 'lt',
  },
  {
    text: 'After',
    value: 'gt',
  },
];

watch([type, localValue], ([t, v]) => {
  const ts = moment(v);
  emit('update:modelValue', { comparator: t, value: ts.isValid() ? ts.toISOString() : null });
});
</script>
