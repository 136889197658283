<template>
  <OverlayComponent
    :width="300"
    :no-overlay="noOverlay !== false"
    @visible="isOpen = true"
    @hidden="isOpen = false"
  >
    <div
      class="flex items-center cursor-pointer"
      @click="noLink === false ? open(token.id) : () => {}"
    >
      <div
        class="overflow-hidden w-10 rounded-lg"
      >
        <TokenImage
          :loading="isLoading"
          :token="token"
          :fallback="collection?.imageUrl"
          :url="token?.previewImageUrl"
          clip
        />
      </div>

      <div class="ml-2">
        <div class="flex items-center">
          <div class="text-sm font-medium dark:text-white">
            <span v-if="token && token.name">{{ token.name.slice(0, 20) }}{{ token.name.length > 20 ? '...' : '' }}</span>

            <span
              v-else-if="collection.name"
              v-tooltip="collection.name.length > 20 ? collection.name : undefined"
            >{{ collection.name.slice(0, 20) }}{{ collection.name.length > 20 ? '...' : '' }}</span>

            <span v-else>{{ formatAddress(collection.id) }}</span>
          </div>
        </div>

        <div class="flex gap-2 items-center mt-1">
          <div
            v-tooltip="'Token ID'"
            class="py-0.5 px-1 font-mono text-xs text-gray-400 rounded-lg border border-gray-600"
          >
            #{{ token.tokenId.slice(0,10) }}
          </div>

          <RarityRank
            v-if="token.rarityRank"
            v-tooltip="'Rarity rank'"
            class="text-xs"
            :class="[token.rarityRank / collection.totalSupply < 0.1 ? 'border-orange-500' : 'border-gray-600']"
            :rank="token.rarityRank"
            :supply="collection.totalSupply"
            text-mode
          />
        </div>
      </div>
    </div>

    <template #overlay-content>
      <slot name="context" />

      <TokenLinks
        :collection="collection"
        :token-id="token.tokenId"
        class="mb-4"
      />

      <div class="flex flex-wrap gap-3">
        <div>
          <div class="mb-2 text-sm text-gray-300">
            Owner
          </div>

          <div>
            <WalletProfile
              :wallet="tokenData?.owner"
              :is-loading="isLoadingDetails"
              class="inline-block"
              no-overlay
              :pic-size="8"
            />
          </div>
        </div>

        <div>
          <div class="mb-2 text-sm text-gray-300">
            Collection
          </div>

          <div>
            <CollectionListItem
              :is-loading="isLoadingDetails"
              :collection="tokenData?.collection"
              :mini="true"
              :pic-size="8"
              no-overlay
            />
          </div>
        </div>

        <div>
          <div class="mb-2 text-sm text-gray-300">
            Floor
          </div>

          <div class="flex items-center h-8 text-lg font-medium text-white">
            <CurrencyDisplay
              v-if="tokenData?.collection"
              :value="tokenData?.collection?.listedFloor"
            />

            <div
              v-else
              class="my-2 w-20 h-3 bg-gray-700 rounded animate-pulse"
            />
          </div>
        </div>

        <TokenAttributes
          v-if="token.id && isOpen"
          :token-id="token.id"
          class="w-full"
        />
      </div>
    </template>
  </OverlayComponent>
</template>

<script setup>
import useTokenModal from '@/composition/tokens/useTokenModal';
import { formatAddress } from '@/utils/filters';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { toRefs, ref, computed } from 'vue';
import tokenDetailsQuery from '@/graphql/token/queries/tokenDetails.query.gql';
import CollectionListItem from './collections/CollectionListItem.vue';
import OverlayComponent from './OverlayComponent.vue';
import TokenImage from './TokenImage.vue';
import TokenLinks from './TokenLinks.vue';
import WalletProfile from './wallets/WalletProfile.vue';
import TokenAttributes from './tokens/TokenAttributes.vue';
import CurrencyDisplay from './CurrencyDisplay.vue';
import RarityRank from './RarityRank.vue';

const props = defineProps({
  token: null,
  collection: null,
  isLoading: {
    type: Boolean,
    default: false,
  },
  noOverlay: {
    type: Boolean,
    default: false,
  },
  noLink: {
    type: Boolean,
    default: false,

  },
});
const {
  token, collection, noOverlay, noLink,
} = toRefs(props);

const { open } = useTokenModal();
const isOpen = ref(false);

const {
  result: tokenDetailsResult,
  loading: isLoadingDetails,
} = useQuery(
  tokenDetailsQuery,
  () => ({ id: token.value?.id }),
  () => ({ enabled: !!token.value?.id && isOpen.value }),
);

const tokenData = computed(() => tokenDetailsResult.value?.token);
</script>
