<template>
  <div
    v-if="isVisible"
    class="flex gap-2 items-center py-1 px-2 text-sm font-bold leading-none text-yellow-900 bg-yellow-500 rounded-md border border-white border-opacity-10 cursor-pointer"
    @click="showModal"
  >
    <i
      class="relative text-base fad fa-exclamation-circle"
      style="top: 1px"
    />

    <div>Delayed</div>
  </div>
</template>

<script setup>
import { usePremium } from '@/composition/premium';
import eventBus from '@/utils/event-bus';
import { computed, toRefs } from 'vue';

const showModal = () => eventBus.$emit('auth:showPremiumModal', 'to view real-time data');

const { isPremium } = usePremium();

const props = defineProps(['disabled']);

const { disabled } = toRefs(props);

const isVisible = computed(() => !isPremium.value && !disabled.value);
</script>
