<template>
  <UiCard title="Unique Buyers and Sellers">
    <template #title-right>
      <PeriodSelector
        v-model="period"
        :periods="['7d', '30d', '90d', '180d', '1y', '2y']"
        :max-enabled="5 * 365 * 24 * 60 * 60"
      />
    </template>

    <UiCardBody>
      <Chart
        :options="chartOptions"
        :is-loading="loading"
        watermark
      />
    </UiCardBody>
  </UiCard>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { ref, computed } from 'vue';
import moment from 'moment';
import { formatNumber } from '@/utils/filters';
import UiCard from '../ui/UiCard.vue';
import PeriodSelector from '../PeriodSelector.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import Chart, { createTooltip } from '../Chart.vue';

const period = ref('1y');
const isStacked = ref(true);

const query = gql`
query GetUniqueBuyersAndSellersOverTime($period: String!){
  uniqueBuyersAndSellersOverTime(period: $period)
}
`;

const {
  result,
  loading,
} = useQuery(query, () => ({ period: period.value }));

const chartOptions = computed(() => ({
  xAxis: {
    type: 'datetime',
  },
  tooltip: createTooltip({
    formatters: {
      Buyers: (value) => formatNumber(value),
      Sellers: (value) => formatNumber(value),
    },
  }),
  legend: {
    enabled: true,
  },
  plotOptions: {
    column: {
      stacking: isStacked.value ? 'normal' : false,
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      data: result.value?.uniqueBuyersAndSellersOverTime?.map((item) => [
        parseInt(moment(item.timestamp).format('x')),
        item.uniqueBuyer,
      ]) || [],
      type: 'spline',
      name: 'Buyers',
    },
    {
      data: result.value?.uniqueBuyersAndSellersOverTime?.map((item) => [
        parseInt(moment(item.timestamp).format('x')),
        item.uniqueSeller,
      ]) || [],
      type: 'spline',
      name: 'Sellers',
    },
  ],
}));

</script>
