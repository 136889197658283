<template>
  <div
    v-tooltip="type"
    class="w-6 text-center"
  >
    <span
      v-if="type === 'Number'"
      class="text-yellow-400 fas fa-hashtag"
    />

    <span
      v-if="type === 'String'"
      class="text-green-400 fas fa-font"
    />

    <span
      v-if="type === 'Boolean'"
      class="text-blue-400 fas fa-toggle-on"
    />

    <span
      v-if="type === 'Object'"
      class="text-brown-500 fas fa-object-group"
    />

    <span
      v-if="type === 'Array'"
      class="text-purple-500 fas fa-list"
    />

    <span
      v-if="type === 'Date'"
      class="text-red-400 fas fa-calendar"
    />

    <span
      v-if="type === 'Any'"
      class="text-blue-400 fas fa-question-circle"
    />
  </div>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
});
const { type } = toRefs(props);
</script>
