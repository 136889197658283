<template>
  <div>
    <ul
      v-if="isLoading"
      class="grid grid-cols-3 gap-2 animate-pulse"
    >
      <li
        v-for="index in 3"
        :key="index"
        class="flex overflow-hidden flex-col gap-y-2 justify-between p-2 bg-blue-50 dark:bg-gray-800 dark:hover:bg-gray-750 rounded-lg"
      >
        <div>
          <div class="text-xs font-bold text-transparent whitespace-nowrap">
            Loading
          </div>

          <div class="text-sm text-transparent whitespace-nowrap">
            Loading
          </div>
        </div>

        <div
          class="flex flex-row justify-between text-xs text-transparent whitespace-nowrap"
        >
          <div>0</div>

          <div>0</div>
        </div>
      </li>
    </ul>

    <ul
      v-if="!isLoading && attributes && attributes.length > 0"
      class="grid grid-cols-3 gap-2"
    >
      <RouterLink
        v-for="(item, index) in attributes"
        :key="index"
        v-slot="{navigate}"
        :to="{
          name: routesNames.collectionItems,
          params: {
            contractAddress: collectionId,
          },
          query: {
            filters: encodeFilters([
              {
                key: 'attributeValueId',
                type: FILTER_TYPES.CATEGORY,
                value: [item.attributeValue.id]
              }
            ])
          },
        }"
        custom
      >
        <li
          v-tooltip="
            mostExpensiveId === item.attributeValue.id
              ? 'Highest floor trait'
              : rarestId === item.attributeValue.id
                ? 'Rarest trait'
                : undefined
          "
          class="flex overflow-hidden flex-col gap-y-2 justify-between p-2 bg-blue-50 dark:bg-gray-800 dark:hover:bg-gray-750 rounded-lg cursor-pointer"
          @click="navigate"
        >
          <div>
            <div class="text-xs font-bold text-gray-400 whitespace-nowrap">
              {{ item.attribute.name }}
            </div>

            <div
              v-tooltip="
                item.attributeValue.value.length > 15
                  ? item.attributeValue.value
                  : undefined
              "
              class="text-sm text-gray-900 dark:text-gray-200 whitespace-nowrap"
            >
              {{ item.attributeValue.value }}
            </div>
          </div>

          <div class="flex flex-row justify-between text-xs leading-none text-gray-400 whitespace-nowrap">
            <div>Rarity</div>

            <div
              v-tooltip="item.attributeValue.tokenCount === 1 ? 'Unique' : `${formatNumber(item.attributeValue.tokenCount)} tokens have this trait`"
              :class="rarestId === item.attributeValue.id ? 'text-orange-500' : 'text-gray-200'"
            >
              <span v-if="item.attributeValue.tokenCount === 1">1/1</span>

              <span>{{ formatPercent(item.attributeValue.tokenCount / collection.totalSupply) }}</span>
            </div>
          </div>

          <div
            class="flex flex-row justify-between text-xs leading-none text-gray-400 whitespace-nowrap"
          >
            <div>Floor</div>

            <div
              :class="mostExpensiveId === item.attributeValue.id ? 'text-green-500': 'text-gray-200'"
            >
              {{ formatCurrency(item.attributeValue.floorPrice) }}
            </div>
          </div>

          <div
            class="flex flex-row justify-between text-xs leading-none text-gray-400 whitespace-nowrap"
          >
            <div>Top Bid</div>

            <div
              :class="bestOfferId === item.attributeValue.id ? 'text-green-500': 'text-gray-200'"
            >
              {{ formatCurrency(item.attributeValue.topOffer) }}
            </div>
          </div>
        </li>
      </RouterLink>
    </ul>
  </div>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import { computed, toRefs } from 'vue';
import { maxBy, minBy } from 'lodash-es';
import tokenAttributesQuery from '@/graphql/token/queries/tokenAttributes.query.gql';
import { formatPercent, formatNumber, formatCurrency } from '@/utils/filters';
import routesNames from '@/router/routesNames';
import { encodeFilters } from '@/composition/filters/useFiltersQuerySync';
import { FILTER_TYPES } from '@/constants/filters';

const props = defineProps(['tokenId']);
const { tokenId } = toRefs(props);
const collectionId = computed(() => tokenId.value?.split('_')[0]);

const { result: tokenAttributesResult, loading: isLoading } = useQuery(
  tokenAttributesQuery,
  () => ({
    id: tokenId.value,
  }),
);

const attributes = computed(() => tokenAttributesResult.value?.token?.attributes);
const collection = computed(() => tokenAttributesResult.value?.token?.collection);

const mostExpensiveId = computed(() => maxBy(
  tokenAttributesResult.value?.token?.attributes.filter((item) => item.attributeValue?.floorPrice),
  (item) => item.attributeValue?.floorPrice,
)?.attributeValue.id);

const bestOfferId = computed(() => maxBy(
  tokenAttributesResult.value?.token?.attributes.filter((item) => item.attributeValue?.topOffer),
  (item) => item.attributeValue?.topOffer,
)?.attributeValue.id);

const rarestId = computed(() => minBy(
  tokenAttributesResult.value?.token?.attributes,
  (item) => item.attributeValue.tokenCount,
)?.attributeValue.id);
</script>
