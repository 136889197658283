<template>
  <div
    v-tooltip="errorMessage"
    class="inline-block py-1 px-2 text-sm font-bold leading-none rounded-lg"
    :class="{
      'bg-green-500 text-green-900': status === 'success',
      'bg-purple-500 text-purple-900': status === 'running',
      'bg-red-500 text-red-900': status === 'error',
      'bg-yellow-500 text-yellow-900': status === 'warning',
    }"
  >
    <span class="font-bold">{{ displayName(status) }}</span>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import { displayName } from '@/utils/filters';

const props = defineProps({
  status: {
    type: String,
    required: true,
  },
  errorMessage: {
    type: String,
    default: null,
  },
});

const { status, errorMessage } = toRefs(props);

</script>
