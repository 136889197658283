<template>
  <div>
    <UiTable
      v-if="loading || (!loading && orders.length > 0)"
      no-sticky
      :fields="['wallet', 'price', 'expiresAt', 'createdAt']"
      :is-loading="loading"
      :items="orders"
    >
      <template #cell(wallet)="{item}">
        <WalletProfile :wallet="item.maker" />
      </template>

      <template #cell(price)="{item}">
        <div class="flex gap-2 items-center">
          <MarketplaceIcon
            class="text-lg"
            :marketplace="item.marketplace"
          />

          <CurrencyDisplay
            :value="item.priceNative"
          />
        </div>

        <div
          v-if="mode === 'offers'"
          class="text-xs text-gray-400"
        >
          <span v-if="item.type === 'ATTRIBUTE'">{{ item.attributeKey }}: {{ item.attributeValue }}</span>

          <span v-if="item.type === 'COLLECTION'">Collection offer</span>
        </div>
      </template>

      <template #cell(expiresAt)="{item}">
        <Timestamp
          v-if="item.expiresAt !== '1970-01-01T00:00:00.000Z'"
          :timestamp="item.expiresAt"
        />

        <span
          v-else
          class="text-sm italic text-gray-500"
        >Unknown</span>
      </template>

      <template #cell(createdAt)="{item}">
        <Timestamp :timestamp="item.createdAt" />
      </template>
    </UiTable>

    <NoContent
      v-else
      :content="`No ${mode} found`"
    />
  </div>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, toRefs } from 'vue';
import { displayName } from '@/utils/filters';
import UiTable from '../ui/UiTable.vue';
import WalletProfile from '../wallets/WalletProfile.vue';
import CurrencyDisplay from '../CurrencyDisplay.vue';
import MarketplaceIcon from '../MarketplaceIcon.vue';
import Timestamp from '../Timestamp.vue';
import NoContent from '../NoContent.vue';

const ordersQuery = gql`
query GetTokenOrders(
  $tokenId: String!, 
  $isListings: Boolean!,
  $isOffers: Boolean!,
) {
  token(where: { id: $tokenId }) {
      id
      listings(take: 20) @include(if: $isListings) {
        id
        priceNative
        marketplace
        maker {
          id
        }
        expiresAt
        createdAt
      }
      offers(take: 20) @include(if: $isOffers) {
        id
        priceNative
        marketplace
        maker {
          id
        }
        expiresAt
        createdAt
        type
        attributeKey
        attributeValue
      }
  }
}
`;

const props = defineProps({
  tokenId: {
    type: String,
    required: true,
  },
  mode: {
    type: String,
    defaultValue: 'listings',
  },
});

const { tokenId, mode } = toRefs(props);

const {
  result,
  loading,
} = useQuery(ordersQuery, () => ({
  tokenId: tokenId.value,
  isListings: mode.value === 'listings',
  isOffers: mode.value === 'offers',
}), () => ({
  pollInterval: 15000,
}));

const orders = computed(() => {
  if (mode.value === 'listings') {
    return result.value?.token?.listings || [];
  }

  return result.value?.token?.offers || [];
});

</script>

<style lang="scss" scoped>

</style>
