<template>
  <div>
    <UiDropdown
      right
      :body-width-class="'min-w-min'"
      :inline="inlineDropdown"
    >
      <template #toggle="{toggle}">
        <UiButton
          variant="white"
          :size="buttonSize"
          @click="toggle"
        >
          <div class="flex gap-2 items-center">
            <i
              class="fad fa-clipboard-list-check"
              :class="{'text-indigo-500': isOnAnyList}"
            />

            <div>
              {{ capitalize(pluralize(noun, 2)) }}
            </div>

            <div
              v-if="isOnAnyList > 0"
              class="py-0.5 px-1.5 text-xs font-bold tabular-nums leading-none text-indigo-900 bg-indigo-500 rounded-full"
              variant="indigo"
            >
              {{ isOnAnyList }}
            </div>
          </div>
        </UiButton>
      </template>

      <template #body>
        <UiDropdownItem
          class="whitespace-nowrap "
          @click="showCreateWatchlist({items: [modelId], type: WATCHLIST_TYPES.WALLET })"
        >
          Add to new {{ capitalize(noun) }}
        </UiDropdownItem>

        <UiDropdownDivider v-if="watchlists.length > 0" />

        <UiDropdownItem
          v-for="item in watchlists"
          :key="item.id"
          class="flex gap-6 justify-between items-center whitespace-nowrap cursor-pointer"
          @click.stop="onValueUpdate(item, !item.isMember)"
        >
          <div>
            <UiCheckbox
              :model-value="item.isMember"
              @update:modelValue="onValueUpdate(item, $event)"
              @click.stop
            >
              {{ item.name }}
            </UiCheckbox>
          </div>

          <RouterLink
            :to="{name: item.__typename === 'WalletWatchlist' ? routesNames.walletWatchlist.show : routesNames.collectionWatchlistsShow, params: {slug: item.slug} }"
            @click.stop
          >
            <i class="fal fa-external-link" />
          </RouterLink>
        </UiDropdownItem>
      </template>
    </UiDropdown>
  </div>

  <UiModal v-model="isCreateModalVisible">
    <UiModalHeader
      :title="`Add to new ${noun}`"
      @dismiss="isCreateModalVisible = false"
    />

    <CreateWatchlistModal
      v-model:name-model="newWatchlistName"
      :type="type"
      :noun="noun"
      @submit="createWatchlist()"
    />

    <UiModalFooter>
      <UiButton
        :is-loading="isCreating"
        :disabled="!canCreate"
        @click="createWatchlist()"
      >
        Create
      </UiButton>
    </UiModalFooter>
  </UiModal>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiButton from '@/components/ui/UiButton.vue';
import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { capitalize } from 'lodash-es';
import routesNames from '@/router/routesNames';
import pluralize from 'pluralize';
import { WATCHLIST_TYPES } from '@/constants';
import useCreateWatchlist from '@/composition/watchlists/use-create-watchlist';
import UiDropdownDivider from '../ui/UiDropdownDivider.vue';
import UiModal from '../ui/UiModal.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import CreateWatchlistModal from './CreateWatchlistModal.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  modelId: {
    type: [String, Number],
    required: true,
  },
  buttonSize: {
    type: String,
  },
  inlineDropdown: {
    type: Boolean,
    default: false,
  },
});

const {
  type, modelId, buttonSize,
} = toRefs(props);

const {
  fetchWatchlistWithMemberCheck,
  add,
  remove,
} = useWatchlists(type, modelId);

const {
  isVisible: isCreateModalVisible,
  name: newWatchlistName,
  create: createWatchlist,
  isCreating,
  canCreate,
  show: showCreateWatchlist,
} = useCreateWatchlist(type);

const noun = computed(() => {
  if (type.value === WATCHLIST_TYPES.WALLET) {
    return 'group';
  }

  return 'watchlist';
});

const { watchlistWithMemberCheck: watchlists } = fetchWatchlistWithMemberCheck();

const onValueUpdate = async (item, event) => {
  if (!item.isMember && event) {
    await add({ id: item.id, modelIds: [modelId.value] });
  }

  if (item.isMember && !event) {
    await remove({ id: item.id, modelIds: [modelId.value] });
  }
};

const isOnAnyList = computed(() => watchlists.value.filter((item) => item.isMember).length);
</script>
