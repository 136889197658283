<template>
  <div>
    <div
      v-if="!isEditing"
      class="flex justify-end items-center"
    >
      <PeriodSelector
        v-model="period"
        :periods="['1m', '5m', '15m', '30m', '1h', '6h', '1d', '3d', '7d', '30d']"
        :save-key="`watchlist-${watchlistId}`"
        class="mb-3"
      />

      <CollectionsTableCustomizer
        :route-key="keyRef"
        :route-name="routeNameRef"
        class="mb-3"
      />
    </div>

    <CollectionsTable
      v-model:sort-by="sortBy"
      v-model:sort-desc="sortDesc"
      :collections="collections"
      :preferred-fields="preferredFields"
      :is-loading="!watchlistId || (loading && !isEditing) || (loading && isEditing && collections?.length === 0)"
      :period="period"
      :show-actions="isEditing"
    >
      <template #actions="{item}">
        <UiButton
          variant="white"
          size="sm"
          :is-loading="collectionsToRemove.includes(item.id)"
          @click="removeItem(item.id)"
        >
          Remove
        </UiButton>
      </template>
    </CollectionsTable>
  </div>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import {
  computed, ref, toRefs, inject,
} from 'vue';
import { useRoute } from 'vue-router';
import { useCollectionsTablesSettings } from '@/composition/collections/collection-table-settings';
import { GET_WATCHLIST_COLLECTION_STATS_QUERY } from '@/graphql/watchlists/queries/watchlistCollectionStats.query';
import CollectionsTable from '@/components/collections/CollectionsTable.vue';
import PeriodSelector from '@/components/PeriodSelector.vue';
import UiButton from '@/components/ui/UiButton.vue';
import CollectionsTableCustomizer from '@/components/CollectionsTableCustomizer.vue';

const route = useRoute();
const keyRef = ref(route.meta.key);
const routeNameRef = ref(route.name);
const { period, preferredFields } = useCollectionsTablesSettings({ keyRef, routeNameRef });

const props = defineProps({
  watchlistId: {
    type: [String, Number],
    required: true,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  collectionsToRemove: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['removeItem']);
const { watchlistId, isEditing } = toRefs(props);
const sortBy = ref('salesCount');
const sortDesc = ref(true);

const {
  result,
  loading,
  refetch,
} = useQuery(
  GET_WATCHLIST_COLLECTION_STATS_QUERY,
  () => ({
    watchlistId: watchlistId.value,
    period: period.value,
    sortBy: sortBy.value,
    sortDesc: sortDesc.value,
  }),
  () => ({
    enabled: !!watchlistId.value,
    fetchPolicy: 'network-only',
  }),
);

const { pushCbToStack } = inject('single-watchlist-change-stack');
pushCbToStack(() => refetch());

const collections = computed(() => result.value?.collections || []);

const removeItem = (id) => {
  // eslint-disable-next-line no-restricted-globals
  if (!confirm('Are you sure that you want to remove this collection?')) {
    return Promise.resolve();
  }

  return emit('removeItem', { id });
};

</script>
