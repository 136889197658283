<template>
  <div>
    <div class="flex flex-wrap gap-2 items-center mb-1">
      <div
        v-for="(item, index) in breadCrumbs"
        :key="index"
      >
        <RouterLink
          class="font-light text-gray-400"
          :to="item.to"
        >
          {{ item.name }}
        </RouterLink>

        <i
          v-if="index < breadCrumbs.length - 1"
          class="ml-2 text-sm text-gray-400 far fa-chevron-right"
        />
      </div>
    </div>

    <div class="mb-6 text-3xl font-light text-white break-all">
      <span v-if="!isLoading">{{ title }}</span>

      <span
        v-else
        class="w-32 h-8 bg-gray-800"
      />
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  breadCrumbs: {
    type: Array,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { breadCrumbs, title, isLoading } = toRefs(props);
</script>
