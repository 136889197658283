<template>
  <IntersectionObserver
    v-tooltip="tooltip"
    class="inline"
    @isShow="isVisible"
    @isHide="isHidden"
  >
    <span
      class="text-sm"
      :class="[relativeClass, commonClasses, {'block': relativeOnly === false}]"
    >
      {{ timestampHuman }}
      <slot name="relative-extra" />
    </span>

    <span
      v-if="relativeOnly === false"
      class="block text-xs"
      :class="[absoluteClass, commonClasses]"
    >
      {{ moment(timestamp).format("lll") }}
    </span>
  </IntersectionObserver>
</template>

<script>
import {
  defineComponent, computed, toRefs, ref, onMounted, watch,
} from 'vue';
import moment from 'moment';
import humanize from 'humanize-duration';
import { subscribe, unsubscribe } from '@/lib/timer';
import IntersectionObserver from './IntersectionObserver.vue';

export default defineComponent({
  name: 'Timestamp',
  components: { IntersectionObserver },
  props: {
    timestamp: null,
    autoUpdate: {
      default: true,
      type: Boolean,
    },
    relativeOnly: {
      default: false,
      type: Boolean,
    },
    relativeClass: {
      default: 'text-gray-900 dark:text-gray-100',
      type: String,
    },
    absoluteClass: {
      default: 'text-gray-600 dark:text-gray-400',
      type: String,
    },
    largest: {
      default: 1,
      type: Number,
    },
  },
  setup(props) {
    const { relativeOnly, timestamp, largest } = toRefs(props);
    const tooltip = computed(() => (relativeOnly.value !== false ? moment(timestamp.value).format() : undefined));
    const commonClasses = { block: relativeOnly.value === false };
    let lastUpdate = Date.now();
    const getRefreshInterval = () => {
      if (!timestamp.value) {
        return 60 * 1000;
      }
      const diff = moment().diff(timestamp.value, 'second');
      if (diff < 100) {
        return 100;
      }
      if (diff < 200) {
        return 1000;
      }
      if (diff < 60 * 60) {
        return 15 * 1000;
      }
      return 60 * 1000;
    };
    const timestampHuman = ref(null);
    const render = () => {
      const diff = Math.floor(moment().diff(timestamp.value, 'millisecond') / 1000) * 1000;
      if (diff > 0) {
        timestampHuman.value = `${humanize(diff, { largest: largest.value, round: true, units: ['y', 'mo', 'w', 'd', 'h', 'm', ...(diff / 1000 < 300 ? ['s'] : [])] })} ago`;
      } else {
        timestampHuman.value = `in ${humanize(diff, { largest: largest.value, round: true, units: ['y', 'mo', 'w', 'd', 'h', 'm', ...(diff / 1000 > -300 ? ['s'] : [])] })}`;
      }
    };
    const updateCallback = () => {
      if (lastUpdate !== null && Date.now() - lastUpdate < getRefreshInterval()) {
        return;
      }

      render();
      lastUpdate = Date.now();
    };

    watch(() => timestamp.value, () => {
      render();
    });

    onMounted(() => {
      render();
    });
    return {
      moment,
      tooltip,
      commonClasses,
      updateCallback,
      timestampHuman,
    };
  },
  beforeUnmount() {
    if (this.updateInterval) {
      unsubscribe(this.updateCallback);
    }
  },
  methods: {
    isVisible() {
      if (this.autoUpdate === false) {
        return;
      }

      subscribe(this.updateCallback);
    },
    isHidden() {
      unsubscribe(this.updateCallback);
    },
  },
});
</script>

<style>
</style>
