<template>
  <div v-if="collection">
    <CollectionSalesMetrics
      v-if="collection"
      :collection="collection"
      class="mb-6"
    />

    <div class="grid grid-cols-1 lg:grid-cols-2 4xl:grid-cols-3 gap-6">
      <div class="contents 4xl:flex 4xl:flex-col 4xl:order-2 4xl:col-span-2 4xl:gap-6 4xl:h-visible">
        <div class="lg:grid 4xl:flex-1 lg:order-1 4xl:order-3 lg:grid-cols-2 lg:col-span-2 4xl:col-span-2 gap-6">
          <CollectionPriceChart
            :collection="collection"
          />

          <CollectionListingsChart
            :collection="collection"
          />
        </div>

        <div class="4xl:flex-grow-0 4xl:flex-shrink-0 lg:order-3 4xl:order-2 4xl:col-span-2 4xl:h-visible-1/2">
          <CollectionSalesMetrics
            v-if="collection"
            :collection="collection"
            class="4xl:hidden mb-6"
            :only="['listedFloor', 'totalTokensListed']"
            cols="grid-cols-2"
          />

          <TokenPriceOverTimeChart
            :collection="collection"
          />
        </div>
      </div>

      <div class="grid lg:order-2 4xl:order-1 grid-cols-1 md:grid-cols-2 gap-6">
        <CollectionActivityFeed
          :collection="collection"
          class="lg:h-visible"
          type="listings"
        />

        <CollectionActivityFeed
          :collection="collection"
          class="lg:h-visible"
          type="sales"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import TokenPriceOverTimeChart from './TokenPriceOverTimeChart.vue';
import CollectionActivityFeed from './CollectionActivityFeed.vue';
import CollectionListingsChart from './CollectionListingsChart.vue';
import CollectionSalesMetrics from './CollectionSalesMetrics.vue';
import CollectionPriceChart from './CollectionPriceChart.vue';

const props = defineProps(['collection']);

const { collection } = toRefs(props);

</script>

<!--
 <style>
.collection-chart .highcharts-column-series {
  opacity: 0.65;
}

.collection-chart .tooltip-series-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.25rem 0;
}

.collection-chart .tooltip-series-value {
  margin-left: 0.5rem;
}
</style>
-->
