<template>
  <slot v-bind="{ isToggling, isFavorite, toggleFavorite, isLoading }" />
</template>

<script>
import {
  defineComponent,
  toRefs,
  inject,
  computed,
  ref,
} from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { useStore } from 'vuex';
import isFavoriteQuery from '@/graphql/scalar/queries/isFavorite.query.gql';
import toggleFavoriteMutation from '@/graphql/scalar/mutation/toggleFavorite.mutation.gql';
import favoriteWalletsQuery from '@/graphql/wallet/queries/favoriteWallets.query.gql';
import userFavoriteCollectionsQuery from '@/graphql/collection-with-stats/queries/userFavoriteCollections.query';
import { mapGraphqlErrors } from '@/composition/errors';
import { usePremium } from '@/composition/premium';

const MODELS = {
  WALLET: 'Wallet',
  COLLECTION: 'Collection',
};

export default defineComponent({
  name: 'ToggleFavorite',

  props: ['model', 'id', 'initialValue'],

  setup(props) {
    const { model, id, initialValue } = toRefs(props);
    const requireLogin = inject('requireLogin');
    const store = useStore();

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    const pendingValue = ref(false);

    const {
      result: isFavoriteResult,
      loading: isLoading,
    } = useQuery(isFavoriteQuery, () => ({ model: model.value, id: id.value }), () => ({ enabled: isLoggedIn.value && initialValue.value !== true }));

    const { requirePremium } = usePremium();

    const {
      mutate: mutateFavorites,
      loading: isToggling,
      onError,
    } = useMutation(
      toggleFavoriteMutation,

      () => ({
        variables: {
          model: model.value,
          id: id.value,
        },
        errorPolicy: 'none',
        update(cache, { data: { isFavorite: updatedIsFavorite } }) {
          const variables = { id: id.value, model: model.value };
          let data = cache.readQuery({ query: isFavoriteQuery, variables });

          data = {
            ...data,
            isFavorite: updatedIsFavorite,
          };

          cache.writeQuery({ query: isFavoriteQuery, data, variables });
        },

        refetchQueries() {
          if (MODELS.WALLET === model.value) {
            return [{ query: favoriteWalletsQuery }];
          }

          if (MODELS.COLLECTION === model.value) {
            return [{ query: userFavoriteCollectionsQuery }];
          }

          return [];
        },
      }),
    );

    const isFavorite = computed(() => {
      if (!isFavoriteResult.value && initialValue.value === true) {
        return true;
      }
      if (isToggling.value) {
        return pendingValue.value;
      }

      return isFavoriteResult.value?.isFavorite;
    });

    return {
      isToggling,
      requireLogin,
      isFavorite,
      isLoading,
      isLoggedIn,
      mutateFavorites: (...args) => {
        pendingValue.value = !isFavorite.value;

        return mutateFavorites(...args);
      },
      requirePremium,
    };
  },
  methods: {
    async toggleFavorite() {
      if (!this.isLoggedIn) {
        await this.requireLogin();
      }

      return this.mutateFavorites().catch(async (err) => {
        if (!err.graphQLErrors) {
          throw err;
        }

        const errors = mapGraphqlErrors(err.graphQLErrors);

        if (errors.get('isFavorite').code === 'PREMIUM_REQUIRED') {
          await this.requirePremium('to save favorites');
        }
      });
    },
  },
});
</script>
