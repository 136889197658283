<template>
  <ActionInputMapper
    v-model="value"
    :action-id="actionId"
    :inputs="action.inputs"
    :validation-issues="validationIssues"
    :trigger="trigger"
    :readonly="readonly"
  />
</template>

<script setup>
import { toRefs, computed } from 'vue';
import ActionInputMapper from '../ActionInputMapper.vue';

const props = defineProps(['modelValue', 'action', 'type', 'validationIssues', 'trigger', 'readonly', 'actionId']);
const emit = defineEmits(['update:modelValue']);

const {
  modelValue, action, validationIssues, trigger, readonly, actionId,
} = toRefs(props);

const value = computed({
  get() {
    return modelValue.value;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});
</script>
