<template>
  <UiModal
    v-model="isModalVisible"
    :z-index="200"
  >
    <UiModalHeader @dismiss="isModalVisible=false">
      Select Variable
    </UiModalHeader>

    <div class="p-3">
      <VariablePicker
        :schema="schema"
        @select="update"
      />
    </div>
  </UiModal>
</template>

<script setup>
import useVariablePicker from '@/composition/automation/use-variable-picker';

import UiModal from '../ui/UiModal.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';
import VariablePicker from './VariablePicker.vue';

const {
  isModalVisible,
  onUpdate,
  schema,
} = useVariablePicker();

const update = (value) => {
  onUpdate(value);
};
</script>
