<template>
  <div class="flex p-4 space-x-6">
    <div
      class="overflow-hidden w-24 h-24 bg-center bg-cover rounded"
      :style="{backgroundImage: `url(${image})`}"
    />

    <div class="flex flex-1 justify-between items-center">
      <div>
        <div class="mb-1 text-lg font-semibold leading-none">
          {{ name }}<span v-if="amount > 1"> × {{ amount }}</span>
        </div>

        <div>
          <UiBadge>{{ typeBadge }}</UiBadge>
        </div>
      </div>

      <div>
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script>
import UiBadge from '@/components/ui/UiBadge.vue';

export default {
  components: { UiBadge },
  props: {
    image: null,
    name: null,
    typeBadge: null,
    amount: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style>

</style>
