<template>
  <div :class="{'relative': absolute === false, 'absolute': absolute !== false}">
    <div
      v-if="!hasError && tokenImage.indexOf('data:image/svg+xml;utf8,') !== 0"
      v-show="!isLoading"
      :class="{'w-full aspect-w-1 aspect-h-1 overflow-hidden': clip !== false}"
    >
      <img
        class="object-cover transition-transform duration-300 hover:transform hover:scale-110"
        :class="imageClass"
        :src="tokenImage"
        @error="error"
        @load="isImgLoading = false"
      >
    </div>

    <div
      v-if="tokenImage.indexOf('data:image/svg+xml;utf8,') === 0"
      v-html="tokenImage.replace('data:image/svg+xml;utf8,', '')"
    />

    <div
      v-if="isLoading"
      class="w-full bg-gray-800 animate-pulse aspect-w-1 aspect-h-1"
    />

    <img
      v-if="(hasError && !fallback)"
      class="w-full h-auto"
      :class="[imageClass, {'filter grayscale': isLoading}]"
      src="../assets/image-placeholder.svg"
    >

    <div
      v-if="hasError && fallback"
      class="overflow-hidden relative w-full h-auto"
    >
      <img

        class="w-full h-auto opacity-50 filter blur-sm transform scale-125"
        :src="fallback"
        :class="imageClass"
      >

      <div
        class="flex absolute top-1/2 left-1/2 gap-2 items-center transform -translate-x-1/2 -translate-y-1/2"
        style="text-shadow: 0 1px 3px rgba(0,0,0,.5)"
      >
        <i
          v-tooltip="'Failed to load'"
          class="text-lg text-red-500 fas fa-exclamation-circle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import imagePlaceholder from '@/assets/image-placeholder.svg';
import ipfsUrl from '@/utils/filters/ipfsUrl';

export default {
  name: 'TokenImage',
  props: {
    token: null,
    fallback: null,
    url: null,
    imageClass: null,
    absolute: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    clip: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      usePlaceholder: false,
      hasError: false,
      isImgLoading: true,
    };
  },
  computed: {
    isLoading() {
      return this.loading || this.isImgLoading;
    },
    tokenImage() {
      if (this.url) {
        return this.url;
      }
      let image;
      if (this.token?.metadata?.image_data && this.token?.metadata?.image_data.indexOf('data:') !== 0) {
        image = `data:image/svg+xml;base64,${btoa(this.token?.metadata?.image_data)}`;
      }

      if (!image && this.token?.metadata?.image_data) {
        image = this.token?.metadata?.image_data;
      }

      if (!image && this.token?.image && this.token?.image.indexOf('ipfs') !== -1) {
        image = ipfsUrl(this.token?.image);
      }

      if (!image && this.token?.image?.indexOf('ar://') === 0) {
        image = `https://arweave.net/${this.token?.image.replace('ar://', '')}`;
      }

      if (!image && this.token?.image) {
        image = this.token.image;
      }

      if (!image && this.token?.metadata?.animation_url && this.token?.metadata?.animation_url.indexOf('.gif') !== -1) {
        image = ipfsUrl(this.token?.metadata?.animation_url);
      }

      if (!image && this.fallback) {
        image = this.fallback;
      }

      if (!image) {
        image = imagePlaceholder;
      }

      return image;
    },
  },
  watch: {
    tokenImage(value) {
      if (value.indexOf('data:image/svg+xml;utf8,') === 0) {
        this.hasError = false;
        this.isImgLoading = false;
      }
    },
  },
  mounted() {
    if (this.tokenImage.indexOf('data:image/svg+xml;utf8,') === 0) {
      this.hasError = false;
      this.isImgLoading = false;
    }
  },
  methods: {
    error(e) {
      this.isImgLoading = false;
      this.hasError = true;
    },
  },
};
</script>

<style>

</style>
