<template>
  <div class="relative">
    <Highcharts
      :options="chartOptions"
      class="h-full highcharts-wrapper"
    />

    <div
      v-if="isLoading"
      class="flex absolute top-0 left-0 justify-center items-center w-full h-full bg-white dark:bg-gray-850"
    >
      <LoadingIndicator class="text-indigo-600" />
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import boost from 'highcharts/modules/boost';
import { Chart } from 'highcharts-vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

const timezone = new Date().getTimezoneOffset();

boost(Highcharts);

Highcharts.setOptions({
  global: {
    timezoneOffset: timezone,
  },
});

// $indigo-400 $blue-400 $green-400
export const ChartColorMap = {
  0: 'indigo-400',
  1: 'blue-400',
  2: 'green-400',
};

export default {
  name: 'BoostedChart',
  components: {
    LoadingIndicator,
    Highcharts: Chart,
  },
  props: {
    labels: {
      default() {
        return [];
      },
      type: Array,
    },
    data: {
      default() {
        return [];
      },
      type: Array,
    },
    options: {
      default() {
        return {};
      },
      type: Object,
    },
    dataFormatter: null,
    isLoading: {
      type: Boolean,
      value: false,
    },
  },
  computed: {
    chartOptions() {
      // if (this.isLoading) {
      //   return {};
      // }
      const { data, labels } = this;
      let dataFormatterFn = () => {};
      if (this.dataFormatter) {
        dataFormatterFn = this.$options.filters[this.dataFormatter];
      }
      return Highcharts.merge({
        chart: {
          animation: false,
          style: {
            fontFamily: 'monospace',
          },
          styledMode: false,
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },

        subtitle: {
          enabled: false,
        },
        yAxis: {
          title: {
            enabled: false,
          },
          style: {
            fontFamily: 'monospace',
          },
          labels: {
            style: '#ccc',
          },
        },
        xAxis: {
          title: {
            enabled: false,
          },
          style: {
            fontFamily: 'monospace',
          },
          labels: {
            style: '#ccc',
          },
        },

        legend: {
          enabled: false,
        },

        tooltip: {

          pointFormatter() {
            return dataFormatterFn(this.y);
          },
        },

        series: [
          {
            data: data.map((item, index) => ([labels[index], item])),
          },
        ],
      }, this.options);
    },
  },
};
</script>
