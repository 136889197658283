<template>
  <div class="p-3 ">
    <UiSearchInput
      v-model="searchText"
      placeholder="Search Actions"
      width-class="w-full"
    />
  </div>

  <div
    class="grid overflow-y-auto grid-cols-2 gap-3 p-3 pt-0 max-h-96 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
  >
    <div
      v-for="(item, index) in actions"
      :key="index"
    >
      <button

        type="button"
        class="flex justify-between items-center py-3 px-3 w-full text-sm leading-5 text-left bg-gray-800 bg-opacity-50 hover:bg-opacity-100 rounded-lg hover:shadow-xl transition-all"
        @click="emit('select', item)"
      >
        <div class="flex gap-3 items-center ">
          <div
            class="flex justify-center items-center w-6 h-6 text-lg leading-none text-center"
            :class="item.type.startsWith('custom-') ? 'text-purple-500' : 'text-indigo-500'"
          >
            <i :class="item.icon" />
          </div>

          <div class="font-light text-gray-200">
            {{ item.name }}
          </div>
        </div>

        <div
          v-if="item.version || !item.isPublic"
          class="py-1 px-1.5 text-xs leading-none bg-gray-700 rounded-md"
        >
          <div>{{ item.version }}</div>

          <div v-if="!item.isPublic">
            Private
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script setup>
import useAutomations from '@/composition/automation/use-automations';
import { ref, computed } from 'vue';
import useLocalSearch from '@/composition/search/use-local-search';
import UiSearchInput from '../ui/UiSearchInput.vue';

const { automationActions } = useAutomations();

const emit = defineEmits(['select']);
const displayedActions = computed(() => automationActions.value.filter((action) => !action.versions.length || action.version === action.versions[0].version));
const { searchText, results: actions } = useLocalSearch(displayedActions, {
  indexFields: [
    'name',
    'outputs[]:name',
    'outputs[]:description',
    'inputs[]:name',
    'inputs[]:description',
  ],
  idField: 'type',
});

</script>
