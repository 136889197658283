<template>
  <Component
    :is="component"
    class="cursor-pointer"
    v-bind="$attrs"
  >
    {{ isActive }}
    <slot />
  </Component>
</template>

<script>
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'UiLink',

  props: [
    'defaultClass',
    'activeClass',
  ],

  computed: {
    component() {
      if (this.$attrs.to) {
        return 'router-link';
      }

      return 'a';
    },
    isActive() {
      return this.$refs.link;
    },
  },
});
</script>

<style scoped>

</style>
