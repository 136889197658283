<template>
  <nav
    ref="clickAwayRoot"
    class="sticky top-0 z-50 bg-gray-800 dark:bg-gray-950 border-gray-800 shadow-xl"
  >
    <div class="px-3 2xl:px-0 mx-auto max-w-screen-2xl">
      <div
        class="flex md:grid relative md:grid-cols-3 justify-between md:justify-center items-center h-24"
      >
        <div class="flex gap-12 items-center">
          <div class="relative flex-shrink-0">
            <a href="/">
              <img
                class="h-11"
                src="../assets/compass-logo-2.svg"
                alt="Compass"
              >

              <div
                v-if="isBeta"
                class="inline-block absolute top-0 right-0 py-1 px-1.5 text-sm font-bold leading-none bg-rose-500 rounded-full"
              >Beta</div>
            </a>
          </div>

          <div
            class="hidden xl:block -ml-10"
          >
            <div class="flex gap-2 items-baseline leading-none">
              <div
                v-for="(item, index) in linksLeft"
                :key="index"
              >
                <RouterLink
                  v-if="!Array.isArray(item.children)"
                  v-slot="{ href, navigate, isActive }"
                  :exact="item.exact"
                  :to="item.to"
                >
                  <a
                    class="block py-3 px-4 font-medium text-gray-200 hover:text-white hover:bg-gray-800 rounded-full shadow-inner transition-all transform active:translate-y-0.5"
                    :class="
                      isActive
                        ? 'text-white bg-gray-800'
                        : ''
                    "
                    :href="href"
                    @click="navigate"
                  >
                    <span v-if="item.title">{{ item.title }}</span>

                    <span
                      v-if="item.icon"
                      :class="item.icon"
                    />
                  </a>
                </RouterLink>

                <UiDropdown v-if="Array.isArray(item.children)">
                  <template #toggle="{ toggle, isOpen }">
                    <a
                      class="block py-3 px-4 font-medium text-gray-200 hover:text-white hover:bg-gray-800 rounded-full shadow-inner transition-all transform active:translate-y-0.5 cursor-pointer"
                      :class="
                        isOpen
                          ? 'text-white bg-gray-800'
                          : ''

                      "
                      @click="toggle"
                    >
                      <span v-if="item.title">{{ item.title }}</span>

                      <span
                        v-if="item.icon"
                        :class="item.icon"
                      />
                    </a>
                  </template>

                  <template #body="{ forceClose: close }">
                    <UiDropdownItem
                      v-for="(child, childIndex) in item.children"
                      :key="childIndex"
                      tag="router-link"
                      :to="child.to"
                      @click="() => close()"
                    >
                      {{ child.title }}
                    </UiDropdownItem>
                  </template>
                </UiDropdown>
              </div>
            </div>
          </div>
        </div>

        <div
          class="justify-center"
          :class="isSearchVisible ? 'block h-full p-3 absolute inset-0 bg-gray-800 dark:bg-gray-950 z-10 items-center justify-center' : 'hidden md:flex'"
        >
          <div class="flex items-center h-full">
            <UniversalSearch
              class="flex-1"
              @selectResult="openResult"
            >
              <template #search-field="{ search, show, isVisible }">
                <div class="flex relative items-center h-full">
                  <Transition
                    name="fade"
                    speed="300"
                  >
                    <div
                      v-if="!isVisible"
                      class="absolute right-4 py-0.5 px-1.5 font-mono text-xs font-bold text-black bg-gray-600 rounded duration-150 pointer-events-none"
                    >
                      /
                    </div>
                  </Transition>

                  <button
                    type="button"
                    class="inline-flex md:hidden absolute right-1 app-header__icon-button"
                    @click="isSearchVisible = false"
                  >
                    <span
                      class="w-8 text-center"
                    >
                      <i class="far fa-times" />
                    </span>
                  </button>

                  <span
                    class="absolute ml-4 text-lg text-gray-500 pointer-events-none"
                  ><i class="far fa-search" /></span>

                  <input
                    id="main-search-input"
                    v-model="search.term"
                    class="
                      py-2.5
                      pr-4
                      pl-12
                      w-full
                      focus:w-96
                      lg:w-64
                      xl:w-80
                      placeholder-gray-400
                      focus:placeholder-gray-400
                      text-gray-100
                      bg-gray-800
                      hover:bg-gray-700
                      focus:bg-gray-700
                      dark:bg-opacity-75
                      rounded-full
                      border
                      focus:border
                      border-none
                      focus:ring-2
                      focus:ring-indigo-500
                      focus:ring-offset-2
                      ring-offset-black
                      transition-width
                      duration-500
                      focus:outline-none
                      text
                    "
                    placeholder="Search collections and wallets"
                    @focus="show"
                  >
                </div>
              </template>
            </UniversalSearch>
          </div>
        </div>

        <div
          class="flex gap-8 justify-end items-center"
        >
          <div class="hidden xl:flex gap-2 items-baseline leading-none">
            <div
              v-for="(item, index) in linksRight"
              :key="index"
            >
              <RouterLink
                v-if="!Array.isArray(item.children)"
                v-slot="{ href, navigate, isActive }"
                :exact="item.exact"
                :to="item.to"
              >
                <a
                  class="block py-3 px-4 font-medium text-gray-200 hover:text-white hover:bg-gray-800 rounded-full shadow-inner transition-all transform active:translate-y-0.5"
                  :class="
                    isActive
                      ? 'text-white bg-gray-800'
                      : ''
                  "
                  :href="href"
                  @click="navigate"
                >
                  <span v-if="item.title">{{ item.title }}</span>

                  <span
                    v-if="item.icon"
                    :class="item.icon"
                  />
                </a>
              </RouterLink>

              <UiDropdown v-if="Array.isArray(item.children)">
                <template #toggle="{ toggle, isOpen }">
                  <a
                    class="block py-3 px-4 font-medium text-gray-200 hover:text-white hover:bg-gray-800 rounded-full shadow-inner transition-all transform active:translate-y-0.5"
                    :class="
                      isOpen
                        ? 'text-white bg-gray-800'
                        : ''
                    "
                    @click="toggle"
                  >
                    <span v-if="item.title">{{ item.title }}</span>

                    <span
                      v-if="item.icon"
                      :class="item.icon"
                    />
                  </a>
                </template>

                <template #body="{ forceClose: close }">
                  <UiDropdownItem
                    v-for="(child, childIndex) in item.children"
                    :key="childIndex"
                    tag="router-link"
                    :to="child.to"
                    @click="() => close()"
                  >
                    {{ child.title }}
                  </UiDropdownItem>
                </template>
              </UiDropdown>
            </div>
          </div>

          <div class="flex gap-4 items-center">
            <button
              type="button"
              class="inline-flex md:hidden app-header__icon-button"
              @click="isSearchVisible = true"
            >
              <span
                class="w-8 text-center"
              >
                <i class="far fa-search" />
              </span>
            </button>

            <button
              type="button"
              class="inline-flex xl:hidden app-header__icon-button"
              @click="toggleMenu"
            >
              <span
                class="w-8 text-center"
                :class="isMenuOpen ? 'hidden' : 'block'"
              >
                <i class="far fa-bars" />
              </span>

              <span
                class="w-8 text-center"
                :class="isMenuOpen ? 'block' : 'hidden'"
              >
                <i class="fal fa-times" />
              </span>
            </button>

            <UserProfile
              v-if="isLoggedIn"
            >
              <template #authenticated="{ wallet }">
                <UiDropdown
                  class="-ml-1"
                  right
                >
                  <template #toggle="{ toggle }">
                    <button
                      type="button"
                      class="
                        flex
                        items-center
                        max-w-xs
                        leading-none
                        bg-gray-800
                        rounded-full
                        focus:ring-2
                        focus:ring-indigo-500
                        focus:ring-offset-2
                        focus:ring-offset-gray-800
                        focus:outline-none
                        text-sm text-white
                        h-9 w-9
                      "
                      @click="toggle"
                    >
                      <WalletProfile
                        v-if="wallet"
                        no-overlay
                        :wallet="wallet"
                        only-pic
                        :pic-size="9"
                        no-link
                      />

                      <div
                        v-else-if="user.profilePictureUrl"
                        class="overflow-hidden w-9 h-9 bg-gray-500 rounded-full"
                      >
                        <img :src="user.profilePictureUrl">
                      </div>

                      <div
                        v-else
                        class="w-9 h-9 bg-gray-500 rounded-full"
                      />
                    </button>
                  </template>

                  <template #body="{ forceClose: closeUserProfile }">
                    <UiDropdownForm
                      v-if="premiumExpiresAt"
                      class="py-2"
                    >
                      <div
                        class="py-1 px-2 text-xs font-medium text-indigo-900 bg-indigo-500 rounded cursor-pointer"
                        @click="eventBus.$emit('auth:showPremiumModal', 'to unlock all periods')"
                      >
                        Premium until {{ moment(premiumExpiresAt).format("l") }}
                      </div>
                    </UiDropdownForm>

                    <UiDropdownDivider v-if="premiumExpiresAt" />

                    <div
                      v-if="user.wallets.length"
                      class="p-3 text-sm font-medium text-gray-300"
                    >
                      Your Wallets
                    </div>

                    <UiDropdownItem
                      v-for="(item, index) in user.wallets"
                      :key="index"
                      tag="router-link"
                      :to="{
                        name: routesNames.wallet.analytics,
                        params: { address: item.id },
                      }"
                      @click="() => closeUserProfile()"
                    >
                      <WalletProfile
                        v-if="wallet"
                        no-overlay
                        no-link
                        :wallet="item"
                        :pic-size="6"
                      />

                      <div
                        v-else
                        class="w-9 h-9 bg-gray-500 rounded-full"
                      />
                    </UiDropdownItem>

                    <UiDropdownDivider v-if="user.wallets.length > 0" />

                    <UiDropdownItem
                      tag="router-link"
                      :to="{
                        name: 'Settings',
                      }"
                      @click="() => closeUserProfile()"
                    >
                      <div class="flex content-center items-center">
                        <div class="w-6">
                          <i class="text-md fas fa-cog" />
                        </div>

                        <div>Settings</div>
                      </div>
                    </UiDropdownItem>

                    <UiDropdownItem
                      v-if="!user.isDiscordLinked && showDiscordLink"
                      tag="a"
                      href="https://discord.com/api/oauth2/authorize?client_id=901060393194115083&redirect_uri=https%3A%2F%2Fcompass.art%2Fhooks%2Fdiscord&response_type=code&scope=guilds.join%20identify"
                      @click="() => closeUserProfile()"
                    >
                      <div class="flex content-center items-center">
                        <div class="w-6">
                          <i class="-ml-0.5 text-md fab fa-discord" />
                        </div>

                        <div>Get Discord Role</div>
                      </div>
                    </UiDropdownItem>

                    <UiDropdownItem
                      tag="a"
                      :href="`${authPrefix}/logout`"
                      @click="onSignOut"
                    >
                      <div class="flex content-center items-center">
                        <div class="w-6">
                          <i class="text-md fas fa-sign-out" />
                        </div>

                        <div>Sign out</div>
                      </div>
                    </UiDropdownItem>
                  </template>
                </UiDropdown>
              </template>
            </UserProfile>

            <UiButton
              v-else-if="isAuthReady"
              class="font-bold rounded-full"
              size="lg"
              @click="eventBus.$emit('auth:signIn')"
            >
              Sign In
            </UiButton>

            <div
              v-else
              class="w-9 h-9 bg-gray-800 rounded-full animate-pulse"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div
      v-if="isMenuOpen"
      id="mobile-menu"
      class="xl:hidden absolute w-full bg-gray-950 dark:bg-gray-950 shadow-4xl"
      @click="() => forceClose()"
    >
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 py-3 px-2 sm:px-3">
        <RouterLink
          v-for="(item, index) in flattenedLinks"
          :key="index"
          v-slot="{ href, navigate, isActive }"
          :exact="item.exact"
          :to="Array.isArray(item.children) ? item.children[0].to : item.to"
        >
          <a
            class="block py-2 px-3 text-base font-medium rounded-lg"
            :class="
              isActive
                ? 'bg-gray-800 text-white'
                : 'text-gray-300 hover:bg-gray-700 bg-gray-900 hover:text-white'
            "
            :href="href"
            @click="navigate"
          >
            <span v-if="item.title">{{ item.title }}</span>

            <span
              v-if="item.icon"
              :class="item.icon"
            />
          </a>
        </RouterLink>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent, ref } from 'vue';
import moment from 'moment-timezone';
import { useClickAway } from '@/composition/click-away/useClickAway';
import routesNames from '@/router/routesNames';

import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiDropdownForm from '@/components/ui/UiDropdownForm.vue';
import eventBus from '@/utils/event-bus';
import UserProfile from '@/components/UserProfile.vue';
import WalletProfile from '@/components/wallets/WalletProfile.vue';
import UniversalSearch from '@/components/universal-search/UniversalSearch.vue';
import UiButton from './ui/UiButton.vue';
import UiDropdownDivider from './ui/UiDropdownDivider.vue';
import useTokenModal from '../composition/tokens/useTokenModal';

const authPrefix = process.env.VUE_APP_AUTH_PREFIX ?? 'http://localhost:3002/auth';
export default defineComponent({
  name: 'AppHeader',

  components: {
    UniversalSearch,
    WalletProfile,
    UserProfile,
    UiDropdownItem,
    UiDropdown,
    UiDropdownForm,
    UiButton,
    UiDropdownDivider,
  },

  props: ['linksLeft', 'linksRight'],

  setup() {
    const {
      clickAwayRoot,
      isOpen: isMenuOpen,
      forceClose,
      toggle: toggleMenu,
    } = useClickAway();

    const isSearchVisible = ref(false);

    const { open: openTokenModal } = useTokenModal();
    const isBeta = Boolean(process.env.VUE_APP_IS_BETA);
    return {
      routesNames,
      moment,
      eventBus,
      isMenuOpen,
      clickAwayRoot,
      forceClose,
      toggleMenu,
      isSearchVisible,
      openTokenModal,
      isBeta,
      authPrefix,
    };
  },

  data() {
    return {
      isBannerVisible: localStorage.getItem('alpha-banner-dismissed') === null,
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },

    user() {
      return this.$store.state.auth.user;
    },

    isAuthReady() {
      return this.$store.getters['auth/isReady'];
    },

    premiumExpiresAt() {
      return this.user.premiumExpiresAt;
    },

    showDiscordLink() {
      return this.isLoggedIn
        && this.user.passBalance > 0
        && !this.user.isDiscordLinked;
    },

    flattenedLinks() {
      return [
        ...(this.linksLeft || []),
        ...(this.linksRight || []),
      ].map((item) => {
        if (item.children) {
          return item.children;
        }

        return item;
      }).flat();
    },
  },

  mounted() {
    document.addEventListener('keyup', (event) => {
      if (event.key !== '/') {
        return;
      }

      document.getElementById('main-search-input').focus();
    });
  },

  methods: {
    onSignOut() {
      this.$store.dispatch('auth/logout');
    },
    openResult({ type, data }) {
      if (type === 'collection') {
        this.$router.push({
          name: this.routesNames.collectionAnalytics,
          params: {
            contractAddress: data.id,
          },
        });
      }

      if (type === 'wallet') {
        this.$router.push({
          name: this.routesNames.wallet.analytics,
          params: {
            address: data.id,
          },
        });
      }

      if (type === 'token') {
        this.openTokenModal(data.id);
      }

      if (type === 'group') {
        this.$router.push({
          name: this.routesNames.walletWatchlist.show,
          params: {
            slug: data.slug,
          },
        });
      }

      this.forceClose();
    },

    dismissBanner() {
      this.isBannerVisible = false;
      localStorage.setItem('alpha-banner-dismissed', Date.now());
    },

    linkDiscord() {
      window.location.href = process.env.VUE_APP_DISCORD_AUTH_REDIRECT_URL;
    },
  },
});

</script>

<style scoped>
  .app-header__icon-button {
    @apply active:translate-y-0.5 transform transition-transform justify-center items-center p-2 w-9 h-9 text-gray-200 bg-gray-800 hover:bg-gray-700 rounded-full focus:ring-2 ring-indigo-500 ring-offset-2 ring-offset-black focus:outline-none
  }
</style>
