<template>
  <div>
    <AutomationForm
      v-model:name="name"
      v-model:trigger="trigger"
      v-model:triggerConfig="triggerConfig"
      v-model:actions="actions"
      v-model:inputs="inputs"
      v-model:inputsSchema="inputsSchema"
      v-model:folderId="folderId"
      :inputs-schema="inputsSchema"
      :extra-variables="inputVariables"
      :validation-issues="validationIssues"
      :is-loading="isLoading"
      :linked-template="automation?.linkedTemplate"
    >
      <template
        v-if="automation?.linkedTemplate"
        #linked-template
      >
        <UiCard>
          <UiCardBody class="flex flex-col justify-center items-center text-center">
            <div class="text-gray-300 text-md">
              This automation is linked to the <span class="font-bold">{{ automation.linkedTemplate.name }}</span> template. You can only edit the inputs.
            </div>

            <div class="flex gap-3 mt-6">
              <UiButton
                tag="router-link"
                :to="{ name: routesNames.automationTemplateUpdate, params: { automationTemplateId: automation.linkedTemplate.id } }"
                variant="white"
              >
                View Template
              </UiButton>

              <UiButton
                variant="white"
                :is-loading="isUnlinkingTemplate"
                @click="unlinkTemplate"
              >
                Unlink
              </UiButton>
            </div>
          </UiCardBody>
        </UiCard>
      </template>
    </AutomationForm>

    <div class="flex fixed bottom-8 justify-between items-center py-6 mx-auto w-full max-w-screen-md bg-gray-950 bg-opacity-90 shadow-xl backdrop-filter backdrop-blur">
      <div>
        <AutomationValidationOverview
          v-if="!isLoading"
          :is-valid="isValid"
          :validation-issues="validationIssues"
        />
      </div>

      <div class="flex gap-3">
        <UiButton
          variant="white"
          :is-loading="isDeleting"
          size="lg"
          @click="deleteAutomation"
        >
          <span class="text-red-500">Delete</span>
        </UiButton>

        <UiButton
          :is-loading="isUpdating"
          size="lg"
          @click="saveAutomation"
        >
          Update
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import AutomationForm from '@/components/automation/AutomationForm.vue';
import UiButton from '@/components/ui/UiButton.vue';
import {
  ref, computed, toRefs, onMounted, watch,
} from 'vue';
import useAutomations from '@/composition/automation/use-automations';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import routesNames from '@/router/routesNames';
import useDialogs from '@/components/ui/composition/use-dialogs';
import useValidation from '@/composition/validation/use-validation';
import useAutomationSchema from '@/composition/validation/schemas/automation-schema';
import { eq } from 'lodash-es';
import UiCard from '@/components/ui/UiCard.vue';
import UiCardBody from '@/components/ui/UiCardBody.vue';
import AutomationValidationOverview from './AutomationValidationOverview.vue';

const props = defineProps(['automationId']);
const { automationId } = toRefs(props);

const {
  update, getAutomation, deleteMutation, unlink, run,
} = useAutomations();
const {
  automation, onResult, isLoading,
} = getAutomation(automationId);

const name = ref('');

const trigger = ref(null);

const triggerConfig = ref({});
const inputs = ref({});

const actions = ref([]);

const folderId = ref(null);
const inputsSchema = ref({});

const isVisible = ref(false);

const model = computed(() => ({
  name: name.value,
  trigger: trigger.value,
  triggerConfig: triggerConfig.value,
  inputs: inputs.value,
  inputsSchema: inputsSchema.value,
  actions: actions.value,
  folderId: folderId.value,
}));

const { open, notify } = useDialogs();
const isUpdating = ref(false);
const saveAutomation = () => {
  isUpdating.value = true;
  update({
    id: automationId.value,
    ...model.value,
  }).then((res) => {
    if (!res.data.updateAutomation) {
      throw new Error('Failed to update automation');
    }

    notify({
      title: 'Automation updated',
      variant: 'primary',
    });
  }).catch((err) => {
    notify({
      title: 'Failed to update automation',
      variant: 'warning',
    });
    console.error(err);
  }).finally(() => {
    isUpdating.value = false;
    isVisible.value = false;
  });
};

const isRunning = ref(false);
const router = useRouter();

const currentId = ref(null);
const hasUnsavedChanges = ref(false);
const init = () => {
  if (!automation.value) {
    return;
  }

  if (currentId.value === automation.value.id) {
    return;
  }

  currentId.value = automation.value.id;
  const data = automation.value ? JSON.parse(JSON.stringify(automation.value)) : null;
  name.value = data?.name || '';
  trigger.value = data?.trigger || null;
  triggerConfig.value = data?.triggerConfig || {};
  inputs.value = data?.inputs || {};
  inputsSchema.value = data?.inputsSchema || {};
  actions.value = data?.actions || [];
  folderId.value = data?.folderId || null;

  hasUnsavedChanges.value = false;
};

onResult(init);
onMounted(init);

const isDeleting = ref(false);

const deleteAutomation = async () => {
  const { confirmed } = await open({
    title: 'Delete automation',
    message: 'Are you sure you want to delete this automation?',
    variant: 'red',
    confirmText: 'Delete',
  });
  if (!await confirmed()) {
    return;
  }
  isDeleting.value = true;
  const { data } = await deleteMutation({ id: automationId.value }, {
    optimisticResponse: {
      deleteAutomation: true,
    },
  }).finally(() => {
    isDeleting.value = false;
  });

  if (data.deleteAutomation) {
    router.push({ name: routesNames.automation });
    notify({
      title: 'Automation deleted',
      variant: 'primary',
    });
  }
};

const { schema, errorMap } = useAutomationSchema();
const {
  isValid,
  validationIssues,
} = useValidation(schema, model, { customErrorMap: errorMap });

const inputVariables = computed(() => automation.value?.inputsSchema?.map((item) => ({
  group: 'automationInputs',
  id: `automationInputs.${item.name}`,
  name: item.name,
  description: item.description,
  type: item.type,
  actingAs: item.actingAs,
})) || []);
const isUnlinkingTemplate = ref(false);
const unlinkTemplate = () => {
  isUnlinkingTemplate.value = true;
  return unlink({
    id: automationId.value,
  }).finally(() => {
    isUnlinkingTemplate.value = false;
  });
};

</script>
