<template>
  <UiModal v-model="isVisible">
    <UiModalHeader
      title="Create release"
      @dismiss="isVisible = false"
    />

    <div class="py-3 px-4">
      <UiInputGroup label="Version">
        <UiInput
          v-model="version"
          placeholder="1.0.0"
        />
      </UiInputGroup>

      <UiInputGroup label="Description">
        <UiTextArea
          v-model="description"
          placeholder="Describe what's new in this release"
          :rows="5"
        />
      </UiInputGroup>
    </div>

    <UiModalFooter>
      <div class="flex gap-2 items-cente">
        <UiButton
          variant="white"
          @click="isVisible = false"
        >
          Cancel
        </UiButton>

        <UiButton
          variant="primary"
          :is-loading="isCreating"
          @click="createRelease"
        >
          Create
        </UiButton>
      </div>
    </UiModalFooter>
  </UiModal>
</template>

<script setup>
import {
  ref, toRefs, inject, computed,
} from 'vue';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { ACTION_RELEASE_FIELDS_FRAGMENT } from '@/graphql/automation/fragments/ActionReleaseFieldsFragment';
import useAutomationActions, { GET_ACTION_QUERY } from '@/composition/automation/use-automation-actions';
import semverPatch from 'semver/functions/inc';
import UiModal from '../ui/UiModal.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import UiButton from '../ui/UiButton.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiTextArea from '../ui/UiTextArea.vue';

const props = defineProps({
  action: null,
});

const { refetchActions } = useAutomationActions();

const { action } = toRefs(props);

const emit = defineEmits(['created']);

const version = ref('');
const description = ref('');

const isVisible = ref(false);
window.semverPatch = semverPatch;
const show = () => {
  isVisible.value = true;

  version.value = action.value.releases.length === 0 ? '0.0.1' : semverPatch(action.value.releases[0].version, 'patch');
  description.value = action.value.releases.length === 0 ? 'Initial release' : '';
};

const isCreating = ref(false);

const { mutate } = useMutation(gql`
mutation CreateActionRelease($model: CreateActionReleaseInput!) {
  createActionRelease(actionRelease: $model) {
    ...ActionReleaseFieldsFragment
  }
}
${ACTION_RELEASE_FIELDS_FRAGMENT}
`, () => ({
  awaitRefetchQueries: true,
  errorPolicy: 'none',
  refetchQueries: [
    {
      query: GET_ACTION_QUERY,
      variables: {
        id: action.value.id,
      },
    },
  ],
}));
const notify = inject('notify');
const createRelease = async () => {
  isCreating.value = true;
  const model = {
    actionId: action.value.id,
    version: version.value,
    description: description.value,
  };

  try {
    await mutate({ model });
    emit('created');
    refetchActions();
    isVisible.value = false;
  } catch (e) {
    notify({
      message: e.message,
      variant: 'warning',
    });
  } finally {
    isCreating.value = false;
  }
};

defineExpose({
  show,
});
</script>
