<template>
  <div
    class="py-4 px-4"
    @click.stop
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiDropdownForm',
};
</script>

<style scoped>

</style>
