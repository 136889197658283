<template>
  <div>
    <template v-if="attributes && attributes.length > 0">
      <UiInputGroup label="Filter traits">
        <UiInput v-model="searchText" />
      </UiInputGroup>

      <ul
        v-if="displayedModels.length > 0"
        id="attribute-value-picker-list"
        class="overflow-y-auto px-3 -mx-3 space-y-2 max-h-96 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
      >
        <li
          v-for="item in displayedModels"
          :key="item.id"
        >
          <div
            v-scroll-to="{el: `#attrId-checkbox-${item.id}`, container: '#attribute-value-picker-list' }"
            class="flex justify-between mb-1 text-sm font-medium dark:text-white cursor-pointer"
            @click="() => openAttributeGroup(item.id)"
          >
            <div class="flex">
              <div
                class="flex items-center"
                @click.stop
              >
                <input
                  :id="`attrId-checkbox-${item.id}`"
                  ref="attrCheckboxes"
                  v-model="attrIdsModel"
                  :data-item-id="item.id"
                  :value="item.id"
                  :name="item.id"
                  type="checkbox"
                  class="
                    inline-block
                    w-4
                    h-4
                    text-indigo-600
                    dark:bg-gray-800
                    rounded-full
                    border-gray-300
                    dark:border-gray-700
                    focus:ring-indigo-500
                    ring-offset-transparent
                  "
                  @change="toggleTraitValues"
                >
              </div>

              <div class="flex-1 ml-2 text-sm">
                {{ item.name.slice(0, 20) }}
              </div>
            </div>

            <div class="text-xs font-medium text-gray-500 dark:text-gray-500">
              {{ formatNumber(item.values.length) }}
            </div>
          </div>

          <ul
            v-if="openId === item.id || searchText.length > 0"
            class="space-y-1"
          >
            <li
              v-for="attributeValue in item.values"
              :key="attributeValue.id"
            >
              <div class="flex relative items-start">
                <div class="flex items-center ml-3 h-5">
                  <input
                    :id="`avp-checkbox-${attributeValue.id}`"
                    v-model="model"
                    aria-describedby="comments-description"
                    :name="`avp-checkbox-${attributeValue.id}`"
                    type="checkbox"
                    :value="attributeValue.id"
                    class="
                      w-4
                      h-4
                      text-indigo-600
                      dark:bg-gray-800
                      rounded
                      border-gray-300
                      dark:border-gray-700
                      focus:ring-indigo-500
                      ring-offset-transparent
                    "
                  >
                </div>

                <div class="flex-1 ml-3 text-sm">
                  <label
                    :for="`avp-checkbox-${attributeValue.id}`"
                    class="flex justify-between items-center w-full font-medium text-gray-700 dark:text-gray-200"
                  >
                    <div>{{ attributeValue.value.slice(0, 20) }}</div>

                    <div class="text-xs text-gray-400 dark:text-gray-600">{{ formatNumber(attributeValue.tokenCount) }}</div>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>

      <div
        v-else
        class="py-3 text-center text-gray-600 dark:text-gray-400"
      >
        No matched traits
      </div>
    </template>

    <template v-else-if="isLoading">
      <div class="flex justify-center items-center h-6">
        <LoadingIndicator class="text-indigo-600" />
      </div>
    </template>

    <template v-else-if="!isLoading && (!attributes || attributes.length === 0)">
      <div class="py-3 text-center text-gray-600 dark:text-gray-400">
        No traits
      </div>
    </template>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
  ref,
  reactive,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import collectionAttributesQuery from '@/graphql/collection/queries/collectionAttributes.query.gql';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

import { formatNumber } from '@/utils/filters';
import UiInputGroup from './ui/UiInputGroup.vue';
import UiInput from './ui/UiInput.vue';

export default defineComponent({
  name: 'AttributeValuePicker',

  components: {
    UiInputGroup,
    UiInput,
    LoadingIndicator,
  },

  props: {
    attributeValuesIds: {
      type: Array,
      default: () => ([]),
    },
    collectionAddress: {
      type: String,
      required: true,
    },
  },

  emits: ['update:attributeValuesIds'],

  setup(props) {
    const {
      collectionAddress,
    } = toRefs(props);
    const attrCheckboxes = ref([]);
    const checkboxDictByItemId = reactive({});

    const {
      result: attributesResult,
      loading: isLoading,
    } = useQuery(
      collectionAttributesQuery,
      () => ({
        collectionAddress: collectionAddress.value,
      }),
    );

    const attributes = computed(() => {
      let data = attributesResult.value?.collection?.attributes;
      if (data) {
        data = JSON.parse(JSON.stringify(data));

        data.forEach((attr) => {
          attr.values.sort((a, b) => a.tokenCount - b.tokenCount);
        });
      }

      return data;
    });

    return {
      attributes,
      isLoading,
      attrCheckboxes,
      formatNumber,
      checkboxDictByItemId,
    };
  },

  data() {
    return {
      searchText: '',
      openId: null,
      attrIdsModel: [],
    };
  },

  computed: {
    model: {
      get() {
        return this.attributeValuesIds;
      },
      set(value) {
        this.$emit('update:attributeValuesIds', value);
        this.handleAttributesCheckboxes(value);
      },
    },

    displayedModels() {
      if (this.searchText?.length === 0) {
        return this.attributes || [];
      }

      const displayedModels = [];
      for (const item of this.attributes) {
        const data = { ...item };

        const values = [];
        for (const attr of data.values) {
          if (attr.value.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0) {
            values.push(attr);
          }
        }
        if (values.length) {
          data.values = values.sort((a, b) => a.tokenCount - b.tokenCount);
          displayedModels.push(data);
        }
      }
      return displayedModels;
    },
  },

  watch: {
    // TODO: Move this watcher to the setup() to unwatch after checkboxes are rendered
    attrCheckboxes(checkboxes) {
      if (checkboxes.length) {
        this.makeCheckBoxDict(checkboxes);
        this.handleAttributesCheckboxes(this.model);
      }
    },
  },

  methods: {
    openAttributeGroup(id) {
      this.openId = this.openId === id ? null : id;
    },

    toggleTraitValues(event) {
      const attribute = this.attributes.find((item) => item.id === event.target.value);
      const attributeValuesIds = attribute.values.map((item) => item.id);

      if (event.target.checked) {
        this.model = [...new Set([...this.model, ...attributeValuesIds])];
      } else {
        this.model = [...this.model.filter((item) => attributeValuesIds.indexOf(item) === -1)];
      }
    },

    makeCheckBoxDict(checkboxes) {
      checkboxes.forEach((checkbox) => {
        this.checkboxDictByItemId[checkbox.dataset.itemId] = checkbox;
      });
    },

    handleAttributesCheckboxes(value) {
      if (!this.attributes || !this.attributes.length) return;

      const selectedAttrcounts = {};

      value.forEach((attributeValueId) => {
        const attribute = this.attributes.find(
          (attr) => attr.values.find((v) => v.id === attributeValueId),
        );
        const attributeId = attribute.id;

        if (!selectedAttrcounts[attributeId]) {
          selectedAttrcounts[attributeId] = {
            attrValueCount: attribute.values.length,
            currentSelectedCount: 1,
          };
          return;
        }

        selectedAttrcounts[attributeId].currentSelectedCount += 1;
      });

      const selectedAttrcountsKeys = Object.keys(selectedAttrcounts);

      if (!selectedAttrcountsKeys.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const checkbox of this.attrCheckboxes) {
          checkbox.indeterminate = false;
        }
      }

      Object.keys(selectedAttrcounts).forEach((attributeId) => {
        const count = selectedAttrcounts[attributeId];
        if (
          count.attrValueCount === count.currentSelectedCount
          && this.attrIdsModel.indexOf(attributeId) === -1
        ) {
          this.attrIdsModel = [...this.attrIdsModel, attributeId];
        } else if (
          count.attrValueCount !== count.currentSelectedCount
          && this.attrIdsModel.indexOf(attributeId) !== -1
        ) {
          this.attrIdsModel = [...this.attrIdsModel.filter((id) => id !== attributeId)];
        }

        if (!this.checkboxDictByItemId[attributeId]) {
          return;
        }

        if (
          count.attrValueCount !== count.currentSelectedCount
          && selectedAttrcounts[attributeId].currentSelectedCount > 0
        ) {
          this.checkboxDictByItemId[attributeId].indeterminate = true;
        } else {
          this.checkboxDictByItemId[attributeId].indeterminate = false;
        }
      });
    },
  },
});
</script>

<style scoped>
</style>
