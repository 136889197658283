<template>
  <div class="p-3">
    <form
      autocomplete="off"
      @submit.prevent="$emit('submit', $event)"
    >
      <UiInputGroup label="Name">
        <UiInput
          :model-value="nameModel"
          :placeholder="`Name your ${noun}`"
          autofocus
          autocomplete="off"
          @update:modelValue="emit('update:nameModel', $event)"
        />
      </UiInputGroup>

      <UiInputGroup
        v-if="Array.isArray(itemsModel)"
        :label="capitalize(pluralize(type))"
      >
        <ResourcePicker
          :model-value="itemsModel"
          :only="type?.toLowerCase()"
          :multiple="true"
          @update:modelValue="emit('update:itemsModel', $event)"
        />
      </UiInputGroup>
    </form>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import UiInputGroup from '@/components/ui/UiInputGroup.vue';
import UiInput from '@/components/ui/UiInput.vue';
import { capitalize } from 'lodash-es';
import pluralize from 'pluralize';
import ResourcePicker from '../ResourcePicker.vue';

const props = defineProps(['nameModel', 'itemsModel', 'noun', 'type']);
const emit = defineEmits(['update:nameModel', 'submit', 'update:itemsModel']);

const {
  noun, type, itemsModel, nameModel,
} = toRefs(props);

</script>
