<template>
  <div class="relative">
    <div
      class="absolute top-3 right-3 z-10 w-10 h-10 leading-none bg-gray-800 hover:bg-gray-700 bg-opacity-60 rounded-full opacity-80 hover:opacity-100 cursor-pointer"
      @click="() => {track('premium modal closed'); close()}"
    >
      <div>
        <i class="absolute top-1/2 left-1/2 pt-0.5 text-xl leading-none text-gray-300 align-bottom transform -translate-x-1/2 -translate-y-1/2 far fa-times" />
      </div>
    </div>

    <div
      class="h-80 "
      :style="{
        backgroundImage: `url(${require('../assets/pass-pattern.png')})`,
        backgroundSize: '100%'
      }"
    />

    <div class="p-8">
      <!-- First step -->

      <div
        class="leading-relaxed text-center text-white"
      >
        <div class="mb-2 font-bold">
          Upgrade now {{ action }}
        </div>

        <div class="font-light">
          Get the best of Compass with Premium. Loved by over 1000 traders for best in class real time analytics, alerts, feeds, wallet insights, instant rarities and quickbuy. All major marketplaces covered.
        </div>
      </div>
      <!-- Second step (select package) -->

      <!-- Buttons -->
      <div
        class="flex gap-6 justify-center items-center mt-4"
      >
        <UiButton
          tag="router-link"
          :to="{ name: routesNames.accountUpgrade }"
          size="lg"
          class="w-36"
          @click="trackContinueAndClose"
        >
          <div class="flex gap-3 items-center">
            <span class="font-bold">Continue</span>

            <i class="fas fa-long-arrow-right" />
          </div>
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  toRefs,
} from 'vue';

import useTracking from '@/composition/tracking';
import routesNames from '@/router/routesNames';
import UiButton from './ui/UiButton.vue';

const props = defineProps({
  close: {
    type: Function,
    default() {
      return () => {};
    },
  },
});

const { track } = useTracking();

const { close } = toRefs(props);
const action = ref(null);

const show = (a) => {
  action.value = a;
  track('premium modal shown', { action: a });
};

defineExpose({
  show,
});

const trackContinueAndClose = () => {
  track('premium modal continue', { action: action.value });
  close.value();
};

</script>
