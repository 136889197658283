<template>
  <UiMultiSelect
    :model-value="modelValue"
    :options="selectOptions"
    :is-loading="loading"
    @update:modelValue="emit('update:modelValue', $event)"
  />
</template>

<script setup>
import { toRefs, computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import UiMultiSelect from '../ui/UiMultiSelect.vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
});

const {
  result,
  loading,
} = useQuery(gql`
  query GetAutomationTemplateTags {
    automationTemplateTags {
      id
      name
    }
  }
`);

const emit = defineEmits(['update:modelValue']);

const { modelValue } = toRefs(props);

const selectOptions = computed(() => result.value?.automationTemplateTags || []);
</script>
