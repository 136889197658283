<template>
  <div>
    <UiInputRadio
      v-model="type"
      :options="radioOptions"
      class="mb-3"
    />

    <UiInput
      v-if="!isVariable"
      v-model="localValue"
      v-autofocus
      placeholder="123"
    />

    <VariableDisplay
      v-else
      v-model="localValue"
    />

    <UiButton
      v-if="allowVariables === true"
      size="xs"
      variant="white"
      @click="selectValueVariable"
    >
      <i class="far fa-brackets-curly" />
    </UiButton>
  </div>
</template>

<script setup>

import {
  toRefs, ref, computed, watch, inject,
} from 'vue';
import VariableDisplay from '../automation/VariableDisplay.vue';
import UiButton from '../ui/UiButton.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputRadio from '../ui/UiInputRadio.vue';

const props = defineProps(['modelValue', 'allowVariables', 'actionId']);
const {
  modelValue, allowVariables, actionId,
} = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const validateNumber = (value) => allowVariables.value || value === 0 || (value && String(value).length > 0 && !Number.isNaN(Number(value)));

const type = ref(modelValue.value?.comparator || 'eq');
const localValue = ref(modelValue.value?.value || null);

const selectVariable = inject('selectVariable');

const isVariable = computed(() => localValue.value?.startsWith?.('{{') && localValue.value?.endsWith?.('}}'));

watch(modelValue, (v) => {
  type.value = v.comparator;
  if (Number(localValue.value) !== Number(v.value)) {
    localValue.value = v.value;
  }
}, { deep: true });

const radioOptions = [
  {
    text: 'Equal',
    value: 'eq',
  },
  {
    text: 'Not Equal',
    value: 'neq',
  },
  {
    text: 'Greater Than',
    value: 'gt',
  },
  {
    text: 'Less Than',
    value: 'lt',
  },
];

watch([type, localValue], ([t, v]) => {
  if (allowVariables.value && v && v.startsWith('{{') && v.endsWith('}}')) {
    emit('update:modelValue', { comparator: t, value: v });
    return;
  }

  if (!validateNumber(v)) {
    return;
  }

  emit('update:modelValue', { comparator: t, value: Number(v) });
});

const selectValueVariable = () => {
  selectVariable({
    type: 'Number',
  }, actionId.value).then((variable) => {
    localValue.value = `{{${variable.id}}}`;
  });
};
</script>
