<template>
  <UiButton
    variant="white"
    size="xs"
    :is-loading="isLoading || isChangingHiddenState"
    @click="toggleHidden"
  >
    <span v-if="isHidden">Un-hide</span>

    <span v-else>Hide</span>
  </UiButton>
</template>

<script setup>
import UiButton from '@/components/ui/UiButton.vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash-es';
import {
  computed, ref, toRefs,
} from 'vue';
import { useStore } from 'vuex';

const props = defineProps(['collection']);
const { collection } = toRefs(props);

const emit = defineEmits(['collectionHidden']);

const store = useStore();
const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

const USER_COLLECTION_HIDDEN_STATE_QUERY = gql`
query GetUserCollectionHiddenState($collectionId: String!) {
  me {
    isCollectionHidden(collectionId: $collectionId)
  }
}
`;

const {
  result: userBalanceData,
  loading: isLoading,
} = useQuery(
  USER_COLLECTION_HIDDEN_STATE_QUERY,
  () => ({ collectionId: collection.value?.id }),
  () => ({ enabled: !!collection.value?.id && isLoggedIn.value }),
);

const isHidden = computed(() => Boolean(userBalanceData.value?.me?.isCollectionHidden));

const isChangingHiddenState = ref(false);

const {
  mutate: toggleHiddenMutate,
} = useMutation(
  gql`
        mutation ToggleCollectionHiddenState($collectionId: String!) {
          toggleCollectionHiddenState(collectionId: $collectionId)
        }
      `,
  () => ({
    update(cache, result, options) {
      cache.updateQuery({
        query: USER_COLLECTION_HIDDEN_STATE_QUERY,
        variables: options.variables,
      }, (cached) => {
        const data = cloneDeep(cached);
        data.me.isCollectionHidden = result.data.toggleCollectionHiddenState;
        return data;
      });
    },
  }),
);

const toggleHidden = async () => {
  isChangingHiddenState.value = true;
  await toggleHiddenMutate({ collectionId: collection.value.id }).then(({ data: { toggleCollectionHiddenState } }) => {
    if (toggleCollectionHiddenState) {
      emit('collectionHidden', collection.value);
    }
  });
  isChangingHiddenState.value = false;
};
</script>
