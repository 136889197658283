<template>
  <div>
    <fieldset>
      <legend
        class="
          flex
          justify-between
          items-center
          w-full
          text-lg
          font-medium
          text-gray-900
          dark:text-gray-100
        "
      >
        <div v-if="noTitle === false">
          Channels
        </div>

        <div>
          <UiButton
            type="button"
            variant="white"
            size="xs"
            @click="() => openCreateChannel()"
          >
            Create channel
          </UiButton>
        </div>
      </legend>

      <div
        v-if="channels"
        class="
          mt-4
          dark:border-gray-800
          dark:divide-gray-800
          border-t border-b border-gray-200
          divide-y divide-gray-200
        "
      >
        <div
          v-for="item in channels"
          :key="item.id"
          class="flex relative items-center py-2"
        >
          <div class="flex flex-1 items-center space-x-2 min-w-0 text-sm">
            <label
              :for="`channel-${item.id}`"
              class="flex font-medium text-gray-700 dark:text-gray-300 select-none"
            >
              <div>{{ item.name }}</div>

              <div
                v-if="item.status ==='ERROR'"
                class="flex items-center py-0.5 px-1 ml-2 text-xs font-medium leading-none text-red-500 bg-red-500 bg-opacity-50 rounded"
              >Error</div>
            </label>

            <UiDropdown>
              <template #toggle="{toggle, isVisible}">
                <button
                  type="button"
                  class="text-lg text-gray-500"
                  @click="toggle"
                >
                  <i
                    v-if="!isVisible"
                    class="fas fa-ellipsis-h-alt"
                  />

                  <i
                    v-if="isVisible"
                    class="fas fa-ellipsis-h-alt"
                  />
                </button>
              </template>

              <template #body="{ forceClose }">
                <UiDropdownItem
                  class="cursor-pointer"
                  @click="() => {
                    deleteChannel(item);
                    forceClose();
                  }"
                >
                  Delete
                </UiDropdownItem>
              </template>
            </UiDropdown>
          </div>

          <div class="flex items-center ml-3 space-x-3 h-5">
            <UiCheckbox
              v-model="model"
              :value="item.id"
              :disabled="item.status !== 'ACTIVE'"
            />
          </div>
        </div>
      </div>

      <div v-else>
        <ViewLoader size="md" />
      </div>
    </fieldset>

    <UiModal v-model="isCreateChannelVisible">
      <CreateChannel @dismiss="isCreateChannelVisible" />
    </UiModal>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
  ref,
} from 'vue';
import { useUserChannelsGraphql } from '@/composition/user-channels';
import { useUiDialog } from '@/components/ui-dialog/useUiDialog';
import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiButton from '@/components/ui/UiButton.vue';
import CreateChannel from '@/components/alerts/CreateChannel.vue';
import ViewLoader from '@/components/ViewLoader.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiCheckbox from '../ui/UiCheckbox.vue';

export default defineComponent({
  name: 'ChannelPicker',

  components: {
    UiModal,
    UiButton,
    CreateChannel,
    ViewLoader,
    UiDropdown,
    UiDropdownItem,
    UiCheckbox,
  },

  props: {
    modelValue: null,
    noTitle: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const { channels, deleteAlertChannel, onResult } = useUserChannelsGraphql();
    const { info } = useUiDialog();

    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    onResult((queryResult) => {
      if (queryResult.data?.channels?.length === 1) {
        emit('update:modelValue', queryResult.data.channels.map((item) => item.id));
      }
    });

    const isCreateChannelVisible = ref(false);
    function openCreateChannel() {
      isCreateChannelVisible.value = true;
    }

    return {
      isCreateChannelVisible,
      openCreateChannel,
      channels,
      deleteAlertChannel,
      model,
      info,
    };
  },

  methods: {
    deleteChannel(channel) {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(`Are you sure that you want to delete the ${channel.name} channel?`)
      ) {
        return Promise.reject();
      }

      return this.deleteAlertChannel({ id: channel.id })
        .then(() => {
          this.info('Channel deleted');
        });
    },
  },
});
</script>

<style>
</style>
