<script setup>
import { computed, onMounted } from 'vue';
import { usePremium } from '@/composition/premium';
import { useRoute } from 'vue-router';
import { useLocalStorageModel } from '@/composition/storage';
import CollectionCharts from '@/components/collections/CollectionCharts.vue';
import CollectionChartsAdvanced from '@/components/collections/CollectionChartsAdvanced.vue';
import UiToggle from '@/components/ui/UiToggle.vue';
import { useCollection } from '@/composition/collections';
import useTracking from '@/composition/tracking';

const route = useRoute();
const { collection } = useCollection({ contractAddress: computed(() => route.params.contractAddress) });
const { isPremium, requirePremium } = usePremium();
const { model, initModel } = useLocalStorageModel('prefersAdvancedLayout', false);
const { setAttributes } = useTracking();

onMounted(() => initModel());

const isAdvancedLayout = computed({
  get() {
    let isAdvanced = false;
    if (!isPremium.value && collection.value.isPremiumRequired) {
      isAdvanced = false;
    } else {
      isAdvanced = model.value;
    }

    setAttributes({ isUsingAdvancedLayout: isAdvanced });

    return isAdvanced;
  },

  set(value) {
    if (value && !isPremium.value && collection.value.isPremiumRequired) {
      requirePremium();
      return;
    }

    model.value = value;
  },
});
</script>

<template>
  <div class="hidden lg:flex justify-end mx-auto mb-6 max-w-screen-2xl">
    <UiToggle v-model="isAdvancedLayout">
      <span class="font-medium">Advanced Layout</span>
    </UiToggle>
  </div>

  <CollectionChartsAdvanced
    v-if="isAdvancedLayout"
    :collection="collection"
  />

  <CollectionCharts
    v-else
    :collection="collection"
  />
</template>
