<template>
  <div class="flex fixed right-0 bottom-0 left-0 justify-between items-center p-1 px-3 text-sm font-medium leading-none bg-gray-950 bg-opacity-95 border-t border-gray-800 z-60">
    <div>
      <div class="flex">
        <div
          v-tooltip="'System status'"
          class="bottom-bar__item"
        >
          <SystemStatus />
        </div>

        <div
          v-tooltip="'Gas Price'"
          class="bottom-bar__item"
        >
          <GasTracker />
        </div>

        <div
          v-tooltip="'Customize Gas'"
          class="bottom-bar__item"
        >
          <GasPreset>
            <template #button="{show, currentPresetName}">
              <button
                class="flex items-center font-medium text-white"
                @click="show()"
              >
                <span class="hidden md:inline mr-2">
                  <i class="text-blue-300 fad fa-sliders-v-square" />
                </span>

                <span class="hidden md:inline mr-1">
                  Preset:
                </span>

                {{ currentPresetName }}
              </button>
            </template>
          </GasPreset>
        </div>

        <div
          v-tooltip="'Block number'"
          class="hidden md:block bottom-bar__item"
        >
          <LiveBadge />
        </div>

        <div
          v-tooltip="'ETH Price'"
          class="bottom-bar__item"
        >
          <div class="flex items-center">
            <i class="mr-2 text-gray-200 fab fa-ethereum" />

            <div class="font-medium tabular-nums text-white">
              ${{ formatNumber(ethPrice, 0) }}
            </div>
          </div>
        </div>

        <div
          class="hidden md:block bottom-bar__item"
        >
          <ProfitCalculator />
        </div>
      </div>
    </div>

    <div class="flex gap-1 items-center h-full">
      <a
        v-tooltip="'Follow us on Twitter'"
        href="https://twitter.com/compass_nft/"
        target="_blank"
        class="text-white hover:text-brand-twitter cursor-pointer bottom-bar__item"
      >
        <i class="fab fa-twitter" />
      </a>

      <a
        v-tooltip="'Join Our Discord'"
        href="https://discord.gg/rt6xh9D6qk"
        target="_blank"
        class="text-white hover:text-brand-discord cursor-pointer bottom-bar__item"
      >
        <i class="fab fa-discord" />
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { formatNumber } from '@/utils/filters';
import GasPreset from './GasPreset.vue';
import GasTracker from './GasTracker.vue';
import LiveBadge from './LiveBadge.vue';
import SystemStatus from './SystemStatus.vue';
import ProfitCalculator from './ProfitCalculator.vue';

export default defineComponent({
  components: {
    LiveBadge,
    GasTracker,
    GasPreset,
    SystemStatus,
    ProfitCalculator,
  },

  setup() {
    return {
      formatNumber,
    };
  },

  computed: {
    ethPrice() {
      return this.$store.getters['gas/ethPrice'];
    },
  },

});
</script>

<style>
  .bottom-bar__item   {
    @apply py-1.5 px-2 cursor-pointer transition-colors rounded-md;
  }
  .bottom-bar__item:hover {
    @apply bg-gray-800 overflow-hidden;
  }
</style>
