<template>
  <span
    v-tooltip="minimal === false ? undefined : typeMap[type] || capitalize(type)"
    class="inline-flex items-center text-xs font-medium leading-none text-gray-900 rounded-full border border-white border-opacity-10"
    :class="[classes, {'py-0.5 px-2': minimal === false, 'w-3 h-3': minimal !== false}]"
  >
    <span
      v-if="minimal === false"
    >{{ typeMap[type] || capitalize(type) }}</span>
  </span>
</template>

<script setup>
import { capitalize } from 'lodash-es';
import { computed, toRefs } from 'vue';

const props = defineProps({
  type: null,
  minimal: {
    type: Boolean,
    default: false,
  },
  typeMap: {
    type: Object,
    default: () => ({
      purchase: 'Listing',
      bid: 'Offer',
    }),
  },
});

const { type: typeProp, minimal, typeMap } = toRefs(props);
const type = computed(() => typeProp.value?.toLowerCase());

const classes = computed(() => `bg-pnl-${type.value}`);
</script>
