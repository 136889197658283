<template>
  <div>
    <div
      v-if="question"
      class="mb-2 text-2xl font-semibold text-indigo-600"
    >
      {{ question }}
    </div>

    <div class="text-lg text-gray-400">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ['question'],
};
</script>

<style>

</style>
