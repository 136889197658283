<template>
  <UiDropdown :body-width-class="hasRevealAlert ? 'w-48' : 'w-64'">
    <template #toggle="{ toggle }">
      <UiBadge
        v-if="isRevealed"
        variant="indigo"
        :class="{'animate-pulse': revealProgress > 0 && revealProgress < 1}"
      >
        <span
          v-if="revealProgress >= 0"
          class="mr-1"
        >{{ formatNumber(100 * revealProgress, 0) }}%</span>
        Revealed
      </UiBadge>

      <button
        v-else
        v-tooltip="{ content: tooltipContent, allowHTML: true }"
        class="cursor-pointer"
        @click="toggle"
      >
        <UiBadge
          variant="yellow"
          :class="{'subscribed-badge': hasRevealAlert}"
        >
          <i
            :class="[
              'fad',
              hasRevealAlert ? 'fa-check-circle' : 'fa-pause-circle',
              'mr-1',
            ]"
          />
          Unrevealed
        </UiBadge>
      </button>
    </template>

    <template #body="{toggle}">
      <UiDropdownForm v-if="!hasRevealAlert">
        <PremiumPlaceholder>
          <ChannelPicker
            v-model="channelIds"
            class="mb-3"
          />

          <div>
            <UiButton
              :disabled="channelIds.length === 0"
              size="sm"
              :is-loading="isCreatingRevealAlert"
              @click="createRevealAlert(() => toggle())"
            >
              Subscribe
            </UiButton>
          </div>
        </PremiumPlaceholder>
      </UiDropdownForm>

      <UiDropdownItem
        v-else
        @click="deleteRevealAlert()"
      >
        Unsubscribe
      </UiDropdownItem>
    </template>
  </UiDropdown>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
  ref,
} from 'vue';
import {
  useApolloClient,
  useQuery,
  useSubscription,
} from '@vue/apollo-composable';
import revealAlertsQuery from '@/graphql/alert/queries/revealAlerts.query.gql';
import deleteAlertMutation from '@/graphql/alert/mutations/deleteAlert.mutation.gql';
import createAlertMutation from '@/graphql/alert/mutations/createAlert.mutation.gql';
import revealProgressQuery from '@/graphql/collection/queries/revealProgress.query.gql';
import revealProgressSubs from '@/graphql/collection/subscriptions/revealProgress.subscription.gql';
import { formatNumber } from '@/utils/filters';

import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import ChannelPicker from '@/components/alerts/ChannelPicker.vue';
import UiBadge from '@/components/ui/UiBadge.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDropdownForm from '@/components/ui/UiDropdownForm.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import PremiumPlaceholder from '@/components/PremiumPlaceholder.vue';

export default defineComponent({
  components: {
    UiBadge,
    UiDropdown,
    UiDropdownForm,
    UiButton,
    ChannelPicker,
    UiDropdownItem,
    PremiumPlaceholder,
  },

  props: ['collection', 'isRevealed'],

  emits: ['reveal'],

  setup(props, { emit }) {
    const { collection } = toRefs(props);
    const { resolveClient } = useApolloClient('default');
    const apolloClient = resolveClient();

    const {
      result: revealAlertsResult,
    } = useQuery(revealAlertsQuery, () => ({ collectionId: collection.value?.id }));
    const revealAlerts = computed(() => revealAlertsResult.value?.revealAlerts);

    const {
      result: revealProgressResult,
    } = useQuery(revealProgressQuery, () => ({ collectionId: collection.value?.id }));

    const { onResult } = useSubscription(
      revealProgressSubs,
      () => ({
        collectionId: collection.value?.id,
      }),
    );

    onResult(({ data }) => {
      const queryParams = {
        query: revealProgressQuery,
        variables: {
          collectionId: collection.value.id,
        },
      };

      apolloClient.cache.updateQuery(queryParams, (cachedData) => {
        if (!cachedData) {
          return cachedData;
        }
        return {
          collection: {
            ...cachedData.collection,
            revealProgress: data.revealProgress,
          },
        };
      });

      if (data.revealProgress === 1) {
        emit('reveal');
      }
    });

    const channelIds = ref([]);
    const isCreatingRevealAlert = ref(false);
    return {
      channelIds,
      isCreatingRevealAlert,
      revealAlerts,
      apolloClient,
      formatNumber,
      revealProgressResult,
    };
  },

  computed: {
    revealProgress() {
      return this.revealProgressResult?.collection?.revealProgress;
    },
    tooltipContent() {
      return this.hasRevealAlert
        ? '<i class="fad fa-check-circle text-green-500 mr-1"></i> Subscribed'
        : 'Subscribe to reveal';
    },
    hasRevealAlert() {
      return this.revealAlerts?.length > 0;
    },
    revealAlert() {
      return this.revealAlerts?.[0] || null;
    },
  },
  methods: {
    createRevealAlert(callback = () => {}) {
      this.isCreatingRevealAlert = true;
      const { id: collectionId } = this.collection;
      return this.apolloClient.mutate({
        mutation: createAlertMutation,
        variables: {
          name: `${this.collection.name} Reveal Alert`,
          config: { collectionId: this.collection.id },
          type: 'REVEAL',
          channels: this.channelIds,
        },
        awaitRefetchQueries: true,
        refetchQueries() {
          return [
            { query: revealAlertsQuery, variables: { collectionId } },
          ];
        },
      }).then(callback).finally(() => {
        this.isCreatingRevealAlert = false;
      });
    },

    deleteRevealAlert(callback = () => {}) {
      const { id: collectionId } = this.collection;
      if (!this.revealAlert) {
        return Promise.resolve();
      }
      return this.apolloClient
        .mutate({
          mutation: deleteAlertMutation,
          variables: {
            id: this.revealAlert.id,
          },
          refetchQueries() {
            return [
              { query: revealAlertsQuery, variables: { collectionId } },
            ];
          },
        })
        .then(callback);
    },
  },
});
</script>

<style scoped>
.subscribed-badge {
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(113, 63, 18, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(113, 63, 18, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(113, 63, 18, 0);
  }
}
</style>
