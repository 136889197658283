<template>
  <div
    v-if="collection"
    class="mx-auto max-w-screen-2xl"
  >
    <CollectionSalesMetrics
      class="mb-6"
      :collection="collection"
    />

    <div class="mb-6">
      <div class="grid lg:grid-cols-2 gap-6 mb-6">
        <CollectionPriceChart :collection="collection" />

        <CollectionListingsChart :collection="collection" />
      </div>

      <TokenPriceOverTimeChart
        class="flex-1"
        :collection="collection"
      />
    </div>

    <div class="grid md:grid-cols-2 gap-6">
      <div>
        <!-- <orders-livefeed :collection="collection" class="h-full" /> -->
        <CollectionActivityFeed
          :collection="collection"
          type="listings"
          class="h-visible"
        />
      </div>

      <div>
        <!-- <sales-live-feed :collection="collection" class="h-full"/> -->
        <CollectionActivityFeed
          :collection="collection"
          type="sales"
          class="h-visible"
        />
      </div>
    </div>
  </div>
</template>

<script setup>

import { toRefs } from 'vue';
import TokenPriceOverTimeChart from './TokenPriceOverTimeChart.vue';
import CollectionActivityFeed from './CollectionActivityFeed.vue';
import CollectionListingsChart from './CollectionListingsChart.vue';
import CollectionSalesMetrics from './CollectionSalesMetrics.vue';
import CollectionPriceChart from './CollectionPriceChart.vue';

const props = defineProps(['collection']);

const { collection } = toRefs(props);
</script>
