<template>
  <form
    v-if="model"
    @submit.prevent
  >
    <div
      v-if="model.type === 'WALLET_ACTIVITY'"
      class="mb-6"
    >
      <UiInputGroup label="Wallets">
        <ResourcePicker
          v-model="model.config.wallets"
          only="wallet"
          multiple
        />
      </UiInputGroup>

      <UiInputGroup label="Activity type">
        <UiRadioCards
          v-model="model.config.types"
          type="checkbox"
          :cols="5"
          :options="activityTypes"
        />
      </UiInputGroup>
    </div>

    <div
      v-if="model.type === 'FLOOR_PRICE'"
      class="mb-6"
    >
      <Banner
        v-if="model.status == 'ERROR'"
        variant="red"
        class="mb-3"
      >
        <p class="mb-2">
          It looks like your Alert is invalid.
        </p>

        <p>
          Make sure you selected the right <span class="font-semibold">collection</span>

          and set a valid <span class="font-semibold">threshold</span> for the alert.
        </p>
      </Banner>

      <UiInputGroup label="Collection">
        <UniversalSearch
          v-if="!model.config.collectionId"
          only="collection"
          @selectResult="setCollectionId($event)"
        >
          <template #search-field="{ search, show }">
            <div class="flex relative items-center">
              <span
                class="absolute ml-3 text-sm text-gray-500"
              ><i class="far fa-search" /></span>

              <UiInput
                v-model="search.term"
                class="pl-8"
                placeholder="Search collections"
                @focus="show"
              />
            </div>
          </template>
        </UniversalSearch>

        <div
          v-if="model.config.collectionId"
          class="flex justify-between items-center p-2 rounded border dark:border-gray-700"
        >
          <CollectionListItem
            :pic-size="8"
            :mini="true"
            no-overlay
            :address="model.config.collectionId"
          />

          <UiButton
            variant="white"
            size="xs"
            type="button"
            @click="model.config.collectionId = null"
          >
            Change
          </UiButton>
        </div>
      </UiInputGroup>

      <UiInputGroup
        v-if="model.config.collectionId && model.config.type"
        :label="`Threshold in ETH - Current: ${formatCurrency(listedFloor)}`"
      >
        <div class="flex">
          <UiInput
            v-model="thresholdValue"
            type="number"
            placeholder="0.1"
            :disabled="!model.config.type"
            step="any"
            @keyup.enter="addThreshold"
          />

          <UiButton
            class="ml-3 w-28"
            type="button"
            size="sm"
            @click="addThreshold"
          >
            Add Threshold
          </UiButton>
        </div>
      </UiInputGroup>

      <div class="flex items-center pb-1 font-medium text-gray-700 dark:text-gray-400 text-md">
        Thresholds
        <UiBadge
          v-if="deletedUnsaved"
          variant="yellow"
          class="ml-3"
        >
          unsaved removed items
        </UiBadge>
      </div>

      <div
        v-for="(threshold, idx) in model.config.threshold"
        :key="idx"
        class="flex justify-between items-center font-medium text-gray-300 dark:text-gray-300 text-md"
      >
        <div class="flex items-center">
          <span>
            Ξ {{ threshold.value.toString().slice(0, 24) }}
          </span>

          <UiBadge
            v-if="threshold.isNew"
            variant="yellow"
            class="ml-3"
          >
            unsaved
          </UiBadge>
        </div>

        <div
          class="cursor-pointer"
          @click="removeThreshold(idx)"
        >
          <i class="p-2 text-xl far fa-times" />
        </div>
      </div>
    </div>

    <div
      v-if="model.type === 'COLLECTION_LISTINGS'"
    >
      <Banner
        v-if="model.status === 'ERROR'"
        variant="red"
        class="mb-3"
      >
        <p class="mb-2">
          It looks like your Alert is invalid.
        </p>

        <p>
          Make sure you selected <span class="font-bold">at least one </span>
          of the following filters:
          <span class="font-bold">Owner, #Token ID, Traits</span>
        </p>
      </Banner>

      <UiInputGroup label="Collection">
        <UniversalSearch
          v-if="!model.config.collectionId"
          only="collection"
          @selectResult="setCollectionId($event)"
        >
          <template #search-field="{ search, show }">
            <div class="flex relative items-center">
              <span
                class="absolute ml-3 text-sm text-gray-500"
              ><i class="far fa-search" /></span>

              <UiInput
                v-model="search.term"
                class="pl-8"
                placeholder="Search collections"
                @focus="show"
              />
            </div>
          </template>
        </UniversalSearch>

        <div
          v-if="model.config.collectionId"
          class="flex justify-between items-center p-2 rounded border dark:border-gray-700"
        >
          <CollectionListItem
            :pic-size="8"
            :mini="true"
            no-overlay
            :address="model.config.collectionId"
          />

          <UiButton
            variant="white"
            size="xs"
            type="button"
            @click="model.config.collectionId = null"
          >
            Change
          </UiButton>
        </div>
      </UiInputGroup>

      <div v-if="model.config.collectionId">
        <div class="pb-3 mb-3 border-b border-black">
          <div class="flex items-center mb-2 leading-none">
            <div class="mr-2">
              <i class="dark:text-gray-300 fas fa-wallet" />
            </div>

            <div class="text-sm font-medium dark:text-gray-100">
              Owner
            </div>
          </div>

          <UiInputGroup
            label="Wallet address"
            class="mb-0"
          >
            <UiInput
              v-model="model.config.tokenFilters.owner"
              placeholder="0x123"
              class="w-full"
            />
          </UiInputGroup>

          <div class="flex space-x-2">
            <UiDropdown>
              <template #toggle="{toggle}">
                <UiButton
                  size="xs"
                  variant="primary"
                  @click="toggle"
                >
                  Me
                </UiButton>
              </template>

              <template #body="{ forceClose }">
                <UiDropdownItem
                  v-for="item in userWallets"
                  :key="item.id"
                  @click="() => {
                    setOwnerToSelf(item.id);
                    forceClose();
                  }"
                >
                  <WalletProfile
                    :wallet="item"
                    :pic-size="4"
                    no-overlay
                    no-link
                  />
                </UiDropdownItem>
              </template>
            </UiDropdown>

            <UiButton
              size="xs"
              :disabled="!model.config.tokenFilters.owner || model.config.tokenFilters.owner.length === 0"
              variant="white"
              @click="model.config.tokenFilters.owner = null"
            >
              Clear
            </UiButton>
          </div>
        </div>

        <div class="pb-3 mb-3 border-b border-black">
          <div class="flex items-center mb-2 leading-none">
            <div class="mr-2">
              <i class="dark:text-gray-300 fas fa-hashtag" />
            </div>

            <div class="text-sm font-medium dark:text-gray-100">
              Token ID
            </div>
          </div>

          <UiInputGroup class="mb-0">
            <UiInput
              v-model="model.config.tokenFilters.tokenId"
              placeholder="123"
              class="w-full "
            />
          </UiInputGroup>

          <UiButton
            size="xs"
            :disabled="!model.config.tokenFilters.tokenId || model.config.tokenFilters.tokenId.length === 0"
            variant="white"
            @click="model.config.tokenFilters.tokenId = null"
          >
            Clear
          </UiButton>
        </div>

        <div class="pb-3 mb-3 border-b border-black">
          <div class="flex items-center mb-2 leading-none">
            <div class="mr-2">
              <i class="dark:text-gray-300 fas fa-money-bill-wave" />
            </div>

            <div class="text-sm font-medium dark:text-gray-100">
              Price
            </div>
          </div>

          <div class="grid grid-cols-2 gap-3">
            <UiInputGroup label="Min">
              <UiInput
                v-model="model.config.tokenFilters.priceFrom"
                type="number"
              />
            </UiInputGroup>

            <UiInputGroup label="Max">
              <UiInput
                v-model="model.config.tokenFilters.priceTo"
                type="number"
              />
            </UiInputGroup>
          </div>

          <UiButton
            size="xs"
            :disabled="!model.config.tokenFilters.priceTo && !model.config.tokenFilters.priceFrom"
            variant="white"
            @click="
              model.config.tokenFilters.priceTo = null;
              model.config.tokenFilters.priceFrom = null;
            "
          >
            Clear
          </UiButton>
        </div>

        <div class="pb-3 mb-3 border-b border-black">
          <div class="flex items-center mb-2 leading-none">
            <div class="mr-2">
              <i class="dark:text-gray-300 fas fa-tags" />
            </div>

            <div class="text-sm font-medium dark:text-gray-100">
              Traits
            </div>
          </div>

          <AttributeValuePicker
            v-model:attribute-values-ids="model.config.tokenFilters.attributeValueIds"
            :collection-address="model.config.collectionId"
          />
        </div>
      </div>
    </div>

    <div class="mb-6">
      <ChannelPicker
        v-model="model.channels"
      />

      <UiInputErrorMessage
        v-if="inputErrors.channels"
        class="mt-2"
      >
        {{ inputErrors.channels }}
      </UiInputErrorMessage>
    </div>

    <UiInputGroup
      label="Name"
      :error-message="inputErrors.name"
    >
      <UiInput
        v-model="model.name"
        name="name"
        placeholder="Name your alert"
        autocomplete="off"
        @keyup.enter="onLastFieldEnter"
      />
    </UiInputGroup>
  </form>
</template>

<script>
import {
  defineComponent,
  toRefs,
  computed,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useStore } from 'vuex';
import collectionSalesFloorQuery from '@/graphql/collection/queries/collectionSalesFloor.query.gql';
import collectionListedFloorQuery from '@/graphql/collection/queries/collectionListedFloor.query.gql';

import { formatCurrency } from '@/utils/filters';
import { invalidateListingAlert } from '@/utils/invalidateListingAlert';

import UniversalSearch from '@/components/universal-search/UniversalSearch.vue';
import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiButton from '@/components/ui/UiButton.vue';
import CollectionListItem from '@/components/collections/CollectionListItem.vue';
import UiBadge from '@/components/ui/UiBadge.vue';
import WalletProfile from '@/components/wallets/WalletProfile.vue';

import UiInput from '@/components/ui/UiInput.vue';
import UiInputGroup from '@/components/ui/UiInputGroup.vue';

import UiRadioCards from '@/components/ui/UiRadioCards.vue';
import ChannelPicker from './ChannelPicker.vue';
import ResourcePicker from '../ResourcePicker.vue';
import Banner from '../Banner.vue';
import AttributeValuePicker from '../AttributeValuePicker.vue';
import UiInputErrorMessage from '../ui/UiInputErrorMessage.vue';

export const activityTypes = [
  { title: 'Mint', value: 'mint' },
  { title: 'Burn', value: 'burn' },
  { title: 'Sale', value: 'sale' },
  { title: 'Purchase', value: 'purchase' },
  { title: 'Transfer', value: 'transfer' },
];

const floorTypes = [
  { title: 'Sales floor', value: 'salesFloor' },
  { title: 'Listed floor', value: 'listedFloor', disabled: false },
];
export default defineComponent({
  name: 'AlertForm',
  components: {
    UiInputGroup,
    UiInput,
    UiRadioCards,
    UiBadge,
    UiDropdown,
    UiDropdownItem,
    WalletProfile,
    ChannelPicker,
    UniversalSearch,
    CollectionListItem,
    UiButton,
    ResourcePicker,
    Banner,
    AttributeValuePicker,
    UiInputErrorMessage,
  },

  props: {
    modelValue: null,
    onLastFieldEnter: {
      type: Function,
      default() {},
    },
    inputErrors: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const store = useStore();

    const wallet = computed(() => store.getters['auth/wallet']);
    const userWallets = computed(() => store.getters['auth/wallets']);
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });

    const {
      result: salesFloorResult,
    } = useQuery(
      collectionSalesFloorQuery,
      () => ({ collectionId: model.value?.config.collectionId }),
      () => ({ enabled: model.value?.type === 'FLOOR_PRICE' && model.value?.config?.type === 'salesFloor' }),
    );
    const salesFloor = computed(() => salesFloorResult.value?.collection.salesFloor);

    const {
      result: listedFloorResult,
    } = useQuery(
      collectionListedFloorQuery,
      () => ({ collectionId: model.value?.config.collectionId }),
      () => ({
        enabled: model.value?.type === 'FLOOR_PRICE'
                     && model.value?.config?.type === 'listedFloor'
                     && Boolean(model.value?.config.collectionId),
      }),
    );
    const listedFloor = computed(() => listedFloorResult.value?.collection.listedFloor);

    return {
      model,
      salesFloor,
      listedFloor,
      formatCurrency,
      wallet,
      userWallets,
      isLoggedIn,
    };
  },

  data() {
    return {
      activityTypes,
      floorTypes,
      collectionName: null,
      thresholdValue: null,
      deletedUnsaved: false,
    };
  },

  methods: {
    invalidateListingAlert,

    setCollectionId({ type, data }) {
      if (type === 'collection') {
        this.model.config.collectionId = data.id;
        this.collectionName = data.name;
        this.model.config.type = 'listedFloor';
      }
    },

    addThreshold() {
      if (this.thresholdValue !== 0 && !this.thresholdValue) return;

      const newModel = JSON.parse(JSON.stringify(this.model));

      newModel.config.threshold = [
        ...newModel.config.threshold,
        { value: parseFloat(this.thresholdValue), isNew: true },
      ].sort((a, b) => a.value - b.value);

      this.model = newModel;

      this.thresholdValue = null;
    },

    removeThreshold(idx) {
      this.model = {
        ...this.model,
        config: {
          ...this.model.config,
          threshold: this.model.config.threshold.filter((_, id) => id !== idx).sort((a, b) => a.value - b.value),
        },
      };
      this.deletedUnsaved = true;
    },

    async setOwnerToSelf(id = null) {
      if (!this.isLoggedIn) {
        await this.$root.requireLogin();
      }

      this.model.config.tokenFilters.owner = id || this.userWallets[0].id;
    },
  },
});
</script>
