const STORAGE_KEY = 'user-search-results';

export function useRecentResults() {
  function getUserSearchResults() {
    return JSON.parse(sessionStorage.getItem(STORAGE_KEY)) || {};
  }

  function saveRecentResultToStorage({ type, data }) {
    const userSearchResults = getUserSearchResults();

    if (userSearchResults?.[type]?.[data.id]) {
      return;
    }

    if (!userSearchResults?.[type]) {
      userSearchResults[type] = {};
    }

    userSearchResults[type][data.id] = data;

    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(userSearchResults));
  }

  function getRecentResultsFromStorage(type = null) {
    const userSearchResults = getUserSearchResults();
    let resultsArr = [];
    const searchTypesKeys = Object.keys(userSearchResults);

    if (searchTypesKeys.length === 0) return resultsArr;

    if (type && typeof userSearchResults[type] === 'undefined') {
      return [];
    }

    if (!type) {
      searchTypesKeys.forEach((key) => {
        resultsArr = resultsArr.concat(Object.values(userSearchResults[key]));
      });
    } else {
      resultsArr = resultsArr.concat(Object.values(userSearchResults[type]));
    }

    return resultsArr.reverse();
  }

  return {
    saveRecentResultToStorage,
    getRecentResultsFromStorage,
  };
}
