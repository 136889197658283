<template>
  <UiModalHeader
    title="Create Alert Channel"
    @dismiss="$emit('dismiss')"
  />

  <div class="p-4">
    <form>
      <!-- https://discord.com/api/webhooks/921082794870992966/Kdg4Kb3AMQkQ5_lg90sWpTe3EkBIa9fucbFh54svqqdrnz-TDKtEZIweJUc2MPSyqQLx -->
      <UiInputGroup label="Name">
        <UiInput v-model="nameModel" />
      </UiInputGroup>

      <UiInputGroup label="Type">
        <UiRadioCards
          v-model="model.type"
          size="md"
          :options="channelTypes"
        />
      </UiInputGroup>

      <div v-if="model.type === 'DISCORD_WEBHOOK'">
        <UiInputGroup label="Webhook URL">
          <UiInput
            v-model="model.config.url"
            placeholder="https://discord.com/api/webhooks/..."
          />
        </UiInputGroup>
      </div>

      <div v-if="model.type === 'DISCORD_DM'">
        <div class="mb-2 text-sm">
          <div class="font-medium">
            Step 1
          </div>

          <div class="text-gray-700 dark:text-gray-300">
            <p>
              Join our <UiLink
                target="_blank"
                class="text-indigo-600 hover:text-indigo-700"
                href="https://discord.gg/pzsMNaKjfN"
              >
                Discord
              </UiLink>

              and send <span class="py-0.5 px-1 dark:text-gray-400 bg-gray-200 dark:bg-gray-800 rounded-sm">/my-id</span> to #roboroom
            </p>
          </div>
        </div>

        <div class="text-sm">
          <div class="font-medium">
            Step 2
          </div>

          <div class="text-gray-700 dark:text-gray-300">
            <p>Enter your Discord User ID that our bot DMed you below</p>
          </div>
        </div>

        <UiInputGroup class="mt-1">
          <UiInput
            v-model="model.config.chatId"
            placeholder="123456789"
          />
        </UiInputGroup>
      </div>

      <div v-if="model.type === 'WEBHOOK'">
        <UiInputGroup label="Webhook URL">
          <UiInput
            v-model="model.config.url"
            placeholder="https://example.com/webhook/..."
          />
        </UiInputGroup>
      </div>

      <div v-if="model.type === 'TELEGRAM'">
        <div class="mb-2 text-sm">
          <div class="font-medium">
            Step 1
          </div>

          <div class="text-gray-700 dark:text-gray-300">
            <p>
              Send <span class="py-0.5 px-1 dark:text-gray-400 bg-gray-200 dark:bg-gray-800 rounded-sm">/start</span>

              to <UiLink
                target="_blank"
                class="text-indigo-600 hover:text-indigo-700"
                href="http://t.me/compass_nft_alert_bot"
              >
                @compass_nft_alert_bot
              </UiLink>
            </p>
          </div>
        </div>

        <div class="text-sm">
          <div class="font-medium">
            Step 2
          </div>

          <div class="text-gray-700 dark:text-gray-300">
            <p>Enter your Telegram ID below</p>
          </div>
        </div>

        <UiInputGroup class="mt-1">
          <UiInput
            v-model="model.config.chatId"
            placeholder="123456789"
          />
        </UiInputGroup>
      </div>

      <div v-if="model.type === 'PUSH'">
        <UiButton
          type="button"
          @click="setupPush"
        >
          Set up push
        </UiButton>

        <div
          v-if="model.config.pushConfig"
          class="text-green-500"
        >
          Done
        </div>
      </div>
    </form>
  </div>

  <UiModalFooter>
    <UiButton
      :disabled="!canCreate"
      :is-loading="isCreating"
      @click="create"
    >
      Create
    </UiButton>
  </UiModalFooter>
</template>

<script>

import { defineComponent } from 'vue';
import { useUserChannelsGraphql } from '@/composition/user-channels';

import { isValidURL } from '@/utils/isValidURL';
import { useUiDialog } from '@/components/ui-dialog/useUiDialog';
import gql from 'graphql-tag';
import UiButton from '../ui/UiButton.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiModalFooter from '../ui/UiModalFooter.vue';
import UiModalHeader from '../ui/UiModalHeader.vue';
import UiRadioCards from '../ui/UiRadioCards.vue';
import UiLink from '../ui/UiLink.vue';
import apolloClients from '../../vue-apollo';

function urlBase64ToUint8Array(base64String) {
  // eslint-disable-next-line no-mixed-operators
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const channelTypes = [
  { title: 'Webhook', value: 'WEBHOOK' },
  { title: 'Discord webhook', value: 'DISCORD_WEBHOOK' },
  { title: 'Discord DM', value: 'DISCORD_DM' },
  { title: 'Telegram', value: 'TELEGRAM' },
  { title: 'Push', value: 'PUSH' },
  // { title: "Telegram", value: "DISCORD_DM" },
];

function defaultConfig() {
  return {
    url: undefined,
    chatId: undefined,
    pushConfig: undefined,
  };
}

export default defineComponent({
  components: {
    UiModalHeader,
    UiInputGroup,
    UiInput,
    UiModalFooter,
    UiButton,
    UiRadioCards,
    UiLink,
  },

  emits: ['dismiss'],

  setup() {
    const { info } = useUiDialog();
    const {
      createChannel: createChannelMutation,
    } = useUserChannelsGraphql({ queryEnabled: false });

    return {
      info,
      createChannelMutation,
    };
  },

  data() {
    return {
      isVisible: false,
      isCreating: false,
      channelTypes,
      model: {
        name: '',
        type: null,
        config: defaultConfig(),
      },

    };
  },
  computed: {
    canCreate() {
      if (this.model.type === null) {
        return false;
      }

      if (!this.model.config.url && !this.model.config.chatId && !this.model.config.pushConfig) {
        return false;
      }

      return true;
    },
    type() {
      return this.model.type;
    },
    nameModel: {
      get() {
        if (this.model.name.length > 0) {
          return this.model.name;
        }

        if (this.type || this.model.config.url || this.model.config.chatId) {
          return this.preFillChannelName({
            type: this.type,
            identifier: this.model.config.url || this.model.config.chatId,
          });
        }
        return '';
      },
      set(val) {
        this.model.name = val;
      },
    },
  },
  watch: {
    type() {
      this.model.config = defaultConfig();
    },
  },
  methods: {
    show() {
      this.isVisible = true;
    },
    preFillChannelName({ type, identifier }) {
      let name = '';

      if (type) {
        const { title } = channelTypes.find((channel) => channel.value === type);
        name = title;
      }

      if (!identifier) {
        return name;
      }

      if (isValidURL(identifier)) {
        const { hostname } = new URL(identifier);
        name = `${name}:${hostname}`;
      } else {
        name = `${name}:${identifier}`;
      }
      return name;
    },
    create() {
      this.isCreating = true;

      if (this.model.name.length === 0) {
        this.model.name = this.nameModel;
      }

      return this.createChannelMutation({ ...this.model })
        .then(() => {
          this.isVisible = false;
          this.info('Channel created');
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('fail', err);
          this.info('Failed to create channel');
        }).finally(() => {
          this.isCreating = false;
        });
    },
    setupPush() {
      if ('serviceWorker' in navigator) {
        const registerWorker = async () => {
          const registration = await navigator.serviceWorker.getRegistration('/');

          const subscription = await registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: 'BPmQlm0xCde7i-DElx87CCqUt4wWWhPHXKfJ576qlENVLbxwp-hKYthSfi9wHectiC6n7n9xEvRTaQK5gzbJtvM',
            });

          this.model.config.pushConfig = subscription;
        };
        registerWorker().catch((error) => console.error(error));
      }
    },
  },
});
</script>

<style>
</style>
