<template>
  <OverlayComponent
    ref="overlay"
    :no-overlay="noOverlay"
    :width="250"
    @visible="show"
    @hidden="hide"
  >
    <Component
      :is="itemComponentProps.is"
      v-bind="itemComponentProps"
    >
      <div
        class="flex items-center leading-none"
        :class="[vertical !== false ? 'flex-col' : 'flex-row', `gap-${Math.ceil((picSize || 8) / 3)}`]"
      >
        <div
          class="flex-shrink-0"
          :class="`w-${picSize || 8} h-${picSize || 8}`"
        >
          <ProfilePicture
            v-if="!isLoading"
            :src="profilePictureUrl"
            :address="resolvedAddress"
            :size="picSize || 8"
            class="flex-grow-0 flex-shrink-0"
          />

          <div
            v-if="isLoading"
            :class="`w-${picSize || 8} h-${picSize || 8}`"
            class="bg-gray-700 rounded-full animate-pulse"
          />
        </div>

        <div v-if="onlyPic === false">
          <div

            class="flex relative flex-row gap-1 items-center whitespace-nowrap"
            :class="nameClass"
          >
            {{ displayName.slice(0, 20) }}
            <i
              v-if="isVerified"
              class="text-blue-400 fas fa-badge-check"
              title="Verified on Opensea"
            />

            <div
              v-if="isLoading"
              class="w-24 h-3 bg-gray-700 rounded animate-pulse"
            />

            <button
              v-if="!isLoading && hover && noOverlay !== false"
              type="button"
              class="absolute left-full ml-1 text-gray-300 hover:text-gray-100"
              @click.stop.prevent="openWalletNameEditor"
            >
              <i class="far fa-edit" />
            </button>
          </div>

          <slot name="extra" />
        </div>
      </div>
    </Component>

    <template
      v-if="!isLoading && hover"
      #overlay-content
    >
      <slot
        name="context"
      />

      <div class="flex gap-6">
        <MetricWidget
          :is-loading="(isPendingLoad || isRealizedGainsLoading)"
          :small="true"
          :value="portfolioValue"
          title="Portfolio value"
          :formatter="formatCurrency"
        />

        <MetricWidget
          :is-loading="isPendingLoad || isRealizedGainsLoading"
          :small="true"
          :value="realizedGains"
          title="Realized gains"
          :formatter="formatCurrency"
        />
      </div>

      <WalletLabels
        class="mt-2"
        :wallet="walletModel"
        :is-active="shouldLoadDetails"
      />

      <div class="flex justify-between items-center mt-2">
        <div class="flex gap-2">
          <ToggleFavorite
            v-if="hover"
            :id="walletModel.id"
            model="Wallet"
          >
            <template
              #default="{
                isFavorite,
                toggleFavorite,
              }"
            >
              <UiButton
                size="xs"
                class="col-span-2"
                type="button"
                variant="white"
                @click="toggleFavorite"
              >
                <div>
                  <i
                    class="fa-heart"
                    :class="isFavorite ? 'fas text-red-500' : 'far'"
                  />
                </div>

                <span class="ml-2">{{
                  isFavorite ? "Unfollow" : "Follow"
                }}</span>
              </UiButton>
            </template>
          </ToggleFavorite>

          <AddToWatchlist
            v-if="walletModel.id"
            :type="WATCHLIST_TYPES.WALLET"
            :model-id="walletModel.id"
            button-size="xs"
            :inline-dropdown="true"
          />
        </div>

        <ExternalLinks
          v-if="walletModel"
          mini
          inline
          :right="true"
          :links="{
            opensea: `https://opensea.io/${walletModel.id}`,
            looksrare: `https://looksrare.org/accounts/${walletModel.id}`,
            x2y2: `https://looksrare.org/user/${walletModel.id}`,
            etherscan: `https://etherscan.io/address/${walletModel.id}`
          }"
        />
      </div>
    </template>

    <UiModal v-model="isWalletNameEditorVisible">
      <WalletDisplayNameModal
        :wallet="walletModel"
      />
    </UiModal>
  </OverlayComponent>
</template>

<script>
import {
  computed,
  defineComponent,
  toRefs,
  ref,
  inject,
} from 'vue';
import { useStore } from 'vuex';
import { useApolloClient, useQuery } from '@vue/apollo-composable';
import walletByAddressQuery from '@/graphql/wallet/queries/walletByAddress.query.gql';
import totalRealizedGainsQuery from '@/graphql/wallet/queries/totalRealizedGains.query.gql';
import totalPortfolioValueQuery from '@/graphql/wallet/queries/totalPortfolioValue.query.gql';
import routesNames from '@/router/routesNames';

import { formatCurrency } from '@/utils/filters';
import UiModal from '@/components/ui/UiModal.vue';
import ProfilePicture from '@/components/wallets/ProfilePicture.vue';
import { WATCHLIST_TYPES } from '@/constants';
import OverlayComponent from '../OverlayComponent.vue';
import MetricWidget from '../MetricWidget.vue';
import ToggleFavorite from '../ToggleFavorite.vue';
import UiButton from '../ui/UiButton.vue';
import WalletDisplayNameModal from './WalletDisplayNameModal.vue';
import UiLink from '../ui/UiLink.vue';
import WalletLabels from './WalletLabels.vue';
import ExternalLinks from '../ExternalLinks.vue';
import AddressDisplay from '../AddressDisplay.vue';
import AddToWatchlist from '../watchlists/AddToWatchlist.vue';

export default defineComponent({
  name: 'WalletProfile',

  components: {
    UiModal,
    ProfilePicture,
    OverlayComponent,
    MetricWidget,
    ToggleFavorite,
    UiButton,
    WalletDisplayNameModal,
    WalletLabels,
    ExternalLinks,
    AddressDisplay,
    AddToWatchlist,
  },

  props: {
    noOverlay: {
      type: Boolean,
      default: false,
    },
    nameClass: {
      type: String,
      default: 'text-sm font-medium dark:text-white',
    },
    wallet: null,
    address: null,
    picSize: null,
    onlyPic: {
      type: Boolean,
      default: false,
    },
    noLink: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    targetTab: {
      type: String,
      default: 'analytics',
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useStore();
    const requireLogin = inject('requireLogin');
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    const isWalletNameEditorVisible = ref(false);
    async function openWalletNameEditor() {
      if (!isLoggedIn.value) {
        await requireLogin();
      }
      isWalletNameEditorVisible.value = true;
    }

    const {
      address,
      wallet,
      isLoading,
    } = toRefs(props);
    const shouldLoadDetails = ref(false);

    const {
      result: walletResult,
    } = useQuery(
      walletByAddressQuery,
      () => ({
        address: address.value,
      }),
      () => ({
        enabled: !isLoading.value && !wallet.value && address.value,
      }),
    );

    const walletData = computed(() => walletResult.value?.wallet);
    const { resolveClient } = useApolloClient();
    const client = resolveClient();
    const walletModel = computed(() => {
      if (wallet?.value && wallet.value?.displayName) {
        return wallet.value;
      }

      if (wallet?.value) {
        const cached = client.cache.data.data[`Wallet:${wallet.value?.id}`];
        if (cached) {
          return cached;
        }

        return wallet?.value;
      }

      if (walletData?.value) {
        return walletData.value;
      }

      // here we should check apollo cache for wallet

      return { id: address?.value };
    });

    const {
      result: totalRealizedGainsResult,
      loading: isRealizedGainsLoading,
    } = useQuery(
      totalRealizedGainsQuery,
      () => ({
        address: walletModel.value?.id,
      }),
      () => ({
        enabled: !isLoading.value && shouldLoadDetails.value && walletModel.value,
      }),
    );

    const realizedGains = computed(() => totalRealizedGainsResult.value?.wallet?.analytics?.totalRealizedGains);

    const portfolioValue = computed(() => totalRealizedGainsResult.value?.wallet?.totalPortfolioValue);

    return {
      walletData,
      walletModel,
      realizedGains,
      isRealizedGainsLoading,
      portfolioValue,
      formatCurrency,
      isWalletNameEditorVisible,
      openWalletNameEditor,
      shouldLoadDetails,
      WATCHLIST_TYPES,
    };
  },

  data() {
    return {
      hover: false,
      showTimeout: null,
      isPendingLoad: false,
      didLoad: false,
    };
  },
  computed: {
    resolvedAddress() {
      return this.wallet?.id || this.address;
    },
    displayName() {
      return this.walletModel?.displayName || this.resolvedAddress?.slice(0, 6) || '';
    },
    profilePictureUrl() {
      return this.walletModel?.openseaProfile?.profilePictureUrl;
    },
    isVerified() {
      return this.walletModel?.openseaProfile?.isVerified;
    },
    itemComponentProps() {
      if (this.isLoading) {
        return {
          is: 'div',
        };
      }

      if (this.noLink === false && this.resolvedAddress) {
        return {
          is: UiLink,
          to: {
            name: routesNames.wallet.show,
            params: { address: this.resolvedAddress, currentTab: this.targetTab },
          },
        };
      }
      return {
        is: 'div',
      };
    },
  },
  methods: {
    show() {
      if (this.showTimeout !== null) {
        clearTimeout(this.showTimeout);
      }
      this.showTimeout = setTimeout(() => {
        this.isPendingLoad = false;
        this.shouldLoadDetails = true;
        this.didLoad = true;
      }, 500);
      this.hover = true;
      if (!this.didLoad) {
        this.isPendingLoad = true;
      }
    },
    hide() {
      if (this.showTimeout !== null) {
        clearTimeout(this.showTimeout);
      }
      this.showTimeout = setTimeout(() => {
        this.shouldLoadDetails = false;
      }, 500);
      this.hover = false;
    },
  },
});
</script>

<style scoped>
</style>
