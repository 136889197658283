<template>
  <div class="overflow-hidden h-2 bg-gray-100 dark:bg-gray-700 rounded-full">
    <div
      class="w-2/3 h-full bg-indigo-500"
      :style="{width}"
    />
  </div>
</template>

<script>
export default {
  name: 'UiProgressBar',
  props: {
    min: {
      default: 0,
      type: [Number, String],
    },
    max: {
      default: 1,
      type: [Number, String],
    },
    value: {
      default: 0,
      type: [Number, String],
    },
  },
  computed: {
    width() {
      return `${100 * (this.value / this.max)}%`;
    },
  },
};
</script>

<style scoped>

</style>
