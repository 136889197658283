<template>
  <div class="mx-auto max-w-screen-2xl">
    <UiCard
      is-overflow-auto
      style="contain:paint"
    >
      <UiCardBody>
        <Filters
          v-model="filters"
          :filters="availableFilters"
          group="wallet"
        />
      </UiCardBody>

      <UiTable
        v-model:sort-by="sortBy"
        v-model:sort-desc="sortDesc"
        :items="flips"
        :fields="['token', 'netProfit', 'purchasedFor', 'soldFor', 'purchasedAt', 'soldAt', 'heldFor', ]"
        :sortable="['netProfit', 'soldAt', 'purchasedAt', 'heldFor', 'purchasedFor', 'soldFor']"
        :is-loading="loading && !loadingMore"
        :show-skeleton="loadingMore"
        :skeletons-rows="20"
      >
        <template #head(purchasedFor)>
          Cost
        </template>

        <template #cell(purchasedAt)="{item}">
          <RouterLink
            :to="{
              name: getRouteName('transactions'),
              params: {...route.params},
              query: {
                filters: encodeFilters([
                  {key: 'collectionId', type: FILTER_TYPES.COLLECTION, value: {comparator: 'in', value: [item.collection.id]}},
                  {key: 'tokenId', type: FILTER_TYPES.NUMBER, value: {comparator: 'eq', value: parseInt(item.token.id.split('_').at(1))}},
                ])
              }
            }"
          >
            <Timestamp :timestamp="item.purchasedAt" />
          </RouterLink>
        </template>

        <template #cell(soldAt)="{item}">
          <RouterLink
            :to="{
              name: getRouteName('transactions'),
              params: {...route.params},
              query: {
                filters: encodeFilters([
                  {key: 'collectionId', type: FILTER_TYPES.COLLECTION, value: {comparator: 'in', value: [item.collection.id]}},
                  {key: 'tokenId', type: FILTER_TYPES.NUMBER, value: {comparator: 'eq', value: parseInt(item.token.id.split('_').at(1))}},
                ])
              }
            }"
          >
            <Timestamp :timestamp="item.soldAt" />
          </RouterLink>
        </template>

        <template #cell(netProfit)="{item}">
          <OverlayComponent :width="300">
            <CurrencyDisplay
              :prepend="item.netProfit > 0 ? '+' : ''"
              :value="item.netProfit"
            />

            <div
              class="text-sm font-medium"
              :class="{'text-red-500': item.netProfit < 0, 'text-green-500': item.netProfit > 0}"
            >
              {{ formatPercent(item.netProfit / (Number(item.purchaseGasPaid || 0) + Number(item.purchasedFor || 0) )) }}
            </div>

            <template #overlay-content>
              <UiTable
                class="-mx-2"
                slim
                no-sticky
                no-shadow
                :items="[
                  {feeType: 'Purchased for', value: item.purchasedFor},
                  {feeType: 'Purchase gas', value: item.purchaseGasPaid},
                  {feeType: 'Sale royaltes', value: item.saleRoyalties},
                  {feeType: 'Sale marketplace fee', value: item.marketplaceFee},
                  {feeType: 'Sale gas', value: item.saleGasPaid},
                ]"
              >
                <template #cell(value)="{item: {value}}">
                  <CurrencyDisplay :value="value" />
                </template>
              </UiTable>
            </template>
          </OverlayComponent>
        </template>

        <template #cell(soldFor)="{item}">
          <CurrencyDisplay :value="item.soldFor" />

          <div>
            <RouterLink
              :to="{
                name: getRouteName('transactions'),
                params: {...route.params},
                query: {
                  filters: encodeFilters([
                    {key: 'collectionId', type: FILTER_TYPES.COLLECTION, value: {comparator: 'in', value: [item.collection.id]}},
                    {key: 'tokenId', type: FILTER_TYPES.NUMBER, value: {comparator: 'eq', value: parseInt(item.token.id.split('_').at(1))}},
                  ])
                }
              }"
            >
              <PurchaseTypeBadge :type="item.saleType" />
            </RouterLink>
          </div>
        </template>

        <template #cell(purchasedFor)="{item}">
          <CurrencyDisplay :value="Number(item.purchaseGasPaid || 0) + Number(item.purchasedFor || 0)" />

          <div>
            <RouterLink
              :to="{
                name: getRouteName('transactions'),
                params: {...route.params},
                query: {
                  filters: encodeFilters([
                    {key: 'collectionId', type: FILTER_TYPES.COLLECTION, value: {comparator: 'in', value: [item.collection.id]}},
                    {key: 'tokenId', type: FILTER_TYPES.NUMBER, value: {comparator: 'eq', value: parseInt(item.token.id.split('_').at(1))}},
                  ])
                }
              }"
            >
              <PurchaseTypeBadge :type="item.purchaseType" />
            </RouterLink>
          </div>
        </template>

        <template #cell(heldFor)="{item}">
          {{ moment.duration(item.heldFor * 1000).humanize() }}
        </template>

        <template #cell(token)="{item}">
          <TokenListItem
            class="cursor-pointer"
            :token="item.token"
            :collection="item.collection"
            @click="openTokenModal(item)"
          >
            <template #context>
              <OverlayFilterContext
                @only="filters.push({key: 'collectionId', type: 'collection', value: {comparator: 'in', value: [item.collection.id]}})"
                @exclude="filters.push({key: 'collectionId', type: 'collection', value: {comparator: 'nin', value: [item.collection.id]}})"
              />
            </template>
          </TokenListItem>
        </template>

        <template #empty>
          <div class="flex justify-center items-center py-12 text-2xl font-light text-gray-500 bg-gray-850">
            No Flips
          </div>
        </template>
      </UiTable>
    </UiCard>
  </div>

  <IntersectionObserver
    v-if="hasMore && !loadingMore"
    class="relative -top-96 pointer-events-none"
    @isShow="fetchMore"
  />
</template>

<script setup>

import gql from 'graphql-tag';
import { computed, ref, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { TOKEN_FIELDS } from '@/graphql/token/fragments/tokenFields.fragment';
import moment from 'moment-timezone';
import { formatPercent } from '@/utils/filters';
import usePaginatedQuery from '@/composition/pagination/usePaginatedQuery';
import { usePremium } from '@/composition/premium';
import { encodeFilters, useFiltersQuerySync } from '@/composition/filters/useFiltersQuerySync';
import { FILTER_TYPES } from '@/constants/filters';
import routesNames from '@/router/routesNames';
import useTokenModal from '@/composition/tokens/useTokenModal';
import UiTable from '../ui/UiTable.vue';
import UiCard from '../ui/UiCard.vue';
import TokenListItem from '../TokenListItem.vue';
import CurrencyDisplay from '../CurrencyDisplay.vue';
import Timestamp from '../Timestamp.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import UiModal from '../ui/UiModal.vue';
import TokenModal from '../TokenModal.vue';
import PurchaseTypeBadge from '../PurchaseTypeBadge.vue';
import IntersectionObserver from '../IntersectionObserver.vue';
import OverlayComponent from '../OverlayComponent.vue';
import Filters from '../filters/Filters.vue';
import OverlayFilterContext from '../OverlayFilterContext.vue';

const FLIP_FIELDS = gql`
fragment Flip_Fields on Flip {
  id
    netProfit
    soldAt
    marketplace
    purchasedAt
    purchasedFor
    purchaseType
    collection {
      name
      id
      imageUrl
      totalSupply
    }
    marketplaceFee
    purchaseGasPaid
    saleGasPaid
    saleRoyalties
    saleType
    soldFor
    token {
      ...Token_Fields
    }
    wallet {
      openseaProfile
      id
      displayName
    }
    heldFor
  }
  ${TOKEN_FIELDS}
`;

const walletQuery = gql`
query GetWalletFlips (
  $filters: [FilterArg!]
  $orderBy: String
  $orderDesc: Boolean
  $skip: Int
  $take: Int
  $modelId: String!
) {
  model: wallet(where: {id: $modelId}) {
    id
    flips(filters: $filters, orderBy: $orderBy, orderDesc: $orderDesc, skip: $skip, take: $take) {
      ...Flip_Fields
    }
  }
}
${FLIP_FIELDS}
`;

const watchlistQuery = gql`
query GetWatchlistFlips (
  $filters: [FilterArg!]
  $orderBy: String
  $orderDesc: Boolean
  $skip: Int
  $take: Int
  $modelId: String!
) {
  model: walletWatchlistBySlug(slug: $modelId) {
    id
    flips(filters: $filters, orderBy: $orderBy, orderDesc: $orderDesc, skip: $skip, take: $take) {
      ...Flip_Fields
    }
  }
}
${FLIP_FIELDS}
`;
const filters = ref([]);
useFiltersQuerySync(filters);
const props = defineProps(['walletId', 'watchlistId']);
const { walletId, watchlistId } = toRefs(props);

const getRouteName = (childName) => {
  if (walletId.value) {
    return routesNames.wallet[childName];
  }

  if (watchlistId.value === 'portfolio') {
    return routesNames.portfolio[childName];
  }

  return routesNames.walletWatchlist[childName];
};

const availableFilters = [
  {
    name: 'Collection',
    key: 'collectionId',
    type: FILTER_TYPES.COLLECTION,
    autoPin: true,
  },
  {
    name: 'Net profit',
    key: 'netProfit',
    type: FILTER_TYPES.NUMBER,
  },
  {
    name: 'Purchase price',
    key: 'purchasedFor',
    type: FILTER_TYPES.NUMBER,
  },
  {
    name: 'Sale price',
    key: 'soldFor',
    type: FILTER_TYPES.NUMBER,
  },
  {
    name: 'Purchase date',
    key: 'purchasedAt',
    type: FILTER_TYPES.TIMESTAMP,
  },
  {
    name: 'Sale date',
    key: 'soldAt',
    type: FILTER_TYPES.TIMESTAMP,
  },
  {
    name: 'Held for',
    key: 'heldFor',
    type: FILTER_TYPES.NUMBER,
  },
  {
    name: 'Purchase type',
    key: 'purchaseType',
    type: FILTER_TYPES.CATEGORY,
    defaultValue: [],
    props: {
      multiple: true,
      options: [
        {
          name: 'Purchase',
          value: 'purchase',
        },
        {
          name: 'Bid',
          value: 'bid',
        },
        {
          name: 'Mint',
          value: 'mint',
        },
      ],
    },
  },
  {
    name: 'Sale type',
    key: 'saleType',
    type: FILTER_TYPES.CATEGORY,
    defaultValue: [],
    props: {
      multiple: true,
      options: [
        {
          name: 'Purchase',
          value: 'purchase',
        },
        {
          name: 'Bid',
          value: 'bid',
        },
      ],
    },
  },
];
const period = ref('1y');
const sortBy = ref('soldAt');
const sortDesc = ref(true);

const {
  result, loading, loadMore, loadingMore, hasMore,
} = usePaginatedQuery(
  {
    take: 20,
    hasMoreCheck: (data) => data.model.flips.length === 20,
    mergeResults: (oldData, newData) => {
      const data = JSON.parse(JSON.stringify(oldData));

      data.model.flips = [
        ...oldData.model.flips,
        ...newData.model.flips,
      ];

      return data;
    },
  },
  walletId.value ? walletQuery : watchlistQuery,
  () => ({
    modelId: walletId.value ? walletId.value : watchlistId.value,
    period: period.value,
    orderBy: sortBy.value,
    orderDesc: sortDesc.value,
    filters: filters.value,
  }),
);

const flips = computed(() => result.value?.model?.flips || []);

const { open } = useTokenModal();

const openTokenModal = ({ token }) => {
  open(token.id);
};

const { requirePremium } = usePremium();
const fetchMore = async () => {
  await requirePremium();

  return loadMore();
};
const route = useRoute();
</script>
