<template>
  <div
    class="inline-flex items-center text-sm leading-none rounded shadow-lg text-shadow-md"
    :class="[variableSchema(baseName) ? 'bg-blue-600 text-white' : 'bg-yellow-500 text-yellow-900']"
  >
    <div class="py-1 px-1.5 ">
      <span v-if="variableSchema(baseName)">
        {{ displayName(humanize(variableSchema(baseName)?.groupName || variableSchema(baseName)?.group)) }} » {{ displayName(humanize(variableSchema(baseName)?.actingAs)) || variableSchema(baseName)?.name }}
      </span>

      <span v-else>
        {{ baseName }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed, inject } from 'vue';
import { displayName, humanize } from '@/utils/filters';
import { Liquid } from 'liquidjs';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
});

const {
  modelValue,
} = toRefs(props);
const liquid = new Liquid();
const parsed = computed(() => liquid.parse(modelValue.value));

const baseName = computed(() => {
  const [{ value }] = parsed.value;

  return value.initial.postfix[0].getText();
});

const variableSchema = inject('variableSchema');

</script>
