<template>
  <div class="relative">
    <Chart
      :options="chartOptions"
      :is-loading="loading"
    />

    <div
      v-if="!loading && data.length === 0"
      class="flex absolute inset-0 justify-center items-center text-2xl font-light text-gray-500 bg-gray-850"
    >
      No Data
    </div>
  </div>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { toRefs, computed } from 'vue';
import { displayName, formatCurrency } from '@/utils/filters';
import {
  cloneDeep,
  groupBy, mapValues, orderBy, sum, sumBy,
} from 'lodash-es';
import { useRouter } from 'vue-router';
import routesNames from '@/router/routesNames';
import { encodeFilters } from '@/composition/filters/useFiltersQuerySync';

import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { WATCHLIST_TYPES } from '@/constants';
import Chart, { createTooltip } from '../Chart.vue';

const props = defineProps(['walletId', 'watchlistId', 'mode', 'filters']);

const router = useRouter();

const {
  walletId,
  watchlistId,
  mode,
  filters,
} = toRefs(props);

const walletQuery = gql`
  query GetWalletProfitByPurchaseType($modelId: String!, $filters: [FilterArg!]) {
    model: wallet(where: {id: $modelId}) {
      id
      profitSource(filters: $filters)
    }
  }
`;

const watchlistQuery = gql`
  query GetWatchlistProfitByPurchaseType($modelId: String!, $filters: [FilterArg!]) {
    model: walletWatchlistBySlug(slug: $modelId) {
      id
      profitSource(filters: $filters)
    }
  }
`;

const {
  result,
  loading,
  refetch,
} = useQuery(() => (walletId.value ? walletQuery : watchlistQuery), () => ({ modelId: walletId.value || watchlistId.value, filters: filters.value }));

const { onWatchlistMembersUpdated } = useWatchlists(WATCHLIST_TYPES.WALLET);

onWatchlistMembersUpdated(() => {
  if (watchlistId.value) {
    refetch();
  }
});

const data = computed(() => cloneDeep(result.value?.model?.profitSource || []).filter((i) => {
  if (mode.value === 'loss') {
    return i.profit < 0;
  }
  return i.profit > 0;
}).map((i) => ({
  ...i,
  profit: Math.abs(i.profit),
})));
const typeColor = {
  bid: 2,
  mint: 0,
  purchase: 1,
};
const chartOptions = computed(() => {
  const dataByType = groupBy(data.value, 'type');
  const sumsByType = mapValues(dataByType, (item) => sumBy(item, 'profit'));

  return {
    chart: {
      type: 'pie',
      height: '450px',
    },
    tooltip: createTooltip({
      flip: true,
      width: 'w-auto',
      formatters: {
        Type: formatCurrency,
        Purchase: formatCurrency,
        Mint: formatCurrency,
      },
    }),
    subtitle: {
      enabled: true,
      text: 'Click for breakdown',
      align: 'right',
    },
    series: [{
      name: 'Type',
      colorByPoint: true,
      innerSize: '60%',
      data: Object.keys(sumsByType).map((item) => ({
        colorIndex: typeColor[item],
        name: displayName(item),
        drilldown: item,
        y: sumsByType[item],
      })),
    }],
    drilldown: {
      series: Object.keys(dataByType).map((item) => {
        const topList = orderBy(dataByType[item], 'profit', 'desc').map((i, index) => ({
          x: i.collectionId, y: i.profit, name: i.collectionName, colorIndex: index + 2, collectionId: i.collectionId,
        }));
        const top10 = topList.slice(0, 8);
        const others = topList.slice(8);
        return {
          id: item,
          innerSize: '60%',
          name: displayName(item),
          events: {
            click: ({ point: { collectionId } }) => {
              if (!collectionId) {
                return;
              }
              router.push({
                name: routesNames.wallet.analytics,
                params: { address: walletId.value },
                query: { filters: encodeFilters([{ key: 'collectionId', type: 'collection', value: { comparator: 'in', value: [collectionId] } }]) },
              });
            },
          },
          data: [
            ...top10,
            ...(others.length > 0 ? [{
              x: 'other', y: sum(others.map((i) => i.y)), name: 'Other', colorIndex: 10,
            }] : []),
          ],
        };
      }),
    },
  };
});
</script>
