<template>
  <div class="mx-auto max-w-screen-2xl main-container">
    <UiCard
      is-overflow-auto
      style="contain: paint"
    >
      <UiCardBody>
        <Filters
          v-model="filters"
          :filters="availableFilters"
          group="collection"
        />
      </UiCardBody>

      <UiCardBody v-if="!isLoading && activity.length === 0">
        <div class="flex flex-col items-center py-4 sm:py-6">
          <div class="mb-5 text-2xl font-light text-gray-400">
            No activity {{ filters.length > 0 ? 'matching your filters' : '' }}
          </div>
        </div>
      </UiCardBody>

      <ActivityTable
        :is-loading="!isRefetching && isLoading"
        :activity="activity"
        :loading-more="loadingMore"
        :loading-items="loadingItems"
        @addFilter="addFilter"
      />
    </UiCard>

    <IntersectionObserver
      v-if="hasMore && !loadingMore"
      class="relative -top-96 pointer-events-none"
      @isShow="fetchMore"
    />
  </div>
</template>

<script setup>
import ActivityTable from '@/components/ActivityTable.vue';
import Filters from '@/components/filters/Filters.vue';
import IntersectionObserver from '@/components/IntersectionObserver.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiCardBody from '@/components/ui/UiCardBody.vue';
import { useFiltersQuerySync } from '@/composition/filters/useFiltersQuerySync';
import usePaginatedQuery from '@/composition/pagination/usePaginatedQuery';
import { useRefetchQueries } from '@/composition/refetch-queries';
import { FILTERS } from '@/constants/filters';
import { ACTIVITY_ITEM_FIELDS_FRAGMENT } from '@/graphql/activity/fragments/activityItemFields.fragment';
import gql from 'graphql-tag';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

const PER_PAGE = 20;

const route = useRoute();
const collectionId = computed(() => route.params.contractAddress);

const query = gql`
query GetCollectionActivity($collectionId: String!, $skip: Int, $take: Int, $filters: [FilterArg!]) {
  collection(where: {id: $collectionId}) {
    id
    activity(take: $take, skip: $skip, filters: $filters) {
      ...ActivityItemFields
    }  
  }
}
${ACTIVITY_ITEM_FIELDS_FRAGMENT}
`;

const availableFilters = FILTERS.ACTIVITY.filter((item) => item.key !== 'collectionId');

const filters = ref([]);
useFiltersQuerySync(filters);
const {
  result, loading: isLoading, hasMore, loadingMore, loadMore,
  refetch,
} = usePaginatedQuery({
  take: PER_PAGE,
  hasMoreCheck: (data) => data.collection?.activity?.length === PER_PAGE,
  mergeResults: (oldData, newData) => {
    if (!oldData?.collection?.activity?.length) {
      return newData;
    }
    const data = JSON.parse(JSON.stringify(oldData));
    data.collection.activity = [
      ...(data.collection?.activity || []),
      ...newData.collection.activity,
    ];

    return data;
  },
}, query, () => ({ collectionId: collectionId.value, filters: filters.value }));
const {
  isRefetching,
} = useRefetchQueries({
  collectionActivity: refetch,
});
const activity = computed(() => result.value?.collection?.activity || []);

const fetchMore = () => loadMore();
const loadingItems = computed(() => ((isLoading.value || loadingMore.value) ? PER_PAGE : 0));

const addFilter = (filter) => filters.value.push(filter);
</script>
