<template>
  <ActionInputMapper
    v-model="value"
    :inputs="action.inputs"
    class="-my-6 -mx-6"
    :trigger="trigger"
  >
    <template #input(script)="{model, updateModel, defaultValue}">
      <UiCodeEditor
        :model-value="model || defaultValue"
        @update:modelValue="updateModel"
      />
    </template>

    <template #input(snippetIds)="{model, updateModel, defaultValue}">
      <UiCardBody>
        <ScriptSnippetPicker
          :model-value="model || defaultValue"
          @update:modelValue="updateModel"
        />
      </UiCardBody>
    </template>
  </ActionInputMapper>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import UiCodeEditor from '@/components/ui/UiCodeEditor.vue';
import UiCardBody from '@/components/ui/UiCardBody.vue';
import ActionInputMapper from '../ActionInputMapper.vue';
import ScriptSnippetPicker from '../scripting/ScriptSnippetPicker.vue';

const props = defineProps(['modelValue', 'outputs', 'action', 'trigger']);
const emit = defineEmits(['update:modelValue']);

const {
  modelValue, action, trigger,
} = toRefs(props);

const value = computed({
  get() {
    return modelValue.value;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});
</script>
