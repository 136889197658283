<template>
  <ActionForm v-model="model">
    <template #buttons>
      <UiButton
        size="lg"
        :is-loading="isLoading"
        @click="create"
      >
        Create
      </UiButton>
    </template>
  </ActionForm>
</template>

<script setup>
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { reactive, ref } from 'vue';
import UiButton from '@/components/ui/UiButton.vue';
import ActionForm from '@/components/automation/ActionForm.vue';
import { useRouter } from 'vue-router';
import routesNames from '@/router/routesNames';
import useAutomationActions from '@/composition/automation/use-automation-actions';

const model = reactive({
  name: '',
  icon: '',
  script: `export default function() {
  // Your code here
  return {}
}
`,
  inputs: [],
  outputs: [],
  snippetIds: [],
  sdkVersion: 'v2',
});

const {
  mutate,
} = useMutation(gql`
mutation CreateAutomationAction($action: CreateAutomationActionInput!) {
  createAutomationAction(action: $action) {
    id
    name
    icon
    snippetIds
    inputs {
      name
      type
      required
      defaultValue
      items
      properties
      description
    }
    outputs {
      name
      type
      items
      properties
      description
    }
  }
}
`);
const isLoading = ref(false);

const router = useRouter();

const { refetchActions } = useAutomationActions();

const create = () => {
  isLoading.value = true;
  return mutate({
    action: {
      sdkVersion: model.sdkVersion,
      icon: model.icon,
      name: model.name,
      script: model.script,
      inputs: model.inputs,
      snippetIds: model.snippetIds,
      outputs: model.outputs.map((output) => ({
        name: output.name,
        type: output.type,
        items: output.items,
        properties: output.properties,
        actingAs: output.actingAs,
        description: output.description,
      })),
    },
  }).then(({ data }) => {
    const { id } = data.createAutomationAction;

    router.push({
      name: routesNames.automationActionUpdate,
      params: {
        id,
      },
    });
    refetchActions();
  }).finally(() => {
    isLoading.value = false;
  });
};

</script>
