<template>
  <div
    v-if="(result && relatedWallets.length > 0) || isLoading"
    class="mb-3 text-2xl font-light text-gray-100"
  >
    Related Wallets
  </div>

  <PremiumPlaceholder action="view related wallets">
    <div
      v-if="result && relatedWallets.length === 0"
      class="py-12 mb-3 text-2xl font-light text-center text-gray-400"
    >
      No Related Wallets
    </div>

    <div
      v-if="!isLoading && relatedWallets.length > 0"
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6"
    >
      <UiCard
        v-for="item in relatedWallets"
        :key="item.wallet.id"
        class="py-4 px-6 text-center"
      >
        <WalletProfile
          :wallet="item.wallet"
          vertical
          :pic-size="12"
        >
          <template #context>
            <OverlayContext>
              <UiButton
                variant="white"
                size="xs"
                :is-loading="isAddingWallet"
                @click="addWallet(item.wallet)"
              >
                Group
              </UiButton>
            </OverlayContext>
          </template>
        </WalletProfile>

        <WalletLabels
          :wallet="item.wallet"
          :labels="item.wallet.labels"
          class="justify-center items-center mt-2"
        />

        <div class="flex justify-center items-center mt-2">
          <RouterLink
            :to="{
              name: getRouteName('transactions'),
              params: {...route.params},
              query: {
                filters: encodeFilters([{ key: 'toAddress', value: { comparator: 'in', value: [item.wallet.id] }, type: 'wallet' }])
              }
            }"
          >
            <UiBadge variant="indigo">
              {{ item.transferCount }} transfers
            </UiBadge>
          </RouterLink>
        </div>
      </UiCard>
    </div>

    <div
      v-if="isLoading"
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6"
    >
      <UiCard
        v-for="n in 6"
        :key="n"
        class="h-32 animate-pulse"
      />
    </div>
  </PremiumPlaceholder>
</template>

<script setup>
import { WALLET_FIELDS } from '@/graphql/wallet/fragments/walletFields.fragment';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, toRefs, ref } from 'vue';
import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { WATCHLIST_TYPES } from '@/constants';
import { useRoute, useRouter } from 'vue-router';
import routesNames from '@/router/routesNames';
import { encodeFilters } from '@/composition/filters/useFiltersQuerySync';
import UiCard from '../ui/UiCard.vue';
import WalletProfile from './WalletProfile.vue';
import WalletLabels from './WalletLabels.vue';
import UiBadge from '../ui/UiBadge.vue';
import OverlayContext from '../OverlayContext.vue';
import UiButton from '../ui/UiButton.vue';
import PremiumPlaceholder from '../PremiumPlaceholder.vue';

const props = defineProps(['walletId', 'watchlistId']);
const { walletId, watchlistId } = toRefs(props);

const getRouteName = (childName) => {
  if (walletId.value) {
    return routesNames.wallet[childName];
  }

  if (watchlistId.value === 'portfolio') {
    return routesNames.portfolio[childName];
  }

  return routesNames.walletWatchlist[childName];
};

const GET_WALLET_RELATED_WALLETS = gql`
  query GetRelatedWallets($walletId: String!) {
    model: wallet(where: { id: $walletId }) {
      id
      relatedWallets: relatedWalletsV2(take: 6) {
        wallet {
          ...Wallet_Fields
          labels {
            displayName
            name
            description
          }
        }
        transferCount
      }
    }
  }
  ${WALLET_FIELDS}
`;

const GET_WATCHLIST_RELATED_WALLETS = gql`
  query GetWalletGroupRelatedWallets($id: String!) {
    model: walletWatchlistBySlug(slug: $id) {
      id
      relatedWallets(take: 6) {
        wallet {
          ...Wallet_Fields
          labels {
            displayName
            name
            description
          }
        }
        transferCount
      }
    }
  }
  ${WALLET_FIELDS}
`;

const {
  result,
  loading: isLoading,
  refetch,
} = useQuery(walletId.value ? GET_WALLET_RELATED_WALLETS : GET_WATCHLIST_RELATED_WALLETS, () => (walletId.value ? { walletId: walletId.value } : { id: watchlistId.value }));

const { onWatchlistMembersUpdated, getDraftWalletWatchlist, add } = useWatchlists(WATCHLIST_TYPES.WALLET);

onWatchlistMembersUpdated(() => {
  if (watchlistId.value) {
    refetch();
  }
});

const relatedWallets = computed(() => result.value?.model?.relatedWallets || []);
const model = computed(() => result.value?.model);
const router = useRouter();
const route = useRoute();
const isAddingWallet = ref(false);
const addWallet = ({ id }) => {
  isAddingWallet.value = true;
  // if this is a watchlist, add the wallet to the watchlist
  // else create a new draft watchlist with the wallet
  const promise = watchlistId.value
    ? add({ id: model.value.id, modelIds: [id] })
    : getDraftWalletWatchlist([walletId.value, id]);

  promise.then(({ data: { watchlist } }) => {
    router.push({ name: routesNames.walletWatchlist.show, params: { slug: watchlist.slug } });
  }).finally(() => {
    isAddingWallet.value = false;
  });
};
</script>
