<template>
  <UiCard>
    <template #title>
      <div class="flex gap-x-2 items-center">
        <div>Compass Bluechip Index</div>

        <div class="text-gray-400">
          <i
            v-tooltip="'Tracks the price of 5 top collections: BAYC, MAYC, Otherdeeds, Moonbirds and Clone X'"
            class="far fa-info-circle"
          />
        </div>
      </div>
    </template>

    <template #title-right>
      <PeriodSelector
        v-model="period"
        :periods="['7d', '30d', '90d', '180d', '1y', '2y']"
        :max-enabled="5 * 365 * 24 * 60 * 60"
      />
    </template>

    <UiCardBody>
      <Chart
        :options="chartOptions"
        :is-loading="loading"
        watermark
      />
    </UiCardBody>
  </UiCard>
</template>

<script setup>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { ref, computed } from 'vue';
import moment from 'moment';
import { formatCurrency } from '@/utils/filters';
import UiCard from '../ui/UiCard.vue';
import PeriodSelector from '../PeriodSelector.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import Chart, { createTooltip } from '../Chart.vue';

const period = ref('1y');
const isStacked = ref(true);

const query = gql`
query GetTopCollectionsIndexOverTime($period: String!){
  topCollectionsIndex(period: $period)
}
`;

const {
  result,
  loading,
} = useQuery(query, () => ({ period: period.value }));

const chartOptions = computed(() => ({
  xAxis: {
    type: 'datetime',
  },
  tooltip: createTooltip({
    formatters: {
      ETH: (value) => formatCurrency(value, 'ETH', 0),
      USD: (value) => formatCurrency(value, 'USD', 0),
    },
  }),
  legend: {
    enabled: true,
  },
  plotOptions: {
    column: {
      stacking: isStacked.value ? 'normal' : false,
      dataLabels: {
        enabled: false,
      },
    },
  },
  yAxis: [
    {
      title: 'USD',
    },
    {
      title: 'ETH',
      opposite: true,
    },
  ],
  series: [
    {
      data: result.value?.topCollectionsIndex?.map((item) => [
        parseInt(moment(item.timestamp).format('x')),
        item.ethValue,
      ]) || [],
      type: 'spline',
      name: 'ETH',
    },
    {
      data: result.value?.topCollectionsIndex?.map((item) => [
        parseInt(moment(item.timestamp).format('x')),
        item.usdValue,
      ]) || [],
      type: 'spline',
      name: 'USD',
      yAxis: 1,
    },
  ],
}));

</script>
