<template>
  <ul>
    <li
      v-for="input in inputs"
      :key="input.type"
    >
      <slot
        :name="`input(${input.name})`"
        :model="mapping[input.name]"
        :default-value="input.defaultValue"
        :update-model="value => { mapping[input.name] = value; }"
        :add-variable="(append = false, callback = null, type = input) => onSelectVariable(type, append, callback)"
        :error="getError(input.name)"
        :validation-issues="validationIssues"
        :is-variable="isVariable(mapping[input.name])"
        :acting-as="input.actingAs"
      >
        <UiInputGroup
          :label="displayName(humanize(input.actingAs || input.name))"
          :error-message="getError(input.name)?.message"
        >
          <div v-if="!isVariable(mapping[input.name])">
            <Component
              :is="defaultComponentByType(input.type)"
              v-if="!input.actingAs"
              :model-value="mapping[input.name]"
              :placeholder="input.defaultValue"
              :disabled="readonly !== false"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <ResourcePicker
              v-if="input.actingAs === 'collection'"
              :model-value="mapping[input.name]"
              only="collection"
              :disabled="readonly !== false"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <UiInput
              v-if="input.actingAs === 'token'"
              placeholder="0x0000000000000000000000000000000000000000_321"
              :model-value="mapping[input.name]"
              :disabled="readonly !== false"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <ResourcePicker
              v-if="input.actingAs === 'wallet'"
              :model-value="mapping[input.name]"
              only="wallet"
              :disabled="readonly !== false"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <UiIntervalPicker
              v-if="input.actingAs === 'duration'"
              :model-value="mapping[input.name]"
              :disabled="readonly !== false"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <UiInputRadio
              v-if="input.actingAs === 'marketplace'"
              :model-value="mapping[input.name]"
              :disabled="readonly !== false"
              :options="[
                { text: 'Opensea', value: 3 },
                { text: 'Looksrare', value: 1 },
                { text: 'X2Y2', value: 4 },
                { text: 'Blur', value: 7 }
              ]"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <PrivateKeyPicker
              v-if="input.actingAs === 'tradingWallet'"
              :model-value="mapping[input.name]"
              :disabled="readonly !== false"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <ChannelPickerV2
              v-if="input.actingAs === 'notificationChannels'"
              :model-value="mapping[input.name]"
              :disabled="readonly !== false"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <PeriodSelector
              v-if="input.actingAs === 'period'"
              :periods="['1m', '5m', '15m', '30m', '1h', '4h', '1d', '7d']"
              class="my-2"
              no-label
              :model-value="mapping[input.name]"
              :disabled="readonly !== false"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <TokenAttributePicker
              v-if="input.actingAs === 'tokenAttribute'"
              :collection-id="mapping.collection"
              :model-value="mapping[input.name]"
              :disabled="readonly !== false"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <CollectionWatchlistPicker
              v-if="input.actingAs === 'collectionWatchlist'"
              :model-value="mapping[input.name]"
              :disabled="readonly !== false"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <Filters
              v-if="input.actingAs === 'collectionTokenFilters'"
              :model-value="mapping[input.name] || []"
              :filters="mapFilters(collectionTokenFilters)"
              :disabled="readonly !== false"
              :filter-props="{
                actionId
              }"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <Filters
              v-if="input.actingAs === 'walletTokenFilters'"
              :model-value="mapping[input.name] || []"
              :filters="mapFilters(FILTERS.WALLET_TOKENS)"
              :disabled="readonly !== false"
              :filter-props="{
                actionId
              }"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />

            <Filters
              v-if="input.actingAs === 'walletCollectionFilters'"
              :model-value="mapping[input.name] || []"
              :filters="mapFilters(FILTERS.WALLET_COLLECTIONS)"
              :disabled="readonly !== false"
              :filter-props="{
                actionId
              }"
              @update:modelValue="value => { mapping[input.name] = value; }"
            />
          </div>

          <VariableDisplay
            v-if="isVariable(mapping[input.name])"
            :key="mapping[input.name]"
            :model-value="mapping[input.name]"
            :disabled="readonly !== false"
            @update:modelValue="value => { mapping[input.name] = value; }"
          />

          <template
            v-if="readonly === false"
            #label="{label}"
          >
            <div>{{ label }}</div>
          </template>

          <template #after-label>
            <div
              v-if="readonly === false"
            >
              <div class="flex gap-1 items-center">
                <button
                  v-if="noVariables === false"
                  class="relative active:top-px px-1 text-xs font-medium text-gray-300 bg-gray-700 hover:bg-gray-600 rounded-md transition-colors"
                  @click="onSelectVariable(input)"
                >
                  <i class="fas fa-plus" />
                  Use variable
                </button>
              </div>
            </div>
          </template>

          <div class="mt-1 text-xs text-gray-500">
            {{ input.description }}
          </div>
        </UiInputGroup>
      </slot>
    </li>
  </ul>
</template>

<script setup>
import { displayName, humanize } from '@/utils/filters';
import {
  toRefs, computed, inject, watchEffect,
} from 'vue';

import AttributeFilter from '@/components/filters/AttributeFilter.vue';
import AttributeFilterDisplay from '@/components/filters/display/AttributeFilterDisplay.vue';
import Filters from '@/components/filters/Filters.vue';
import { FILTERS, FILTER_TYPES } from '@/constants/filters';
import ChannelPickerV2 from '../alerts/ChannelPickerV2.vue';
import CollectionWatchlistPicker from '../CollectionWatchlistPicker.vue';
import PeriodSelector from '../PeriodSelector.vue';
import ResourcePicker from '../ResourcePicker.vue';
import TokenAttributePicker from '../TokenAttributePicker.vue';
import UiInput from '../ui/UiInput.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';
import UiInputRadio from '../ui/UiInputRadio.vue';
import UiIntervalPicker from '../ui/UiIntervalPicker.vue';
import UiToggle from '../ui/UiToggle.vue';
import PrivateKeyPicker from './PrivateKeyPicker.vue';
import VariableDisplay from './VariableDisplay.vue';
import NumericTraitFilterDisplay from '../filters/display/NumericTraitFilterDisplay.vue';
import NumericTraitFilter from '../filters/NumericTraitFilter.vue';

const mapFilters = (values) => {
  if (!values) {
    return [];
  }

  return values.map((item) => ({
    ...item,
    props: {
      ...item.props,
      allowVariables: true,
    },
  }));
};

const props = defineProps({
  actionId: null,
  inputs: null,
  modelValue: null,
  validationIssues: null,
  noVariables: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const {
  actionId, inputs, modelValue, validationIssues, noVariables, readonly,
} = toRefs(props);

const getError = computed(() => (key) => validationIssues.value?.find((i) => i.path.at(-1) === key));

const selectVariable = inject('selectVariable');
const emit = defineEmits(['update:modelValue']);
const mapping = computed({
  get() {
    return modelValue.value || {};
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

watchEffect(() => {
  inputs.value.forEach((item) => {
    if (mapping.value[item.name] === null && item.defaultValue) {
      mapping.value[item.name] = item.defaultValue;
    }
  });
});

const onSelectVariable = (targetSchema, append = false, callback = null) => selectVariable(targetSchema, actionId.value).then(async (value) => {
  if (!value) {
    return;
  }

  if (callback && typeof callback === 'function') {
    callback(value.id);
  } else if (append) {
    mapping.value[targetSchema.name] = `${mapping.value?.[targetSchema.name] || ''}{{ ${value.id} }}`;
  } else {
    mapping.value[targetSchema.name] = `{{ ${value.id} }}`;
  }
});

const isVariable = (value) => value?.startsWith?.('{{') && value?.endsWith?.('}}');

const defaultComponentByType = computed(() => (type) => {
  switch (type) {
    case 'Boolean':
      return UiToggle;
    default:
      return UiInput;
  }
});

const collectionTokenFilters = computed(() => [
  ...FILTERS.COLLECTION_TOKENS.map((item) => ({
    ...item,
    props: {
      ...item.props,
      allowVariables: true,
    },
  })),
  {
    key: 'attributeValueId',
    type: FILTER_TYPES.CATEGORY,
    name: 'Trait',
    component: AttributeFilter,
    displayComponent: AttributeFilterDisplay,
    props: {
      collectionId: mapping.value.collection,
      multiple: true,
      allowVariables: true,
    },
  },
  {
    key: 'numericTrait',
    type: FILTER_TYPES.NUMERIC_TRAIT,
    name: 'Numeric Trait',
    component: NumericTraitFilter,
    displayComponent: NumericTraitFilterDisplay,
    props: {
      collectionId: mapping.value.collection,
    },
  },
]);
</script>
