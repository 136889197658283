<template>
  <div>
    <div v-if="isLoggedIn">
      <slot
        name="authenticated"
        v-bind="{wallet, displayName}"
      >
        <WalletProfile
          no-overlay
          :wallet="wallet"
        />
      </slot>
    </div>

    <button
      v-else
      v-tooltip="'Connect with your wallet'"
      class="w-10 h-10 bg-indigo-500 rounded-full opacity-90 hover:opacity-100"
      @click="eventBus.$emit('auth:signIn')"
    >
      <i class="fas fa-wallet" />
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import eventBus from '@/utils/event-bus';
import WalletProfile from '@/components/wallets/WalletProfile.vue';

export default {
  name: 'UserProfile',

  components: {
    WalletProfile,
  },

  data() {
    return {
      eventBus,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
    wallet() {
      return this.user.wallets[0];
    },
    displayName() {
      return this.wallet?.openseaProfile?.username || this.wallet?.name || this.wallet?.id.slice(0, 6);
    },
  },
};
</script>

<style scoped>

</style>
