<template>
  <UiDropdown top>
    <template #toggle="{ toggle }">
      <UiLink @click="toggle">
        <div
          v-if="gasPriceInGwei"
          class="flex items-center"
        >
          <div
            class="flex content-center mr-2 align-middle"
            :class="{
              'text-green-600': gasPriceInGwei < 60,
              'text-yellow-600': gasPriceInGwei < 150,
              'text-red-600': gasPriceInGwei >= 150,
            }"
          >
            <i class="fad fa-gas-pump" />
          </div>

          <div class="font-medium tabular-nums text-white">
            {{ formatNumber(gasPriceInGwei, 0, false) }}
          </div>
        </div>
      </UiLink>
    </template>

    <template #body>
      <UiDropdownForm class="dark:text-gray-100">
        <div class="divide-y dark:divide-gray-600">
          <div class="flex justify-between items-center py-2 space-x-3 text-sm">
            <div>🚀 Rapid</div>

            <div v-tooltip="{content: `Fee<sup>Max Priority Fee</sup>`, allowHTML: true}">
              {{ formatNumber(priceInGwei('rapid'), 0, false) }}

              <sup>
                {{ formatNumber(rapidPriorityFee, 0, false) }}
              </sup>

              Gwei
            </div>
          </div>

          <div class="flex justify-between items-center py-2 space-x-3 text-sm">
            <div>🚙 Average</div>

            <div v-tooltip="{content: `Fee<sup>Max Priority Fee</sup>`, allowHTML: true}">
              {{ formatNumber(priceInGwei('fast'), 0, false) }}

              <sup>
                {{ formatNumber(fastPriorityFee, 0, false) }}
              </sup>

              Gwei
            </div>
          </div>

          <div class="flex justify-between items-center py-2 space-x-3 text-sm">
            <div>🐢 Slow</div>

            <div v-tooltip="{content: `Fee<sup>Max Priority Fee</sup>`, allowHTML: true}">
              {{ formatNumber(priceInGwei('standard'), 0, false) }}

              <sup>
                {{ formatNumber(standardPriorityFee, 0, false) }}
              </sup>
              Gwei
            </div>
          </div>

          <div class="flex justify-between items-center py-2 space-x-3 text-sm">
            <div>Opensea Trade</div>

            <div>${{ formatNumber(tradeCost, 1, false) }}</div>
          </div>
        </div>
      </UiDropdownForm>
    </template>
  </UiDropdown>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { formatNumber } from '@/utils/filters';
import UiLink from '@/components/ui/UiLink.vue';
import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownForm from '@/components/ui/UiDropdownForm.vue';

export default defineComponent({
  name: 'GasTracker',

  components: { UiLink, UiDropdown, UiDropdownForm },

  setup() {
    return {
      formatNumber,
    };
  },

  computed: {
    ...mapGetters('gas', {
      tradeCost: 'tradeCost',
      gasPriceInGwei: 'gasPriceInGwei',
      priceData: 'ethPrice',
      eip1559PriceInGwei: 'eip1559GasPriceInGwei',
      priceInGwei: 'presetGasPriceInGwei',
    }),
    rapidPriorityFee() {
      return this.eip1559PriceInGwei('rapid')?.priorityFee || 0;
    },
    fastPriorityFee() {
      return this.eip1559PriceInGwei('fast')?.priorityFee || 0;
    },
    standardPriorityFee() {
      return this.eip1559PriceInGwei('standard')?.priorityFee || 0;
    },
  },
});
</script>

<style scoped>
</style>
