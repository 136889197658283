<template>
  <div>
    <UiInputRadio
      v-model="type"
      :options="radioOptions"
      class="mb-3"
    />

    <ResourcePicker
      v-if="!isVariable"
      v-model="localValue"
      only="collection"
      multiple
    />

    <div v-else>
      <VariableDisplay

        v-model="localValue"
        :reset-value="() => []"
      />
    </div>

    <UiButton
      v-if="allowVariables === true"
      size="xs"
      variant="white"
      @click="selectValueVariable"
    >
      <i class="far fa-brackets-curly" />
    </UiButton>
  </div>
</template>

<script setup>
import {
  toRefs, ref, computed, watch, inject,
} from 'vue';
import VariableDisplay from '../automation/VariableDisplay.vue';
import ResourcePicker from '../ResourcePicker.vue';
import UiButton from '../ui/UiButton.vue';
import UiInputRadio from '../ui/UiInputRadio.vue';

const props = defineProps(['modelValue', 'allowVariables', 'actionId']);
const {
  modelValue, allowVariables, actionId,
} = toRefs(props);
const emit = defineEmits(['update:modelValue']);

const validate = (value) => value?.length > 0;

const type = ref(modelValue.value?.comparator || 'eq');
const localValue = ref(modelValue.value?.value || null);

watch(modelValue, (v) => {
  type.value = v.comparator;
  localValue.value = v.value;
}, { deep: true });

const radioOptions = [
  {
    text: 'In',
    value: 'in',
  },
  {
    text: 'Not In',
    value: 'nin',
  },
];

watch([type, localValue], ([t, v]) => {
  emit('update:modelValue', { comparator: t, value: validate(v) ? v : null });
});
const selectVariable = inject('selectVariable');
const isVariable = computed(() => localValue.value?.startsWith?.('{{') && localValue.value?.endsWith?.('}}'));
const selectValueVariable = () => {
  selectVariable({
    type: 'String',
    actingAs: 'collection',
  }, actionId.value).then((variable) => {
    localValue.value = `{{${variable.id}}}`;
  });
};
</script>
