<template>
  <UiCard
    class="overflow-x-auto w-full max-w-full"
  >
    <UiCardBody
      slim
      class="-mt-4"
    >
      <Filters
        v-model="filters"
        :filters="availableFilters"
        group="token"
      />
    </UiCardBody>

    <div class="overflow-x-auto max-w-full">
      <ActivityTable
        :activity="activity"
        :is-loading="isLoading"
        :loading-more="loadingMore"
        :table-props="{noSticky: true, noShadow: true}"
        hide-token
      />
    </div>

    <IntersectionObserver
      v-if="hasMore && !loadingMore"
      @isShow="loadMore"
    />
  </UiCard>
</template>

<script setup>
import {
  computed, toRefs, ref,
} from 'vue';
import gql from 'graphql-tag';
import usePaginatedQuery from '@/composition/pagination/usePaginatedQuery';
import { ACTIVITY_ITEM_FIELDS_FRAGMENT } from '@/graphql/activity/fragments/activityItemFields.fragment';
import { FILTERS } from '@/constants/filters';
import UiCard from '../ui/UiCard.vue';
import ActivityTable from '../ActivityTable.vue';
import UiCardBody from '../ui/UiCardBody.vue';
import Filters from '../filters/Filters.vue';
import IntersectionObserver from '../IntersectionObserver.vue';

const query = gql`
  query GetTokenActivity($tokenId: String!, $take: Int, $skip: Int, $filters: [FilterArg!]) {
    token(where: {id: $tokenId}) {
      id
      activity(take: $take, skip: $skip, filters: $filters) {
        ...ActivityItemFields
      }
    }
  }
  ${ACTIVITY_ITEM_FIELDS_FRAGMENT}
`;
const PER_PAGE = 10;

const props = defineProps(['tokenId']);

const { tokenId } = toRefs(props);

const filters = ref([]);
const availableFilters = FILTERS.ACTIVITY;

const {
  result, loading: isLoading, loadingMore, hasMore, loadMore,
} = usePaginatedQuery({
  take: PER_PAGE,
  hasMoreCheck: (data) => data.token.activity.length === PER_PAGE,
  mergeResults: (oldData, newData) => {
    const data = JSON.parse(JSON.stringify(oldData));

    data.token.activity = [
      ...oldData.token.activity,
      ...newData.token.activity,
    ];

    return data;
  },
}, query, () => ({ tokenId: tokenId.value, filters: filters.value }));

const activity = computed(() => result.value?.token?.activity || []);
</script>

<style>
.tx-table-min-w {
  min-width: 720px;;
}
</style>
