<template>
  <div
    :key="formattedValue"
    v-tooltip="showTooltip ? usdValue : undefined"
    class="inline-flex items-center tabular-nums whitespace-nowrap"
  >
    {{ formattedValue }}
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
} from 'vue';
import { useStore } from 'vuex';
import { formatNumber, formatCurrency } from '@/utils/filters';

export default defineComponent({
  name: 'CurrencyDisplay',
  props: {
    value: null,
    currency: {
      default: 'ETH',
    },
    precision: {
      default: null,
    },
    showTooltip: {
      default: true,
    },
    prepend: null,
  },
  setup(props) {
    const {
      value,
      currency,
      prepend,
    } = toRefs(props);
    const store = useStore();
    const priceData = computed(() => store.getters['gas/ethPrice']);

    const usdValue = computed(() => `$${formatNumber(value.value * priceData.value, 2, false)}`);

    const formattedValue = computed(() => `${formatCurrency(value.value, currency.value, undefined, true, prepend.value ? prepend.value : '')}`);

    return {
      usdValue,
      formattedValue,
    };
  },
  data() {
    return {
      previousValue: null,
    };
  },

});
</script>

<style scoped>

</style>
