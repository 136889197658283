<template>
  <div v-tooltip="inline === false ? tooltipContent : undefined">
    <div v-if="marketplace === 0 || marketplace === 2 || marketplace === 3">
      <i class="text-brand-opensea fak fa-brand-opensea" />
    </div>

    <div v-if="marketplace === 4">
      <i class="text-brand-x2y2 fak fa-brand-x2y2" />
    </div>

    <div v-if="marketplace === 1">
      <i class="text-brand-looksrare fak fa-brand-looksrare" />
    </div>

    <div v-if="marketplace === 5">
      <i class="fak fa-brand-cryptopunks" />
    </div>

    <div v-if="marketplace === 6">
      <i class="text-brand-sudoswap fak fa-brand-sudoswap" />
    </div>

    <div v-if="marketplace === 7">
      <i class="text-brand-blur fak fa-brand-blur" />
    </div>

    <div
      v-if="inline !== false"
      class="inline"
    >
      {{ tooltipContent }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 4,
    },
    marketplace: {
      type: Number,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sizeClass() {
      return `h-${this.size}`;
    },
    tooltipContent() {
      switch (this.marketplace) {
        case 0:
          return 'Opensea: Wyvern v1';
        case 2:
          return 'OpenSea: Wyvern v2';
        case 1:
          return 'LooksRare';
        case 3:
          return 'OpenSea: Seaport';
        case 4:
          return 'X2Y2';
        case 5:
          return 'Cryptopunks';
        case 6:
          return 'Sudoswap';
        case 7:
          return 'Blur';
        default:
          return '';
      }
    },
  },
};
</script>

<style>

</style>
