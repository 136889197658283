<template>
  <div
    v-once
    class="flex justify-center content-center"
    :class="{
      'py-12': size === 'lg',
      'py-6': size === 'md',
      'py-3': size === 'sm',
    }"
  >
    <LoadingIndicator class="text-indigo-600">
      <div class="ml-3 font-medium text-gray-600 dark:text-gray-300">
        Loading
      </div>
    </LoadingIndicator>
  </div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default {
  name: 'ViewLoader',

  components: {
    LoadingIndicator,
  },

  props: {
    size: {
      default: 'lg',
      type: String,
    },
  },
};
</script>
