<template>
  <UiCard
    class="flex flex-col w-full h-full"
    :slim="slim"
  >
    <UiCardBody
      class="flex overflow-hidden flex-col flex-1"
      :slim="slim"
    >
      <UiTabs
        v-model="selectedTabIndex"
        pills
        :tabs="tabs"
      >
        <template #tabs-extra>
          <div class="flex gap-3 items-center">
            <DelayedBadge :disabled="!collection.isPremiumRequired" />

            <LoadingIndicator
              v-if="isLoading"
              :size="20"
            />

            <div>
              <PeriodSelector
                v-model="chartPeriod"
                :save-key="`sales-v2-chart-${collection.id}`"
                :periods="[
                  '1m',
                  '5m',
                  '15m',
                  '1h',
                  '6h',
                  '1d',
                  '3d',
                  '7d',
                  '14d',
                  '30d',
                  '90d',
                ]"
              />
            </div>

            <UiDropdown>
              <template #toggle="{toggle}">
                <button
                  type="button"
                  class="
                text-lg
                dark:text-gray-200 dark:hover:text-indigo-500
                transition-colors
              "
                  @click="toggle"
                >
                  <i class="fad fa-cog" />
                </button>
              </template>

              <template #body>
                <UiDropdownForm class="space-y-3">
                  <UiToggle
                    v-model="filterTransactionsOutliers"
                    size="sm"
                  >
                    Filter outliers
                  </UiToggle>

                  <UiToggle
                    v-model="isTrendlineEnabled"
                    size="sm"
                  >
                    Trendlines
                  </UiToggle>

                  <UiInputGroup label="Color points by">
                    <UiSelectCustom
                      v-model="colorBy"
                      :options="colorOptions"
                    />
                  </UiInputGroup>
                </uidropdownform>
              </template>
            </UiDropdown>
          </div>
        </template>
      </UiTabs>

      <BoostedChart
        :is-loading="isLoading && !isRefetching"
        :options="chartOptions"
        class="flex-1"
      />
    </UiCardBody>
  </UiCard>
</template>

<script setup>
import {
  toRefs,
  computed,
  ref,
  shallowRef,
  watchEffect,
  watch,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import collectionSalesChartData from '@/graphql/collection/queries/collectionSalesChart.query.gql';
import collectionListingsChartData from '@/graphql/collection/queries/collectionListingsChart.query.gql';
import { useRefetchQueries } from '@/composition/refetch-queries';
import moment from 'moment-timezone';
import createTrend from 'trendline';
import { formatNumber, formatCurrency } from '@/utils/filters';
import useSettings from '@/composition/settings/useSettings';

import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import PeriodSelector from '@/components/PeriodSelector.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiCardBody from '@/components/ui/UiCardBody.vue';
import UiDropdownForm from '@/components/ui/UiDropdownForm.vue';
import UiToggle from '@/components/ui/UiToggle.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import DelayedBadge from '@/components/DelayedBadge.vue';
import {
  dataWorker,
} from '@/components/collections/workers/token-price-over-time-chart';
import { dateRangeFromPeriod } from '@/utils/dateRangeFromPeriod';
import rarityRankColor from '@/utils/rarityRankColor';
import BoostedChart from '@/components/BoostedChart.vue';
import { orderBy } from 'lodash-es';
import useTokenModal from '@/composition/tokens/useTokenModal';
import UiTabs from '../ui/UiTabs.vue';
import UiSelectCustom from '../ui/UiSelectCustom.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';

const intervalMap = {
  '1m': '1m',
  '5m': '1m',
  '15m': '1m',
  '1h': '1m',
  '6h': '5m',
  '1d': '15m',
  '7d': '1h',
  '14d': '3h',
  '30d': '6h',
  '90d': '1d',
};

const props = defineProps({
  collection: null,
  type: null,
  period: {
    default: '1h',
    type: String,
  },
  slim: null,
});

const {
  collection,
  slim,
} = toRefs(props);

const {
  isTrendlineEnabled,
  filterTransactionsOutliers,
} = useSettings({
  isTrendlineEnabled: { key: 'collection.analytics.tokenActivityScatter.isTrendlineEnabled', default: true },
  filterTransactionsOutliers: { key: 'collection.analytics.tokenActivityScatter.filterTransactionsOutliers', default: true },
});

const chartPeriod = ref('1h');

const fetchPolicy = 'cache-and-network';
const commonOptsFn = () => ({
  fetchPolicy,
});

const tabs = [
  {
    name: 'Sales',
    key: 'sales',
    color: 'rgba(191, 237, 114, 0.5)',
    colorSolid: 'rgba(191, 237, 114, 0.75)',
  },
  {
    name: 'Listings',
    key: 'listings',
    color: 'rgba(96, 165, 250, 0.5)',
    colorSolid: 'rgba(96, 165, 250, 0.75)',
  },
];

const selectedTabIndex = ref(0);

const selectedSeries = computed(() => tabs[selectedTabIndex.value].key);

const {
  result: chartDataResult,
  refetch,
  loading: isLoading,
} = useQuery(
  () => {
    if (selectedSeries.value === 'sales') {
      return collectionSalesChartData;
    }

    return collectionListingsChartData;
  },
  () => ({
    contractAddress: collection.value.id,
    period: chartPeriod.value,
    interval: intervalMap[chartPeriod.value],
  }),
  commonOptsFn,
);

const scatterDataRaw = computed(() => chartDataResult.value?.collection?.scatterData || []);

const scatterData = shallowRef([]);
dataWorker.onmessage = (e) => {
  scatterData.value = e.data;
};

watchEffect(() => {
  if (!scatterDataRaw.value || scatterDataRaw.value?.length === 0) return;
  const message = JSON.stringify({
    data: scatterDataRaw.value,
    filterOutliers: filterTransactionsOutliers.value,
  });
  dataWorker.postMessage(message);
});

watch([() => chartPeriod.value, selectedTabIndex.value, collection.value], () => {
  scatterData.value = [];
});

const volumeData = computed(() => {
  if (selectedSeries.value === 'sales') {
    return chartDataResult.value?.collection?.charts?.volume;
  }

  return chartDataResult.value?.collection?.listingsOverTime;
});

const {
  isRefetching,
} = useRefetchQueries({
  chartData: refetch,
});

const colorBy = ref(null);
watch(() => selectedTabIndex.value, () => {
  colorBy.value = null;
});

const { open } = useTokenModal();

const isColoredByRarity = computed(() => colorBy.value === 'rarity');
const isColoredByPnl = computed(() => colorBy.value === 'pnl');
const isColoredBySaleType = computed(() => colorBy.value === 'type');

const boostThreshold = 10000;

const colorOptions = computed(() => {
  const base = [{ id: null, name: 'None' }, { id: 'rarity', name: 'Rarity' }];
  if (selectedSeries.value === 'sales') {
    base.push({ id: 'pnl', name: 'Profit and Loss' });
    base.push({ id: 'type', name: 'Sale Type' });
  }

  return base;
});

const chartOptions = computed(() => {
  const { start } = dateRangeFromPeriod(chartPeriod.value, moment(scatterData.value?.[scatterData.value.length - 1]?.timestamp).add(5, 'second'));

  let trendlineSeries = [];
  if (isTrendlineEnabled.value) {
    const trendDataRaw = scatterData.value.map((item) => ({ x: Number(moment(item.key).format('x')), y: item.value }));
    const trendData = createTrend(trendDataRaw, 'x', 'y');
    trendlineSeries = [
      {
        name: `${tabs[selectedTabIndex.value].name} trendline`,
        type: 'line',
        lineWidth: 5,
        dashStyle: 'Dash',
        marker: {
          enabled: false,
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
        shadow: {
          color: '#000',
          opacity: 0.5,
        },
        opacity: 0.65,
        enableMouseTracking: false,
        data: scatterData.value.map((item) => {
          const ts = Number(moment(item.key).format('x'));
          return [ts, trendData.calcY(ts)];
        }),
        color: tabs[selectedTabIndex.value].color,
        boostThreshold: 0,
        yAxis: 0,
      },
    ];
  }

  const sortedData = orderBy(scatterData.value, 'key');

  return {
    chart: {
      zoomType: 'xy',
      styledMode: false,
      resetZoomButton: {
        theme: {
          fill: '#404040',
          stroke: '#404040',
          style: {
            color: '#D4D4D4',
          },
        },
      },
    },
    xAxis: {
      type: 'datetime',
      min: parseFloat(start.format('x')),
      max: parseFloat(moment().add(30, 'second').format('x')),
    },
    yAxis: [
      {
        height: '85%',
        title: {
          enabled: false,
        },
      },
      {
        opposite: false,
        top: '85%',
        height: '15%',
        labels: {
          enabled: false,
        },
        title: {
          enabled: false,
        },
      },
    ],
    // yAxis: {
    //   type: 'logarithmic',
    // },
    plotOptions: {
      series: {
        animation: false,
        stickyTracking: false,
        dataGrouping: {
          enabled: false,
        },
      },
      scatter: {
        // opacity: 0.75,
        jitter: {
          x: 1,
        },
        turboThreshold: boostThreshold,
        boostThreshold,
        marker: {
          symbol: 'circle',
          radius: 4,
          fillOpacity: 1,
        },
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => {
              const item = sortedData[event.point.index];

              // eslint-disable-next-line prefer-destructuring
              open(item.tokenId, { accordion: 'Activity Chart' });
            },
          },
        },
      },
      column: {
        borderRadius: 2,
        pointPadding: 0.3,
        grouping: false,
        groupPadding: 0.3,
      },
    },
    tooltip: {
      followPointer: false,
      snap: true,
      shared: false,
      useHTML: true,
      padding: 0,
      borderWidth: 0,
      shadow: false,
      hideDelay: 30,
      animation: false,
      shape: 'rect',
      headerFormat: '',
      footerFormat: '',
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: 'rgba(0,0,0,0)',
      pointFormatter() {
        if (this.series.initialType === 'column') {
          return `
          <div class="bg-gray-800 font-sans p-3 text-gray-400 rounded-xl">
            <table class="font-sans w-full">
              <tr class="mb-2">
                <th class="text-xs font-sans font-normal text-gray-400" colspan="2">${moment.utc(this.x).local().format('ll')}</th>
              </tr>
              <tr>
                <td class="text-sm">${this.series.name}</td>
                <td class="text-sm text-white text-medium">${this.y}</td>
              </tr>
            </table>
          </div>
          `;
        }
        const item = sortedData[this.index];
        return `
          <div class="bg-gray-800 font-sans p-3 text-gray-400 rounded-xl">
            ${item.image ? `
            <div class="aspect-w-1 aspect-h-1 w-40 rounded-lg overflow-hidden mb-2">
              <img class="w-full h-auto" src="${item.image}"/>
            </div>
            ` : ''}
            <table class="font-sans w-full">
              <tr>
                <th class="text-xs font-sans font-normal text-gray-400" colspan="2">${moment.utc(item.key).local().format('lll')}</th>
              </tr>
              <tr class="text-sm">
                <td class="font-sans" style="color: {series.color}">${this.series.name}</td>
                <td style="text-align: right" class="font-medium font-sans text-gray-100">
                  ${this.series.type === 'column' ? formatNumber(this.y) : formatCurrency(this.y)}
                </td>
              </tr>
              <tr class="text-sm">
                <td class="font-sans">Token ID</td>
                <td style="text-align: right" class="font-medium font-sans text-gray-100">
                  ${item.tokenId.split('_')[1]}
                </td>
              </tr>
              ${item.rarityRank ? `
              <tr class="text-sm">
                <td class="font-sans">Rarity</td>
                <td style="text-align: right" class="font-medium font-sans text-gray-100">
                  ${formatNumber(item.rarityRank)}
                </td>
              </tr>
              ` : ''}
              ${item.netProfit || item.netProfit === 0 ? `
              <tr class="text-sm">
                <td class="font-sans">Profit</td>
                <td style="text-align: right" class="font-medium font-sans text-gray-100">
                  ${formatCurrency(item.netProfit)}
                </td>
              </tr>
              ` : ''}
            </table>
          </div>`;
      },
      enabled: true,
    },
    series: [
      {
        stickyTracking: false,
        name: `${tabs[selectedTabIndex.value].name} price`,
        type: 'scatter',
        marker: {
          symbol: 'circle',
        },
        data: sortedData.map((item) => {
          const timestamp = Number(moment(item.key).format('x'));
          if (sortedData.length > boostThreshold) {
            return [timestamp, item.value];
          }
          let { color } = tabs[selectedTabIndex.value];
          if (isColoredByRarity.value) {
            color = rarityRankColor(item.rarityRank / collection.value.totalSupply);
          } else if (isColoredByPnl.value) {
            if (item.netProfit < 0) {
              color = 'rgba(255, 0, 0, 0.5)';
            }

            if (item.netProfit >= 0) {
              color = 'rgba(0, 255, 0, 0.5)';
            }
          } else if (isColoredBySaleType.value) {
            if (item.type === 'purchase') {
              color = 'rgba(0, 255, 0, 0.5)';
            }

            if (item.type === 'bid') {
              color = 'rgba(255, 0, 0, 0.5)';
            }
          }
          return {
            x: timestamp, y: item.value, color,
          };
        }),
        color: tabs[selectedTabIndex.value].color,
      },
      {
        name: `${tabs[selectedTabIndex.value].name} count`,
        type: 'column',
        yAxis: 1,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        color: tabs[selectedTabIndex.value].colorSolid,
        data: volumeData.value
          ?.map((item) => [
            Number(moment(item.key).format('x')),
            item.value,
          ]) || [],
      },
      // ...(selectedSeries.value === 'sales' ? [
      //   {
      //     stickyTracking: false,
      //     states: {
      //       inactive: {
      //         opacity: 1,
      //       },
      //     },
      //     name: 'Sale',
      //     type: 'scatter',
      //     data: salesData.value.map((item) => {
      //       if (salesData.value.length > boostThreshold) {
      //         return [item.timestamp, item.value];
      //       }
      //       let color;
      //       if (isColoredByRarity.value) {
      //         color = rarityRankColor(item.rarityRank / collection.value.totalSupply);
      //       } else if (isColoredByPnl.value) {
      //         color = item.netProfit < 0 ? 'rgba(255, 0, 0, 1)' : 'rgba(0, 255, 0, 1)';
      //       }
      //       return {
      //         x: item.timestamp, y: item.value, color,
      //       };
      //     }),
      //     color: 'rgba(191, 237, 114, 0.3)',
      //   },
      //   {
      //     stickyTracking: false,
      //     states: {
      //       inactive: {
      //         opacity: 1,
      //       },
      //     },
      //     visible: selectedSeries.value === null || selectedSeries.value === 'sales',
      //     name: 'Sales',
      //     type: 'column',
      //     yAxis: 1,
      //     color: '#bfed72',
      //     opacity: 0.75,
      //     data:
      //         volume.value
      //           ?.map((item) => {
      //             const data = { ...item };
      //             data.timestamp = moment(data.key).toDate().getTime();
      //             return data;
      //           })
      //           ?.map((item) => [item.timestamp, item.value]) || [],
      //   },
      // ] : []),
      ...trendlineSeries,
    ],
  };
});
</script>
