<template>
  <UiModal
    v-model="isVisible"
    bg-blur
    z-index="100"
  >
    <div
      v-if="emailSent"
      class="p-8 text-center"
    >
      <div>
        <i class="mb-6 text-6xl text-indigo-500 fas fa-check-circle" />
      </div>

      <div class="mb-3 text-xl font-medium">
        Check your inbox
      </div>

      <div class="mb-2 text-gray-400">
        We sent you an email with a link to sign in.
      </div>

      <div
        class="text-sm text-indigo-500 hover:text-indigo-400 hover:underline cursor-pointer"
        @click="emailSent = false"
      >
        Having trouble? Resend email
      </div>
    </div>

    <div
      v-if="!emailSent"
      class="p-8 text-center"
    >
      <h3 class="mb-4 text-lg font-medium text-gray-800 dark:text-gray-200">
        Sign in <span
          v-if="action"
        >{{ action }}</span>
      </h3>

      <div class="mx-auto space-y-3 sm:max-w-xs">
        <div>
          <UiInput
            v-model="emailAddress"
            v-autofocus
            size="lg"
            placeholder="E-mail address"
            class="mb-2"
            type="email"
            @keyup.enter="sendEmailWithCode"
          />

          <UiButton
            block
            size="lg"
            :disabled="isSigningIn"
            @click="requestEmailWithLink"
          >
            Continue With Email
          </UiButton>
        </div>

        <div class="my-3 italic font-light text-gray-400">
          or
        </div>

        <UiButton
          block
          :disabled="isSigningIn"
          size="lg"
          class="flex items-center bg-brand-discord bg-opacity-80"
          :class="{'hover:bg-opacity-100': !isSigningIn}"
          variant="custom"
          @click="connectDiscord()"
        >
          <i class="mr-2 fab fa-discord" />
          Continue With Discord
        </UiButton>

        <UiButton
          block
          :disabled="isSigningIn"
          size="lg"
          variant="white"
          @click="connectGoogle()"
        >
          <i class="mr-2 fab fa-google" />
          Continue With Google
        </UiButton>

        <UiButton
          block
          :disabled="isSigningIn"
          size="lg"
          class="flex items-center"
          variant="white"
          @click="connectWallet()"
        >
          <i class="mr-2 fas fa-wallet" />
          Connect With Wallet
        </UiButton>

        <div
          v-if="error"
          class="
              p-3
              mt-2
              text-sm
              text-center
              text-red-600
              rounded-lg
              bg-red-600 bg-opacity-20
              ring-red-600 ring-offset-0
            "
        >
          {{ error }}
        </div>
      </div>

      <div class="mt-6 text-sm text-gray-400">
        By signing in you agree to our <a
          href="https://compass.art/legal/tos"
          target="_blank"
          class="text-gray-500 hover:text-gray-400 hover:underline"
        >Terms of Service</a>

        and <a
          href="https://compass.art/legal/privacy"
          target="_blank"
          class="text-gray-500 hover:text-gray-400 hover:underline"
        >Privacy Policy</a>.
      </div>
    </div>
  </UiModal>
</template>

<script setup>
/* eslint-disable */
import { inject, onMounted, toRefs, ref, reactive } from "vue";
import { useUiDialog } from "@/components/ui-dialog/useUiDialog";
import Web3 from "web3";
import UiModal from "@/components/ui/UiModal";
import UiButton from "@/components/ui/UiButton";
import eventBus from "@/utils/event-bus";
import UiInput from "./ui/UiInput.vue";
import axios from "axios";
import { useStore } from "vuex";
import useAuth from "@/composition/auth/use-auth";



const { info } = useUiDialog();
const notify = inject("notify");

// const isVisible = ref(false);

const {
  isVisible, 
  error,
  action,
  emailAddress,
  emailSent,
} = toRefs(reactive({
  isVisible: false,
  // Flags 
  isWaitingForSignature: false,
  isConnectingWallet: false,
  isLinkingWallet: false,
  // Data
  error: null,
  action: null,
  emailAddress: '',
  emailSent: false,
}))

const store = useStore();

onMounted(() => {
  eventBus.$on("auth:signIn", (a = null) => {
    isVisible.value = true;
    error.value = null;
    action.value = a;
  });
});




const onLogin = async () => {
  await store.dispatch("auth/init");
  isVisible.value = false;
  notify({ title: 'Welcome!' });
  eventBus.$emit('auth:didSignIn');
}

const {
  isSigningIn,
  signInWithDiscord,
  signInWithGoogle,
  signInWithEmail,
  signInWithWallet,
} = useAuth();

const connectDiscord = () => {
  return signInWithDiscord()
    .then(onLogin)
    .catch((e) => {
      error.value = e.message;
    });
};

const connectGoogle = () => {
  return signInWithGoogle()
    .then(onLogin)
    .catch((e) => {
      error.value = e.message;
    });
};

const connectWallet = () => {
  return signInWithWallet()
    .then(onLogin)
    .catch((e) => {
      error.value = e.message;
    });
};

const requestEmailWithLink = () => {
  return signInWithEmail(emailAddress.value)
    .then(() => {
      emailSent.value = true;
    })
    .catch((e) => {
      error.value = e.message;
    });
};
</script>

<style scoped>
.discord-button {
  background: #5865F2;
  color: #fff;
}
.discord-button:hover {
  background: #717cf4;
  color: #fff;
}
</style>
