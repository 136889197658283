<template>
  <UiCard>
    <div>
      <fieldset>
        <UiCardBody class="p-3 border-b border-black">
          <UiButton
            type="button"
            variant="primary"
            @click="() => openCreateChannel()"
          >
            <i class="mr-2 far fa-plus" />
            Create Channel
          </UiButton>
        </UiCardBody>

        <div
          v-if="channels"
          class="divide-y dark:divide-black"
        >
          <div
            v-for="item in channels"
            :key="item.id"
            class="flex relative items-center p-3 px-6 hover:bg-gray-800"
          >
            <div class="flex-1">
              <div class="flex flex-1 items-center space-x-2 min-w-0 text-sm">
                <label
                  :for="`channel-${item.id}`"
                  class="
                    font-medium
                    text-gray-700
                    dark:text-gray-300
                    select-none
                  "
                >{{ item.name }}</label>

                <div
                  v-if="item.status === 'ERROR'"
                  v-tooltip="errorMessages[item.type]"
                  class="
                      flex
                      items-center
                      py-0.5
                      px-1
                      ml-2
                      text-xs
                      font-medium
                      leading-none
                      text-red-100
                      bg-red-900
                      rounded
                      cursor-default
                    "
                >
                  Error
                </div>
              </div>

              <div class="overflow-hidden mt-1 max-w-full text-xs text-gray-400">
                <div v-if="['TELEGRAM', 'DISCORD_DM'].indexOf(item.type) !== -1">
                  Chat ID: {{ item.config.chatId }}
                </div>

                <div v-if="['DISCORD_WEBHOOK', 'WEBHOOK'].indexOf(item.type) !== -1">
                  Webhook: <div
                    class="inline underline cursor-pointer"
                    @click="copyUrl(item.config.url)"
                  >
                    click to copy
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center ml-3 space-x-3 h-5">
              <UiButton
                variant="white"
                size="xs"
                :is-loading="channelsTesting.includes(item.id)"
                @click="testChannel(item)"
              >
                Test
              </UiButton>

              <UiButton
                variant="red"
                size="xs"
                @click="deleteChannel(item)"
              >
                Delete
              </UiButton>
            </div>
          </div>
        </div>

        <div v-else>
          <ViewLoader size="md" />
        </div>
      </fieldset>

      <UiModal v-model="isCreateChannelVisible">
        <CreateChannel @dismiss="isCreateChannelVisible = false" />
      </UiModal>
    </div>
  </UiCard>
</template>

<script>
import {
  defineComponent,
  computed,
  toRefs,
  ref,
} from 'vue';
import { useUserChannelsGraphql } from '@/composition/user-channels';
import { copyTextToClipboard } from '@/utils/clipboard';
import { useUiDialog } from '@/components/ui-dialog/useUiDialog';

import UiModal from '@/components/ui/UiModal.vue';
import UiButton from '../ui/UiButton.vue';
import CreateChannel from './CreateChannel.vue';
import ViewLoader from '../ViewLoader.vue';
import UiCard from '../ui/UiCard.vue';
import UiCardBody from '../ui/UiCardBody.vue';

export default defineComponent({
  name: 'ChannelPicker',

  components: {
    UiModal,
    UiButton,
    CreateChannel,
    ViewLoader,
    UiCard,
    UiCardBody,
  },

  props: {
    modelValue: null,
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const { channels, deleteAlertChannel, testChannel } = useUserChannelsGraphql();
    const { info } = useUiDialog();
    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit('update:modelValue', val);
      },
    });
    const isCreateChannelVisible = ref(false);
    function openCreateChannel() {
      isCreateChannelVisible.value = true;
    }

    return {
      channelsTesting: ref([]),
      isCreateChannelVisible,
      openCreateChannel,
      channels,
      deleteAlertChannel,
      testAlertChannel: testChannel,
      info,
      model,
      errorMessages: ref({
        DISCORD_DM: 'Invalid channel. Make sure you allow Discord DMs from our alerts bot.',
        DISCORD_WEBHOOK: 'Invalid channel. Make sure the webhook link is correct and the channel exists.',
        TELEGRAM: 'Invalid channel. Make sure you send /start to our Telegram bot.',
        WEBHOOK: 'Invalid channel. Make sure the webhook URL is correct',
      }),
    };
  },

  methods: {
    copyUrl(url) {
      return copyTextToClipboard(url).then(() => {
        this.info('Webhook URL copied to clipboard', {
          icon: 'fas fa-check-circle text-green-400',
        });
      });
    },
    deleteChannel(channel) {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(`Are you sure that you want to delete the ${channel.name} channel?`)
      ) {
        return Promise.reject();
      }
      return this.deleteAlertChannel({ id: channel.id })
        .then(() => {
          this.info('Channel deleted');
        });
    },
    testChannel(channel) {
      if (this.channelsTesting.includes(channel.id)) {
        return Promise.resolve();
      }
      this.channelsTesting.push(channel.id);
      return this.testAlertChannel({ id: channel.id })
        .then((res) => {
          if (res.data.testChannel.status === 'ERROR') {
            throw new Error('Invalid channel status');
          }
          this.info(`Channel ${channel.name} is valid and working, check your inbox.`);
        })
        .catch(() => {
          this.info(this.errorMessages[channel.type]);
        }).finally(() => {
          this.channelsTesting = this.channelsTesting.filter((item) => item !== channel.id);
        });
    },
  },
});
</script>

<style>
</style>
