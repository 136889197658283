<script setup>
import { VText, FlexCol } from '@/components/fcomponents';
import { useErrorRefetcher } from '@/composition/error-refetcher/useErrorRefetcher';
import UiButton from './ui/UiButton.vue';

const { executeFailedRefetch } = useErrorRefetcher();
</script>

<template>
  <FlexCol
    items="center"
    class="py-4 sm:py-6"
  >
    <VText
      size="medium"
      color="white"
    >
      Something went wrong.
    </VText>

    <UiButton
      class="mr-2"
      variant="white"
      @click="() => executeFailedRefetch()"
    >
      Try again
    </UiButton>
  </FlexCol>
</template>
