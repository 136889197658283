<template>
  <ActionInputMapper
    v-model="value"
    :inputs="action.inputs"
    :validation-issues="validationIssues"
    :trigger="trigger"
  >
    <template #input(items)="{model, updateModel, error}">
      <UiInputGroup
        label="Items"
        :error-message="error?.message"
        hint="Optionally set the type of the array items"
      >
        <UiSelectCustom
          :model-value="model"
          :options="IO_TYPES.filter(item => item.isPrimitive === true)"
          @update:modelValue="updateModel"
        />
      </UiInputGroup>
    </template>

    <template #input(name)="{model, updateModel, error}">
      <UiInputGroup
        label="Name"
        :error-message="error?.message"
        hint="The name of the variable to set, overwriting any existing variable with the same name."
      >
        <UiInput
          placeholder="variableName"
          :model-value="model"
          @update:modelValue="updateModel($event)"
        />
      </UiInputGroup>
    </template>
  </ActionInputMapper>
</template>

<script setup>
import UiSelectCustom from '@/components/ui/UiSelectCustom.vue';
import { toRefs, computed } from 'vue';
import { IO_TYPES } from '@/constants/automation';
import UiInputGroup from '@/components/ui/UiInputGroup.vue';
import UiInput from '@/components/ui/UiInput.vue';
import ActionInputMapper from '../ActionInputMapper.vue';

const props = defineProps(['modelValue', 'action', 'type', 'validationIssues', 'trigger']);
const emit = defineEmits(['update:modelValue', 'trigger']);

const {
  modelValue, action, validationIssues,
} = toRefs(props);

const value = computed({
  get() {
    return modelValue.value;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

</script>
