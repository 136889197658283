<template>
  <div class="inline-block overflow-hidden rounded-full">
    <img
      v-if="src"
      :src="src"
      :class="`w-${size} h-${size}`"
    >

    <div
      v-else-if="address"
      v-jazzicon="[size * 4, parseInt(address.slice(0, 10), 16)]"
      :class="`w-${size} h-${size}`"
    />
  </div>
</template>

<script>
import jazzicon from '@metamask/jazzicon';

export default {
  name: 'ProfilePicture',
  directives: {
    jazzicon: {
      mounted(el, { value }) {
        const icon = jazzicon(...value);

        el.replaceChildren(icon);
      },
      updated(el, { value }) {
        const icon = jazzicon(...value);

        el.replaceChildren(icon);
      },
    },
  },
  props: {
    src: null,
    /**
     * Seed used to generate icon data, default: random
     */
    address: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 8,
    },
    /**
     * Width/height of each block in pixels, default: 4
     */
    scale: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      dataURL: null,
      canvas: null,
    };
  },
};
</script>
