<template>
  <div
    ref="clickAwayRoot"
    class="relative"
  >
    <form
      autocomplete="off"
      class="relative z-40"
      @submit.prevent
    >
      <slot
        name="search-field"
        v-bind="{search, show, selectResult, isVisible, close}"
      />
    </form>

    <Transition name="dropdown">
      <div
        v-if="isVisible"
        class="overflow-x-hidden overflow-y-auto absolute z-50 pr-2 mt-2 w-full bg-white dark:bg-gray-900 rounded dark:divide-gray-800 shadow-md divide scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
        @click="close"
      >
        <slot
          name="results"
          :collections="collections"
          :wallets="wallets"
        >
          <div class="p-2 max-h-96 text-white">
            <div
              v-if="only === null && isLikelySearchingForToken"
              class="py-1.5 px-3 text-sm font-medium text-gray-300"
            >
              Tokens
            </div>

            <div v-if="only === null && isLikelySearchingForToken">
              <UiLink
                v-if="tokenResult"
                class="universal-search__result"
                @click="selectResult('token', tokenResult)"
              >
                <TokenListItem
                  :collection="{id: tokenResult.collectionId}"
                  :token="tokenResult"
                  no-overlay
                  no-link
                />
              </UiLink>

              <div
                v-if="!tokenResult"
                class="universal-search__result"
              >
                <div class="flex gap-2 items-center ">
                  <div class="w-10 h-10 bg-gray-800 rounded-lg animate-pulse" />

                  <div>
                    <div class="w-24 h-4 bg-gray-800 animate-pulse" />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="only === null"
              class="py-1.5 px-3 text-sm font-medium text-gray-300"
            >
              Collections
            </div>
            <!-- Search results -->
            <div
              v-if="(only === null || only === 'collection') && collections.length"
            >
              <UiLink
                v-for="item in collections"
                :key="item.id"
                class="universal-search__result"
                @click="selectResult('collection', item)"
              >
                <CollectionListItem
                  no-link
                  no-overlay
                  :pic-size="small === false ? 8 : 6"
                  :mini="small !== false"
                  :collection="item"
                  class="overflow-hidden whitespace-nowrap"
                >
                  <template
                    #extra-content="{item: resultItem}"
                  >
                    <span v-if="resultItem.totalVolume">
                      Total volume: {{ formatCurrency(resultItem.totalVolume) }}
                    </span>
                  </template>
                </CollectionListItem>

                <slot
                  name="result-right"
                  type="collection"
                />
              </UiLink>
            </div>

            <div
              v-if="isCollectionSearchResultsLoading"
              class="flex gap-3 items-center p-3"
            >
              <div class="w-9 h-9 bg-gray-800 rounded-full animate-pulse" />

              <div class="w-40 h-4 bg-gray-800 rounded-xl animate-pulse" />
            </div>

            <div
              v-else-if="(only === null || only === 'collection') && search.term.length > 0 && collections.length === 0"
              class="py-2.5 px-3 text-sm font-light text-center text-gray-400"
            >
              No matches
            </div>

            <div
              v-if="only === null"
              class="p-3 py-1.5 px-3 text-sm font-medium text-gray-300"
            >
              Wallets
            </div>

            <div
              v-if="(only === null || only === 'wallet') && wallets.length"
              class="dark:divide-gray-700 divide"
            >
              <UiLink
                v-for="item in wallets"
                :key="item.id"
                class="universal-search__result"
                @click="selectResult('wallet', item)"
              >
                <WalletProfile
                  :pic-size="small === false ? 8 : 6"
                  :wallet="item"
                  no-link
                  no-overlay
                />

                <slot
                  name="result-right"
                  type="wallet"
                />
              </UiLink>
            </div>
            <!-- Loading state -->
            <div
              v-if="(only === null || only === 'wallet') && isWalletSearchResultsLoading"
              class="flex gap-3 items-center p-3"
            >
              <div class="w-9 h-9 bg-gray-800 rounded-full animate-pulse" />

              <div class="w-40 h-4 bg-gray-800 rounded-xl animate-pulse" />
            </div>

            <div
              v-else-if="(only === null || only === 'wallet') && search.term.length > 0 && wallets.length === 0"
              class="py-2.5 px-3 text-sm font-light text-center text-gray-400"
            >
              No matches
            </div>

            <div v-if="isLoggedIn && (only === null || only === 'walletWatchlist')">
              <div
                v-if="only === null"
                class="p-3 py-1.5 px-3 text-sm font-medium text-gray-300"
              >
                Wallet Groups
              </div>

              <div
                v-if="walletWatchlists.length"
                class="dark:divide-gray-700 divide"
              >
                <UiLink
                  v-for="item in walletWatchlists"
                  :key="item.id"
                  class="universal-search__result"
                  @click="selectResult('group', item)"
                >
                  <WalletWatchlistListItem :watchlist="item" />

                  <slot
                    name="result-right"
                    type="wallet"
                  />
                </UiLink>
              </div>
              <!-- Loading state -->
              <div
                v-if="isWalletWatchlistsLoading"
                class="flex gap-3 items-center p-3"
              >
                <div class="w-9 h-9 bg-gray-800 rounded-full animate-pulse" />

                <div class="w-40 h-4 bg-gray-800 rounded-xl animate-pulse" />
              </div>

              <div
                v-else-if="search.term.length > 0 && walletWatchlists.length === 0"
                class="py-2.5 px-3 text-sm font-light text-center text-gray-400"
              >
                No matches
              </div>
            </div>
          </div>
        </slot>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import {
  reactive,
  computed,
  toRefs,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { isAddress } from 'web3-utils';
import { orderBy, uniqBy } from 'lodash-es';
import searchCollectionsQuery from '@/graphql/collection/queries/searchCollections.query.gql';
import searchWalletsQuery from '@/graphql/wallet/queries/searchWallets.query.gql';
import collectionListItemQuery from '@/graphql/collection/queries/collectionListItem.query.gql';
import walletByEnsQuery from '@/graphql/wallet/queries/walletByEns.query.gql';
import { formatCurrency } from '@/utils/filters';
import { useClickAway } from '@/composition/click-away/useClickAway';

import UiLink from '@/components/ui/UiLink.vue';
import CollectionListItem from '@/components/collections/CollectionListItem.vue';
import { useRecommendedCollections } from '@/composition/collections/use-recommended-collections';
import { useStore } from 'vuex';
import gql from 'graphql-tag';
import { TOKEN_FIELDS } from '@/graphql/token/fragments/tokenFields.fragment';
import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { WATCHLIST_TYPES } from '@/constants';
import WalletProfile from '../wallets/WalletProfile.vue';
import { useRecentResults } from './composition';
import TokenListItem from '../TokenListItem.vue';
import WalletWatchlistListItem from '../wallets/WalletWatchlistListItem.vue';

const props = defineProps({
  only: {
    default: null,
    type: String,
  },
  maxResults: {
    default: 5,
    type: Number,
  },
  small: {
    default: false,
    type: Boolean,
  },
});
const emit = defineEmits(['select-result']);

const {
  getRecentResultsFromStorage,
  saveRecentResultToStorage,
} = useRecentResults();
const {
  forceClose: close,
  forceOpen: show,
  isOpen: isVisible,
  clickAwayRoot,
} = useClickAway();

const { only, maxResults } = toRefs(props);
const search = reactive({
  term: '',
});

const isValidAddress = computed(() => search.term && search.term.length > 0 && isAddress(search.term));

const isValidEns = computed(() => search.term.slice(-4) === '.eth');

const { collections: bluechipCollectionsData } = useRecommendedCollections({ enabled: isVisible });

const bluechipCollections = computed(() => bluechipCollectionsData.value || []);

const store = useStore();

const userWallets = computed(() => store.getters['auth/wallets']);

const matchedTokenId = computed(() => {
  const parts = search.term.split(/\s|:|_|\//);
  if (parts.length < 2) {
    return null;
  }

  const lastWord = parts.at(-1);
  const tokenId = Number(lastWord);

  return Number.isNaN(tokenId) ? null : tokenId;
});
const isLikelySearchingForToken = computed(() => search.term.length > 0 && matchedTokenId.value);
const searchTerm = computed(() => (isLikelySearchingForToken.value ? search.term.split(/\s|:|_|\//).slice(0, -1).join(' ') : search.term).toLowerCase());

const {
  result: searchCollectionResult,
  loading: isCollectionSearchResultsLoading,
} = useQuery(
  searchCollectionsQuery,
  () => ({
    search: searchTerm.value,
    take: 5,
  }),
  () => ({
    enabled: Boolean(search.term.length) && !isValidAddress.value && (only.value === null || only.value === 'collection'),
    debounce: 400,
  }),
);
const collectionSearchResults = computed(() => searchCollectionResult.value?.searchCollections);

const {
  result: searchWalletResult,
  loading: isLoadingWalletSearchResults,
} = useQuery(
  searchWalletsQuery,
  () => ({
    search: searchTerm.value,
    take: 5,
  }),
  () => ({
    enabled: Boolean(search.term.length) && !isValidAddress.value && (only.value === null || only.value === 'wallet'),
  }),
);

const walletSearchResults = computed(() => searchWalletResult.value?.searchWallets || []);

const {
  result: collectionResult,
} = useQuery(
  collectionListItemQuery,
  () => ({ address: searchTerm.value }),
  () => ({
    enabled: (only.value === null || only.value === 'collection') && isValidAddress.value,
  }),
);
const collection = computed(() => collectionResult.value?.collection);

const {
  result: walletByEnsResult,
  loading: isLoadingWalletByEns,
} = useQuery(
  walletByEnsQuery,
  () => ({
    address: searchTerm.value,
  }),
  () => ({
    enabled: isValidEns.value,
  }),
);
const wallet = computed(() => walletByEnsResult.value?.wallet);

const { getWatchlists } = useWatchlists(WATCHLIST_TYPES.WALLET);

const { watchlists, isLoadingWatchlists: isWalletWatchlistsLoading } = getWatchlists({ enabled: (only.value === null || only.value === 'walletWatchlist') });

const walletWatchlists = computed(() => {
  if (!watchlists.value) {
    return [];
  }

  return watchlists.value.filter((watchlist) => watchlist.name.toLowerCase().includes(search.term.toLowerCase()));
});

const isWalletSearchResultsLoading = computed(() => isLoadingWalletByEns.value || isLoadingWalletSearchResults.value);
const sortedCollectionResults = computed(() => orderBy(collectionSearchResults.value || [], 'totalVolume', 'desc').slice(0, maxResults.value));

const bestCollectionMatch = computed(() => sortedCollectionResults.value?.[0] || collection.value);

const isSearchingForToken = computed(() => (only.value === 'token' || only.value === null) && search.term.length > 0 && Boolean(bestCollectionMatch.value) && isLikelySearchingForToken.value);

const { result: tokenQueryResult, loading: isLoadingToken } = useQuery(gql`
    query SingleToken($tokenId: String!) {
      token(where: {id: $tokenId}) {
        ...Token_Fields
      }
    }
    ${TOKEN_FIELDS}
    `, () => ({ tokenId: `${bestCollectionMatch.value?.id}_${matchedTokenId.value}` }), () => ({
  enabled: isSearchingForToken.value,
}));

const tokenResult = computed(() => tokenQueryResult.value?.token);

const collections = computed(() => {
  if (isValidAddress.value && collection.value) {
    return [collection.value];
  }

  if (search.term?.length > 0) {
    return sortedCollectionResults.value;
  }

  if (isValidAddress.value && collection.value) {
    return [collection.value];
  }

  return uniqBy([
    ...(getRecentResultsFromStorage('collection') || []),
    ...(bluechipCollections.value || []),
  ], 'id').slice(0, maxResults.value);
});

const wallets = computed(() => {
  if (isValidEns.value && wallet.value) {
    return [wallet.value];
  }
  if (isValidAddress.value) {
    return [
      {
        id: search.term.toLowerCase(),
      },
    ];
  }
  if (search.term?.length > 0) {
    return walletSearchResults.value;
  }

  return uniqBy([
    ...(getRecentResultsFromStorage('wallet') || []),
    ...(userWallets.value || []),
  ], 'id').slice(0, maxResults.value);
});

const selectResult = (type, data) => {
  saveRecentResultToStorage({ type, data });
  emit('select-result', { type, data });
  search.term = '';
};

const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

</script>

<style scoped>
.universal-search__result {
  @apply flex justify-between items-center py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-xl cursor-pointer;
}
</style>
