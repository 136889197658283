<template>
  <div>
    <button
      type="button"
      class="
        ml-2
        text-lg
        dark:text-gray-200 dark:hover:text-indigo-500
        transition-colors
      "
      @click="isVisible = true"
    >
      <i class="fad fa-cog" />
    </button>

    <UiModal v-model="isVisible">
      <UiModalHeader
        title="Customize Table Columns"
        @dismiss="isVisible = false"
      />

      <div v-if="preferredFields">
        <PremiumPlaceholder action="customize table columns">
          <div class="py-1 px-4 mt-2 text-sm font-semibold text-gray-100">
            Visible columns
          </div>

          <Draggable
            v-model="enabledFields"
            :disabled="enabledFields.length === 1"
            ghost-class="ghost"
            tag="ul"
            class="divide-y divide-gray-1000"
            :item-key="(fieldName) => fieldName"
          >
            <template #item="{ element }">
              <li
                class="flex justify-between items-center py-2.5 px-4 bg-white dark:bg-gray-850 cursor-move"
              >
                <div class="text-sm">
                  {{ element.name }}
                </div>

                <div class="flex items-center space-x-3">
                  <UiToggle
                    v-if="!element.fixed"
                    :model-value="element.isEnabled"
                    @update:modelValue="togglePreferredFields(element.id)"
                  />
                </div>
              </li>
            </template>
          </Draggable>

          <div
            v-if="disabledFields.length > 0"
            class="py-1 px-4 mt-4 text-sm font-semibold text-gray-100"
          >
            Hidden columns
          </div>

          <ul
            v-if="disabledFields.length > 0"
            class="divide-y divide-gray-1000"
          >
            <li
              v-for="(item, index) in disabledFields"
              :key="index"
              class="flex justify-between items-center py-2.5 px-4"
            >
              <div class="text-sm">
                {{ item.name }}
              </div>

              <div class="flex items-center space-x-3">
                <UiToggle
                  v-if="!item.fixed"
                  v-model="item.isEnabled"
                  :model-value="item.isEnabled"
                  @update:modelValue="() => togglePreferredFields(item.id)"
                />
              </div>
            </li>
          </ul>
        </PremiumPlaceholder>
      </div>
    </UiModal>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  toRefs,
} from 'vue';
import { useCollectionsTablesSettings } from '@/composition/collections/collection-table-settings';
import { maxBy, minBy } from 'lodash-es';
import Draggable from 'vuedraggable';

import UiModal from './ui/UiModal.vue';
import UiToggle from './ui/UiToggle.vue';
import UiModalHeader from './ui/UiModalHeader.vue';
import PremiumPlaceholder from './PremiumPlaceholder.vue';

export default defineComponent({
  components: {
    UiModal,
    UiToggle,
    UiModalHeader,
    Draggable,
    PremiumPlaceholder,
  },

  props: {
    routeKey: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const {
      routeKey: keyRef,
      routeName: routeNameRef,
    } = toRefs(props);

    const {
      availableFields,
      enabledFields,
      disabledFields,
      togglePreferredFields,
      preferredFields,
    } = useCollectionsTablesSettings({ keyRef, routeNameRef });

    const isVisible = ref(false);

    const maxOrder = computed(() => maxBy(
      availableFields.value.filter((item) => item.isEnabled),
      'order',
    ).order);

    const minOrder = computed(() => minBy(
      availableFields.value.filter((item) => item.isEnabled),
      'order',
    ).order);

    return {
      togglePreferredFields,
      availableFields,
      enabledFields,
      disabledFields,
      maxOrder,
      minOrder,
      isVisible,
      preferredFields,
    };
  },
});
</script>

<style lang="scss" scoped>
.ghost {
  opacity: .7;
  @apply bg-gray-500 dark:bg-gray-1000;
}
</style>
