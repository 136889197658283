<template>
  <Chart
    v-bind="chartProps"
    :is-loading="loading && !isRefetching"
  />
</template>

<script>
import {
  defineComponent,
  toRefs,
  computed,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import {
  groupBy, mapValues, max, min, reduce, sortBy,
} from 'lodash-es';
import listingDistributionChartDataQuery from '@/graphql/listing-histogram-data/queries/listingDistributionChartData.query.gql';
import { useRefetchQueries } from '@/composition/refetch-queries';

import { formatNumber } from '@/utils/filters';
import Chart, { ChartColorMap, createTooltip } from '../Chart.vue';

const marketplaceOrder = ['Opensea', 'LooksRare', 'X2Y2', 'Sudoswap'];

const marketplaceColor = {
  Opensea: 100,
  LooksRare: 101,
  X2Y2: 102,
  Sudoswap: 103,
};

const marketplaceMap = {
  0: 'Opensea',
  1: 'LooksRare',
  2: 'Opensea',
  3: 'Opensea',
  4: 'X2Y2',
  5: 'Cryptopunks',
  6: 'Sudoswap',
  7: 'Blur',
};

export default defineComponent({
  name: 'CollectionListingsHistorgram',

  components: { Chart },

  props: ['collection', 'dataGrouping', 'maxPrice', 'minPrice'],

  setup(props) {
    const {
      collection,
      dataGrouping,
      maxPrice,
      minPrice,
    } = toRefs(props);

    const {
      result: ordersHistogramResult,
      refetch,
      loading,
    } = useQuery(
      listingDistributionChartDataQuery,
      () => ({
        collectionId: collection.value.id,
        dataGrouping: dataGrouping.value,
      }),
    );
    const ordersHistogram = computed(() => ordersHistogramResult.value?.ordersHistogram);

    const ordersHistogramSlice = computed(() => {
      const minValue = minPrice.value ? parseFloat(minPrice.value) : -Infinity;
      const maxValue = maxPrice.value ? parseFloat(maxPrice.value) : Infinity;
      return ordersHistogram.value?.filter((histogramData) => {
        const priceBin = parseFloat(histogramData.priceBin);
        return priceBin >= minValue && priceBin <= maxValue;
      });
    });

    const { isRefetching } = useRefetchQueries({ 'orders-histogram': refetch });

    return {
      ordersHistogramSlice,
      ordersHistogram,
      isRefetching,
      loading,
    };
  },

  data() {
    return {
      invisibleScrollArea: false,
    };
  },
  computed: {
    chartProps() {
      const { setInvisibleScrollArea } = this;
      const dataLength = (this.ordersHistogramSlice || []).length;
      const byMarketplace = mapValues(
        groupBy(this.ordersHistogramSlice || [], 'marketplace'),
        (data) => data.map((item) => ({
          key: parseFloat(item.priceBin.toFixed(this.dataGrouping < 0.01 ? 3 : 2)),
          count: item.count,
          totalCount: item.totalCount,
          totalValue: item.totalValue,
        })),
      );

      const totalByPriceBin = mapValues(
        groupBy(this.ordersHistogramSlice || [], 'priceBin'),
        (data) => data.reduce((acc, item) => acc + item.count, 0),
      );

      let total = sortBy(Object.keys(totalByPriceBin).map((key) => ({ x: parseFloat(Number(key).toFixed(this.dataGrouping < 0.01 ? 3 : 2)), y: totalByPriceBin[key] })), 'x');
      total = total.reduce((acc, t, index) => {
        if (index === 0) {
          acc.push(t);
        } else {
          acc.push({
            ...t,
            y: t.y + acc[index - 1].y,
          });
        }

        return acc;
      }, []);

      return {
        options: {
          chart: {
            zoomType: 'xy',
            scrollablePlotArea: {
              minWidth: this.invisibleScrollArea ? dataLength : dataLength * 15,
              opacity: 0.42,
            },
          },
          plotOptions: {
            column: {
              //   pointPadding: 0,
              //   borderWidth: 0,
              //   groupPadding: 0,
              //   shadow: false,
              stacking: 'normal',
            },
            spline: {
              marker: false,
            },
          },
          xAxis: {
            // type: "linear",
            // min: listingsData?.[0]?.[0],
            // max: listingsData?.[Math.min(40, listingsData.length)]?.[0],
            // scrollbar: {
            //   enabled: listingsData.length > 40,
            // },
            events: {
              afterSetExtremes() {
                setInvisibleScrollArea(this.chart.resetZoomButton);
              },
            },
          },
          yAxis: [
            {
              opposite: false,
              min: 0,
              max: max(this.ordersHistogram?.map((item) => item.count)),
              title: {
                text: undefined,
              },
            },
            {
              opposite: true,
              min: min(total.map((item) => item.y)),
              max: max(total.map((item) => item.y)),
              title: {
                text: undefined,
              },
            },
          ],
          tooltip: createTooltip(),
          series: [
            ...Object.keys(byMarketplace).map((marketplace) => ({
              name: `${marketplaceMap[marketplace]} Listings`,
              data: byMarketplace[marketplace].map((item) => [item.key, item.count]),
              type: 'column',
              colorIndex: marketplaceColor[marketplaceMap[marketplace]],
              className: `${marketplaceMap[marketplace].toLowerCase()}-branded-chart`,
              // maxPointWidth: 15,
              yAxis: 0,
              animate: false,
            })),
            {
              name: 'Total',
              yAxis: 1,
              data: total.map((item) => [item.x, item.y]),
              type: 'spline',
              colorIndex: 0,
            },
          ],
        },
      };
    },
  },
  methods: {
    setInvisibleScrollArea(state) {
      this.invisibleScrollArea = state;
    },
  },
});
</script>

<style>
</style>
