<template>
  <div
    v-overlay-component="id"
    class="inline-block relative"
    @mouseover="onmouseover"
  >
    <Teleport
      v-if="isEnabled && hover"
      to="#overlay-components"
    >
      <div
        class="z-10000 py-2 overlay-component__overlay"
        :class="[overlayWrapClass, `overlay-component__overlay-${id}`]"
        :style="width ? `width: ${width}px` : ``"
      >
        <div
          class="
          p-2
          bg-white
          dark:bg-gray-900
          rounded-md
          border
          border-gray-850
        "
        >
          <slot
            name="overlay-content"
            :hover="hover"
          />
        </div>
      </div>
    </Teleport>

    <slot />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { defineComponent } from 'vue';
import { createPopper } from '@popperjs/core';
import { useDevice } from '@/composition/device-detector';

const showEvents = ['mouseenter', 'focus'];
const hideEvents = ['mouseleave', 'blur'];

const createPopperInstance = ({
  popcorn, tooltip, el,
}) => {
  if (popcorn === null || tooltip === null) return null;
  const popperInstance = createPopper(popcorn, tooltip, {
    placement: 'right-start',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: document,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [-8, 0],
        },
      },
    ],
  });
  // eslint-disable-next-line no-underscore-dangle
  el.__POPPER_INSTANCE__ = popperInstance;
  return popperInstance;
};

export default defineComponent({
  name: 'OverlayComponent',
  directives: {

    overlayComponent: {
      updated(el, { value: id, instance }) {
        const popperInstance = el.__POPPER_INSTANCE__;
        if (instance.hover === false && popperInstance) {
          // destroy popper
          popperInstance.destroy();
          el.__POPPER_INSTANCE__ = null;
          return;
        }

        if (popperInstance) {
          popperInstance.update();
        } else if (instance.hover) {
          const popcorn = el;
          const tooltip = document.querySelector(`.overlay-component__overlay-${id}`);
          if (!tooltip) return;
          createPopperInstance({
            el, popcorn, tooltip, instance,
          });

          showEvents.forEach((event) => {
            tooltip.addEventListener(event, () => {
              if (el._overlay_hide_timeout) {
                clearTimeout(el._overlay_hide_timeout);
                el._overlay_hide_timeout = null;
              }
              instance.setHover(true);
              instance.onmouseover();

              // We need to tell Popper to update the tooltip position
              // after we show the tooltip, otherwise it will be incorrect
              if (el.__POPPER_INSTANCE__) {
                el.__POPPER_INSTANCE__.update();
              }
            });
          });

          hideEvents.forEach((event) => {
            tooltip.addEventListener(event, () => {
              el._overlay_hide_timeout = setTimeout(() => {
                instance.setHover(false);
              }, 50);
            });
          });
        }
      },
      mounted(el, { value: id, instance }) {
        const popcorn = el;
        const tooltip = document.querySelector(`.overlay-component__overlay-${id}`);

        if (tooltip) {
          createPopperInstance({
            el, popcorn, tooltip, instance,
          });
        }

        showEvents.forEach((event) => {
          popcorn.addEventListener(event, () => {
            if (el._overlay_hide_timeout) {
              clearTimeout(el._overlay_hide_timeout);
              el._overlay_hide_timeout = null;
            }
            instance.setHover(true);
            instance.onmouseover();

            // We need to tell Popper to update the tooltip position
            // after we show the tooltip, otherwise it will be incorrect
            if (el.__POPPER_INSTANCE__) {
              el.__POPPER_INSTANCE__.update();
            }
          });
        });

        hideEvents.forEach((event) => {
          popcorn.addEventListener(event, () => {
            el._overlay_hide_timeout = setTimeout(() => {
              instance.setHover(false);
            }, 50);
          });
        });
      },
    },
  },
  props: {
    noOverlay: {
      default: false,
      type: Boolean,
    },
    width: {
      default: null,
      type: Number,
    },
    overlayWrapClass: {
      default: 'z-10',
      type: String,
    },
  },
  emits: ['visible', 'hidden'],
  setup() {
    const {
      isMinimumLgScreen,
    } = useDevice();

    return {
      isMinimumLgScreen,
    };
  },

  data() {
    return {
      hoverTimeout: null,
      hover: false,
      id: Math.floor((Math.random() * (2 ** 16)) + (2 ** 16)).toString(16),
    };
  },
  computed: {
    isEnabled() {
      return this.isMinimumLgScreen && this.noOverlay === false;
    },
  },
  methods: {
    setHover(value) {
      this.hover = value;
      this.$emit(value ? 'visible' : 'hidden');
    },
    onmouseover() {
      this.hover = true;
    },
  },
});
</script>

<style>
@keyframes fadeIn {
     from {
          opacity: 0;
          transform: translateY(15%) scale(0.975);
     }

     to {
          opacity: 1;
          transform: translateY(0) scale(1);
     }
}.overlay-component__overlay {
  width: calc(100% + 1rem + 1px);
}

.overlay-component__overlay > div {
  box-shadow: 0 5px 30px 5px rgba(0, 0, 0, 0.55);
}

.overlay-component__overlay > div {
  animation: fadeIn 0.3s;
}

</style>
