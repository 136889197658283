<template>
  <Component
    :is="mini === false ? 'div' : UiDropdown"
    :class="{'flex flex-wrap': mini === false}"
    :inline="inlineDropdown !== false"
    :right="right !== false"
  >
    <template
      v-if="mini !== false"
      #toggle="{toggle}"
    >
      <button
        class="inline-block
        p-2
        text-gray-400
        hover:text-indigo-600
        transition-colors
        cursor-pointer"
        @click="toggle"
      >
        <i class="fas fa-ellipsis-h" />
      </button>
    </template>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.opensea"
      v-tooltip="mini === false ? 'Open on OpenSea' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"
      :href="links.opensea"
    >
      <i class="fak fa-brand-opensea" />

      <div v-if="mini !== false">
        Open on OpenSea
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.looksrare"
      v-tooltip="mini === false ? 'Open on LooksRare' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"
      :href="links.looksrare"
    >
      <i class="fak fa-brand-looksrare" />

      <div v-if="mini !== false">
        Open on LooksRare
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.x2y2"
      v-tooltip="mini === false ? 'Open on X2Y2' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"
      :href="links.x2y2"
    >
      <i class="fak fa-brand-x2y2" />

      <div v-if="mini !== false">
        Open on X2Y2
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.blur"
      v-tooltip="mini === false ? 'Open on Blur.io' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"
      :href="links.blur"
    >
      <i class="fak fa-brand-blur" />

      <div v-if="mini !== false">
        Open on Blur.io
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.genie"
      v-tooltip="mini === false ? 'Open on Genie' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"
      :href="links.genie"
    >
      <i class="fak fa-brand-genie" />

      <div v-if="mini !== false">
        Open on Genie
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.uniswap"
      v-tooltip="mini === false ? 'Open on Uniswap' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"
      :href="links.uniswap"
    >
      <i class="fak fa-brand-uniswap" />

      <div v-if="mini !== false">
        Open on Uniswap
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.etherscan"
      v-tooltip="mini === false ? 'View on Etherscan' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"

      :href="links.etherscan"
    >
      <i class="fak fa-brand-etherscan" />

      <div v-if="mini !== false">
        View on Etherscan
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.externalWebsite"
      v-tooltip="mini === false ? 'Open External Website' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"

      :href="links.externalWebsite"
    >
      <i class="far fa-browser" />

      <div v-if="mini !== false">
        Open External Website
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.discord"
      v-tooltip="mini === false ? 'Join Discord' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"

      :href="links.discord"
    >
      <i class="fab fa-discord" />

      <div v-if="mini !== false">
        Join Discord
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.twitter"
      v-tooltip="mini === false ? 'Follow on Twitter' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"

      :href="links.twitter"
    >
      <i class="fab fa-twitter" />

      <div v-if="mini !== false">
        Follow on Twitter
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.telegram"
      v-tooltip="mini === false ? 'Join Telegram' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"

      :href="links.telegram"
    >
      <i class="fab fa-telegram" />

      <div v-if="mini !== false">
        Join Telegram
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.medium"
      v-tooltip="mini === false ? 'Follow on Medium' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"

      :href="links.medium"
    >
      <i class="fab fa-medium" />

      <div v-if="mini !== false">
        Follow on Medium
      </div>
    </Component>

    <Component
      :is="mini === false ? UiLink : UiDropdownItem"
      v-if="links.instagram"
      v-tooltip="mini === false ? 'Follow on Instagram' : undefined"
      target="_blank"
      :class="itemClasses"
      tag="a"

      :href="links.instagram"
    >
      <i class="fab fa-instagram" />

      <div v-if="mini !== false">
        Follow on Instagram
      </div>
    </Component>

    <slot />
  </Component>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import { RouterLink } from 'vue-router';
import UiDropdown from './ui/ui-dropdown/UiDropdown.vue';
import UiDropdownItem from './ui/UiDropdownItem.vue';
import UiLink from './ui/UiLink.vue';

const props = defineProps({
  links: Object,
  mini: {
    type: Boolean,
    default: false,
  },
  right: {
    type: Boolean,
    default: false,
  },
  inlineDropdown: {
    type: Boolean,
    default: false,
  },
});
const {
  links,
  mini,
  right,
  inlineDropdown,
} = toRefs(props);

const itemClasses = computed(() => (mini.value === false ? `
        inline-block
        p-2
        text-gray-400
        hover:text-indigo-600
        transition-colors
        cursor-pointer
      ` : 'flex gap-3 items-center'));
</script>
