<template>
  <BoostedChart
    :is-loading="loading"
    :options="activityChartOptions"
    class="wallet-activity-scatter"
  />
</template>

<script setup>
import moment from 'moment-timezone';
import walletActivityChartQuery from '@/graphql/wallet/queries/walletActivityChart.query.gql';
import walletWatchlistActivityChartQuery from '@/graphql/watchlists/queries/walletWatchlistActivityChart.query.gql';
import { computed, toRefs, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { displayName, formatCurrency, formatNumber } from '@/utils/filters';
import {
  groupBy, maxBy, minBy, orderBy,
} from 'lodash-es';
import { ACTIVITY_TYPE_TO_COLOR, ACTIVITY_TYPE_TO_COLOR_INDEX } from '@/utils/activityTypeColors';
import gql from 'graphql-tag';
import { useWatchlists } from '@/composition/watchlists/use-watchlists';
import { WATCHLIST_TYPES } from '@/constants';
import useTokenModal from '@/composition/tokens/useTokenModal';
import BoostedChart from '../BoostedChart.vue';
import UiModal from '../ui/UiModal.vue';
import TokenModal from '../TokenModal.vue';

const props = defineProps(['walletId', 'collectionId', 'period', 'watchlistId', 'filters']);
const {
  watchlistId, walletId, collectionId, period,
  filters,
} = toRefs(props);

const walletQuery = gql`
  query GetWalletProfitAndLossOverTime($modelId: String!, $collectionId: String, $period: String, $filters: [FilterArg!]) {
    model: wallet(where: {id: $modelId}) {
      id
      profitAndLossOverTime(collectionId: $collectionId, period: $period, filters: $filters)
    }
  }
`;

const watchlistQuery = gql`
  query GetWalletGroupProfitAndLossOverTime($modelId: String!, $collectionId: String, $period: String, $filters: [FilterArg!]) {
    model: walletWatchlistBySlug(slug: $modelId) {
      id
      profitAndLossOverTime(collectionId: $collectionId, period: $period, filters: $filters)
    }
  }
`;

const {
  result: profitAndLossResult,
  refetch: refetchProfitAndLoss,
} = useQuery(
  () => (walletId.value ? walletQuery : watchlistQuery),
  () => ({
    modelId: walletId.value || watchlistId.value,
    collectionId: collectionId.value,
    period: period.value,
    filters: filters.value,
  }),
);

const {
  result: activityChartResult,
  loading,
  refetch: refetchActivityChart,
} = useQuery(
  () => (walletId.value ? walletActivityChartQuery : walletWatchlistActivityChartQuery),
  () => ({
    modelId: walletId.value || watchlistId.value,
    collectionId: collectionId.value,
    period: period.value,
    filters: filters.value,
  }),
);

const { onWatchlistMembersUpdated } = useWatchlists(WATCHLIST_TYPES.WALLET);

onWatchlistMembersUpdated(() => {
  if (watchlistId.value) {
    refetchProfitAndLoss();
    refetchActivityChart();
  }
});

const { open } = useTokenModal();

const activityChart = computed(() => activityChartResult.value?.model.activityChart);
const activityChartOptions = computed(() => {
  const byType = groupBy(activityChart.value || [], 'type');
  const pnlData = orderBy(profitAndLossResult.value?.model?.profitAndLossOverTime?.map((item) => (
    {
      x: Number(moment(item.key).format('X') * 1000),
      y: item.value,
      colorIndex: item.value > 0 ? 0 : 4,
    }
  )) || [], 'x');
  return {
    chart: {
      zoomType: 'xy',
      styledMode: false,
      resetZoomButton: {
        theme: {
          fill: '#404040',
          stroke: '#404040',
          style: {
            color: '#D4D4D4',
          },
        },
      },
    },
    subtitle: {
      enabled: true,
      text: 'Click and drag to zoom',
      align: 'right',
    },
    xAxis: {
      type: 'datetime',
      startOnTick: true,
      endOnTick: true,

      // gridLineWidth: 1,
      // tickInterval: 24 * 3600 * 1000,
    },
    yAxis: [
      {
        opposite: false,
        height: '70%',
        min: -0.001,
        title: {
          enabled: false,
        },
        labels: {
          formatter: function formatter() {
            if (this.value < 0) {
              return '';
            }
            return formatCurrency(this.value, 'ETH');
          },
        },
      },
      {
        opposite: false,
        height: '30%',
        top: '70%',
        max: maxBy(pnlData, 'y')?.y,
        min: minBy(pnlData, 'y')?.y,
        labels: {
          enabled: false,
        },
        title: {
          enabled: false,
        },
      },
    ],
    plotOptions: {

      series: {
        // pointPlacement: 'between',
        boostThreshold: 2000,
        point: {
          events: {
            click: (event) => {
              const [tx] = activityChart.value.filter((item) => parseInt(moment(item.timestamp).format('x')) === event.point.x && item.value === event.point.y);
              if (!tx) {
                return;
              }

              open(tx.tokenId, { accordion: 'Activity Chart' });
            },
          },

        },
      },
      scatter: {
        marker: {
          symbol: 'circle',
          radius: 5,
        },
      },
      column: {
        borderRadius: 2,
        pointPadding: 0.3,
        grouping: false,
        groupPadding: 0.3,
      },
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: '#ddd', cursor: 'pointer', fontSize: '12px', fontWeight: 'bold', textOverflow: 'ellipsis',
      },
      itemHoverStyle: {
        color: '#eee', cursor: 'pointer', fontSize: '12px', fontWeight: 'bold', textOverflow: 'ellipsis',
      },
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      symbolWidth: 12,
      symbolHeight: 12,

    },

    tooltip: {
      followPointer: false,
      snap: false,
      shared: true,
      useHTML: true,
      padding: 0,
      borderWidth: 0,
      shadow: false,
      hideDelay: 30,
      animation: false,
      shape: 'rect',
      headerFormat: '',
      footerFormat: '',
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: 'rgba(0,0,0,0)',
      pointFormatter() {
        // const item = activityChart.value.value[this.index];
        const item = byType[this.series.name.toLowerCase()]?.[this.index];

        if (!item) {
          return `
          <div class="bg-gray-800 font-sans p-3 text-gray-400 rounded-xl pointer-events-none">
            <table class="font-sans w-full">
              <tr class="mb-2">
                <th class="text-xs font-sans font-normal text-gray-400" colspan="2">${moment(this.x).format('ll')}</th>
              </tr>
              <tr>
                <td class="text-sm ">${this.series.name}</td>
                <td class="text-sm text-white text-medium">${formatCurrency(this.y)}</td>
              </tr>
            </table>
          </div>
          `;
        }
        // eslint-disable-next-line
        return `
          <div class="bg-gray-800 font-sans p-3 text-gray-400 rounded-xl pointer-events-none">
            ${item?.image ? `
            <div class="aspect-w-1 aspect-h-1 w-40 rounded-lg overflow-hidden mb-2">
              <img class="w-full h-full highcharts-tooltip-image" src="${item.image}"/>
            </div>
            ` : ''}
            <table class="font-sans w-full ">
              <tr>
                <th class="text-xs font-sans font-normal text-gray-400" colspan="2">${moment(item.timestamp).format('lll')}</th>
              </tr>
              <tr class="text-sm">
                <td class="font-sans" style="color: {series.color}">${this.series.name} Price</td>
                <td style="text-align: right" class="font-medium font-sans text-gray-100">
                  ${this.series.type === 'column' ? formatNumber(this.y) : formatCurrency(this.y)}
                </td>
              </tr>
              <tr class="text-sm">
                <td class="font-sans">Token ID</td>
                <td style="text-align: right" class="font-medium font-sans text-gray-100">
                  ${item.tokenId.split('_')[1]}
                </td>
              </tr>
              ${item.rarityRank ? `
              <tr class="text-sm">
                <td class="font-sans">Rarity</td>
                <td style="text-align: right" class="font-medium font-sans text-gray-100">
                  ${formatNumber(item.rarityRank)}
                </td>
              </tr>
              ` : ''}
              ${item.netProfit || item.netProfit === 0 ? `
              <tr class="text-sm">
                <td class="font-sans">Profit</td>
                <td style="text-align: right" class="font-medium font-sans text-gray-100">
                  ${formatCurrency(item.netProfit)}
                </td>
              </tr>
              ` : ''}
            </table>
          </div>`;
      },
    },
    series: [

      ...Object.keys(byType).map((type) => ({
        states: {
          inactive: {
            opacity: 1,
          },
        },
        stickyTracking: false,
        yAxis: 0,
        name: displayName(type),
        type: 'scatter',
        colorIndex: ACTIVITY_TYPE_TO_COLOR_INDEX[type],
        color: ACTIVITY_TYPE_TO_COLOR[type],
        data: byType[type].map((item) => ([moment(item.timestamp).toDate().getTime(), item.value])) || [],
        showInLegend: true,
      })),
      {
        stickyTracking: false,
        name: 'Net Profit',
        type: 'column',
        yAxis: 1,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        // pointWidth: (chartWidth / (length / 60 / 60 / 24)) / 2,
        data: pnlData,
        pointPlacement: 0.5,
        showInLegend: false,
      },
    ],
  };
});

</script>
