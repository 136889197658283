<template>
  <UiDropdown top>
    <template #toggle="{ toggle }">
      <slot
        name="button"
        :show="() => toggle()"
        :current-preset="gasPreset"
        :current-preset-name="presets[gasPreset || 'standard']"
      >
        <button
          class="text-xs leading-none dark:text-gray-300 hover:underline"
          @click="toggle"
        >
          Gas Preset: {{ presets[gasPreset || 'standard'] }}
        </button>
      </slot>
    </template>

    <template #body>
      <div class="py-2 px-4 text-xs text-gray-300">
        Gas preset
      </div>

      <UiDropdownItem
        v-for="(value, key) in presets"
        :key="key"
        :is-active="key === gasPreset"
        @click="setGasPreset($event, key)"
      >
        {{ value }}
      </UiDropdownItem>

      <UiDropdownDivider v-if="gasPreset === 'custom'" />

      <UiDropdownForm
        v-if="gasPreset === 'custom'"
        class="pt-2"
      >
        <form autocomplete="off">
          <UiInputGroup label="Max Fee per Gas">
            <UiInput
              v-model="maxFeePerGas"
              autocomplete="off"
              :placeholder="formatWei(maxFeePerGasData, 'gwei')?.toFixed(2)"
            />
          </UiInputGroup>

          <UiInputGroup label="Max Priority Fee per Gas">
            <UiInput
              v-model="maxPriorityFeePerGas"
              autocomplete="off"
              :placeholder="formatWei(maxPriorityFeePerGasData, 'gwei')?.toFixed(2)"
            />
          </UiInputGroup>
        </form>
      </UiDropdownForm>
    </template>
  </UiDropdown>
</template>

<script>
import mapSettings from '@/mixins/mapSettings';
import { formatWei } from '@/utils/filters';
import UiDropdown from '@/components/ui/ui-dropdown/UiDropdown.vue';
import UiDropdownForm from '@/components/ui/UiDropdownForm.vue';
import UiDropdownItem from '@/components/ui/UiDropdownItem.vue';
import UiInput from './ui/UiInput.vue';
import UiInputGroup from './ui/UiInputGroup.vue';
import UiDropdownDivider from './ui/UiDropdownDivider.vue';

const GAS_PRESETS = {
  rapid: '⚡️ Instant',
  fast: '🚀 Fast',
  standard: '🚙 Standard',
  custom: '🛠 Custom',
};

export default {
  components: {
    UiDropdown,
    UiDropdownItem,
    UiDropdownForm,
    UiInputGroup,
    UiInput,
    UiDropdownDivider,
  },
  mixins: [
    mapSettings({
      gasPreset: { key: 'gas.preset', default: 'fast' },
      maxFeePerGas: { key: 'gas.custom.maxFeePerGas' },
      maxPriorityFeePerGas: { key: 'gas.custom.maxPriorityFeePerGas' },
    }),
  ],
  computed: {
    presets() {
      return GAS_PRESETS;
    },
    gasData() {
      return this.$store.getters['gas/gasData']?.eip1559 || {};
    },
    maxFeePerGasData() {
      return this.gasData?.fast?.maxFeePerGas || '';
    },
    maxPriorityFeePerGasData() {
      return this.gasData?.fast?.maxPriorityFeePerGas || '';
    },
  },
  methods: {
    formatWei,
    setGasPreset(event, preset) {
      if (preset === 'custom') {
        event.stopPropagation();
      }
      if (Object.keys(GAS_PRESETS).indexOf(preset) === -1) {
        throw new Error('Invalid preset selected:', preset);
      }

      this.gasPreset = preset;
    },
  },
};
</script>
