<template>
  <Draggable
    v-if="model?.length > 0"
    v-model="model"
    group="inputs"
    item-key="id"
    :class="`grid-cols-${cols}`"
    class="grid gap-2 mb-3 text-white"
    tag="ul"
    :animation="150"
    :disabled="readonly !== false"
  >
    <template #item="{element: item}">
      <li
        class=" py-1 px-2 bg-gray-800 rounded-lg"
      >
        <div class="flex gap-2 justify-between items-center">
          <div class="flex gap-2 items-center">
            <VariableType :type="item.type" />

            <ContextVariableEditorDropdown
              :model-value="item"
              :mode="mode"
              @update:modelValue="model[index] = $event"
            >
              <template #toggle="{toggle}">
                <button
                  v-tooltip="item.description"
                  class="text-sm font-medium"
                  :class="{'cursor-pointer': readonly === false}"
                  type="button"
                  @click="() => {
                    if (readonly !== false) {
                      return;
                    }
                    toggle();
                  }"
                >
                  <span v-if="item.actingAs">{{ actingAsById[item.actingAs].name }} <span class="text-sm text-gray-400">{{ item.name }}</span></span>

                  <span v-if="!item.actingAs">{{ item.name }}</span>
                </button>
              </template>

              <template #footer="{toggle}">
                <UiInputGroup :label="''">
                  <UiButton
                    size="sm"
                    @click="() => {
                      toggle();
                    }"
                  >
                    Done
                  </UiButton>
                </UiInputGroup>
              </template>
            </ContextVariableEditorDropdown>

            <span
              v-if="mode === 'input' && item.required"
              v-tooltip="'Required'"
              class="text-xs text-red-400 fas fa-asterisk"
            />
          </div>

          <div>
            <UiButton
              v-if="readonly === false"
              size="xs"
              variant="white"
              @click="removeInput(item)"
            >
              <i class="far fa-trash-alt" />
            </UiButton>

            <slot
              name="item-actions"
              :item="item"
            />
          </div>
        </div>

        <div
          v-if="item.type === 'Array' && item.items?.type === 'Object'"
          class="p-2 mt-2 bg-gray-850 rounded-md"
        >
          <ContextVariablesEditor
            v-model="item.items.properties"
            :readonly="readonly"
            :cols="1"
            :mode="mode"
          >
            <template #item-actions="{item: nestedItem}">
              <slot
                name="item-actions"
                :item="{...nestedItem, name: `${item.name}[0].${nestedItem.name}`}"
              />
            </template>
          </ContextVariablesEditor>
        </div>
      </li>
    </template>
  </Draggable>

  <ContextVariableEditorDropdown
    v-if="readonly === false"
    v-model="newInput"
    :mode="mode"
  >
    <template #toggle="{toggle}">
      <UiButton
        v-if="modelValue?.length > 0"
        variant="white"
        size="xs"
        @click="toggle"
      >
        <div class="flex gap-2 items-center">
          <i class="far fa-plus" />
          Add
        </div>
      </UiButton>

      <NoContent
        v-if="modelValue?.length === 0"
        :content="mode === 'input' ? 'No inputs yet' : 'No outputs yet'"
        size="sm"
        horizontal
      >
        <template #cta>
          <UiButton
            size="sm"
            variant="white"
            @click="toggle"
          >
            Add first {{ mode }}
          </UiButton>
        </template>
      </NoContent>
    </template>

    <template #footer="{toggle, isValid}">
      <UiInputGroup :label="''">
        <UiButton
          :disabled="!isValid"
          size="sm"
          @click="() => {
            if (readonly !== false) {
              return;
            }
            addInput();
            toggle();
          }"
        >
          Add
        </UiButton>
      </UiInputGroup>
    </template>
  </ContextVariableEditorDropdown>
</template>

<script setup>
import { IO_ACTING_AS } from '@/constants/automation';
import { keyBy } from 'lodash-es';
import {
  toRefs, reactive, computed, ref,
} from 'vue';

import Draggable from 'vuedraggable';
import UiBadge from '../ui/UiBadge.vue';
import UiButton from '../ui/UiButton.vue';
import ContextVariableEditorDropdown from './ContextVariableEditorDropdown.vue';
import VariableType from './VariableType.vue';
import NoContent from '../NoContent.vue';
import UiInputGroup from '../ui/UiInputGroup.vue';

const actingAsById = keyBy(IO_ACTING_AS, 'id');

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  cols: {
    type: String,
    default: '2',
  },
  mode: {
    type: String,
    default: 'input',
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const {
  modelValue,
  cols,
  mode,
  readonly,
} = toRefs(props);

const emit = defineEmits(['update:modelValue']);

const newInput = ref({
  name: '',
  type: null,
  required: true,
  defaultValue: '',
});

const model = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    if (readonly.value !== false) {
      return;
    }
    emit('update:modelValue', value);
  },
});

const addInput = () => {
  if (readonly.value !== false) {
    return;
  }
  model.value = [...(model.value || []), { ...newInput.value }];
  newInput.value.name = '';
  newInput.value.type = null;
  newInput.value.required = true;
  newInput.value.defaultValue = '';
};

const removeInput = (input) => {
  if (readonly.value !== false) {
    return;
  }
  model.value = model.value.filter((item) => item !== input);
};

</script>
