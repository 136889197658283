<template>
  <ActionInputMapper
    v-model="value"
    :inputs="action.inputs"
    :validation-issues="validationIssues"
  >
    <template #input(attributeKey)="{model, updateModel}">
      <UiInputGroup
        label="Trait"
        hint="Note: Trait bidding works on Opensea only."
      >
        <UiSelectCustom
          v-if="!isLoadingTraits"
          :model-value="model"
          :options="traitOptions"
          :disabled="!value.collectionId"
          @update:modelValue="updateModel"
        />

        <div
          v-else
          class="w-32 h-8 bg-gray-800 rounded-lg animate-pulse"
        />
      </UiInputGroup>
    </template>

    <template #input(attributeValue)="{model, updateModel}">
      <UiInputGroup
        label="Trait value"
      >
        <UiSelectCustom
          v-if="!isLoadingTraits"
          :model-value="model"
          :options="traitValueOptions"
          :disabled="!value.attributeKey"
          @update:modelValue="updateModel"
        />

        <div
          v-else
          class="w-32 h-8 bg-gray-800 rounded-lg animate-pulse"
        />
      </UiInputGroup>
    </template>

    <template #input(marketplace)="{model, updateModel, isVariable, addVariable}">
      <UiInputGroup label="Marketplace">
        <UiInputRadio
          v-if="!isVariable"
          :model-value="model"
          :disabled="readonly"
          :options="[
            { text: 'Opensea', value: 3 },
            { text: 'Looksrare', value: 1 },
            { text: 'X2Y2', value: 4 },
          ]"
          @update:modelValue="updateModel"
        />

        <VariableDisplay
          v-if="isVariable"
          :key="model"
          :model-value="model"
          :disabled="readonly !== false"
          @update:modelValue="updateModel"
        />

        <template #after-label>
          <div
            v-if="!readonly"
          >
            <div class="flex gap-1 items-center">
              <button
                class="relative active:top-px px-1 text-xs font-medium text-gray-300 bg-gray-700 hover:bg-gray-600 rounded-md transition-colors"
                @click="addVariable(false, null)"
              >
                <i class="fas fa-plus" />
                Use variable
              </button>
            </div>
          </div>
        </template>
      </UiInputGroup>
    </template>
  </ActionInputMapper>
</template>

<script setup>

import { toRefs, computed } from 'vue';

import UiInputGroup from '@/components/ui/UiInputGroup.vue';
import UiSelectCustom from '@/components/ui/UiSelectCustom.vue';
import useTraits from '@/composition/tokens/useTraits';
import UiInputRadio from '@/components/ui/UiInputRadio.vue';
import ActionInputMapper from '../ActionInputMapper.vue';
import VariableDisplay from '../VariableDisplay.vue';

const props = defineProps(['modelValue', 'action', 'type', 'validationIssues', 'trigger', 'readonly']);
const emit = defineEmits(['update:modelValue', 'trigger']);

const {
  modelValue, action, validationIssues, readonly,
} = toRefs(props);

const value = computed({
  get() {
    return modelValue.value;
  },
  set(v) {
    emit('update:modelValue', v);
  },
});

const { traits, loading: isLoadingTraits } = useTraits(
  computed(() => modelValue.value.collectionId),
);

const selectedTrait = computed(() => traits.value.find((t) => t.name === modelValue.value.attributeKey));

const traitOptions = computed(() => [
  {
    id: null,
    name: 'None',
  },
  ...(traits.value?.map((t) => ({
    id: t.name,
    name: t.name,
  })) || []),
]);

const traitValueOptions = computed(() => [
  {
    id: null,
    name: 'None',
  },
  ...(selectedTrait.value?.values.map((v) => ({
    id: v.value,
    name: v.value,
  })) || []),
]);
</script>
